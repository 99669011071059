import {
  ChangeBookStatusParams,
  ChangeBookStatusResult,
  DeleteMyBookParams,
  GetMyBooksParams,
  GetMyViewedBooksParams,
  GetPlaceReadByBookSlugParams,
  GetPlaceReadResult,
  HiddenBookParams,
  SavePlaceReadParams,
} from '~/api/account/myBooksApiTypes';
import { Book } from '~/api/book/bookApiTypes';
import { provider } from '~/api/provider/provider.config';
import { handleRejectedRequest } from '~/api/provider/providerErrors';
import {
  ApiResponse, Paginated, RejectedRequest,
} from '~/api/provider/providerTypes';

const namespace = Symbol('namespace');

export const myBooksApi = {
  [namespace]: '/v1/mybooks',

  async getMyBooks(data: GetMyBooksParams) {
    try {
      const response = await provider.get<
      Paginated<Book[]> | RejectedRequest<void>
      >(
        `${this[namespace]}`,
        {
          params: {
            genreSlugs: data.genreSlugs.length > 0 ? data.genreSlugs.join(',').replace(' ', '') : undefined,
            statuses: data.statuses,
            statusComplete: data.statusComplete,
            page: data.page,
            perPage: data.perPage,
            free: data.free,
            type: data.type,
            reaction: data.reaction,
          },
          headers: {
            Authorization: data && data?.token
              ? `Bearer ${data?.token}`
              : provider.defaults.headers.common.Authorization,
          },
        },
      );

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getMyViewedBooks(data: GetMyViewedBooksParams) {
    try {
      const response = await provider.get<
      Paginated<Book[]> | RejectedRequest<void>
      >(
        `${this[namespace]}/viewed`,
        {
          params: {
            page: data.page,
            perPage: data.perPage,
          },
          headers: {
            Authorization: data && data?.token
              ? `Bearer ${data?.token}`
              : provider.defaults.headers.common.Authorization,
          },
        },
      );

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async clearViewedBooks() {
    try {
      await provider.delete<
      void | RejectedRequest<void>
      >(
        `${this[namespace]}/viewed`,
      );
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async changeBookStatus(data: ChangeBookStatusParams) {
    const { bookId, ...requestData } = data;
    try {
      const response = await provider.put<
      ApiResponse<ChangeBookStatusResult, RejectedRequest<void>>
      >(
        `${this[namespace]}/book/${bookId}/status`,
        requestData,
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async addMyBooks(data: ChangeBookStatusParams) {
    try {
      const response = await provider.post<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/book`,
        data,
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async deleteMyBook(data: DeleteMyBookParams) {
    try {
      const response = await provider.delete<
      ApiResponse<ChangeBookStatusResult, RejectedRequest<void>>
      >(
        `${this[namespace]}/book/${data.bookId}`,
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async savePlaceRead(data: SavePlaceReadParams) {
    try {
      const response = await provider.post<
      ApiResponse<void, RejectedRequest<void>>
      >(`${this[namespace]}/placeReadSave`, data);

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async getPlaceReadByBookSlug(data: GetPlaceReadByBookSlugParams) {
    try {
      const response = await provider.get<
      ApiResponse<GetPlaceReadResult, RejectedRequest<void>>
      >(`${this[namespace]}/getReadPlace`, { params: data });
      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async hiddenBook(data: HiddenBookParams) {
    try {
      const response = await provider.post<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/hidden/${data.bookId}`,
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async cancelHiddenBook(data: HiddenBookParams) {
    try {
      const response = await provider.delete<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/hidden/${data.bookId}`,
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

};
