import React, { FC } from 'react';
import styled from 'styled-components';

import { CommonLayout } from '~/atomic/layout/CommonLayout';
import { TextBoldStyle } from '~/atomic/Typography';
import { BlogsFilterNav } from '~/feature/blogs/BlogsFilterNav/BlogsFilterNav';
import { lessThan } from '~/lib/mediaQuery';
import { WithChildren } from '~/lib/WithChildren';

import { VKWidjet } from '../vk/VKWidjet';

export const PostTemplate: FC<WithChildren> = ({ children }) => {
  return (
    <CommonLayout>
      <SCName>
        Блоги
      </SCName>
      <SCBlogTemplate>
        <LeftNav>
          <BlogsFilterNav />
          <SCVKWidjet
            widjetSetting={{ width: 'auto' }}
          />
        </LeftNav>
        { children }
      </SCBlogTemplate>
    </CommonLayout>
  );
};

const LeftNav = styled.div`
  margin-right: 25px;
  margin-bottom: 30px;
  width: max-content;
  flex-shrink: 0;

  ${lessThan('sm')} {
    margin-right: 0;
    margin-bottom: 20px;
    width: 100%;
  }
`;

const SCBlogTemplate = styled.div`
  display: flex;
  align-items: flex-start;
  padding-top: 16px;
  width: 100%;
  justify-content: space-between;
  padding-right: 15px;
  padding-left: 15px;

  ${lessThan('sm')} {
    flex-direction: column;
    padding-bottom: 30px;
  }
`;

const SCName = styled.h1`
  ${TextBoldStyle};
  display: flex;
  color: var(--gray-color);
  margin-top: 18px;
  padding-left: 15px;
  margin-bottom: 0;
`;

const SCVKWidjet = styled(VKWidjet)`
  margin-top: 40px;
`;
