import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const headerSliceName = 'header';

export enum ActiveLibraryEnum {
  Genre = 'genre',
  Tag = 'tag',
}

export const headerSlice = createSlice({
  name: headerSliceName,
  initialState: {
    isOpenLibrary: false,
    isShowMobileSearchDraw: false,
    isMobileUserInfoOpen: false,
    isOpenMobileLibrary: false,
    isHamburgerActive: false,
    activeLibrary: ActiveLibraryEnum.Genre,
  },
  reducers: {
    setIsOpenLibrary: (state, action: PayloadAction<boolean>) => {
      state.isOpenLibrary = action.payload;
    },
    setIsOpenMobileLibrary: (state, action: PayloadAction<boolean>) => {
      state.isOpenMobileLibrary = action.payload;
    },
    setIsShowMobileSearchDraw: (state, action: PayloadAction<boolean>) => {
      state.isShowMobileSearchDraw = action.payload;
    },
    setIsHamburgerActive: (state, action: PayloadAction<boolean>) => {
      state.isHamburgerActive = action.payload;
    },
    setIsMobileUserInfoOpen: (state, action: PayloadAction<boolean>) => {
      state.isMobileUserInfoOpen = action.payload;
    },
    toggleIsHamburgerActive: (state) => {
      state.isHamburgerActive = !state.isHamburgerActive;
    },
    changeActiveLibrary: (state, action: PayloadAction<ActiveLibraryEnum>) => {
      state.activeLibrary = action.payload;
    },
  },
});

export const { actions: headerActions, reducer: headerReducer } = headerSlice;
