import React, { FC, HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

import { MiniatureSizesEnum } from '~/api/book/bookApiTypes';
import { Avatar } from '~/atomic/atom/Avatar/Avatar';
import { TextArea } from '~/atomic/atom/Input';
import { Loader, SCLoader } from '~/atomic/atom/Loader';
import { BookReaderThemeEnum } from '~/atomic/page/book-reader/reader.slice';
import { TextBoldStyle, TextSmallStyle } from '~/atomic/Typography';
import { addComment, deleteSaveComment } from '~/feature/comments/comment.data';
import { commentsSelector } from '~/feature/comments/comment.selector';
import { commentActions, isCommentsLoading } from '~/feature/comments/comment.slice';
import { isLoggedInSelector } from '~/feature/user/isLoggedInSelector';
import { userSelector } from '~/feature/user/user.selector';
import { useAppDispatch, useAppSelector } from '~/store';

import { openModal } from '../authorization/authorizationModal.slice';

interface CreateCommentsTextAreaProps extends HTMLAttributes<HTMLDivElement> {
  targetClass: 'book' | 'post';
  targetId: string | number;
  placeholder?: string;
  afterSendComment?: () => void;
  theme?: BookReaderThemeEnum;
  isReview?: boolean;
}

export const CreateCommentTextArea: FC<CreateCommentsTextAreaProps> = ({
  targetClass,
  targetId,
  placeholder,
  afterSendComment,
  theme,
  isReview,
  ...props
}) => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const { user } = useAppSelector(userSelector);
  const {
    loading, saveComment, createCommentText, isDeleteSaveCommentLoading,
  } = useAppSelector(commentsSelector);

  const sendComment = async () => {
    await dispatch(addComment({
      content: createCommentText,
      targetClass,
      targetId,
      isReview,
    }));
    if (afterSendComment) afterSendComment();
  };

  const sendCommentHandler = async () => {
    if (createCommentText.length > 0) {
      sendComment().then(() => {
        dispatch(commentActions.changeCreateCommentText(''));
      });
    }
  };

  const ctrlEnterHandler = (e) => {
    if (e.keyCode === 13 && e.ctrlKey) {
      sendCommentHandler();
    }
  };

  const handleCommentTextChange = async (e) => {
    if (e.target.value && e.target.value.length > 3999) {
      const { message } = await import('~/atomic/atom/message');
      message.warning('Длина комментария не может быть больше 4000 символов.');
    } else if (isLoggedIn) {
      if (saveComment?.id && !isDeleteSaveCommentLoading) {
        dispatch(deleteSaveComment());
      }
      dispatch(commentActions.changeCreateCommentText(e.target.value));
    }
  };

  const textAreaClickHandler = () => {
    if (!isLoggedIn) {
      dispatch(openModal('login'));
    }
  };

  return (
    <SCTextAreaWrapper {...props}>
      <SCAvatarWrapper
        data-is-disabled={String(!isLoggedIn)}
        data-theme={theme}
      >
        <SCAvatar>
          {isLoggedIn
            ? (
              <Avatar
                imageSize={24}
                avatarSize={MiniatureSizesEnum.M}
                avatarImages={user?.avatarImages}
                userName={user?.name}
                alt="Аватарка пользователя"
              />
            )
            : (
              <Avatar
                imageSize={24}
                avatarSize={MiniatureSizesEnum.M}
                src="/image/default-avatar.svg"
                alt="Аватарка пользователя"
              />
            ) }
        </SCAvatar>
      </SCAvatarWrapper>
      <SCTextArea
        data-is-disabled={!isLoggedIn}
        onKeyDown={ctrlEnterHandler}
        placeholder={placeholder}
        autoSize={{ minRows: 1 }}
        value={createCommentText}
        onChange={handleCommentTextChange}
        onClick={textAreaClickHandler}
        data-theme={theme}
      />
      <SCActionsWrapper
        data-is-disabled={String(!isLoggedIn)}
      >
        {isCommentsLoading('addComment', loading)
          ? <SCLoaderWrap><Loader size={30} /></SCLoaderWrap>
          : (
            <SCSendButton
              onClick={sendCommentHandler}
              disabled={
                createCommentText.length === 0 || !user?.id
              }
            />
          )}
      </SCActionsWrapper>
    </SCTextAreaWrapper>
  );
};

export const SCTextAreaWrapper = styled.div` 
  position: relative;
  border: 1px solid var(--border-color);
  border-radius: 2px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SCAvatar = styled.div`
  margin-top: 10px;
`;

const SCAvatarWrapper = styled.div`
  position: absolute;
  min-height: 44px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
  pointer-events: none;
  
  &[data-is-disabled="true"] {
    background-color: #f5f5f5;
  }

  &[data-theme=${BookReaderThemeEnum.Dark}] {
    background-color: transparent;
  }
`;

const SCTextArea = styled(TextArea)` && {
  border: none;
  padding-left: 50px;
  padding-right: 70px;
  z-index: 1;
  resize: none;
  
  &.ant-input {
    min-height: 44px !important; 
    font-family: var(--second-font);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;                                      
    line-height: 22px;
    padding-top: 11px;

    &[data-is-disabled="true"] {
      background-color: #f5f5f5;
      cursor: not-allowed;

      &:focus, &:active {
        border: none;
        box-shadow: none;
        outline: none;
        caret-color: transparent;
      }
    }

    &[data-theme=${BookReaderThemeEnum.Dark}] {
      background: var(--gray3-color);
      ::-webkit-input-placeholder { color: var(--gray-color); }
      ::-moz-placeholder          { color: var(--gray-color); }
      :-moz-placeholder           { color: var(--gray-color); }
      :-ms-input-placeholder      { color: var(--gray-color); }
    }
  }
}
`;

const SCReward = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  background: var(--bg-color);
  border: 1px solid var(--gray3-color);
  box-sizing: border-box;
  border-radius: 2px;
  margin-left: 48px;
  margin-bottom: 13px;
  margin-top: 20px;
  position: relative;
`;

const SCRewardName = styled.span`
  ${TextSmallStyle};
  color: var(--gray-color);
  margin-bottom: 5px;
`;

const SCrewardAmount = styled.span`
  ${TextBoldStyle};
  color: var(--black-color);
`;

const SCDeleteRewardIcon = css`
  font-size: 10px;
  color: var(--gray-color);
  transition: color 0.3s ease; 
`;

const SCDeleteReward = styled.div`
  background: #FFFFFF;
  border: 1px solid var(--gray3-color);
  box-sizing: border-box;
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  right: 0;
  top: 0;
  transform: translateX(50%) translateY(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    ${SCDeleteRewardIcon} {
      color: var(--primary-hover-color);
    }
  }
`;

const SCActionsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  z-index: 2;
  min-width: 20px;
  min-height: 20px;
  right: 12px;
  bottom: 12px;
  
  &[data-is-disabled="true"] {
    pointer-events: none;
  }
`;

const SCSendButton = styled.button`
  border: unset;
  mask-image: url('/icon/send.svg');
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
  background-color: var(--primary-hover-color);
  cursor: pointer;
  width: 20px;
  height: 20px;
  opacity: 0.6;
  
  &:disabled {
    background-color: var(--gray-color);
    user-select: none;
    pointer-events: none;
  }
`;

const SCLoaderWrap = styled.div`
  ${SCLoader} {
    &[data-theme=${BookReaderThemeEnum.Dark}] {
      background-color: var(--black-color);
    }

    &[data-theme=${BookReaderThemeEnum.Sandy}] {
      background-color: var(--sandy-color);
    }
  }
`;
