import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetMyBooksReactionParam } from '~/api/account/myBooksApiTypes';
import { Book } from '~/api/book/bookApiTypes';

export const myBooksSliceName = 'myBooks';

export type MyBooksTabNames = 'read' | 'readLater' | 'readNow' | 'viewed' | 'reaction';

const myBooksSlice = createSlice({
  name: myBooksSliceName,
  initialState: {
    tabName: '' as MyBooksTabNames,
    reactionFilter: 'all' as GetMyBooksReactionParam,
    isLoading: false,
    isChangeBookStatusWithBookLoading: false,
    readNow: {
      books: [] as Book[],
      total: 0,
    },
    readLater: {
      books: [] as Book[],
      total: 0,
    },
    read: {
      books: [] as Book[],
      total: 0,
    },
    reaction: {
      books: [] as Book[],
      total: 0,
    },
    viewed: {
      books: [] as Book[],
      total: 0,
    },
    percent: {
      books: [] as Book[],
      total: 0,
    },
  },
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    changeTabName: (state, action: PayloadAction<MyBooksTabNames>) => {
      state.tabName = action.payload;
    },
    setIsChangeBookStatusWithBookLoading: (state, action: PayloadAction<boolean>) => {
      state.isChangeBookStatusWithBookLoading = action.payload;
    },
    changeBooks: (state, action: PayloadAction<{ tabName: MyBooksTabNames, books: Book[], total: number }>) => {
      state[action.payload.tabName] = {
        books: action.payload.books,
        total: action.payload.total,
      };
    },
    changeReactionFilter: (state, action: PayloadAction<GetMyBooksReactionParam>) => {
      state.reactionFilter = action.payload;
    },
    clearViewedBooks: (state) => {
      state.viewed = {
        total: 0,
        books: [],
      };
    },
  },
});

export const {
  actions: myBooksActions,
  reducer: myBooksReducer,
} = myBooksSlice;
