import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { environments } from '~/lib/const';

const scrollTopWindow = () => {
  if (window?.scrollTo) {
    window.scrollTo({ top: 0 });
  }
};

export const ChangeScrolling = () => {
  const router = useRouter();

  useEffect(() => {
    if (environments.isClient) {
      window.history.scrollRestoration = 'manual';

      router.events.on('routeChangeComplete', scrollTopWindow);

      return () => {
        router.events.off('routeChangeComplete', scrollTopWindow);
      };
    }
  }, [environments.isClient]);

  return <></>;
};
