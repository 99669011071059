/* eslint-disable max-len,no-underscore-dangle */
import Script from 'next/script';
import React from 'react';

import { BookIdType } from '~/api/book/bookApiTypes';
import { isLoggedInSelector } from '~/feature/user/isLoggedInSelector';
import { userSelector } from '~/feature/user/user.selector';
import { GraphqlBook } from '~/graphql/books/factory/book/BookFactoryTypes';
import { environments } from '~/lib/const';
import { useAppSelector } from '~/store';

export const VkMetrikaInit = () => {
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const { user } = useAppSelector(userSelector);

  if (!environments.isProduction) {
    return null;
  }

  return (
    <Script
      async
      defer
      strategy="lazyOnload"
      dangerouslySetInnerHTML={{
        __html: `
          var _tmr = window._tmr || (window._tmr = []);
    _tmr.push({
      id: '3266273', type: 'pageView', start: (new Date()).getTime(), pid: ${isLoggedIn ? user.id : undefined},
    });
    (function (d, w, id) {
      if (d.getElementById(id)) return;
      const ts = d.createElement('script'); ts.type = 'text/javascript'; ts.async = true; ts.id = id;
      ts.src = 'https://top-fwz1.mail.ru/js/code.js';
      const f = function () { const s = d.getElementsByTagName('script')[0]; s.parentNode.insertBefore(ts, s); };
      if (w.opera == '[object Opera]') { d.addEventListener('DOMContentLoaded', f, false); } else { f(); }
    }(document, window, 'tmr-code'));`,
      }}
    />
  );
};

export const trackingRegisterUserInVkMetrika = () => {
  if ((window as any)?._tmr) {
    (window as any)?._tmr.push({
      type: 'reachGoal', id: 3266273, value: 1, goal: 'register',
    });
  }
};

export const trackingAuthorizationUserInVkMetrika = () => {
  if ((window as any)?._tmr) {
    (window as any)?._tmr.push({
      type: 'reachGoal', id: 3266273, goal: 'user-authorization',
    });
  }
};

export const trackingBuyBookInVkMetrika = (book: { price: number; id: BookIdType; name: string }) => {
  if ((window as any)?._tmr) {
    try {
      (window as any)?._tmr.push({
        type: 'reachGoal', id: 3266273, value: book?.price, goal: 'bookpurchase', params: { product_id: book.id },
      });
    } catch (e) {
      console.error(`error tracking buy book, book params: book.id=${book?.id}, book.name=${book?.name} reason:`, e);
    }
  }
};

export const trackingBuyBookGenreInVkMetrika = (genreSlug: string) => {
  if ((window as any)?._tmr) {
    try {
      (window as any)?._tmr.push({
        type: 'reachGoal', id: 3266273, value: 40, goal: `buy-${genreSlug}`,
      });
    } catch (e) {
      console.error(`error tracking buy book genre, book params: genre=${genreSlug} reason:`, e);
    }
  }
};

export const trackingBuyAudioBookInVkMetrika = (book: GraphqlBook) => {
  if ((window as any)?._tmr) {
    try {
      (window as any)?._tmr.push({
        type: 'reachGoal', id: 3266273, value: book?.audiobook?.price, goal: 'audiopurchase', params: { product_id: book.id },
      });
    } catch (e) {
      console.error(`error tracking buy audiobook, book params: book.id=${book?.id}, book.name=${book?.name} reason:`, e);
    }
  }
};

const TRACKING_GENRE_LOCAL_STORAGE_NAME = 'tracking_genre_in_vk';

type TrackingGenre = {
  name: string;
  count: number;
};

export const trackingShowGenreInVkMetrika = (genreSlug: string) => {
  if ((window as any)?._tmr) {
    try {
      let trackingGenres: Array<TrackingGenre> = JSON.parse(localStorage.getItem(TRACKING_GENRE_LOCAL_STORAGE_NAME)) ?? [];
      const currentGenreCounter = trackingGenres.find((genre) => genre.name === genreSlug);

      if (currentGenreCounter) {
        currentGenreCounter.count += 1;

        if (currentGenreCounter.count === 3) {
          (window as any)?._tmr.push({
            type: 'reachGoal', id: 3266273, value: 5, goal: genreSlug,
          });
          currentGenreCounter.count = 0;
        }

        trackingGenres = trackingGenres.filter((genre) => genre.name !== genreSlug);
        if (currentGenreCounter.count !== 0) {
          trackingGenres.push(currentGenreCounter);
        }
      } else {
        const newTrackingGenre = {
          name: genreSlug,
          count: 1,
        };

        trackingGenres.push(newTrackingGenre);
      }

      localStorage.setItem(TRACKING_GENRE_LOCAL_STORAGE_NAME, JSON.stringify(trackingGenres));
    } catch (e) {
      console.error(`error tracking show genre, genre=${genreSlug} reason:`, e);
    }
  }
};
