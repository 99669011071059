import React from 'react';
import styled from 'styled-components';

import Modal from '~/atomic/molecula/Modal';
import { PageTitleStyle } from '~/atomic/Typography';
import { LoginForm } from '~/feature/authorization/LoginModal/LoginForm';
import { ym } from '~/feature/yandex/YandexMetrikaInit';
import { environments } from '~/lib/const';
import { lessThan } from '~/lib/mediaQuery';
import { useAppDispatch, useAppSelector } from '~/store';

import {
  AuthorizationModalState,
  openModal,
} from '../authorizationModal.slice';

export type LoginModalProps = {};

export const LoginModal: React.FC<LoginModalProps> = () => {
  const dispatch = useAppDispatch();
  const { openedModal } = useAppSelector<AuthorizationModalState>(
    (state) => state.authorizationModal,
  );

  const onRecoveryClick = () => {
    dispatch(openModal('recovery'));
  };

  const onRegisterClick = () => {
    if (environments.isProduction) ym('reachGoal', 'reg-popup');
    dispatch(openModal('register'));
  };

  const closeModal = () => {
    dispatch(openModal(''));
  };

  return (
    <SCAuthModal
      onCancel={closeModal}
      open={openedModal === 'login'}
    >
      <SCTitle>Вход в аккаунт на Bookriver</SCTitle>
      <LoginForm
        onRecoveryClick={onRecoveryClick}
        onRegisterClick={onRegisterClick}
      />
    </SCAuthModal>
  );
};

const SCTitle = styled.h2`
  ${PageTitleStyle};
  margin-bottom: 37px;
  text-align: center;
`;

export const SCAuthModal = styled(Modal)`
  max-width: 458px;
  
  ${lessThan('xs')} {
    max-width: 100vw;
    top: 0;
  }
`;
