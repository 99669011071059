import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PartnerProgramStatistics } from '~/api/book/statisticsApiTypes';

export enum ReferralLinksTimeSorted {
  Days = 'daily',
  Weeks = 'weekly',
  Mounth = 'monthly',
}

export const referralTableSliceName = 'referralTable';

export const referralTableSlice = createSlice({
  name: referralTableSliceName,
  initialState: {
    isGetPartnerProgramStatisticsLoading: false,
    partnerProgramStatistics: [] as PartnerProgramStatistics[],
    timeSorted: ReferralLinksTimeSorted.Days,
  },
  reducers: {
    partnerProgramStatistics: (state, action: PayloadAction<boolean>) => {
      state.isGetPartnerProgramStatisticsLoading = action.payload;
    },
    changeReferralLinks: (state, action: PayloadAction<{
      statistics: PartnerProgramStatistics[],
    }>) => {
      state.partnerProgramStatistics = action.payload.statistics;
    },
    changeTimeSorted: (state, action: PayloadAction<ReferralLinksTimeSorted>) => {
      state.timeSorted = action.payload;
    },
  },
});

export const { actions: referralTableActions, reducer: referralTableReducer } = referralTableSlice;
