import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Methods } from '~/api/finance/financeApiTypes';

export const buyBookSliceName = 'buyBook';

export const buyBookSlice = createSlice({
  name: buyBookSliceName,
  initialState: {
    isOpenModal: false,
    bookId: '',
    methodPayment: '' as Methods,
    returnUrl: '',
    bankCardId: 0,
    isOpenChoosePayModal: false,
    email: '',
  },
  reducers: {
    showBuyModal: (state) => {
      state.isOpenModal = true;
    },
    hideBuyModal: (state) => {
      state.isOpenModal = false;
    },
    changeBookId: (state, action: PayloadAction<string>) => {
      state.bookId = action.payload;
    },
    changeMethodPayment: (state, action: PayloadAction<Methods>) => {
      state.methodPayment = action.payload;
    },
    changeReturnUrl: (state, action: PayloadAction<string>) => {
      state.returnUrl = action.payload;
    },
    changeBankCardId: (state, action: PayloadAction<number>) => {
      state.bankCardId = action.payload;
    },
    changeEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setIsOpenChoosePayModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenChoosePayModal = action.payload;
    },
  },
});

export const {
  actions: buyBookActions,
  reducer: buyBookReducer,
} = buyBookSlice;
