import React from 'react';
import styled from 'styled-components';

import { pagesConfigStore } from '~/atomic/atom/links/pagesConfigStore';
import { NavItem, SCNavMenuIcon } from '~/atomic/organism/NavMenu';
import { SCCounter } from '~/atomic/Typography';
import { ReaderNavigationAbonnement } from '~/feature/user/navigation/ReaderNavigation/ReaderNavigationAbonnement';
import { userSelector } from '~/feature/user/user.selector';
import { useAppSelector } from '~/store';

export const ReaderNavigationLinks = () => {
  const { userCounters, user } = useAppSelector(userSelector);

  return (
    <SCReaderNavigationLinks>
      <SCList>
        <ReaderNavigationAbonnement />
        <NavItem href={pagesConfigStore.finance.url} shallow>
          <SCNavMenuIcon src="/icon/wallet.svg" />
          Личный счет
          {' '}
          {user?.balance > 0 && (
            <SCCounter>
              {`${user?.balance} ₽`}
            </SCCounter>
          )}
        </NavItem>
        <NavItem href={pagesConfigStore.myPurchases.url} shallow>
          <SCNavMenuIcon src="/icon/basket.svg" />
          Покупки
          {' '}
          {userCounters?.purchases > 0 && (
            <SCCounter>
              {userCounters?.purchases > 99 ? 99 : userCounters?.purchases}
            </SCCounter>
          )}
        </NavItem>
        <NavItem href={pagesConfigStore.subscriptions.url} shallow>
          <SCNavMenuIcon src="/icon/heart.svg" />
          Подписки и скидки
          {' '}
          {userCounters?.subscriptions > 0 && (
            <SCCounter>
              {userCounters?.subscriptions > 99 ? 99 : userCounters?.subscriptions}
            </SCCounter>
          )}
        </NavItem>
      </SCList>
    </SCReaderNavigationLinks>
  );
};

const SCReaderNavigationLinks = styled.li``;

const SCList = styled.ul`
  > * {
    border-bottom: 1px solid var(--bg-color);
    border-top: 1px solid var(--bg-color);
  }
`;
