import React, { FC } from 'react';
import styled from 'styled-components';

import { BookIdType } from '~/api/book/bookApiTypes';
import { Button } from '~/atomic/atom/Button';
import { EditBookLink } from '~/atomic/atom/links/dynamicLinks';
import { LinkStyle, TextRegStyle } from '~/atomic/Typography';
import { publishComments } from '~/feature/comments/comment.data';
import { commentsSelector, preModerationCommentsCountSelector } from '~/feature/comments/comment.selector';
import { lessThan } from '~/lib/mediaQuery';
import { useAppDispatch, useAppSelector } from '~/store';

interface PreModerationBookCommentsProps {
  bookId: BookIdType;
  targetClass: 'book' | 'post';
  targetId: string | number;
}

export const PreModerationBookComments: FC<PreModerationBookCommentsProps> = ({
  bookId,
  targetClass,
  targetId,
}) => {
  const dispatch = useAppDispatch();
  const { isPublishCommentsLoading } = useAppSelector(commentsSelector);
  const preModerationCommentsCount = useAppSelector(preModerationCommentsCountSelector);

  const publishCommentsHandler = () => {
    if (bookId && targetId && targetClass) {
      dispatch(publishComments({
        bookId,
        targetId,
        targetClass,
      }));
    }
  };

  return (
    <SCPreModerationComments>
      <Button
        onClick={publishCommentsHandler}
        isLoading={isPublishCommentsLoading}
      >
        Опубликовать комментарии (
        {preModerationCommentsCount}
        )
      </Button>
      <SCStatus>
        Включен
        {' '}
        <EditBookLink
          bookId={bookId}
          query={{ tab: 2 }}
        >
          <SCLink>
            режим премодерации
          </SCLink>
        </EditBookLink>
      </SCStatus>
    </SCPreModerationComments>
  );
};

const SCPreModerationComments = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 25px;

  ${lessThan('sm')} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SCStatus = styled.span`
  ${TextRegStyle};
  color: var(--gray-color);
  display: flex;
  align-items: center;
  margin-left: auto;

  ${lessThan('sm')} {
    margin-left: 0;
    margin-top: 5px;
  }
`;

const SCLink = styled.a`
  ${LinkStyle};
  text-decoration: underline;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  line-height: inherit;
  padding-left: 3px;
`;
