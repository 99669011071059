import dynamic from 'next/dynamic';

export const Menu = dynamic(
  () => import('antd/lib/menu'),
  { ssr: false },
);

export const MenuItem = dynamic(
  () => import('antd/lib/menu/MenuItem'),
  { ssr: false },
);
