import React, { useEffect } from 'react';

import { baseFrontServerUrl } from '~/api/provider/provider.config';
import { getAuthTokenFromClient } from '~/feature/authorization/getAuthToken';
import { me } from '~/feature/user/user.data';
import { userSelector } from '~/feature/user/user.selector';
import { userActions } from '~/feature/user/user.slice';
import { environments } from '~/lib/const';
import { useAppDispatch, useAppSelector } from '~/store';

export const CheckAuth = () => {
  const { user } = useAppSelector(userSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (environments.isClient) {
      const token = getAuthTokenFromClient();

      if (!user?.id && token) {
        dispatch(me());
      }
    }
  }, []);

  return (
    <>
    </>
  );
};

export const CheckAuthOnErrorPage = () => {
  const { user } = useAppSelector(userSelector);
  const dispatch = useAppDispatch();

  const check = async () => {
    const response = await fetch(`${baseFrontServerUrl}/api/auth/getToken`);
    const data = await response.json();

    if (!user?.id && data?.token) {
      dispatch(userActions.changeToken(data?.token));
      dispatch(me());
    }
  };

  useEffect(() => {
    check();
  }, []);

  return (
    <>
    </>
  );
};
