import React, { FC } from 'react';

import { DefaultLayout } from '~/atomic/layout/DefaultLayout';
import { Footer } from '~/atomic/organism/Footer';
import { Header } from '~/atomic/organism/Header/Header';
import { WithChildren } from '~/lib/WithChildren';

export const SiteTemplate: FC<WithChildren> = ({ children }) => {
  return (
    <DefaultLayout
      footer={<Footer />}
      header={<Header />}
    >
      { children }
    </DefaultLayout>
  );
};
