import React, { useState } from 'react';
import styled from 'styled-components';

import { MiniatureSizesEnum } from '~/api/book/bookApiTypes';
import { Avatar } from '~/atomic/atom/Avatar/Avatar';
import { SkeletonAvatar, SkeletonInput } from '~/atomic/atom/Skeleton';
import { headerSelector } from '~/atomic/organism/Header/header.selector';
import { headerActions } from '~/atomic/organism/Header/header.slice';
import { TextBoldStyle } from '~/atomic/Typography';
import { openLoginModal } from '~/feature/authorization/auth.data';
import { authSelector } from '~/feature/authorization/auth.selector';
import { LoginModal } from '~/feature/authorization/LoginModal/LoginModal';
import { RecoveryModal } from '~/feature/authorization/RecoveryModal/RecoveryModal';
import { RegisterModal } from '~/feature/authorization/RegisterModal/RegisterModal';
import { SaveUserLoginModal } from '~/feature/authorization/SaveUserLoginModal/SaveUserLoginModal';
import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import { isLoggedInSelector } from '~/feature/user/isLoggedInSelector';
import { userSelector } from '~/feature/user/user.selector';
import { ym } from '~/feature/yandex/YandexMetrikaInit';
import { useOutsideClick } from '~/lib/hooks/useOutsideClick';
import { lessThan } from '~/lib/mediaQuery';
import { useAppDispatch, useAppSelector } from '~/store';

export const UserInfo = () => {
  const dispatch = useAppDispatch();
  const [isVisible, setIsVisible] = useState(false);

  const { isGetMeLoading, user } = useAppSelector(userSelector);
  const { isMobileUserInfoOpen } = useAppSelector(headerSelector);
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const isMobileSize = useAppSelector(isMobileSizeSelector);
  const avatarSize = isMobileSize ? 36 : 44;

  const { loading: authLoading } = useAppSelector(authSelector);

  const isShowLoader = isGetMeLoading || authLoading?.providerCallback;

  const ref = useOutsideClick({
    callback: () => {
      setIsVisible(false);
      dispatch(headerActions.setIsMobileUserInfoOpen(false));
    },
    isActive: isVisible || isMobileUserInfoOpen,
  });

  const onLoginClick = async () => {
    dispatch(openLoginModal());
    ym('reachGoal', 'header:log-in');
  };

  if (isShowLoader) {
    return (
      <SCUserInfo>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <SkeletonAvatar active size={44} />
          <SkeletonInput
            active
            size="small"
            style={{
              width: '10px',
              marginLeft: '12px',
            }}
          />
        </div>
      </SCUserInfo>
    );
  }

  return (
    <SCUserInfo>
      {isLoggedIn ? (
        <div
          ref={ref}
          onClick={() => {
            dispatch(headerActions.setIsHamburgerActive(false));
            dispatch(headerActions.setIsMobileUserInfoOpen(true));
          }}
        >
          <Avatar
            imageSize={avatarSize}
            avatarSize={MiniatureSizesEnum.L}
            avatarImages={user?.avatarImages}
            userName={user?.name}
            alt={user?.name}
          />
        </div>
      ) : null}
      {!isLoggedIn ? (
        <>
          <SCAvatarWrapper onClick={onLoginClick}>
            <Avatar
              imageSize={avatarSize}
              avatarSize={MiniatureSizesEnum.L}
              src="/icon/components/Header/default-user-icon.svg"
              alt="иконка пользователя"
            />
            <SCLabel>
              Войти
            </SCLabel>
          </SCAvatarWrapper>
          <RegisterModal />
          <RecoveryModal />
          <LoginModal />
          <SaveUserLoginModal />
        </>
      ) : null}
    </SCUserInfo>
  );
};

export const SCArrow = styled.img`
  margin-left: 10px;
  color: #242d33;
  position: relative;
  top: -2px;
  width: 12px;
  height: 12px;
`;

const SCLabel = styled.span`
  ${TextBoldStyle};
  color: var(--primary-color);
  padding-left: 15px;
`;

export const SCAvatarWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-grow: 0;
  transition: 0.3s;

  .ant-avatar {
    transition: 0.3s;
  }

  &:hover {
    filter: brightness(1.03);
  }

  ${lessThan('xs')} {
    ${SCLabel} {
      display: none;
    }
  }
`;

const SCUserInfo = styled.div`
  margin-left: 20px;
  cursor: pointer;
  flex-shrink: 0;

  ${lessThan('sm')} {
    margin-left: 0;
  }
`;
