import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { LogoIcon } from '~/atomic/atom/icon/LogoIcon';
import { pagesConfigStore } from '~/atomic/atom/links/pagesConfigStore';
import { HomeLink } from '~/atomic/atom/links/staticLinks';
import { environments } from '~/lib/const';
import { lessThan } from '~/lib/mediaQuery';
import animatedLogo from '~/public/animations/logo/bookriver.json';

let animLogo = null;

export const HeaderLogo = () => {
  const [isHideLogo, setIsHideLogo] = useState(false);

  let isLandingPage = false;

  useEffect(() => {
    let scrolling = 0;

    const scrollHandler = () => {
      const currentScrollY = window.pageYOffset;
      const isScrollingTop = currentScrollY < scrolling;
      scrolling = currentScrollY;

      if (isScrollingTop && (currentScrollY <= 0)) {
        if (animLogo && !isLandingPage) {
          animLogo.goToAndPlay(1);
          setIsHideLogo(true);
        }
      }
    };

    if (environments.isClient) {
      window.addEventListener('scroll', scrollHandler, { passive: true });

      return () => {
        window.removeEventListener('scroll', scrollHandler);
      };
    }
  }, []);

  if (environments.isClient) {
    isLandingPage = window.location.pathname.startsWith(pagesConfigStore.about.url)
      || window.location.pathname.startsWith(pagesConfigStore.imAuthor.url);
  }

  useEffect(() => {
    let handleLoopComplete;

    if (environments.isClient) {
      if (!isLandingPage) {
        import('lottie-web')
          .then((module) => {
            const lottie = module.default;

            animLogo = lottie.loadAnimation({
              container: document.getElementById('bm-logo-animated'),
              renderer: 'svg',
              loop: true,
              autoplay: false,
              animationData: animatedLogo,
            });

            handleLoopComplete = () => {
              animLogo.stop();
              setIsHideLogo(false);
            };

            animLogo.addEventListener('loopComplete', handleLoopComplete);
          })
          .catch((error) => {
            console.error('inport lottle error cause = ', error);
          });
      }
    }

    return () => {
      if (animLogo) {
        animLogo.removeEventListener('loopComplete', handleLoopComplete);
      }
    };
  }, [isLandingPage]);

  return (
    <SCHeaderLogo>
      <HomeLink shallow>
        <SCLogoLink onMouseLeave={() => {
          if (animLogo && !isLandingPage) {
            animLogo.goToAndPlay(1);
            setIsHideLogo(true);
          }
        }}
        >
          {!isHideLogo && (
            <SCLogoIcon />
          )}
          <SCAnimatedLogo
            data-is-hidden={String(!isHideLogo)}
            id="bm-logo-animated"
          />
        </SCLogoLink>
      </HomeLink>
    </SCHeaderLogo>
  );
};

const SCHeaderLogo = styled.div``;

const SCAnimatedLogo = styled.div`
  display: block;
  width: 162px;
  height: 41px;
  overflow: hidden;
  
  &[data-is-hidden="true"] {
    display: none;
  }

  ${lessThan('sm')} {
    width: 120px;
  }
`;

const SCLogoIcon = styled(LogoIcon)`
  width: 162px;
  
  ${lessThan('sm')} {
    width: 120px;
  }
`;

const SCLogoLink = styled.a`
  display: flex;
`;
