import { CancelToken } from 'axios';

import {
  AddCoverParams,
  AddCoverResponse,
  AddPropertiesFields,
  AddPropertiesParams,
  Book,
  BookLog,
  BookProperties,
  ChangeBookCycleParams,
  ChangeCompletenessStatusParams,
  ChangeIndexInCycleParams,
  ChangeNameParams,
  ChangePriceParams,
  ChangePublishStatusParams,
  CreateBookParams,
  CreateBookResult,
  DeleteBookParams,
  DeleteCoverParams,
  ExportBookParams,
  GetBookBySlugParams,
  GetBookListParams,
  GetBookLogsParams,
  GetBookParams,
  GetGenresParams,
  GetMyBooksParams,
  GetNextBookInCycleParams,
  GetPropertiesParams,
  GetSimilarBooksParams,
  ImportBookParams,
  ImportBookRejectValue,
} from '~/api/book/bookApiTypes';
import { BookTag } from '~/api/book/bookTagApiTypes';
import { Genre } from '~/api/book/genreApiTypes';
import {
  baseURL,
  provider,
} from '~/api/provider/provider.config';
import { handleRejectedRequest } from '~/api/provider/providerErrors';
import {
  ApiResponse, Paginated, PaginatedResponse, RejectedRequest,
} from '~/api/provider/providerTypes';

const namespace = Symbol('namespace');

export const bookApi = {
  [namespace]: '/v1/books',

  async create(data: CreateBookParams) {
    const formData = new FormData();

    formData.append('type', data.type);
    formData.append('name', data.name);
    if (data?.file) formData.append('file', data.file);
    if (data?.cycleId) formData.append('cycleId', data.cycleId);

    try {
      const response = await provider.post<
      ApiResponse<CreateBookResult | Book, RejectedRequest<CreateBookParams>>
      >(`${this[namespace]}/book`, formData);

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async changeName(data: ChangeNameParams) {
    try {
      const response = await provider.put<
      ApiResponse<void, RejectedRequest<Pick<ChangeNameParams, 'name'>>>
      >(`${this[namespace]}/book/${data.id}/name`, { name: data.name });

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async changeCycle(data: ChangeBookCycleParams) {
    try {
      const response = await provider.put<
      ApiResponse<void, RejectedRequest<void>>
      >(`${this[namespace]}/book/${data.bookId}/cycle`, {
        cycle: data.cycleId,
        numInCycle: data.numInCycle,
      });

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async changeCompletenessStatus(data: ChangeCompletenessStatusParams) {
    try {
      const response = await provider.put<
      ApiResponse<void, RejectedRequest<Omit<ChangeCompletenessStatusParams, 'id'>>>
      >(
        `${this[namespace]}/book/${data.id}/complete`,
        { statusComplete: data.statusComplete },
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async changePublishStatus(data: ChangePublishStatusParams) {
    try {
      const response = await provider.put<
      ApiResponse<void, RejectedRequest<Omit<ChangePublishStatusParams, 'id'>>>
      >(
        `${this[namespace]}/book/${data.id}/publish`,
        { statusPublish: data.statusPublish },
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async changePrice(data: ChangePriceParams) {
    const { bookId, ...otherData } = data;
    try {
      const response = await provider.put<
      ApiResponse<void, RejectedRequest<Omit<ChangePriceParams, 'id'>>>
      >(`${this[namespace]}/book/${bookId}/price`, otherData);

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async getBook(data: GetBookParams) {
    try {
      const response = await provider.get<
      ApiResponse<Book, RejectedRequest<void>>
      >(
        `${this[namespace]}/book/${data.id}`,
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async getBookBySlug(data: GetBookBySlugParams) {
    try {
      const response = await provider.get<
      ApiResponse<Book, RejectedRequest<void>>
      >(
        `${this[namespace]}/bookBySlug/${data.slug}`,
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async deleteBook(data: DeleteBookParams) {
    try {
      const response = await provider.delete<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/book/${data.id}`,
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async getMyBooks(data: GetMyBooksParams) {
    try {
      const response = await provider.get<
      PaginatedResponse<Book[], RejectedRequest<void>>
      >(`${this[namespace]}/me`, { params: data });
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async getGenres(data: GetGenresParams) {
    try {
      const response = await provider.get<
      ApiResponse<Genre, RejectedRequest<void>>
      >(
        `${this[namespace]}/book/${data.id}/genres`,
      );

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  uploadCoverUrl(bookId) {
    return `${baseURL}${this[namespace]}/book/${bookId}/cover`;
  },

  async addCover(data: AddCoverParams) {
    try {
      const formData = new FormData();
      formData.append('image', data.image);
      const config = { headers: { 'content-type': 'multipart/form-data' } };

      const response = await provider.post<
      ApiResponse<AddCoverResponse, RejectedRequest<void>>
      >(
        `${this[namespace]}/book/${data.id}/cover`,
        formData,
        config,
      );

      if ('data' in response.data) {
        return response.data;
      }
      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async deleteCover(data: DeleteCoverParams) {
    try {
      const response = await provider.delete<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/book/${data.id}/cover`,
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async import(data: ImportBookParams, cancelToken: CancelToken) {
    try {
      const formData = new FormData();
      formData.append('file', data.file);
      const config = {
        headers: { 'content-type': 'multipart/form-data' },
        cancelToken,
      };
      const response = await provider.post<
      ApiResponse<void, RejectedRequest<ImportBookRejectValue>>
      >(
        `${this[namespace]}/book/${data.id}/import`,
        formData,
        config,
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async export(data: ExportBookParams) {
    try {
      const response = await provider.get<ArrayBuffer>(`${this[namespace]}/book/${data.bookId}/export`, {
        params: { format: data.format },
        responseType: 'arraybuffer',
      });

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async getLogs(data: GetBookLogsParams) {
    try {
      const response = await provider.get<
      Paginated<BookLog[]> | RejectedRequest<void>
      >(
        `${this[namespace]}/book/${data.id}/logs`,
        { params: { page: data.page } },
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async getProperties(data: GetPropertiesParams) {
    try {
      const response = await provider.get<
      ApiResponse<BookProperties, RejectedRequest<void>>
      >(
        `${this[namespace]}/book/${data.id}/properties`,
      );
      if ('data' in response) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async addProperties(data: AddPropertiesParams) {
    const { id, ...mewData } = data;
    try {
      const response = await provider.post<
      ApiResponse<BookProperties, RejectedRequest<AddPropertiesFields>>
      >(`${this[namespace]}/book/${id}/properties`, mewData);

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<AddPropertiesFields>(error);
      return handleError;
    }
  },

  async changeIndexInCycle(data: ChangeIndexInCycleParams) {
    try {
      const response = await provider.put<
      ApiResponse<void, RejectedRequest<Omit<ChangeIndexInCycleParams, 'id'>>>
      >(
        `${this[namespace]}/book/${data.bookId}/numInCycle`,
        { numInCycle: data.numInCycle },
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getBookList(data: GetBookListParams) {
    try {
      const response = await provider.get<
      Paginated<Book[]> & { popularTags: BookTag[] } | RejectedRequest<void>
      >(
        `${this[namespace]}`,
        {
          params: data,
          headers: {
            Authorization: data && data?.token
              ? `Bearer ${data?.token}`
              : provider.defaults.headers.common.Authorization,
          },
        },
      );

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getNextBook(data: GetNextBookInCycleParams) {
    try {
      const response = await provider.get<
      Paginated<Book> | RejectedRequest<void>
      >(`${this[namespace]}/book/${data.bookId}/nextInCycle`);

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getSimilarBooks(data: GetSimilarBooksParams) {
    const { bookId, ...otherData } = data;
    try {
      const response = await provider.get<
      ApiResponse<Book[], RejectedRequest<void>>
      >(
        `${this[namespace]}/book/${bookId}/similar`,
        { params: otherData },
      );

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
};
