import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from '~/api/account/accountApiTypes';

export const authorSliceName = 'author';

export const authorSlice = createSlice({
  name: authorSliceName,
  initialState: {
    currentAuthor: {} as User,
    subscriptionLoading: false,
    isSubscription: false,
  },
  reducers: {
    changeCurrentAuthorHeroImage: (state, action: PayloadAction<string>) => {
      state.currentAuthor.heroImage = action.payload;
    },
    changeCurrentAuthor: (state, action: PayloadAction<User>) => {
      state.currentAuthor = action.payload;
    },
    setSubscriptionLoading: (state, action: PayloadAction<boolean>) => {
      state.subscriptionLoading = action.payload;
    },
    changeIsSubscription: (state, action: PayloadAction<boolean>) => {
      state.isSubscription = action.payload;
    },
  },
});

export const { actions: authorActions, reducer: authorReducer } = authorSlice;
