import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Book } from '~/api/book/bookApiTypes';
import { AuthorWithStatistic } from '~/api/search/searchApiTypes';

export const searchCatalogSliceName = 'searchCatalog';

const searchCatalogSlice = createSlice({
  name: searchCatalogSliceName,
  initialState: {
    authors: {
      authors: [] as AuthorWithStatistic[],
      total: 0,
    },
    books: {
      books: [] as Book[],
      total: 0,
    },
  },
  reducers: {
    changeBooks: (state, action: PayloadAction<Book[]>) => {
      state.books.books = action.payload;
    },
    changeAuthors: (state, action: PayloadAction<AuthorWithStatistic[]>) => {
      state.authors.authors = action.payload;
    },
    changeBooksTotal: (state, action: PayloadAction<number>) => {
      state.books.total = action.payload;
    },
    changeAuthorsTotal: (state, action: PayloadAction<number>) => {
      state.authors.total = action.payload;
    },
  },
});

export const {
  actions: searchCatalogActions,
  reducer: searchCatalogReducer,
} = searchCatalogSlice;
