import { MiniatureFormatEnum, MiniatureSizesEnum } from '~/api/book/bookApiTypes';

export interface DefaultImage {
  url: string;
  format: MiniatureFormatEnum;
  sizeType: MiniatureSizesEnum;
}

export class ImagesFactory {
  static create(images: Array<{ format: string; sizeType: string; url: string; }>): Array<DefaultImage> {
    if (!images && !images?.length) return null;

    return images?.map((coverImage) => ({
      url: coverImage.url,
      format: coverImage.format as MiniatureFormatEnum,
      sizeType: coverImage.sizeType as MiniatureSizesEnum,
    }));
  }
}
