import styled from 'styled-components';

import { Button } from '~/atomic/atom/Button';
import { CommentsIcon } from '~/atomic/atom/icon/CommentsIcon';
import { TrashIcon } from '~/atomic/atom/icon/TrashIcon';
import { PaperStyle } from '~/atomic/atom/PaperStyle';
import { PageTitleStyle, TextRegStyle } from '~/atomic/Typography';
import { lessThan } from '~/lib/mediaQuery';

export const SCPostCard = styled.article`
  ${PaperStyle};
  border: none;
  width: 100%;
  
  &:not(:last-child) {
    margin-bottom: 24px;
  }
`;

export const SCMainPostCard = styled.div`
  padding: 20px 16px 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SCHeaderPostCard = styled.header`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 12px;
  margin-left: -14px;
  margin-top: -8px;

  && {
    > * {
      margin-top: 8px;
      margin-left: 14px;
    }
  }
`;

export const SCTitlePostCardLink = styled.a`
  ${PageTitleStyle};
  width: 100%;
`;

export const SCTitlePostCard = styled.h2`
  ${PageTitleStyle};
  width: 100%;
`;

export const SCDatePostCard = styled.span`
  ${TextRegStyle};
`;

export const SCTextPostCard = styled.div`
  --font-size: 14px;
  --line-height: 20px;

  color: var(--black-color);

  line-height: var(--line-height);
  font-size: var(--font-size);

  height: var(--font-size) * var(--line-height) * 3; /* Fallback for non-webkit */

  * {
    text-align: left !important;
    ${TextRegStyle};
    font-weight: normal !important;
    color: var(--black-color);
  }

  p, h1, h2, h3, h4, h5 {
    margin-bottom: 0;
  }
  
  img {
    max-width: 100% !important;
    height: auto !important;
  }
`;

export const SCTextPost = styled.div`
  position: relative;

  * {
    font-family: var(--second-font);
    font-size: 14px;
    line-height: 22px;
    color: var(--black-color);
  }

  & p:after {
    content: "";
    clear: both;
    display: table;
  }

  img {
    max-width: 100% !important;
    height: auto !important;
  }
`;

export const SCBottomPostCard = styled.div`
  background: var(--gray6-color);
  padding: 7px 14px 7px 20px;
  display: flex;
  align-items: center;
  
  ${lessThan('sm')} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const SCStatisticsPostCard = styled.ul`
  display: flex;
  align-items: center;
`;

export const SCStatisticsItemPostCard = styled.li`
  font-weight: bold;
  font-size: 10px;
  line-height: 20px;
  text-transform: uppercase;
  color: var(--gray-color);
  font-family: var(--second-font);
  display: flex;
  align-items: center;
  
  &:not(:last-child) {
    margin-right: 30px;
  }
`;

export const SCStatisticsCountPostCard = styled.span`
  margin-left: 6px;
  font-weight: 500;
  font-size: 13px;
  line-height: 16px;
  font-family: var(--second-font);
  color: var(--black-color);
`;

export const SCControlsPostCard = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
  
  > * {
    &:not(:last-child) {
      margin-right: 12px;
    }
  }
  
  ${lessThan('sm')} {
    align-items: center;
    margin-left: 0;
    margin-top: 12px;
  }
`;

export const SCEditPostCardButton = styled(Button)` && {
    border: 1px solid var(--gray3-color);
  }
`;

export const SCDeletePostCardIcon = styled(TrashIcon)`
  font-size: 20px;
  color: var(--gray-color);
  transition: color 0.3s ease; 
`;

export const SCDeletePostCard = styled.div`
  border: 1px solid var(--gray3-color);
  box-sizing: border-box;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: 36px;
  transition-property: border;
  transition: 0.3s ease;
  
  &:hover {
    cursor: pointer;
    border: 1px solid var(--black-color);
    
    ${SCDeletePostCardIcon} {
      fill: var(--primary-color);
    }
  }
`;

export const SCDeletePostCardLoader = styled.span`
  svg {
    width: 20px;
    height: 20px; 
  }
`;

export const SCAuthorPostCard = styled.a`
  ${TextRegStyle};
  color: var(--gray-color);
  transition: color 0.3s ease;

  &:hover {
    color: var(--primary-hover-color);
  }
`;

export const SCPostCommentsIcon = styled(CommentsIcon)`
  font-size: 18px;
  color: var(--gray-color);
`;

export const SCPostButton = styled(Button)`
  margin-top: 8px;
`;

export const SCPostCount = styled.span`
  ${TextRegStyle};
  color: var(--gray-color);
`;

export const SCPostCommentsWrapper = styled.span`
  display: flex;
  align-items: center;
  
  > *:not(:last-child) {
    margin-right: 8px;
  }
`;
