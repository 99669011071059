import React from 'react';
import styled from 'styled-components';

import {
  BookTypesText, CompleteStatusEnum, PublishStatusEnum,
} from '~/api/book/bookApiTypes';
import { ChapterStatus } from '~/api/book/chapterApiTypes';
import { Status, StatusProps } from '~/atomic/atom/Status';

interface BookStatusProps extends StatusProps {
  type: PublishStatusEnum | CompleteStatusEnum | ChapterStatus | 'theme' | string;
  text?: string;
}

export const BookStatus: React.FC<BookStatusProps> = ({
  type, text, ...props
}) => {
  return (
    <SCStatus
      {...props}
      data-type={type}
    >
      {text || BookTypesText[type] || 'статус неизвестен'}
    </SCStatus>
  );
};

export const SCStatus = styled(Status)`
  padding: 5px 10px;
  background-color: var(--yellow-color);
  background-position: 10px center;

  && {
    &[data-type="reference"] {
      background-color: var(--blue-color);
    }

    &[data-type="theme"] {
      background-color: var(--blue-color);
    }

    &[data-type="writing"] {
      background-image: url("/icon/components/Status/pencil.svg");
      background-color: var(--yellow-color);
      padding-right: 10px;
      padding-left: 25px;
    }

    &[data-type="used"], &[data-type="not_published"] {
      background-color: var(--yellow-color);
    }

    &[data-type="draft"] {
      background-image: url("/icon/components/Status/pencil.svg");
      background-color: var(--yellow-color);
      padding-right: 10px;
      padding-left: 25px;
    }

    &[data-type="freeze"] {
      background-image: url("/icon/freeze.svg");
      background-color: #FCE1E8;
      padding-right: 10px;
      padding-left: 25px;
    }

    &[data-type="expired"] {
      background-color: #FCE1E8;
    }

    &[data-type="temp_not_available"] {
      background-image: url('/icon/exclamation.svg');
      background-color: #FCE1E8;
      padding-right: 10px;
      padding-left: 25px;
    }

    &[data-type="complete"] {
      background-color: var(--green-color);
      background-image: url("/icon/components/Status/ok.svg");
      padding-right: 10px;
      padding-left: 25px;
    }

    &[data-type="published"] {
      background-color: var(--green-color);
      background-image: url("/icon/components/Status/ok.svg");
      padding-right: 10px;
      padding-left: 25px;
    }
  }
`;
