import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '~/store';

import { ResultModalsType } from './paymentResult.slice';

export const paymentResultSelector = (state: RootState) => state.paymentResult;

export const resultModalSelectorFactory = (name: ResultModalsType) => createSelector(
  paymentResultSelector,
  ({ modalOpen }) => modalOpen[name] ?? false,
);
