import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const postEditSliceName = 'postEdit';

const postEditSlice = createSlice({
  name: postEditSliceName,
  initialState: {
    isCreatePostLoading: false,
    isSavePostLoading: false,
    isNewPost: false,
  },
  reducers: {
    setIsCreatePostLoading: (state, action: PayloadAction<boolean>) => {
      state.isCreatePostLoading = action.payload;
    },
    setIsSavePostLoading: (state, action: PayloadAction<boolean>) => {
      state.isSavePostLoading = action.payload;
    },
    changeIsNewPost: (state, action: PayloadAction<boolean>) => {
      state.isNewPost = action.payload;
    },
  },
});

export const { actions: postEditActions, reducer: postEditReducer } = postEditSlice;
