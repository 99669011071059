import React, { useState } from 'react';
import styled from 'styled-components';

import { repeatConfirmationEmailToken } from '~/feature/user/user.data';
import { userSelector } from '~/feature/user/user.selector';
import { useAppDispatch, useAppSelector } from '~/store';

export const Alert = () => {
  const {
    user,
    user: {
      emailVerified: isEmailVerified, banned: isBanned, email,
    },
  } = useAppSelector(userSelector);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const repeatConfirmationEmailHandler = async () => {
    if (!loading) {
      setLoading(true);
      await dispatch(repeatConfirmationEmailToken());
      setLoading(false);
    }
  };

  if (!user?.id) { return null; }

  return (
    <>
      {isBanned && (
        <Error>
          <RedText>Аккаунт заблокирован.</RedText>
          {' Пожалуйста, обратитесь в службу поддержки '}
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://support.bookriver.ru"
          >
            support.bookriver.ru
          </a>
        </Error>
      )}
      {!isEmailVerified && (
        <Warning>
          Пожалуйста, подтвердите аккаунт. Инструкция отправлена вам на почту по адресу
          {' '}
          <b>
            {email}
            {' '}
          </b>
          (
          <SCLink
            onClick={repeatConfirmationEmailHandler}
          >
            повторить
          </SCLink>
          )
        </Warning>
      )}
    </>
  );
};

export const SCAlert = styled.div`
  position: sticky;
  top: 0;
  width: 100%;
  min-height: 44px;
  padding: 6px;
  font-size: 14px;
  line-height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: var(--black-color);
  z-index: 7;
  flex-wrap: wrap;
  white-space: pre-wrap;
  text-align: center;
`;

const Warning = styled(SCAlert)`
  background: var(--warning-color);
`;

const Error = styled(SCAlert)`
  background: #f8edf0;
`;

const RedText = styled.span`
  color: var(--error-color);
`;

const SCLink = styled.span`
  color: var(--primary-color);
  transition-property: color;
  transition: 0.3s;
  cursor: pointer;

  &:hover {
    color: var(--primary-hover-color);
  }
`;
