import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { NotificationChannels } from '~/api/account/notificationsApiTypes';

export const settingsInputsSliceName = 'settingsInputs';

export const settingsInputsSlice = createSlice({
  name: settingsInputsSliceName,
  initialState: {
    isDeleteAccountModalOpen: false,
    availableChannels: {} as NotificationChannels,
  },
  reducers: {
    changeIsDeleteAccountModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isDeleteAccountModalOpen = action.payload;
    },
    changeAvailableChannels: (state, action: PayloadAction<NotificationChannels>) => {
      state.availableChannels = action.payload;
    },
  },
});

export const {
  actions: settingsInputsActions,
  reducer: settingsInputsReducer,
} = settingsInputsSlice;
