import dynamic from 'next/dynamic';
import React, { FC, ReactNode } from 'react';

import {
  SEOPageTemplate,
  SEOPageTemplateProps,
} from '~/atomic/organism/PageTemplate/SEOPageTemplate';
import { CheckAuth } from '~/feature/authorization/CheckAuth';
import { TopProgressBar } from '~/feature/topProgressBar/TopProgressBar';

const GeneratePartnerOrReferralLink = dynamic(() => import('~/feature/referralLink/GeneratePartnerOrReferralLink')
  .then((mod) => mod.GeneratePartnerOrReferralLink), { ssr: false });

interface PublicPageTemplateProps {
  seoProps: SEOPageTemplateProps;
  content: ReactNode;
  isShowGenerateLinkBadger?: boolean;
}

export const PublicPageTemplate: FC<PublicPageTemplateProps> = ({
  seoProps,
  content,
  isShowGenerateLinkBadger = true,
}) => {
  return (
    <>
      <SEOPageTemplate {...seoProps} />
      {content}
      <CheckAuth />
      <TopProgressBar />
      {isShowGenerateLinkBadger && <GeneratePartnerOrReferralLink />}
    </>
  );
};
