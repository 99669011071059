import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import { ProviderEnum, RegisterRequestParams } from '~/api/auth/authApiTypes';
import { RejectedRequest } from '~/api/provider/providerTypes';
import { Button, SCButton } from '~/atomic/atom/Button';
import { AppleIcon } from '~/atomic/atom/icon/AppleIcon';
import { GoogleIcon } from '~/atomic/atom/icon/GoogleIcon';
import { MailIcon } from '~/atomic/atom/icon/MailIcon';
import { VkIcon } from '~/atomic/atom/icon/VkIcon';
import { YandexIcon } from '~/atomic/atom/icon/YandexIcon';
import { Input } from '~/atomic/atom/Input';
import {
  PrivacyAgreementLink, RoyaltyLicenceLink,
  UserPolicyLink,
} from '~/atomic/atom/links/staticLinks';
import { Field } from '~/atomic/molecula/Field';
import { PageTitleStyle, TextBoldStyle } from '~/atomic/Typography';
import { getProviderLoginURL, register } from '~/feature/authorization/auth.data';
import { authSelector } from '~/feature/authorization/auth.selector';
import { openModal } from '~/feature/authorization/authorizationModal.slice';
import { validatePasswordSchema } from '~/feature/validators';
import { useAppDispatch, useAppSelector } from '~/store';

interface RegisterFormProps {
  afterRegister?(): void;
  openLoginModal?(): void;
  queries?: { name: string; value: string }[];
  title?: string;
}

export const RegisterForm = ({
  queries, afterRegister, openLoginModal, title,
}: RegisterFormProps) => {
  const dispatch = useAppDispatch();
  const { loading, message } = useAppSelector(authSelector);

  const handleSocialNetworkIconClick = async (provider: ProviderEnum) => {
    await dispatch(getProviderLoginURL({ provider, queries }));
  };

  const registerForm = useFormik({
    initialValues: {
      email: '',
      password: '',
      name: '',
      gRecaptchaResponse: '',
    },
    onSubmit: ({
      email, password, name, gRecaptchaResponse,
    }, formikHelpers) => {
      dispatch(register({
        email, password, name, gRecaptchaResponse,
      }))
        .then((res) => {
          if (register.rejected.match(res)) {
            const payload = (res?.payload ?? {}) as RejectedRequest<RegisterRequestParams>;
            formikHelpers.setErrors({
              email: payload.errors?.email?.[0] ?? '',
              password: payload.errors?.password?.[0] ?? '',
              name: payload.errors?.name?.[0] ?? '',
              gRecaptchaResponse: payload.errors?.gRecaptchaResponse?.[0] ?? '',
            });
          } else {
            dispatch(openModal(''));
            if (afterRegister) {
              afterRegister();
            }
          }
        });
    },
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      email: Yup
        .string()
        .required('Почтовый адрес обязателен к заполнению')
        .email('Введите корректный E-mail адрес'),
      password: validatePasswordSchema,
      name: Yup.string()
        .required('Имя обязательно к заполнению')
        .max(32, 'Максимальная длина псевдонима - 32 символа'),
      gRecaptchaResponse: Yup.string().required('Подтвердите что вы не робот'),
    }),
  });

  useEffect(() => {
    const head = document.getElementsByTagName('head')[0];

    if (!document.getElementById('recaptcha-script')) {
      const script = document.createElement('script');
      script.id = 'recaptcha-script';
      script.type = 'text/javascript';
      script.src = 'https://www.google.com/recaptcha'
        + '/api.js?onload=onloadReCaptchaCallback&render=explicit';
      head.appendChild(script);
    }

    if (typeof (window as any).grecaptcha !== 'undefined') {
      try {
        (window as any).grecaptcha.render(
          'grecaptcha-placeholder',
          {
            sitekey: process.env.NEXT_PUBLIC_RECAPTCHA_KEY,
            hl: navigator.language,
            callback: (token: string) => {
              registerForm.setFieldValue('gRecaptchaResponse', token);
            },
          },
        );
      } catch {
        console.warn('recaptcha already exist');
      }
    }

    (window as any).onloadReCaptchaCallback = () => {
      if (typeof (window as any).grecaptcha !== 'undefined') {
        try {
          (window as any).grecaptcha.render(
            'grecaptcha-placeholder',
            {
              sitekey: process.env.NEXT_PUBLIC_RECAPTCHA_KEY,
              hl: navigator.language,
              callback: (token: string) => {
                registerForm.setFieldValue('gRecaptchaResponse', token);
              },
            },
          );
        } catch {
          console.warn('recaptcha already exist');
        }
      }
    };

    return () => {
      registerForm.resetForm();
    };
  }, []);

  return (
    <SCRegisterForm>
      <SCTitle>{title ?? 'Регистрация на Bookriver'}</SCTitle>
      <SCRowSocials>
        <SCVkIconWrapper
          onClick={() => handleSocialNetworkIconClick(ProviderEnum.vkontakte)}
        >
          <SCVKIcon />
        </SCVkIconWrapper>
        <SCGoogleIconWrapper
          onClick={() => handleSocialNetworkIconClick(ProviderEnum.google)}
        >
          <SCGoogleIcon />
        </SCGoogleIconWrapper>
        <SCMailIconWrapper
          onClick={() => handleSocialNetworkIconClick(ProviderEnum.mailru)}
        >
          <SCMailIcon />
        </SCMailIconWrapper>
        <SCYandexIconWrapper
          onClick={() => handleSocialNetworkIconClick(ProviderEnum.yandex)}
        >
          <SCYandexIcon />
        </SCYandexIconWrapper>
        <SCAppleIconWrapper
          onClick={() => handleSocialNetworkIconClick(ProviderEnum.apple)}
        >
          <SCAppleIcon />
        </SCAppleIconWrapper>
      </SCRowSocials>

      <SCLabelWithOr>
        или
      </SCLabelWithOr>

      <SCError>{message}</SCError>

      <form onSubmit={registerForm.handleSubmit}>
        <SCFieldWrapper>
          <Field
            error={registerForm.touched.email ? registerForm.errors.email : null}
          >
            <SCInput
              placeholder="E-mail"
              name="email"
              onChange={(e) => {
                registerForm.setFieldValue('email', e.target.value.trim());
              }}
              onBlur={registerForm.handleBlur}
              value={registerForm.values.email}
            />
          </Field>
        </SCFieldWrapper>
        <SCFieldWrapper>
          <Field
            error={registerForm.touched.password ? registerForm.errors.password : null}
          >
            <SCInput
              name="password"
              type="password"
              placeholder="Пароль"
              onChange={(e) => {
                registerForm.setFieldValue('password', e.target.value.trim());
              }}
              onBlur={registerForm.handleBlur}
              value={registerForm.values.password}
            />
          </Field>
        </SCFieldWrapper>
        <SCFieldWrapper>
          <Field
            error={registerForm.touched.name ? registerForm.errors.name : null}
          >
            <SCInput
              placeholder="Псевдоним"
              name="name"
              onChange={(e) => {
                registerForm.setFieldValue('name', e.target.value);
              }}
              onBlur={registerForm.handleBlur}
              value={registerForm.values.name}
            />
          </Field>
        </SCFieldWrapper>

        <SCFieldWrapper>
          <Field
            error={registerForm.touched.gRecaptchaResponse ? registerForm.errors.gRecaptchaResponse : null}
          >
            <SCReCaptchaPlaceholder id="grecaptcha-placeholder" />
          </Field>
        </SCFieldWrapper>
        <SCAgreement>
          Нажимая кнопку,я принимаю условия

          {' '}
          <UserPolicyLink>
            <a
              target="_blank"
              rel="noopener noreferrer"
            >
              Пользовательского соглашения
            </a>
          </UserPolicyLink>
          {' '}
          и
          {' '}
          <RoyaltyLicenceLink>
            <a
              target="_blank"
              rel="noopener noreferrer"
            >
              Программы лояльности
            </a>
          </RoyaltyLicenceLink>
          {' '}
          , а также даю Согласие на
          {' '}
          <PrivacyAgreementLink>
            <a
              target="_blank"
              rel="noopener noreferrer"
            >
              обработку персональных данных
            </a>
          </PrivacyAgreementLink>
        </SCAgreement>
        <SCButtonWrapp>
          <Button
            type="primary"
            htmlType="submit"
            block
            size="large"
            isLoading={loading.register === true}
          >
            Зарегистрироваться
          </Button>
        </SCButtonWrapp>
      </form>

      <SCLabelWithAccount>Уже есть аккаунт на Bookriver?</SCLabelWithAccount>
      <SCLink onClick={openLoginModal}>
        Войти
      </SCLink>
    </SCRegisterForm>
  );
};

const SCReCaptchaPlaceholder = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SCButtonWrapp = styled.div`
  ${SCButton} {
    ${TextBoldStyle};
    margin-bottom: 30px;
    height: 44px;
  }
`;

const SCInput = styled(Input)`
  background: var(--white-color);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  border-radius: 2px;
  height: 44px;
  `;

const SCFieldWrapper = styled.div`
  &:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const SCLink = styled.button`
  cursor: pointer;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: var(--primary-color);
  outline: unset;
  border: unset;
  background-color: transparent;
  
  &:hover {
    filter: brightness(1.2);
  }
`;

const SCText = styled.div`
  font-size: 13px;
  color: var(--gray-color);
`;

const SCAgreement = styled(SCText)`
  margin-bottom: 27px;
  margin-top: 10px;
`;

const SCError = styled.span`
  color: var(--error-color);
  font-size: 13px;
  line-height: 20px;
  font-family: var(--second-font);
  margin: 6px 0;
`;

const SCLabel = styled.span`
  color: var(--gray-color);
  font-size: 13px;
  line-height: 20px;
  font-family: var(--second-font);
`;

const SCLabelWithOr = styled(SCLabel)`
  position: relative;
  display: flex;
  align-items: center;
  text-align: center;

  margin-bottom: 41px;
  &:before {
    content: "";
    width: 50%;
    height: 1px;
    background: var(--gray3-color);
    margin-right: 13px;
  }
  &:after {
    content: "";
    width: 50%;
    height: 1px;
    background: var(--gray3-color);
    margin-left: 13px;
  }
`;

const SCLabelWithAccount = styled(SCLabel)`
  margin-bottom: 8px;
`;

const SCRowSocials = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  max-width: 320px;
  margin: 0 auto 22px auto;

  svg {
    display: block;
    width: 48px !important;
    height: 48px !important;
  }
`;

const SCTitle = styled.h2`
 ${PageTitleStyle};
  color: var(--black-color);
  margin-bottom: 37px;
`;

const SCRegisterForm = styled.div`
  text-align: center;
`;

const SCAuthProviderBackgroundTemplate = styled.div`
  border-radius: 50%;
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 0.7;
  }
`;

const SCVkIconWrapper = styled(SCAuthProviderBackgroundTemplate)`
  background: #506d91;
`;

const SCVKIcon = styled(VkIcon)`
  font-size: 48px;
  color: var(--white-color);
`;

const SCGoogleIconWrapper = styled(SCAuthProviderBackgroundTemplate)`
  background: #FFFFFF;
  border: 1px solid var(--gray3-color);
`;

const SCGoogleIcon = styled(GoogleIcon)`
  font-size: 26px;
`;

const SCMailIconWrapper = styled(SCAuthProviderBackgroundTemplate)`
  background: #1C4685;
`;

const SCMailIcon = styled(MailIcon)`
  font-size: 26px;
  color: #EA9845;
`;

const SCYandexIconWrapper = styled(SCAuthProviderBackgroundTemplate)`
  background: #FFFFFF;
  border: 1px solid var(--gray3-color);
`;

const SCYandexIcon = styled(YandexIcon)`
  font-size: 26px;
  color: #EA1B4D;
`;

const SCAppleIconWrapper = styled(SCAuthProviderBackgroundTemplate)`
  background: var(--black-color);
`;

const SCAppleIcon = styled(AppleIcon)`
  font-size: 26px;
  color: var(--white-color);
`;
