import React, { FC, HTMLAttributes } from 'react';

import { MyBookStatusEnum } from '~/api/account/myBooksApiTypes';
import { BookmarkIcon } from '~/atomic/atom/icon/BookmarkIcon';
import { BooksIcon } from '~/atomic/atom/icon/BooksIcon';
import { ReadersIcon } from '~/atomic/atom/icon/ChapterInfo/ReadersIcon';

interface MyBooksStatusIconProps extends HTMLAttributes<HTMLOrSVGElement> {
  status: MyBookStatusEnum;
}

export const MyBooksStatusIcon: FC<MyBooksStatusIconProps> = ({ status, ...props }) => {
  if (status === MyBookStatusEnum.read) {
    return <BooksIcon {...props} />;
  }
  if (status === MyBookStatusEnum.readNow) {
    return <ReadersIcon {...props} />;
  }
  if (status === MyBookStatusEnum.readLater) {
    return <BookmarkIcon {...props} />;
  }

  return <BookmarkIcon {...props} />;
};
