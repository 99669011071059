import { WithToken } from '~/api/auth/authApiTypes';
import {
  Author, Book, BookIdType,
} from '~/api/book/bookApiTypes';

export enum DeviceEnum {
  Desktop = 'desktop',
  Mobile = 'mobile',
}

export enum CampaignSourceEnum {
  Yandex = 'yandex',
  Google = 'google',
  Vk = 'vk',
  Mytarget = 'mytarget',
  Other = 'other',
}

export enum TargetologistTypeEnum {
  Self = 'self',
  Id = 'id',
  Other = 'other',
}

export enum PromotionTypeEnum {
  IncreasedRoyalty = 'increased-royalty',
  PaymentForServices = 'payment-for-services',
  No = 'no',
}

export enum CampaignSourceEnumName {
  Yandex = 'Яндекс',
  Google = 'Google',
  Vk = 'VK',
  Mytarget = 'MyTarget',
  Other = 'Другое',
}

export interface AdDepartmentCampaign {
  book: {
    id: number
    name: string;
    slug: string;
  };
  libraryAdditions: number;
  income: number;
  targetPurchases: number;
  otherPurchases: number;
  visits: number;
}

export interface Campaign {
  id: string;
  source: CampaignSourceEnum;
  readerUrl: string;
  authorUrl: string;
  book: {
    id: number
    name: string;
    slug: string;
  };
  createdAt: string;
  libraryAdditions: number;
  income: number;
  publicId: number;
  targetPurchases: number;
  otherPurchases: number;
  visits: number;
  canBeEdited: boolean;
  targetologist: TargetologistType;
  targetologistType: TargetologistTypeEnum;
  promotionType: PromotionTypeEnum;
}

export interface CreateCampaignsProps {
  source: CampaignSourceEnum;
  bookId: number;
  targetologistId?: number;
  targetologistType: TargetologistTypeEnum;
  promotionType: PromotionTypeEnum;
}

export interface GetCampaignProps extends WithToken {
  adCampaignId: string;
}

export interface VisitLinkCampaignParams {
  visitUrl: string;
  refererUrl: string;
  device: DeviceEnum;
}

export interface TargetologistType {
  id: number;
  name: string;
  userId: number;
}

export interface GetNetworkBooksProps {
  limit: number;
  widgetId: 'vidget-book-1' | 'vidget-read-1';
  bookId: BookIdType;
}

export type NetworkBook = Book & {
  utmQueryParams: {
    [key: string]: string;
  };
  author: Author;
};

export interface GetPixelProps {
  bookId: BookIdType;
}

export interface GetCampaignBooksProps {
  keyword: string;
}

export interface GetCampaignsResult {
  ownCampaigns: Campaign[];
  adDepartmentCompanies: AdDepartmentCampaign[];
}
