import React, { FC } from 'react';
import styled from 'styled-components';

import { MyBookStatusEnum } from '~/api/account/myBooksApiTypes';
import { ArrowIcon } from '~/atomic/atom/icon/ArrowIcon';
import { EyeIcon } from '~/atomic/atom/icon/EyeIcon';
import { StarIcon } from '~/atomic/atom/icon/StarIcon';
import { MyBooksLink } from '~/atomic/atom/links/dynamicLinks';
import { MyBooksStatusIcon } from '~/atomic/atom/MyBooksStatusIcon';
import { PaperStyle } from '~/atomic/atom/PaperStyle';
import { TextBoldStyle } from '~/atomic/Typography';
import { userSelector } from '~/feature/user/user.selector';
import { useAppSelector } from '~/store';

interface MyBooksOverlayFilterProps {
  transitionOnMyBooks?: () => void;
}

export const MyBooksOverlayFilter: FC<MyBooksOverlayFilterProps> = ({ transitionOnMyBooks, ...props }) => {
  const { userCounters } = useAppSelector(userSelector);

  return (
    <SCMyBooksFilterOverlay {...props}>
      <SCNavMenu>
        <MyBooksLink
          status="read-now"
        >
          <SCNavItem
            onClick={transitionOnMyBooks}
          >
            <SCIconWrapper>
              <SCReadersIcon status={MyBookStatusEnum.readNow} />
            </SCIconWrapper>
            Читаю
            {' '}
            <SCCounter>
              {userCounters?.mybooks?.readNow > 0 && (userCounters?.mybooks?.readNow > 99
                ? '99+'
                : userCounters?.mybooks?.readNow)}
              <SCArrowIcon />
            </SCCounter>
          </SCNavItem>
        </MyBooksLink>
        <MyBooksLink
          status="read"
        >
          <SCNavItem
            onClick={transitionOnMyBooks}
          >
            <SCIconWrapper>
              <SCBooksIcon status={MyBookStatusEnum.read} />
            </SCIconWrapper>
            Прочитано
            {' '}
            <SCCounter>
              {userCounters?.mybooks?.read > 0 && (userCounters?.mybooks?.read > 99
                ? '99+'
                : userCounters?.mybooks?.read)}
              <SCArrowIcon />
            </SCCounter>
          </SCNavItem>
        </MyBooksLink>
        <MyBooksLink
          status="read-later"
        >
          <SCNavItem
            onClick={transitionOnMyBooks}
          >
            <SCIconWrapper>
              <SCBookmarkIcon status={MyBookStatusEnum.readLater} />
            </SCIconWrapper>
            Хочу прочитать
            {' '}
            <SCCounter>
              {userCounters?.mybooks?.readLater > 0 && (userCounters?.mybooks?.readLater > 99
                ? '99+'
                : userCounters?.mybooks?.readLater)}
              <SCArrowIcon />
            </SCCounter>
          </SCNavItem>
        </MyBooksLink>
        <MyBooksLink
          status="reaction"
        >
          <SCNavItem
            onClick={transitionOnMyBooks}
          >
            <SCIconWrapper>
              <SCFilledStarIcon />
            </SCIconWrapper>
            Оценил
            {' '}
            <SCCounter>
              {userCounters?.mybooks?.reactions?.all > 0 && (userCounters?.mybooks?.reactions?.all > 99
                ? '99+'
                : userCounters?.mybooks?.reactions?.all)}
              <SCArrowIcon />
            </SCCounter>
          </SCNavItem>
        </MyBooksLink>
        <MyBooksLink
          status="viewed"
        >
          <SCNavItem
            onClick={transitionOnMyBooks}
          >
            <SCIconWrapper>
              <SCEyeIcon />
            </SCIconWrapper>
            Просмотренные
            <SCCounter>
              <SCArrowIcon />
            </SCCounter>
          </SCNavItem>
        </MyBooksLink>
      </SCNavMenu>
    </SCMyBooksFilterOverlay>
  );
};

const SCMyBooksFilterOverlay = styled.div`
  ${PaperStyle};
  border-bottom: none;
  border-top: 1px solid #DCE3E4;
  border-right: 1px solid #DCE3E4;
  border-left: 1px solid #DCE3E4;
  display: flex;
  flex-direction: column;
`;

const SCReadersIcon = styled(MyBooksStatusIcon)`
  font-size: 20px;
  color: var(--black-color);
  flex-shrink: 0;
`;

const SCBookmarkIcon = styled(MyBooksStatusIcon)`
  font-size: 18px;
  color: var(--black-color);
  flex-shrink: 0;
`;

const SCBooksIcon = styled(MyBooksStatusIcon)`
  font-size: 18px;
  color: var(--black-color);
  flex-shrink: 0;
`;

const SCFilledStarIcon = styled(StarIcon)`
  font-size: 18px;
  color: var(--black-color);
  flex-shrink: 0;
`;

const SCEyeIcon = styled(EyeIcon)`
  font-size: 26px;
  color: var(--black-color);
  flex-shrink: 0;
`;

const SCNavMenu = styled.div`
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  width: 250px;
  
  > *:last-child {
    padding-bottom: 10px;
  }

  > *:first-child {
    padding-top: 16px;
  }

  > * {
    padding-right: 14px;
    padding-left: 14px;
  }
`;

const SCNavItem = styled.a`
  ${TextBoldStyle};
  color: var(--black-color);
  display: inline-flex;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
`;

const SCArrowIcon = styled(ArrowIcon)`
  font-size: 12px;
  color: var(--gray-color);
  margin-left: 12px;
`;

const SCCounter = styled.div`
  margin-left: auto;
  color: var(--gray-color);
`;

const SCIconWrapper = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 12px;
`;
