import {
  CreatePostFields,
  CreatePostParams,
  DeletePostParams, DownloadImageParams, EditPostParams,
  GetAuthorPostsParams,
  GetMePostsParams,
  GetPostByIdParams, GetPostsParams,
  Post,
  ViewPostParams,
} from '~/api/blog/blogTypes';
import { handleRejectedRequest } from '~/api/provider/providerErrors';
import {
  ApiResponse, PaginatedResponse, RejectedRequest,
} from '~/api/provider/providerTypes';

import {
  baseURL,
  provider,
} from '../provider/provider.config';

const namespace = Symbol('namespace');

export const blogApi = {
  [namespace]: '/v1/blogs',

  async createPost(data: CreatePostParams) {
    try {
      const response = await provider.post<
      ApiResponse<Post, RejectedRequest<CreatePostFields>>
      >(`${this[namespace]}/posts`, data);
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async editPost(data: EditPostParams) {
    try {
      await provider.put<
      ApiResponse<Post, RejectedRequest<CreatePostFields>>
      >(`${this[namespace]}/posts/${data.postId}`, data);
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getAuthorPosts(data: GetAuthorPostsParams) {
    try {
      const response = await provider.get<
      PaginatedResponse<Post[], RejectedRequest<void>>
      >(`${this[namespace]}/posts`, { params: data });
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getPostById(data: GetPostByIdParams) {
    try {
      const response = await provider.get<
      ApiResponse<Post, RejectedRequest<void>>
      >(
        `${this[namespace]}/posts/${data.id}`,
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getMePosts(data: GetMePostsParams) {
    try {
      const response = await provider.get<
      PaginatedResponse<Post[], RejectedRequest<void>>
      >(`${this[namespace]}/my/posts`, { params: data });
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async deletePost(data: DeletePostParams) {
    try {
      await provider.delete<
      ApiResponse<Post, RejectedRequest<void>>
      >(
        `${this[namespace]}/posts/${data.id}`,
      );
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getPosts(data: GetPostsParams) {
    try {
      const result = await provider.get<
      PaginatedResponse<Post[], RejectedRequest<void>>
      >(`${this[namespace]}/posts`, { params: data });
      if ('data' in result.data) {
        return result.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async viewPost(data: ViewPostParams) {
    try {
      await provider.post<
      PaginatedResponse<Post[], RejectedRequest<void>>
      >(
        `${this[namespace]}/posts/${data.postId}/views`,
      );
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  downloadImage(data: DownloadImageParams) {
    return `${baseURL}${this[namespace]}/posts/${data.postId}/image`;
  },
};
