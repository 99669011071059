import type { CheckboxProps } from 'antd/lib/checkbox/Checkbox';
import dynamic from 'next/dynamic';
import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { TextRegStyle } from '~/atomic/Typography';

const AntCheckbox = dynamic(() => import('antd/lib/checkbox'), { ssr: false });

export const Checkbox: FC<Omit<CheckboxProps, 'title'> & { title?: ReactNode }> = ({ title, ...rest }) => (
  <SCCheckboxWrapper data-is-have-text={!!title}>
    <SCCheckbox>
      <AntCheckbox {...rest}>
        {title}
      </AntCheckbox>
    </SCCheckbox>
  </SCCheckboxWrapper>
);

const SCCheckbox = styled.div`
  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;
  }

  .ant-checkbox {
    width: 20px;
    height: 20px;
    top: 0;
  }
  
  .ant-checkbox-inner {
    width: 20px;
    height: 20px;
    
    &:after {
      width: 6px;
      height: 10px;
    }
  }
`;

const SCCheckboxWrapper = styled.div`
  &[data-is-have-text="true"] {
    .ant-checkbox {
      margin-right: 12px;
    }
  }

  .ant-checkbox + span {
    ${TextRegStyle};
    padding-left: 0;
    padding-right: 0;
  }

  &__row {
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    width: 100%;
  }

  &__tip {
    color: var(--gray-color);
    font-size: 13px;
    line-height: 20px;
    font-family: var(--second-font);
  }

  &__title {
    ${TextRegStyle};
    font-size: 14px;
    line-height: 22px;
    color: var(--black-color);
  }
`;
