import { UserAbonnementStatusEnum } from '~/api/account/accountApiTypes';

export enum AbonnementTypeEnum {
  Legend = 'legend',
  Master = 'master',
  Seeker = 'seeker',
}

export enum AbonnementChapterCountEnum {
  Legend = 1300,
  Master = 1000,
  Seeker = 750,
}

export enum AbonnementPriceEnum {
  Legend = 499,
  Master = 399,
  Seeker = 299,
}

export enum AbonnementTypeNameEnum {
  Legend = 'Легенда',
  Master = 'Мастер',
  Seeker = 'Искатель',
}

export interface AbonnementType {
  type: AbonnementTypeEnum;
  price: number;
}

export interface UserAbonnement {
  abonnementType: AbonnementType;
  status: UserAbonnementStatusEnum;
  paymentError: string;
  shouldExpireAt: string;
  isAutoRenewalEnabled: boolean;
  characterConsumptionAsPercent: number;
  switchAbonnementType: AbonnementTypeEnum;
}
