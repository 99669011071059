import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetServerSidePropsContext } from 'next';
import Router from 'next/router';
import qs from 'query-string';

import { getPosts } from '~/feature/blogs/blog.data';
import {
  blogsFilterNavActions,
  blogsFilterNavDefaultOnlySubscribed,
  blogsFilterNavDefaultThemes,
  blogsFilterNavSliceName,
} from '~/feature/blogs/BlogsFilterNav/blogsFilterNav.slice';
import { environments } from '~/lib/const';
import { RootState } from '~/store';

export const getBlogsFilterFromUrl = createAsyncThunk<
void,
GetServerSidePropsContext | void,
{ rejectValue: { error: string }; state: RootState }
>(
  `${blogsFilterNavSliceName}/getFilterFromUrl`,
  async (
    ctx,
    thunkAPI,
  ) => {
    try {
      let query;

      if (environments.isClient) {
        query = Router?.query;
      } else {
        query = (ctx && ctx?.query) ?? undefined;
      }

      const onlySubscribed = (query?.onlySubscribed ?? blogsFilterNavDefaultOnlySubscribed) === 'true';
      thunkAPI.dispatch(blogsFilterNavActions.changeOnlySubscribed(onlySubscribed));

      const themes = query?.theme ? [query?.theme] : blogsFilterNavDefaultThemes;
      thunkAPI.dispatch(blogsFilterNavActions.changeThemes(themes));
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const setBlogsFilterToUrl = createAsyncThunk<
void,
void,
{ rejectValue: { error: string }; state: RootState }
>(
  `${blogsFilterNavSliceName}/setBlogsFilterToUrl`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const parsedUrl = qs.parseUrl(window.location.toString());
      const blogsFilterState = thunkAPI.getState().blogsFilterNav;

      parsedUrl.query.onlySubscribed = String(blogsFilterState.onlySubscribed);

      window.history.replaceState(
        undefined,
        document.title,
        qs.stringifyUrl(parsedUrl),
      );
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const changeBlogsFilteroOlySubscribed = createAsyncThunk<void, { onlySubscribed: boolean }>(
  `${blogsFilterNavSliceName}/changeThemes`,
  async ({ onlySubscribed }, thunkAPI) => {
    await thunkAPI.dispatch(blogsFilterNavActions.changeOnlySubscribed(onlySubscribed));
    await thunkAPI.dispatch(setBlogsFilterToUrl());
    await thunkAPI.dispatch(getPosts({}));
  },
);
