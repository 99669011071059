import Script from 'next/script';
import React, { FC, useEffect } from 'react';

export const OmniDesk: FC<{ isOpen?: boolean }> = ({ isOpen }) => {
  useEffect(() => {
    (window as any)?.OmniWidgetApi?.call('show_btn');

    if (isOpen) {
      (window as any)?.OmniWidgetApi?.call('open');
    }
    return () => {
      (window as any)?.OmniWidgetApi?.call('hide_btn');
      if (isOpen) {
        (window as any)?.OmniWidgetApi?.call('close');
      }
    };
  });

  return (
    <>
      <Script
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: "!function(e,o){!window.omni?window.omni=[]:'';window.omni.push(o);"
              + 'o.g_config={widget_id:"13154-w2wjzaf0"}; o.email_widget=o.email_widget||{};'
              + 'var w=o.email_widget;w.readyQueue=[];'
              + 'o.config=function(e){ this.g_config.user=e};'
              + 'w.ready=function(e){ '
              + 'this.readyQueue.push(e);'
              + '};'
              + 'var r=e.getElementsByTagName("script")[0];c=e.createElement("script");'
              + 'c.type="text/javascript",c.async=!0;'
              + 'c.src="https://omnidesk.ru/bundles/acmesite/js/cwidget0.2.min.js";'
              + 'r.parentNode.insertBefore(c,r)}(document,[]);',
        }}
      />
      <Script
        strategy="lazyOnload"
        dangerouslySetInnerHTML={{
          __html: 'omni[0].config({'
              + ' display_button: true,'
              + ' user_info: true,'
              + ' });'
              + ' omni[0].widget={'
              + '   ready: function() {'
              + ' if(typeof window !== "undefined") { '
              + 'var user = undefined;'
              + " var cookieArr = document.cookie.split(';');"
              + 'for(var i = 0; i < cookieArr.length; i++) {'
              + " var cookiePair = cookieArr[i].split('=');"
              + 'if("user" == cookiePair[0].trim()) {'
              + '  user = JSON.parse(decodeURIComponent(cookiePair[1]));'
              + '}'
              + '}'
              + '   omni[0].widget.identify={'
              + '   user_full_name: user && user.name || undefined,'
              + '   user_email: user && user.email || undefined,'
              + '   user_id: user && user.id || undefined,'
              + ' };'
              + ' omni[0].widget.case_subject=['
              + "  'Сообщение о технической ошибке',"
              + "  'Вопрос по работе сервиса'"
              + ' ];'
              + " omni[0].widget.search = ''"
              + ' }'
              + '} '
              + ' };',
        }}
      />
    </>
  );
};
