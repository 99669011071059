import React, { FC } from 'react';
import styled from 'styled-components';

import { CommercialStatusEnum } from '~/api/account/accountApiTypes';
import { ArrowIcon } from '~/atomic/atom/icon/ArrowIcon';
import {
  AuthorCommercialLink, AuthorCommercialRegistrationPendingLink,
  AuthorRegistrationLink,
} from '~/atomic/atom/links/staticLinks';
import { getRegistrationStepAuthor } from '~/feature/user/registration.data';
import {
  isLoggedUserAuthorSelector,
  isLoggedUserCommercialAuthorSelector, userSelector,
} from '~/feature/user/user.selector';
import { useAppDispatch, useAppSelector } from '~/store';

export const AuthorStatusButton: FC = () => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(userSelector);

  const isLoggedUserCommercialAuthor = useAppSelector(isLoggedUserCommercialAuthorSelector);
  const isAuthor = useAppSelector(isLoggedUserAuthorSelector);
  const isPending = user?.commercialStatus === CommercialStatusEnum.pending;
  const isRejected = user?.commercialStatus === CommercialStatusEnum.rejected;

  if (!isAuthor && !isLoggedUserCommercialAuthor && !isPending) {
    return (
      <SCButton
        onClick={() => { dispatch(getRegistrationStepAuthor()); }}
      >
        <AuthorRegistrationLink>
          <SCLink>
            Стать автором
            <SCArrowIcon />
          </SCLink>
        </AuthorRegistrationLink>
      </SCButton>
    );
  }

  if (isAuthor && !isLoggedUserCommercialAuthor && isRejected) {
    return (
      <SCButton data-is-error="true">
        <AuthorRegistrationLink>
          <SCLink>
            Заявка отклонена
            <SCArrowIcon />
          </SCLink>
        </AuthorRegistrationLink>
      </SCButton>
    );
  }

  if (isAuthor && !isLoggedUserCommercialAuthor && !isPending) {
    return (
      <SCButton>
        <AuthorRegistrationLink>
          <SCLink>
            Стать коммерческим автором
            <SCArrowIcon />
          </SCLink>
        </AuthorRegistrationLink>
      </SCButton>
    );
  }

  if (isPending) {
    return (
      <SCButton data-is-warning="true">
        <AuthorCommercialRegistrationPendingLink>
          <SCLink>
            Заявка на рассмотрении
            <SCArrowIcon />
          </SCLink>
        </AuthorCommercialRegistrationPendingLink>
      </SCButton>
    );
  }

  if (isLoggedUserCommercialAuthor) {
    return (
      <SCButton>
        <AuthorCommercialLink>
          <SCLink>
            Коммерческий автор
            <SCArrowIcon />
          </SCLink>
        </AuthorCommercialLink>
      </SCButton>
    );
  }

  return null;
};

const SCArrowIcon = styled(ArrowIcon)`
  color: var(--primary-color);
  font-size: 12px;
  margin-left: auto;
`;

const SCButton = styled.li`
  border-radius: 4px;
  padding: 14px 16px;

  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  
  background: var(--white-color);
  
  &[data-is-warning="true"] {
    background: var(--yellow-dark-color);
    
    a {
      color: var(--black-color);
    }

    ${SCArrowIcon} {
      color: var(--black-color);
    }
  }
  
  &[data-is-error="true"] {
    background: var(--error-color);

    a {
      color: var(--white-color);
    }

    ${SCArrowIcon} {
      color: var(--white-color);
    }
  }
`;

const SCLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-family: var(--main-font);
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: var(--primary-color);
  width: 100%;
`;
