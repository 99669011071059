/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

import { SCSVGIcon } from '~/atomic/atom/icon/SCCIcon';

export const AudioPlayerPlayIcon = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <SCSVGIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22 22" {...props}>
      <path d="M15.9844 9.97656L8.42188 5.37891C8.07812 5.17839 7.73438 5.17839 7.39062 5.37891C7.04688 5.57943 6.875 5.88021 6.875 6.28125V15.2188C6.875 15.6198 7.04688 15.9206 7.39062 16.1211C7.73438 16.3216 8.07812 16.3216 8.42188 16.1211L15.9844 11.7812C16.3281 11.5807 16.5 11.2799 16.5 10.8789C16.5 10.4779 16.3281 10.1771 15.9844 9.97656ZM21.6562 10.75C21.6562 8.83073 21.1693 7.05469 20.1953 5.42188C19.25 3.78906 17.9609 2.5 16.3281 1.55469C14.6953 0.580729 12.9193 0.09375 11 0.09375C9.08073 0.09375 7.30469 0.580729 5.67188 1.55469C4.03906 2.5 2.73568 3.78906 1.76172 5.42188C0.816406 7.05469 0.34375 8.83073 0.34375 10.75C0.34375 12.6693 0.816406 14.4453 1.76172 16.0781C2.73568 17.7109 4.03906 19 5.67188 19.9453C7.30469 20.9193 9.08073 21.4062 11 21.4062C12.9193 21.4062 14.6953 20.9193 16.3281 19.9453C17.9609 19 19.25 17.7109 20.1953 16.0781C21.1693 14.4453 21.6562 12.6693 21.6562 10.75ZM2.40625 10.75C2.40625 9.20312 2.79297 7.77083 3.56641 6.45312C4.33984 5.13542 5.38542 4.08984 6.70312 3.31641C8.02083 2.54297 9.45312 2.15625 11 2.15625C12.5469 2.15625 13.9792 2.54297 15.2969 3.31641C16.6146 4.08984 17.6602 5.13542 18.4336 6.45312C19.207 7.77083 19.5938 9.20312 19.5938 10.75C19.5938 12.2969 19.207 13.7292 18.4336 15.0469C17.6602 16.3646 16.6146 17.4102 15.2969 18.1836C13.9792 18.957 12.5469 19.3438 11 19.3438C9.45312 19.3438 8.02083 18.957 6.70312 18.1836C5.38542 17.4102 4.33984 16.3646 3.56641 15.0469C2.79297 13.7292 2.40625 12.2969 2.40625 10.75Z" />
    </SCSVGIcon>
  );
};
