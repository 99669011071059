import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Purchase } from '~/api/finance/financeApiTypes';

export const purchaseTableSliceName = 'purchaseTable';

export const purchaseSlice = createSlice({
  name: purchaseTableSliceName,
  initialState: {
    purchase: [] as Purchase[],
    isLoading: true,
  },
  reducers: {
    setPurchase: (state, action: PayloadAction<Purchase[]>) => {
      state.purchase = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const { actions: purchaseActions, reducer: purchaseReducer } = purchaseSlice;
