import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import qs from 'query-string';

import { financeApi } from '~/api/finance/financeApi';
import {
  GenerateReferralLinkParams,
  GenerateReferralLinkResult,
  ReferralUrlVisitedParams,
} from '~/api/finance/financeApiTypes';
import { campaignsApi } from '~/api/finance/marketing/campaignsApi';
import {
  deletePartner,
  getDeletePartnerFromStorage,
  updatePartner,
} from '~/feature/finance/partnerProgram/partnerProgram.data';
import {
  generateReferralLinkName,
  referralLinkActions,
  referralLinkSliceName,
} from '~/feature/referralLink/referralLink.slice';
import { isLoggedInSelector } from '~/feature/user/isLoggedInSelector';
import { getQueriesFromUrl } from '~/feature/utils/getQueriesFromUrl/getQueriesFromUrl';
import { getDeviceFromClient } from '~/lib/getDeviceFromClient';
import { RootState } from '~/store';

export const generateReferralLink = createAsyncThunk<
GenerateReferralLinkResult,
GenerateReferralLinkParams,
{ rejectValue: { message: string }, state: RootState }
>(
  `${referralLinkSliceName}/generateReferralLink`,
  async (data, thunkAPI) => {
    const {
      isGenerateReferralLinkLoading,
      isHideGenerateReferralLink,
    } = thunkAPI.getState().referralLink;
    const { user } = thunkAPI.getState().user;

    if (!isGenerateReferralLinkLoading
      && !isHideGenerateReferralLink
      && typeof user?.id !== 'undefined') {
      try {
        thunkAPI.dispatch(referralLinkActions.setIsGenerateReferralLinkLoading(true));
        const generateReferralLinkResult = await financeApi.generateReferralLink(data);

        if (generateReferralLinkResult && 'data' in generateReferralLinkResult) {
          thunkAPI.dispatch(
            referralLinkActions.changeReferralLink(generateReferralLinkResult.data.url),
          );
        }
      } catch (error) {
        return thunkAPI.rejectWithValue({ message: error.message });
      } finally {
        thunkAPI.dispatch(referralLinkActions.setIsGenerateReferralLinkLoading(false));
      }
    }
  },
);

export const referralUrlVisited = createAsyncThunk<
void,
ReferralUrlVisitedParams,
{ rejectValue: { message: string }, state: RootState }
>(
  `${referralLinkSliceName}/referralUrlVisited`,
  async (data, thunkAPI) => {
    const { isReferralUrlVisitedLoading } = thunkAPI.getState().referralLink;
    if (!isReferralUrlVisitedLoading) {
      try {
        thunkAPI.dispatch(referralLinkActions.setIsReferralUrlVisitedLoading(true));
        await financeApi.referralUrlVisited(data);
      } catch (error) {
        return thunkAPI.rejectWithValue({ message: error.message });
      } finally {
        thunkAPI.dispatch(referralLinkActions.setIsReferralUrlVisitedLoading(false));
      }
    }
  },
);

export const updatePartnerFromReferralLink = createAsyncThunk<
void,
void,
{ rejectValue: { message: string }, state: RootState }
>(
  `${referralLinkSliceName}/setPartnerFromReferralLink`,
  async (data, thunkAPI) => {
    const { isUpdatePartnerFromReferralLinkLoading } = thunkAPI.getState().referralLink;

    if (localStorage) {
      const referralUrl = localStorage?.getItem(referralLinkSliceName) ?? '';
      if (!isUpdatePartnerFromReferralLinkLoading && referralUrl) {
        try {
          const isDeletePartner = unwrapResult(await thunkAPI.dispatch(getDeletePartnerFromStorage()));
          if (!isDeletePartner) {
            thunkAPI.dispatch(referralLinkActions.setIsUpdatePartnerFromReferralLinkLoading(true));
            await localStorage.removeItem(referralLinkSliceName);
            await financeApi.updatePartner({ visitedUrl: referralUrl });
          } else {
            await localStorage.removeItem(referralLinkSliceName);
          }
        } catch (error) {
          return thunkAPI.rejectWithValue({ message: error.message });
        } finally {
          thunkAPI.dispatch(referralLinkActions.setIsUpdatePartnerFromReferralLinkLoading(false));
        }
      }
    } else {
      console.error('failed load localStorage');
    }
  },
);

export const loadReferralLinkFromStorage = createAsyncThunk<void, void, { state: RootState }>(
  `${referralLinkSliceName}/loadReferralLinkFromStorage`,
  async (_, thunkAPI) => {
    const generateReferralLinkStorageJson = localStorage.getItem(generateReferralLinkName);

    const generateReferralLinkStorage = generateReferralLinkStorageJson
      ? JSON.parse(generateReferralLinkStorageJson)
      : {};

    if (generateReferralLinkStorage?.isHide) {
      thunkAPI.dispatch(referralLinkActions.setIsHideGenerateReferralLink(true));
    } else {
      thunkAPI.dispatch(referralLinkActions.setIsHideGenerateReferralLink(false));
    }
  },
);

export const saveReferralLinkThunk = createAsyncThunk<void, void, { state: RootState }>(
  `${referralLinkSliceName}/saveReferralLinkThunk`,
  async (_, thunkAPI) => {
    try {
      const isLoggedIn = isLoggedInSelector(thunkAPI.getState());
      const visitedUrl = window.location.href.toString();
      const parsedUrl = qs.parseUrl(String(window.location));
      const utmQueries = await getQueriesFromUrl({
        searchedQueries: [
          'btm_campaign',
          'utm_campaign',
          'admitad_uid',
          'utm_content',
          'utm_medium',
          'utm_source',
          'click_id',
          'from',
        ],
        isDeleteOptions: false,
      });
      const refQueries = await getQueriesFromUrl({ searchedQueries: ['pid'] });
      const queries = await getQueriesFromUrl({ searchedQueries: ['yclid', 'rb_clickid'], isDeleteOptions: false });

      const isInsideCampaign = (
        utmQueries?.utm_medium === 'cpc'
          || utmQueries?.utm_source === 'bookriver'
          || utmQueries?.utm_source === 'bookriver-vmd'
          || utmQueries?.btm_campaign
      )
        && utmQueries?.utm_source !== 'topadvert';

      const isReferralLink = refQueries?.pid;

      const isDeletePartner = (queries?.yclid || queries?.rb_clickid)
        && !isReferralLink
        && !utmQueries?.admitad_uid;

      const isOutsideCampaign = Boolean(
        utmQueries?.admitad_uid
        || utmQueries?.click_id
        || utmQueries?.utm_campaign
        || utmQueries?.utm_medium
        || utmQueries?.utm_source,
      ) && !isInsideCampaign;

      const isCPAPartner = (
        utmQueries?.utm_source === 'cityads'
        || utmQueries?.utm_source === 'test'
        || utmQueries?.utm_source === 'yoomoney'
        || utmQueries?.utm_source === 'guruleads'
        || utmQueries?.from === 'topadvert'
      );

      const isSavePartner = (isOutsideCampaign || isReferralLink || isCPAPartner) && utmQueries.utm_source !== 'email';
      const isVisitReferralUrl = isReferralLink || isCPAPartner;

      if (isDeletePartner) {
        thunkAPI.dispatch(deletePartner());
      }

      if (isVisitReferralUrl) {
        thunkAPI.dispatch(referralUrlVisited({ visitedUrl }));
      }

      if (isInsideCampaign) {
        const device = getDeviceFromClient();

        const url = new URL(qs.stringifyUrl(parsedUrl));
        await campaignsApi.visitLinkCampaign({
          visitUrl: String(url),
          device,
          refererUrl: document?.referrer,
        });
      }

      if (isSavePartner) {
        const url = new URL(qs.stringifyUrl(parsedUrl));

        if (isLoggedIn) {
          await thunkAPI.dispatch(updatePartner({ visitedUrl: String(url) }));
        } else {
          await localStorage.setItem(referralLinkSliceName, String(url));
        }
      }
    } catch (error) {
      console.error('saveReferralLinkThunk error ', error);
    }
  },
);
