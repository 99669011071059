import React, {
  FC, useRef, useState,
} from 'react';
import styled from 'styled-components';

import { ArrowIcon } from '~/atomic/atom/icon/ArrowIcon';
import { GenreLink } from '~/atomic/atom/links/dynamicLinks';
import { headerActions } from '~/atomic/organism/Header/header.slice';
import { TextBoldStyle } from '~/atomic/Typography';
import { getGenreSlugByName } from '~/feature/genre/genres';
import { useAppDispatch } from '~/store';

const ChildGenreLabel: FC<{ genreName: string }> = ({ genreName }) => {
  const dispatch = useAppDispatch();

  const handleHeaderExitClick = () => {
    dispatch(headerActions.setIsHamburgerActive(false));
  };

  const closeLibrary = () => {
    dispatch(headerActions.setIsOpenMobileLibrary(false));
  };

  return (
    <SCChildGenreLabel key={getGenreSlugByName(genreName)}>
      <GenreLink
        slug={getGenreSlugByName(genreName)}
      >
        <a
          onClick={() => {
            closeLibrary();
            handleHeaderExitClick();
          }}
        >
          <span>{ genreName }</span>
        </a>
      </GenreLink>
    </SCChildGenreLabel>
  );
};

interface GenreGroupProps {
  children?: React.ReactNode;
  genreName: string;
}

const GenreGroup: FC<GenreGroupProps> = ({ children, genreName }) => {
  const dispatch = useAppDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const listRef = useRef<HTMLDivElement>(null);
  const titleWrapperRef = useRef<HTMLDivElement>(null);
  const MIN_HEIGHT = 44;
  const titleHeight = (titleWrapperRef?.current?.offsetHeight ?? MIN_HEIGHT);
  const [mainHeight, setMainHeight] = useState(titleHeight);

  const handleHeaderExitClick = () => {
    dispatch(headerActions.setIsHamburgerActive(false));
  };

  const closeLibrary = () => {
    dispatch(headerActions.setIsOpenMobileLibrary(false));
  };

  const showAllGenresHandler = () => {
    if (children) {
      setIsOpen((isOpen) => !isOpen);
      setMainHeight(!isOpen ? listRef.current.offsetHeight + titleHeight : titleHeight);
    }
  };

  if (!children) {
    return (
      <SCGenreGroup
        data-is-open={isOpen}
        style={{ maxHeight: `${mainHeight}px` }}
      >
        <GenreLink
          slug={getGenreSlugByName(genreName)}
        >
          <SCTitleWrapper
            ref={titleWrapperRef}
          >
            <SCTitle
              onClick={showAllGenresHandler}
            >
              { genreName }
            </SCTitle>
            <SCOpenGenreButton
              onClick={() => {
                closeLibrary();
                handleHeaderExitClick();
              }}
            >
              <SCButtonArrowIcon />
            </SCOpenGenreButton>
          </SCTitleWrapper>
        </GenreLink>
      </SCGenreGroup>
    );
  }

  return (
    <SCGenreGroup
      data-is-open={isOpen}
      style={{ maxHeight: `${mainHeight}px` }}
    >
      <SCTitleWrapper
        ref={titleWrapperRef}
      >
        <SCTitle
          onClick={showAllGenresHandler}
        >
          { genreName }
          {children ? <SCArrowIcon /> : null}
        </SCTitle>
        <GenreLink
          slug={getGenreSlugByName(genreName)}
        >
          <SCOpenGenreButton
            onClick={() => {
              closeLibrary();
              handleHeaderExitClick();
            }}
          >
            <SCButtonArrowIcon />
          </SCOpenGenreButton>
        </GenreLink>
      </SCTitleWrapper>
      <SCGenreLabelGroup
        ref={listRef}
      >
        {children}
      </SCGenreLabelGroup>
    </SCGenreGroup>
  );
};

export const HeaderMobNavGenreLibrary = () => {
  return (
    <>
      <GenreGroup
        genreName="Фантастика"
      >
        <ChildGenreLabel
          genreName="Боевая фантастика"
        />
        <ChildGenreLabel
          genreName="Героическая фантастика"
        />
        <ChildGenreLabel
          genreName="Научная фантастика"
        />
        <ChildGenreLabel
          genreName="Постапокалипсис"
        />
        <ChildGenreLabel
          genreName="Космическая фантастика"
        />
        <ChildGenreLabel
          genreName="Киберпанк"
        />
        <ChildGenreLabel
          genreName="Детективная фантастика"
        />
        <ChildGenreLabel
          genreName="Социальная фантастика"
        />
        <ChildGenreLabel
          genreName="Альтернативная история"
        />
        <ChildGenreLabel
          genreName="ЛитРПГ"
        />
        <ChildGenreLabel
          genreName="Боярь-аниме"
        />
        <ChildGenreLabel
          genreName="Стимпанк"
        />
        <ChildGenreLabel
          genreName="Юмористическая фантастика"
        />
      </GenreGroup>
      <GenreGroup
        genreName="Фэнтези"
      >
        <ChildGenreLabel
          genreName="Боевое фэнтези"
        />
        <ChildGenreLabel
          genreName="Бытовое фэнтези"
        />
        <ChildGenreLabel
          genreName="Героическое фэнтези"
        />
        <ChildGenreLabel
          genreName="Городское фэнтези"
        />
        <ChildGenreLabel
          genreName="Историческое фэнтези"
        />
        <ChildGenreLabel
          genreName="Тёмное фэнтези"
        />
        <ChildGenreLabel
          genreName="Славянское фэнтези"
        />
        <ChildGenreLabel
          genreName="Юмористическое фэнтези"
        />
        <ChildGenreLabel
          genreName="Приключенческое фэнтези"
        />
        <ChildGenreLabel
          genreName="Эпическое фэнтези"
        />
        <ChildGenreLabel
          genreName="Магические академии"
        />
      </GenreGroup>

      <GenreGroup
        genreName="Любовный роман"
      >
        <ChildGenreLabel
          genreName="Современный любовный роман"
        />
        <ChildGenreLabel
          genreName="Любовная фантастика"
        />
        <ChildGenreLabel
          genreName="Любовное фэнтези"
        />
        <ChildGenreLabel
          genreName="Остросюжетный любовный роман"
        />
        <ChildGenreLabel
          genreName="Исторический любовный роман"
        />
        <ChildGenreLabel
          genreName="Короткий любовный роман"
        />
      </GenreGroup>
      <GenreGroup
        genreName="Детектив"
      >
        <ChildGenreLabel
          genreName="Исторический детектив"
        />
        <ChildGenreLabel
          genreName="Классический детектив"
        />
        <ChildGenreLabel
          genreName="Юмористический детектив"
        />
        <ChildGenreLabel
          genreName="Шпионский детектив"
        />
        <ChildGenreLabel
          genreName="Политический детектив"
        />
        <ChildGenreLabel
          genreName="Криминальный детектив"
        />
      </GenreGroup>
      <GenreGroup
        genreName="Мистика"
      />
      <GenreGroup
        genreName="Молодежная проза"
      />
      <GenreGroup
        genreName="Попаданцы"
      />
      <GenreGroup
        genreName="Эротика"
      >
        <ChildGenreLabel
          genreName="Романтическая эротика"
        />
        <ChildGenreLabel
          genreName="Эротическое фэнтези"
        />
        <ChildGenreLabel
          genreName="Эротическая фантастика"
        />
        <ChildGenreLabel
          genreName="Эротический фанфик"
        />
        <ChildGenreLabel
          genreName="Жёсткая эротика"
        />
        <ChildGenreLabel
          genreName="С элементами эротики"
        />
      </GenreGroup>
      <GenreGroup
        genreName="Юмористический роман"
      />
      <GenreGroup
        genreName="Современная проза"
      />
      <GenreGroup
        genreName="Историческая проза"
      />
      <GenreGroup
        genreName="Боевик"
      />
      <GenreGroup
        genreName="Хоррор"
      />
      <GenreGroup
        genreName="Триллер"
      />
      <GenreGroup
        genreName="Сказка"
      />
      <GenreGroup
        genreName="Фанфик"
      />
      <GenreGroup
        genreName="Драма"
      />
      <GenreGroup
        genreName="Стихи, поэзия"
      />
      <GenreGroup
        genreName="Разное"
      >
        <ChildGenreLabel
          genreName="Бизнес-литература"
        />
        <ChildGenreLabel
          genreName="Биографии и мемуары"
        />
        <ChildGenreLabel
          genreName="Детская литерату"
        />
        <ChildGenreLabel
          genreName="Развитие личности"
        />
        <ChildGenreLabel
          genreName="Профессиональная литература"
        />
        <ChildGenreLabel
          genreName="Учебная литература"
        />
        <ChildGenreLabel
          genreName="Хобби, досуг"
        />
        <ChildGenreLabel
          genreName="Психология"
        />
      </GenreGroup>
    </>
  );
};

const SCChildGenreLabel = styled.span`
  ${TextBoldStyle};
  display: flex;
  margin-bottom: 12px;

  a {
    color: var(--gray-color);

    &:hover {
      color: var(--primary-hover-color);
    }
  }
`;

const SCGenreLabelGroup = styled.div`
  padding-bottom: 12px;
  margin-left: 12px;
`;

const SCTitleWrapper = styled.span`
  display: flex;
  align-items: center;
  padding-bottom: 12px;
`;

const SCTitle = styled.span`
  ${TextBoldStyle};
  width: 100%;
  display: flex;
  align-items: center;
`;

const SCArrowIcon = styled(ArrowIcon)`
  font-size: 12px;
  color: var(--black-color);
  transform: rotate(90deg);
  margin-left: auto;
  transition: all 0.3s ease;
`;

const SCGenreGroup = styled.div`
  overflow: hidden;
  padding: 4px 12px;
  transition: all 0.5s ease;
  
  &[data-is-open="true"] {
    ${SCArrowIcon} {
      transform: rotate(270deg);      
    }
  }
`;

const SCOpenGenreButton = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  background-color: var(--gray6-color);
  margin-left: 12px;
  flex-shrink: 0;
`;

const SCButtonArrowIcon = styled(ArrowIcon)`
  font-size: 12px;
  color: var(--black-color);
`;
