import type { InputRef } from 'antd/lib/input';
import debounce from 'lodash.debounce';
import React, {
  useCallback, useEffect, useRef,
} from 'react';
import styled from 'styled-components';

import { Drawer } from '~/atomic/atom/Drawer';
import { ArrowIcon } from '~/atomic/atom/icon/ArrowIcon';
import { SearchIcon } from '~/atomic/atom/icon/SearchIcon';
import { Input } from '~/atomic/atom/Input';
import { SearchBooksLink } from '~/atomic/atom/links/dynamicLinks';
import { Loader } from '~/atomic/atom/Loader';
import { headerSelector } from '~/atomic/organism/Header/header.selector';
import { headerActions } from '~/atomic/organism/Header/header.slice';
import { findUsersAndBooks } from '~/feature/search/search.data';
import { searchByPlaceSelector } from '~/feature/search/search.selector';
import { searchActions, SearchFieldPlaces } from '~/feature/search/search.slice';
import { AuthorPreview } from '~/feature/search/SearchResult/AuthorPreview';
import { BookPreview } from '~/feature/search/SearchResult/BookPreview';
import { useAppDispatch, useAppSelector } from '~/store';

export const MobNavSearchDrawer = () => {
  const dispatch = useAppDispatch();
  const {
    books, authors, total, keyword, loading,
  } = useAppSelector(searchByPlaceSelector(SearchFieldPlaces.HeaderMobile));
  const { isShowMobileSearchDraw } = useAppSelector(headerSelector);
  const inputRef = useRef<InputRef>();

  const searchEntry = (newKeyword: string) => {
    if (newKeyword.length > 0) {
      dispatch(findUsersAndBooks({
        place: SearchFieldPlaces.HeaderMobile,
        data: {
          keyword: newKeyword, page: 1, perPage: 5,
        },
      }));
    }
  };

  const debouncedSearch = useCallback(debounce(searchEntry, 300), []);

  useEffect(() => {
    debouncedSearch(keyword);
  }, [keyword]);

  const onClearSearch = () => {
    dispatch(headerActions.setIsShowMobileSearchDraw(false));
    dispatch(searchActions.clearSearch({ place: SearchFieldPlaces.HeaderMobile }));
  };

  const openAllResultsHandler = () => {
    onClearSearch();
    dispatch(headerActions.setIsHamburgerActive(false));
  };

  const afterOpenDrawHandler = () => {
    if (isShowMobileSearchDraw) {
      if (inputRef.current && inputRef.current.focus) {
        inputRef.current.focus();
      }
    }
  };

  return (
    <SCSearchDrawer
      placement="right"
      closable={false}
      onClose={onClearSearch}
      open={isShowMobileSearchDraw}
      afterOpenChange={afterOpenDrawHandler}
      width="100%"
    >
      <SCDrawerContent>
        <SCResultInputWrapper>
          <SCArrowIcon onClick={onClearSearch} />
          <SCSearchInput
            ref={inputRef}
            value={keyword}
            onKeyUp={(event) => {
              if (event.key === 'Escape') {
                onClearSearch();
              }
              if (event.key === 'Enter') {
                dispatch(headerActions.setIsShowMobileSearchDraw(true));
              }
            }}
            onChange={(event) => {
              dispatch(searchActions.clearSearch({ place: SearchFieldPlaces.HeaderMobile }));
              dispatch(searchActions.setKeyword({
                keyword: event.target.value,
                place: SearchFieldPlaces.HeaderMobile,
              }));
            }}
            suffix={(
              <SCSearchIcon
                onClick={() => { dispatch(headerActions.setIsShowMobileSearchDraw(true)); }}
              />
            )}
            placeholder="Название книги, автор"
          />
        </SCResultInputWrapper>
        <SCResultContainer>
          {
            loading && (
              <SCLoaderContainer>
                <Loader size={80} />
              </SCLoaderContainer>
            )
          }
          {
            authors.map((author) => (
              <AuthorPreview author={author} />
            ))
          }
          {
            books.map((book) => (
              <BookPreview
                book={book}
              />
            ))
          }
          { total > 5 && (
            <SearchBooksLink
              keyword={keyword}
            >
              <a onClick={openAllResultsHandler}>
                {`Ещё ${total} результатов`}
              </a>
            </SearchBooksLink>
          )}
        </SCResultContainer>
      </SCDrawerContent>
    </SCSearchDrawer>
  );
};

const SCResultContainer = styled.div`
  position: relative;
  min-height: 500px;
`;

const SCDrawerContent = styled.div`
  padding: 10px 16px;
`;

const SCArrowIcon = styled(ArrowIcon)`
  font-size: 22px;
  margin-right: 16px;
  cursor: pointer;
  transform: scaleX(-1);

  &:hover {
    color: var(--primary-hover-color);
  } 
`;

const SCResultInputWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

const SCSearchIcon = styled(SearchIcon)`
  font-size: 20px;
  transition: 0.3s;
  cursor: pointer;
  color: var(--black-color);

  &:hover {
    color: var(--primary-hover-color);
  } 
`;

const SCSearchDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0;
  }
`;

const SCSearchInput = styled(Input)`
  width: 100%;
`;

const SCLoaderContainer = styled.div`
  min-height: 80px;
`;
