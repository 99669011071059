import { CompleteStatusEnum, PublishStatusEnum } from '~/api/book/bookApiTypes';
import { ChapterStatus } from '~/api/book/chapterApiTypes';
import { BookFactory } from '~/graphql/books/factory/book/BookFactory';
import { BookPropertiesFactory } from '~/graphql/books/factory/book/BookPropertiesFactory';
import { EBookChapterFactory } from '~/graphql/books/factory/book/EBookChapterFactory';
import { GetBookPageResult } from '~/graphql/books/factory/book/GetBookPageFactoryTypes';
import { CoverImagesFactory } from '~/graphql/books/factory/image/CoverImagesFactory';
import { GetBookPageQuery } from '~/graphql/books/GraphQLGeneratedCode';

export class GetBookPageFactory {
  static create({
    book, otherBooksByAuthor, cycleBooks,
  }: GetBookPageQuery['getBookPage']): GetBookPageResult {
    return {
      book: {
        ...BookFactory.create(book),
        ...BookPropertiesFactory.create(book),
        counters: {
          comments: book.counters.comments,
          likes: book.counters.likes,
          readCompleted: book.counters.readCompleted,
          readLater: book.counters.readLater,
          readNow: book.counters.readNow,
          rewards: book.counters.rewards,
          views: book.counters.views,
          dislikes: 0,
        },
        ebook: {
          bookId: book.ebook.bookId,
          available: book.ebook.available,
          bought: book.ebook.bought,
          canBuy: book.ebook.canBuy,
          price: book.ebook.price,
          discount: book.ebook.discount,
          purchase: book.ebook.purchase === null ? null : {
            price: book.ebook.purchase.price,
            boughtWithTimerDiscount: book.ebook.purchase.boughtWithTimerDiscount,
          },
          addedToAbonnement: book.ebook.addedToAbonnement,
          chapters: book.ebook.chapters.map((chapter) => EBookChapterFactory.create(chapter)),
        },
        audiobook: {
          bookId: book.audiobook.bookId,
          canListen: book.audiobook.canListen,
          bought: book.audiobook.bought,
          canBuy: book.audiobook.canBuy,
          price: book.audiobook.price,
          discount: book.audiobook.discount,
          available: book.audiobook.isAvailableForDesktop,
          purchase: book.audiobook.purchase === null ? null : {
            price: book.audiobook.purchase.price,
            boughtWithTimerDiscount: book.audiobook.purchase.boughtWithTimerDiscount,
          },
          chapters: book.audiobook.chapters.map((chapter) => ({
            available: chapter.available,
            chapterId: chapter.chapterId,
            createdAt: chapter.createdAt,
            associatedTextChapterId: chapter.associatedTextChapterId,
            name: chapter.name,
            audioUrl: chapter.audioUrl,
            free: chapter.free,
            duration: chapter.duration,
            status: String(chapter.status) as ChapterStatus,
            firstPublishedAt: chapter.firstPublishedAt,
            position: chapter.position,
            updatedAt: chapter.updatedAt,
            voicingStatus: chapter.voicingStatus,
          })),
        },
      },
      otherBooksByAuthor: otherBooksByAuthor.map((book) => ({
        id: book.id,
        name: book.name,
        slug: book.slug,
        exclusive: book.exclusive,
        free: book.free,
        genres: book.genres.map((genre) => ({
          slug: genre.slug,
          name: genre.name,
          id: genre.id,
        })),
        coverImages: CoverImagesFactory.create(book.coverImages),
        statusPublish: String(book.statusPublish) as PublishStatusEnum,
        annotation: book.annotation,
        statusComplete: String(book.statusComplete) as CompleteStatusEnum,
      })),
      cycleBooks: cycleBooks.map((book) => ({
        id: book.id,
        name: book.name,
        slug: book.slug,
        exclusive: book.exclusive,
        free: book.free,
        genres: book.genres.map((genre) => ({
          slug: genre.slug,
          name: genre.name,
          id: genre.id,
        })),
        coverImages: CoverImagesFactory.create(book.coverImages),
        statusPublish: String(book.statusPublish) as PublishStatusEnum,
        annotation: book.annotation,
        statusComplete: String(book.statusComplete) as CompleteStatusEnum,
      })),
    };
  }
}
