import { Dropdown } from 'antd';
import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { TextBoldStyle } from '~/atomic/Typography';
import { MyBooksOverlayFilter } from '~/feature/myBooks/MyBooksFilter/MyBooksOverlayFilter';
import { lessThan } from '~/lib/mediaQuery';

export const MyBooksFilter: FC = () => {
  const [isOpenFilter, setIsOpenFilter] = useState(false);

  const transitionOnMyBooksHandler = () => {
    setIsOpenFilter(false);
  };

  return (
    <SCDropdownWrapper>
      <Dropdown
        overlay={(
          <MyBooksOverlayFilter
            transitionOnMyBooks={transitionOnMyBooksHandler}
          />
        )}
        trigger={['click']}
        onOpenChange={() => setIsOpenFilter((isOpen) => !isOpen)}
        placement="bottomLeft"
        open={isOpenFilter}
      >
        <SCDropdownLabelWrapper>
          <SCLabel>
            Моя библиотека
          </SCLabel>
          <SCTriangleArrow />
        </SCDropdownLabelWrapper>
      </Dropdown>
    </SCDropdownWrapper>
  );
};

const SCTriangleArrow = styled.div`
  width: 13px;
  height: 13px;
  cursor: pointer;
  margin-top: -5px;
  mask-image: url('/icon/arrow-sort-down.svg');
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
  transition: 0.3s;
  background-color: var(--white-color);
`;

const SCLabel = styled.span`
  ${TextBoldStyle};
  cursor: pointer;
  color: var(--white-color);
  transition: 0.3s;
`;

const SCDropdownWrapper = styled.div`
  margin-right: 0;    
  
  ${lessThan('md')} {
    display: none;
  }
`;

const SCDropdownLabelWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  background: var(--primary-color);
  border: 1px solid var(--primary-hover-color);
  padding: 6px 12px 6px 16px;
  border-radius: 2px;
  height: 44px;
  
  ${SCLabel} {
    margin-right: 8px;
  }
`;
