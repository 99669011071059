/* eslint-disable max-len */
import classNames from 'classnames';
import React from 'react';

export const GoogleIcon = ({ className, ...props }: React.HTMLAttributes<HTMLElement>) => {
  return (
    <i className={classNames('bookriver-icon-GoogleIcon', className)} {...props}>
      <i className="path1 bookriver-icon-" />
      <i className="path2 bookriver-icon-" />
      <i className="path3 bookriver-icon-" />
      <i className="path4 bookriver-icon-" />
    </i>
  );
};
