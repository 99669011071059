import React, { FC } from 'react';
import styled from 'styled-components';

import { User } from '~/api/account/accountApiTypes';
import { MiniatureSizesEnum } from '~/api/book/bookApiTypes';
import { Avatar } from '~/atomic/atom/Avatar/Avatar';
import { AuthorLink } from '~/atomic/atom/links/dynamicLinks';

export const AuthorPreview: FC<{ author: User }> = ({ author }) => {
  return (
    <SCAuthorPreview>
      <AuthorLink username={author.username}>
        <SCLink>
          <SCAvatar
            avatarSize={MiniatureSizesEnum.L}
            imageSize={44}
            avatarImages={author?.avatarImages}
            alt={author?.name}
            userName={author?.name}
          />
          <SCAuthorInfo>
            <SCAuthorName>
              { author.name }
            </SCAuthorName>
          </SCAuthorInfo>
        </SCLink>
      </AuthorLink>
    </SCAuthorPreview>
  );
};

const SCAvatar = styled(Avatar)`
  margin-right: 16px;
`;

const SCLink = styled.a`
  display: flex;
  flex-direction: row;
  cursor: pointer;
  padding: 8px;

  &:hover {
    background-color: var(--bg-color);
  }
`;

export const SCAuthorPreview = styled.div`

`;

const SCAuthorInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const SCAuthorName = styled.span`
  font-family: var(--main-font);;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 2px;

  color: var(--black-color)
`;
