import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '~/store';

export const authorSelector = (state: RootState) => state.author;

export const currentAuthorSelector = createSelector(
  authorSelector,
  (author) => author.currentAuthor,
);
