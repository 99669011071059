import { AvatarImage } from '~/api/account/accountApiTypes';
import { MyBookStatusEnum } from '~/api/account/myBooksApiTypes';
import { WithToken } from '~/api/auth/authApiTypes';
import { BookTag } from '~/api/book/bookTagApiTypes';
import { Cycle } from '~/api/book/cycleApiTypes';
import { Genre } from '~/api/book/genreApiTypes';
import { CommentAccessStatusEnum } from '~/api/comment/commentApiTypes';
import { BookReaction } from '~/graphql/books/factory/book/BookFactoryTypes';

export interface BookLog {
  message: string;
  context: string;
  createdAt: string;
}

export enum AgeRatingEnum {
  '0+' = '0+',
  '6+' = '6+',
  '12+' = '12+',
  '16+' = '16+',
  '18+' = '18+',
}

export interface BookProperties {
  bookId: BookIdType;
  freeChaptersCount?: number;
  annotation: string;
  ageRating: AgeRatingEnum;
  note: string;
  genres: Genre[];
  tags: BookTag[]
  isFoulLanguage: boolean;
  commentAccessStatus: CommentAccessStatusEnum;
  redLine: boolean;
  cycleId: number;
  download: boolean;
  containsEroticScenes: boolean;
  canAuthorEditGenres: boolean;
  canBeCompleted: boolean;
  profitableStart: {
    available: boolean;
  };
  paidBookSubscription: {
    startedAt?: string;
    expiredAt?: string;
  };
  addedToAbonnement: boolean;
  canAuthorAddBookToAbonnement: boolean;
  scheduledDateRemovalFromAbonnement: string;
}

export enum PublishStatusEnum {
  Published = 'published',
  NotPublished = 'not_published',
}

export enum BookTypeEnum {
  Novel = 'novel',
  Story = 'story',
}

export enum BookTypeNameEnum {
  novel = 'Роман',
  story = 'Рассказ',
}

export enum CompleteStatusEnum {
  Writing = 'writing',
  Complete = 'complete',
  Reference = 'reference',
  Freeze = 'freeze',
}

export enum BookTypesText {
  writing = 'в процессе',
  complete = 'завершена',
  reference = 'для ознакомления',
  freeze = 'заморожена',
  not_published = 'неопубликована',
  'not-published' = 'неопубликована',
  published = 'опубликована',
  draft = 'пишется',
  temp_not_available = 'временно недоступна',
}

export interface Author {
  id: number;
  name: string;
  username?: string;
  yandexMetrika?: string;
}

export interface ChangeBookCycleParams {
  bookId: BookIdType;
  cycleId: string;
  numInCycle?: string;
}

export enum MiniatureSizesEnum {
  S = 's',
  M = 'm',
  L = 'l',
  Original = 'original',
}

export enum MiniatureFormatEnum {
  JPG = 'jpg',
  JPEG = 'jpeg',
  WEBP = 'webp',
}

export enum ExportBookFormatsEnum {
  Fb2 = 'fb2',
}

export interface CoverImage {
  url: string;
  format: MiniatureFormatEnum;
  sizeType: MiniatureSizesEnum;
}

export interface BookDiscount {
  grade?: number;
  percent?: number;
  price?: number;
  expiredAt?: string;
}

export enum BookSortingEnum {
  RecommendationScore = 'recommendation-score',
  RecommendationNumberOfRecommenders = 'recommendation-number-of-recommenders',
  Popularity = 'popularity',
  Newest = 'newest',
  Bestseller = 'bestseller',
  BestsellerLastMonth = 'bestseller-last-month',
  LastUpdate = 'last-update',
  Rand = 'rand',
}

export interface BookRecommender {
  name: string;
  username: string;
  avatarImages?: AvatarImage[];
}

export interface BookRecommendation {
  totalRecommenders: number;
  feedbackRating: boolean;
  recommenders: BookRecommender[];
}

export interface Book {
  id: BookIdType;
  name: string;
  price?: number;
  audiobook?: {
    price: number;
    bought: boolean;
    discount: BookDiscount;
    canListen: boolean;
    available: boolean;
    canBuy: boolean;
  };
  coverImages?: CoverImage[];
  statusPublish?: PublishStatusEnum;
  statusComplete?: CompleteStatusEnum;
  type: BookTypeEnum;
  age: AgeRatingEnum;
  free: boolean;
  symbols: number;
  slug: string;
  authorPages: number;
  createdAt: string;
  updatedAt?: string;
  annotation: string;
  cycle: {
    id: number;
    name: string;
    numInCycle: number;
    slug: string;
  },
  genres?: Genre[];
  authors: Author[];
  isFoulLanguage?: boolean;
  myBook?: {
    symbols?: number;
    buy?: boolean;
    note?: string;
    reaction: BookReaction;
    readStatus: MyBookStatusEnum;
  };
  recommendation?: BookRecommendation;
  isAvailableEdit?: boolean;
  exclusive: boolean;
  redLine?: boolean;
  discount?: BookDiscount;
  allowToSellBookByChapter: boolean;
  chapterPrice: number;
  expectedAmountOfChapters: number;
  spentOnChapters?: number;
  addedToAbonnement?: boolean;
  partnerProgram?: {
    percent: number;
  };
  deleted?: boolean;
  ebook?: {
    available?: boolean;
    addedToAbonnement?: boolean;
  }
  advertisingFromBookriver: {
    VMDAdCampaign: {
      utmQueryParams: {
        [key: string]: string;
      }
    }
  }
}

export interface CreateBookParams {
  name: string;
  cycleId?: string;
  type: BookTypeEnum;
  file?: File;
}

export interface ChangeNameParams {
  id: BookIdType;
  slug: string;
  name: string;
}

export interface ChangeCompletenessStatusParams {
  id: BookIdType;
  statusComplete: CompleteStatusEnum;
}

export interface ChangePublishStatusParams {
  id: BookIdType;
  statusPublish: PublishStatusEnum;
}

export interface ChangePriceFields {
  bookId: BookIdType;
  price: number;
  audiobookPrice: number;
  free: string;
  countFreeChapters: number;
  allowToSellBookByChapter: boolean;
  chapterPrice: number;
  exclusive: boolean;
}

export interface ChangePriceRejectValue {
  price: string[];
  free: string[];
  countFreeChapters: string[];
  chapterPrice: string[];
}

export interface ChangePriceParams extends ChangePriceFields {
  bookId: BookIdType;
}

export interface GetMyBooksParams {
  page?: number,
  perPage?: number,
}

export interface GetPublicBookParams {
  key: string;
}

export type BookIdType = number | string;

export interface GetBookParams {
  id: BookIdType;
}

export interface GetBookBySlugParams {
  slug: string;
}

export interface DeleteBookParams {
  id: BookIdType;
}

export interface GetGenresParams {
  id: string;
}

export interface AddCoverParams {
  id: string;
  image: File;
}

export interface AddCoverResponse {
  imageUrl: string;
}

export interface DeleteCoverParams {
  id: BookIdType;
}

export interface ImportBookRejectValue {
  file?: string[];
}

export interface ImportBookParams {
  id: string;
  file: File;
}

export interface ExportBookParams {
  bookId: BookIdType;
  format: ExportBookFormatsEnum;
}

export interface GetBookLogsParams {
  id: BookIdType;
  page: number;
}

export interface GetPropertiesParams {
  id: BookIdType;
}

export interface AddPropertiesFields {
  annotation: string;
  ageRating: AgeRatingEnum;
  note: string;
  isFoulLanguage: boolean;
  tags?: string[];
  genres?: string[];
  commentAccessStatus?: CommentAccessStatusEnum;
  cycle?: Partial<Cycle>;
  redLine?: boolean;
  download?: boolean;
  containsEroticScenes?: boolean;
}

export interface AddPropertiesRejectValue {
  annotation?: string[];
  ageRating?: string[];
  note?: string[];
  isFoulLanguage?: string[];
  tags?: string[];
  genres?: string[];
  commentAccessStatus?: string[];
  cycleId?: string[];
  redLine?: string[];
  download?: string[];
  containsEroticScenes?: string[];
}

export interface AddPropertiesParams extends AddPropertiesFields {
  id: BookIdType;
}

export interface ChangeIndexInCycleParams {
  bookId: BookIdType;
  numInCycle: number;
}

export interface GetBookListParams extends WithToken {
  genre?: string;
  additionalGenres?: string;
  statusComplete?: CompleteStatusEnum;
  free?: boolean;
  download?: boolean;
  exclusive?: boolean;
  withAudiobook?: boolean;
  withAbonnement?: boolean;
  withRecommendations?: boolean;
  minDiscountPercent?: number;
  type?: BookTypeEnum;
  tag?: string;
  page?: number;
  perPage?: number;
  sortingType?: BookSortingEnum;
  favoriteGenres?: string;
  canBeBoughtByChapter?: boolean;
  hideAudiobooksWithoutEbooks?: boolean;
  withAdvertisingFromBookriver?: boolean;
}

export interface GetNextBookInCycleParams {
  bookId: BookIdType;
}

export interface GetSimilarBooksParams {
  bookId?: BookIdType;
  limit?: number;
}

export interface CreateBookResult {
  book: Book;
  error?: {
    type: 'importBookError',
    message: string;
  }
}

export enum BookLayoutEnum {
  LIST = 'list',
  GRID = 'grid',
}
