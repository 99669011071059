import React from 'react';
import styled from 'styled-components';

import { AuthorLink, BookLink } from '~/atomic/atom/links/dynamicLinks';
import Modal from '~/atomic/molecula/Modal';
import { TextBoldStyle, TextRegStyle } from '~/atomic/Typography';
import {
  audioPlayerActions,
  audioPlayerBookAuthorSelector,
  audioPlayerSelector,
} from '~/feature/audio/audioPlayer.slice';
import { AudioPlayerChapterList } from '~/feature/audio/audioPlayerChapterList/AudioPlayerChapterList';
import { lessThan } from '~/lib/mediaQuery';
import { useAppDispatch, useAppSelector } from '~/store';

export const AudioPlayerChapterListModal = () => {
  const dispatch = useAppDispatch();
  const { isOpenChapterListModal } = useAppSelector(audioPlayerSelector);
  const { book } = useAppSelector(audioPlayerSelector);
  const bookAuthor = useAppSelector(audioPlayerBookAuthorSelector);

  const closeModalHandler = () => {
    dispatch(audioPlayerActions.setIsOpenChapterListModal(false));
  };

  return (
    <Modal
      open={isOpenChapterListModal}
      onCancel={closeModalHandler}
    >
      <SCHeader>
        <BookLink slug={book?.slug}>
          <SCName>{book?.name}</SCName>
        </BookLink>
        <SCCoAuthors>
          <AuthorLink username={bookAuthor?.username ?? ''} key={bookAuthor?.id ?? 0}>
            <SCCoAuthorsLink>
              {bookAuthor?.name ?? 'Имя автора'}
            </SCCoAuthorsLink>
          </AuthorLink>
        </SCCoAuthors>
      </SCHeader>
      <AudioPlayerChapterList />
    </Modal>
  );
};

const SCHeader = styled.div` && {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 10px;
    padding-left: 12px;
    margin-bottom: 0;
    border-bottom: 1px solid var(--border-color);
  }
`;

const SCCoAuthorsLink = styled.a`
  ${TextRegStyle};
  margin-right: 3px;
  color: var(--gray-color);

  &:not(:last-child) {
    &:after {
      content: ",";
    }
  }

  span {
    transition: color 0.3s ease;
    &:hover {
      color: inherit;
    }
  }
`;

const SCCoAuthors = styled.div`
  ${TextRegStyle};
`;

const SCName = styled.a`
  ${TextBoldStyle};
  color: var(--black-color);
  cursor: pointer;

  ${lessThan('xs')} {
    font-size: 15px;
    line-height: 16px;
  }
`;
