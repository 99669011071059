import Script from 'next/script';
import React, { useEffect } from 'react';

import { environments } from '~/lib/const';

export const YandexMetrikaInit = () => {
  useEffect(() => {
    if (environments.isClient && environments.isProduction) {
      try {
        (window as any).dataLayerSite = (window as any).dataLayerSite || [];
        (window as any).dataLayer = (window as any).dataLayer || [];
      } catch (e) {
        console.log(e);
      }
    }
  }, [environments.isClient, environments.isProduction]);

  if (!environments.isProduction || !environments.isClient) {
    return null;
  }

  return (
    <Script
      async
      dangerouslySetInnerHTML={{
        __html: `
        (function(m,e,t,r,i,k,a){m[i]=m[i]||function(){(m[i].a=m[i].a||[]).push(arguments)};
         m[i].l=1*new Date();k=e.createElement(t),a=e.getElementsByTagName(t)[0],
         k.async=1,k.src=r,a.parentNode.insertBefore(k,a)})
         (window, document, "script", "https://mc.yandex.ru/metrika/tag.js", "ym");
      
         ym(66920041, "init", {
              clickmap:true,
              trackLinks:true,
              accurateTrackBounce:true,
              ecommerce:"dataLayerSite"
              });`,
      }}
    />
  );
};

export const ym = (...props) => (
  environments.isClient && environments.isProduction
    ? (window as any)?.ym(66920041, ...props)
    : undefined);

export const ymOther = (...props) => (
  environments.isClient && environments.isProduction
    ? (window as any).ym(...props)
    : undefined);
