import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const activateCatalogTimerDiscountSliceName = 'activateCatalogTimerDiscount';

const activateCatalogTimerDiscountSlice = createSlice({
  name: activateCatalogTimerDiscountSliceName,
  initialState: {
    isSuccessActivateCatalogTimerDiscount: false,
    isShowActivateCatalogTimerDiscountModal: false,
    isShowActivateCatalogTimerDiscountLoadingModal: false,
    isOpenLoginModalWithActivateCatalogTimerDiscount: false,
    isOpenRegisterModalWithActivateCatalogTimerDiscount: false,
    isOpenSaveUserModalWithActivateCatalogTimerDiscount: false,
  },
  reducers: {
    setIsSuccessActivateCatalogTimerDiscount: (state, action: PayloadAction<boolean>) => {
      state.isSuccessActivateCatalogTimerDiscount = action.payload;
    },
    setIsOpenLoginModalWithActivateCatalogTimerDiscount: (state, action: PayloadAction<boolean>) => {
      state.isOpenLoginModalWithActivateCatalogTimerDiscount = action.payload;
    },
    setIsOpenRegisterModalWithActivateCatalogTimerDiscount: (state, action: PayloadAction<boolean>) => {
      state.isOpenRegisterModalWithActivateCatalogTimerDiscount = action.payload;
    },
    setIsOpenSaveUserModalWithActivateCatalogTimerDiscount: (state, action: PayloadAction<boolean>) => {
      state.isOpenSaveUserModalWithActivateCatalogTimerDiscount = action.payload;
    },
    setIsShowActivateCatalogTimerDiscountLoadingModal: (state, action: PayloadAction<boolean>) => {
      state.isShowActivateCatalogTimerDiscountLoadingModal = action.payload;
    },
    setIsShowActivateCatalogTimerDiscountModal: (state, action: PayloadAction<boolean>) => {
      state.isShowActivateCatalogTimerDiscountModal = action.payload;
    },
  },
});

export const {
  actions: activateCatalogTimerDiscountActions,
  reducer: activateCatalogTimerDiscountReducer,
} = activateCatalogTimerDiscountSlice;
