import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import Router from 'next/router';
import qs from 'query-string';
import React from 'react';

import { notificationsApi } from '~/api/account/notificationsApi';
import { authApi } from '~/api/auth/authApi';
import {
  ConfirmRegistrationRequestParams,
  ForgotRequestParams,
  LoginRequestParams,
  LoginRequestRejectValue,
  LoginResponse, ProviderEnum, RegisterRequestParams,
  ResetPasswordErrorValid,
  ResetPasswordRequestParams,
} from '~/api/auth/authApiTypes';
import { baseFrontServerUrl } from '~/api/provider/provider.config';
import { HttpValidationError } from '~/api/provider/providerErrors';
import { RejectedRequest } from '~/api/provider/providerTypes';
import { pagesConfigStore } from '~/atomic/atom/links/pagesConfigStore';
import { headerActions } from '~/atomic/organism/Header/header.slice';
import { closeAudioPlayer } from '~/feature/audio/audioPlayer.data';
import { authActions } from '~/feature/authorization/auth.slice';
import { openModal } from '~/feature/authorization/authorizationModal.slice';
import {
  deleteUserEmailFromLocalStorage, getUserEmailInLocalStorage,
  saveUserEmailInLocalStorage,
} from '~/feature/authorization/SaveUserLoginModal/saveUserEmailInLocalStorage';
import { deleteTokenFromHeaders, setTokenToHeaders } from '~/feature/authorization/setTokenToHeaders';
import { checkDeletePartner } from '~/feature/finance/partnerProgram/partnerProgram.data';
import { updatePartnerFromReferralLink } from '~/feature/referralLink/referralLink.data';
import { setUser } from '~/feature/user/user.data';
import { userActions } from '~/feature/user/user.slice';
import { showAlert } from '~/feature/userAlert/showAlert';
import {
  trackingAuthorizationUserInVkMetrika,
  trackingRegisterUserInVkMetrika,
} from '~/feature/vk/VkMetrikaInit';
import { ym } from '~/feature/yandex/YandexMetrikaInit';
import { environments } from '~/lib/const';
import { createAppThunk, RootState } from '~/store';

const authPreviouslyName = 'auth_previously';

export const setTokenToCookie = async (token: string, ttl: number) => {
  await fetch(`${baseFrontServerUrl}/api/auth/setToken`, {
    method: 'POST',
    body: JSON.stringify({
      data: {
        token,
        ttl,
      },
    }),
  });
};

export const removeTokenFromCookie = async () => {
  await fetch(`${baseFrontServerUrl}/api/auth/removeToken`);
};

export const login = createAsyncThunk<
void,
LoginRequestParams & {
  afterLogin?:(
  ) => void
},
{
  rejectValue: { message?: string; errors?: LoginRequestRejectValue }, state: RootState
}>(
    'auth/login',
    async (
      {
        email,
        password,
        rememberMe = 0,
        afterLogin,
      },
      thunkAPI,
    ) => {
      thunkAPI.dispatch(authActions.onLoadingStart('login'));
      try {
        const result = await authApi.login({
          email,
          password,
          rememberMe,
        });

        if (result && 'data' in result) {
          await thunkAPI.dispatch(successAuth(result));
          thunkAPI.dispatch(saveUserEmailInLocalStorage({ user: result.data }));
          thunkAPI.dispatch(openModal(''));
          if (afterLogin) {
            afterLogin();
          }
        }
      } catch (error) {
        if (error instanceof HttpValidationError) {
          return thunkAPI.rejectWithValue({ errors: error.getErrors() });
        }

        if (environments.isClient && error instanceof Error) {
          const { message } = await import('~/atomic/atom/message');
          message.error(error.message);
          return thunkAPI.rejectWithValue({ message: error.message });
        }
      } finally {
        thunkAPI.dispatch(authActions.onLoadingEnd('login'));
      }
    },
    );

export const getProviderLoginURL = createAsyncThunk<
void,
{ provider: ProviderEnum, queries?: { name: string; value: string }[] },
{ state: RootState; }>(
  'auth/getProviderLoginURL',
  async (
    data,
    thunkAPI,
  ) => {
    thunkAPI.dispatch(authActions.onLoadingStart('provider'));
    try {
      const parsedLocation = qs.parseUrl(String(window.location));

      parsedLocation.query.code = null;
      parsedLocation.query.provider = null;

      if (data?.queries?.length) {
        data.queries?.forEach((query) => {
          parsedLocation.query[query.name] = query.value;
        });
      }

      const result = await authApi.loginProvider(
        {
          provider: data.provider,
          state: qs.stringifyUrl(parsedLocation),
        },
        document.location.origin.includes('localhost:3000'),
      );

      if ('redirectUrl' in result) {
        window.location.href = result.redirectUrl;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    } finally {
      thunkAPI.dispatch(authActions.onLoadingEnd('provider'));
    }
  },
);

export const logout = createAsyncThunk<void, void, { state: RootState }>(
  'auth/logout',
  async (
    _,
    thunkAPI,
  ) => {
    thunkAPI.dispatch(authActions.onLoadingStart('logout'));
    thunkAPI.dispatch(userActions.setLoading(true));
    try {
      await authApi.logout();
      await thunkAPI.dispatch(successLogout());
    } catch (error) {
      await thunkAPI.dispatch(successLogout());
      return thunkAPI.rejectWithValue(error);
    } finally {
      thunkAPI.dispatch(authActions.onLoadingEnd('logout'));
      thunkAPI.dispatch(userActions.setLoading(false));
    }
  },
);

export const register = createAsyncThunk<void, RegisterRequestParams, {
  rejectValue: { error: string, errors: RejectedRequest<RegisterRequestParams> }, state: RootState;
}>(
  'auth/register',
  async (
    {
      email,
      name,
      password,
      gRecaptchaResponse,
    }: RegisterRequestParams,
    thunkAPI,
  ) => {
    thunkAPI.dispatch(authActions.onLoadingStart('register'));

    try {
      const result = await authApi.register({
        email,
        password,
        name,
        gRecaptchaResponse,
      });

      if (result?.message) {
        const { message } = await import('~/atomic/atom/message');
        message.success(
          (
            <>
              На вашу почту
              {' '}
              <b>{email}</b>
              {' '}
              отправлено письмо для подтверждения регистрации
            </>
          ),
          8,
        );
      }

      if ('token' in result) {
        if (environments.isProduction && (window as any)?.VK) {
          (window as any).VK?.Goal('complete_registration');
        }
        await thunkAPI.dispatch(successAuth(result));
        await thunkAPI.dispatch(successRegister());
        thunkAPI.dispatch(saveUserEmailInLocalStorage({ user: result.data }));
        ym('reachGoal', 'register-form');
      }
    } catch (error) {
      if (error instanceof HttpValidationError) {
        const { message } = await import('~/atomic/atom/message');

        message.error(error.message);
        return thunkAPI.rejectWithValue(
          {
            error: error.message,
            errors: (error as any)?.errors ?? {},
          },
        );
      }
    } finally {
      thunkAPI.dispatch(authActions.onLoadingEnd('register'));
    }
  },
);

export const successAuth = createAsyncThunk<void,
LoginResponse, { state: RootState }>(
  'auth/successAuth',
  async (data, thunkAPI) => {
    try {
      trackingAuthorizationUserInVkMetrika();
      setTokenToHeaders();
      thunkAPI.dispatch(userActions.changeToken(data.token));
      thunkAPI.dispatch(setUser(data.data));
      thunkAPI.dispatch(updatePartnerFromReferralLink());
      thunkAPI.dispatch(checkDeletePartner());
      thunkAPI.dispatch(setAuthPreviouslyInLocalStorage());
      thunkAPI.dispatch(userActions.setIsAuth(true));
      thunkAPI.dispatch(showAlert());
      await setTokenToCookie(data.token, data.ttl);
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const successLogout = createAsyncThunk<
void,
void,
{ state: RootState }>(
  'auth/successLogout',
  async (data, thunkAPI) => {
    try {
      await removeTokenFromCookie();
      deleteTokenFromHeaders();
      thunkAPI.dispatch(userActions.changeToken(''));
      thunkAPI.dispatch(closeAudioPlayer());
      thunkAPI.dispatch(userActions.changeUser({}));
      thunkAPI.dispatch(userActions.changeUserCounters({}));
      thunkAPI.dispatch(headerActions.setIsMobileUserInfoOpen(false));
      thunkAPI.dispatch(userActions.setIsAuth(false));
      await Router.push(pagesConfigStore.home.url, undefined, { shallow: true });
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const successRegister = createAsyncThunk<void,
void>(
  'auth/successRegister',
  async (data, thunkAPI) => {
    try {
      ym('reachGoal', 'success-register');
      trackingRegisterUserInVkMetrika();
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const setAuthPreviouslyInLocalStorage = createAsyncThunk<void,
void>(
  'auth/setAuthHistoryInLocalStorage',
  async (data, thunkAPI) => {
    try {
      if (window?.localStorage) {
        localStorage.setItem(authPreviouslyName, 'true');
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getAuthPreviouslyInLocalStorage = createAsyncThunk<void,
void>(
  'auth/setAuthHistoryInLocalStorage',
  async (data, thunkAPI) => {
    try {
      if (window?.localStorage) {
        const result = localStorage.getItem(authPreviouslyName);
        thunkAPI.dispatch(authActions.setAuthPreviously(result === 'true'));
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const reset = createAsyncThunk<void, ResetPasswordRequestParams, {
  rejectValue: { error?: ResetPasswordErrorValid, message: string };
}>(
  'auth/reset',
  async (
    {
      password,
      token,
    },
    thunkAPI,
  ) => {
    thunkAPI.dispatch(authActions.onLoadingStart('reset'));

    try {
      const result = await authApi.resetPassword({
        password,
        token,
      });

      thunkAPI.dispatch(userActions.changeUser({}));
      if (result && 'data' in result) {
        return result.data;
      }
    } catch (e) {
      if (e?.errors) {
        return thunkAPI.rejectWithValue({
          error: e.errors,
          message: e.message,
        });
      }
      if (environments.isClient) {
        const { message } = await import('~/atomic/atom/message');
        message.error(e.message);
      }
      return thunkAPI.rejectWithValue({ message: e.message });
    } finally {
      thunkAPI.dispatch(authActions.onLoadingEnd('reset'));
    }
  },
);

export const confirmRegistration = createAsyncThunk<void, ConfirmRegistrationRequestParams, {
  rejectValue: { error?: ResetPasswordErrorValid, message: string };
}>(
  'auth/reset',
  async (
    data,
    thunkAPI,
  ) => {
    thunkAPI.dispatch(authActions.onLoadingStart('confirm'));

    try {
      await authApi.confirmRegistration(data);
    } catch (e) {
      if (environments.isClient) {
        const { message } = await import('~/atomic/atom/message');
        message.error(e.message);
      }
      return thunkAPI.rejectWithValue({ message: e.message });
    } finally {
      thunkAPI.dispatch(authActions.onLoadingEnd('confirm'));
    }
  },
);

export const providerCallback = createAsyncThunk<void,
void,
{ state: RootState }>(
  'auth/providerCallback',
  async (
    _,
    thunkAPI,
  ) => {
    thunkAPI.dispatch(authActions.onLoadingStart('providerCallback'));
    try {
      const parsedUrl = qs.parseUrl(String(window.location));

      const code = parsedUrl.query.code as string;
      const providerEnum = parsedUrl.query.provider as ProviderEnum;
      const user = 'user' in parsedUrl.query ? parsedUrl.query.user as string : undefined;

      if (!!code && !!providerEnum) {
        parsedUrl.query.code = null;
        parsedUrl.query.provider = null;
        if (user) parsedUrl.query.user = null;
        const url = new URL(qs.stringifyUrl(parsedUrl));
        const redirectUrl = url.pathname + url.search + url.hash;
        await Router.replace(Router.pathname, redirectUrl, { shallow: true });

        const result = await authApi.loginProviderCallback({
          code,
          provider: providerEnum,
          user,
        });

        if (result && 'data' in result) {
          await thunkAPI.dispatch(successAuth(result));
          thunkAPI.dispatch(deleteUserEmailFromLocalStorage());
          if (result?.isNewUser) {
            ym('reachGoal', 'register-social-network');
            await thunkAPI.dispatch(successRegister());
          }

          if (environments.isProduction && (window as any)?.VK) {
            (window as any).VK?.Goal('complete_registration');
          }
        }
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
        return thunkAPI.rejectWithValue(error);
      }
    } finally {
      thunkAPI.dispatch(authActions.onLoadingEnd('providerCallback'));
    }
  },
);

export const sendTokenToServer = createAsyncThunk<void, { currentToken: string; userId: string }, {
  rejectValue: { error: string }; state: RootState;
}>(
  'sendTokenToServer',
  async (
    {
      currentToken,
      userId,
    },
  ) => {
    try {
      const {
        isTokenSendToServer,
        setTokenSendToServer,
      } = await import('~/feature/firebase/firebase');
      if (!isTokenSendToServer({
        currentToken,
        userId,
      })) {
        await notificationsApi.saveFCMToken({ fcmToken: currentToken });
        setTokenSendToServer({
          currentToken,
          userId,
        });
      }
    } catch (e) {
      console.error(e);
    }
  },
);

export const forgot = createAsyncThunk<void, ForgotRequestParams>(
  'auth/forgot',
  async (
    data,
    thunkAPI,
  ) => {
    thunkAPI.dispatch(authActions.onLoadingStart('forgot'));

    try {
      await authApi.forgot(data);
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue(error);
    } finally {
      thunkAPI.dispatch(authActions.onLoadingEnd('forgot'));
    }
  },
);

export const openLoginModal = createAppThunk(
  'auth/openLoginModal',
  async (_, thunkAPI) => {
    const userEmailFromStorage = unwrapResult(await thunkAPI.dispatch(getUserEmailInLocalStorage()));

    if (userEmailFromStorage) {
      thunkAPI.dispatch(openModal('saveUserLogin'));
    } else {
      thunkAPI.dispatch(openModal('login'));
    }
  },
);
