import React, { FC } from 'react';
import styled from 'styled-components';

import { TextRegStyle } from '~/atomic/Typography';
import { openAudioPlayer } from '~/feature/audio/audioPlayer.data';
import {
  AudioPlayerChapter,
  audioPlayerSelector,
} from '~/feature/audio/audioPlayer.slice';
import { showAudioPlayerTime } from '~/feature/audio/AudioPlayerProgress';
import { lessThan } from '~/lib/mediaQuery';
import { useAppDispatch, useAppSelector } from '~/store';

interface AudioPlayerChapterItemProps {
  chapter: AudioPlayerChapter;
}

export const AudioPlayerChapterItem: FC<AudioPlayerChapterItemProps> = ({ chapter }) => {
  const dispatch = useAppDispatch();
  const { audioPath } = useAppSelector(audioPlayerSelector);
  const chapterUrl = chapter?.url;
  const isActive = audioPath === chapterUrl;
  const isAvailable = chapter?.available;

  const playChapterHandler = () => {
    if (isAvailable) {
      dispatch(openAudioPlayer({ filePath: chapterUrl }));
    }
  };

  return (
    <SCAudioPlayerChapterItem
      data-is-active={isActive}
      data-is-available={isAvailable}
      onClick={playChapterHandler}
    >
      <SCChapterName>
        {chapter.name}
      </SCChapterName>
      <SCChapterTime>
        {chapter?.duration ? showAudioPlayerTime(chapter.duration * 1000) : null }
      </SCChapterTime>
    </SCAudioPlayerChapterItem>
  );
};

const SCChapterName = styled.span`
  ${TextRegStyle};
  color: var(--black-color);
`;

const SCChapterTime = styled.span`
  ${TextRegStyle};
  color: var(--black-color);
  padding-left: 45px;
  max-width: 120px;
  width: 100%;
  text-align: center;
  
  ${lessThan('md')} {
    padding-left: 15px;
  }
`;

const SCAudioPlayerChapterItem = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 12px;
  cursor: pointer;
  width: 100%;
  min-width: 700px;
  
  &[data-is-active="true"] {
    ${SCChapterName} {
      color: var(--primary-color);
    }
    
    ${SCChapterTime} {
      color: var(--primary-color);
    }
  }
  
  &[data-is-can-listen="false"] {
    cursor: auto;
    pointer-events: none;

    ${SCChapterName} {
      color: var(--gray-color);
    }

    ${SCChapterTime} {
      color: var(--gray-color);
      position: relative;
    }
  }
  
  &[data-is-available="false"] {
    cursor: auto;
    pointer-events: none;
    
    ${SCChapterName} {
      color: var(--gray-color);
    }
    
    ${SCChapterTime} {
      color: var(--gray-color);
      position: relative;  
      
      &:before {
        content: '';
        display: block;
        position: absolute;
        left: 5px;
        top: 50%;
        transform: translateY(-50%);
        background: url("/icon/lock.svg") no-repeat center center / cover;
        width: 16px;
        height: 16px;

        ${lessThan('md')} {
          left: 15px;
        }
      }
    }
  }

  ${lessThan('md')} {
    min-width: auto;
  }
`;
