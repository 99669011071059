import React, { FC } from 'react';
import styled from 'styled-components';

import { AuthorBlogLink } from '~/atomic/atom/links/dynamicLinks';
import { PaperStyle } from '~/atomic/atom/PaperStyle';
import { Skeleton } from '~/atomic/atom/Skeleton';
import { PageTitleStyle } from '~/atomic/Typography';
import { AuthorPostItem } from '~/feature/blogs/AuthorPostsWitdget/AuthorPostItem';
import { useGetAuthorPostsWidget } from '~/feature/blogs/AuthorPostsWitdget/authorPostsWidgey.data';
import { lessThan } from '~/lib/mediaQuery';

export interface AuthorPostsWitdgetProps {
  authorUsername: string;
}

export const AuthorPostsWitdget: FC<AuthorPostsWitdgetProps> = ({ authorUsername }) => {
  const { isLoading, data: posts } = useGetAuthorPostsWidget(authorUsername);

  if (posts?.length === 0 && !isLoading) {
    return null;
  }

  if (isLoading) {
    return (
      <SCAuthorPostsWitdget>
        <Skeleton title active paragraph={false} />
        <SCList>
          {Array(4).fill('dummy').map((_, index) => (
            <Skeleton title active paragraph={{ rows: 2 }} key={index} />
          ))}
        </SCList>
      </SCAuthorPostsWitdget>
    );
  }

  return (
    <SCAuthorPostsWitdget>
      <AuthorBlogLink username={authorUsername}>
        <SCTitle>
          Новости автора
        </SCTitle>
      </AuthorBlogLink>
      <SCList>
        {posts?.map((post) => (
          <AuthorPostItem key={post.id} post={post} />
        ))}
      </SCList>
    </SCAuthorPostsWitdget>
  );
};

const SCAuthorPostsWitdget = styled.div`
  ${PaperStyle};
  padding: 20px 24px;

  ${lessThan('sm')} {
    padding: 12px;
    margin: 0 16px;
  }
`;

const SCTitle = styled.a`
  ${PageTitleStyle};
  display: inline-flex;
  margin-bottom: 16px;
`;

const SCList = styled.ul`
  > *:not(:last-child) {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid var(--gray3-color);
    
    ${lessThan('sm')} {
      padding-bottom: 12px;
      margin-bottom: 12px;
    }
  }
`;
