import React, { forwardRef } from 'react';
import styled from 'styled-components';

import { SearchBooksLink } from '~/atomic/atom/links/dynamicLinks';
import { Loader } from '~/atomic/atom/Loader';
import { searchByPlaceSelector } from '~/feature/search/search.selector';
import {
  searchActions,
  SearchFieldPlaces,
} from '~/feature/search/search.slice';
import { useAppDispatch, useAppSelector } from '~/store';

import { AuthorPreview, SCAuthorPreview } from './AuthorPreview';
import { BookPreview, SCBookPreview } from './BookPreview';

export const SearchResult = forwardRef<
HTMLDivElement,
{ place: SearchFieldPlaces, width: number; }
>(
  ({ place, width }, ref) => {
    const {
      books, authors, total, keyword, loading,
    } = useAppSelector(searchByPlaceSelector(place));
    const dispatch = useAppDispatch();

    return (
      <SCSearchResultContainer
        ref={ref}
        style={{ ['--width' as any]: `${width}px` }}
        onClick={() => dispatch(searchActions.hideSearch({ place }))}
      >
        {
          loading && (
            <SCLoaderContainer>
              <Loader size={80} />
            </SCLoaderContainer>
          )
        }
        {
          authors.map((author) => (
            <AuthorPreview key={author.id} author={author} />
          ))
        }
        {
          books.map((book) => (
            <BookPreview
              key={book.slug}
              book={book}
            />
          ))
        }
        { total > 3 && (
          <SearchBooksLink
            keyword={keyword}
          >
            {`Ещё ${total} результатов`}
          </SearchBooksLink>
        )}
      </SCSearchResultContainer>
    );
  },
);

const SCLoaderContainer = styled.div`
  min-height: 80px;
`;

const SCSearchResultContainer = styled.div`
  --width: 0;
  display: flex;
  flex-direction: column;
  background: #FFFFFF;
  border: 1px solid #DCE3E4;
  box-sizing: border-box;
  padding: 8px;
  width: var(--width);

  box-shadow: 0 14px 10px rgba(0, 0, 0, 0.04);
  
  a:nth-last-child(1)>${SCBookPreview}, a:nth-last-child(1)>${SCAuthorPreview} {
    margin-bottom: unset;
  }
`;
