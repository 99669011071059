import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from '~/api/account/accountApiTypes';
import { Book } from '~/api/book/bookApiTypes';

export enum SearchFieldPlaces {
  Header = 'Header',
  EmptyPurchasePage = 'EmptyPurchasePage',
  HeaderMobile = 'HeaderMobile',
}

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    places: {
      [SearchFieldPlaces.EmptyPurchasePage]: {
        authors: [],
        books: [],
        total: 0,
        isShowSearch: false,
        loading: false,
        keyword: '',
      },
      [SearchFieldPlaces.Header]: {
        authors: [],
        books: [],
        total: 0,
        isShowSearch: false,
        loading: false,
        keyword: '',
      },
      [SearchFieldPlaces.HeaderMobile]: {
        authors: [],
        books: [],
        total: 0,
        isShowSearch: false,
        loading: false,
        keyword: '',
      },
    } as {
      [key in SearchFieldPlaces]: {
        authors: User[];
        books: Book[];
        total: number;
        isShowSearch: boolean;
        loading: boolean;
        keyword: string;
      } },
  },
  reducers: {
    setLoading: (state, action: PayloadAction<{ isLoading: boolean; place: SearchFieldPlaces }>) => {
      const { place, isLoading } = action.payload;

      state.places[place].loading = isLoading;
    },
    showSearch: (state, action: PayloadAction<{ place: SearchFieldPlaces }>) => {
      const { place } = action.payload;

      state.places[place].isShowSearch = true;
    },
    hideSearch: (state, action: PayloadAction<{ place: SearchFieldPlaces }>) => {
      const { place } = action.payload;

      state.places[place].isShowSearch = false;
      state.places[place].keyword = '';
      state.places[place].authors = [];
      state.places[place].books = [];
    },
    clearSearch: (state, action: PayloadAction<{ place: SearchFieldPlaces }>) => {
      const { place } = action.payload;

      state.places[place].authors = [];
      state.places[place].books = [];
      state.places[place].total = 0;
      state.places[place].keyword = '';
      state.places[place].loading = false;
    },
    setKeyword: (state, action: PayloadAction<{ keyword: string; place: SearchFieldPlaces }>) => {
      const { place, keyword } = action.payload;

      state.places[place].loading = keyword.length > 0;
      state.places[place].keyword = keyword;
    },
    setBooks: (state, action: PayloadAction<{ books: Book[], place: SearchFieldPlaces }>) => {
      state.places[action.payload.place].books = action.payload.books;
    },
    setAuthors: (state, action: PayloadAction<{ authors: User[], place: SearchFieldPlaces }>) => {
      state.places[action.payload.place].authors = action.payload.authors;
    },
    setTotal: (state, action: PayloadAction<{ total: number, place: SearchFieldPlaces }>) => {
      state.places[action.payload.place].total = action.payload.total;
    },
  },
});

export const { actions: searchActions, reducer: searchReducer } = searchSlice;
