import { createAsyncThunk } from '@reduxjs/toolkit';

import { subscriptionsApi } from '~/api/account/subscriptionsApi';
import { SubscribeParams } from '~/api/account/subscriptionsApiTypes';
import { userApi } from '~/api/account/userApi';
import { GetUserParams, UserWithSubscriptions } from '~/api/account/userApiTypes';
import { authorActions, authorSliceName } from '~/atomic/page/author/author.slice';
import { openModal } from '~/feature/authorization/authorizationModal.slice';
import { environments } from '~/lib/const';
import { RootState } from '~/store';

export const getAuthorByName = createAsyncThunk<UserWithSubscriptions, GetUserParams, {
  rejectValue: { error: string }
}>(
  `${authorSliceName}/getAuthorByName`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const result = await userApi.getUser(data);
      if (result && 'data' in result) {
        thunkAPI.dispatch(authorActions.changeCurrentAuthor(result.data));
        thunkAPI.dispatch(authorActions.changeIsSubscription(result.isSubscription));
        return result;
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');

        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const subscribeCurrentAuthor = createAsyncThunk<
void,
SubscribeParams & {
  afterSubscribe?:(
  isSubscribe: boolean) => void
},
{ rejectValue: { error: string, id: string } }
>(
    `${authorSliceName}/subscribe`,
    async (data, thunkAPI) => {
      try {
        thunkAPI.dispatch(authorActions.setSubscriptionLoading(true));
        await subscriptionsApi.subscribe(data);

        const { message } = await import('~/atomic/atom/message');
        message.success('Вы успешно подписались!');

        if (data?.afterSubscribe) data?.afterSubscribe(true);
        thunkAPI.dispatch(authorActions.changeIsSubscription(true));
      } catch (error) {
        if (environments.isClient && error instanceof Error) {
          const { message } = await import('~/atomic/atom/message');
          message.error(error.message);
        }
        return thunkAPI.rejectWithValue({ error: error.message, id: data.targetId });
      } finally {
        thunkAPI.dispatch(authorActions.setSubscriptionLoading(false));
      }
    },
    );

export const unsubscribeCurrentAuthor = createAsyncThunk<
void,
SubscribeParams & { authorUsername: string },
{ rejectValue: { error: string, id: string } }>(
  `${authorSliceName}/unSubscribe`,
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(authorActions.setSubscriptionLoading(true));
      const result = await subscriptionsApi.unSubscribe(data);
      await thunkAPI.dispatch(getAuthorByName({ username: data.authorUsername }));

      if (result?.message) {
        const { message } = await import('~/atomic/atom/message');
        message.success(result.message);
      }

      thunkAPI.dispatch(authorActions.changeIsSubscription(false));
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message, id: data.targetId });
    } finally {
      thunkAPI.dispatch(authorActions.setSubscriptionLoading(false));
    }
  },
);

export const toggleSubscription = createAsyncThunk<
void,
{
  authorUsername: string; afterSubscribe?:(
  isSubscribe: boolean) => void
},
{ rejectValue: { error: string, id: string }; state: RootState }>(
    `${authorSliceName}/toggleSubscribe`,
    async (data, thunkAPI) => {
      const {
        subscriptionLoading, isSubscription, currentAuthor,
      } = thunkAPI.getState().author;

      const { user } = thunkAPI.getState().user;
      if (!user?.id) {
        thunkAPI.dispatch(openModal('login'));
        return;
      }
      if (!subscriptionLoading) {
        if (isSubscription) {
          thunkAPI.dispatch(unsubscribeCurrentAuthor({
            targetId: String(currentAuthor.id),
            authorUsername: data.authorUsername,
          }));
        } else {
          thunkAPI.dispatch(subscribeCurrentAuthor({
            targetId: String(currentAuthor.id),
            afterSubscribe: data?.afterSubscribe,
          }));
        }
      }
    },
    );
