import 'antd/dist/antd.min.css';
import 'nprogress/nprogress.css';
import './index.scss';

import { ApolloProvider } from '@apollo/client';
import { CaptureConsole, RewriteFrames } from '@sentry/integrations';
import * as Sentry from '@sentry/node';
import { NextComponentType } from 'next';
import { AppProps } from 'next/app';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import SingletonRouter from 'next/router';
import React, { useEffect, useRef } from 'react';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';

import { reactQueryClient } from '~/api/reactQuery';
import { CommonLayout } from '~/atomic/layout/CommonLayout';
import { LayoutType } from '~/atomic/layout/DefaultLayout';
import { AudioPlayer } from '~/feature/audio/AudioPlayer';
import { CheckTransaction } from '~/feature/book/paymentResult/CheckTransaction';
import { ChangeScrolling } from '~/feature/ChangeScrolling';
import { GoogleAnalyticsInit } from '~/feature/google/GoogleAnalyticsInit';
import { apolloClient } from '~/feature/graphql/ApolloClientProvider';
import { WindowSize } from '~/feature/media/WindowSize';
import { SavePrevPageUrl } from '~/feature/savePrevPageUrl/SavePrevPageUrl';
import { ScrollUpButton } from '~/feature/ScrollUpButton';
import { AuthorModalAboutAbonnement } from '~/feature/userAlert/AuthorModalAbountAbonnement';
import { CheckUserAlert } from '~/feature/userAlert/CheckUserAlert';
import { generateDeviceSessionUid } from '~/feature/utils/deviceSessionUid';
import { VkMetrikaInit } from '~/feature/vk/VkMetrikaInit';
import { VkPixel } from '~/feature/vk/VkPixel';
import { YandexMetrikaInit } from '~/feature/yandex/YandexMetrikaInit';
import { YandexMetrikaInitUser } from '~/feature/yandex/YandexMetrikaInitUser';
import { GlobalCss } from '~/globalStyled';
import { environments } from '~/lib/const';
import { initStore, RootState } from '~/store';

const FirebaseMessaging = dynamic(
  () => import('~/feature/firebase/FirebaseMessaging')
    .then((component) => component.FirebaseMessaging),
  { ssr: false },
);

if (environments.isClient) {
  import('smoothscroll-polyfill')
    .then((smoothscroll) => {
      smoothscroll.polyfill();
    });
}

if (environments.isClient) {
  import('~/lib/polyfills/composedPath')
    .catch((error) => {
      console.log('import pylyfill composedPath error cause = ', error);
    });
}

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;

  Sentry.init({
    release: process.env.CI_COMMIT_SHA,
    enabled:
      (process.env.NODE_ENV === 'production')
      && environments.isProduction,
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame?.filename?.replace(distDir, 'app:///_next');
          return frame;
        },
      }),
      new CaptureConsole({ levels: ['error'] }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

const MyApp = ({
  Component, pageProps, err,
}: AppProps<{ state: RootState }> & { err: string }) => {
  const getLayout = (Component as NextComponentType & LayoutType).getLayout || (
    (page) => <CommonLayout>{page}</CommonLayout>
  );

  const storeRef = useRef<ReturnType<typeof initStore>>(null);

  if (!storeRef.current) {
    storeRef.current = initStore(pageProps.state);
  }

  useEffect(() => {
    // eslint-disable-next-line no-underscore-dangle
    const oldGetData = (SingletonRouter.router as any)._getData;

    const newGetData = async <T extends { props: { pageProps?: { state?: RootState } } }>(
      fn: () => Promise<T>,
    ): Promise<T> => {
      let cancelled = false;

      const cancel = () => {
        cancelled = true;
      };

      (SingletonRouter.router as any).clc = cancel;

      return fn()
        .then((data) => {
          if (cancel === (SingletonRouter.router as any).clc) {
            (SingletonRouter.router as any).clc = null;
          }

          if (cancelled) {
            const err: any = new Error('Loading initial props cancelled');
            err.cancelled = true;
            throw err;
          }
          if (storeRef.current && data?.props?.pageProps?.state) {
            storeRef.current.dispatch({
              type: 'POPULATE',
              payload: data.props.pageProps.state,
            });
          }

          return data;
        });
    };

    // eslint-disable-next-line no-underscore-dangle
    (SingletonRouter.router as any)._getData = newGetData;

    return () => {
      // eslint-disable-next-line no-underscore-dangle
      (SingletonRouter.router as any)._getData = oldGetData;
    };
  }, [storeRef.current]);

  useEffect(() => {
    generateDeviceSessionUid();
  }, []);

  return (
    <>
      <Head>
        <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
        <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
        <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
        <meta name="msapplication-TileColor" content="#da532c" />
        <meta name="theme-color" content="#ffffff" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
        />
        <meta property="og:site_name" content="Букривер" />
      </Head>
      <QueryClientProvider client={reactQueryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <ApolloProvider client={apolloClient()}>
          <Provider store={storeRef.current}>
            <ChangeScrolling />
            <SavePrevPageUrl />
            <VkMetrikaInit />
            <VkPixel />
            <YandexMetrikaInit />
            <YandexMetrikaInitUser />
            <GoogleAnalyticsInit />
            <FirebaseMessaging />
            <WindowSize />
            <ScrollUpButton />
            <AudioPlayer />
            <CheckUserAlert />
            <CheckTransaction />
            <AuthorModalAboutAbonnement />
            {getLayout(
              (<Component {...pageProps} err={err} /> as any),
            )}
            <GlobalCss />
          </Provider>
        </ApolloProvider>
      </QueryClientProvider>
    </>
  );
};

export default MyApp;
