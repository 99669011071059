import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '~/store';

export const paginationSelector = (state: RootState) => state.pagination;

export const paginationTotalSelector = createSelector(
  paginationSelector,
  ({ total }) => total,
);
