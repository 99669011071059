import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  BookSortingEnum, BookTypeEnum, CompleteStatusEnum,
} from '~/api/book/bookApiTypes';
import { BookTag } from '~/api/book/bookTagApiTypes';

export const catalogFilterSliceName = 'catalogFilter';

const catalogFilterSlice = createSlice({
  name: catalogFilterSliceName,
  initialState: {
    pageUrlStartWith: '',
    completeStatus: null as null | CompleteStatusEnum,
    mainGenreSlug: null as string,
    subGenresSlugs: [] as Array<string>,
    withAudiobook: false,
    isOnlyFree: false,
    isOnlyFreeUrl: '',
    isExclusive: false,
    isWithAbonnement: false,
    isCanDownload: false,
    isCanDownloadUrl: '',
    withAdvertisingFromBookriver: false,
    tagSlug: '',
    tags: [] as BookTag[],
    sortingType: BookSortingEnum.Bestseller,
    bookType: null as null | BookTypeEnum,
    isAddGenreToParams: true,
    isAddFilterToParams: true,
  },
  reducers: {
    changePageUrlStartWith: (state, action: PayloadAction<string>) => {
      state.pageUrlStartWith = action.payload;
    },
    setCompleteStatus: (state, action: PayloadAction<CompleteStatusEnum | undefined>) => {
      state.completeStatus = action.payload;
    },
    setMainGenreSlug: (state, action: PayloadAction<string>) => {
      state.mainGenreSlug = action.payload;
    },
    setSubGenresSlugs: (state, action: PayloadAction<string[]>) => {
      state.subGenresSlugs = action.payload;
    },
    setIsOnlyFree: (state, action: PayloadAction<boolean>) => {
      state.isOnlyFree = action.payload;
    },
    setBookType: (state, action: PayloadAction<BookTypeEnum>) => {
      state.bookType = action.payload;
    },
    setIsExclusive: (state, action: PayloadAction<boolean>) => {
      state.isExclusive = action.payload;
    },
    setIsCanDownload: (state, action: PayloadAction<boolean>) => {
      state.isCanDownload = action.payload;
    },
    setBookSorting: (state, action: PayloadAction<BookSortingEnum>) => {
      state.sortingType = action.payload;
    },
    setWithAudiobook: (state, action: PayloadAction<boolean>) => {
      state.withAudiobook = action.payload;
    },
    setTagSlug: (state, action: PayloadAction<string>) => {
      state.tagSlug = action.payload;
    },
    changeTags: (state, action: PayloadAction<BookTag[]>) => {
      state.tags = action.payload;
    },
    resetTags: (state) => {
      state.tags = [];
      state.tagSlug = null;
    },
    changeIsOnlyFreeUrl: (state, action: PayloadAction<string>) => {
      state.isOnlyFreeUrl = action.payload;
    },
    changeIsCanDownloadUrl: (state, action: PayloadAction<string>) => {
      state.isCanDownloadUrl = action.payload;
    },
    setIsWithAbonnement: (state, action: PayloadAction<boolean>) => {
      state.isWithAbonnement = action.payload;
    },
    setWithAdvertisingFromBookriver: (state, action: PayloadAction<boolean>) => {
      state.withAdvertisingFromBookriver = action.payload;
    },
    setIsAddGenreToParams: (state, action: PayloadAction<boolean>) => {
      state.isAddGenreToParams = action.payload;
    },
    setIsAddFilterToParams: (state, action: PayloadAction<boolean>) => {
      state.isAddFilterToParams = action.payload;
    },
  },
});

export const {
  actions: catalogFilterActions,
  reducer: catalogFilterReducer,
} = catalogFilterSlice;
