import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { User } from '~/api/account/accountApiTypes';

export const authorSubscribersSliceName = 'authorSubscribers';

export const authorSubscribersSlice = createSlice({
  name: authorSubscribersSliceName,
  initialState: { subscribers: [] as User[] },
  reducers: {
    changeSubscribers: (state, action: PayloadAction<User[]>) => {
      state.subscribers = action.payload;
    },
  },
});

export const {
  actions: authorSubscribersActions,
  reducer: authorSubscribersReducer,
} = authorSubscribersSlice;
