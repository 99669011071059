import { createAsyncThunk } from '@reduxjs/toolkit';

import { statisticsApi } from '~/api/book/statisticsApi';
import {
  BookListStatisticsPublic,
  GetBookListTotalStatisticsParams,
} from '~/api/book/statisticsApiTypes';
import { booksSliceName } from '~/atomic/page/books/books.slice';
import { environments } from '~/lib/const';

export const getBookListTotalStatistics = createAsyncThunk<
BookListStatisticsPublic[],
GetBookListTotalStatisticsParams,
{ rejectValue: { error: string }; }
>(
  `${booksSliceName}/getBookListTotalStatistics`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const result = await statisticsApi.getBookListTotalStatistics(data);
      if (result && 'data' in result) {
        return result.data;
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);
