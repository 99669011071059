import React from 'react';
import styled from 'styled-components';

import { TagLink } from '~/atomic/atom/links/dynamicLinks';
import { headerActions } from '~/atomic/organism/Header/header.slice';
import { getPopularTagSlugByName } from '~/feature/popularTags/popularTags';
import { useAppDispatch } from '~/store';

const SCTagLabel = styled.div`
  font-family: var(--main-font);
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  position: relative;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: unset;
  }
  
  a {
    color: var(--black-color);
    &:hover {
      color: var(--primary-hover-color);
    }
  }
`;

const SCLabelGroup = styled.div`
  max-width: 250px
`;

const SCMainTagLabel = styled(SCTagLabel)`
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 16px;
  cursor: default;
  color: var(--gray-color);
`;

const SCChildTagLabel = styled(SCTagLabel)`
  line-height: 16px;
  margin-bottom: 0;
  
  a {
    line-height: 23px;
  }
`;

const MainTagLabel: React.FC<{
  tagLabel: string;
}> = ({ tagLabel }) => {
  return (
    <SCMainTagLabel>
      {tagLabel}
    </SCMainTagLabel>
  );
};

const ChildTagLabel: React.FC<{
  tagLabel: string;
}> = ({ tagLabel }) => {
  const dispatch = useAppDispatch();

  const onClick = () => {
    dispatch(headerActions.setIsOpenLibrary(false));
  };

  return (
    <SCChildTagLabel
      onClick={onClick}
    >
      <TagLink
        slug={getPopularTagSlugByName(tagLabel)}
      >
        <a>{tagLabel}</a>
      </TagLink>
    </SCChildTagLabel>
  );
};

export const HeaderLibraryTags = () => {
  return (
    <SCHeaderLibraryTag>
      <SCLibraryColumn>
        <SCLabelGroup>
          <MainTagLabel tagLabel="Этот дивный мир" />
          <ChildTagLabel
            tagLabel="Авторский мир"
          />
          <ChildTagLabel
            tagLabel="Магический мир"
          />
          <ChildTagLabel
            tagLabel="Реалрпг"
          />
          <ChildTagLabel
            tagLabel="Иные миры"
          />
          <ChildTagLabel
            tagLabel="Современность"
          />
          <ChildTagLabel
            tagLabel="Параллельные миры"
          />
          <ChildTagLabel
            tagLabel="Средневековье"
          />
          <ChildTagLabel
            tagLabel="Русреал"
          />
          <ChildTagLabel
            tagLabel="Стимпанк"
          />
          <ChildTagLabel
            tagLabel="Техномагия"
          />
          <ChildTagLabel
            tagLabel="Антиутопия"
          />
          <ChildTagLabel
            tagLabel="Российская империя"
          />
          <ChildTagLabel
            tagLabel="Виртуальная реальность"
          />
        </SCLabelGroup>
        <SCLabelGroup>
          <MainTagLabel
            tagLabel="Не(просто)люди"
          />
          <ChildTagLabel
            tagLabel="Оборотни"
          />
          <ChildTagLabel
            tagLabel="Драконы"
          />
          <ChildTagLabel
            tagLabel="Демоны"
          />
          <ChildTagLabel
            tagLabel="Ведьма"
          />
          <ChildTagLabel
            tagLabel="Вампиры"
          />
          <ChildTagLabel
            tagLabel="Авторские расы"
          />
          <ChildTagLabel
            tagLabel="Эльфы"
          />
          <ChildTagLabel
            tagLabel="Боги"
          />
          <ChildTagLabel
            tagLabel="Некромант"
          />
          <ChildTagLabel
            tagLabel="Монстры"
          />
          <ChildTagLabel
            tagLabel="Мутанты"
          />
          <ChildTagLabel
            tagLabel="Искусственный интеллект"
          />
          <ChildTagLabel
            tagLabel="Призраки"
          />
          <ChildTagLabel
            tagLabel="Мифические существа"
          />
          <ChildTagLabel
            tagLabel="Инопланетяне"
          />
          <ChildTagLabel
            tagLabel="Ангелы"
          />
          <ChildTagLabel
            tagLabel="Наги"
          />
        </SCLabelGroup>
      </SCLibraryColumn>
      <SCLibraryColumn>
        <SCLabelGroup>
          <MainTagLabel
            tagLabel="Такие разные герои"
          />
          <ChildTagLabel
            tagLabel="Властный герой"
          />
          <ChildTagLabel
            tagLabel="Становление героя"
          />
          <ChildTagLabel
            tagLabel="Попаданка"
          />
          <ChildTagLabel
            tagLabel="Сильная героиня"
          />
          <ChildTagLabel
            tagLabel="Неунывающая героиня"
          />
          <ChildTagLabel
            tagLabel="Беременная героиня"
          />
          <ChildTagLabel
            tagLabel="Адекватные герои"
          />
          <ChildTagLabel
            tagLabel="Настоящий мужчина"
          />
          <ChildTagLabel
            tagLabel="Невинная героиня"
          />
          <ChildTagLabel
            tagLabel="Попаданец"
          />
          <ChildTagLabel
            tagLabel="Сильный герой"
          />
          <ChildTagLabel
            tagLabel="Студенты"
          />
          <ChildTagLabel
            tagLabel="Нежная героиня"
          />
          <ChildTagLabel
            tagLabel="Бывшие"
          />
          <ChildTagLabel
            tagLabel="Богатый парень и простая девушка"
          />
          <ChildTagLabel
            tagLabel="Подростки"
          />
          <ChildTagLabel
            tagLabel="Дерзкая героиня"
          />
          <ChildTagLabel
            tagLabel="Неидеальные герои"
          />
          <ChildTagLabel
            tagLabel="Сводные брат и сестра"
          />
          <ChildTagLabel
            tagLabel="Плохой парень"
          />
          <ChildTagLabel
            tagLabel="Антигерой"
          />
          <ChildTagLabel
            tagLabel="Жесткий герой"
          />
          <ChildTagLabel
            tagLabel="Попаданцы в СССР"
          />
          <ChildTagLabel
            tagLabel="Близнецы"
          />
          <ChildTagLabel
            tagLabel="Отец одиночка"
          />
        </SCLabelGroup>
      </SCLibraryColumn>
      <SCLibraryColumn>
        <SCLabelGroup>
          <MainTagLabel
            tagLabel="Вся палитра чувств"
          />
          <ChildTagLabel
            tagLabel="От ненависти до любви"
          />
          <ChildTagLabel
            tagLabel="Очень откровенно"
          />
          <ChildTagLabel
            tagLabel="Романтика и любовь"
          />
          <ChildTagLabel
            tagLabel="Юмор"
          />
          <ChildTagLabel
            tagLabel="Любовь и страсть"
          />
          <ChildTagLabel
            tagLabel="Сложные отношения"
          />
          <ChildTagLabel
            tagLabel="Противостояние героев"
          />
          <ChildTagLabel
            tagLabel="Первая любовь"
          />
          <ChildTagLabel
            tagLabel="Разница в возрасте"
          />
          <ChildTagLabel
            tagLabel="Любовный треугольник"
          />
          <ChildTagLabel
            tagLabel="Истинная пара"
          />
          <ChildTagLabel
            tagLabel="Сильные чувства"
          />
          <ChildTagLabel
            tagLabel="Служебный роман"
          />
          <ChildTagLabel
            tagLabel="Тайны прошлого"
          />
          <ChildTagLabel
            tagLabel="Секс"
          />
          <ChildTagLabel
            tagLabel="Встреча через время"
          />
          <ChildTagLabel
            tagLabel="МЖМ"
          />
          <ChildTagLabel
            tagLabel="Вынужденный брак"
          />
          <ChildTagLabel
            tagLabel="Драма"
          />
          <ChildTagLabel
            tagLabel="Дружба"
          />
          <ChildTagLabel
            tagLabel="Запретная любовь"
          />
          <ChildTagLabel
            tagLabel="Любовь вопреки"
          />
          <ChildTagLabel
            tagLabel="Любовь и приключения"
          />
          <ChildTagLabel
            tagLabel="Измена"
          />
          <ChildTagLabel
            tagLabel="Жестокость и насилие"
          />
          <ChildTagLabel
            tagLabel="Интриги и страсть"
          />
          <ChildTagLabel
            tagLabel="Предательство"
          />
          <ChildTagLabel
            tagLabel="Страсть"
          />
          <ChildTagLabel
            tagLabel="Настоящая любовь"
          />
          <ChildTagLabel
            tagLabel="Принуждение"
          />
          <ChildTagLabel
            tagLabel="Любовь и месть"
          />
          <ChildTagLabel
            tagLabel="Любовь с первого взгляда"
          />
          <ChildTagLabel
            tagLabel="Одержимость"
          />
          <ChildTagLabel
            tagLabel="Босс и подчинённая"
          />
        </SCLabelGroup>
      </SCLibraryColumn>
      <SCLibraryColumn>
        <SCLabelGroup>
          <MainTagLabel
            tagLabel="Особенности"
          />
          <ChildTagLabel
            tagLabel="Приключения"
          />
          <ChildTagLabel
            tagLabel="Магия и волшебство"
          />
          <ChildTagLabel
            tagLabel="Интриги"
          />
          <ChildTagLabel
            tagLabel="Кланы"
          />
          <ChildTagLabel
            tagLabel="Мистика"
          />
          <ChildTagLabel
            tagLabel="Сверхспособности"
          />
          <ChildTagLabel
            tagLabel="Криминал"
          />
          <ChildTagLabel
            tagLabel="Выживание"
          />
          <ChildTagLabel
            tagLabel="Война"
          />
          <ChildTagLabel
            tagLabel="Жизнь"
          />
          <ChildTagLabel
            tagLabel="Хэппи энд"
          />
          <ChildTagLabel
            tagLabel="Космос"
          />
          <ChildTagLabel
            tagLabel="БДСМ"
          />
          <ChildTagLabel
            tagLabel="Отбор невест"
          />
          <ChildTagLabel
            tagLabel="Расследование"
          />
          <ChildTagLabel
            tagLabel="Гаремник"
          />
          <ChildTagLabel
            tagLabel="Смерть"
          />
          <ChildTagLabel
            tagLabel="Повседневность"
          />
          <ChildTagLabel
            tagLabel="Чёрный юмор"
          />
          <ChildTagLabel
            tagLabel="Прогрессорство"
          />
          <ChildTagLabel
            tagLabel="Путешествие"
          />
          <ChildTagLabel
            tagLabel="Спасение мира"
          />
          <ChildTagLabel
            tagLabel="Боевые искусства"
          />
          <ChildTagLabel
            tagLabel="Культивация"
          />
          <ChildTagLabel
            tagLabel="Young adult"
          />
          <ChildTagLabel
            tagLabel="Путешествие во времени"
          />
        </SCLabelGroup>
      </SCLibraryColumn>
    </SCHeaderLibraryTag>
  );
};

const SCLibraryColumn = styled.div`
  display: flex;
  flex-direction: column;
  
  > *:not(:last-child) {
    margin-bottom: 30px;
  }
`;

const SCHeaderLibraryTag = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
`;
