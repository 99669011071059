export enum PostThemeNamesEnum {
  CreativeProcess = 'Творческий процесс',
  EntertainingStories = 'Занимательные истории',
  Reflections = 'Личное',
  AllAboutSales = 'Все о продажах',
  ReviewsAndCriticism = 'Отзывы и критика',
  InterestingEvents = 'Интересные мероприятия',
  Interview = 'Интервью',
  ProjectNews = 'Новости',
}

export enum PostStatus {
  Draft = 'draft',
  Published = 'published',
  NotPublished = 'not_published',
}

export interface GetPostsParams {
  theme?: string;
  page?: number;
  perPage?: number;
  onlySubscribed?: boolean;
}

export interface DownloadImageParams {
  postId: string | number;
}

export interface EditPostParams extends CreatePostParams {
  postId?: string | number;
}

export enum PostThemeEnum {
  CreativeProcess = 'creative-process',
  EntertainingStories = 'entertaining-stories',
  Reflections = 'reflections',
  AllAboutSales = 'all-about-sales',
  ReviewsAndCriticism = 'reviews-and-criticism',
  InterestingEvents = 'interesting-events',
  Interview = 'interview',
  ProjectNews = 'project-news',
}

export interface Post {
  id: number;
  theme: PostThemeEnum;
  author: {
    id: number;
    name: string;
    username: string;
  },
  title: string;
  content: string;
  slug: string;
  isAvailableEdit: boolean;
  status: PostStatus;
  counters: {
    comments: number,
    views: number
  },
  publishedAt: string;
  createdAt: string;
  updatedAt: string;
}

export interface CreatePostFields {
  title?: string;
  theme?: PostThemeEnum;
  content?: string;
}

export interface CreatePostRejectValue {
  title?: string[];
  theme?: string[];
  content?: string[];
}

export interface CreatePostParams extends CreatePostFields {
  publish?: boolean;
}

export interface GetAuthorPostsParams {
  username: string;
  page?: number;
  perPage?: number;
}

export interface GetPostByIdParams {
  id: string | number;
}

export interface DeletePostParams {
  id: string | number;
}

export interface ViewPostParams {
  postId: string | number;
}

export interface GetMePostsParams {
  page: number | string;
  perPage: number | string;
}
