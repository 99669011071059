import { createAsyncThunk } from '@reduxjs/toolkit';

import { User } from '~/api/account/accountApiTypes';
import { RootState } from '~/store';

const userEmailLocalStorageNameOld = 'user_email';
const userEmailLocalStorageName = 'user_save_email';

export const saveUserEmailInLocalStorage = createAsyncThunk<
void,
{ user: User },
{ state: RootState }
>(
  `${userEmailLocalStorageName}/saveUserInLocalStorage`,
  ({ user }, thunkAPI) => {
    try {
      if (localStorage && user && 'email' in user) {
        const userEmailFromStorage = localStorage.getItem(userEmailLocalStorageName) ?? '';
        const isCurrentUserSaveInStorage = userEmailFromStorage === user.email;
        if (!isCurrentUserSaveInStorage && user.email) {
          localStorage.setItem(userEmailLocalStorageName, user.email);
        }
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const deleteUserEmailFromLocalStorage = createAsyncThunk<
void,
void,
{ state: RootState }
>(
  `${userEmailLocalStorageName}/deleteUserEmailFromLocalStorage`,
  (_, thunkAPI) => {
    try {
      if (localStorage) {
        localStorage.removeItem(userEmailLocalStorageName);
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  },
);

export const getUserEmailInLocalStorage = createAsyncThunk<
string,
void,
{ state: RootState }
>(
  `${userEmailLocalStorageName}/getUserInLocalStorage`,
  async () => {
    try {
      if (localStorage) {
        localStorage.removeItem(userEmailLocalStorageNameOld);
        const userEmailFromStorage = localStorage.getItem(userEmailLocalStorageName) ?? '';

        if (userEmailFromStorage) {
          return userEmailFromStorage;
        }
      }

      return '';
    } catch (error) {
      return '';
    }
  },
);
