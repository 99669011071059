import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ScheduledDiscount } from '~/api/finance/marketing/scheduledDiscountApiTypes';

export const scheduledDiscountSliceName = 'scheduledDiscount';

const scheduledDiscountSlice = createSlice({
  name: scheduledDiscountSliceName,
  initialState: {
    discounts: [] as ScheduledDiscount[],
    isOpenAddScheduleDiscountModal: false,
    selectBookId: 0,
    selectDiscountId: '',
    isLoadDiscounts: false,
    isAddDiscountLoading: false,
    availableScheduledDiscountDate: '',
  },
  reducers: {
    setIsOpenAddScheduleDiscountModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenAddScheduleDiscountModal = action.payload;
    },
    setIsLoadDiscounts: (state, action: PayloadAction<boolean>) => {
      state.isLoadDiscounts = action.payload;
    },
    setIsAddDiscountLoading: (state, action: PayloadAction<boolean>) => {
      state.isAddDiscountLoading = action.payload;
    },
    changeDiscounts: (state, action: PayloadAction<ScheduledDiscount[]>) => {
      state.discounts = action.payload;
    },
    changeAvailableScheduledDiscountDate: (state, action: PayloadAction<string>) => {
      state.availableScheduledDiscountDate = action.payload;
    },
    changeSelectBookId: (state, action: PayloadAction<number>) => {
      state.selectBookId = action.payload;
    },
    changeSelectDiscountId: (state, action: PayloadAction<string>) => {
      state.selectDiscountId = action.payload;
    },
  },
});

export const {
  actions: scheduledDiscountActions,
  reducer: scheduledDiscountReducer,
} = scheduledDiscountSlice;
