import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Book, BookSortingEnum, BookTypeEnum, CompleteStatusEnum,
} from '~/api/book/bookApiTypes';
import { GenreFromApi } from '~/api/book/genreApiTypes';
import { AbonnementTypeEnum } from '~/graphql/books/factory/abonement/AbonnementFactoryTypes';

export const genrePageSliceName = 'genrePage';

export type CatalogParams = {
  completeStatus: null | CompleteStatusEnum;
  genres: string[];
  tag: string | null;
  isOnlyFree: boolean;
  isCanDownload: boolean;
  withAudiobook: boolean;
  bookType: null | BookTypeEnum;
  sortingType: BookSortingEnum;
  abonnementType: AbonnementTypeEnum;
};

const genrePageSlice = createSlice({
  name: genrePageSliceName,
  initialState: {
    books: [] as Book[],
    isLoadBooks: true,
    genreInfo: {} as GenreFromApi,
  },
  reducers: {
    changeGenreInfo: (state, action: PayloadAction<GenreFromApi>) => {
      state.genreInfo = action.payload;
    },
    changeBooks: (state, action: PayloadAction<Book[]>) => {
      state.books = action.payload;
    },
    setIsLoadBooks: (state, action: PayloadAction<boolean>) => {
      state.isLoadBooks = action.payload;
    },
  },
});

export const {
  actions: genrePageActions,
  reducer: genrePageReducer,
} = genrePageSlice;
