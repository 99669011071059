import dayjs from 'dayjs';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

import { provider } from '~/api/provider/provider.config';
import { environments } from '~/lib/const';

dayjs.extend(utc);
dayjs.extend(timezone);

export const setUserTimeToHeaders = () => {
  if (environments.isClient) {
    const dateNow = dayjs();
    const timezone = dayjs.tz.guess();
    const dateWithTimeZone = dayjs(dateNow).tz(timezone).format('YYYY-MM-DDTHH:mm:ssZ');

    if (dateNow) {
      provider.defaults.headers.common['request-user-time'] = dateWithTimeZone;
    }
  }
};
