import { createAsyncThunk } from '@reduxjs/toolkit';
import qs from 'query-string';

import { WithToken } from '~/api/auth/authApiTypes';
import { PayEntitiesEnum } from '~/api/finance/financeApiTypes';
import { adAccountApi } from '~/api/finance/marketing/adAccountApi';
import { AdAccountReplenishmentProps } from '~/api/finance/marketing/adAccountApiTypes';
import { HttpValidationError } from '~/api/provider/providerErrors';
import {
  adAccountActions,
  adAccountSliceName,
} from '~/atomic/page/campaigns/AdAccount/adAccount.slice';
import { financePageSliceName } from '~/atomic/page/finance/financePage.slice';
import { paymentTransaction } from '~/feature/book/paymentResult/paymentResult.data';
import { paymentResultActions } from '~/feature/book/paymentResult/paymentResult.slice';
import { environments } from '~/lib/const';
import { RootState } from '~/store';

export const getAdAccountInfo = createAsyncThunk<
void,
WithToken | void,
{ state: RootState }
>(
  `${adAccountSliceName}/getAdAccountInfo`,
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(adAccountActions.setIsLoadAdAccountInfo(true));
      const result = await adAccountApi.getAccountInfo(data);

      if ('data' in result) {
        thunkAPI.dispatch(adAccountActions.changeAdAccountInfo(result.data.adAccount));
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    } finally {
      thunkAPI.dispatch(adAccountActions.setIsLoadAdAccountInfo(false));
    }
  },
);

export const registerAdAccountReplenishment = createAsyncThunk<
void,
AdAccountReplenishmentProps, {
  rejectValue: { error?: string, errors?: Record<string, string[]> }; state: RootState
}>(
  `${financePageSliceName}/registerAdAccountReplenishment`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const currentUrl = qs.parseUrl(String(window.location));
      const returnUrl = qs.parseUrl(currentUrl.url);

      returnUrl.query.payEntity = PayEntitiesEnum.AdAccountReplenishment;
      returnUrl.query.methodPayment = data.methodPayment;
      returnUrl.query.transactionId = 'TIDSET';

      const result = await adAccountApi.adAccountReplenishment({
        ...data,
        returnUrl: qs.stringifyUrl(returnUrl),
      });

      await thunkAPI.dispatch(
        paymentTransaction({
          ...result?.data,
          successTransactionHandler: async () => {
            await thunkAPI.dispatch(getAdAccountInfo());
            thunkAPI.dispatch(paymentResultActions.openModal('adAccountReplenishmentSuccess'));
          },
        }),
      );
      thunkAPI.dispatch(adAccountActions.setIsOpenAdAccountReplenishmentModal(false));
    } catch (error) {
      if (error instanceof HttpValidationError) {
        return thunkAPI.rejectWithValue({ errors: error.getErrors() });
      }

      thunkAPI.dispatch(paymentResultActions.openModal('adAccountReplenishmentReject'));
      if (environments.isClient && error.message) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);
