import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Book } from '~/api/book/bookApiTypes';
import {
  AdDepartmentCampaign,
  Campaign,
  CampaignSourceEnum,
  CampaignSourceEnumName,
  NetworkBook,
  TargetologistType,
  TargetologistTypeEnum,
} from '~/api/finance/marketing/campaignsApiTypes';

export const campaignsSliceName = 'campaigns';

export const getCampaignsSourceName = (campaignsSource: CampaignSourceEnum) => {
  const campaignsSourceKey = Object
    .entries(CampaignSourceEnum)
    .find(([, value]) => value === campaignsSource)?.[0] as keyof typeof CampaignSourceEnum;

  return CampaignSourceEnumName[campaignsSourceKey];
};

export const targetologistName = (campaign: Campaign, userId: number) => {
  if (campaign.targetologistType === TargetologistTypeEnum.Other) {
    return 'Другой таргетолог';
  }

  if (campaign?.targetologist?.userId === userId || !campaign?.targetologist) {
    return 'Cамостоятельно';
  }

  return 'Рекламный отдел';
};

const campaignsSlice = createSlice({
  name: campaignsSliceName,
  initialState: {
    ownCampaignsList: [] as Campaign[],
    adDepartmentCompanies: [] as AdDepartmentCampaign[],
    campaign: {} as Campaign,
    isOpenCreateCampaignModal: false,
    isLoadingGetCampaigns: false,
    isLoadingCreateCampaign: false,
    targetologists: [] as TargetologistType[],
    networkBooks: [] as NetworkBook[],
    campaignBooks: [] as Book[],
  },
  reducers: {
    changeOwnCampaignsList: (state, action: PayloadAction<Campaign[]>) => {
      state.ownCampaignsList = action.payload;
    },
    changeAdDepartmentCompanies: (state, action: PayloadAction<AdDepartmentCampaign[]>) => {
      state.adDepartmentCompanies = action.payload;
    },
    changeCampaign: (state, action: PayloadAction<Campaign>) => {
      state.campaign = action.payload;
    },
    setIsOpenCreateCampaignModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenCreateCampaignModal = action.payload;
    },
    setIsLoadingGetCampaigns: (state, action: PayloadAction<boolean>) => {
      state.isLoadingGetCampaigns = action.payload;
    },
    setIsLoadingCreateCampaign: (state, action: PayloadAction<boolean>) => {
      state.isLoadingCreateCampaign = action.payload;
    },
    changeTargetologists: (state, action: PayloadAction<TargetologistType[]>) => {
      state.targetologists = action.payload;
    },
    changeAdNetworkBooks: (state, action: PayloadAction<NetworkBook[]>) => {
      state.networkBooks = action.payload;
    },
    changeCampaignBooks: (state, action: PayloadAction<Book[]>) => {
      state.campaignBooks = action.payload;
    },
  },
});

export const {
  actions: campaignsActions,
  reducer: campaignsReducer,
} = campaignsSlice;
