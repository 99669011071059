import type { RcFile } from 'antd/lib/upload';
import type { UploadChangeParam, UploadFile } from 'antd/lib/upload/interface';
import React, {
  useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';

import { accountApi } from '~/api/account/accountApi';
import { LoaderIcon } from '~/atomic/atom/icon/LoaderIcon';
import { TrashIcon } from '~/atomic/atom/icon/TrashIcon';
import { UploadFileIcon } from '~/atomic/atom/icon/UploadFileIcon';
import { Loader } from '~/atomic/atom/Loader';
import { Upload } from '~/atomic/atom/Upload';
import { authorSelector } from '~/atomic/page/author/author.selector';
import { deleteHeroImage } from '~/atomic/page/author/heroImage/heroImage.data';
import { heroImageSelector } from '~/atomic/page/author/heroImage/heroImage.selector';
import { TextRegStyle } from '~/atomic/Typography';
import { getAuthTokenFromClient } from '~/feature/authorization/getAuthToken';
import { userSelector } from '~/feature/user/user.selector';
import { environments } from '~/lib/const';
import { getBase64DataUrl } from '~/lib/getBase64DataUrl';
import { lessThan } from '~/lib/mediaQuery';
import { useAppDispatch, useAppSelector } from '~/store';

import { authorActions } from '../author.slice';
import { heroImageActions } from './heroImage.slice';

const MIN_HEIGHT_HERO_IMAGE = 220;
const MAX_HEIGHT_HERO_IMAGE = 263;
const MIN_WIDTH_HERO_IMAGE = 1200;

export const HeroImage = () => {
  const dispatch = useAppDispatch();
  const {
    isValidHeroImage, isLoading, isDeleteLoading,
  } = useAppSelector(heroImageSelector);
  const { currentAuthor } = useAppSelector(authorSelector);
  const { user } = useAppSelector(userSelector);
  const uploadRef = useRef(null);
  const userIsAuthor = (user?.username ?? '') === currentAuthor.username;
  const [headers, changeHeaders] = useState({
    Accept: 'application/json',
    'X-Requested-With': null,
  });

  useEffect(() => {
    if (environments.isClient) {
      const token = getAuthTokenFromClient();

      if (token) {
        changeHeaders((oldHeaders) => ({
          ...oldHeaders,
          authorization: `Bearer ${token}`,
        }));
      }
    }
  }, []);

  const uploadFileHandler = async (file: RcFile): Promise<any> => {
    const isJpg = file.type === 'image/jpeg' || file.type === 'image/jpg';
    const isPng = file.type === 'image/png';
    const isLt1M = file.size / 1024 / 1024 < 1;

    if (!isJpg && !isPng) {
      const { message } = await import('~/atomic/atom/message');
      message.error('Вы можете использовать только JPG/JPEG/PNG форматы');
      dispatch(heroImageActions.setIsValidHeroImage(false));
      return false;
    }

    if (!isLt1M) {
      const { message } = await import('~/atomic/atom/message');
      message.error('Изображение должно быть меньше 1 мб');
      dispatch(heroImageActions.setIsValidHeroImage(false));
      return false;
    }

    const fileSize = await (new Promise<{ width: number;height: number; }>((resolve, reject) => {
      if (file) {
        const img = new Image();
        const objectUrl = URL.createObjectURL(file);
        img.onload = function () {
          resolve({ width: img.width, height: img.height });

          URL.revokeObjectURL(objectUrl);
        };

        img.src = objectUrl;
      } else {
        reject(false);
      }
    }));

    const isLessThenMinHeight = fileSize.height < MIN_HEIGHT_HERO_IMAGE;
    const isLessThenMinWidth = fileSize.width < MIN_WIDTH_HERO_IMAGE;
    const isMoreMaxHeightHeroImage = fileSize.height > MAX_HEIGHT_HERO_IMAGE;

    if (isLessThenMinWidth) {
      const { message } = await import('~/atomic/atom/message');
      message.error(
        `Ширина баннера должна быть больше ${MIN_WIDTH_HERO_IMAGE}px`,
      );
      dispatch(heroImageActions.setIsValidHeroImage(false));
      return false;
    }

    if (isLessThenMinHeight || isMoreMaxHeightHeroImage) {
      const { message } = await import('~/atomic/atom/message');
      message.error(
        `Высота баннера должна быть больше ${
          MIN_HEIGHT_HERO_IMAGE}px и меньше ${
          MAX_HEIGHT_HERO_IMAGE}px`,
      );
      dispatch(heroImageActions.setIsValidHeroImage(false));
      return false;
    }

    dispatch(heroImageActions.setIsValidHeroImage(true));
    return true;
  };

  const changeFileHandler = async (info: UploadChangeParam<UploadFile<any>>) => {
    if (!isValidHeroImage) {
      dispatch(heroImageActions.setIsLoading(false));
      return;
    }

    if (info.file.status === 'uploading') {
      dispatch(heroImageActions.setIsLoading(true));
      return;
    }
    if (info.file.status === 'done') {
      const url = await getBase64DataUrl(
        info.file.originFileObj,
      );
      if (typeof url === 'string') {
        dispatch(authorActions.changeCurrentAuthorHeroImage(url));
      }
    }
    dispatch(heroImageActions.setIsLoading(false));
  };

  const deleteHeroImageHandler = async (e: React.MouseEvent<HTMLSpanElement, MouseEvent>) => {
    e.stopPropagation();
    await dispatch(deleteHeroImage());
  };

  const uploadHeroImageAction = () => {
    return accountApi.setHeroImage();
  };

  const customRequest = isValidHeroImage
    ? undefined
    : (options) => {
      options.onError(new Error(''));
    };

  if (!userIsAuthor && !currentAuthor?.heroImage) {
    return null;
  }

  if (currentAuthor.heroImage && userIsAuthor) {
    return (
      <SCViewUpload
        ref={uploadRef}
        withCredentials
        action={uploadHeroImageAction}
        listType="picture-card"
        headers={headers}
        showUploadList={false}
        name="image"
        accept=".jpeg, .jpg, .png"
        beforeUpload={uploadFileHandler}
        onChange={changeFileHandler}
        disabled={isLoading}
        customRequest={customRequest}
      >
        <SCHeroImageWrapper>
          {isLoading && (
            <Loader
              size={50}
            />
          )}
          <SCHeroImage
            alt={`Все книги автора ${currentAuthor?.name}`}
            src={currentAuthor?.heroImage}
          />
          <SCChangeImage>
            <SCChangeHeroImageIcon />
            Изменить баннер
          </SCChangeImage>
          <SCDeleteImage
            onClick={deleteHeroImageHandler}
          >
            {isDeleteLoading ? (
              <SCDeleteLoading><LoaderIcon /></SCDeleteLoading>
            ) : (<SCDeleteIcon />)}

            Удалить
          </SCDeleteImage>
        </SCHeroImageWrapper>
      </SCViewUpload>
    );
  }

  if (!currentAuthor?.heroImage && userIsAuthor) {
    return (
      <SCUpload
        ref={uploadRef}
        withCredentials
        action={uploadHeroImageAction}
        listType="picture-card"
        headers={headers}
        showUploadList={false}
        name="image"
        accept=".jpeg, .jpg, .png"
        beforeUpload={uploadFileHandler}
        onChange={changeFileHandler}
        disabled={isLoading}
        customRequest={customRequest}
      >
        {isLoading && (
          <Loader
            size={50}
          />
        )}
        <>
          <SCTitle>
            <SCUploadIcon />
            Загрузить баннер
          </SCTitle>
          <SCUploadDescription>Размер 1200х263, Мах вес 1Мб</SCUploadDescription>
        </>
      </SCUpload>

    );
  }

  return (
    <SCHeroImageWrapper>
      <SCHeroImage
        alt={`Все книги автора ${currentAuthor?.name}`}
        src={currentAuthor.heroImage}
      />
    </SCHeroImageWrapper>
  );
};

const SCHeroImage = styled.img`
  width: 100%;
  border-radius: 4px;

  ${lessThan('lg')} {
    margin-right: 8px;
    margin-left: 8px;
  };

  ${lessThan('md')} {
    margin: unset;
    border-radius: 0;
  };
`;

const SCUpload = styled(Upload)`
  background: inherit;
  display: flex;
  margin-top: 30px;
  position: relative;
  &&& {
    .ant-upload.ant-upload-select {
      display: flex;
      width: 100%;
      height: 100%;
      margin: 0;
      background-color: #fafafa;
    }
    
    .ant-upload-select > .ant-upload {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      padding: 0 35px;
      min-height: 122px;
      ${TextRegStyle};
    }
  }
`;

const SCViewUpload = styled(Upload)`
  background: inherit;
  display: flex;
  position: relative;
  pointer-events: none;
  
  &&& {
    .ant-upload {
      padding: 0;
    }
    
    .ant-upload.ant-upload-select {
      display: flex;
      width: 100%;
      height: 100%;
      border: none;
      margin: 0;
      background-color: #fafafa;
  
      &:hover {
        border: none;
      }
    }
  }
`;

const SCUploadIcon = styled(UploadFileIcon)`
  color: var(--primary-color);
  font-size: 15px;
  margin-right: 8px;    
`;

const SCTitle = styled.span`
  ${TextRegStyle};
  color: var(--primary-color);
  margin-bottom: 8px;
  display: flex;
  align-items: center;
`;

const SCUploadDescription = styled.span`
  color: var(--gray-color);
`;

const SCChangeImage = styled.span`
  ${TextRegStyle};
  color: var(--white-color);
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 130px;
  z-index: 1;
  pointer-events: all;
`;

const SCChangeHeroImageIcon = styled(UploadFileIcon)`
  color: var(--white-color);
  font-size: 15px;
  margin-right: 12px; 
`;

const SCDeleteImage = styled.span`
  ${TextRegStyle};
  color: var(--white-color);
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 8px;
  right: 11px;
  z-index: 1;
  pointer-events: all;
`;

const SCDeleteIcon = styled(TrashIcon)`
  color: var(--white-color);
  font-size: 20px;
  margin-right: 12px; 
`;

const SCHeroImageWrapper = styled.div`
  position: relative;
  margin-top: 24px;
`;

const SCDeleteLoading = styled.span`
  svg {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }
`;
