import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const ANCHOR_CLASS = 'anchor';
export const anchorsSliceName = 'anchors';

export interface Anchor {
  id: string;
  synonym: string;
}

export const ANCHOR_DATA_KEY = 'anchor';

const anchorsSlice = createSlice({
  name: anchorsSliceName,
  initialState: {
    isActive: false,
    isGuardEnabled: false,
    anchors: [] as Anchor[],
  },
  reducers: {
    setGuardStatus: (state, action: PayloadAction<boolean>) => {
      state.isGuardEnabled = action.payload;
    },
    setIsActive: (state, action: PayloadAction<boolean>) => {
      state.isActive = action.payload;
    },
    addAnchor: (state, action: PayloadAction<Anchor>) => {
      const anchor = action.payload;

      state.anchors.push(anchor);
    },
    setAnchors: (state, action: PayloadAction<Anchor[]>) => {
      const anchors = action.payload;
      state.anchors = anchors;
    },
    updateAnchor: (
      state,
      action: PayloadAction<{ anchorId: string; newSynonym: string; }>,
    ) => {
      const { anchorId, newSynonym } = action.payload;
      const editedAnchorIndex = state.anchors.findIndex((anchor) => anchor.id === anchorId);

      if (editedAnchorIndex !== -1) {
        state.anchors[editedAnchorIndex].synonym = newSynonym;
      }
    },

    deleteAnchor: (state, action: PayloadAction<{ anchorId: string }>) => {
      const { anchorId } = action.payload;

      const deletedAnchorIndex = state.anchors.findIndex((anchor) => anchor.id === anchorId);

      if (deletedAnchorIndex !== -1) {
        state.anchors.splice(deletedAnchorIndex, 1);
      }
    },
  },
});

export const { actions: anchorsActions, reducer: anchorsReducer } = anchorsSlice;
