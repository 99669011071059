import { useEffect, useRef } from 'react';

export type RefRegister = <T extends HTMLElement = HTMLElement>(ref: T) => void;

export const useOutsideClick = (
  { callback, isActive }:{ callback: () => void; isActive: boolean },
): RefRegister => {
  const nodeRef = useRef<HTMLElement[]>([]);

  const refRegister: RefRegister = (ref) => {
    if (!nodeRef.current.includes(ref)) {
      nodeRef.current.push(ref);
    }
  };

  useEffect(() => {
    let listener: EventListener | undefined;

    if (isActive) {
      listener = (event) => {
        if (!(nodeRef.current.some((element) => event.composedPath().includes(element)))) {
          callback();
        }
      };

      document.body.addEventListener('click', listener);
    }

    return () => {
      if (listener) {
        document.body.removeEventListener('click', listener);
        listener = undefined;
      }
    };
  }, [isActive]);

  return refRegister;
};
