import { createAsyncThunk } from '@reduxjs/toolkit';
import Router from 'next/router';

import { WithToken } from '~/api/auth/authApiTypes';
import { financeApi } from '~/api/finance/financeApi';
import {
  ChangeAutoPaymentStatusParams,
  ExportAgencyReportProps,
  GetBalanceResult,
  WithdrawalMethodTypeEnum,
} from '~/api/finance/financeApiTypes';
import { financePageActions, financePageSliceName } from '~/atomic/page/finance/financePage.slice';
import { environments } from '~/lib/const';
import { RootState } from '~/store';

export const getBalance = createAsyncThunk<
GetBalanceResult,
WithToken | void, {
  rejectValue: { error: string };
}>(
  `${financePageSliceName}/getBalance`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const result = await financeApi.getBalance(data);

      if (result && 'data' in result) {
        thunkAPI.dispatch(financePageActions.setInfo(result.data));
      }
    } catch (error) {
      if (environments.isClient) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const changeAutoPaymentStatus = createAsyncThunk<
void,
ChangeAutoPaymentStatusParams, {
  rejectValue: { error: string };
}>(
  `${financePageSliceName}/changeAutoPaymentStatus`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      thunkAPI.dispatch(financePageActions.setIsChangeAutoPaymentStatusLoading(true));
      await financeApi.changeAutoPaymentStatus(data);
      await thunkAPI.dispatch(getBalance());
    } catch (error) {
      if (environments.isClient) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(financePageActions.setIsChangeAutoPaymentStatusLoading(false));
    }
  },
);

export const withdrawMoney = createAsyncThunk<
void,
void, {
  rejectValue: { error: string }, state: RootState;
}>(
  `${financePageSliceName}/withdrawMoney`,
  async (
    _,
    thunkAPI,
  ) => {
    const { info } = thunkAPI.getState().finance.page;
    try {
      thunkAPI.dispatch(financePageActions.changeIsWithdrawMoneyLoading(true));
      await financeApi.withdrawMoney({
        amount: info.balance,
        attachedPaymentMethodUuid: info.attached.uuid,
      });

      thunkAPI.dispatch(getBalance());
    } catch (error) {
      if (environments.isClient) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(financePageActions.changeIsWithdrawMoneyLoading(false));
    }
  },
);

export const removeConnectedPayoutMethod = createAsyncThunk<
void,
void, {
  rejectValue: { error: string }, state: RootState;
}>(
  `${financePageSliceName}/removeConnectedPayoutMethod`,
  async (
    _,
    thunkAPI,
  ) => {
    const { info } = thunkAPI.getState().finance.page;
    try {
      if (info.attached.type === WithdrawalMethodTypeEnum.Bill) {
        await financeApi.removeConnectedBillPayoutMethod({ uuid: info.attached.uuid });
      } else if (info.attached.type === WithdrawalMethodTypeEnum.Card) {
        await financeApi.removeConnectedCardPayoutMethod({ uuid: info.attached.uuid });
      }

      thunkAPI.dispatch(financePageActions.deleteConnectPayoutMethod());
    } catch (error) {
      if (environments.isClient) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const getAgencyReports = createAsyncThunk<void, void, {
  rejectValue: { error: string };
}>(
  `${financePageSliceName}/getAgencyReports`,
  async (
    _,
    thunkAPI,
  ) => {
    try {
      thunkAPI.dispatch(financePageActions.setIsGetReportsLoading(true));
      const result = await financeApi.getAgencyReports();

      if (result && 'data' in result) {
        thunkAPI.dispatch(financePageActions.changeAgencyReports(result.data));
      }
    } catch (error) {
      if (environments.isClient) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(financePageActions.setIsGetReportsLoading(false));
    }
  },
);

export const exportAgencyReport = createAsyncThunk<
void,
ExportAgencyReportProps & { filename: string }, {
  rejectValue: { error: string };
}>(
  `${financePageSliceName}/exportAgencyReport`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const result = await financeApi.exportAgencyReport(data);

      if (result instanceof ArrayBuffer) {
        const a = document.createElement('a');

        const blob = new Blob([result], { type: 'application/zip' });
        a.href = URL.createObjectURL(blob);
        a.download = data.filename;

        a.click();
        URL.revokeObjectURL(a.href);
        a.remove();
      }
    } catch (error) {
      if (environments.isClient) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const changeLegalStatus = createAsyncThunk<
void,
void, {
  rejectValue: { error: string }; state: RootState
}>(
  `${financePageSliceName}/changeLegalStatus`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      Router.push('/partners/registration');
    } catch (error) {
      if (environments.isClient) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);
