import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { WithChildren } from '~/lib/WithChildren';

export interface LayoutType {
  getLayout?(page: any): ReactNode;
}

export const DefaultLayout: FC<{
  header: ReactNode; footer: ReactNode
} & WithChildren> = ({
  children, header, footer,
}) => {
  return (
    <SCDefaultLayoutWrapper>
      <SCHeaderWrapper>
        { header }
      </SCHeaderWrapper>
      { children }
      <SCFooterWrapper>
        { footer }
      </SCFooterWrapper>
    </SCDefaultLayoutWrapper>
  );
};

const SCHeaderWrapper = styled.div`
  display: flex;
  margin-top: 0;
`;

const SCFooterWrapper = styled.div`
  display: flex;
  margin-bottom: 0;
  margin-top: auto;
`;

const SCDefaultLayoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
`;
