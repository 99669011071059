import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Post } from '~/api/blog/blogTypes';

export const authorBlogSliceName = 'authorBlog';

const authorBlogSlice = createSlice({
  name: authorBlogSliceName,
  initialState: {
    isGetAuthorPostsLoading: false,
    posts: [] as Post[],
  },
  reducers: {
    setIsGetAuthorPostsLoading: (state, action: PayloadAction<boolean>) => {
      state.isGetAuthorPostsLoading = action.payload;
    },
    changeAuthorPosts: (state, action: PayloadAction<Post[]>) => {
      state.posts = action.payload;
    },
  },
});

export const { actions: authorBlogActions, reducer: authorBlogReducer } = authorBlogSlice;
