import React, { FC, ReactNode } from 'react';
import styled from 'styled-components';

import { WithChildren } from '~/lib/WithChildren';

export const HeroLayout: FC<{ hero: ReactNode } & WithChildren> = ({ children, hero }) => {
  return (
    <SCHeroLayout>
      <SCHeroWrapper>
        { hero }
      </SCHeroWrapper>
      <SCChildrenWrapper>
        { children }
      </SCChildrenWrapper>
    </SCHeroLayout>
  );
};

const SCHeroLayout = styled.div`
`;

const SCHeroWrapper = styled.div`

`;

const SCChildrenWrapper = styled.div`
`;
