import { SubscribeParams } from '~/api/account/subscriptionsApiTypes';
import { handleRejectedRequest } from '~/api/provider/providerErrors';
import { ApiResponse, RejectedRequest } from '~/api/provider/providerTypes';

import { provider } from '../provider/provider.config';

const namespace = Symbol('namespace');

export const subscriptionsApi = {
  [namespace]: '/v1/subscriptions',

  async subscribe(data: SubscribeParams) {
    try {
      const response = await provider.post<
      ApiResponse<void, RejectedRequest<SubscribeParams>>
      >(`${this[namespace]}`, data);
      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async unSubscribe(data: SubscribeParams) {
    try {
      const response = await provider.delete<
      ApiResponse<void, RejectedRequest<void>>
      >(`${this[namespace]}/${data.targetId}`);

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

};
