import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const subscribeBookModalSliceName = 'subscribeBookModal';

export const subscribeBookModalSlice = createSlice({
  name: subscribeBookModalSliceName,
  initialState: {
    isOpenModal: false,
    isOpenLoginModalWithSubscribeBook: false,
    isOpenRegisterModalWithSubscribeBook: false,
    isSubscribeBookLoading: false,
  },
  reducers: {
    setIsOpenModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenModal = action.payload;
    },
    setIsOpenLoginModalWithSubscribeBook: (state, action: PayloadAction<boolean>) => {
      state.isOpenLoginModalWithSubscribeBook = action.payload;
    },
    setIsOpenRegisterModalWithSubscribeBook: (state, action: PayloadAction<boolean>) => {
      state.isOpenRegisterModalWithSubscribeBook = action.payload;
    },
    setIsSubscribeBookLoading: (state, action: PayloadAction<boolean>) => {
      state.isSubscribeBookLoading = action.payload;
    },
  },
});

export const {
  actions: subscribeBookModalActions,
  reducer: subscribeBookModalReducer,
} = subscribeBookModalSlice;
