import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';

import { financeApi } from '~/api/finance/financeApi';
import { partnerProgramSelector } from '~/feature/finance/partnerProgram/partnerProgram.selector';
import {
  deletePartnerProgramName,
  partnerProgramActions,
  partnerProgramSliceName,
} from '~/feature/finance/partnerProgram/partnerProgram.slice';
import { isLoggedInSelector } from '~/feature/user/isLoggedInSelector';
import { environments } from '~/lib/const';
import { RootState } from '~/store';

export const updatePartner = createAsyncThunk<void,
{ visitedUrl: string },
{ rejectValue: { message: string }, state: RootState }>(
  `${partnerProgramSliceName}/updatePartner`,
  async ({ visitedUrl }, thunkAPI) => {
    const { isUpdatePartnerLoading } = partnerProgramSelector(thunkAPI.getState());

    if (!isUpdatePartnerLoading) {
      try {
        thunkAPI.dispatch(partnerProgramActions.setIsUpdatePartnerLoading(true));
        await financeApi.updatePartner({ visitedUrl });
      } catch (error) {
        return thunkAPI.rejectWithValue({ message: error.message });
      } finally {
        thunkAPI.dispatch(partnerProgramActions.setIsUpdatePartnerLoading(false));
      }
    }
  },
);

export const saveDeletePartnerInStorage = createAsyncThunk<void,
void,
{ rejectValue: { message: string }, state: RootState }>(
  `${partnerProgramSliceName}/saveDeletePartnerInStorage`,
  async () => {
    if (environments.isClient) {
      localStorage.setItem(deletePartnerProgramName, 'true');
    }
  },
);

export const getDeletePartnerFromStorage = createAsyncThunk<
boolean,
void,
{ rejectValue: { message: string }, state: RootState }>(
  `${partnerProgramSliceName}/getDeletePartnerFromStorage`,
  () => {
    if (environments.isClient) {
      return localStorage.getItem(deletePartnerProgramName) === 'true';
    }
  },
);

export const deletePartnerInStorage = createAsyncThunk<
void,
void,
{ rejectValue: { message: string }, state: RootState }>(
  `${partnerProgramSliceName}/deletePartnerInStorage`,
  () => {
    if (environments.isClient) {
      return localStorage.removeItem(deletePartnerProgramName);
    }
  },
);

export const checkDeletePartner = createAsyncThunk<
void,
void,
{ rejectValue: { message: string }, state: RootState }>(
  `${partnerProgramSliceName}/checkDeletePartner`,
  async (_, thunkAPI) => {
    const isDeletePartner = unwrapResult(await thunkAPI.dispatch(getDeletePartnerFromStorage()));

    if (isDeletePartner) {
      await financeApi.deletePartner();
      thunkAPI.dispatch(deletePartnerInStorage());
    }
  },
);

export const deletePartner = createAsyncThunk<void,
void,
{ rejectValue: { message: string }, state: RootState }>(
  `${partnerProgramSliceName}/updatePartner`,
  async (data, thunkAPI) => {
    const isLoggedIn = isLoggedInSelector(thunkAPI.getState());

    try {
      if (isLoggedIn) {
        await financeApi.deletePartner();
      } else {
        thunkAPI.dispatch(saveDeletePartnerInStorage());
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ message: error.message });
    }
  },
);
