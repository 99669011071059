import { GetServerSidePropsContext } from 'next';
import Router from 'next/router';
import qs from 'query-string';

interface GetQueriesFromUrlProps <TYPE extends string> {
  url?: string;
  searchedQueries: TYPE[];
  isDeleteOptions?: boolean;
}
interface GetQueriesFromContextProps <TYPE extends string> extends
  Omit<GetQueriesFromUrlProps<TYPE>, 'url' | 'isDeleteOptions'> {
  context: GetServerSidePropsContext;
}

export const getQueriesFromUrl = async <TYPE extends string>(
  {
    searchedQueries,
    isDeleteOptions = true,
    url = String(window.location),
  }: GetQueriesFromUrlProps<TYPE>,
) => {
  const parsedUrl = qs.parseUrl(url);

  const queries = {} as { [key in TYPE]: string | undefined };

  searchedQueries.forEach((query) => {
    const searchedQuery = parsedUrl.query[query];

    if (searchedQuery) {
      if (Array.isArray(searchedQuery)) {
        queries[query] = searchedQuery[0] as string;
      } else {
        queries[query] = searchedQuery as string;
      }
      delete parsedUrl.query[query];
    }
  });

  if (Object.keys(queries).length && isDeleteOptions) {
    const url = new URL(qs.stringifyUrl(parsedUrl));

    const redirectUrl = url.pathname + url.search + url.hash;
    await Router.replace(Router.pathname, redirectUrl, { shallow: true });
  }

  return queries;
};

export const getQueriesFromContext = <TYPE extends string>(
  {
    searchedQueries,
    context,
  }: GetQueriesFromContextProps<TYPE>,
) => {
  const queries = {} as { [key in TYPE]: string | undefined };

  searchedQueries.forEach((query) => {
    const searchedQuery = context.query[query];

    if (searchedQuery) {
      if (Array.isArray(searchedQuery)) {
        queries[query] = searchedQuery[0] as string;
      } else {
        queries[query] = searchedQuery as string;
      }
    }
  });

  return queries;
};
