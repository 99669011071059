import { environments } from '~/lib/const';

export const createSocialLinks = ({
  url = environments.isClient
    ? window.location.href
    : '',
  title = environments.isClient
    ? document.title
    : '',
  description = environments.isClient
    ? document.querySelector<HTMLMetaElement>('meta[name="description"]')?.content ?? ''
    : '',
  image = environments.isClient
    ? `${window.location.origin}/favicon.ico`
    : '',
}) => {
  if (!environments.isClient) {
    return {
      vk: '',
      ok: '',
    };
  }

  return {
    vk: `https://vk.com/share.php?url=${
      url}&title=${
      title}&description=${
      description}&image=${
      image
    }`,
    ok: `https://connect.ok.ru/offer?url=${
      url}`,
  };
};
