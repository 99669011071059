import {
  createAsyncThunk, createSlice, PayloadAction,
} from '@reduxjs/toolkit';
import { message } from 'antd';

import { WithToken } from '~/api/auth/authApiTypes';
import { bookApi } from '~/api/book/bookApi';
import { Book, BookSortingEnum } from '~/api/book/bookApiTypes';
import { Paginated } from '~/api/provider/providerTypes';
import {
  genresCheckboxForApiSelector,
  isAllFavoriteGenresIsActiveSelector,
} from '~/atomic/page/index/feed.selector';
import { createCatalogFilterUrl } from '~/feature/catalog/filter/catalogFilter.data';
import {
  catalogFilterGenresSelector,
  catalogFilterSelector,
} from '~/feature/catalog/filter/catalogFilter.selector';
import { catalogFilterActions } from '~/feature/catalog/filter/catalogFilter.slice';
import { paginationSelector } from '~/feature/pagination/pagination.selector';
import { paginationActions } from '~/feature/pagination/pagination.slice';
import { environments } from '~/lib/const';
import { RootState } from '~/store';

const blackfridaySliceName = 'blackfriday';

export const getBlackfridayPageBooks = createAsyncThunk<
Paginated<Book[]>,
WithToken | void,
{ rejectValue: { error: string }; state: RootState }>(
  `${blackfridaySliceName}/getBlackfridayPageBooks`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const {
        completeStatus,
        bookType,
        isCanDownload,
        isOnlyFree,
        withAudiobook,
        isExclusive,
        tagSlug,
        withAdvertisingFromBookriver,
        isWithAbonnement,
      } = catalogFilterSelector(thunkAPI.getState());
      const { perPage, page } = paginationSelector(thunkAPI.getState());

      const genresSlugs = catalogFilterGenresSelector(thunkAPI.getState());
      const genres = genresSlugs.join(',').replace(' ', '');
      const genresCheckboxForApi = genresCheckboxForApiSelector(thunkAPI.getState());
      const isAllFavoriteGenresIsActive = isAllFavoriteGenresIsActiveSelector(thunkAPI.getState());
      const isSendFavoriteGenres = genres.length === 0
        && !isAllFavoriteGenresIsActive;

      const result = await bookApi.getBookList({
        additionalGenres: genres.length > 0
          ? genres
          : undefined,
        favoriteGenres: isSendFavoriteGenres
          ? JSON.stringify({ genres: genresCheckboxForApi })
          : undefined,
        statusComplete: completeStatus,
        free: isOnlyFree ? true : undefined,
        download: isCanDownload ? true : undefined,
        exclusive: isExclusive ? true : undefined,
        withAudiobook: withAudiobook ? true : undefined,
        withAbonnement: isWithAbonnement ? true : undefined,
        minDiscountPercent: 20,
        type: bookType,
        tag: tagSlug === '' ? undefined : tagSlug,
        sortingType: BookSortingEnum.Rand,
        perPage,
        hideAudiobooksWithoutEbooks: withAudiobook ? false : undefined,
        withAdvertisingFromBookriver: withAdvertisingFromBookriver ? true : undefined,
        page,
        token: data && data?.token ? data.token : undefined,
      });

      if (result && 'data' in result) {
        const popularTags = result.popularTags.map((tag) => {
          const urlWithTagResult = createCatalogFilterUrl(thunkAPI.getState(), { tagSlug: tag.slug });
          const urlWithoutTagResult = createCatalogFilterUrl(thunkAPI.getState(), { tagSlug: '' });

          return {
            ...tag,
            url: urlWithTagResult,
            withoutUrl: urlWithoutTagResult,
          };
        });

        thunkAPI.dispatch(paginationActions.initPagination({
          total: result.total,
          page: result.currentPage,
          perPage: result.perPage,
        }));

        thunkAPI.dispatch(blackfridayPageActions.changeBooks(result.data));
        thunkAPI.dispatch(catalogFilterActions.changeTags(popularTags));

        return {
          ...result,
          popularTags: genres.length > 0 ? popularTags : [],
        };
      }
    } catch (error) {
      if (environments.isClient && error.message) message.error(error.message);
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(blackfridayPageActions.setIsLoadBooks(false));
    }
  },
);

export const blackfridayPageSelector = (state: RootState) => state.blackfridayPage;

const blackfridayPageSlice = createSlice({
  name: blackfridaySliceName,
  initialState: {
    books: [] as Book[],
    isLoadBooks: true,
  },
  reducers: {
    changeBooks: (state, action: PayloadAction<Book[]>) => {
      state.books = action.payload;
    },
    setIsLoadBooks: (state, action: PayloadAction<boolean>) => {
      state.isLoadBooks = action.payload;
    },
  },
});

export const blackfridayPageActions = blackfridayPageSlice.actions;

export const blackfridayPageReducer = blackfridayPageSlice.reducer;
