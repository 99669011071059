import { GraphqlBookProperties } from '~/graphql/books/factory/book/BookPropertiesFactoryTypes';
import { BookPropertiesFragment } from '~/graphql/books/GraphQLGeneratedCode';

export class BookPropertiesFactory {
  static create(book: BookPropertiesFragment): GraphqlBookProperties {
    return {
      freeChaptersCount: book.freeChaptersCount,
      note: book.note,
      tags: book.tags.map((tag) => ({
        slug: tag.slug,
        name: tag.name,
        id: tag.id,
        nameVin: tag.nameVin,
      })),
      commentAccessStatus: book.commentAccessStatus,
      canAuthorEditGenres: book.canAuthorEditGenres,
      isFoulLanguage: book.isFoulLanguage,
      download: book.download,
      canComment: book.canComment,
      containsEroticScenes: book.containsEroticScenes,
    };
  }
}
