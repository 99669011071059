import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const bindingPhoneSliceName = 'bindingPhone';

const bindingPhoneFormSlice = createSlice({
  name: bindingPhoneSliceName,
  initialState: {
    code: '',
    codeError: '',
    checkCode: false,
    isPhoneValid: false,
    isCodeValid: false,
  },
  reducers: {
    setCodeError: (state, action: PayloadAction<string>) => {
      state.codeError = action.payload;
    },
    setCode: (state, action: PayloadAction<string>) => {
      state.code = action.payload;
    },
    setCheckCode: (state, action: PayloadAction<boolean>) => {
      state.checkCode = action.payload;
    },
    setIsPhoneValid: (state, action: PayloadAction<boolean>) => {
      state.isPhoneValid = action.payload;
    },
    setIsCodeValid: (state, action: PayloadAction<boolean>) => {
      state.isCodeValid = action.payload;
    },
  },
});

export const {
  actions: bindingPhoneActions,
  reducer: bindingPhoneReducer,
} = bindingPhoneFormSlice;
