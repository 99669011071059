import { GetServerSideProps, NextPage } from 'next';
import React from 'react';

import { pagesConfigStore } from '~/atomic/atom/links/pagesConfigStore';
import { LayoutType } from '~/atomic/layout/DefaultLayout';
import { PageTemplate } from '~/atomic/organism/PageTemplate/PageTemplate';
import { getAuthorByName } from '~/atomic/page/author/author.data';
import { SCAuthorPage } from '~/atomic/page/author/styled';
import { PostPage } from '~/atomic/page/post/PostPage';
import { checkAuthToken } from '~/feature/authorization/checkAuthToken';
import { getAuthTokenFromServer } from '~/feature/authorization/getAuthToken';
import { getPostById } from '~/feature/blogs/blog.data';
import { blogSelector, postContentForSeoSelector } from '~/feature/blogs/blog.selector';
import { blogActions, getPostIdFromSlug } from '~/feature/blogs/blog.slice';
import { AuthorTemplate } from '~/feature/template/AuthorTemplate';
import { PostTemplate } from '~/feature/template/PostTemplate';
import {
  createStore, RootState, useAppSelector,
} from '~/store';

export enum PostPlaceEnum {
  Author = 'author',
  Blog = 'blog',
}

const Post:NextPage<{ place: PostPlaceEnum }> & LayoutType = ({ place }) => {
  const { post } = useAppSelector(blogSelector);
  const title = `${post?.title} | Литературно-издательская площадка Bookriver`;
  const description = useAppSelector(postContentForSeoSelector);
  const content = place === PostPlaceEnum.Author
    ? <SCAuthorPage><PostPage /></SCAuthorPage>
    : <PostPage />;

  return (
    <PageTemplate
      isPublic={pagesConfigStore.post.isPublic}
      seo={{
        title,
        description,
        image: '/android-chrome-192x192.png',
        type: 'article',
        canonical: pagesConfigStore.post.as(post.slug),
        customSeoTags: (
          <>
            <meta
              property="article:published_time"
              content={post?.publishedAt}
            />
            <meta
              property="article:modified_time"
              content={post?.updatedAt}
            />
            <meta
              property="article:section"
              content={post?.theme}
            />
            <meta
              property="article:author"
              content={post?.author?.name}
            />
          </>
        ),
      }}
      content={content}
    />
  );
};

Post.getLayout = (page) => {
  if (page?.props?.place === PostPlaceEnum.Author) {
    return (
      <AuthorTemplate>
        { page }
      </AuthorTemplate>
    );
  }

  return (
    <PostTemplate>{ page }</PostTemplate>
  );
};

export const getServerSideProps: GetServerSideProps<
{ state: RootState; place: string; },
{ slugPost: string; }
> = async (context) => {
  const { dispatch, store } = await createStore();
  await checkAuthToken(context, dispatch);
  const token = getAuthTokenFromServer(context);

  const slugPost = context.params?.slugPost ?? '';
  const place = (context.query?.place ?? PostPlaceEnum.Blog) as PostPlaceEnum;
  const postId = getPostIdFromSlug(slugPost);

  if (!postId) {
    return { notFound: true };
  }

  await dispatch(blogActions.setIsGetMePostsLoading(true));
  await dispatch(getPostById({ id: postId }));

  const { post } = store.getState().blog;

  if (!post?.id || post.status === 'draft') {
    return { notFound: true };
  }

  if (place === PostPlaceEnum.Author) {
    await dispatch(getAuthorByName({ username: post.author.username, token }));
  }

  const state = store.getState();
  return { props: { state, place } };
};

export default Post;
