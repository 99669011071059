import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ChangeMyBookStatus } from '~/api/account/myBooksApiTypes';
import {
  Book, BookLog,
  BookProperties,
  CompleteStatusEnum,
  MiniatureFormatEnum,
  MiniatureSizesEnum,
} from '~/api/book/bookApiTypes';
import { BookReaction, GraphqlBook } from '~/graphql/books/factory/book/BookFactoryTypes';
import { GraphqlBookWithProperties } from '~/graphql/books/factory/book/BookPropertiesFactoryTypes';
import {
  AuthorGraphqlBook,
  CycleGraphqlBook,
  GraphqlBookWithChapters,
} from '~/graphql/books/factory/book/GetBookPageFactoryTypes';

const initState = {
  message: '',
  loading: false,
  isGetBookPropertiesLoading: false,
  isGetCurrentBookLoading: false,
  changeMyBookStatusLoading: false,
  isGetSimilarBooksLoading: false,
  isChangePublishStatusLoading: false,
  bookList: [] as Book[],
  currentBook: {} as Partial<Book>,
  currentGraphqlBook: {} as Partial<GraphqlBook>,
  currentBookLog: {
    data: [] as BookLog[],
    total: 0,
    currentPage: 1,
    perPage: 20,
  },
  currentBookProperties: {} as Partial<BookProperties>,
  bookPage: {} as GraphqlBookWithChapters & GraphqlBookWithProperties,
  otherBooksByAuthor: [] as AuthorGraphqlBook[],
  bookPageCycleBooks: [] as CycleGraphqlBook[],
};

const bookSlice = createSlice({
  name: 'book',
  initialState: initState,
  reducers: {
    setChangeMyBookStatusLoading: (state, action: PayloadAction<boolean>) => {
      state.changeMyBookStatusLoading = action.payload;
    },
    setIsGetBookPropertiesLoading: (state, action: PayloadAction<boolean>) => {
      state.isGetBookPropertiesLoading = action.payload;
    },
    setIsGetCurrentBookLoading: (state, action: PayloadAction<boolean>) => {
      state.isGetCurrentBookLoading = action.payload;
    },
    setIsGetSimilarBooksLoading: (state, action: PayloadAction<boolean>) => {
      state.isGetSimilarBooksLoading = action.payload;
    },
    setIsChangePublishStatusLoading: (state, action: PayloadAction<boolean>) => {
      state.isChangePublishStatusLoading = action.payload;
    },
    changeCurrentBookCompleteStatus: (state, action: PayloadAction<CompleteStatusEnum>) => {
      state.currentBook.statusComplete = action.payload;
    },
    changeCurrentBook: (state, action: PayloadAction<Partial<Book>>) => {
      state.currentBook = action.payload;
    },
    changeCurrentGraphqlBook: (state, action: PayloadAction<Partial<GraphqlBook>>) => {
      state.currentGraphqlBook = action.payload;
    },
    setMessage: (state, action: PayloadAction<string>) => { state.message = action.payload; },
    changeMyBookStatus: (
      state,
      action: PayloadAction<ChangeMyBookStatus>,
    ) => {
      state.currentGraphqlBook.myBook.readStatus = action.payload.status;
    },
    changeMyBookPageStatus: (
      state,
      action: PayloadAction<ChangeMyBookStatus>,
    ) => {
      state.bookPage.myBook.readStatus = action.payload.status;
    },
    changeCurrentBookPrice: (state, action: PayloadAction<number>) => {
      state.currentBook.price = action.payload;
    },
    changeCurrentBookFree: (state, action: PayloadAction<boolean>) => {
      state.currentBook.free = action.payload;
    },
    changeCurrentBookFreeChapters: (state, action: PayloadAction<number>) => {
      state.currentBookProperties.freeChaptersCount = action.payload;
    },
    changeCurrentBookExclusive: (state, action: PayloadAction<boolean>) => {
      state.currentBook.exclusive = action.payload;
    },
    changeCurrentBookName: (state, action: PayloadAction<string>) => {
      state.currentBook.name = action.payload;
    },
    changeCurrentBookCoverUrl: (state, action: PayloadAction<string>) => {
      if (state.currentBook?.coverImages?.length) {
        state.currentBook.coverImages = [...state.currentBook.coverImages, {
          sizeType: MiniatureSizesEnum.Original,
          format: MiniatureFormatEnum.JPG,
          url: action.payload,
        }];
      } else {
        state.currentBook.coverImages = [{
          sizeType: MiniatureSizesEnum.Original,
          format: MiniatureFormatEnum.JPG,
          url: action.payload,
        }];
      }
    },
    changeBookList: (state, action: PayloadAction<Book[]>) => {
      state.bookList = action.payload;
    },
    changeBookPageRating: (state, action: PayloadAction<BookReaction>) => {
      state.bookPage.myBook.reaction = action.payload;
    },
    changeGraphqlBookRating: (state, action: PayloadAction<BookReaction>) => {
      state.currentGraphqlBook.myBook.reaction = action.payload;
    },
    changeBookPageHasReview: (state, action: PayloadAction<boolean>) => {
      state.bookPage.myBook.hasReview = action.payload;
    },
    changeCurrentGraphqlBookHasReview: (state, action: PayloadAction<boolean>) => {
      state.currentGraphqlBook.myBook.hasReview = action.payload;
    },
    changeBookPage: (state, action: PayloadAction<GraphqlBookWithChapters & GraphqlBookWithProperties>) => {
      state.bookPage = action.payload;
    },
    changeCurrentBookLog: (state, action: PayloadAction<{
      data: BookLog[],
      total: number,
      currentPage: number,
      perPage: number,
    }>) => {
      state.currentBookLog = action.payload;
    },
    changeCurrentBookProperties: (state, action: PayloadAction<BookProperties>) => {
      state.currentBookProperties = action.payload;
    },
    changeBookPageCycleBooks: (state, action: PayloadAction<CycleGraphqlBook[]>) => {
      state.bookPageCycleBooks = action.payload;
    },
    changeOtherBooksByAuthor: (state, action: PayloadAction<AuthorGraphqlBook[]>) => {
      state.otherBooksByAuthor = action.payload;
    },
  },
});

export const { actions: bookActions, reducer: bookReducer } = bookSlice;
