import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { GetRegistrationStepAuthorResponse, RegistrationRequest } from '~/api/account/userApiTypes';
import { RootState } from '~/store';

export const userRegistrationSliceName = 'user/registration';

export const registrationSelector = (state: RootState) => state.registration;

const registrationSlice = createSlice({
  name: userRegistrationSliceName,
  initialState: {
    author: {} as Partial<GetRegistrationStepAuthorResponse>,
    registrationRequest: {} as Partial<RegistrationRequest>,
  },
  reducers: {
    changeAuthor: (state, action: PayloadAction<Partial<GetRegistrationStepAuthorResponse>>) => {
      state.author = action.payload;
    },
    changeRegistrationRequest: (state, action: PayloadAction<Partial<RegistrationRequest>>) => {
      state.registrationRequest = action.payload;
    },
  },
});

export const { actions: registrationActions, reducer: registrationReducer } = registrationSlice;
