import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetServerSidePropsContext, NextPageContext } from 'next';
import Router from 'next/router';
import qs from 'query-string';

import {
  DEFAULT_PAGE_SIZE,
  paginationActions,
  paginationSliceName,
} from '~/feature/pagination/pagination.slice';
import { environments } from '~/lib/const';
import { RootState } from '~/store';

export const getPagingFromUrl = createAsyncThunk<
void,
GetServerSidePropsContext | NextPageContext | void,
{ rejectValue: { error: string }; state: RootState }
>(
  `${paginationSliceName}/getPagingFromUrl`,
  async (
    ctx,
    thunkAPI,
  ) => {
    try {
      let query;

      if (environments.isClient) {
        query = Router?.query;
      } else {
        query = (ctx && ctx?.query) ?? undefined;
      }

      const perPage = Number(query?.perPage ?? DEFAULT_PAGE_SIZE);
      const page = Number(query?.page ?? 1);

      thunkAPI.dispatch(paginationActions.setPage({ page }));
      thunkAPI.dispatch(paginationActions.setPageSize({ perPage }));
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const setPagingToUrl = createAsyncThunk<
void,
void,
{ rejectValue: { error: string }; state: RootState }
>(
  `${paginationSliceName}/setPagingToUrl`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const parsedUrl = qs.parseUrl(String(window.location));
      const paginationState = thunkAPI.getState().pagination;

      parsedUrl.query.page = String(paginationState.page);
      parsedUrl.query.perPage = String(paginationState.perPage);

      const url = new URL(qs.stringifyUrl(parsedUrl));

      const redirectUrl = url.pathname + url.search + url.hash;

      await Router.replace(Router.pathname, redirectUrl, { shallow: true });
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const changePage = createAsyncThunk<
void,
{ page: number, saveInUrl: boolean; },
{ rejectValue: { error: string }; state: RootState }
>(
  `${paginationSliceName}/changePage`,
  async (
    data,
    thunkAPI,
  ) => {
    await thunkAPI.dispatch(paginationActions.setPage({ page: data.page }));
    if (data.saveInUrl) {
      await thunkAPI.dispatch(setPagingToUrl());
    }
  },
);

export const changePageSize = createAsyncThunk<
void,
{ perPage: number, saveInUrl: boolean; },
{ rejectValue: { error: string }; state: RootState }
>(
  `${paginationSliceName}/changePageSize`,
  async (
    data,
    thunkAPI,
  ) => {
    await thunkAPI.dispatch(paginationActions.setPageSize(data));
    if (data.saveInUrl) {
      await thunkAPI.dispatch(setPagingToUrl());
    }
  },
);

export const resetPagination = createAsyncThunk<
void,
{ isNotSave?: boolean } | void,
{ rejectValue: { error: string }; state: RootState }
>(
  `${paginationSliceName}/resetPagination`,
  async (
    data,
    thunkAPI,
  ) => {
    await thunkAPI.dispatch(paginationActions.setPageSize({ perPage: DEFAULT_PAGE_SIZE }));
    await thunkAPI.dispatch(paginationActions.setPage({ page: 1 }));
    await thunkAPI.dispatch(paginationActions.setTotal({ total: 0 }));

    // Такое сложное условия для того что бы можно было не передавать параметры
    if (!data || (data && ('isNotSave' in data) && !data.isNotSave)) {
      await thunkAPI.dispatch(setPagingToUrl());
    }
  },
);
