import { useEffect } from 'react';

import { showAlert } from '~/feature/userAlert/showAlert';
import { useAppDispatch } from '~/store';

export const CheckUserAlert = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(showAlert());
  }, []);

  return null;
};
