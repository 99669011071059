import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Notification,
  NotificationCategory,
  NotificationChannelsEnum,
  NotificationsSettings,
} from '~/api/account/notificationsApiTypes';

export const notificationsSliceName = 'notifications';

const notificationsSlice = createSlice({
  name: notificationsSliceName,
  initialState: {
    isNotificationsLoading: false,
    isGetNotificationSettings: false,
    notifications: [] as Notification[],
    settings: [] as NotificationsSettings[],
    groups: [] as NotificationCategory[],
    page: 1,
    perPage: 50,
    total: 0,
  },
  reducers: {
    changeNotificationsLoading: (state, action: PayloadAction<boolean>) => {
      state.isNotificationsLoading = action.payload;
    },
    setIsGetNotificationSettings: (state, action: PayloadAction<boolean>) => {
      state.isGetNotificationSettings = action.payload;
    },
    updateNotificationSettings: (state, action: PayloadAction<
    { group: string; value: boolean; channel: NotificationChannelsEnum }
    >) => {
      state.settings.find(
        (setting) => setting.group.alias === action.payload.group,
      ).channels[action.payload.channel] = action.payload.value;
    },
    changeGroups: (state, action: PayloadAction<NotificationCategory[]>) => {
      state.groups = action.payload;
    },
    changeSettings: (state, action: PayloadAction<NotificationsSettings[]>) => {
      state.settings = action.payload;
    },
    changeNotifications: (state, action: PayloadAction<Notification[]>) => {
      state.notifications = action.payload;
    },
    loadMoreNotifications: (state, action: PayloadAction<Notification[]>) => {
      state.notifications = [...state.notifications, ...action.payload];
    },
    changePage: (state, action: PayloadAction<number>) => {
      state.page = action.payload;
    },
    changePagination: (state, action: PayloadAction<{ total: number; page: number; perPage: number; }>) => {
      state.total = action.payload.total;
      state.perPage = action.payload.perPage;
      state.page = action.payload.page;
    },
  },
});

export const {
  actions: notificationsActions,
  reducer: notificationsReducer,
} = notificationsSlice;
