/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

import { SCSVGIcon } from './SCCIcon';

export const LoaderIcon = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <SCSVGIcon
      fill={props.fill || '#4b96fb'}
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
    >
      <path d="M10 50A40 40 0 0 0 90 50A40 43 0 0 1 10 50" stroke="none">
        <animateTransform attributeName="transform" type="rotate" dur="0.78125s" repeatCount="indefinite" keyTimes="0;1" values="0 50 51.5;360 50 51.5" />
      </path>
    </SCSVGIcon>
  );
};
