import styled from 'styled-components';

import { lessThan } from '~/lib/mediaQuery';

import { TextMediumStyle } from '../Typography';

export const SCAgeRating = styled.div`
  ${TextMediumStyle};
  background: #535659;
  box-sizing: border-box;
  width: 100%;
  max-width: 36px;
  height: 36px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;

  ${lessThan('sm')} {
    height: 32px;
    max-width: 32px;
    font-size: 12px;
    line-height: 14px;
  }

  ${lessThan('xs')} {
    height: 28px;
    max-width: 28px;
    font-size: 12px;
    line-height: 14px;
  }
`;
