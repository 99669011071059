export const breakepoints = {
  'screen-xxs-down': '440px',
  'screen-xxs-down-raw': 440,

  'screen-xs-down': '576px',
  'screen-xs-down-raw': 576,

  'screen-sm-down': '767px',
  'screen-sm-down-raw': 767,

  'screen-md-down': '1023px',
  'screen-md-down-raw': 1023,

  'screen-lg-down': '1170px',
  'screen-lg-down-raw': 1170,

  'screen-xl-down': '1440px',
  'screen-xl-down-raw': 1440,

  'container-width-down': '1199px',
  'container-width-down-raw': 1199,
};
