import React, { useEffect } from 'react';
import styled from 'styled-components';

import { AuthorLink, PostEditLink } from '~/atomic/atom/links/dynamicLinks';
import { PaperStyle } from '~/atomic/atom/PaperStyle';
import { Skeleton } from '~/atomic/atom/Skeleton';
import { BookStatus } from '~/atomic/molecula/BookStatus';
import { viewPost } from '~/feature/blogs/blog.data';
import { blogSelector } from '~/feature/blogs/blog.selector';
import { getPostThemeName } from '~/feature/blogs/EditPostCard';
import { PostCardSocialLinks } from '~/feature/blogs/PostCardSocialLinks';
import {
  SCAuthorPostCard,
  SCBottomPostCard,
  SCControlsPostCard,
  SCDatePostCard,
  SCEditPostCardButton,
  SCHeaderPostCard,
  SCMainPostCard,
  SCPostCard,
  SCStatisticsCountPostCard,
  SCStatisticsItemPostCard,
  SCStatisticsPostCard, SCTextPost,
  SCTitlePostCard,
} from '~/feature/blogs/postCardStyle';
import { Comments } from '~/feature/comments/Comments';
import { userSelector } from '~/feature/user/user.selector';
import { environments } from '~/lib/const';
import { DateFormatComponent } from '~/lib/dateFormatText';
import { useAppDispatch, useAppSelector } from '~/store';

export const PostPage = () => {
  const dispatch = useAppDispatch();
  const { post, isGetPostByIdLoading } = useAppSelector(blogSelector);
  const { user } = useAppSelector(userSelector);

  const isUserAuthorPost = Number(post?.author?.id) === Number(user?.id);

  useEffect(() => {
    if (!isGetPostByIdLoading && environments.isClient && post?.id) {
      dispatch(viewPost({ postId: post.id }));
    }
  }, [post.id]);

  if (isGetPostByIdLoading) {
    <SCPostCard>
      <Skeleton
        active
        paragraph={{ rows: 8 }}
      />
    </SCPostCard>;
  }

  return (
    <SCPostPage itemScope itemType="http://www.schema.org/BlogPosting">
      <SCPostCard>
        <SCMainPostCard>
          <SCHeaderPostCard>
            <SCTitlePostCard itemProp="headline" as="h1">
              {post?.title}
            </SCTitlePostCard>
            <AuthorLink
              username={post?.author?.username ?? ''}
            >
              <SCAuthorPostCard>
                <span itemProp="author">
                  {post?.author?.name ?? ''}
                </span>
              </SCAuthorPostCard>
            </AuthorLink>
            <BookStatus itemProp="about" text={getPostThemeName(post?.theme)} type="theme" />
            {post?.publishedAt && (
              <SCDatePostCard itemProp="datePublished">
                <DateFormatComponent date={post?.publishedAt ?? new Date()} />
              </SCDatePostCard>
            )}
          </SCHeaderPostCard>
          <SCTextPost
            dangerouslySetInnerHTML={{ __html: post?.content }}
          />
        </SCMainPostCard>
        <SCBottomPostCard>
          <SCStatisticsPostCard>
            <SCStatisticsItemPostCard>
              комментарии
              <SCStatisticsCountPostCard itemProp="commentCount">
                {post?.counters?.comments ?? '0'}
              </SCStatisticsCountPostCard>
            </SCStatisticsItemPostCard>
          </SCStatisticsPostCard>
          <SCControlsPostCard>
            <>
              <PostCardSocialLinks />
              {isUserAuthorPost && (
                <PostEditLink
                  postId={post.id}
                >
                  <a>
                    <SCEditPostCardButton>
                      Редактировать пост
                    </SCEditPostCardButton>
                  </a>
                </PostEditLink>
              )}
            </>
          </SCControlsPostCard>
        </SCBottomPostCard>
      </SCPostCard>
      {post?.author?.id && (
        <SCCommentWrap>
          <Comments
            targetClass="post"
            targetId={post?.id}
            targetAuthorId={String(post?.author?.id)}
          />
        </SCCommentWrap>
      )}
    </SCPostPage>
  );
};

const SCPostPage = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;
`;

const SCCommentWrap = styled.div`
  ${PaperStyle};
  padding: 20px 16px 16px;
`;
