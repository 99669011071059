import React, { FC } from 'react';
import styled from 'styled-components';

import { TextBoldStyle } from '../Typography';
import { LoaderIcon } from './icon/LoaderIcon';

type ButtonType = 'secondary' | 'default' | 'primary' | 'gray' | 'error';
export type ButtonSize = 'normal' | 'small' | 'large';

export interface ButtonProps extends React.HTMLAttributes<HTMLButtonElement> {
  type?: ButtonType;
  size?: ButtonSize;
  isLoading?: boolean;
  block?: boolean;
  disabled?: boolean;
  htmlType?: 'button' | 'submit' | 'reset';
  className?: string;
  as?: React.ElementType<any>;
}

export const Button: FC<ButtonProps> = ({
  size = 'normal',
  type = 'default',
  children,
  isLoading = false,
  disabled = false,
  htmlType = 'button',
  block = false,
  style,
  className,
  as,
  ...props
}) => {
  return (
    <SCButton
      style={style}
      className={className}
      data-size={size}
      data-type-color={type}
      data-block={String(block)}
      disabled={disabled}
      type={htmlType}
      data-is-loading={String(isLoading)}
      as={as}
      {...props}
    >
      {isLoading ? <SCLoaderIcon /> : null}
      { children }
    </SCButton>
  );
};

const SCLoaderIcon = styled(LoaderIcon)`
  margin-right: 0;
  transition: 0.3s;
  width: 0;
  height: 0;
  opacity: 0;
  pointer-events: none;
`;

export const SCButton = styled.button`
  ${TextBoldStyle};

  &[type="button"] {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
  }

  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;

  transition: 0.3s;
  box-sizing: border-box;
  border-radius: 2px;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &[data-block="true"] {
    width: 100%;
  }

  &[data-type-color="default"] {
    color: var(--black-color);
    background: none;
    border: 1px solid var(--border-color);

    ${SCLoaderIcon} {
      svg {
        fill: var(--black-color);
      }
    }

    &:hover {
      border: 1px solid var(--gray3-color);
    }
  }

  &[data-type-color="primary"]  {
    color: var(--white-color);
    background: var(--primary-color);
    border: 1px solid var(--primary-hover-color);

    &:hover {
      background: var(--primary-hover-color);
    }

    
    &[data-is-loading="true"] {
      background: #78b1ff;
      border: 1px solid #78b1ff;

      &:hover {
        background: #78b1ff;
      }
    }

    ${SCLoaderIcon} {
      fill: #fff;
    }
  }

  &[data-type-color="error"]  {
    color: var(--white-color);
    background: var(--error-color);
    border: 1px solid var(--background-error-color);

    &:hover {
      background: var(--error-color);
    }

    &[data-is-loading="true"] {
      background: #f83868;
    }

    ${SCLoaderIcon} {
      fill: #fff;
    }
  }

  &[data-type-color="secondary"] {
    color: var(--black-color);
    background: var(--yellow-dark-color);
    border: 1px solid var(--yellow-dark-color);

    &:hover {
      background: var(--yellow-dark-color);
    }

    ${SCLoaderIcon} {
      fill: var(--black-color);
    }

    &[data-is-loading="true"] {
      &:hover {
        border: 1px solid var(--yellow-dark-color);
        background: var(--yellow-dark-color);
      }
    }
  }

  &[data-type-color="gray"] {
    color: var(--black-color);
    background: var(--bg-color);
    border: 1px solid var(--bg-color);

    ${SCLoaderIcon} {
      svg {
        fill: var(--black-color);
      }
    }

    &:hover {
      border: 1px solid var(--gray3-color);
    }
  }

  &[data-is-loading="true"] {
    pointer-events: none;
    
    ${SCLoaderIcon} {
      opacity: 1;
      pointer-events: auto;
      margin-right: 12px;
      width: 20px;
      height: 20px;
    }

    &:hover {
      cursor: default;
    }
  }

  &[data-size="normal"] {
    padding: 7px 16px;
  }

  &[data-size="small"] {
    padding: 5px 12px;
  }

  &[data-size="large"] {
    height: 44px;
    padding: 11px 24px;
  }

  &:disabled {
    color: rgba(0,0,0,.25);
    background: #f5f5f5;
    border-color: #d9d9d9;
    text-shadow: none;
    cursor: not-allowed;

    &:hover {
      color: rgba(0,0,0,.25);
      background: #f5f5f5;
      border-color: #d9d9d9;
      text-shadow: none;
      cursor: not-allowed;
    }
  }
`;
