import styled from 'styled-components';

import { breakepoints } from '~/atomic/breakepoints';
import { MobH2Style, TextTagStyle } from '~/atomic/Typography';
import { lessThan } from '~/lib/mediaQuery';

export const SCAuthorPage = styled.div`
  padding-bottom: 100px;
  display: flex;
  align-items: flex-start;

  ${lessThan('sm')} {
    flex-direction: column;
    align-items: center;
    padding-bottom: 40px;
  }
  
  @media only screen and (max-width: ${breakepoints['container-width-down']}) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

export const SCAuthorCard = styled.div``;

export const SCContent = styled.div`
  width: 100%;
  
  ${lessThan('sm')} {
    margin-top: 28px;
    margin-left: unset;
    
    h2 {
      ${MobH2Style} 
    }
  }
  
`;

export const SCAuthorCardPercent = styled.div`
  ${TextTagStyle};
  display: flex;
  align-items: center;
  position: absolute;
  top:0;
  left: 0;
  background: rgba(234, 27, 77, 0.15);
  padding: 2px 6px;
  color: var(--error-color);
  border-bottom-right-radius: 10px;
`;

export const SCAuthorCardPercentIcon = styled.img`
  width: 12px;
  height: 12px;
  margin-left: 8px;
`;
