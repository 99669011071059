import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Book } from '~/api/book/bookApiTypes';

export const discountsSliceName = 'discounts';

const discountsPageSlice = createSlice({
  name: discountsSliceName,
  initialState: {
    books: [] as Book[],
    isLoadBooks: true,
  },
  reducers: {
    changeBooks: (state, action: PayloadAction<Book[]>) => {
      state.books = action.payload;
    },
    setIsLoadBooks: (state, action: PayloadAction<boolean>) => {
      state.isLoadBooks = action.payload;
    },
  },
});

export const {
  actions: discountsPageActions,
  reducer: discountsPageReducer,
} = discountsPageSlice;
