import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Book, BookTypeEnum, CompleteStatusEnum,
} from '~/api/book/bookApiTypes';

export const partnersBooksSliceName = 'partnersBooks';

export const partnersBooksSlice = createSlice({
  name: partnersBooksSliceName,
  initialState: {
    filter: {
      genres: [] as string[],
      isCanDownload: null as boolean,
      bookType: null as BookTypeEnum,
      completeStatus: null as CompleteStatusEnum,
    },
    books: [] as Book[],
  },
  reducers: {
    changeBooks: (state, action: PayloadAction<Book[]>) => {
      state.books = action.payload;
    },
    updateFilter: (state, action: PayloadAction<{
      genres?: string[],
      isCanDownload?: boolean,
      bookType?: BookTypeEnum,
      bookCompleteStatus?: CompleteStatusEnum,
    }>) => {
      state.filter = { ...state.filter, ...action.payload };
    },
  },
});

export const { actions: partnersBooksActions, reducer: partnersBooksReducer } = partnersBooksSlice;
