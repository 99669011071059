import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Book } from '~/api/book/bookApiTypes';

export const tagPageSliceName = 'tagPage';

const tagPageSlice = createSlice({
  name: tagPageSliceName,
  initialState: {
    books: [] as Book[],
    isLoadBooks: false,
  },
  reducers: {
    setIsLoadBooks: (state, action: PayloadAction<boolean>) => {
      state.isLoadBooks = action.payload;
    },
    changeBooks: (state, action: PayloadAction<Book[]>) => {
      state.books = action.payload;
    },
  },
});

export const { actions: tagPageActions, reducer: tagPageReducer } = tagPageSlice;
