import throttle from 'lodash.throttle';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { ArrowIcon } from '~/atomic/atom/icon/ArrowIcon';
import { allowCookieSelector } from '~/feature/cookie/allowCookie/allowCookie.selector';
import { isMobileSizeSelector, windowSizeSelector } from '~/feature/media/windowSize.selector';
import { environments } from '~/lib/const';
import { useAppSelector } from '~/store';

export const ScrollUpButton = () => {
  const [scrolledHeight, changeScrolledHeight] = useState(0);
  const { isAllowCookie } = useAppSelector(allowCookieSelector);
  const { size: { height } } = useAppSelector(windowSizeSelector);
  const isMobileSize = useAppSelector(isMobileSizeSelector);

  const isScrolledWindowHeight = scrolledHeight > height;

  const scrollUpHandler = () => {
    if (window?.scrollTo) { window.scrollTo({ top: 0, behavior: 'smooth' }); }
  };

  useEffect(() => {
    const changeScrollWindowHandler = () => {
      changeScrolledHeight(window.scrollY);
    };

    const changeScrollWindowHandlerThrottle = throttle(changeScrollWindowHandler, 300);

    if (environments.isClient) {
      window.addEventListener('scroll', changeScrollWindowHandlerThrottle, { passive: true });

      return () => {
        window.removeEventListener('scroll', changeScrollWindowHandlerThrottle);
      };
    }
  }, []);

  if (isMobileSize) {
    return (
      <>
      </>
    );
  }

  return (
    <SCScrollUpButton
      data-is-show={isScrolledWindowHeight}
      data-is-show-allow-cookie={!isAllowCookie}
      onClick={scrollUpHandler}
    >
      <SCArrowIcon />
    </SCScrollUpButton>
  );
};

const SCScrollUpButton = styled.div`
  right: 30px;
  width: 40px;
  bottom: 50px;
  height: 40px;
  display: flex;
  opacity: 0;
  pointer-events: none;
  position: fixed;
  align-items: center;
  justify-content: center;
  background-color: var(--blue-color);
  box-shadow: 2px 2px 10px 0 rgba(210, 244, 255, 0.46);
  transition: 
    opacity 0.3s ease,
    box-shadow 0.3s ease;
  border-radius: 5px;
  cursor: pointer;
  z-index: 9;
  
  &:hover {
    box-shadow: none
  }
  
  &[data-is-show="true"] {
    opacity: 1;
    pointer-events: auto;
  }
  
  &[data-is-show-allow-cookie="true"] {
    bottom: 220px;
  }
`;

const SCArrowIcon = styled(ArrowIcon)`
  transform: rotate(-90deg);
  font-size: 20px;
  color: var(--primary-hover-color);
  align-items: center;
  justify-content: center;
`;
