import React from 'react';
import styled from 'styled-components';

import Modal from '~/atomic/molecula/Modal';
import { PageTitleStyle } from '~/atomic/Typography';
import { SaveUserLoginForm } from '~/feature/authorization/SaveUserLoginModal/SaveUserLoginForm';
import { lessThan } from '~/lib/mediaQuery';
import { useAppDispatch, useAppSelector } from '~/store';

import { openModal } from '../authorizationModal.slice';

export const SaveUserLoginModal = () => {
  const dispatch = useAppDispatch();
  const { openedModal } = useAppSelector(
    (state) => state.authorizationModal,
  );

  const closeModal = () => {
    dispatch(openModal(''));
  };

  const loginInOtherAccountHandler = () => {
    closeModal();
    dispatch(openModal('login'));
  };

  return (
    <SCAuthModal
      onCancel={closeModal}
      open={openedModal === 'saveUserLogin'}
    >
      <SCTitle>Вход</SCTitle>
      <SaveUserLoginForm
        loginInOtherAccount={loginInOtherAccountHandler}
      />
    </SCAuthModal>
  );
};

const SCTitle = styled.h2`
  ${PageTitleStyle};
  margin-bottom: 20px;
`;

export const SCAuthModal = styled(Modal)`
  max-width: 458px;
  
  ${lessThan('xs')} {
    max-width: 100vw;
    top: 0;
  }
`;
