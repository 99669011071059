import { createAsyncThunk } from '@reduxjs/toolkit';

import { myBooksApi } from '~/api/account/myBooksApi';
import {
  audioPlayerActions,
  audioPlayerActiveChapterSelector,
  AudioPlayerBook,
  AudioPlayerChapter, audioPlayerDefaultState,
  audioPlayerSelector,
  audioPlayerSliceName,
  firstPublishAudioChapterSelector,
} from '~/feature/audio/audioPlayer.slice';
import { RootState } from '~/store';

export const openAudioPlayer = createAsyncThunk<
void,
{ filePath?: string; } | void,
{ state: RootState }
>(
  `${audioPlayerSliceName}/openAudioPlayer`,
  async (data, thunkAPI) => {
    try {
      const { isOpenPlayer, audioPath } = thunkAPI.getState().audioPlayer;
      const firstChapter = firstPublishAudioChapterSelector(thunkAPI.getState());

      const path = data ? data.filePath : firstChapter.url;

      if (audioPath === path && isOpenPlayer) {
        thunkAPI.dispatch(audioPlayerActions.setIsChangeTrack(true));
        thunkAPI.dispatch(audioPlayerActions.setIsPlayed(false));
        thunkAPI.dispatch(audioPlayerActions.changeCurrentAudioTime(0));
      } else {
        thunkAPI.dispatch(audioPlayerActions.changeAudioPath(path));
        thunkAPI.dispatch(audioPlayerActions.setIsOpenPlayer(true));
        thunkAPI.dispatch(audioPlayerActions.setIsChangeTrack(true));
        thunkAPI.dispatch(audioPlayerActions.setIsPlayed(false));
        thunkAPI.dispatch(audioPlayerActions.changeCurrentAudioTime(0));
        thunkAPI.dispatch(audioPlayerActions.changeAudioTime(0));
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const loadAudioPlayer = createAsyncThunk<
void,
{ book: AudioPlayerBook, chapters: AudioPlayerChapter[], isSaveProgress?: boolean },
{ state: RootState }
>(
  `${audioPlayerSliceName}/loadAudioPlayerChapters`,
  async (data, thunkAPI) => {
    try {
      const book = data?.book;

      if (data.chapters.length) {
        thunkAPI.dispatch(audioPlayerActions.changeChapters(data.chapters));
        thunkAPI.dispatch(audioPlayerActions.changeBook(book));
        thunkAPI.dispatch(audioPlayerActions.setIsSaveProgress(data.isSaveProgress ?? true));
      }
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const preloadAudiobook = createAsyncThunk<
void,
{ book: AudioPlayerBook, chapters: AudioPlayerChapter[], },
{ state: RootState }
>(
  `${audioPlayerSliceName}/loadAudioPlayerChapters`,
  async ({ book, chapters }, thunkAPI) => {
    // сделано, чтобы исправить проблему на IOS с первой загрузкой главы
    const firstChapter = chapters.find((chapter) => chapter?.url);
    thunkAPI.dispatch(loadAudioPlayer({
      book: {
        author: book.author,
        name: book.name,
        id: book.id,
        slug: book.slug,
      },
      chapters,
    }));
    thunkAPI.dispatch(audioPlayerActions.changeAudioPath(firstChapter?.url));
  },
);
export const closeAudioPlayer = createAsyncThunk<
void,
void,
{ state: RootState }
>(
  `${audioPlayerSliceName}/loadAudioPlayerChapters`,
  async (_, thunkAPI) => {
    thunkAPI.dispatch(audioPlayerActions.setState(audioPlayerDefaultState));
  },
);

export const saveAudioChapterProgress = createAsyncThunk<
void,
{ progress: number },
{ state: RootState }
>(
  `${audioPlayerSliceName}/loadAudioPlayerChapters`,
  async (data, thunkAPI) => {
    const activeChapter = audioPlayerActiveChapterSelector(thunkAPI.getState());
    const {
      savedChapterProgress, isSaveProgress, book,
    } = audioPlayerSelector(thunkAPI.getState());
    const chapterProgressDiff = Math.abs(data.progress - savedChapterProgress);

    if (chapterProgressDiff > 10 && isSaveProgress) {
      await myBooksApi.savePlaceRead({
        chapterId: activeChapter.id,
        bookId: book.id,
        chapterProgress: Math.round(data.progress),
      });
      thunkAPI.dispatch(audioPlayerActions.changeSavedChapterProgress(data.progress));
    }
  },
);
