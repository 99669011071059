import React from 'react';
import styled from 'styled-components';

import { NavMenu } from '~/atomic/organism/NavMenu';
import { DefaultNavigationLinks } from '~/feature/user/navigation/DefaultNavigationLinks';
import { ExitNavigationLink } from '~/feature/user/navigation/ExitNavigationLink';
import { ReaderNavigationLinks } from '~/feature/user/navigation/ReaderNavigation/ReaderNavigationLinks';

import { AuthorStatusButton } from '../../AuthorStatusButton';

export const ReaderNavigation = ({ isMobile }: { isMobile?: boolean }) => {
  return (
    <SCNavMenu isMobile={isMobile}>
      <ReaderNavigationLinks />
      <DefaultNavigationLinks />
      <AuthorStatusButton />
      <ExitNavigationLink />
    </SCNavMenu>
  );
};

const SCNavMenu = styled(NavMenu)`
  > *:not(:last-child) {
    border-bottom: 10px solid var(--border-color);
  }
`;
