import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const timerDiscountsSliceName = 'timerDiscounts';

const timerDiscountsSlice = createSlice({
  name: timerDiscountsSliceName,
  initialState: {
    isEditDiscount: false,
    isSaveTimerDiscountLoading: false,
    isGetTimerDiscountLoading: false,
    isActive: false,
    isHaveTimerDiscount: false,
    isLoadingActivate: false,
    subsequentPurchasePercentage: 5,
    firstPurchasePercentage: 30,
  },
  reducers: {
    setIsEditDiscount: (state, action: PayloadAction<boolean>) => {
      state.isEditDiscount = action.payload;
    },
    setIsHaveTimerDiscount: (state, action: PayloadAction<boolean>) => {
      state.isHaveTimerDiscount = action.payload;
    },
    setIsSaveTimerDiscountLoading: (state, action: PayloadAction<boolean>) => {
      state.isSaveTimerDiscountLoading = action.payload;
    },
    setIsGetTimerDiscountLoading: (state, action: PayloadAction<boolean>) => {
      state.isGetTimerDiscountLoading = action.payload;
    },
    changeSubsequentPurchasePercentage: (state, action: PayloadAction<number>) => {
      state.subsequentPurchasePercentage = action.payload;
    },
    changeFirstPurchasePercentage: (state, action: PayloadAction<number>) => {
      state.firstPurchasePercentage = action.payload;
    },
    changeIsActive: (state, action: PayloadAction<boolean>) => {
      state.isActive = action.payload;
    },
    changeIsLoadingActivate: (state, action: PayloadAction<boolean>) => {
      state.isLoadingActivate = action.payload;
    },
  },
});

export const {
  actions: timerDiscountsActions,
  reducer: timerDiscountsReducer,
} = timerDiscountsSlice;
