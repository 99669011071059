export interface GetWidgetDiscountBooks {
  limit: number;
}

export interface Discount {
  percentMax: number;
  grade: number;
}

export interface SaveDiscountProps {
  percentMax: number;
  grade: number;
}

export interface GetDiscountProps extends Discount {
  isActive: boolean;
}

export interface CumulativeDiscountStatistics {
  percent: number;
  readers: number;
  purchases: number;
}

export interface TimerDiscount {
  expiredAt: string;
  enabled: boolean;
  percent: number;
  numberOfBooks: number;
}

export interface TimerDiscountSettings {
  firstPurchasePercentage: number;
  subsequentPurchasePercentage: number;
}

export interface GetTimerDiscountSettings {
  firstPurchasePercentage: number;
  subsequentPurchasePercentage: number;
  enabled: boolean;
}

export enum TimerDiscountOfferSourceEnum {
  Mail = 'mail',
  LastChapter = 'last_chapter',
  Feed = 'feed',
  Ad = 'ad',
}

export interface ActivateTimerDiscountProps {
  offerSource: TimerDiscountOfferSourceEnum;
}
