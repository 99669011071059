import { LinkProps as NextLinkProps } from 'next/dist/client/link';
import Link from 'next/link';
import { ParsedUrlQueryInput } from 'querystring';
import React, { FC, ReactNode } from 'react';

export interface DefaultLinkProps extends Omit<NextLinkProps, 'href' | 'as'> {
  hash?: string;
  query?: ParsedUrlQueryInput;
  children: ReactNode;
}

export interface TemplateStaticLinkProps extends DefaultLinkProps {
  href: string;
}

export const TemplateStaticLink: FC<TemplateStaticLinkProps> = ({
  children,
  hash,
  href,
  query,
  ...restProps
}) => {
  return (
    <Link
      href={{
        hash,
        query,
        pathname: href,
      }}
      passHref
      prefetch={false}
      {...restProps}
    >
      {children}
    </Link>
  );
};
