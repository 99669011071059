import React from 'react';
import styled from 'styled-components';

import { GenreLink } from '~/atomic/atom/links/dynamicLinks';
import { headerActions } from '~/atomic/organism/Header/header.slice';
import { getGenreSlugByName } from '~/feature/genre/genres';
import { useAppDispatch } from '~/store';

const SCGenreLabel = styled.div`
  font-family: var(--main-font);
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  position: relative;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: unset;
  }

  a {
    color: var(--black-color);
    &:hover {
      color: var(--primary-hover-color);
    }
  }
`;

const SCLabelGroup = styled.div`
  max-width: 250px;
`;

const SCMainGenreLabel = styled(SCGenreLabel)`
  font-size: 20px;
  line-height: 20px;
  margin-bottom: 16px;
  cursor: default;
`;

const SCChildGenreLabel = styled(SCGenreLabel)`
  line-height: 16px;
  margin-bottom: 0;

  a {
    line-height: 23px;
  }
`;

const GenreLabel: React.FC<{
  genres: string[];
}> = ({ genres }) => {
  const dispatch = useAppDispatch();
  const isMain = typeof genres[1] === 'undefined';

  const { length, [length - 1]: label } = genres;

  const GenreLabelComponent = isMain ? SCMainGenreLabel : SCChildGenreLabel;

  const onClick = () => {
    dispatch(headerActions.setIsOpenLibrary(false));
  };

  return (
    <GenreLabelComponent
      onClick={onClick}
    >
      <GenreLink
        slug={getGenreSlugByName(label)}
      >
        <a>{label}</a>
      </GenreLink>
    </GenreLabelComponent>
  );
};

export const HeaderLibraryGenres = () => {
  return (
    <SCHeaderLibraryGenres>
      <SCLibraryColumn>
        <SCLabelGroup>
          <GenreLabel genres={['Фантастика']} />
          <GenreLabel
            genres={['Фантастика', 'Боевая фантастика']}
          />
          <GenreLabel
            genres={['Фантастика', 'Героическая фантастика']}
          />
          <GenreLabel
            genres={['Фантастика', 'Научная фантастика']}
          />
          <GenreLabel
            genres={['Фантастика', 'Постапокалипсис']}
          />
          <GenreLabel
            genres={['Фантастика', 'Космическая фантастика']}
          />
          <GenreLabel
            genres={['Фантастика', 'Киберпанк']}
          />
          <GenreLabel
            genres={['Фантастика', 'Детективная фантастика']}
          />
          <GenreLabel
            genres={['Фантастика', 'Социальная фантастика']}
          />
          <GenreLabel
            genres={['Фантастика', 'Альтернативная история']}
          />
          <GenreLabel
            genres={['Фантастика', 'ЛитРПГ']}
          />
          <GenreLabel
            genres={['Фантастика', 'Боярь-аниме']}
          />
          <GenreLabel
            genres={['Фантастика', 'Стимпанк']}
          />
          <GenreLabel
            genres={['Фантастика', 'Юмористическая фантастика']}
          />
        </SCLabelGroup>
        <SCLabelGroup>
          <GenreLabel
            genres={['Детектив']}
          />
          <GenreLabel
            genres={['Детектив', 'Исторический детектив']}
          />
          <GenreLabel
            genres={['Детектив', 'Классический детектив']}
          />
          <GenreLabel
            genres={['Детектив', 'Юмористический детектив']}
          />
          <GenreLabel
            genres={['Детектив', 'Шпионский детектив']}
          />
          <GenreLabel
            genres={['Детектив', 'Политический детектив']}
          />
          <GenreLabel
            genres={['Детектив', 'Криминальный детектив']}
          />
        </SCLabelGroup>
      </SCLibraryColumn>
      <SCLibraryColumn>
        <SCLabelGroup>
          <GenreLabel
            genres={['Фэнтези']}
          />
          <GenreLabel
            genres={['Фэнтези', 'Боевое фэнтези']}
          />
          <GenreLabel
            genres={['Фэнтези', 'Бытовое фэнтези']}
          />
          <GenreLabel
            genres={['Фэнтези', 'Героическое фэнтези']}
          />
          <GenreLabel
            genres={['Фэнтези', 'Городское фэнтези']}
          />
          <GenreLabel
            genres={['Фэнтези', 'Историческое фэнтези']}
          />
          <GenreLabel
            genres={['Фэнтези', 'Тёмное фэнтези']}
          />
          <GenreLabel
            genres={['Фэнтези', 'Славянское фэнтези']}
          />
          <GenreLabel
            genres={['Фэнтези', 'Юмористическое фэнтези']}
          />
          <GenreLabel
            genres={['Фэнтези', 'Приключенческое фэнтези']}
          />
          <GenreLabel
            genres={['Фэнтези', 'Эпическое фэнтези']}
          />
          <GenreLabel
            genres={['Фэнтези', 'Магические академии']}
          />
        </SCLabelGroup>
        <SCLabelGroup>
          <GenreLabel
            genres={['Эротика']}
          />
          <GenreLabel
            genres={['Эротика', 'Романтическая эротика']}
          />
          <GenreLabel
            genres={['Эротика', 'Эротическое фэнтези']}
          />
          <GenreLabel
            genres={['Эротика', 'Эротическая фантастика']}
          />
          <GenreLabel
            genres={['Эротика', 'Эротический фанфик']}
          />
          <GenreLabel
            genres={['Эротика', 'Жёсткая эротика']}
          />
          <GenreLabel
            genres={['Эротика', 'С элементами эротики']}
          />
        </SCLabelGroup>
      </SCLibraryColumn>
      <SCLibraryColumn>
        <SCLabelGroup>
          <GenreLabel
            genres={['Любовный роман']}
          />
          <GenreLabel
            genres={['Любовный роман', 'Современный любовный роман']}
          />
          <GenreLabel
            genres={['Любовный роман', 'Любовная фантастика']}
          />
          <GenreLabel
            genres={['Любовный роман', 'Любовное фэнтези']}
          />
          <GenreLabel
            genres={['Любовный роман', 'Остросюжетный любовный роман']}
          />
          <GenreLabel
            genres={['Любовный роман', 'Исторический любовный роман']}
          />
          <GenreLabel
            genres={['Любовный роман', 'Короткий любовный роман']}
          />
        </SCLabelGroup>
        <SCLabelGroup>
          <GenreLabel
            genres={['Разное']}
          />
          <GenreLabel
            genres={['Разное', 'Бизнес-литература']}
          />
          <GenreLabel
            genres={['Разное', 'Биографии и мемуары']}
          />
          <GenreLabel
            genres={['Разное', 'Детская литература']}
          />
          <GenreLabel
            genres={['Разное', 'Развитие личности']}
          />
          <GenreLabel
            genres={['Разное', 'Профессиональная литература']}
          />
          <GenreLabel
            genres={['Разное', 'Учебная литература']}
          />
          <GenreLabel
            genres={['Разное', 'Хобби, досуг']}
          />
          <GenreLabel
            genres={['Разное', 'Психология']}
          />
        </SCLabelGroup>
      </SCLibraryColumn>
      <SCLibraryColumn>
        <SCLabelGroup>
          <GenreLabel
            genres={['Юмористический роман']}
          />
          <GenreLabel
            genres={['Современная проза']}
          />
          <GenreLabel
            genres={['Историческая проза']}
          />
          <GenreLabel
            genres={['Молодежная проза']}
          />
          <GenreLabel
            genres={['Попаданцы']}
          />
          <GenreLabel
            genres={['Боевик']}
          />
          <GenreLabel
            genres={['Стихи, поэзия']}
          />
          <GenreLabel
            genres={['Мистика']}
          />
          <GenreLabel
            genres={['Хоррор']}
          />
          <GenreLabel
            genres={['Триллер']}
          />
          <GenreLabel
            genres={['Сказка']}
          />
          <GenreLabel
            genres={['Фанфик']}
          />
          <GenreLabel
            genres={['Драма']}
          />
        </SCLabelGroup>
      </SCLibraryColumn>
    </SCHeaderLibraryGenres>
  );
};

const SCLibraryColumn = styled.div`
  display: flex;
  flex-direction: column;
  
  > *:not(:last-child) {
    margin-bottom: 30px;
  }
`;

const SCHeaderLibraryGenres = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 20px;
`;
