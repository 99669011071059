/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

import { SCSVGIcon } from '~/atomic/atom/icon/SCCIcon';

export const AudioPlayerNextIcon = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <SCSVGIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 20" {...props}>
      <path d="M13.3594 0.125H12.3281C12.1849 0.125 12.056 0.182292 11.9414 0.296875C11.8555 0.382812 11.8125 0.497396 11.8125 0.640625V8.50391L2.40234 0.425781C2.11589 0.196615 1.78646 0.0963542 1.41406 0.125C1.07031 0.153646 0.769531 0.296875 0.511719 0.554688C0.253906 0.8125 0.125 1.1276 0.125 1.5V18C0.125 18.3724 0.253906 18.6875 0.511719 18.9453C0.769531 19.2031 1.07031 19.3464 1.41406 19.375C1.78646 19.4036 2.11589 19.3034 2.40234 19.0742L11.8125 10.9531V18.8594C11.8125 19.0026 11.8555 19.1172 11.9414 19.2031C12.056 19.3177 12.1849 19.375 12.3281 19.375H13.3594C13.5026 19.375 13.6172 19.3177 13.7031 19.2031C13.8177 19.1172 13.875 19.0026 13.875 18.8594V0.640625C13.875 0.497396 13.8177 0.382812 13.7031 0.296875C13.6172 0.182292 13.5026 0.125 13.3594 0.125ZM2.1875 16.4961V2.96094L10.2227 9.70703L2.1875 16.4961Z" />
    </SCSVGIcon>
  );
};
