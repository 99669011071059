import React, { useState } from 'react';
import styled from 'styled-components';

import { BookReaderThemeEnum } from '~/atomic/page/book-reader/reader.slice';
import { useOutsideClick } from '~/lib/hooks/useOutsideClick';

export type PositionType = 'centerTop' | 'centerRight' | 'bottomRight' | 'bottomLeft' | 'leftTop';

export interface PopoverParams {
  isOpen?: boolean;
  onClose?: () => void;
  header: React.ReactNode;
  children: any;
  innerStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  headerStyle?: React.CSSProperties;
  position?: PositionType;
  trigger?: 'hover' | 'click';
  theme?: BookReaderThemeEnum;
}

export const Popover = ({
  children,
  isOpen,
  onClose,
  header,
  innerStyle,
  contentStyle,
  headerStyle,
  trigger = 'click',
  position = 'bottomRight',
  theme = BookReaderThemeEnum.light,
  ...props
} : React.HTMLAttributes<HTMLDivElement> & PopoverParams) => {
  const isHaveIsOpen = typeof isOpen !== 'undefined';

  const [localIsOpen, setLocalIsOpen] = useState(isOpen ?? false);

  const ref = useOutsideClick({
    callback: () => {
      if (onClose) onClose();
      if (!isHaveIsOpen && trigger === 'click') {
        setLocalIsOpen(false);
      }
    },
    isActive: isHaveIsOpen ? isOpen : localIsOpen,
  });

  const openPopover = () => {
    if (!isHaveIsOpen && trigger === 'click') {
      setLocalIsOpen((prevLocalIsOpenState) => !prevLocalIsOpenState);
    }
  };

  const hoverHandler = () => {
    if (trigger === 'hover' && !isHaveIsOpen) {
      setLocalIsOpen(true);
    }
  };

  const unhoverHandler = () => {
    if (trigger === 'hover' && !isHaveIsOpen) {
      setLocalIsOpen(false);
    }
  };

  return (
    <SCPopover
      ref={ref}
      data-open={String(isOpen ?? localIsOpen)}
      data-theme={theme}
      {...props}
    >
      <SCHeaderWrapper
        onClick={openPopover}
        onMouseEnter={hoverHandler}
        onMouseLeave={unhoverHandler}
        style={headerStyle}
      >
        {header}
      </SCHeaderWrapper>
      <SCContent
        style={contentStyle}
        data-position={position}
        onClick={(e) => e.stopPropagation()}
      >
        <SCInner style={innerStyle}>{children}</SCInner>
      </SCContent>
      <SCArrow />
    </SCPopover>
  );
};

const SCInner = styled.div`
  background: var(--bg-color);
  border-radius: 8px;
  box-shadow: 0 3px 5px rgba(53, 63, 72, 0.25);
  padding: 10px;

  > * {
    &:not(:last-child) {
      margin-bottom: 10px;
    }
  }
`;

const SCHeaderWrapper = styled.div`
  display: flex;
  width: max-content;
`;

const SCArrow = styled.div`
  position: absolute;
  z-index: 13;
  width: 20px;
  height: 0;
  overflow: hidden;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s;

  &::before {
    position: absolute;
    content: '';
    display: block;
    width:  20px;
    height: 20px;
    top: -15px;
    transform: rotate(45deg);
    background-color: var(--bg-color);
  }
`;

const SCContent = styled.div`
  position: absolute;
  z-index: 12;
  width: max-content;
  cursor: auto;
  opacity: 0;
  overflow: hidden;
  height: 0;
  pointer-events: none;
  transition: 0.3s;
  transition-property: opacity;

  &[data-position="centerTop"] {
    bottom: calc(100% + 16px);
    right: 50%;
    transform: translateX(50%);


    + ${SCArrow} {
      bottom: 100%;
      right: 50%;
      transform: translateX(50%);
    }
  }

  &[data-position="leftTop"] {
    bottom: calc(100% + 16px);
    left: 0;

    + ${SCArrow} {
      bottom: 100%;
      left: 16px;
    }
  }

  &[data-position="centerRight"] {
    bottom: calc(100% + 16px);
    right: -16px;

    + ${SCArrow} {
      bottom: 100%;
      right: 0;
    }
  }

  &[data-position="bottomRight"] {
    top: calc(100% + 16px);
    right: 0;

    + ${SCArrow} {
      top: 100%;
      right: 16px;
      transform: rotate(180deg);
    }
  }

  &[data-position="bottomLeft"] {
    top: calc(100% + 16px);
    left: 0;

    + ${SCArrow} {
      top: 100%;
      left: 16px;
      transform: rotate(180deg);
    }
  }
`;

const SCPopover = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;

  &[data-open="true"] {
    ${SCContent} {
      opacity: 1;
      overflow: visible;
      height: auto;
      pointer-events: auto;
    }

    ${SCArrow} {
      opacity: 1;
      pointer-events: auto;
      height: 20px;
    }
  }

  &[data-theme="dark"] {
    ${SCInner}, ${SCArrow}::before {
      background-color: var(--black-color);
    }
  }
`;
