/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

import { SCSVGIcon } from '~/atomic/atom/icon/SCCIcon';

export const AudioPlayerStopIcon = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <SCSVGIcon {...props} xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 129 129" enable-background="new 0 0 129 129">
      <g>
        <g>
          <path d="m64.5,122.6c32.1,0 58.1-26.1 58.1-58.1s-26-58.1-58.1-58.1-58.1,26-58.1,58.1 26,58.1 58.1,58.1zm0-108.1c27.5,0 50,22.4 50,50s-22.4,50-50,50-50-22.4-50-50 22.5-50 50-50z" />
          <path d="m53.8,94.7c2.3,0 4.1-1.8 4.1-4.1v-53.1c0-2.3-1.8-4.1-4.1-4.1-2.3,0-4.1,1.8-4.1,4.1v53.1c7.10543e-15,2.3 1.8,4.1 4.1,4.1z" />
          <path d="m75.2,94.7c2.3,0 4.1-1.8 4.1-4.1v-53.1c0-2.3-1.8-4.1-4.1-4.1-2.3,0-4.1,1.8-4.1,4.1v53.1c-1.42109e-14,2.3 1.8,4.1 4.1,4.1z" />
        </g>
      </g>
    </SCSVGIcon>
  );
};
