import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const paginationSliceName = 'pagination';
export const DEFAULT_PAGE_SIZE = 24;

const paginationSlice = createSlice({
  name: paginationSliceName,
  initialState: {
    perPage: DEFAULT_PAGE_SIZE,
    page: 1,
    total: 0,
  },
  reducers: {
    initPagination: (state, action: PayloadAction<{
      perPage: number;
      page: number,
      total: number;
    }>) => {
      return action.payload;
    },
    setPage: (state, action: PayloadAction<{ page: number }>) => {
      state.page = action.payload.page;
    },
    setPageSize: (state, action: PayloadAction<{ perPage: number }>) => {
      state.perPage = action.payload.perPage;
    },
    setTotal: (state, action: PayloadAction<{ total: number }>) => {
      state.total = action.payload.total;
    },
    resetPaging: (state) => {
      state.page = 1;
      state.perPage = DEFAULT_PAGE_SIZE;
    },
  },
});

export const { actions: paginationActions, reducer: paginationReducer } = paginationSlice;
