import {
  createSelector, createSlice, PayloadAction,
} from '@reduxjs/toolkit';

import { Author, BookIdType } from '~/api/book/bookApiTypes';
import { RootState } from '~/store';

export const audioPlayerSliceName = 'audioPlayer';

export interface AudioPlayerBook {
  id: BookIdType;
  name: string;
  slug: string;
  author: Author;
}

export interface AudioPlayerChapter {
  id: number;
  name: string;
  url: string;
  available: boolean;
  duration: number;
}

export const audioPlayerDefaultState = {
  isPlayed: false,
  isOpenPlayer: false,
  audioTime: 0,
  currentAudioTime: 0,
  isOpenChapterListModal: false,
  audioPath: '',
  isEndAudio: false,
  isChangeTrack: false,
  isSaveProgress: false,
  savedChapterProgress: 0,
  chapters: [] as AudioPlayerChapter[],
  book: {
    name: '',
    slug: '',
    author: {},
  } as AudioPlayerBook,
};

export const audioPlayerSlice = createSlice({
  name: audioPlayerSliceName,
  initialState: audioPlayerDefaultState,
  reducers: {
    setState: (state, action: PayloadAction<typeof audioPlayerDefaultState>) => {
      return action.payload;
    },
    setIsPlayed: (state, action: PayloadAction<boolean>) => {
      state.isPlayed = action.payload;
    },
    setIsOpenPlayer: (state, action: PayloadAction<boolean>) => {
      state.isOpenPlayer = action.payload;
    },
    setIsEndAudio: (state, action: PayloadAction<boolean>) => {
      state.isOpenPlayer = action.payload;
    },
    setIsOpenChapterListModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenChapterListModal = action.payload;
    },
    setIsChangeTrack: (state, action: PayloadAction<boolean>) => {
      state.isChangeTrack = action.payload;
    },
    changeAudioTime: (state, action: PayloadAction<number>) => {
      state.audioTime = action.payload;
    },
    changeCurrentAudioTime: (state, action: PayloadAction<number>) => {
      state.currentAudioTime = action.payload;
    },
    changeAudioPath: (state, action: PayloadAction<string>) => {
      state.audioPath = action.payload;
    },
    changeChapters: (state, action: PayloadAction<AudioPlayerChapter[]>) => {
      state.chapters = action.payload;
    },
    changeBook: (state, action: PayloadAction<AudioPlayerBook>) => {
      state.book = action.payload;
    },
    changeSavedChapterProgress: (state, action: PayloadAction<number>) => {
      state.savedChapterProgress = action.payload;
    },
    setIsSaveProgress: (state, action: PayloadAction<boolean>) => {
      state.isSaveProgress = action.payload;
    },
  },
});

export const audioPlayerSelector = (state: RootState) => state.audioPlayer;

export const audioPlayerActiveChapterSelector = createSelector(
  audioPlayerSelector,
  ({ audioPath, chapters }) => {
    return chapters.find((chapter) => chapter?.url === audioPath);
  },
);

export const prevChapterPlayerSelector = createSelector(
  audioPlayerSelector,
  audioPlayerActiveChapterSelector,
  ({ chapters }, activeChapter) => {
    let prevChapterIndex = 0;
    chapters.forEach((chapter, index) => {
      if (chapter?.id === activeChapter?.id) {
        prevChapterIndex = index - 1;
      }
    });

    return chapters[prevChapterIndex] ?? {} as AudioPlayerChapter;
  },
);

export const isAvailablePrevChapterPlayerSelector = createSelector(
  prevChapterPlayerSelector,
  (prevChapter) => prevChapter && !!prevChapter?.url,
);

export const nextChapterPlayerSelector = createSelector(
  audioPlayerSelector,
  audioPlayerActiveChapterSelector,
  ({ chapters }, activeChapter) => {
    let nextChapterIndex = 0;
    chapters.forEach((chapter, index) => {
      if (chapter?.id === activeChapter?.id) {
        nextChapterIndex = index + 1;
      }
    });

    return chapters[nextChapterIndex] ?? {} as AudioPlayerChapter;
  },
);

export const isAvailableNextChapterPlayerSelector = createSelector(
  nextChapterPlayerSelector,
  (nextChapter) => nextChapter && !!nextChapter?.url,
);

export const firstPublishAudioChapterSelector = createSelector(
  audioPlayerSelector,
  ({ chapters }) => {
    return chapters.find((chapter) => chapter?.url);
  },
);

export const audioPlayerBookAuthorSelector = createSelector(
  audioPlayerSelector,
  ({ book }) => book.author,
);

export const {
  reducer: audioPlayerReducer,
  actions: audioPlayerActions,
} = audioPlayerSlice;
