import debounce from 'lodash.debounce';
import { useRouter } from 'next/router';
import React, {
  FC, useCallback, useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';

import { Button, SCButton } from '~/atomic/atom/Button';
import { Dropdown } from '~/atomic/atom/Dropdown';
import { Input } from '~/atomic/atom/Input';
import { pagesConfigStore } from '~/atomic/atom/links/pagesConfigStore';
import { HideSearchButton } from '~/feature/search/HideSearchButton';
import { findUsersAndBooks } from '~/feature/search/search.data';
import { searchByPlaceSelector } from '~/feature/search/search.selector';
import {
  searchActions,
  SearchFieldPlaces,
} from '~/feature/search/search.slice';
import { useOutsideClick } from '~/lib/hooks/useOutsideClick';
import { useAppDispatch, useAppSelector } from '~/store';

import { SearchResult } from './SearchResult/SearchResult';

export const SearchField: FC<{ place: SearchFieldPlaces; autoFocus?:boolean }> = ({
  place,
  autoFocus,
}) => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const inputContainerRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [container, setContainer] = useState<HTMLDivElement | null>(null);

  const {
    books, authors, loading, keyword, isShowSearch,
  } = useAppSelector(searchByPlaceSelector(place));

  const searchEntry = (newKeyword: string) => {
    if (newKeyword.length > 0) {
      dispatch(findUsersAndBooks({
        place,
        data: {
          keyword: newKeyword, page: 1, perPage: 3,
        },
      }));
    }
  };

  const refRegister = useOutsideClick({
    callback: () => {
      dispatch(searchActions.hideSearch({ place }));
    },
    isActive: isShowSearch,
  });

  const debouncedSearch = useCallback(debounce(searchEntry, 300), []);

  const searchClickHandler = () => {
    if (keyword.length > 0) {
      const as = pagesConfigStore.searchBooks.as([], keyword);

      router.push(
        as,
        as,
      );
    }
  };

  useEffect(() => {
    debouncedSearch(keyword);
  }, [keyword]);

  const isShowResult = loading || (
    authors.length > 0 || books.length > 0
  );

  useEffect(() => {
    if (inputContainerRef?.current) {
      setContainer(inputContainerRef?.current);
      const input = inputContainerRef.current.querySelectorAll('input').item(0);
      if (input) {
        inputRef.current = input;
      }
    }
  }, [inputContainerRef]);

  useEffect(() => {
    if (isShowSearch && autoFocus && inputRef?.current) {
      inputRef?.current.focus();
    }
  }, [isShowSearch]);

  return (
    <SCSearchContainer
      id={`search-container-${place}`}
      ref={(ref) => {
        inputContainerRef.current = ref;
        refRegister(ref);
      }}
    >
      <Dropdown
        open={isShowResult}
        getPopupContainer={() => container}
        overlay={(
          <SearchResult
            width={inputContainerRef?.current?.offsetWidth ?? 0}
            place={place}
            ref={refRegister}
          />
        )}
        placement="bottomLeft"
      >
        <SCSearchInput>
          <Input
            autoFocus={autoFocus}
            value={keyword}
            onKeyUp={(event) => {
              if (event.key === 'Escape') {
                dispatch(searchActions.hideSearch({ place }));
              }
              if (event.key === 'Enter') {
                searchClickHandler();
              }
            }}
            onChange={(event) => {
              dispatch(searchActions.clearSearch({ place }));
              dispatch(searchActions.setKeyword({ keyword: event.target.value, place }));
            }}
            prefix={<HideSearchButton place={place} />}
            placeholder="Поиск по книге, циклу, автору, жанру или тегу"
          />
        </SCSearchInput>
      </Dropdown>

      <SCSearchButtonWrap>
        <SCSearchButton>
          <Button
            type="primary"
            block
            size="large"
            onClick={searchClickHandler}
          >
            Найти
          </Button>
        </SCSearchButton>
      </SCSearchButtonWrap>
    </SCSearchContainer>
  );
};

export const SCSearchContainer = styled.div`
  width: 100%;
  display: flex;
  margin-right: auto;
  margin-left: -11px;
  z-index: 100;
  position: relative;
  transform: translateY(1px);
`;

const SCSearchButtonWrap = styled.div`
  width: 103px;
`;

const SCSearchButton = styled.div`
  ${SCButton} {
    border-bottom-left-radius: 0 !important;
    border-top-left-radius: 0 !important;
  }
`;

const SCSearchInput = styled.div`
  width: 100%;
  
  .ant-input-affix-wrapper-focused, .ant-input-affix-wrapper:focus {
    //border: none;
    box-shadow: none;
    border-top-right-radius: 0 !important;
    border-bottom-right-radius: 0 !important;
  }

  .ant-input-prefix {
    margin-right: 16px !important;
  }

  .ant-input {
    height: 34px;
  }
`;
