import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const antiPirateSliceName = 'anti-piracy';

const antiPirateSlice = createSlice({
  name: antiPirateSliceName,
  initialState: {
    isIdentifyLoading: false,
    result: null as 'success' | 'fail' | null,
    errorMessage: '',
    notFoundError: '',
  },
  reducers: {
    setNotFoundError: (state, action: PayloadAction<string>) => {
      state.notFoundError = action.payload;
    },
    setErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    setIsIdentifyLoading: (state, action: PayloadAction<boolean>) => {
      state.isIdentifyLoading = action.payload;
    },
    setResult: (state, action: PayloadAction<'success' | 'fail'>) => {
      state.result = action.payload;
    },
  },
});

export const antiPirateActions = antiPirateSlice.actions;

export const antiPirateReducer = antiPirateSlice.reducer;
