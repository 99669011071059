import { UserRole } from '~/api/account/accountApiTypes';
import { CoverImage } from '~/api/book/bookApiTypes';
import { CommentStatusEnum } from '~/api/comment/commentApiTypes';
import { AvatarImagesFactory } from '~/graphql/books/factory/image/AvatarImagesFactory';
import { GetCommentsQuery } from '~/graphql/books/GraphQLGeneratedCode';

export interface GraphqlCommenter {
  id: number;
  name: string;
  username: string;
  avatarImages?: Array<CoverImage>;
  role: UserRole;
}

export interface GraphqlBookComment {
  id: number;
  createdAt: string;
  isReview: boolean;
  publicationStatus: CommentStatusEnum;
  text: string;
  updatedAt: string;
  commenter: GraphqlCommenter;
  childComments: Array<GraphqlChildComment>;
}

export interface GraphqlChildComment {
  text: string;
  updatedAt: string;
  publicationStatus: CommentStatusEnum;
  id: number;
  createdAt: string;
  parentId: number;
  commenter: GraphqlCommenter;
}

export class CommentFactory {
  static create(comment: GetCommentsQuery['getBookComments']['rootComments'][0]): GraphqlBookComment {
    return {
      id: comment.id,
      createdAt: comment.createdAt,
      isReview: comment.isReview,
      publicationStatus: comment.publicationStatus as CommentStatusEnum,
      text: comment.text,
      updatedAt: comment.updatedAt,
      commenter: {
        role: comment.commenter.role as UserRole,
        id: comment.commenter.id,
        name: comment.commenter.name,
        username: comment.commenter.username,
        avatarImages: AvatarImagesFactory.create(comment.commenter.avatarImages),
      },
      childComments: comment.childComments.map((childComment) => ({
        id: childComment.id,
        createdAt: childComment.createdAt,
        parentId: childComment.parentId,
        text: childComment.text,
        publicationStatus: childComment.publicationStatus as CommentStatusEnum,
        updatedAt: childComment.updatedAt,
        commenter: {
          role: childComment.commenter.role as UserRole,
          id: childComment.commenter.id,
          name: childComment.commenter.name,
          username: childComment.commenter.username,
          avatarImages: AvatarImagesFactory.create(childComment.commenter.avatarImages),
        },
      })),
    };
  }
}
