import { createSelector } from '@reduxjs/toolkit';

import { getGenreBySlug } from '~/feature/genre/genres';
import { RootState } from '~/store';

export const catalogFilterSelector = (state: RootState) => state.catalogFilter;

export const catalogFilterGenresSelector = createSelector(
  catalogFilterSelector,
  ({ mainGenreSlug, subGenresSlugs }) => {
    if (mainGenreSlug) return [mainGenreSlug, ...subGenresSlugs];

    return subGenresSlugs;
  },
);
export const catalogFilterGenresForApiSelector = createSelector(
  catalogFilterGenresSelector,
  (genres) => {
    if (genres.length === 0) return undefined;

    return genres.join(',').replace(' ', '');
  },
);

export const catalogFilterTagsSelector = createSelector(
  catalogFilterSelector,
  ({ tags }) => tags,
);

export const catalogFilterIsCanDownloadBooksSelector = createSelector(
  catalogFilterSelector,
  ({ isCanDownload }) => isCanDownload,
);

export const catalogFilterIsFreeBooksSelector = createSelector(
  catalogFilterSelector,
  ({ isOnlyFree }) => isOnlyFree,
);

export const catalogFilterIsExclusiveSelector = createSelector(
  catalogFilterSelector,
  ({ isExclusive }) => isExclusive,
);

export const catalogFilterWithAbonnementSelector = createSelector(
  catalogFilterSelector,
  ({ isWithAbonnement }) => isWithAbonnement,
);

export const catalogFilterIsHaveTagSelector = createSelector(
  catalogFilterSelector,
  ({ tagSlug }) => Boolean(tagSlug),
);

export const catalogFilterIsHaveGenreSelector = createSelector(
  catalogFilterSelector,
  ({ mainGenreSlug }) => Boolean(mainGenreSlug),
);

export const catalogFilterFirstGenreSelector = createSelector(
  catalogFilterSelector,
  ({ mainGenreSlug }) => {
    return getGenreBySlug(mainGenreSlug);
  },
);

export const catalogFilterTagSlugSelector = createSelector(
  catalogFilterSelector,
  ({ tagSlug }) => tagSlug,
);

export const catalogPageTagSelector = createSelector(
  catalogFilterTagSlugSelector,
  catalogFilterTagsSelector,
  (tagSlug, popularTags) => popularTags.find((tag) => tag.slug === tagSlug) ?? {
    slug: '',
    name: '',
    nameVin: '',
  },
);
