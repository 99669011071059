import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type AuthLoadingType = 'login' | 'refresh' | 'register' |
'logout' | 'reset' | 'forgot' | 'provider' | 'providerCallback' | 'confirm';

export type AuthLoading = {
  [key in AuthLoadingType]: boolean | undefined;
};

const authSlice = createSlice({
  name: 'auth',
  initialState: {
    message: '',
    loading: {} as AuthLoading,
    error: '',
    email: '',
    authPreviously: false,
  },
  reducers: {
    onLoadingStart: (state, action: PayloadAction<AuthLoadingType>) => {
      state.loading[action.payload] = true;
    },
    onLoadingEnd: (state, action: PayloadAction<AuthLoadingType>) => {
      delete state.loading[action.payload];
    },
    changeEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    changeMessage: (state, action: PayloadAction<string>) => {
      state.message = action.payload;
    },
    setAuthPreviously: (state, action: PayloadAction<boolean>) => {
      state.authPreviously = action.payload;
    },
  },
});

export const { actions: authActions, reducer: authReducer } = authSlice;
