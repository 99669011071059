import React from 'react';
import styled from 'styled-components';

import { headerSelector } from '~/atomic/organism/Header/header.selector';
import { headerActions } from '~/atomic/organism/Header/header.slice';
import { useAppDispatch, useAppSelector } from '~/store';

export const Hamburger = () => {
  const dispatch = useAppDispatch();
  const { isHamburgerActive } = useAppSelector(headerSelector);

  const handleTurnToggle = () => {
    dispatch(headerActions.toggleIsHamburgerActive());
  };

  return (
    <SCHamburger
      onClick={handleTurnToggle}
      type="button"
      data-is-active={String(isHamburgerActive)}
      aria-label="Кнопка открытия меню"
    >
      <SCHamburgerBox>
        <SCHamburgerInnerTop />
        <SCHamburgerInnerCenter />
        <SCHamburgerInnerBottom />
      </SCHamburgerBox>
    </SCHamburger>
  );
};

const SCHamburgerBox = styled.span`
  width: 20px;
  height: 22px;
  display: block;
  position: relative;
`;

const SCHamburgerInnerTop = styled.span`
  display: block;
  width: 24px;
  height: 2px;
  background-color: #000;
  border-radius: 1px;
  position: absolute;
  top: 0;
  transform: translate3d(0, 0, 0) rotate(0);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
`;

const SCHamburgerInnerCenter = styled.span`
  display: block;
  width: 24px;
  height: 2px;
  background-color: #000;
  border-radius: 1px;
  position: absolute;
  top: calc(50% - 1px);
  transform: rotateX(0) rotateY(0);
  transition: 
    background-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
    transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
`;

const SCHamburgerInnerBottom = styled.span`
  display: block;
  width: 24px;
  height: 2px;
  background-color: #000;
  border-radius: 1px;
  position: absolute;
  bottom: 0;
  transform: translate3d(0, 0, 0) rotate(0);
  transition: transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
`;

const SCHamburger = styled.button`
  padding: 15px 15px;
  margin-left: -15px;
  cursor: pointer;
  transition: 0.3s ease-in-out;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  overflow: visible;
  outline: none;
  z-index: 1000;
  
  &[data-is-active="true"] {
    ${SCHamburgerInnerTop} {
      transform: translate3d(0, 8px, 0) rotate(405deg);
    }
    ${SCHamburgerInnerCenter} {
      background-color: transparent !important;
      transform: rotateX(180deg) rotateY(180deg);
    }
    ${SCHamburgerInnerBottom} {
      bottom: 4px;
      transform: translate3d(0, -8px, 0) rotate(-405deg);
    }
  }
`;
