import React, { FC } from 'react';
import styled from 'styled-components';

import { SearchIcon } from '~/atomic/atom/icon/SearchIcon';
import { searchActions, SearchFieldPlaces } from '~/feature/search/search.slice';
import { useAppDispatch } from '~/store';

export const HideSearchButton: FC<{ place: SearchFieldPlaces }> = ({ place }) => {
  const dispatch = useAppDispatch();

  return (
    <SCSearchIcon
      onClick={() => {
        dispatch(searchActions.hideSearch({ place }));
      }}
    />
  );
};

const SCSearchIcon = styled(SearchIcon)`
  font-size: 20px;
  transition: 0.3s;
  cursor: pointer;
  color: var(--black-color);

  &:hover {
    color: var(--primary-hover-color);
  }
`;
