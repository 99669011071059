import React, {
  FC, ReactNode, useEffect,
} from 'react';

import { PrivatePageTemplate } from '~/atomic/organism/PageTemplate/PrivatePageTemplate';
import { PublicPageTemplate } from '~/atomic/organism/PageTemplate/PublicPageTemplate';
import { SEOPageTemplateProps } from '~/atomic/organism/PageTemplate/SEOPageTemplate';
import { providerCallback } from '~/feature/authorization/auth.data';
import { saveReferralLinkThunk } from '~/feature/referralLink/referralLink.data';
import { useAppDispatch } from '~/store';

interface PageTemplateProps {
  isPublic: boolean;
  seo: SEOPageTemplateProps;
  content: ReactNode;
  onLoadPage?: () => Promise<void>;
  onLoadPageDeps?: React.DependencyList;
  isShowGenerateLinkBadger?: boolean;
}

export const PageTemplate: FC<PageTemplateProps> = ({
  content,
  isPublic,
  seo,
  onLoadPage: onLoadPageFromProps,
  onLoadPageDeps = [],
  isShowGenerateLinkBadger = true,
}) => {
  const dispatch = useAppDispatch();

  const useLoadPage = async () => {
    await dispatch(providerCallback());
    if (onLoadPageFromProps) await onLoadPageFromProps();
    await dispatch(saveReferralLinkThunk());
  };

  useEffect(() => {
    useLoadPage();
  }, [...onLoadPageDeps]);

  if (!isPublic) {
    return (
      <PrivatePageTemplate
        content={content}
        seoProps={seo}
      />
    );
  }

  return (
    <PublicPageTemplate
      content={content}
      seoProps={seo}
      isShowGenerateLinkBadger={isShowGenerateLinkBadger}
    />
  );
};
