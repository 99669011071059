import { ChapterStatus } from '~/api/book/chapterApiTypes';
import { GraphqlEChapter } from '~/graphql/books/factory/book/EBookChapterFactoryTypes';
import { EbookChapter } from '~/graphql/books/GraphQLGeneratedCode';

export class EBookChapterFactory {
  static create(chapter: EbookChapter): GraphqlEChapter {
    return {
      bookId: chapter.bookId,
      available: chapter.available,
      chapterId: chapter.chapterId,
      createdAt: chapter.createdAt,
      name: chapter.name,
      free: chapter.free,
      status: String(chapter.status) as ChapterStatus,
      firstPublishedAt: chapter.firstPublishedAt,
      position: chapter.position,
      updatedAt: chapter.updatedAt,
      authorPages: chapter.authorPages,
      associatedAudioChapterId: chapter.associatedAudioChapterId,
      symbols: chapter.symbols,
      protected: chapter.protected,
      views: chapter.views,
      publicationScheduledFor: chapter.publicationScheduledFor,
    };
  }
}
