import React, { FC } from 'react';

import { PostThemeEnum } from '~/api/blog/blogTypes';
import { BookIdType } from '~/api/book/bookApiTypes';
import { CreateTagUrlProps } from '~/atomic/atom/links/createTagUrl';
import { CreateUrlWithFilterProps } from '~/atomic/atom/links/createUrlWithFilter';
import { pagesConfigStore } from '~/atomic/atom/links/pagesConfigStore';
import { TemplateDynamicLink } from '~/atomic/atom/links/TemplateDynamicLink';
import { DefaultLinkProps } from '~/atomic/atom/links/TemplateStaticLink';
import { PostPlaceEnum } from '~/pages/post/[slugPost]';

export const AuthorLink: FC<
DefaultLinkProps & { username?: string; }
> = ({
  username = '',
  children,
  ...restProps
}) => (
  <TemplateDynamicLink
    href={pagesConfigStore.author.url}
    as={pagesConfigStore.author.as(username)}
    {...restProps}
  >
    { children }
  </TemplateDynamicLink>
);

export const AuthorSubscribersLink: FC<
DefaultLinkProps & { username?: string; }
> = ({
  username = '',
  children,
  ...restProps
}) => (
  <TemplateDynamicLink
    href={pagesConfigStore.authorSubscribers.url}
    as={pagesConfigStore.authorSubscribers.as(username)}
    {...restProps}
  >
    { children }
  </TemplateDynamicLink>
);

export const UserLink: FC<
DefaultLinkProps & { username?: string; }
> = ({
  username = '',
  children,
  ...restProps
}) => (
  <TemplateDynamicLink
    href={pagesConfigStore.user.url}
    as={pagesConfigStore.user.as(username)}
    {...restProps}
  >
    { children }
  </TemplateDynamicLink>
);

export const BookLink: FC<
DefaultLinkProps & { slug?: string; }
> = ({
  slug = '',
  children,
  ...restProps
}) => {
  return (
    <TemplateDynamicLink
      href={pagesConfigStore.book.url}
      as={pagesConfigStore.book.as(slug)}
      {...restProps}
    >
      { children }
    </TemplateDynamicLink>
  );
};

export const ReaderChapterLink: FC<
DefaultLinkProps & { id: string | number; bookSlug: string; }
> = ({
  id = '',
  bookSlug = '',
  children, ...restProps
}) => (
  <TemplateDynamicLink
    href={pagesConfigStore.readerChapter.url}
    as={pagesConfigStore.readerChapter.as(bookSlug, id)}
    {...restProps}
  >
    { children }
  </TemplateDynamicLink>
);

export const CycleLink: FC<
DefaultLinkProps & { slug?: string; authorUsername?: string; }
> = ({
  slug = '',
  authorUsername = '',
  children,
  ...restProps
}) => {
  return (
    <TemplateDynamicLink
      href={pagesConfigStore.cycle.url}
      as={pagesConfigStore.cycle.as(authorUsername, slug)}
      {...restProps}
    >
      { children }
    </TemplateDynamicLink>
  );
};

export const GenreLink: FC<DefaultLinkProps & Omit<CreateUrlWithFilterProps, 'startWith'> & { slug?: string }> = ({
  children,
  bookType,
  completeStatus,
  genresSlugs,
  isCanDownload,
  isOnlyFree,
  page,
  perPage,
  sortingType,
  withAudiobook,
  tagSlug,
  slug,
  ...restProps
}) => {
  return (
    <TemplateDynamicLink
      href={pagesConfigStore.genre.url({
        genresSlugs,
        isOnlyFree,
        isCanDownload,
        tagSlug,
        genreSlug: slug,
        withAudiobook,
        sortingType,
        bookType,
        completeStatus,
        perPage,
        page,
      })}
      as={pagesConfigStore.genre.as({
        genresSlugs,
        isOnlyFree,
        isCanDownload,
        tagSlug,
        genreSlug: slug,
        withAudiobook,
      })}
      query={{
        sortingType,
        bookType,
        completeStatus,
        perPage,
        page,
      }}
      {...restProps}
    >
      { children }
    </TemplateDynamicLink>
  );
};

export const ReaderLink: FC<
DefaultLinkProps & { bookSlug: string; }
> = ({
  bookSlug = '',
  children,
  ...restProps
}) => {
  return (
    <TemplateDynamicLink
      href={pagesConfigStore.reader.url}
      as={pagesConfigStore.reader.as(bookSlug)}
      {...restProps}
    >
      { children }
    </TemplateDynamicLink>
  );
};

export const EditBookLink: FC<
DefaultLinkProps & { bookId?: BookIdType; }
> = ({
  bookId = '',
  children,
  ...restProps
}) => {
  return (
    <TemplateDynamicLink
      href={pagesConfigStore.bookEdit.url}
      as={pagesConfigStore.bookEdit.as(bookId)}
      {...restProps}
    >
      { children }
    </TemplateDynamicLink>
  );
};

export const TagLink: FC<
DefaultLinkProps & Omit<CreateTagUrlProps, 'tagSlug'> & { slug?: string }
> = ({
  bookType,
  completeStatus,
  genresSlugs,
  isOnlyFree,
  page,
  perPage,
  slug,
  children,
  ...restProps
}) => {
  return (
    <TemplateDynamicLink
      href={pagesConfigStore.tag.url({
        bookType,
        completeStatus,
        genresSlugs,
        isOnlyFree,
        page,
        perPage,
        tagSlug: slug,
      })}
      as={pagesConfigStore.tag.as({
        bookType,
        completeStatus,
        genresSlugs,
        isOnlyFree,
        page,
        perPage,
        tagSlug: slug,
      })}
      {...restProps}
    >
      { children }
    </TemplateDynamicLink>
  );
};

export const EditChapterLink: FC<
DefaultLinkProps & { bookId?: BookIdType; chapterId?: string | 'new'; }
> = ({
  bookId = '',
  chapterId = '',
  children,
  ...restProps
}) => {
  return (
    <TemplateDynamicLink
      href={pagesConfigStore.chapterEdit.url}
      as={pagesConfigStore.chapterEdit.as(bookId, chapterId)}
      {...restProps}
    >
      { children }
    </TemplateDynamicLink>
  );
};

export const PostEditLink: FC<
DefaultLinkProps & { postId: string | number; }
> = ({
  postId,
  children,
  ...restProps
}) => {
  return (
    <TemplateDynamicLink
      href={pagesConfigStore.postEdit.url}
      as={pagesConfigStore.postEdit.as(postId)}
      {...restProps}
    >
      {children}
    </TemplateDynamicLink>
  );
};

export const AuthorBlogLink: FC<
DefaultLinkProps & { username: string; }
> = ({
  username = '',
  children,
  ...restProps
}) => (
  <TemplateDynamicLink
    href={pagesConfigStore.authorBlog.url}
    as={pagesConfigStore.authorBlog.as(username)}
    {...restProps}
  >
    { children }
  </TemplateDynamicLink>
);

export const AuthorPostLink: FC<
DefaultLinkProps & { slugPost: string; place?: PostPlaceEnum }
> = ({
  slugPost = '',
  place = PostPlaceEnum.Author,
  children,
  ...restProps
}) => (
  <TemplateDynamicLink
    href={pagesConfigStore.post.url()}
    as={pagesConfigStore.post.as(slugPost)}
    query={{ place }}
    {...restProps}
  >
    { children }
  </TemplateDynamicLink>
);

export const PostLink: FC<
DefaultLinkProps & { slugPost: string; place?: PostPlaceEnum }
> = ({
  slugPost = '',
  place = PostPlaceEnum.Blog,
  children,
  ...restProps
}) => (
  <TemplateDynamicLink
    href={pagesConfigStore.post.url()}
    as={pagesConfigStore.post.as(slugPost)}
    query={{ place }}
    {...restProps}
  >
    { children }
  </TemplateDynamicLink>
);

export const CampaignLink: FC<
DefaultLinkProps & { id: string }
> = ({
  children,
  id = '',
  ...restProps
}) => {
  return (
    <TemplateDynamicLink
      href={pagesConfigStore.campaign.url}
      as={pagesConfigStore.campaign.as(id)}
      {...restProps}
    >
      { children }
    </TemplateDynamicLink>
  );
};

export const MyBooksLink: FC<
DefaultLinkProps & { status: string }
> = ({
  status = '',
  children,
  ...restProps
}) => {
  return (
    <TemplateDynamicLink
      href={pagesConfigStore.mybooks.url()}
      as={pagesConfigStore.mybooks.as({ })}
      query={{ status }}
      {...restProps}
    >
      {children}
    </TemplateDynamicLink>
  );
};

export const BlogsThemeLink: FC<
DefaultLinkProps & { theme: PostThemeEnum }
> = ({
  children,
  theme,
  ...restProps
}) => {
  return (
    <TemplateDynamicLink
      href={pagesConfigStore.blogsTheme.url}
      as={pagesConfigStore.blogsTheme.as(theme)}
      {...restProps}
    >
      {children}
    </TemplateDynamicLink>
  );
};

export const SearchBooksLink: FC<
DefaultLinkProps & { genres?: Array<string>, keyword?: string }
> = ({
  children,
  keyword = '',
  genres = [],
  ...restProps
}) => {
  return (
    <TemplateDynamicLink
      href={pagesConfigStore.searchBooks.url(keyword)}
      as={pagesConfigStore.searchBooks.as(genres)}
      query={{ keyword }}
      {...restProps}
    >
      {children}
    </TemplateDynamicLink>
  );
};

export const SearchAuthorsLink: FC<
DefaultLinkProps & { genres?: Array<string>, keyword?: string }
> = ({
  children,
  keyword = '',
  genres = [],
  ...restProps
}) => {
  return (
    <TemplateDynamicLink
      href={pagesConfigStore.searchAuthors.url(keyword)}
      as={pagesConfigStore.searchAuthors.as(genres)}
      query={{ keyword }}
      {...restProps}
    >
      {children}
    </TemplateDynamicLink>
  );
};
