import React, { FC, Fragment } from 'react';
import styled from 'styled-components';

import { NotificationLink } from '~/atomic/atom/links/staticLinks';
import { RegisterPopup, SCRegisterPopupContent } from '~/atomic/organism/Header/RegisterPopup';
import {
  SCHeaderNotificationIcon, SCIconWrapper, SCNotificationsCount,
} from '~/atomic/organism/Header/styled';
import { authSelector } from '~/feature/authorization/auth.selector';
import { isLoggedInSelector } from '~/feature/user/isLoggedInSelector';
import { userSelector } from '~/feature/user/user.selector';
import { useAppSelector } from '~/store';

export const NotificationsIcon: FC = () => {
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const { loading: userLoading, userCounters } = useAppSelector(userSelector);

  const { loading: authLoading } = useAppSelector(authSelector);
  const isShowLoader = userLoading || authLoading?.providerCallback;

  const Container = isLoggedIn || isShowLoader ? Fragment : RegisterPopup;

  const IconWrapper = isLoggedIn || isShowLoader ? NotificationLink : RegisterPopup;

  return (
    <SCNotifications
      data-is-logged-in={String(!isLoggedIn)}
    >
      <Container>
        <IconWrapper>
          <SCIconWrapper>
            {(userCounters?.notifications?.unread ?? 0) > 0 && (
              <SCNotificationsCount />
            )}
            <SCHeaderNotificationIcon />
          </SCIconWrapper>
        </IconWrapper>
      </Container>
    </SCNotifications>
  );
};

export const SCNotifications = styled.div`
  
  &[data-is-logged-in="true"]:hover {
    ${SCRegisterPopupContent} {
      opacity: 1;
      pointer-events: auto;
    }
  }
  
  ${SCNotificationsCount} {
    border: 3px solid var(--bg-color);
    scale: 0.85;
  }
`;
