import React from 'react';

import { openModal } from '~/feature/authorization/authorizationModal.slice';
import { SCAuthModal } from '~/feature/authorization/LoginModal/LoginModal';
import { RegisterForm } from '~/feature/authorization/RegisterModal/RegisterForm';
import { useAppDispatch, useAppSelector } from '~/store';

interface RegisterModalProps {
}

export const RegisterModal: React.FC<RegisterModalProps> = () => {
  const dispatch = useAppDispatch();
  const { openedModal } = useAppSelector(
    (state) => state.authorizationModal,
  );

  const closeModal = () => {
    dispatch(openModal(''));
  };

  const openLoginModal = () => {
    dispatch(openModal('login'));
  };

  return (
    <SCAuthModal
      onCancel={closeModal}
      open={openedModal === 'register'}
    >
      <RegisterForm
        openLoginModal={openLoginModal}
      />
    </SCAuthModal>
  );
};
