import React from 'react';
import styled from 'styled-components';

import { PostThemeEnum, PostThemeNamesEnum } from '~/api/blog/blogTypes';
import { pagesConfigStore } from '~/atomic/atom/links/pagesConfigStore';
import { PaperStyle } from '~/atomic/atom/PaperStyle';
import { Checkbox } from '~/atomic/molecula/Checkbox';
import { NavItem, NavMenu } from '~/atomic/organism/NavMenu';
import { TextRegStyle } from '~/atomic/Typography';
import { changeBlogsFilteroOlySubscribed } from '~/feature/blogs/BlogsFilterNav/blogsFilterNav.data';
import { blogsFilterNavSelector } from '~/feature/blogs/BlogsFilterNav/blogsFilterNav.selector';
import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import { isLoggedInSelector } from '~/feature/user/isLoggedInSelector';
import { lessThan } from '~/lib/mediaQuery';
import { useAppDispatch, useAppSelector } from '~/store';

import { BlogsFilterNavMobile } from './BlogsFilterNavMobile';

export const BlogsFilterNav = () => {
  const dispatch = useAppDispatch();
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const isMobileSize = useAppSelector(isMobileSizeSelector);
  const { onlySubscribed } = useAppSelector(blogsFilterNavSelector);

  return (
    <SCBlogsFilterNav>
      {!isMobileSize ? (
        <NavMenu>
          <NavItem key="all" href={pagesConfigStore.blogs.url}>
            Все посты
          </NavItem>
          {Object.keys(PostThemeEnum)
            .map((themeKey) => (
              <NavItem
                key={themeKey}
                as={pagesConfigStore.blogsTheme.as(PostThemeEnum[themeKey])}
                href={pagesConfigStore.blogsTheme.url}
              >
                {PostThemeNamesEnum[themeKey]}
              </NavItem>
            ))}
        </NavMenu>
      ) : null}
      {isMobileSize ? <BlogsFilterNavMobile /> : null}
      {isLoggedIn && (
        <SCBottom>
          <SCCheckbox
            onChange={async (e) => {
              await dispatch(changeBlogsFilteroOlySubscribed({ onlySubscribed: e.target.checked }));
            }}
            checked={onlySubscribed}
            title="Только авторы, на которых подписан"
          />
        </SCBottom>
      )}
    </SCBlogsFilterNav>
  );
};

const SCBlogsFilterNav = styled.div`
  ${PaperStyle};
  width: 282px;

  ${lessThan('md')} {
    width: 250px;
  }

  ${lessThan('sm')} {
    width: 100%;
  }
`;

const SCBottom = styled.div`
  padding: 20px 16px;
  border-top: 1px solid var(--gray3-color);

  ${lessThan('sm')} {
    padding: 10px 16px;
  }
`;

const SCCheckbox = styled(Checkbox)`
  margin-left: 0 !important;
  width: 100%;
  &:not(:last-child) {
    margin-bottom: 16px;
  }

  span {
    ${TextRegStyle};
    color: #000;
  }
`;
