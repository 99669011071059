import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const allowCookieSliceName = 'allow_cookie_bookriver';

const allowCookieSlice = createSlice({
  name: allowCookieSliceName,
  initialState: { isAllowCookie: false },
  reducers: {
    setIsAllowCookie: (state, action: PayloadAction<boolean>) => {
      state.isAllowCookie = action.payload;
    },
  },
});

export const { actions: allowCookieActions, reducer: allowCookieReducer } = allowCookieSlice;
