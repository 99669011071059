export type PopularTagType = {
  name: string;
  slug: string;
};

export const getPopularTagSlugByName = (name: string) => {
  return popularTags.find(
    (tag) => tag.name === name,
  )?.slug ?? '';
};

export const getPopularTagNameBySlug = ({ slug }:{ slug: string }) => {
  return popularTags.find(
    (tag) => tag.slug === slug,
  )?.name ?? '';
};

export const getPopularTagBySlug = (slug: string) => {
  return popularTags.find(
    (tag) => tag.slug === slug,
  );
};

export const popularTags: PopularTagType[] = [
  {
    name: 'Авторский мир',
    slug: 'avtorskii-mir',
  },
  {
    name: 'Антиутопия',
    slug: 'antiutopiya',
  },
  {
    name: 'Виртуальная реальность',
    slug: 'virtualnaya-realnost',
  },
  {
    name: 'Иные миры',
    slug: 'inye-miry',
  },
  {
    name: 'Магический мир',
    slug: 'magicheskii-mir',
  },
  {
    name: 'Параллельные миры',
    slug: 'parallelnye-miry',
  },
  {
    name: 'Реалрпг',
    slug: 'realrpg',
  },
  {
    name: 'Российская империя',
    slug: 'rossiiskaya-imperiya',
  },
  {
    name: 'Русреал',
    slug: 'rusreal',
  },
  {
    name: 'Современность',
    slug: 'sovremennost',
  },
  {
    name: 'Средневековье',
    slug: 'srednevekove',
  },
  {
    name: 'Стимпанк',
    slug: 'stimpank',
  },
  {
    name: 'Техномагия',
    slug: 'tekhnomagiya',
  },
  {
    name: 'Авторские расы',
    slug: 'avtorskie-rasy',
  },
  {
    name: 'Ангелы',
    slug: 'angely',
  },
  {
    name: 'Боги',
    slug: 'bogi',
  },
  {
    name: 'Вампиры',
    slug: 'vampiry',
  },
  {
    name: 'Ведьма',
    slug: 'vedma',
  },
  {
    name: 'Демоны',
    slug: 'demony',
  },
  {
    name: 'Драконы',
    slug: 'drakony',
  },
  {
    name: 'Инопланетяне',
    slug: 'inoplanetyane',
  },
  {
    name: 'Искусственный интеллект',
    slug: 'iskusstvennyi-intellekt',
  },
  {
    name: 'Мифические существа',
    slug: 'mificheskie-suschestva',
  },
  {
    name: 'Монстры',
    slug: 'monstry',
  },
  {
    name: 'Мутанты',
    slug: 'mutanty',
  },
  {
    name: 'Наги',
    slug: 'nagi',
  },
  {
    name: 'Некромант',
    slug: 'nekromant',
  },
  {
    name: 'Оборотни',
    slug: 'oborotni',
  },
  {
    name: 'Призраки',
    slug: 'prizraki',
  },
  {
    name: 'Эльфы',
    slug: 'elfy',
  },
  {
    name: 'Адекватные герои',
    slug: 'adekvatnye-geroi',
  },
  {
    name: 'Антигерой',
    slug: 'antigeroi',
  },
  {
    name: 'Беременная героиня',
    slug: 'beremennaya-geroinya',
  },
  {
    name: 'Близнецы',
    slug: 'bliznetsy',
  },
  {
    name: 'Богатый парень и простая девушка',
    slug: 'bogatyi-paren-i-prostaya-devushka',
  },
  {
    name: 'Бывшие',
    slug: 'byvshie',
  },
  {
    name: 'Властный герой',
    slug: 'vlastnyi-geroi',
  },
  {
    name: 'Дерзкая героиня',
    slug: 'derzkaya-geroinya',
  },
  {
    name: 'Жесткий герой',
    slug: 'zhestkii-geroi',
  },
  {
    name: 'Настоящий мужчина',
    slug: 'nastoyaschii-muzhchina',
  },
  {
    name: 'Невинная героиня',
    slug: 'nevinnaya-geroinya',
  },
  {
    name: 'Нежная героиня',
    slug: 'nezhnaya-geroinya',
  },
  {
    name: 'Неидеальные герои',
    slug: 'neidealnye-geroi',
  },
  {
    name: 'Неунывающая героиня',
    slug: 'neunyvayuschaya-geroinya',
  },
  {
    name: 'Отец одиночка',
    slug: 'otets-odinochka',
  },
  {
    name: 'Плохой парень',
    slug: 'plokhoi-paren',
  },
  {
    name: 'Подростки',
    slug: 'podrostki',
  },
  {
    name: 'Попаданец',
    slug: 'popadanets',
  },
  {
    name: 'Попаданка',
    slug: 'popadanka',
  },
  {
    name: 'Попаданцы в СССР',
    slug: 'popadantsy-v-sssr',
  },
  {
    name: 'Сводные брат и сестра',
    slug: 'svodnye-brat-i-sestra',
  },
  {
    name: 'Сильная героиня',
    slug: 'silnaya-geroinya',
  },
  {
    name: 'Сильный герой',
    slug: 'silnyi-geroi',
  },
  {
    name: 'Становление героя',
    slug: 'stanovlenie-geroya',
  },
  {
    name: 'Студенты',
    slug: 'studenty',
  },
  {
    name: 'Босс и подчинённая',
    slug: 'boss-i-podchinennaya',

  },
  {
    name: 'Встреча через время',
    slug: 'vstrecha-cherez-vremya',

  },
  {
    name: 'Вынужденный брак',
    slug: 'vynuzhdennyi-brak',

  },
  {
    name: 'Драма',
    slug: 'drama',

  },
  {
    name: 'Дружба',
    slug: 'druzhba',

  },
  {
    name: 'Жестокость и насилие',
    slug: 'zhestokost-i-nasilie',

  },
  {
    name: 'Запретная любовь',
    slug: 'zapretnaya-lyubov',

  },
  {
    name: 'Измена',
    slug: 'izmena',

  },
  {
    name: 'Интриги и страсть',
    slug: 'intrigi-i-strast',

  },
  {
    name: 'Истинная пара',
    slug: 'istinnaya-para',

  },
  {
    name: 'Любовный треугольник',
    slug: 'lyubovnyi-treugolnik',

  },
  {
    name: 'Любовь вопреки',
    slug: 'lyubov-vopreki',

  },
  {
    name: 'Любовь и месть',
    slug: 'lyubov-i-mest',

  },
  {
    name: 'Любовь и приключения',
    slug: 'lyubov-i-priklyucheniya',

  },
  {
    name: 'Любовь и страсть',
    slug: 'lyubov-i-strast',

  },
  {
    name: 'Любовь с первого взгляда',
    slug: 'lyubov-s-pervogo-vzglyada',

  },
  {
    name: 'МЖМ',
    slug: 'mzhm',

  },
  {
    name: 'Настоящая любовь',
    slug: 'nastoyaschaya-lyubov',

  },
  {
    name: 'Одержимость',
    slug: 'oderzhimost',

  },
  {
    name: 'От ненависти до любви',
    slug: 'ot-nenavisti-do-lyubvi',

  },
  {
    name: 'Очень откровенно',
    slug: 'ochen-otkrovenno',

  },
  {
    name: 'Первая любовь',
    slug: 'pervaya-lyubov',

  },
  {
    name: 'Предательство',
    slug: 'predatelstvo',

  },
  {
    name: 'Принуждение',
    slug: 'prinuzhdenie',

  },
  {
    name: 'Противостояние',
    slug: 'protivostoyanie',

  },
  {
    name: 'Противостояние героев',
    slug: 'protivostoyanie-geroev',

  },
  {
    name: 'Разница в возрасте',
    slug: 'raznitsa-v-vozraste',

  },
  {
    name: 'Романтика и любовь',
    slug: 'romantika-i-lyubov',

  },
  {
    name: 'Секс',
    slug: 'seks',

  },
  {
    name: 'Сильные чувства',
    slug: 'silnye-chuvstva',

  },
  {
    name: 'Сложные отношения',
    slug: 'slozhnye-otnosheniya',

  },
  {
    name: 'Служебный роман',
    slug: 'sluzhebnyi-roman',

  },
  {
    name: 'Страсть',
    slug: 'strast',

  },
  {
    name: 'Тайны прошлого',
    slug: 'tainy-proshlogo',

  },
  {
    name: 'Юмор',
    slug: 'yumor',

  },
  {
    name: 'Young adult',
    slug: 'young-adult',

  },
  {
    name: 'БДСМ',
    slug: 'bdsm',

  },
  {
    name: 'Боевые искусства',
    slug: 'boevye-iskusstva',

  },
  {
    name: 'Война',
    slug: 'voina',

  },
  {
    name: 'Выживание',
    slug: 'vyzhivanie',

  },
  {
    name: 'Гаремник',
    slug: 'garemnik',

  },
  {
    name: 'Жизнь',
    slug: 'zhizn',

  },
  {
    name: 'Интриги',
    slug: 'intrigi',

  },
  {
    name: 'Кланы',
    slug: 'klany',

  },
  {
    name: 'Космос',
    slug: 'kosmos',

  },
  {
    name: 'Криминал',
    slug: 'kriminal',

  },
  {
    name: 'Культивация',
    slug: 'kultivatsiya',

  },
  {
    name: 'Магия и волшебство',
    slug: 'magiya-i-volshebstvo',

  },
  {
    name: 'Мистика',
    slug: 'mistika',

  },
  {
    name: 'Отбор невест',
    slug: 'otbor-nevest',

  },
  {
    name: 'Повседневность',
    slug: 'povsednevnost',

  },
  {
    name: 'Приключения',
    slug: 'priklyucheniya',

  },
  {
    name: 'Прогрессорство',
    slug: 'progressorstvo',

  },
  {
    name: 'Путешествие',
    slug: 'puteshestvie',

  },
  {
    name: 'Путешествие во времени',
    slug: 'puteshestvie-vo-vremeni',

  },
  {
    name: 'Расследование',
    slug: 'rassledovanie',

  },
  {
    name: 'Сверхспособности',
    slug: 'sverkhsposobnosti',

  },
  {
    name: 'Смерть',
    slug: 'smert',

  },
  {
    name: 'Современность',
    slug: 'sovremennost',

  },
  {
    name: 'Спасение мира',
    slug: 'spasenie-mira',

  },
  {
    name: 'Хэппи энд',
    slug: 'kheppi-end',

  },
  {
    name: 'Чёрный юмор',
    slug: 'chernyi-yumor',

  },
];
