import { useRouter } from 'next/router';
import React from 'react';
import styled from 'styled-components';

import { PostThemeEnum, PostThemeNamesEnum } from '~/api/blog/blogTypes';
import { Dropdown } from '~/atomic/atom/Dropdown';
import { ArrowIcon } from '~/atomic/atom/icon/ArrowIcon';
import { BlogsThemeLink } from '~/atomic/atom/links/dynamicLinks';
import { BlogsLink } from '~/atomic/atom/links/staticLinks';
import { Menu, MenuItem } from '~/atomic/atom/Menu';
import { TextRegStyle } from '~/atomic/Typography';
import { lessThan } from '~/lib/mediaQuery';

export const BlogsFilterNavMobile = () => {
  const router = useRouter();
  const theme = router.query?.theme as PostThemeEnum;

  const menu = (
    <Menu>
      <MenuItem>
        <BlogsLink>
          <SCNavItem
            data-is-active={String(theme === undefined)}
          >
            Все посты
          </SCNavItem>
        </BlogsLink>
      </MenuItem>
      {Object.keys(PostThemeEnum).map((themeKey) => (
        <MenuItem
          key={themeKey}
        >
          <BlogsThemeLink
            theme={PostThemeEnum[themeKey]}
          >
            <SCNavItem
              data-is-active={String(theme === PostThemeEnum[themeKey])}
            >
              {PostThemeNamesEnum[themeKey]}
            </SCNavItem>
          </BlogsThemeLink>
        </MenuItem>
      ))}
    </Menu>
  );

  return (
    <SCBlogsFilterNavMobile
      trigger={['click']}
      overlay={menu}
    >
      <SCWrapper>
        <SCNavActiveItem
          data-is-active={String(theme === undefined)}
        >
          <SCNavItem
            data-is-active={String(theme === undefined)}
          >
            Все посты
          </SCNavItem>
        </SCNavActiveItem>
        {Object.keys(PostThemeEnum).map((themeKey) => (
          <SCNavActiveItem
            key={themeKey}
            data-is-active={String((theme) === PostThemeEnum[themeKey])}
          >
            <SCNavItem
              data-is-active={String(theme === PostThemeEnum[themeKey])}
            >
              {PostThemeNamesEnum[themeKey]}
              <SCArrowIcon />
            </SCNavItem>
          </SCNavActiveItem>
        ))}
      </SCWrapper>
    </SCBlogsFilterNavMobile>
  );
};

const SCBlogsFilterNavMobile = styled(Dropdown)`
  display: none;

  ${lessThan('sm')} {
    display: block;
  }
`;

const SCNavItem = styled.a`
  ${TextRegStyle};
  position: relative;
  color: var(--black-color) !important;
  padding: 0 !important;
  margin: 0 !important;
  display: flex;

  &:hover {
    color: var(--primary-hover-color);
  }
  
  &[data-is-active="true"] {
    color: var(--primary-color) !important;
  }
`;

const SCNavActiveItem = styled.div`
  display: none;

  &[data-is-active='true'] {
    display: block;
  }
`;

const SCWrapper = styled.div`
  padding: 10px 16px;
`;

const SCArrowIcon = styled(ArrowIcon)`
  content: "";
  font-size: 12px;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`;
