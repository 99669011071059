import type { PaginationProps as AntdPaginationProps } from 'antd/lib/pagination';
import dynamic from 'next/dynamic';
import React, { FC } from 'react';

import { changePage, changePageSize } from '~/feature/pagination/pagination.data';
import { paginationSelector } from '~/feature/pagination/pagination.selector';
import { useAppDispatch, useAppSelector } from '~/store';

import { DEFAULT_PAGE_SIZE } from './pagination.slice';

interface PaginationProps {
  onChange(page: number, pageSize?: number): void;
  saveInUrl?: boolean;
}

export const AntdPagination = dynamic(
  () => import('antd/lib/pagination'),
  { ssr: false },
);

export const Pagination: FC<PaginationProps & AntdPaginationProps> = ({
  onChange, saveInUrl = true, ...props
}) => {
  const dispatch = useAppDispatch();
  const pagination = useAppSelector(paginationSelector);

  const onChangeHandler = (page: number, pageSize: number) => {
    if (page !== pagination?.page) {
      dispatch(changePage({ page, saveInUrl }));
    }
    if (pageSize !== pagination.perPage) {
      dispatch(changePageSize({ perPage: pageSize, saveInUrl }));
    }

    if (onChange) { onChange(page, pageSize); }
  };

  if (!((pagination.total ?? 0) > pagination.perPage)) return null;

  return (
    <AntdPagination
      pageSize={pagination.perPage ?? DEFAULT_PAGE_SIZE}
      current={pagination.page ?? 1}
      onChange={onChangeHandler}
      showSizeChanger={false}
      total={pagination.total ?? 0}
      {...props}
    />
  );
};
