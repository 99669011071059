/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

import { SCSVGIcon } from '~/atomic/atom/icon/SCCIcon';

export const AudioPlayerPrevIcon = (props: SVGAttributes<SVGSVGElement>) => {
  return (
    <SCSVGIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 14 20" {...props}>
      <path d="M0.640625 19.375H1.67188C1.8151 19.375 1.92969 19.3177 2.01562 19.2031C2.13021 19.1172 2.1875 19.0026 2.1875 18.8594V10.9961L11.6406 19.0742C11.8984 19.3034 12.1992 19.4036 12.543 19.375C12.9154 19.3464 13.2305 19.2031 13.4883 18.9453C13.7461 18.6875 13.875 18.3724 13.875 18V1.5C13.875 1.1276 13.7461 0.8125 13.4883 0.554688C13.2305 0.296875 12.9154 0.153646 12.543 0.125C12.1992 0.0963542 11.8984 0.196615 11.6406 0.425781L2.1875 8.54688V0.640625C2.1875 0.497396 2.13021 0.382812 2.01562 0.296875C1.92969 0.182292 1.8151 0.125 1.67188 0.125H0.640625C0.497396 0.125 0.36849 0.182292 0.253906 0.296875C0.167969 0.382812 0.125 0.497396 0.125 0.640625V18.8594C0.125 19.0026 0.167969 19.1172 0.253906 19.2031C0.36849 19.3177 0.497396 19.375 0.640625 19.375ZM11.8125 2.96094V16.5391L3.77734 9.75L11.8125 2.96094Z" />
    </SCSVGIcon>
  );
};
