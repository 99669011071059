import React from 'react';
import styled from 'styled-components';

import { FilledStarIcon } from '~/atomic/atom/icon/FilledStarIcon';
import { TextTagStyle } from '~/atomic/Typography';

export const ReviewTag = () => {
  return (
    <SCReviewTag>
      <SCFilledStarIcon />
      Отзыв
    </SCReviewTag>
  );
};

const SCReviewTag = styled.span`
  ${TextTagStyle};
  color: var(--black-color);
  letter-spacing: 0.05em;
  text-transform: uppercase;
  border-radius: 10px;
  background-color: #FFEDB8;
  display: inline-flex;
  padding: 4px 6px;
`;

const SCFilledStarIcon = styled(FilledStarIcon)`
  color: #FBCA4B;
  font-size: 10px;
  margin-right: 4px;
`;
