import { createSelector } from '@reduxjs/toolkit';

import { FeedTabEnum, GetFeedGenreParam } from '~/api/feed/feedApiTypes';
import { catalogFilterSelector } from '~/feature/catalog/filter/catalogFilter.selector';
import { isTabletSizeSelector } from '~/feature/media/windowSize.selector';
import { RootState } from '~/store';

export const feedSelector = (state: RootState) => state.mainPage;

export const feedGenresSelector = createSelector(
  feedSelector,
  ({ genres }) => genres,
);

export const feedSlidersSelector = createSelector(
  feedSelector,
  ({ sliders }) => sliders,
);

export const feedBestsellersBooksSelector = createSelector(
  feedSelector,
  isTabletSizeSelector,
  ({ sliders, loadMorePage }, isTabletSize) => {
    const showBooks = isTabletSize ? 6 : 8;

    return sliders
      .find((slider) => slider.keyword.type === 'bestseller')
      ?.books
      .filter((b, index) => index < (showBooks * loadMorePage));
  },
);

export const feedExclusiveBooksSelector = createSelector(
  feedSlidersSelector,
  (sliders) => {
    return sliders.find((slider) => slider.keyword.type === 'hot_and_exclusive')?.books;
  },
);

export const feedRecommendedBooksSelector = createSelector(
  feedSlidersSelector,
  (sliders) => {
    return sliders.find((slider) => slider.keyword.type === 'recommended')?.books;
  },
);

export const feedLatestBooksSelector = createSelector(
  feedSlidersSelector,
  (sliders) => {
    return sliders.find((slider) => slider.keyword.type === 'latest')?.books;
  },
);

export const feedActiveGenreSlugsSelector = createSelector(
  feedGenresSelector,
  (genres) => {
    return genres
      .filter((genre) => genre.isActive)
      .map((genre) => genre.slug);
  },
);

export const feedActiveGenresCountSelector = createSelector(
  feedGenresSelector,
  (genres) => {
    let count = 0;
    genres.forEach((genre) => {
      if (genre.isActive) {
        count += 1;
      }
    });
    return count;
  },
);

export const feedActiveGenresTagsSelector = createSelector(
  feedSelector,
  (feed) => {
    return feed.genresTags
      .reduce((array, genreTags) => {
        let name = '';
        let isActive = false;

        feed.genres.forEach((genre) => {
          if (genre.slug === genreTags.slug) {
            if (genre.isActive) {
              isActive = true;
            }
            name = genre.name;
          }
        });

        if (isActive) {
          return [
            ...array,
            {
              ...genreTags,
              tags: genreTags.tags.slice(0, 8),
              name,
            }];
        }
        return array;
      }, []);
  },
);

export const genresCheckboxForApiSelector = createSelector(
  feedGenresSelector,
  (genres): Array<GetFeedGenreParam> => {
    return genres.map((genre) => ({
      slug: genre.slug,
      checked: genre.isActive,
      subgenres: genre.childs.map((child) => ({
        slug: child.slug,
        checked: child.isActive,
      })),
    }));
  },
);

export const isAllFavoriteGenresIsActiveSelector = createSelector(
  feedGenresSelector,
  (genres) => genres.every((genre) => genre.isActive && genre.isAllSubgenresActive),
);

export const isShowFeedFavoriteGenresSelector = createSelector(
  catalogFilterSelector,
  feedSelector,
  ({ mainGenreSlug }, { feedTab }) => (feedTab !== FeedTabEnum.Abonnement ? true : !mainGenreSlug),
);
