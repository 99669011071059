import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Chapter, ChapterTitle } from '~/api/book/chapterApiTypes';
import { GraphqlChapters } from '~/graphql/books/factory/book/ChaptersFactoryTypes';
import { GraphqlEChapter } from '~/graphql/books/factory/book/EBookChapterFactoryTypes';
import { GraphqlEChapterWithContent } from '~/graphql/books/factory/book/EChapterWithContentFactoryTypes';

const initState = {
  message: '',
  loading: false,
  isDeleteChapterLoading: false,
  isGetPublishChaptersLoading: false,
  isCurrentChapterLoading: false,
  isLoadAudioChapterLoading: false,
  isDeleteAudioChapterLoading: false,
  bookChapters: [] as ChapterTitle[],
  currentEditChapter: {} as Chapter,
  currentChapter: {} as GraphqlEChapterWithContent,
  bookPageChapters: [] as GraphqlEChapter[],
  graphqlChapters: {} as GraphqlChapters,
};

const chapterSlice = createSlice({
  name: 'chapter',
  initialState: initState,
  reducers: {
    changeChapter: (state, action: PayloadAction<ChapterTitle>) => {
      state.bookChapters = state.bookChapters.map((chapter) => {
        if (chapter.id === action.payload.id) {
          return action.payload;
        }
        return chapter;
      });
    },
    changeCurrentChapter: (state, action: PayloadAction<Partial<GraphqlEChapterWithContent>>) => {
      state.currentChapter = { ...state.currentChapter, ...action.payload };
    },
    changeCurrentEditChapter: (state, action: PayloadAction<Partial<Chapter>>) => {
      state.currentEditChapter = { ...state.currentEditChapter, ...action.payload };
    },
    setPublishChaptersLoading: (state, action: PayloadAction<boolean>) => {
      state.isGetPublishChaptersLoading = action.payload;
    },
    setIsLoadAudioChapterLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoadAudioChapterLoading = action.payload;
    },
    setIsDeleteAudioChapterLoading: (state, action: PayloadAction<boolean>) => {
      state.isDeleteAudioChapterLoading = action.payload;
    },
    changeIsGetPublishChaptersLoading: (state, action: PayloadAction<boolean>) => {
      state.isGetPublishChaptersLoading = action.payload;
    },
    changeIsCurrentChapterLoading: (state, action: PayloadAction<boolean>) => {
      state.isCurrentChapterLoading = action.payload;
    },
    setChapters: (state, action: PayloadAction<ChapterTitle[]>) => {
      state.bookChapters = action.payload;
    },
    changeGraphqlChapters: (state, action: PayloadAction<GraphqlChapters>) => {
      state.graphqlChapters = action.payload;
    },
    deleteChapterFromBookChapters: (state, action: PayloadAction<string>) => {
      state.bookChapters = state.bookChapters.filter((chapter) => chapter.id !== action.payload);
    },
    changeCurrentEditChapterProtected: (state, action: PayloadAction<boolean>) => {
      state.currentEditChapter.protected = action.payload;
    },
  },
});

export const { actions: chapterActions, reducer: chapterReducer } = chapterSlice;
