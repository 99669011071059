import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '~/store';

export const blogSelector = (state: RootState) => state.blog;

export const postContentForSeoSelector = createSelector(
  blogSelector,
  ({ post }) => {
    return `${post.title} на литературно-издательской площадке Bookriver`
      + ' ⭐ Серии книг ⭐ Библиотека новых романов'
      + ' ⭐ Комментарии и отзывы читателей'
      + ' ⭐ Скачать полностью произведения в формате fb2 ✉'
      + ' Найти интересную книгу на нашем сайте и читать онлайн бесплатно';
  },
);
