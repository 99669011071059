import { useQuery } from 'react-query';

import { blogApi } from '~/api/blog/blog';
import { Post } from '~/api/blog/blogTypes';

export const useGetAuthorPostsWidget = (username: string) => {
  const initialData = [] as Post[];

  const result = useQuery(
    ['AuthorPostsWidget', username],
    async () => {
      const response = await blogApi.getAuthorPosts({
        perPage: 3, page: 1, username,
      });
      return response.data;
    },
    { placeholderData: initialData },
  );

  return { ...result, isLoading: result.isPlaceholderData };
};
