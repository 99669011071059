import { ProviderEnum } from '~/api/auth/authApiTypes';
import { MiniatureFormatEnum, MiniatureSizesEnum } from '~/api/book/bookApiTypes';
import { AdAccount } from '~/api/finance/marketing/adAccountApiTypes';
import { AbonnementTypeEnum } from '~/graphql/books/factory/abonement/AbonnementFactoryTypes';

export enum GenderEnum {
  Male = 'male',
  Female = 'female',
}

export const isMale = (gender: GenderEnum): boolean => {
  return gender === GenderEnum.Male;
};

export enum UserRole {
  guest = 'guest',
  reader = 'reader',
  author = 'author',
  admin = 'admin',
}

export enum CommercialStatusEnum {
  none = 'none',
  accepted = 'accepted',
  rejected = 'rejected',
  pending = 'pending',
}

export enum LegalStatusEnum {
  None = 'none',
  Individual = 'individual',
  SelfEmployed = 'self-employee',
  IndividualEntrepreneur = 'individual-entrepreneur',
}

export enum LegalStatusNameEnum {
  None = 'Физ лицо',
  Individual = 'Физ лицо',
  SelfEmployed = 'Самозанятый',
  IndividualEntrepreneur = 'Индивидуальный предприниматель',
}

export interface AvatarImage {
  url: string;
  format: MiniatureFormatEnum;
  sizeType: MiniatureSizesEnum;
}

export enum UserAbonnementStatusEnum {
  Active = 'active',
  DateExpired = 'date_expired',
  CharactersLimitReached = 'characters_limit_reached',
}

export interface User {
  id: string;
  name?: string;
  username?: string;
  email?: string;
  birthday?: string;
  aboutMe?: string;
  status?: string;
  gender?: GenderEnum;
  photoUrl?: string;
  avatarImages?: AvatarImage[];
  confirmed?: boolean;
  emailVerified?: boolean;
  banned?: boolean;
  createdAt: string;
  attachedSocialAccounts: string[];
  role?: UserRole;
  isTargetologist: boolean;
  phone: string;
  phoneConfirmed: boolean;
  commercialStatus?: CommercialStatusEnum;
  legalStatus: LegalStatusEnum;
  visitedAt?: string;
  country?: string;
  imported?: boolean;
  counters: Partial<Counters>,
  socialPasswordNotSet?: boolean;
  balance: number;
  heroImage?: string;
  uuid?: string;
  discount?: {
    grade?: number;
    percent?: number;
  };
  password?: string;
  yandexMetrika?: string;
  abonnement?: {
    type: AbonnementTypeEnum;
    characterConsumptionAsPercent: number;
    shouldExpireAt: string;
    status: UserAbonnementStatusEnum;

  }
  author?: {
    hasBooksAddedToAbonnement: boolean;
    adAccount?: AdAccount;
  }
}

export interface PasswordRequestParams {
  currentPassword: string;
  newPassword: string;
}

export interface SetSocialPasswordParams {
  password: string;
}

export interface EmailRequestParams {
  currentPassword?: string;
  email: string;
}

export interface EmailConfirmationParams {
  confirmationToken: string;
}

export interface ChangeMeRequestParams {
  name: string;
  birthday: string;
  aboutMe: string;
  status: string;
  username: string;
  gender?: GenderEnum;
}

export interface SetAvatarRequestParams {
  file: File;
}

export interface SetAvatarResponse {
  imageUrl: string;
}

export interface GetSocialAccountParams {
  provider: ProviderEnum;
}

export interface GetSocialAccountRequest {
  redirectUrl: string;
}

export interface DeleteSocialAccountParams {
  provider: ProviderEnum;
}

export interface UpdateSocialAccountParams {
  provider: ProviderEnum;
  code: string;
}

export interface Counters {
  subscriptions?: number;
  subscribers?: number;
  books: number;
  purchases: number;
  posts: number;
  notifications: {
    unread: number;
  },
  mybooks: {
    readWithoutReactions: number;
    read: number;
    readNow: number;
    readLater: number;
    viewed: number;
    percent: number;
    reactions: {
      all: number;
      bad: number;
      soSo: number;
      willDO: number;
      well: number;
      great: number;
    };
  }
}
