import React, { FC } from 'react';
import styled from 'styled-components';

import { Book } from '~/api/book/bookApiTypes';
import { AuthorLink, BookLink } from '~/atomic/atom/links/dynamicLinks';
import { TextBoldStyle, TextSmallStyle } from '~/atomic/Typography';
import { BookPreviewCover } from '~/feature/search/SearchResult/BookPreviewCover';

export const BookPreview: FC<{ book: Partial<Book> }> = ({ book }) => {
  const author = book.authors?.[0];

  return (
    <SCBookPreview>
      <BookLink slug={book.slug}>
        <SCCoverLink>
          <BookPreviewCover
            coverImages={book?.coverImages}
            book={{
              author: author?.name ?? 'Имя автора',
              title: book?.name ?? 'Название книги',
            }}
          />
        </SCCoverLink>
      </BookLink>
      <SCBookInfo>
        <SCBookHeader>
          <BookLink slug={book.slug}>
            <a>
              <SCBookTitle title={book?.name ?? 'Название книги'}>
                { book?.name ?? 'Название книги' }
              </SCBookTitle>
            </a>
          </BookLink>
        </SCBookHeader>
        <AuthorLink username={author?.username}>
          <SCBookAuthor title={author?.name ?? 'Имя автора'}>
            {author?.name ?? 'Имя автора'}
          </SCBookAuthor>
        </AuthorLink>
      </SCBookInfo>
    </SCBookPreview>
  );
};

const SCCoverLink = styled.a`
  min-width: 42px;
`;

export const SCBookPreview = styled.div`
  display: flex;
  flex-direction: row;
  padding: 8px;
  transition: 0.3s;

  &:hover {
    background-color: var(--bg-color);
  }
`;

const SCBookInfo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-left: 16px;
`;

const SCBookTitle = styled.span`
  ${TextBoldStyle};
  
  color: var(--black-color);
  transition: 0.3s;
  margin-right: 14px;

  &:hover {
    color: var(--primary-hover-color);
  }

  display: block; /* Fallback for non-webkit */
  display: -webkit-box;
  max-width: 100%;
  height: 15 * 20 * 2; /* Fallback for non-webkit */
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const SCBookAuthor = styled.a`
  ${TextSmallStyle};
  overflow: hidden;
  text-overflow: ellipsis;
  transition: 0.3s;
  color: var(--gray-color);

  &:hover {
    color: var(--primary-hover-color);
  }
`;

const SCBookHeader = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 2px;
  flex-wrap: wrap;
`;
