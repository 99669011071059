import translitEngine from 'translitit-engine';

const transliterationTable = {
  А: 'a',
  а: 'a',
  Б: 'b',
  б: 'b',
  В: 'v',
  в: 'v',
  Г: 'g',
  г: 'g',
  Д: 'd',
  д: 'd',
  Е: 'e',
  е: 'e',
  Ё: 'yo',
  ё: 'yo',
  Ж: 'zh',
  ж: 'zh',
  З: 'z',
  з: 'z',
  И: 'i',
  и: 'i',
  Й: 'i',
  й: 'i',
  К: 'k',
  к: 'k',
  Л: 'l',
  л: 'l',
  М: 'm',
  м: 'm',
  Н: 'n',
  н: 'n',
  О: 'o',
  о: 'o',
  П: 'p',
  п: 'p',
  Р: 'r',
  р: 'r',
  С: 's',
  с: 's',
  Т: 't',
  т: 't',
  У: 'u',
  у: 'u',
  Ф: 'f',
  ф: 'f',
  Х: 'kh',
  х: 'kh',
  Ц: 'ts',
  ц: 'ts',
  Ч: 'ch',
  ч: 'ch',
  Ш: 'sh',
  ш: 'sh',
  Щ: 'shc',
  щ: 'shc',
  Ы: 'y',
  ы: 'y',
  Э: 'e',
  э: 'e',
  Ю: 'yu',
  ю: 'yu',
  Я: 'ya',
  я: 'ya',
  Ь: '',
  ь: '',
  Ъ: '',
  ый: 'yi',
  ъе: 'ye',
  '\\': 'i',
  '/': '',
  ' ': '-',
  ', ': '-',
  '(': '',
  ')': '',
};

export const transliterate = translitEngine(transliterationTable);
