import Head from 'next/head';
import React, { FC, ReactNode } from 'react';

export interface SEOPageTemplateProps {
  type?: string;
  title: string;
  image?: string;
  canonical: string;
  description?: string;
  customSeoTags?: ReactNode;
}

export const SEOPageTemplate: FC<SEOPageTemplateProps> = ({
  canonical,
  customSeoTags,
  description,
  image,
  title,
  type,
}) => {
  return (
    <Head>
      <title>
        {title}
      </title>
      <meta
        name="title"
        content={title}
      />
      <meta
        property="og:title"
        content={title}
      />
      <meta
        name="description"
        content={description}
      />
      <meta
        property="og:description"
        content={description}
      />
      <meta
        property="og:image"
        content={image ?? `${process.env.NEXT_PUBLIC_BASE_FRONT_URL}/image/main-page.jpg`}
      />
      <meta
        property="og:type"
        content={type ?? 'website'}
      />
      <meta
        property="og:url"
        content={process.env.NEXT_PUBLIC_BASE_FRONT_URL + canonical}
      />
      <link
        rel="canonical"
        href={process.env.NEXT_PUBLIC_BASE_FRONT_URL + canonical}
      />
      {customSeoTags}
    </Head>
  );
};
