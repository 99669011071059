import { useRouter } from 'next/router';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';

import { BookWithHeartIcon } from '~/atomic/atom/icon/BookWithHeartIcon';
import { LogoIcon } from '~/atomic/atom/icon/LogoIcon';
import { MyBooksLink } from '~/atomic/atom/links/dynamicLinks';
import { BlogsLink, ContestsLink } from '~/atomic/atom/links/staticLinks';
import { Hamburger } from '~/atomic/organism/Header/Hamburger';
import { headerSelector } from '~/atomic/organism/Header/header.selector';
import { HeaderAbonnement } from '~/atomic/organism/Header/HeaderAbonnement';
import { HeaderLinks } from '~/atomic/organism/Header/HeaderLinks';
import { HeaderLogo } from '~/atomic/organism/Header/HeaderLogo';
import { HeaderMobileUserInfo } from '~/atomic/organism/Header/HeaderMobileUserInfo';
import { HeaderMobNav } from '~/atomic/organism/Header/HeaderMobNav/HeaderMobNav';
import { LibraryModal } from '~/atomic/organism/Header/LibraryModal';
import { TextBoldStyle } from '~/atomic/Typography';
import { Alert } from '~/feature/Alert';
import { isMobileSizeSelector, isTabletSizeSelector } from '~/feature/media/windowSize.selector';
import { NotificationsIcon } from '~/feature/notification/NotificationsIcon';
import { MobNavSearchDrawer } from '~/feature/search/MobNavSearchDrawer';
import { searchByPlaceSelector } from '~/feature/search/search.selector';
import { SearchFieldPlaces } from '~/feature/search/search.slice';
import { SearchField } from '~/feature/search/SearchField';
import { SCSearchIcon, ShowSearchButton } from '~/feature/search/ShowSearchButton';
import { isLoggedInSelector } from '~/feature/user/isLoggedInSelector';
import { UserInfo } from '~/feature/user/UserInfo';
import { TimerDiscountBadger } from '~/feature/withTiwerDiscount/TimerDiscountBadger';
import { getTimerDiscount } from '~/feature/withTiwerDiscount/withTimerDiscount.data';
import { environments } from '~/lib/const';
import { lessThan } from '~/lib/mediaQuery';
import { useAppDispatch, useAppSelector } from '~/store';

import { headerActions } from './header.slice';

export const Header: FC = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();
  const { isOpenLibrary } = useAppSelector(headerSelector);
  const isMobileSize = useAppSelector(isMobileSizeSelector);
  const isTabletSize = useAppSelector(isTabletSizeSelector);
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const isShowNotificationIcon = isMobileSize ? isLoggedIn : true;
  const isShowMyBooksIcon = (isTabletSize || isMobileSize) ? isLoggedIn : false;
  const isShowLogoInHeader = (!isMobileSize || !isLoggedIn);

  const { isShowSearch } = useAppSelector(searchByPlaceSelector(SearchFieldPlaces.Header));

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(getTimerDiscount());
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (environments.isClient && isLoggedIn) {
      const getTimerDiscountFunction = () => {
        dispatch(getTimerDiscount());
      };

      router.events.on('routeChangeComplete', getTimerDiscountFunction);

      return () => {
        router.events.off('routeChangeComplete', getTimerDiscountFunction);
      };
    }
  }, [environments.isClient, isLoggedIn]);

  return (
    <SCHeaderWrapper>
      <SCHeader>
        <SCInner
          data-is-show-logo={isShowLogoInHeader}
        >
          <SCLogoWrapper>
            <SCHamburgerWrapp>
              <Hamburger />
            </SCHamburgerWrapp>
            <div>
              {isShowLogoInHeader ? (
                <HeaderLogo />
              ) : null}
            </div>
          </SCLogoWrapper>
          {
            !isShowSearch && (
              <>
                <ShowSearchButton place={SearchFieldPlaces.Header} />
                {!isMobileSize
                  ? (
                    <>
                      <SCLibraryLabel
                        onClick={() => {
                          dispatch(headerActions.setIsOpenLibrary(!isOpenLibrary));
                        }}
                        data-is-active={isOpenLibrary}
                      >
                        <span>
                          Книги
                        </span>
                        <SCTriangleArrow />
                      </SCLibraryLabel>
                      <SCLinks>
                        <BlogsLink>
                          <SCHeaderBlogLink>
                            Блоги
                          </SCHeaderBlogLink>
                        </BlogsLink>
                        <ContestsLink>
                          <SCHeaderBlogLink>
                            Конкурсы
                          </SCHeaderBlogLink>
                        </ContestsLink>
                        <HeaderAbonnement />
                      </SCLinks>
                    </>
                  )
                  : null}
                <SCWithTimerDiscount isShowText={false} />
                <HeaderLinks />
              </>
            )
          }
          {isShowSearch && (
            <SCSearchFieldWrapper>
              <SearchField place={SearchFieldPlaces.Header} autoFocus />
            </SCSearchFieldWrapper>
          )}
          {isShowMyBooksIcon ? (
            <SCProfileWrapp>
              <SCIconsWrapper>
                <MyBooksLink
                  status="read-now"
                >
                  <SCLink>
                    <SCBookWithHeartIcon />
                  </SCLink>
                </MyBooksLink>
              </SCIconsWrapper>
            </SCProfileWrapp>
          ) : null}
          {isShowNotificationIcon ? (
            <SCProfileWrapp>
              <SCIconsWrapper>
                <NotificationsIcon />
              </SCIconsWrapper>
            </SCProfileWrapp>
          ) : null}
          <UserInfo />
        </SCInner>
        <HeaderMobileUserInfo />
        <HeaderMobNav />
      </SCHeader>
      <MobNavSearchDrawer />
      <Alert />
      <LibraryModal />
    </SCHeaderWrapper>
  );
};

const SCSearchFieldWrapper = styled.div`
  margin-right: auto;
  width: 80%;

  ${lessThan('md')} {
    width: 60%;
    margin-left: 26px;
    margin-right: auto;
  }

  ${lessThan('sm')} {
    display: none;
  }
`;

const SCHeaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

const SCLabel = styled.span`
  ${TextBoldStyle};
  color: var(--gray-color);
`;

export const SCAuthorLabel = styled(SCLabel)`
  color: var(--black-color);

  ${lessThan('md')} {
    display: none;
  }
`;

export const SCHeaderBlogLink = styled.a`
  ${TextBoldStyle};

  color: var(--black-color);
  transition: 0.3s;

  &:hover {
    color: var(--primary-hover-color);
  }

  ${lessThan('md')} {
    display: none;
  }
`;

const SCLinks = styled.div`
  margin-right: auto;
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 30px;
  }
  
  ${lessThan('md')} {
    display: none;
  }
`;

export const SCTriangleArrow = styled.div`
  width: 13px;
  height: 13px;
  cursor: pointer;
  margin-top: -2px;
  mask-image: url('/icon/arrow-sort-down.svg');
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
  transition: 0.3s;
  background-color: var(--black-color);
`;

export const SCLibraryLabel = styled.div`
  ${TextBoldStyle};

  color: var(--black-color);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.3s;
  position: relative;
  margin-right: 30px;

  &:hover, &[data-is-active="true"] {
    ${SCTriangleArrow} {
      background-color: var(--primary-hover-color);
    }
    color: var(--primary-hover-color);
  }

  span {
    margin-right: 8px;
  }

  ${lessThan('md')} {
    display: none;
  }

`;

export const SCHeader = styled.header`
  background: #fff;
  flex-direction: row;
  height: var(--header-height);
  width: 100%;
  border-bottom: 1px solid var(--border-color);

  ${lessThan('md')} {
    --header-height: 56px;
  }
`;

const SCLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 52px;

  ${lessThan('md')} {
    margin-right: 20px;
  }

  ${lessThan('sm')} {
    margin-right: 0;
  }
`;

const SCInner = styled.div`
  max-width: var(--container-width);
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 1200px) {
    padding: 8px 16px;
  }

  ${lessThan('md')} {
    padding: 0 16px;
    justify-content: space-between;
  }

  ${lessThan('sm')} {
    &[data-is-show-logo="true"] {
      ${SCLogoWrapper} {
        margin-right: 20px;
      }

      ${SCSearchIcon} {
        margin-right: auto;
      }
    }
  }

  ${SCSearchIcon} {
    margin-right: 32px;

    ${lessThan('md')} {
      margin-right: 0;
    }
  }
`;

export const SCLogoIcon = styled(LogoIcon)`
  width: 162px;
  height: 41px;
`;

const SCProfileWrapp = styled.div`
  display: flex;
  align-items: center;
  margin-right: 0;
  margin-left: 20px;

  ${lessThan('sm')} {
    margin-left: 0;
  }
`;

export const SCHamburgerWrapp = styled.div`
  display: none;

  ${lessThan('md')} {
    display: block;
  }
`;

const SCIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const SCWithTimerDiscount = styled(TimerDiscountBadger)`
  margin-right: 16px;

  ${lessThan('xs')} {
    margin-right: 0;
  }

  @media only screen and (max-width: 420px) {
    display: none;
  }
`;

const SCBookWithHeartIcon = styled(BookWithHeartIcon)`
  font-size: 24px;
  color: var(--black-color);
  transition: color 0.3s ease;

  &:hover {
    color: var(--primary-hover-color);
  }
`;

const SCLink = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
`;
