import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { BookReaderThemeEnum } from '~/atomic/page/book-reader/reader.slice';

interface LoaderProps extends HTMLAttributes<HTMLDivElement> {
  size?: number;
  theme?: BookReaderThemeEnum;
}

export const Loader: FC<LoaderProps> = ({
  size, theme, ...props
}) => {
  return (
    <SCLoader
      {...props}
      data-theme={theme}
    >
      <SCEclipseLoader style={{ ['--size' as any]: size ? `${size}px` : '100%' }} />
    </SCLoader>
  );
};

export const SCEclipseLoader = styled.div<{ size?: number }>`
  --size: 100%;
  background-image: url('/image/eclipse-loader.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: var(--size);
  height: var(--size);
  
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const SCLoader = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 30px;
  color: var(--primary-color);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
  background-color: var(--bg-color);

  &[data-theme=${BookReaderThemeEnum.Dark}] {
    background-color: var(--black-color);
  }
  
  &[data-theme=${BookReaderThemeEnum.Sandy}] {
    background-color: var(--sandy-color);
  }
`;
