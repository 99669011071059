import React, {
  FC, HTMLAttributes, ReactNode,
} from 'react';
import styled, { FlattenSimpleInterpolation } from 'styled-components';

import { breakepoints } from '~/atomic/breakepoints';
import { lessThan } from '~/lib/mediaQuery';

interface LeftNavLayoutProps extends HTMLAttributes<HTMLDivElement> {
  nav: ReactNode;
  navCss?: FlattenSimpleInterpolation;
}

export const LeftNavLayout: FC<LeftNavLayoutProps> = ({
  children,
  nav,
  navCss,
  ...props
}) => {
  return (
    <SCLeftNavLayout {...props}>
      <SCLeftSideNavWrapper css={navCss}>
        { nav }
      </SCLeftSideNavWrapper>
      <SCChildrenWrapper>
        { children }
      </SCChildrenWrapper>
    </SCLeftNavLayout>
  );
};

export const SCChildrenWrapper = styled.div`
  max-width: 894px;
  width: 100%;
  
  ${lessThan('md')} {
    max-width: 100%;
  }
`;

export const SCLeftSideNavWrapper = styled.div<{ css: FlattenSimpleInterpolation }>`
  margin-right: 25px;
  margin-bottom: 30px;
  max-width: 290px;
  
  ${lessThan('md')} {
    display: none;
  }
  
  ${(props) => props?.css}
`;

export const SCLeftNavLayout = styled.div`
  display: flex;
  align-items: flex-start;
  padding-top: 40px;
  width: 100%;
  justify-content: space-between;
  
  ${lessThan('md')} {
    padding-left: 0;
    flex-direction: column;
    padding-top: 30px;
  }
  
  @media only screen and (max-width: ${breakepoints['container-width-down']}) {
    padding-left: 16px;
    padding-right: 16px;
  };

  ${lessThan('sm')} {
    padding-left: 12px;
    padding-right: 12px;
    padding-top: 20px;
  }
`;
