const DefaultAvatarColorNames = ['one', 'two', 'three', 'four', 'five', 'six', 'seven'] as const;
export type DefaultAvatarColorNamesType = typeof DefaultAvatarColorNames[number];

export const generateDefaultAvatarColor = (userName: string): DefaultAvatarColorNamesType => {
  if (!userName || userName.length === 0) {
    return DefaultAvatarColorNames[0];
  }

  return DefaultAvatarColorNames[userName.length % 7];
};
