import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Methods } from '~/api/finance/financeApiTypes';

export const buyAudioBookSliceName = 'buyAudioBook';

export const buyAudioBookSlice = createSlice({
  name: buyAudioBookSliceName,
  initialState: {
    isOpenModal: false,
    bookId: '',
    methodPayment: '' as Methods,
    returnUrl: '',
    email: '',
    bankCardId: 0,
    isOpenChoosePayModal: false,
  },
  reducers: {
    changeEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    showBuyAudioBookModal: (state) => {
      state.isOpenModal = true;
    },
    hideBuyAudioBookModal: (state) => {
      state.isOpenModal = false;
    },
    changeBookId: (state, action: PayloadAction<string>) => {
      state.bookId = action.payload;
    },
    changeMethodPayment: (state, action: PayloadAction<Methods>) => {
      state.methodPayment = action.payload;
    },
    changeReturnUrl: (state, action: PayloadAction<string>) => {
      state.returnUrl = action.payload;
    },
    changeBankCardId: (state, action: PayloadAction<number>) => {
      state.bankCardId = action.payload;
    },
    setIsOpenChoosePayModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenChoosePayModal = action.payload;
    },
  },
});

export const {
  actions: buyAudioBookActions,
  reducer: buyAudioBookReducer,
} = buyAudioBookSlice;
