import { createSelector } from '@reduxjs/toolkit';

import {
  CommercialStatusEnum,
  LegalStatusEnum,
  LegalStatusNameEnum, UserAbonnementStatusEnum, UserRole,
} from '~/api/account/accountApiTypes';
import { isLoggedInSelector } from '~/feature/user/isLoggedInSelector';
import { RootState } from '~/store';

export const userSelector = (state: RootState) => state.user;

export const isLoggedUserCommercialAuthorSelector = createSelector(
  userSelector,
  ({ user }) => user?.role === UserRole.author
    && user.commercialStatus === CommercialStatusEnum.accepted,
);

export const isLoggedUserReaderSelector = createSelector(
  userSelector,
  ({ user }) => user?.role === UserRole.reader,
);

export const isLoggedUserAuthorSelector = createSelector(
  userSelector,
  ({ user }) => user?.role === UserRole.author,
);

export const isLoggedUserPartnerSelector = createSelector(
  userSelector,
  ({ user }) => user?.legalStatus === LegalStatusEnum.SelfEmployed
    || user.legalStatus === LegalStatusEnum.IndividualEntrepreneur,
);

export const isLoggedUserAdminSelector = createSelector(
  userSelector,
  ({ user }) => user?.role === UserRole.admin,
);

export const isLoggedUserTargetologistSelector = createSelector(
  userSelector,
  ({ user }) => user?.isTargetologist,
);

export const isLoggedUserTargetologistOrAdminSelector = createSelector(
  userSelector,
  ({ user }) => user?.isTargetologist || user?.role === UserRole.admin,
);

export const loggedUserSelector = createSelector(
  userSelector,
  (user) => user.user,
);

export const legalStatusNameSelector = createSelector(
  userSelector,
  ({ user }) => {
    let legalStatusKeyName = '';
    let legalStatusValueName = '';
    Object.entries(LegalStatusEnum)
      .forEach(([key, value]) => {
        if (value === user.legalStatus) {
          legalStatusKeyName = key;
        }
      });

    Object.entries(LegalStatusNameEnum)
      .forEach(([key, value]) => {
        if (key === legalStatusKeyName) {
          legalStatusValueName = value;
        }
      });

    return legalStatusValueName === '' ? LegalStatusNameEnum.Individual : legalStatusValueName;
  },
);

export const isUserAdultSelector = createSelector(
  userSelector,
  ({ user }) => {
    if (user?.birthday) {
      const currentDate = new Date();
      const dateOfBirth = new Date(user.birthday);
      const dayInMilliseconds = 1000 * 60 * 60 * 24;
      const daysForAdult = 365 * 18;

      const daysAfterBirthday = (currentDate.getTime() - dateOfBirth.getTime()) / dayInMilliseconds;
      const isUserAdult = daysAfterBirthday > daysForAdult;

      return isUserAdult;
    }
    return false;
  },
);

export const isHaveUserAbonnementSelector = createSelector(
  userSelector,
  isLoggedInSelector,
  ({ user }, isLoggedIn) => isLoggedIn && user.abonnement && user.abonnement.status === UserAbonnementStatusEnum.Active,
);

export const userAbonnementSelector = createSelector(
  userSelector,
  ({ user }) => user.abonnement,
);
