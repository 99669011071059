import {
  ApolloClient, DefaultOptions, HttpLink, InMemoryCache,
} from '@apollo/client';

import { apolloBaseLink } from '~/feature/graphql/apolloBaseLink';

const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export const apolloClient = () => new ApolloClient({
  link: apolloBaseLink.concat(new HttpLink({ uri: `${process.env.NEXT_PUBLIC_BASE_GRAPHQL_URL}` })),
  cache: new InMemoryCache(),
  connectToDevTools: true,
  defaultOptions,
});
