import {
  EmailDigestUnsubscribeParams,
  EmailDiscountUnsubscribeParams,
  GetNotificationsParams,
  Notification,
  NotificationCategory,
  NotificationChannels,
  NotificationsSettings,
  SaveFCMTokenParams,
  SaveNotificationsSettingsParams,
} from '~/api/account/notificationsApiTypes';
import { provider } from '~/api/provider/provider.config';
import { handleRejectedRequest } from '~/api/provider/providerErrors';
import {
  ApiResponse, Paginated, RejectedRequest,
} from '~/api/provider/providerTypes';

const namespace = Symbol('namespace');

export const notificationsApi = {
  [namespace]: '/v1/notifications',
  async markAsRead(data: void) {
    try {
      const response = await provider.post<void>(
        `${this[namespace]}/read`,
        data,
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async getNotifications(data: GetNotificationsParams) {
    try {
      const response = await provider.get<
      Paginated<Notification[]>
      >(
        `${this[namespace]}`,
        { params: data },
      );

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async getNotificationCategories(data: void) {
    try {
      const response = await provider.get<
      ApiResponse<NotificationCategory[], RejectedRequest<void>>
      >(
        `${this[namespace]}/groups`,
        { params: data },
      );

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async getNotificationsSettings(data: void) {
    try {
      const response = await provider.get<
      ApiResponse<NotificationsSettings[], RejectedRequest<void>>
      >(
        `${this[namespace]}/settings`,
        { params: data },
      );

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async postNotificationsSettings(data: SaveNotificationsSettingsParams) {
    try {
      await provider.post<ApiResponse<void, RejectedRequest<void>>>(`${this[namespace]}/settings`, data);
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async emailDigestUnsubscribe(data: EmailDigestUnsubscribeParams) {
    try {
      await provider.delete<
      ApiResponse<void, RejectedRequest<void>>
      >(`${this[namespace]}/emailDigestSubscription`, { data });
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async emailDiscountUnsubscribe(data: EmailDiscountUnsubscribeParams) {
    try {
      await provider.delete<
      ApiResponse<void, RejectedRequest<void>>
      >(`${this[namespace]}/email-discounts-subscription`, { data });
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async availableChannels() {
    try {
      const response = await provider.get<NotificationChannels | RejectedRequest<void>>(
        `${this[namespace]}/availableChannels`,
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async saveFCMToken(data: SaveFCMTokenParams) {
    try {
      const response = await provider.post<ApiResponse<void, RejectedRequest<void>>>(
        `${this[namespace]}/fcm/token`,
        data,
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
};
