// eslint-disable-next-line max-classes-per-file
import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import * as rax from 'retry-axios';

import { isAxiosError } from '~/api/provider/providerErrors';
import { getAuthTokenFromClient } from '~/feature/authorization/getAuthToken';
import { setUserTimeToHeaders } from '~/feature/setUserTimeToHeaders';
import { environments } from '~/lib/const';
import { AppDispatch, getStore } from '~/store';

export const baseURL = process.env.NEXT_PUBLIC_BASE_URL;
export const baseFrontServerUrl = process.env.NEXT_PUBLIC_BASE_FRONT_URL;

axios.defaults.baseURL = baseURL;
axios.defaults.withCredentials = true;

if (typeof (axios.defaults.raxConfig as any)?.raxId !== 'undefined') {
  rax.detach((axios.defaults.raxConfig as any)?.raxId);
}

const raxId = rax.attach();

(axios.defaults.raxConfig as any) = {
  raxId,
  retry: 3,
  retryDelay: 300,
  shouldRetry: (err: any) => {
    if (environments.isClient) {
      const count = err?.config?.raxConfig?.currentRetryAttempt ?? 3;
      return err.response!.status === 401 && count < 3;
    }

    return false;
  },
};

function requestFulfilledInterceptor(config: AxiosRequestConfig) {
  const token = getAuthTokenFromClient();

  if (token && environments.isClient) {
    const Authorization = `${config?.headers?.Authorization ?? ''} Bearer ${token}`;

    return {
      ...config,
      headers: {
        ...config.headers,
        Authorization,
      },
    } as any;
  }
  return config;
}

function requestRejectedInterceptor(error: any) {
  return Promise.reject(error);
}

if (typeof (axios.defaults as any).requestInterceptorId !== 'undefined') {
  axios.interceptors.request.eject((axios.defaults as any).requestInterceptorId);
}

const requestInterceptorId = axios.interceptors.request.use(
  requestFulfilledInterceptor,
  requestRejectedInterceptor,
);

(axios.defaults as any).requestInterceptorId = requestInterceptorId;

function responseFulfilledInterceptor(response: AxiosResponse<any>) {
  return response;
}

function responseRejectedInterceptor(error: any) {
  const store = getStore();
  const dispatch = store.dispatch as AppDispatch;

  if (isAxiosError(error) && error.code === '401') {
    dispatch({
      type: 'auth/logout',
      payload: null,
    });
  }
  return Promise.reject(error);
}

if (typeof (axios.defaults as any).responseInterceptorId !== 'undefined') {
  axios.interceptors.response.eject((axios.defaults as any).responseInterceptorId);
}

const responseInterceptorId = axios.interceptors.response.use(
  responseFulfilledInterceptor,
  responseRejectedInterceptor,
);

(axios.defaults as any).responseInterceptorId = responseInterceptorId;
export const provider = axios;

setUserTimeToHeaders();
