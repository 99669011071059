import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BookTag } from '~/api/book/bookTagApiTypes';

const tagSlice = createSlice({
  name: 'tag',
  initialState: {
    tags: [] as BookTag[],
    isLoading: false,
    isCurrentTagLoading: false,
    currentTag: {} as Partial<BookTag>,
    relatedTags: [] as BookTag[],
    currentTagSeo: {
      title: '',
      h1: '',
      description: '',
      seotext: '',
    },
  },
  reducers: {
    changeTags: (state, action: PayloadAction<BookTag[]>) => {
      state.tags = action.payload;
    },
    setIsCurrentTagLoading: (state, action: PayloadAction<boolean>) => {
      state.isCurrentTagLoading = action.payload;
    },
    changeCurrentTag: (state, action: PayloadAction<BookTag>) => {
      state.currentTag = action.payload;
    },
    changeRelatedTags: (state, action: PayloadAction<BookTag[]>) => {
      state.relatedTags = action.payload;
    },
    changeCurrentTagSeo: (state, action: PayloadAction<{
      title: string;
      h1: string;
      description: string;
      seotext: string;
    }>) => {
      state.currentTagSeo = action.payload;
    },
  },
});

export const { reducer: tagReducer, actions: tagActions } = tagSlice;
