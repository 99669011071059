import React, { FC } from 'react';

import { FeedTabEnum } from '~/api/feed/feedApiTypes';
import { PostLink } from '~/atomic/atom/links/dynamicLinks';
import { pagesConfigStore } from '~/atomic/atom/links/pagesConfigStore';
import {
  TemplateStaticLink,
  TemplateStaticLinkProps,
} from '~/atomic/atom/links/TemplateStaticLink';

interface StaticTemplateLink extends Omit<TemplateStaticLinkProps, 'href'> {}

export const HomeLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.home.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const HomeAbonnementLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.home.url}
      query={{ feedTab: FeedTabEnum.Abonnement }}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const RefriverLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.refriver.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const BooksLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.books.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const MyBlogLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.myblog.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const PartnersBooksLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.partnersBooks.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const TimerDiscountBooksLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.partnersDiscount.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const PartnersWidgetBooksLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.partnersBooksWidget.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const AboutUsLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.about.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const ImAuthorLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.imAuthor.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const AntiPirateLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.antiPirate.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const ContactsLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.contacts.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const AntiPirateCheckLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.antiPirateCheck.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const StatisticsLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.statistics.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const NotificationLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.notifications.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const MarketingLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.marketing.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const SettingsLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.settings.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const PrivacyPolicyLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.legalPrivacyPolicy.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const PurchasesLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.myPurchases.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const UserPolicyLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.legalUserPolicy.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const PrivacyAgreementLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.legalPrivacyAgreement.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const AuthorPolicyLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.legalAuthorPolicy.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const AdvertisingLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.legalAdvertising.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const ReferralProgramLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.legalReferralProgram.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const RoyaltyLicenceLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.legalLoyaltyPolicy.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const AboutPartnersProgramLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.partnersAboutProgram.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const CampaignsListLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.campaigns.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const BlogsLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.blogs.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const AuthorCommercialLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.authorCommercial.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const AuthorRegistrationLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.authorRegistration.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const AuthorCommercialRegistrationPendingLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.authorCommercialRegistrationPending.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const AuthorCommercialRegistrationLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.authorCommercialRegistration.url}
      {...restProps}
    >
      {children}
    </TemplateStaticLink>
  );
};

export const PersonalYandexMetricaInfoLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.personalYandexMetricaCounterInfo.url}
      {...restProps}
    >
      { children }
    </TemplateStaticLink>
  );
};

export const CumulativeDiscountLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.cumulativeDiscounts.url}
      {...restProps}
    >
      { children }
    </TemplateStaticLink>
  );
};

export const DiscountsLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.discounts.url}
      {...restProps}
    >
      { children }
    </TemplateStaticLink>
  );
};

export const ContestsLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.contests.url}
      {...restProps}
    >
      { children }
    </TemplateStaticLink>
  );
};

export const ContestEverythingWillBeFineLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.contestEverythingWillBeFine.url}
      {...restProps}
    >
      { children }
    </TemplateStaticLink>
  );
};

export const ContestStorySapphireLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.contestStorySapphire.url}
      {...restProps}
    >
      { children }
    </TemplateStaticLink>
  );
};

export const ContestDukeRectorTwoDragonsLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.contestDukeRectorTwoDragons.url}
      {...restProps}
    >
      { children }
    </TemplateStaticLink>
  );
};
export const ContestZhenitNelzyaPomilovatLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.contestZhenitNelzyaPomilovat.url}
      {...restProps}
    >
      { children }
    </TemplateStaticLink>
  );
};

export const FinanceLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.finance.url}
      {...restProps}
    >
      { children }
    </TemplateStaticLink>
  );
};

export const AbonnementPageLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.bookSubscriptions.url}
      {...restProps}
    >
      { children }
    </TemplateStaticLink>
  );
};

export const LicensePolicyPageLink: FC<StaticTemplateLink> = ({
  children,
  ...restProps
}) => {
  return (
    <TemplateStaticLink
      href={pagesConfigStore.legalLicensePolicy.url}
      {...restProps}
    >
      { children }
    </TemplateStaticLink>
  );
};

export const AboutAbonnementPostLink = ({ children }: { children: React.ReactNode }) => {
  return (
    <PostLink
      slugPost="abonement-avtoram-dlya-starykh-serii-i-novinok-v-protsesse-5048"
    >
      {children}
    </PostLink>
  );
};
