import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Promocode } from '~/api/book/promocodesApiTypes';

export const bookPromocodesSliceName = 'bookPromocodes';

const bookPromocodesSlice = createSlice({
  name: bookPromocodesSliceName,
  initialState: {
    promocodes: [] as Promocode[],
    isLoading: false,
    isSuccess: false,
    createPromocodeLoading: false,
  },
  reducers: {
    changePromocodes: (state, action: PayloadAction<Array<any>>) => {
      state.promocodes = action.payload;
    },
    addPromocode: (state, action: PayloadAction<Promocode>) => {
      state.promocodes = [...state.promocodes, action.payload];
    },
    setCreatePromocodeLoading: (state, action: PayloadAction<boolean>) => {
      state.createPromocodeLoading = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsSuccess: (state, action: PayloadAction<boolean>) => {
      state.isSuccess = action.payload;
    },
  },
});

export const {
  reducer: bookPromocodesSliceReducer,
  actions: bookPromocodesSliceActions,
} = bookPromocodesSlice;
