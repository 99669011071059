import React, { FC, ReactNode } from 'react';

import {
  SEOPageTemplate,
  SEOPageTemplateProps,
} from '~/atomic/organism/PageTemplate/SEOPageTemplate';
import { CheckAuth } from '~/feature/authorization/CheckAuth';
import { OmniDesk } from '~/feature/OmniDesk';
import { TopProgressBar } from '~/feature/topProgressBar/TopProgressBar';

interface PrivatePageTemplateProps {
  seoProps: SEOPageTemplateProps;
  content: ReactNode;
}

export const PrivatePageTemplate: FC<PrivatePageTemplateProps> = ({
  seoProps,
  content,
}) => {
  return (
    <>
      <SEOPageTemplate {...seoProps} />
      {content}
      <CheckAuth />
      <TopProgressBar />
      <OmniDesk />
    </>
  );
};
