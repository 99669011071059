import { BookIdType } from '~/api/book/bookApiTypes';
import { ReferralLinksTimeSorted } from '~/atomic/page/finance/ReferralLinksTable/referralLinksTable.slice';

export enum StatisticPeriodEnum {
  Month = 'month',
  All = 'all',
}

export interface BookStatistics {
  date: string;
  rewards: number;
  sales: number;
  views: number;
  libraryAdditions: number;
  comments: number;
  sumRewards: number;
  sumSales: number;
}

export interface BookStatisticsPublic {
  readCompleted: number;
  readLater: number;
  readNow: number;
  views: number;
  likes: number,
  dislikes: number,
  rewards: number,
  comments: null | number,
}

export interface BookListStatisticsPublic extends BookStatisticsPublic {
  bookId: BookIdType;
}

export interface GetBookTotalStatisticsParams {
  bookId: BookIdType;
}

export interface GetBookListTotalStatisticsParams {
  bookIds: string;
}

export interface GetStatisticsParams {
  bookId: BookIdType;
  period?: StatisticPeriodEnum;
}

export interface ViewBookParams {
  bookId: BookIdType;
}

export interface ViewChapterParams {
  chapterId: string;
}

export interface GetPartnerProgramStatisticsParams {
  period: ReferralLinksTimeSorted;
  perPage: number;
  page: number;
}

export interface PartnerProgramStatistics {
  date: string;
  visits: number;
  registrations: number;
  sales: number;
  income: number;
}

export type GetAbonnementStatisticsParams = {
  month: string;
};

export type AbonnementRoyaltyBook = {
  bookName: string;
  bookPrice: number;
  numberOfConsumedKiloCharacters: number;
  bookNumberOfKiloCharacters: number;
  royalty: number;
};

export type AbonnementRoyalty = {
  numberOfConsumedKiloCharacters: number;
  avgPercentOfRoyalty: number;
  royalty: number;
  books: AbonnementRoyaltyBook[];
};

export type GetAbonnementStatisticsResult = {
  abonnementRoyalty: AbonnementRoyalty;
};

export type GetAbonnementStatisticsDates = {
  months: string[];
};
