import { useEffect } from 'react';

import { isLoggedInSelector } from '~/feature/user/isLoggedInSelector';
import { loggedUserSelector } from '~/feature/user/user.selector';
import { ym } from '~/feature/yandex/YandexMetrikaInit';
import { useAppSelector } from '~/store';

export const YandexMetrikaInitUser = () => {
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const user = useAppSelector(loggedUserSelector);

  useEffect(() => {
    if (isLoggedIn && user?.id) {
      ym('userParams', { UserID: user.id });
    }
  }, [isLoggedIn]);

  return null;
};
