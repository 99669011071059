import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const generateReferralLinkName = 'generateReferralLink';
export const referralLinkSliceName = 'referralLink';

const referralLinkSlice = createSlice({
  name: referralLinkSliceName,
  initialState: {
    isGenerateReferralLinkLoading: false,
    isReferralUrlVisitedLoading: false,
    isUpdatePartnerFromReferralLinkLoading: false,
    isReferralWindowOpen: false,
    isHideWindowOpen: false,
    isHideGenerateReferralLink: true,
    referralLink: '',
  },
  reducers: {
    setIsGenerateReferralLinkLoading: (state, action: PayloadAction<boolean>) => {
      state.isGenerateReferralLinkLoading = action.payload;
    },
    setIsReferralUrlVisitedLoading: (state, action: PayloadAction<boolean>) => {
      state.isReferralUrlVisitedLoading = action.payload;
    },
    setIsUpdatePartnerFromReferralLinkLoading: (state, action: PayloadAction<boolean>) => {
      state.isUpdatePartnerFromReferralLinkLoading = action.payload;
    },
    changeReferralLink: (state, action: PayloadAction<string>) => {
      state.referralLink = action.payload;
    },
    changeIsReferralWindowOpen: (state, action: PayloadAction<boolean>) => {
      state.isReferralWindowOpen = action.payload;
    },
    toggleIsReferralWindowOpen: (state) => {
      state.isReferralWindowOpen = !state.isReferralWindowOpen;
    },
    changeIsHideWindowOpen: (state, action: PayloadAction<boolean>) => {
      state.isHideWindowOpen = action.payload;
    },
    setIsHideGenerateReferralLink: (state, action: PayloadAction<boolean>) => {
      state.isHideGenerateReferralLink = action.payload;
    },
  },
});

export const { actions: referralLinkActions, reducer: referralLinkReducers } = referralLinkSlice;
