import Link from 'next/link';
import React, { FC } from 'react';

import { DefaultLinkProps } from '~/atomic/atom/links/TemplateStaticLink';

interface TemplateDynamicLinkProps extends DefaultLinkProps {
  href: string;
  as: string;
}

export const TemplateDynamicLink: FC<TemplateDynamicLinkProps> = ({
  as = '',
  hash = '',
  href = '',
  query = {},
  children,
  ...restProps
}) => {
  Object.keys(query).forEach((queryKey) => {
    if (queryKey in query && query[queryKey] === undefined) {
      delete query[queryKey];
    }
  });

  return (
    <Link
      href={{
        hash,
        query,
        pathname: href,
      }}
      as={{
        hash,
        query,
        href,
        pathname: as,
      }}
      passHref
      prefetch={false}
      {...restProps}
    >
      {children}
    </Link>
  );
};
