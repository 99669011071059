import qs from 'query-string';

import {
  BookSortingEnum, BookTypeEnum, CompleteStatusEnum,
} from '~/api/book/bookApiTypes';
import { createRelativeUrl } from '~/lib/utils/createRelativeUrl';

export const FREE_BOOK_FILTER = 'besplatno';
export const DOWNLOAD_BOOK_FILTER = 'skachat';
export const WITH_AUDIOBOOK_FILTER = 'audiobook';
export const WITH_ABONNEMENT_FILTER = 'abonnement';
export const GENRES_BOOK_FILTER = 'g';

export interface CreateUrlWithFilterProps {
  startWith: string;
  genresSlugs?: Array<string>;
  isOnlyFree?: boolean;
  isCanDownload?: boolean;
  withAudiobook?: boolean;
  completeStatus?: CompleteStatusEnum;
  isWithAbonnement?: boolean;
  bookType?: BookTypeEnum;
  sortingType?: BookSortingEnum;
  page?: number;
  perPage?: number;
  parseUrl?: string;
  query?: qs.ParsedQuery<string>;
  isAddGenreToParams?: boolean;
  genreSlug?: string;
  tagSlug?: string;
}

export const createUrlWithFilter = ({
  isAddGenreToParams = true,
  isWithAbonnement,
  completeStatus,
  isCanDownload = false,
  withAudiobook = false,
  sortingType,
  genresSlugs = [],
  isOnlyFree = false,
  startWith,
  genreSlug,
  bookType,
  perPage,
  parseUrl,
  tagSlug,
  query,
  page,
}: CreateUrlWithFilterProps) => {
  const parsedUrl = qs.parseUrl(parseUrl ?? '');

  const isHaveTag = Boolean(tagSlug);
  let newUrl = startWith;
  const genresToQuery = [...genresSlugs];

  if (genreSlug) genresToQuery.unshift(genreSlug);

  if (isAddGenreToParams) {
    const genreToParam = genresToQuery.shift();
    const isHaveGenre = Boolean(genreToParam);

    if (isHaveGenre) {
      newUrl += `/${genreToParam}`;
    }
  }

  if (isHaveTag) {
    newUrl += `/${tagSlug}`;
  }

  if (isOnlyFree) {
    newUrl += `/${FREE_BOOK_FILTER}`;
  }

  if (isCanDownload) {
    newUrl += `/${DOWNLOAD_BOOK_FILTER}`;
  }

  if (withAudiobook) {
    newUrl += `/${WITH_AUDIOBOOK_FILTER}`;
  }

  if (isWithAbonnement) {
    newUrl += `/${WITH_ABONNEMENT_FILTER}`;
  }

  if (completeStatus) {
    parsedUrl.query.completeStatus = completeStatus;
  } else {
    delete parsedUrl.query.completeStatus;
  }

  if (bookType) {
    parsedUrl.query.bookType = bookType;
  } else {
    delete parsedUrl.query.bookType;
  }

  if (sortingType) {
    parsedUrl.query.sortingType = sortingType;
  } else {
    delete parsedUrl.query.sortingType;
  }

  if (genresToQuery.length > 0) {
    parsedUrl.query[GENRES_BOOK_FILTER] = genresToQuery;
  } else {
    parsedUrl.query[GENRES_BOOK_FILTER] = undefined;
  }

  if (page) {
    parsedUrl.query.page = String(page);
  }

  if (perPage) {
    parsedUrl.query.perPage = String(perPage);
  }

  return createRelativeUrl({
    pathname: newUrl,
    query: {
      ...query,
      ...parsedUrl.query,
    },
  });
};
