import { createAsyncThunk } from '@reduxjs/toolkit';

import { searchApi } from '~/api/search/searchApi';
import { FindUsersAndBooksParams, FindUsersAndBooksResponse } from '~/api/search/searchApiTypes';
import { searchActions, SearchFieldPlaces } from '~/feature/search/search.slice';
import { environments } from '~/lib/const';

export const findUsersAndBooks = createAsyncThunk<
FindUsersAndBooksResponse,
{ data: FindUsersAndBooksParams, place: SearchFieldPlaces },
{ rejectValue: { error: string }; }
>(
  'search/findUsersAndBooks',
  async (
    { data, place },
    thunkAPI,
  ) => {
    try {
      thunkAPI.dispatch(searchActions.setLoading({
        isLoading: true,
        place,
      }));
      const result = await searchApi.findUsersAndBooks(data);
      if (result && 'data' in result) {
        thunkAPI.dispatch(searchActions.setBooks({
          books: result.data.books,
          place,
        }));
        thunkAPI.dispatch(searchActions.setAuthors({
          authors: result.data.authors,
          place,
        }));
        thunkAPI.dispatch(searchActions.setTotal({
          total: result.total,
          place,
        }));
        return result;
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(searchActions.setLoading({
        isLoading: false,
        place,
      }));
    }
  },
);
