import { User } from '~/api/account/accountApiTypes';
import {
  BindPhoneParams,
  ConfirmationPhoneParams,
  GetRegistrationStepAuthorResponse,
  GetSubscriptionListParams,
  GetUserParams,
  GetUserSubscribersParams,
  GetUserSubscriptionsListResult,
  PostRegistrationStepAuthorParams,
  PostRegistrationStepCommercialParams,
  PostRegistrationStepPartnerParams,
  RegistrationRequest,
  UserWithSubscriptions,
} from '~/api/account/userApiTypes';
import {
  baseURL,
  provider,
} from '~/api/provider/provider.config';
import { handleRejectedRequest } from '~/api/provider/providerErrors';
import { ApiResponse, RejectedRequest } from '~/api/provider/providerTypes';

const namespace = Symbol('namespace');

export const userApi = {
  [namespace]: '/v1/users',

  async getUser(data: GetUserParams) {
    try {
      const response = await provider.get<
      UserWithSubscriptions | RejectedRequest<void>
      >(
        `${this[namespace]}/${data.username}`,
        {
          headers: {
            Authorization: data && data?.token
              ? `Bearer ${data?.token}`
              : provider.defaults.headers.common.Authorization,
          },
        },
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getRegistrationMe() {
    try {
      const response = await provider.get<
      ApiResponse<GetRegistrationStepAuthorResponse, RejectedRequest<void>>
      >(
        `${this[namespace]}/legalStatus/me`,
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async postRegistrationStepCommercialAuthor(data: PostRegistrationStepAuthorParams) {
    try {
      const response = await provider.post<
      ApiResponse<User, RejectedRequest<void>>
      >(`${this[namespace]}/legalStatus/commercialAuthor`, data);

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async postRegistrationStepAuthor(data: PostRegistrationStepAuthorParams) {
    try {
      const response = await provider.post<
      ApiResponse<User, RejectedRequest<void>>
      >(`${this[namespace]}/legalStatus/author`, data);

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async postRegistrationStepPartner(data: PostRegistrationStepPartnerParams) {
    try {
      const response = await provider.post<
      ApiResponse<User, RejectedRequest<void>>
      >(`${this[namespace]}/legalStatus/partner`, data);

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async postRegistrationPassport(data: PostRegistrationStepCommercialParams) {
    try {
      const response = await provider.post<
      ApiResponse<User, RejectedRequest<PostRegistrationStepCommercialParams>>
      >(`${this[namespace]}/legalStatus/passport`, data);

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<PostRegistrationStepCommercialParams>(error);
      return handleError;
    }
  },
  async getRegistrationRequest() {
    try {
      const response = await provider.get<
      ApiResponse<RegistrationRequest, RejectedRequest<void>>
      >(`${this[namespace]}/mereserve/request`);

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getUserSubscribers(data: GetUserSubscribersParams) {
    try {
      const response = await provider.get<
      ApiResponse<User[], RejectedRequest<void>>
      >(
        `${this[namespace]}/${data.username}/subscribers`,
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  getSubscriptionList(data: GetSubscriptionListParams) {
    try {
      return provider.get<
      GetUserSubscriptionsListResult
      >(`${this[namespace]}/mereserve/subscriptions`, { params: data });
    } catch (error) {
      return handleRejectedRequest<void>(error);
    }
  },
  async bindPhone(data: BindPhoneParams) {
    try {
      const response = await provider.put<
      ApiResponse<void, RejectedRequest<void>>
      >(`${this[namespace]}/mereserve/phone`, data);

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async confirmationPhone(data: ConfirmationPhoneParams) {
    try {
      const response = await provider.post<
      ApiResponse<User, RejectedRequest<void>>
      >(`${this[namespace]}/mereserve/phone/confirmation`, data);

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  setUploadPassportPhotoUrl() {
    return `${baseURL}${this[namespace]}/mereserve/uploadPassportPhoto`;
  },
  setUploadApprovePhotoUrl() {
    return `${baseURL}${this[namespace]}/mereserve/uploadApprovePhoto`;
  },
};
