import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Counters, User } from '~/api/account/accountApiTypes';

export const userSliceName = 'user';

const userSlice = createSlice({
  name: userSliceName,
  initialState: {
    message: '',
    user: {} as Partial<User>,
    userCounters: {} as Partial<Counters>,
    currentUser: {} as Partial<User>,
    loading: false,
    isGetMeLoading: false,
    updateMeLoading: false,
    error: '',
    isAuth: false,
    token: '',
  },
  reducers: {
    setIsGetMeLoading: (state, action: PayloadAction<boolean>) => {
      state.isGetMeLoading = action.payload;
    },
    setUpdateMeLoading: (state, action: PayloadAction<boolean>) => {
      state.updateMeLoading = action.payload;
    },
    setIsAuth: (state, action: PayloadAction<boolean>) => {
      state.isAuth = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    changeUser: (state, action: PayloadAction<Partial<User>>) => {
      state.user = action.payload;
    },
    changeCurrentUser: (state, action: PayloadAction<Partial<User>>) => {
      state.currentUser = action.payload;
    },
    changeUserCounters: (state, action: PayloadAction<Partial<Counters>>) => {
      state.userCounters = action.payload;
    },
    changeToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    updateUser: (state, action: PayloadAction<Partial<User>>) => {
      state.user = { ...state.user, ...action.payload };
    },
    markAsReadNotifications: (state) => {
      state.userCounters.notifications = { unread: 0 };
    },
    setSocialPassword: (state) => {
      const userCopy = { ...state.user };

      delete userCopy?.socialPasswordNotSet;

      state.user = { ...userCopy };
    },
  },
});

export const { actions: userActions, reducer: userReducer } = userSlice;
