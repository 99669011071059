import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Book, BookIdType } from '~/api/book/bookApiTypes';
import { BookStatistics, StatisticPeriodEnum } from '~/api/book/statisticsApiTypes';

export const statisticsPageSliceName = 'statisticsPage';

export enum StatisticsPageParameterEnum {
  Sales = 'sales',
  Views = 'views',
  Profit = 'profit',
  Comments = 'comments',
  LibraryAdditions = 'libraryAdditions',
}

const statisticsPageSlice = createSlice({
  name: statisticsPageSliceName,
  initialState: {
    isLoadingStatistics: false,
    statistics: [] as BookStatistics[],
    booksForFilter: [] as Book[],
    activeBookId: null,
    activeBookTime: StatisticPeriodEnum.Month,
    activeChapterTime: 'month',
    activeParameter: StatisticsPageParameterEnum.Sales,
  },
  reducers: {
    changeStatistics: (state, action: PayloadAction<BookStatistics[]>) => {
      state.statistics = action.payload;
    },
    changeActiveBookId: (state, action: PayloadAction<BookIdType>) => {
      state.activeBookId = action.payload;
    },
    changeActiveBookTime: (state, action: PayloadAction<StatisticPeriodEnum>) => {
      state.activeBookTime = action.payload;
    },
    changeActiveChapterTime: (state, action: PayloadAction<string>) => {
      state.activeChapterTime = action.payload;
    },
    changeActiveParameter: (state, action: PayloadAction<StatisticsPageParameterEnum>) => {
      state.activeParameter = action.payload;
    },
    changeBooksForFilter: (state, action: PayloadAction<Book[]>) => {
      state.booksForFilter = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoadingStatistics = action.payload;
    },
  },
});

export const {
  actions: statisticsPageActions,
  reducer: statisticsPageReducer,
} = statisticsPageSlice;
