import { EBookChapterFactory } from '~/graphql/books/factory/book/EBookChapterFactory';
import { GraphqlEChapterWithContent } from '~/graphql/books/factory/book/EChapterWithContentFactoryTypes';
import { EbookChapter } from '~/graphql/books/GraphQLGeneratedCode';

export class EChapterWithContentFactory {
  static create(chapter: EbookChapter): GraphqlEChapterWithContent {
    return {
      ...EBookChapterFactory.create(chapter),
      content: chapter.content,
    };
  }
}
