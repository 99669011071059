import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Book, BookSortingEnum, BookTypeEnum, CompleteStatusEnum,
} from '~/api/book/bookApiTypes';

export const partnersCPASliceName = 'partnersCPA';

const partnersCPASlice = createSlice({
  name: partnersCPASliceName,
  initialState: {
    books: [] as Book[],
    filter: {
      genres: [] as string[],
      isCanDownload: null as boolean,
      bookType: null as BookTypeEnum,
      completeStatus: null as CompleteStatusEnum,
      sortingType: BookSortingEnum.Bestseller,
    },
  },
  reducers: {
    changeBooks: (state, action: PayloadAction<Book[]>) => {
      state.books = action.payload;
    },
    updateFilter: (state, action: PayloadAction<{
      genres?: string[],
      isCanDownload?: boolean,
      bookType?: BookTypeEnum,
      bookCompleteStatus?: CompleteStatusEnum,
      sortingType?: BookSortingEnum,
    }>) => {
      state.filter = { ...state.filter, ...action.payload };
    },
  },
});

export const {
  actions: partnersCPAActions,
  reducer: partnersCPAReducer,
} = partnersCPASlice;
