/* eslint-disable max-len */
import React, { SVGAttributes } from 'react';

import { BookReaderThemeEnum } from '~/atomic/page/book-reader/reader.slice';

import { SCSVGIcon } from './SCCIcon';

export const LogoIcon = ({ theme, ...props }: SVGAttributes<SVGSVGElement> & { theme?: BookReaderThemeEnum }) => {
  return (
    <SCSVGIcon {...props} viewBox="0 0 3943.68 750.92" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <style dangerouslySetInnerHTML={{
          __html: `
          .cls-1{fill:none;}.cls-2{clip-path:url(#clip-path);}.cls-3{fill:#438dfb;}.cls-4{fill:${theme === BookReaderThemeEnum.Dark ? '#ffffff' : 'var(--black-color)'};}.cls-5{fill:#439bfb;}
        `,
        }}
        />
        <clipPath id="clip-path" transform="translate(-403.32 -506.08)"><rect className="cls-1" width="4464" height="1807" /></clipPath>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g className="cls-2">
            <g className="cls-2">
              <path className="cls-3" d="M985.42,1143.89S819.9,978.36,455.74,1088.72c0,0,2.76,80-52.42,168.28,0,0,331.06-137.94,568.31,0,0,0,111.05-121.57,204.85-5.71,0,0,312-63.37,538.66-46.31,0,0-287.61-29.25-533.84,2.36,0,0-93.8-126.9-209.67,0,0,0-237.25-104.83-485.54-16.55,0,0,11-33.11,11-60.69,0,0,154.49-93.8,488.3,13.79" transform="translate(-403.32 -506.08)" />
              <path className="cls-3" d="M605.45,809.18s354.89-34.9,468.65,312.12c0,0,2.44-526.48-532.92-481Z" transform="translate(-403.32 -506.08)" />
              <path className="cls-3" d="M916.47,662.73s216.73,108.21,171.6,450.52c0,0,149.1-452.88-155.33-607.17Z" transform="translate(-403.32 -506.08)" />
              <path className="cls-3" d="M466.23,935l107.69,69.54s236.08-86.46,485.42,116c0,0-215.68-386.27-593.11-185.56" transform="translate(-403.32 -506.08)" />
              <path className="cls-4" d="M1433.9,724.39c97.51,0,173,74.8,173,188.34s-76.13,191.67-173,191.67c-60.78,0-104.19-27.38-128.23-59.44v53.43h-76.14l.26-463.1,75.88-31.11v181c24-33.4,70.12-60.78,128.23-60.78m-16.7,66.12c-56.77,0-111.53,44.74-111.53,123.55,0,79.48,54.76,124.22,111.53,124.22,57.44,0,112.2-46.08,112.2-125.55s-54.76-122.22-112.2-122.22" transform="translate(-403.32 -506.08)" />
              <path className="cls-4" d="M1845.29,1104.4c-104.85,0-184.33-74.8-184.33-190.34s82.81-189.67,187.67-189.67,187.67,74.13,187.67,189.67-85.49,190.34-191,190.34m0-66.12c56.77,0,112.87-40.74,112.87-124.22s-54.1-123.55-110.87-123.55c-57.43,0-108.86,40.07-108.86,123.55s49.42,124.22,106.86,124.22" transform="translate(-403.32 -506.08)" />
              <path className="cls-4" d="M2274.71,1104.4c-104.86,0-184.33-74.8-184.33-190.34s82.81-189.67,187.67-189.67,187.66,74.13,187.66,189.67-85.48,190.34-191,190.34m0-66.12c56.77,0,112.86-40.74,112.86-124.22s-54.09-123.55-110.86-123.55c-57.44,0-108.86,40.07-108.86,123.55s49.42,124.22,106.86,124.22" transform="translate(-403.32 -506.08)" />
              <polygon className="cls-4" points="2140.82 129.21 2217.32 98.1 2217.32 385.28 2350.89 224.32 2456.41 224.32 2286.78 408.65 2456.41 592.31 2353.56 592.31 2217.32 434.03 2217.32 592.31 2141.18 592.31 2140.82 129.21" />
              <path className="cls-4" d="M2994,1098.39h-76.13v-368H2994v53.43c21.37-36.73,58.11-59.44,110.2-59.44V803.2h-19.37c-56.1,0-90.83,23.37-90.83,101.51Z" transform="translate(-403.32 -506.08)" />
              <path className="cls-4" d="M3167.59,632.89c0-27.38,21.37-48.75,48.76-48.75,26.71,0,48.08,21.37,48.08,48.75s-21.37,48.76-48.08,48.76c-27.39,0-48.76-21.37-48.76-48.76m10,97.51h76.14v368h-76.14Z" transform="translate(-403.32 -506.08)" />
              <polygon className="cls-4" points="2992 224.32 3096.19 524.19 3200.38 224.32 3281.19 224.32 3140.94 592.31 3050.11 592.31 2910.52 224.32 2992 224.32" />
              <path className="cls-4" d="M3903.55,1104.4c-105.52,0-183.66-74.8-183.66-190.34s74.8-189.67,183.66-189.67c104.86,0,179,72.8,179,181a235.28,235.28,0,0,1-2.67,37.4H3798.7c5.34,60.78,48.75,97.51,104.85,97.51,46.75,0,72.8-22.71,86.82-51.43h82.15c-20.7,64.12-78.81,115.54-169,115.54M3799.37,881.34h203.69c-1.33-56.77-46.08-92.83-102.85-92.83-51.42,0-92.83,34.72-100.84,92.83" transform="translate(-403.32 -506.08)" />
              <path className="cls-4" d="M4236.8,1098.39h-76.13v-368h76.13v53.43c21.37-36.73,58.11-59.44,110.2-59.44V803.2h-19.37c-56.1,0-90.83,23.37-90.83,101.51Z" transform="translate(-403.32 -506.08)" />
              <path className="cls-5" d="M3268.63,633.82a53.42,53.42,0,1,1-53.42-53.42,53.42,53.42,0,0,1,53.42,53.42" transform="translate(-403.32 -506.08)" />
            </g>
          </g>
        </g>
      </g>
    </SCSVGIcon>
  );
};
