import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const accountReplenishmentSliceName = 'accountReplenishment';

export const MIN_ACCOUNT_REPLENISHMENT_AMOUNT = 200;
export const MAX_ACCOUNT_REPLENISHMENT_AMOUNT = 5000;

export const accountReplenishment = createSlice({
  name: accountReplenishmentSliceName,
  initialState: {
    isOpenModal: false,
    amount: MIN_ACCOUNT_REPLENISHMENT_AMOUNT,
    currentStage: 'chooseAmount' as 'chooseAmount' | 'choosePayment',
  },
  reducers: {
    setIsOpenModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenModal = action.payload;
    },
    changeAmount: (state, action: PayloadAction<number>) => {
      state.amount = action.payload;
    },
  },
});

export const {
  actions: accountReplenishmentActions,
  reducer: accountReplenishmentReducer,
} = accountReplenishment;
