import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const uploadBookSliceName = 'uploadBook';

const uploadBookSlice = createSlice({
  name: uploadBookSliceName,
  initialState: {
    fileName: '',
    isLoading: false,
  },
  reducers: {
    changeFileName: (state, action: PayloadAction<string>) => {
      state.fileName = action.payload;
    },
    onStartLoading: (state) => { state.isLoading = true; },
    onEndLoading: (state) => { state.isLoading = false; },
  },
});

export const { actions: uploadBookActions, reducer: uploadBookReducer } = uploadBookSlice;
