import React, { FC } from 'react';
import styled from 'styled-components';

import { AvatarImage } from '~/api/account/accountApiTypes';
import { MiniatureSizesEnum } from '~/api/book/bookApiTypes';
import { DefaultAvatar } from '~/atomic/atom/Avatar/DefaultAvatar';
import { getImageUrlBySize, sortByType } from '~/atomic/atom/SimpleCover';
import { isSupportWebpSelector } from '~/feature/supportWebp/isSupportWebp.selector';
import { useAppSelector } from '~/store';

export interface AvatarProps extends React.HTMLAttributes<HTMLDivElement> {
  src?: string;
  imageSize: number;
  avatarSize?: MiniatureSizesEnum;
  alt?: string;
  avatarImages?: AvatarImage[];
  userName?: string;
}

export const Avatar: FC<AvatarProps> = ({
  alt,
  avatarSize,
  avatarImages,
  imageSize,
  src: srcFromProps,
  userName,
  ...props
}) => {
  let src = null;

  if (avatarImages && avatarImages.length) {
    const { jpeg, webp } = sortByType(avatarImages);
    const isSupportWebp = useAppSelector(isSupportWebpSelector);

    if (avatarSize === MiniatureSizesEnum.Original) {
      src = getImageUrlBySize(jpeg, avatarSize);
    } else if (isSupportWebp) {
      src = getImageUrlBySize(webp, avatarSize);
    } else {
      src = getImageUrlBySize(jpeg, avatarSize);
    }
  }

  if (!src && !srcFromProps) {
    return (
      <DefaultAvatar
        imageSize={imageSize}
        userName={userName}
        {...props}
      />
    );
  }

  return (
    <SCAvatar
      {...props}
      style={{ ['--size' as any]: `${imageSize}px` }}
    >
      <img width={imageSize} height={imageSize} src={srcFromProps ?? src} alt={alt ?? 'Аватарка'} />
    </SCAvatar>
  );
};

const SCAvatar = styled.div`
  width: var(--size);
  height: var(--size);
  line-height: var(--size);
  display: inline-flex;
  vertical-align: middle;
  text-align: center;
  margin: 0;
  padding: 0;
  border-radius: 50%;
  overflow: hidden;
  background-color: hsla(0,0%,74.5%,.2);
  
  > img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;
