import { createSelector } from '@reduxjs/toolkit';

import { CompleteStatusEnum } from '~/api/book/bookApiTypes';
import { bookPageSelector, currentGraphqlBookSelector } from '~/feature/book/book.selector';
import { isHaveBuyPublishChaptersSelector } from '~/feature/chapter/chapter.selector';
import { RootState } from '~/store';

export const buyBookSelector = (state: RootState) => state.buyBook;

export const isCanNotBuyCurrentBookSelector = createSelector(
  currentGraphqlBookSelector,
  isHaveBuyPublishChaptersSelector,
  (
    {
      free,
      myBook,
      statusComplete,
    },
    isHavePainChapters,
  ) => {
    return free || statusComplete === CompleteStatusEnum.Freeze
      || statusComplete === CompleteStatusEnum.Reference
      || (myBook && myBook?.bought)
      || (!isHavePainChapters);
  },
);

export const isCanNotBuyBookPageSelector = createSelector(
  bookPageSelector,
  (book) => {
    return !book?.ebook?.canBuy;
  },
);
