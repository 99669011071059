import { createGlobalStyle } from 'styled-components';

export const GlobalCss = createGlobalStyle`
  .ant-scrolling-effect {
    width: calc(100%) !important;
  }
  
  #nprogress .bar {
    height: 4px;
    background: var(--primary-color);
  }

  #nprogress .peg {
    box-shadow: 0 0 10px var(--primary-color), 0 0 5px var(--primary-color);
  }
`;
