import React from 'react';
import styled from 'styled-components';

import { SCNavItem, SCNavMenuIcon } from '~/atomic/organism/NavMenu';
import { TextBoldStyle } from '~/atomic/Typography';
import { logout } from '~/feature/authorization/auth.data';
import { useAppDispatch } from '~/store';

export const ExitNavigationLink = () => {
  const dispatch = useAppDispatch();

  return (
    <SCNavItem>
      <SCExitLabel
        onClick={(event) => {
          event.persist();
          event.stopPropagation();
          dispatch(logout());
        }}
      >
        <SCNavMenuIcon src="/icon/exit.svg" />
        Выход
      </SCExitLabel>
    </SCNavItem>
  );
};

const SCExitLabel = styled.span`
  ${TextBoldStyle};
  cursor: pointer;
  color: var(--gray-color);
  display: inline-flex;
  align-items: center;
  padding: 15px 16px;
  transition: color 0.3s ease;
  
  &:hover {
    color: var(--primary-hover-color);
  }
`;
