import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const isSupportWebpSlice = createSlice({
  name: 'isSupportWebp',
  initialState: { isSupport: null },
  reducers: {
    changeisSupportWebp: (state, action: PayloadAction<boolean>) => {
      state.isSupport = action.payload;
    },
  },
});

export const { reducer: isSupportWebpReducer, actions: isSupportWebpActions } = isSupportWebpSlice;
