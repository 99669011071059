import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const heroImageSliceName = 'heroImageAuthor';

const heroImageSlice = createSlice({
  name: heroImageSliceName,
  initialState: {
    isDeleteLoading: false,
    isValidHeroImage: false,
    isLoading: false,
  },
  reducers: {
    setIsDeleteLoading: (state, action: PayloadAction<boolean>) => {
      state.isDeleteLoading = action.payload;
    },
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsValidHeroImage: (state, action: PayloadAction<boolean>) => {
      state.isValidHeroImage = action.payload;
    },
  },
});

export const {
  actions: heroImageActions,
  reducer: heroImageReducer,
} = heroImageSlice;
