import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const ageLimitSliceName = 'ageLimit';

const ageLimitSlice = createSlice({
  name: ageLimitSliceName,
  initialState: { isAdult: null as boolean },
  reducers: {
    changeIsAdult: (state, action: PayloadAction<boolean>) => {
      state.isAdult = action.payload;
    },
  },
});

export const { actions: ageLimitActions, reducer: ageLimitReducer } = ageLimitSlice;
