import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Book, BookLayoutEnum, BookSortingEnum,
} from '~/api/book/bookApiTypes';
import {
  FeedGenreType, FeedSlider, FeedTabEnum, GenreTags,
} from '~/api/feed/feedApiTypes';
import { BannerType } from '~/atomic/page/index/feed.data';
import { getGenreSlugByName } from '~/feature/genre/genres';
import { nestedGenres } from '~/feature/genre/nestedGenres';

export const feedSliceName = 'feed';

const defaultFeedGenresSlugs = [
  getGenreSlugByName('Фантастика'),
  getGenreSlugByName('Любовный роман'),
  getGenreSlugByName('Детектив'),
  getGenreSlugByName('Попаданцы'),
  getGenreSlugByName('Молодежная проза'),
  getGenreSlugByName('Фэнтези'),
  getGenreSlugByName('Мистика'),
  getGenreSlugByName('Эротика'),
];

export const defaultFeedGenres = defaultFeedGenresSlugs
  .map((genreSlug) => {
    const nestedGenre = nestedGenres
      .find((nestedGenre) => nestedGenre.slug === genreSlug);

    return {
      ...nestedGenre,
      isAllSubgenresActive: true,
      isAllSubgenresInactive: false,
      childs: nestedGenre.childs
        .filter((_, index) => index < 10)
        .map((childGenre) => ({
          ...childGenre,
          isActive: true,
        })),
      isActive: true,
    };
  }) as FeedGenreType[];

export type FavoriteGenreFromCookieType = {
  i: string;
  c: string;
};

export const feedSlice = createSlice({
  name: feedSliceName,
  initialState: {
    sliders: [] as FeedSlider[],
    isGedFeedLoading: false,
    isLoadMoreBestsellersLoading: false,
    genres: defaultFeedGenres,
    genresFromCookie: [] as FavoriteGenreFromCookieType[],
    loadMorePage: 1,
    genresTags: [] as GenreTags[],
    editGenre: {} as FeedGenreType,
    isOpenEditGenreModal: false,
    isUpdateGenres: true,
    isShowEroticGenreBLock: false,
    randomMainBanners: [] as BannerType[],
    randomSidebarBanners: [] as BannerType[],
    feedBooks: [] as Array<Book>,
    feedTab: FeedTabEnum.Popularity as FeedTabEnum,
    isLoadFeedBooks: false,
    isLoadMoreFeedBooksLoading: false,
    recommendationLayout: BookLayoutEnum.GRID,
    recommendationSortingType: BookSortingEnum.RecommendationScore,
    isDisableShowMore: false,

  },
  reducers: {
    addSliders: (state, action: PayloadAction<FeedSlider[]>) => {
      state.sliders = action.payload;
    },
    setIsUpdateGenres: (state, action: PayloadAction<boolean>) => {
      state.isUpdateGenres = action.payload;
    },
    setIsShowEroticGenreBLock: (state, action: PayloadAction<boolean>) => {
      state.isShowEroticGenreBLock = action.payload;
    },
    changeGenres: (state, action: PayloadAction<Array<FeedGenreType>>) => {
      state.genres = action.payload;
    },
    changeGenresFromCookie: (state, action: PayloadAction<Array<FavoriteGenreFromCookieType>>) => {
      state.genresFromCookie = action.payload;
    },
    setIsGedFeedLoading: (state, action: PayloadAction<boolean>) => {
      state.isGedFeedLoading = action.payload;
    },
    setIsLoadMoreBestsellersLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoadMoreBestsellersLoading = action.payload;
    },
    changeLoadMorePage: (state, action: PayloadAction<number>) => {
      state.loadMorePage = action.payload;
    },
    changeGenresTags: (state, action: PayloadAction<Array<GenreTags>>) => {
      state.genresTags = action.payload;
    },
    changeEditGenre: (state, action: PayloadAction<FeedGenreType>) => {
      state.editGenre = action.payload;
    },
    changeRandomMainBanners: (state, action: PayloadAction<Array<BannerType>>) => {
      state.randomMainBanners = action.payload;
    },
    changeRandomSidebarBanners: (state, action: PayloadAction<Array<BannerType>>) => {
      state.randomSidebarBanners = action.payload;
    },
    setIsEditGenreModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenEditGenreModal = action.payload;
    },
    changeFeedBooks: (state, action: PayloadAction<Array<Book>>) => {
      state.feedBooks = action.payload;
    },
    changeFeedTab: (state, action: PayloadAction<FeedTabEnum>) => {
      state.feedTab = action.payload;
    },
    setIsLoadFeedBooks: (state, action: PayloadAction<boolean>) => {
      state.isLoadFeedBooks = action.payload;
    },
    setIsLoadMoreFeedBooksLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoadMoreFeedBooksLoading = action.payload;
    },
    changeRecommendationSortingType: (state, action: PayloadAction<BookSortingEnum>) => {
      state.recommendationSortingType = action.payload;
    },
    changeRecommendationLayout: (state, action: PayloadAction<BookLayoutEnum>) => {
      state.recommendationLayout = action.payload;
    },
    setIsDisableShowMore: (state, action: PayloadAction<boolean>) => {
      state.isDisableShowMore = action.payload;
    },
  },
});

export const { actions: feedActions, reducer: feedReducer } = feedSlice;
