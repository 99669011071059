import throttle from 'lodash.throttle';
import React, {
  FC, useEffect,
  useRef,
} from 'react';
import styled from 'styled-components';

import { TextRegStyle } from '~/atomic/Typography';
import { environments } from '~/lib/const';
import { lessThan } from '~/lib/mediaQuery';
import textFit from '~/lib/textFit';

interface DefaultBookCoverProps {
  authorName: string;
  bookName: string;
  // eslint-disable-next-line react/require-default-props
  font?: {
    min: number;
    max: number;
  }
}

export const DefaultBookCover:FC<DefaultBookCoverProps> = ({
  authorName, bookName, font,
} = {
  authorName: 'Имя Автора',
  bookName: 'Название Книги',
  font: null,
}) => {
  const authorRef = useRef<HTMLDivElement>(null);
  const bookRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const fitText = () => {
    if (authorRef.current && bookRef.current && environments.isClient) {
      try {
        textFit(
          authorRef.current,
          {
            maxFontSize: font?.max ?? 16,
            minFontSize: font?.min ?? 6,
            multiline: true,
            widthOnly: true,
            reProcess: true,
          },
        );
        textFit(bookRef.current, {
          maxFontSize: font?.max ?? 16,
          minFontSize: font?.min ?? 6,
          multiline: true,
          widthOnly: true,
          reProcess: true,
        });
      } catch (e) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    fitText();

    const onResizeEventHandler = () => {
      fitText();
    };

    const throttledHandler = throttle(onResizeEventHandler, 1000);

    window.addEventListener('resize', throttledHandler, { passive: true });

    return () => {
      window.removeEventListener('resize', throttledHandler);
    };
  }, []);

  return (
    <SCDefaultBookCover ref={containerRef}>
      <SCDefaultBookCoverContent>
        <SCAuthorName ref={authorRef}>
          {authorName}
        </SCAuthorName>
        <SCBookName ref={bookRef}>
          {bookName}
        </SCBookName>
      </SCDefaultBookCoverContent>
    </SCDefaultBookCover>
  );
};

export const SCDefaultBookCoverContent = styled.div`
  overflow: hidden;
  display: flex;
  align-items: center;
  flex-direction: column;
  background-color: #E6E8E9;
  background-image: url("/image/default-cover.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  flex-shrink: 0;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
`;

export const SCDefaultBookCover = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: var(--image-aspect); /* Aspect Ratio */

  &:before {
    content: "";
    position: absolute;
    bottom: -7px;
    left: 50%;
    transform: translateX(-50%);
    height: 18px;
    width: 92%;
    background: #D1D2D2;
    z-index: 1;
    border-radius: 0 0 8px 8px;

    ${lessThan('sm')} {
      bottom: -5px;
      display: none;
    }
  }

  &::after {
    content: "";
    position: absolute;
    bottom: -11px;
    left: 50%;
    transform: translateX(-50%);
    height: 18px;
    width: 86%;
    background: #EFEFEF;
    z-index: 0;
    border-radius: 0 0 8px 8px;

    ${lessThan('sm')} {
      bottom: -9px;
      display: none;
    }
  }
`;

export const SCAuthorName = styled.span`
  ${TextRegStyle};
  
  position: absolute;
  top: 5%;
  width: 80%;
  height: 20%;
  text-align: center;
  color: var(--black-color);
  line-height: unset;
`;

export const SCBookName = styled.span`
  ${TextRegStyle};
  position: absolute;
  bottom: 30%;
  max-width: 85%;
  width: 80%;
  height: 35%;
  text-align: center;
  color: var(--black-color);
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 500;
  line-height: unset;
`;
