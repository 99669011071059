import React, { FC } from 'react';
import styled from 'styled-components';

import { AntdSlider } from '~/atomic/atom/AntdSlider';
import { TextRegStyle, TextSmallStyle } from '~/atomic/Typography';
import {
  audioPlayerActions,
  audioPlayerActiveChapterSelector,
  audioPlayerSelector,
} from '~/feature/audio/audioPlayer.slice';
import { lessThan } from '~/lib/mediaQuery';
import { useAppDispatch, useAppSelector } from '~/store';

const millisecondsInSecond = 1000;
const millisecondsInMinute = millisecondsInSecond * 60;
const millisecondsInHour = millisecondsInMinute * 60;

export const showAudioPlayerTime = (timeInMilliseconds: number) => {
  let timeInHour = Math.floor(timeInMilliseconds / millisecondsInHour) ?? 0;

  const timeWithoutHour = timeInMilliseconds - (timeInHour * millisecondsInHour);

  let timeInMinutes = Math.floor(
    timeInHour > 0
      ? timeWithoutHour / millisecondsInMinute
      : timeInMilliseconds / millisecondsInMinute,
  );

  const timeWithoutMinutesAndHour = timeInMilliseconds - (timeInHour > 0
    ? (timeInMinutes * millisecondsInMinute) + (timeInHour * millisecondsInHour)
    : (timeInMinutes * millisecondsInMinute));

  let timeInSeconds = Math.floor(
    timeWithoutMinutesAndHour / millisecondsInSecond,
  );

  if (Number.isNaN(timeInHour)) {
    timeInHour = 0;
  }
  if (Number.isNaN(timeInMinutes)) {
    timeInMinutes = 0;
  }
  if (Number.isNaN(timeInSeconds)) {
    timeInSeconds = 0;
  }

  return `0${
    timeInHour}:${
    timeInMinutes > 9 ? timeInMinutes : `0${timeInMinutes}`}:${
    timeInSeconds > 9 ? timeInSeconds : `0${timeInSeconds}`}`;
};

const getTimeOfPercent = (percent: number, time: number) => {
  return Math.floor(time * (percent / 100));
};

const getPercentOfTime = (time: number, currentTime: number) => {
  return Number((currentTime / (time / 100)).toFixed(2));
};

interface AudioPlayerProgressProps {
  audioPlayer: HTMLAudioElement;
}

export const AudioPlayerProgress: FC<AudioPlayerProgressProps> = ({ audioPlayer }) => {
  const dispatch = useAppDispatch();
  const {
    audioTime,
    currentAudioTime,
  } = useAppSelector(audioPlayerSelector);
  const activeChapter = useAppSelector(audioPlayerActiveChapterSelector);

  const changeTimeHandler = (percent: number) => {
    const currentTime = getTimeOfPercent(percent, audioTime);
    audioPlayer.pause();
    dispatch(audioPlayerActions.setIsPlayed(false));
    dispatch(audioPlayerActions.changeCurrentAudioTime(currentTime));
  };

  const afterChangeTimeHandler = (percent: number) => {
    const currentTime = getTimeOfPercent(percent, audioTime);
    audioPlayer.currentTime = currentTime / 1000;
    audioPlayer.play();
    dispatch(audioPlayerActions.setIsPlayed(true));
  };

  return (
    <SCAudioPlayerProgress>
      <SCProgressChapter>
        {activeChapter?.name}
      </SCProgressChapter>
      <SCProgressIndicatorWrapper>
        <SCCurrentTime>
          {showAudioPlayerTime(currentAudioTime)}
        </SCCurrentTime>
        <SCSlider
          step={0.01}
          value={getPercentOfTime(audioTime, currentAudioTime)}
          tipFormatter={(percent) => {
            return (
              <span>
                {showAudioPlayerTime(getTimeOfPercent(percent ?? 0, audioTime))}
              </span>
            );
          }}
          onChange={changeTimeHandler}
          onAfterChange={afterChangeTimeHandler}
          trackStyle={{ backgroundColor: 'var(--primary-color)' }}
          handleStyle={{
            backgroundColor: 'var(--primary-color)',
            border: '1px solid var(--primary-color)',
            boxShadow: 'none',
            width: '18px',
            height: '18px',
            marginTop: '-7px',
          }}
        />
        <SCAllTime>
          {showAudioPlayerTime(audioTime)}
        </SCAllTime>
      </SCProgressIndicatorWrapper>
    </SCAudioPlayerProgress>
  );
};

const SCAudioPlayerProgress = styled.div`
  padding: 6px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  max-width: 700px;

  ${lessThan('sm')} {
    order: 3;
    padding: 6px 12px;
  }
`;

const SCProgressChapter = styled.span`
  ${TextRegStyle};
  font-size: 15px;
  color: var(--white-color);
`;

const SCProgressIndicatorWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const SCCurrentTime = styled.span`
  ${TextSmallStyle};
  color: var(--white-color);
`;

const SCAllTime = styled.span`
  ${TextSmallStyle};
  color: var(--white-color);
`;

const SCSlider = styled(AntdSlider)`
  width: 100%;
  margin-right: 13px;
  margin-left: 13px;
`;
