import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const reactionSliceName = 'reaction';

const reactionSlice = createSlice({
  name: reactionSliceName,
  initialState: { isLoadLibGif: false },
  reducers: {
    setIsLoadLibGif: (state, action: PayloadAction<boolean>) => {
      state.isLoadLibGif = action.payload;
    },
  },
});

export const {
  actions: reactionActions,
  reducer: reactionReducer,
} = reactionSlice;
