import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { WidgetSettings } from '~/api/finance/marketing/partnersBooksApiTypes';

export const partnersWidgetSliceName = 'partnersWidget';

export const partnersWidgetSlice = createSlice({
  name: partnersWidgetSliceName,
  initialState: {
    settings: {} as WidgetSettings,
    partnerCode: '',
  },
  reducers: {
    changeSettings: (state, action: PayloadAction<WidgetSettings>) => {
      state.settings = action.payload;
    },
    changePartnerCode: (state, action: PayloadAction<string>) => {
      state.partnerCode = action.payload;
    },
  },
});

export const {
  actions: partnersWidgetActions,
  reducer: partnersWidgetReducer,
} = partnersWidgetSlice;
