import { Book } from '~/api/book/bookApiTypes';
import { GetOperationsProps, Operation } from '~/api/finance/operationApiTypes';
import { handleRejectedRequest } from '~/api/provider/providerErrors';
import { PaginatedResponse, RejectedRequest } from '~/api/provider/providerTypes';

import { provider } from '../provider/provider.config';

const namespace = Symbol('namespace');

export const operationApi = {
  [namespace]: '/v1/finance',

  async getOperations(data: GetOperationsProps) {
    try {
      const response = await provider.get<
      PaginatedResponse<Operation[], RejectedRequest<void>>
      >(`${this[namespace]}/me/operations`, { params: data });
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getOperationBooks() {
    try {
      const response = await provider.get<
      PaginatedResponse<Book[], RejectedRequest<void>>
      >(
        `${this[namespace]}/operation/books`,
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

};
