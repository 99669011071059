import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import relativeTime from 'dayjs/plugin/relativeTime';
import updateLocale from 'dayjs/plugin/updateLocale';
import React, { FC } from 'react';

import { useIsClientRender } from '~/lib/hooks/useIsClientRender';

require('dayjs/locale/ru');

dayjs.extend(updateLocale);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat);

// @ts-ignore
const dateListFormatter = dayjs.updateLocale('ru', {})?.relativeTime?.mm ?? '';
const DAY_IN_MILLISECONDS = 1000 * 60 * 59 * 24;

const dateFormatter = (date: Date | string) => {
  return dayjs(date).locale('ru').format('D MMM в H:mm');
};

const dateFormatterWithYear = (date: Date | string) => {
  return dayjs(date).locale('ru').format('DD.MM.YY');
};

dayjs.updateLocale('ru', {
  relativeTime: {
    past: '%s назад',
    s(number, withoutSuffix, key, isFuture) {
      return 'сейчас';
    },
    m: () => 'минуту назад',
    mm(number, withoutSuffix, key, isFuture) {
      return dateListFormatter(number, withoutSuffix, key).concat(' назад');
    },
    h: () => 'час назад',
    hh(number, withoutSuffix, key, isFuture) {
      return dateListFormatter(number, withoutSuffix, key).concat(' назад');
    },
    d(number, withoutSuffix, key, isFuture) {
      return String(number).concat(' часа назад');
    },
    dd(number, withoutSuffix, key, isFuture) {
      return number;
    },
    M(number, withoutSuffix, key, isFuture) {
      return number;
    },
    MM(number, withoutSuffix, key, isFuture) {
      return number;
    },
    y(number, withoutSuffix, key, isFuture) {
      return number;
    },
    yy(number, withoutSuffix, key, isFuture) {
      return number;
    },
  },
});

interface DateFormatProps {
  date: Date | string | number;
}

export const DateFormatComponent: FC<DateFormatProps> = ({ date }) => {
  const isClient = useIsClientRender();

  if (!isClient) return null;

  return (
    <>
      {dateFormatText(date)}
    </>
  );
};

export const dateFormatText = (date: Date | string | number) => {
  const dateNowNumber = Date.now();
  const timeZoneOffset = (new Date()).getTimezoneOffset();

  let dateNumber;

  if (typeof date === 'string') {
    const parseString = dayjs(date, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format();
    dateNumber = Date.parse(parseString);
  } else if (date instanceof Date) {
    dateNumber = Number(date);
  } else {
    dateNumber = date;
  }

  const dateWithTimezoneOffset = dateNumber + timeZoneOffset;
  const betweenMillisecond = dateNowNumber - dateWithTimezoneOffset;

  if (betweenMillisecond > DAY_IN_MILLISECONDS * 365) {
    return dateFormatterWithYear(dateWithTimezoneOffset);
  }

  if (betweenMillisecond < DAY_IN_MILLISECONDS) {
    return dayjs(dateWithTimezoneOffset).locale('ru').fromNow(true);
  }

  return dateFormatter(dateWithTimezoneOffset);
};
