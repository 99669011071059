import React from 'react';
import styled from 'styled-components';

import { Button, SCButton } from '~/atomic/atom/Button';
import { TextBoldStyle } from '~/atomic/Typography';
import { openModal } from '~/feature/authorization/authorizationModal.slice';
import { ym } from '~/feature/yandex/YandexMetrikaInit';
import { useAppDispatch } from '~/store';

export const RegisterPopup = ({ children }) => {
  const dispatch = useAppDispatch();

  return (
    <SCRegisterPopup>
      {children}
      <SCRegisterPopupContent>
        <SCText>
          Войдите в свой профиль и узнайте, что нового произошло за последнее время
        </SCText>
        <SCBtns>
          <SCLoginButton>
            <Button
              type="primary"
              onClick={(e) => {
                ym('reachGoal', 'header:bell-LogIn');
                dispatch(openModal('login'));
              }}
            >
              Войти
            </Button>
          </SCLoginButton>
          <SCRegisterButton>
            <Button
              onClick={(e) => {
                ym('reachGoal', 'header:bell-reg');
                dispatch(openModal('register'));
              }}
            >
              Зарегистрироваться
            </Button>
          </SCRegisterButton>
        </SCBtns>
      </SCRegisterPopupContent>
    </SCRegisterPopup>
  );
};

const SCRegisterButton = styled.div`
  ${SCButton} {
    border: 1px solid var(--white-color);
  }
`;

const SCLoginButton = styled.div`
  ${SCButton} {
    width: 182px;
    margin-right: 32px;
  }
`;

const SCBtns = styled.div`
  display: flex;
  align-items: center;
  margin-top: 20px;
`;

const SCText = styled.span`
  ${TextBoldStyle};
  color: var(--black-color);
`;

export const SCRegisterPopupContent = styled.div`
  opacity: 0;
  pointer-events: none;
  position: absolute;
  z-index: 2;
  background: #FFFFFF;
  border: 1px solid #DCE3E4;
  box-sizing: border-box;
  box-shadow: 0 14px 10px rgba(0, 0, 0, 0.04);
  max-width: 420px;
  width: max-content;
  top: 40px;
  left: 50%;
  transform: translateX(-50%);
  padding: 16px 16px 20px;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;

  &:after {
    content: ''; 
    display: block;
    position: absolute;
    width: 100%;
    top: -20px;
    left: 0;
    height: 20px;
  }
`;

const SCRegisterPopup = styled.div`
  position: relative;
`;
