import { GenreWithChild } from '~/api/book/genreApiTypes';

export const nestedGenres: GenreWithChild[] = [
  {
    name: 'Фантастика',
    slug: 'fantastika',
    childs: [
      {
        name: 'Боевая фантастика',
        slug: 'boevaya-fantastika',
      },
      {
        name: 'Героическая фантастика',
        slug: 'geroicheskaya-fantastika',
      },
      {
        name: 'Научная фантастика',
        slug: 'nauchnaya-fantastika',
      },
      {
        name: 'Постапокалипсис',
        slug: 'postapokalipsis',
      },
      {
        name: 'Космическая фантастика',
        slug: 'kosmicheskaya-fantastika',
      },
      {
        name: 'Киберпанк',
        slug: 'kiberpank',
      },
      {
        name: 'Детективная фантастика',
        slug: 'detektivnaya-fantastika',
      },
      {
        name: 'Социальная фантастика',
        slug: 'sotsialnaya-fantastika',
      },
      {
        name: 'Альтернативная история',
        slug: 'alternativnaya-istoriya',
      },
      {
        name: 'ЛитРПГ',
        slug: 'litrpg',
      },
      {
        name: 'Боярь-аниме',
        slug: 'boyar-anime',
      },
      {
        name: 'Стимпанк',
        slug: 'stimpank',
      },
      {
        name: 'Юмористическая фантастика',
        slug: 'yumoristicheskaya-fantastika',
      },
    ],
  },
  {
    name: 'Фэнтези',
    slug: 'fentezi',
    childs: [
      {
        name: 'Боевое фэнтези',
        slug: 'boevoe-fentezi',
      },
      {
        name: 'Бытовое фэнтези',
        slug: 'bytovoe-fentezi',
      },
      {
        name: 'Героическое фэнтези',
        slug: 'geroicheskoe-fentezi',
      },
      {
        name: 'Городское фэнтези',
        slug: 'gorodskoe-fentezi',
      },
      {
        name: 'Историческое фэнтези',
        slug: 'istoricheskoe-fentezi',
      },
      {
        name: 'Тёмное фэнтези',
        slug: 'temnoe-fentezi',
      },
      {
        name: 'Славянское фэнтези',
        slug: 'slavyanskoe-fentezi',
      },
      {
        name: 'Юмористическое фэнтези',
        slug: 'yumoristicheskoe-fentezi',
      },
      {
        name: 'Приключенческое фэнтези',
        slug: 'priklyuchencheskoe-fentezi',
      },
      {
        name: 'Эпическое фэнтези',
        slug: 'epicheskoe-fentezi',
      },
      {
        name: 'Магические академии',
        slug: 'magicheskie-akademii',
      },
    ],
  },
  {
    name: 'Попаданцы',
    slug: 'popadantsy',
    childs: [],
  },
  {
    name: 'Любовный роман',
    slug: 'lyubovnyi-roman',
    childs: [
      {
        name: 'Современный любовный роман',
        slug: 'sovremennyi-lyubovnyi-roman',
      },
      {
        name: 'Любовная фантастика',
        slug: 'lyubovnaya-fantastika',
      },
      {
        name: 'Любовное фэнтези',
        slug: 'lyubovnoe-fentezi',
      },
      {
        name: 'Остросюжетный любовный роман',
        slug: 'ostrosyuzhetnyi-lyubovnyi-roman',
      },
      {
        name: 'Исторический любовный роман',
        slug: 'istoricheskii-lyubovnyi-roman',
      },
      {
        name: 'Короткий любовный роман',
        slug: 'korotkii-lyubovnyi-roman',
      },
    ],
  },
  {
    name: 'Фанфик',
    slug: 'fanfik',
    childs: [],
  },
  {
    name: 'Детектив',
    slug: 'detektiv',
    childs: [
      {
        name: 'Исторический детектив',
        slug: 'istoricheskii-detektiv',
      },
      {
        name: 'Классический детектив',
        slug: 'klassicheskii-detektiv',
      },
      {
        name: 'Юмористический детектив',
        slug: 'yumoristicheskii-detektiv',
      },
      {
        name: 'Шпионский детектив',
        slug: 'shpionskii-detektiv',
      },
      {
        name: 'Политический детектив',
        slug: 'politicheskii-detektiv',
      },
      {
        name: 'Криминальный детектив',
        slug: 'kriminalnyi-detektiv',
      },
    ],
  },
  {
    name: 'Эротика',
    slug: 'erotika',
    childs: [
      {
        name: 'Романтическая эротика',
        slug: 'romanticheskaya-erotika',
      },
      {
        name: 'Эротическое фэнтези',
        slug: 'eroticheskoe-fentezi',
      },
      {
        name: 'Эротическая фантастика',
        slug: 'eroticheskaya-fantastika',
      },
      {
        name: 'Эротический фанфик',
        slug: 'eroticheskii-fanfik',
      },
      {
        name: 'Жёсткая эротика',
        slug: 'zhyostkaya-erotika',
      },
      {
        name: 'С элементами эротики',
        slug: 's-elementami-erotiki',
      },
    ],
  },
  {
    name: 'Молодежная проза',
    slug: 'molodezhnaya-proza',
    childs: [],
  },
  {
    name: 'Сказка',
    slug: 'skazki',
    childs: [],
  },
  {
    name: 'Стихи, поэзия',
    slug: 'stikhi-poeziya',
    childs: [],
  },
  {
    name: 'Боевик',
    slug: 'boevik',
    childs: [],
  },
  {
    name: 'Хоррор',
    slug: 'uzhasy',
    childs: [],
  },
  {
    name: 'Мистика',
    slug: 'mistika',
    childs: [],
  },
  {
    name: 'Современная проза',
    slug: 'sovremennaya-proza',
    childs: [],
  },
  {
    name: 'Историческая проза',
    slug: 'istoricheskaya-proza',
    childs: [],
  },
  {
    name: 'Триллер',
    slug: 'triller',
    childs: [],
  },
  {
    name: 'Драма',
    slug: 'drama',
    childs: [],
  },
  {
    name: 'Юмористический роман',
    slug: 'yumor',
    childs: [],
  },
  {
    name: 'Хобби, досуг',
    slug: 'khobbi-dosug',
    childs: [],
  },
  {
    name: 'Разное',
    slug: 'raznoe',
    childs: [
      {
        name: 'Детская литература',
        slug: 'detskaya-literatura',
      },
      {
        name: 'Бизнес-литература',
        slug: 'biznes-literatura',
      },
      {
        name: 'Развитие личности',
        slug: 'razvitie-lichnosti',
      },
      {
        name: 'Профессиональная литература',
        slug: 'professionalnaya-literatura',
      },
      {
        name: 'Учебная литература',
        slug: 'uchebnaya-literatura',
      },
      {
        name: 'Психология',
        slug: 'psikhologiya',
      },
      {
        name: 'Биографии и мемуары',
        slug: 'biografii-i-memuary',
      },
    ],
  },
];
