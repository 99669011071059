export const breakpoints = {
  xl: 1440,

  'container-width': 1200,

  lg: 1170,

  md: 1023,

  sm: 767,

  xs: 576,

  xxs: 440,

};

export type BreakpointsName = keyof typeof breakpoints;

export const lessThan = (breakpointsKey: BreakpointsName) => {
  return `@media only screen and (max-width: ${
    breakpoints[breakpointsKey]
  }px)`;
};

export const moreThan = (breakpointsKey: BreakpointsName) => {
  return `@media only screen and (min-width: ${
    breakpoints[breakpointsKey]
  }px)`;
};

export const lessAndMoreThan = (
  lessBreakpointsKey: BreakpointsName,
  moreBreakpointsKey: BreakpointsName,
) => {
  return `@media only screen and (max-width: ${
    breakpoints[lessBreakpointsKey]
  }px) and (min-width: ${
    breakpoints[moreBreakpointsKey]
  }px)`;
};
