import { MyBookStatusEnum } from '~/api/account/myBooksApiTypes';
import {
  AgeRatingEnum, CompleteStatusEnum, PublishStatusEnum,
} from '~/api/book/bookApiTypes';
import { BookReactionRatingEnum, GraphqlBook } from '~/graphql/books/factory/book/BookFactoryTypes';
import { AvatarImagesFactory } from '~/graphql/books/factory/image/AvatarImagesFactory';
import { CoverImagesFactory } from '~/graphql/books/factory/image/CoverImagesFactory';
import { BookFragment } from '~/graphql/books/GraphQLGeneratedCode';

export class BookFactory {
  static create(book: BookFragment): GraphqlBook {
    return {
      id: book.id,
      age: book.ageRating as AgeRatingEnum,
      allowToSellBookByChapter: book.allowToSellBookByChapter,
      annotation: book.annotation,
      audiobook: {
        bookId: book.audiobook.bookId,
        available: book.audiobook.isAvailableForDesktop,
        bought: book.audiobook.bought,
        canBuy: book.audiobook.canBuy,
        canListen: book.audiobook.canListen,
        discount: book.audiobook.discount,
        price: book.audiobook.price,
        purchase: book.audiobook.purchase === null ? null : {
          price: book.audiobook.purchase.price,
          boughtWithTimerDiscount: book.audiobook.purchase.boughtWithTimerDiscount,
        },
      },
      authorPages: book.authorPages,
      author: book.author,
      chapterPrice: book.chapterPrice,
      coverImages: CoverImagesFactory.create(book.coverImages),
      createdAt: book.createdAt,
      cycle: {
        id: book.cycle.id,
        name: book.cycle.name,
        slug: book.cycle.slug,
        numInCycle: book.cycle.numInCycle,
      },
      ebook: {
        bookId: book.ebook.bookId,
        available: book.ebook.available,
        bought: book.ebook.bought,
        canBuy: book.ebook.canBuy,
        price: book.ebook.price,
        discount: book.ebook.discount,
        purchase: book.ebook.purchase === null ? null : {
          price: book.ebook.purchase.price,
          boughtWithTimerDiscount: book.ebook.purchase.boughtWithTimerDiscount,
        },
        addedToAbonnement: book.ebook.addedToAbonnement,
      },
      exclusive: book.exclusive,
      free: book.free,
      genres: book.genres.map((genre) => ({
        id: genre.id,
        slug: genre.slug,
        name: genre.name,
      })),
      hideCover: book.hideCover,
      isAvailableEdit: book.isAvailableEdit,
      myBook: {
        bought: book.myBook.bought,
        note: book.myBook.note,
        hasReview: book.myBook.hasReview,
        symbols: book.myBook.symbols,
        reaction: {
          rating: book.myBook.reaction?.rating === null || book.myBook.reaction === null
            ? null
            : String(book.myBook.reaction.rating) as BookReactionRatingEnum,
        },
        readStatus: book.myBook.readStatus === null ? null : String(book.myBook.readStatus) as MyBookStatusEnum,
      },
      name: book.name,
      slug: book.slug,
      spentOnChapters: book.spentOnChapters,
      statusComplete: String(book.statusComplete) as CompleteStatusEnum,
      statusPublish: String(book.statusPublish) as PublishStatusEnum,
      symbols: book.symbols,
      type: book.type,
      updatedAt: book.updatedAt,
      redLine: book.redLine,
      partnerProgram: { percent: book?.partnerProgram?.percent ?? null },
      recommendation: 'recommendation' in book && book.recommendation?.totalRecommenders > 0 ? {
        feedbackRating: book.recommendation?.feedbackRating ?? null,
        totalRecommenders: book.recommendation?.totalRecommenders,
        recommenders: book.recommendation?.recommenders?.map((recommender) => ({
          name: recommender.name,
          username: recommender.username,
          avatarImages: AvatarImagesFactory.create(recommender.avatarImages),
        })),
      } : null,
    };
  }
}
