import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '~/store';

const userSelector = (state: RootState) => state.user;

export const isLoggedInSelector = createSelector(
  userSelector,
  ({ user }) => typeof user?.id !== 'undefined',
);
