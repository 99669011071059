import { ParsedUrlQueryInput } from 'querystring';
import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { ClockIcon } from '~/atomic/atom/icon/ClockIcon';
import { TimerDiscountBooksLink } from '~/atomic/atom/links/staticLinks';
import { TextBoldStyle } from '~/atomic/Typography';
import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import { getDiscountTime } from '~/feature/withTiwerDiscount/getDiscountTime';
import {
  isShowTimerDiscountSelector,
  withTimerDiscountSelector,
} from '~/feature/withTiwerDiscount/withTimerDiscount.selectors';
import { useAppSelector } from '~/store';

interface TimerDiscountBadgerProps extends HTMLAttributes<HTMLAnchorElement> {
  isShowText?: boolean;
  queries?: ParsedUrlQueryInput;
}

export const TimerDiscountBadger: FC<TimerDiscountBadgerProps> = ({
  isShowText = true,
  queries = {},
  ...props
}) => {
  const isMobileSize = useAppSelector(isMobileSizeSelector);
  const { timer } = useAppSelector(withTimerDiscountSelector);
  const isShowTimerDiscount = useAppSelector(isShowTimerDiscountSelector);
  const discountTimeLeft = getDiscountTime(timer.expiredAt);
  const isTrigger = discountTimeLeft < 2;

  if (!isShowTimerDiscount) {
    return <></>;
  }

  return (
    <TimerDiscountBooksLink
      query={queries}
    >
      <SCLink
        {...props}
      >
        <SCTimerDiscountBadger
          data-isTrigger={isTrigger}
        >
          <SCTime>
            {`${discountTimeLeft}ч`}
          </SCTime>
          <SCTimerDiscountBadgerIconWrapper>
            <SCTimerDiscountBadgerIcon />
          </SCTimerDiscountBadgerIconWrapper>
          <SCDiscount>
            {`${timer.percent}% ${isMobileSize || !isShowText ? '' : 'скидка'}`}
          </SCDiscount>
        </SCTimerDiscountBadger>
      </SCLink>
    </TimerDiscountBooksLink>
  );
};

export const SCTimerDiscountBadgerIconWrapper = styled.div`
  background-color: var(--primary-hover-color);
  width: 20px;
  height: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const SCTimerDiscountBadger = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--primary-hover-color);
  border-radius: 10px;
  padding: 0 8px;
  color: var(--primary-hover-color);
  ${TextBoldStyle};
  font-size: 10px;
  line-height: 12px;
  height: 26px;
  
  &[data-isTrigger="true"] {
    border: 1px solid var(--error-color);
    color: var(--error-color);
    
    ${SCTimerDiscountBadgerIconWrapper} {
      background-color: var(--error-color);
    }
  }
`;

const SCTime = styled.span`
  padding-right: 4px;
`;

export const SCTimerDiscountBadgerIcon = styled(ClockIcon)`
  color: var(--white-color);
  font-size: 10px;
`;

const SCDiscount = styled.span`
  padding-left: 4px;
`;

const SCLink = styled.a`
  flex-shrink: 0;
`;
