import {
  ChangeCycleCompleteParams,
  CreateCycleParams,
  Cycle,
  DeleteCycleParams,
  GetAuthorCyclesParams,
  GetCycleBySlugParams,
  GetCycleParams,
  UpdateCycleParams,
} from '~/api/book/cycleApiTypes';
import { provider } from '~/api/provider/provider.config';
import { defaultErrorMessage, handleRejectedRequest } from '~/api/provider/providerErrors';
import {
  ApiResponse, PaginatedResponse, RejectedRequest,
} from '~/api/provider/providerTypes';

const namespace = Symbol('namespace');

export const cycleApi = {
  [namespace]: '/v1/books/cycle',

  async create(data: CreateCycleParams) {
    try {
      const response = await provider.post<ApiResponse<Cycle, RejectedRequest<CreateCycleParams>>>(
        `${this[namespace]}`,
        data,
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async get(data: GetCycleParams) {
    try {
      const response = await provider.get<
      ApiResponse<Cycle, RejectedRequest<void>>
      >(
        `${this[namespace]}/${data.id}`,
      );

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async getBySlug(data: GetCycleBySlugParams) {
    try {
      const response = await provider.get<
      ApiResponse<{ cycle: Cycle }, RejectedRequest<void>>
      >(
        `${this[namespace]}/${data.slug}`,
        {
          params: { withBooks: 'true' },
          headers: {
            Authorization: data && data?.token
              ? `Bearer ${data?.token}`
              : provider.defaults.headers.common.Authorization,
          },
        },
      );

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async update(data: UpdateCycleParams) {
    try {
      const response = await provider.put<
      ApiResponse<Cycle, RejectedRequest<void>>
      >(`${this[namespace]}/${data.id}`, { name: data.name });
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async changeCycleIndex(data: { cycleId: string; position: number; }) {
    try {
      await provider.put<
      ApiResponse<void, RejectedRequest<void>>
      >(`${this[namespace]}/${data.cycleId}/position`, { position: data.position });
    } catch (error) {
      handleRejectedRequest<void>(error);
      if (error.response.status === 422) {
        return Promise.reject({ errors: error.response?.data.errors });
      }
      return Promise.reject(new Error(error.response?.data?.message ?? defaultErrorMessage));
    }
  },
  async delete(data: DeleteCycleParams) {
    try {
      const response = await provider.delete<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/${data.id}`,
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getAuthorCycles(data: GetAuthorCyclesParams) {
    try {
      const response = await provider.get<
      PaginatedResponse<Cycle[], RejectedRequest<void>>
      >(
        `${this[namespace]}s`,
        {
          params: data,
          headers: {
            Authorization: data && data?.token
              ? `Bearer ${data?.token}`
              : provider.defaults.headers.common.Authorization,
          },
        },
      );

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async changeCycleComplete(data: ChangeCycleCompleteParams) {
    try {
      const response = await provider.put<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/${data.id}/complete`,
        { complete: data.complete },
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
};
