import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BookLayoutEnum } from '~/api/book/bookApiTypes';

export const catalogBookLayoutSliceName = 'catalogBookLayout';

const catalogBookLayoutSlice = createSlice({
  name: catalogBookLayoutSliceName,
  initialState: { layout: BookLayoutEnum.GRID },
  reducers: {
    changeLayout: (state, action: PayloadAction<BookLayoutEnum>) => {
      state.layout = action.payload;
    },
  },
});

export const {
  actions: catalogBookLayoutActions,
  reducer: catalogBookLayoutReducer,
} = catalogBookLayoutSlice;
