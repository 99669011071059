import React, {
  FC, useEffect,
  useRef,
} from 'react';
import styled from 'styled-components';

import { Button, SCButton } from '~/atomic/atom/Button';
import { Skeleton } from '~/atomic/atom/Skeleton';
import { getComments } from '~/feature/comments/comment.data';
import {
  commentsSelector,
  preModerationCommentsCountSelector,
} from '~/feature/comments/comment.selector';
import { isCommentsLoading } from '~/feature/comments/comment.slice';
import { userSelector } from '~/feature/user/user.selector';
import { useAppDispatch, useAppSelector } from '~/store';

import { Pagination } from '../pagination/Pagination';
import { CommentsList } from './CommentsList';
import { CreateCommentTextArea } from './CreateCommentTextArea';
import { PreModerationBookComments } from './PreModerationComments';

const COMMENTS_COUNT_TO_SHOW_BUTTON = 10;

export interface CommentsProps {
  targetClass: 'book' | 'post';
  targetId: number;
  targetAuthorId: string;
  isPreModeration?: boolean;
}

export const Comments: FC<CommentsProps> = ({
  targetClass, targetId, targetAuthorId, isPreModeration,
}) => {
  const dispatch = useAppDispatch();
  const ref = useRef(null);
  const { user } = useAppSelector(userSelector);
  const { comments, loading } = useAppSelector(commentsSelector);
  const preModerationCommentsCount = useAppSelector(preModerationCommentsCountSelector);

  const isTargetAuthor = targetAuthorId === String(user?.id ?? '');

  const isShowPreModeration = isPreModeration && isTargetAuthor && targetClass === 'book';

  const loadComments = () => {
    if (targetClass && targetId) {
      return dispatch(getComments({ targetClass, targetId }));
    }
  };

  const isShowWriteCommentButton = comments.length >= COMMENTS_COUNT_TO_SHOW_BUTTON;

  useEffect(() => {
    loadComments();
  }, [targetId]);

  return (
    <SCCommentsWrapper id="comments" ref={ref}>
      {isCommentsLoading('getComments', loading) ? (
        <>
          <Skeleton loading active avatar />
          <Skeleton loading active avatar />
          <Skeleton loading active avatar />
        </>
      ) : (
        <>
          <SCCreateCommentTextArea
            targetClass={targetClass}
            targetId={targetId}
            placeholder="Написать комментарий"
            afterSendComment={loadComments}
          />
          {!!isShowPreModeration && !!preModerationCommentsCount && (
            <PreModerationBookComments
              bookId={targetId}
              targetClass={targetClass}
              targetId={targetId}
            />
          )}
          <CommentsList
            targetAuthorId={targetAuthorId}
            targetClass={targetClass}
            targetId={targetId}
          />
          {
            isShowWriteCommentButton && (
              <div style={{ marginTop: '28px' }}>
                <SCWriteButton>
                  <Button>
                    Написать комментарий
                  </Button>
                </SCWriteButton>
              </div>
            )
          }
        </>
      )}
      <SCPagination
        onChange={loadComments}
        saveInUrl={false}
      />
    </SCCommentsWrapper>
  );
};

const SCWriteButton = styled.div`
  ${SCButton} {
    color: var(--black-color);
    font-weight: 600; 
  }
`;

const SCCommentsWrapper = styled.div`
`;

const SCCreateCommentTextArea = styled(CreateCommentTextArea)`
  margin-bottom: 16px;
  min-height: 44px;
`;

const SCPagination = styled(Pagination)`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
