import { combineReducers } from '@reduxjs/toolkit';

import { antiPirateReducer } from '~/atomic/page/anti-pirate/check/antiPirate.slice';
import { authorReducer } from '~/atomic/page/author/author.slice';
import { heroImageReducer } from '~/atomic/page/author/heroImage/heroImage.slice';
import { authorSubscribersReducer } from '~/atomic/page/author/subscribers/authorSubscribers.slice';
import { blackfridayPageReducer } from '~/atomic/page/blackfriday/blackfriday.slice';
import { bookModerationReducer } from '~/atomic/page/book/Book/bookModeration/bookModeration.slice';
import { bookPublicStatisticsReducer } from '~/atomic/page/book/BookPublicStatistic/bookPublicStatistic.slice';
import { bookEditAudioSettingsReducer } from '~/atomic/page/book-edit/BookEditAudioSettings/BookEditAudioSettings.slice';
import { bookEditTabsReducer } from '~/atomic/page/book-edit/BookEditTabs/BookEditTabs.slice';
import { bookPromocodesSliceReducer } from '~/atomic/page/book-edit/BookPromocodes/bookPromocodes.slice';
import { descriptionInputsReducer } from '~/atomic/page/book-edit/DescriptionInputs/descriptionInputs.slice';
import { editChapterReducer } from '~/atomic/page/book-edit/EditChapter/editChapter.slice';
import { uploadBookReducer } from '~/atomic/page/book-edit/UploadBook/uploadBook.slice';
import { activateReaderAdTimerDiscountReducer } from '~/atomic/page/book-reader/activateReaderAdTimerDiscount/activateReaderAdTimerDiscount.slice';
import { readerReducer } from '~/atomic/page/book-reader/reader.slice';
import { readerGiftTimerDiscountReducer } from '~/atomic/page/book-reader/ReaderGiftTimerDiscount/readerGiftTimerDiscount.slice';
import { adAccountReducer } from '~/atomic/page/campaigns/AdAccount/adAccount.slice';
import { campaignsReducer } from '~/atomic/page/campaigns/campaigns.slice';
import { genrePageReducer } from '~/atomic/page/catalog/genrePage/genrePage.slice';
import { searchCatalogReducer } from '~/atomic/page/catalog/searchCatalog/searchCatalog.slice';
import { tagPageReducer } from '~/atomic/page/catalog/tagPage/tagPage.slice';
import { discountsPageReducer } from '~/atomic/page/discounts/discounts.slice';
import { operationTableReducer } from '~/atomic/page/finance/OperationsTable/operationsTable.slice';
import { marketingReducer } from '~/atomic/page/marketing/marketing.slice';
import { scheduledDiscountReducer } from '~/atomic/page/marketing/ScheduledDiscount/scheduledDiscount.slice';
import { timerDiscountsReducer } from '~/atomic/page/marketing/timerDiscounts/timerDiscounts.slice';
import { purchaseReducer } from '~/atomic/page/my-purchases/purchaseTable.slice';
import { myBooksReducer } from '~/atomic/page/mybooks/myBooks.slice';
import { partnersAdsReducer } from '~/atomic/page/partners/PartnersAdsBooks/partnersAds.slice';
import { partnersCPAReducer } from '~/atomic/page/partners/PartnersCPABooks/partnersCPA.slice';
import { partnersWidgetReducer } from '~/atomic/page/partners/widget/partnersWidget.slice';
import { statisticsPageReducer } from '~/atomic/page/statistics/statisticsPage.slice';
import { ageLimitReducer } from '~/feature/ageLimit/ageLimit.slice';
import { audioPlayerReducer } from '~/feature/audio/audioPlayer.slice';
import { authorizationModalReducer } from '~/feature/authorization/authorizationModal.slice';
import { buyAbonnementReducer } from '~/feature/book/buyAbonnement/buyAbonnement.slice';
import { buyAudioBookReducer } from '~/feature/book/buyAudioBook/buyAudioBook.slice';
import { catalogBookLayoutReducer } from '~/feature/catalog/CatalogBookLayout/catalogBookLayout.slice';
import { catalogFilterReducer } from '~/feature/catalog/filter/catalogFilter.slice';
import { chapterReducer } from '~/feature/chapter/chapter.slice';
import { commentReducer } from '~/feature/comments/comment.slice';
import { allowCookieReducer } from '~/feature/cookie/allowCookie/allowCookie.slice';
import { cycleReducer } from '~/feature/cycle/cycleSlice';
import { accountReplenishmentReducer } from '~/feature/finance/accountReplenishment/accountReplenishment.slice';
import { choosePayReducer } from '~/feature/finance/BuyingSteps/ChoosePay/choosePay.slice';
import { connectPayoutMethodReducer } from '~/feature/finance/connectPayoutMethod/connectPayoutMethod.slice';
import { partnerProgramReducer } from '~/feature/finance/partnerProgram/partnerProgram.slice';
import { anchorsReducer } from '~/feature/jodit-plugins/anchors/anchors.slice';
import { windowSizeReducer } from '~/feature/media/windowSize.slice';
import { reactionReducer } from '~/feature/myBooks/reactions/reaction.slice';
import { notificationsReducer } from '~/feature/notification/notifications.slice';
import { paginationReducer } from '~/feature/pagination/pagination.slice';
import { referralLinkReducers } from '~/feature/referralLink/referralLink.slice';
import { searchReducer } from '~/feature/search/search.slice';
import { bindingPhoneReducer } from '~/feature/setting/BindingPhoneForm/bindingPhone.slice';
import { isSupportWebpReducer } from '~/feature/supportWebp/isSupportWebp.slice';
import { tagReducer } from '~/feature/tag/tag.slice';
import { topProgressBarReducer } from '~/feature/topProgressBar/topProgressBar.slice';
import { registrationReducer } from '~/feature/user/registration.slice';
import { userReducer } from '~/feature/user/user.slice';
import { userAlertReducer } from '~/feature/userAlert/userAlert.slice';
import { activateCatalogTimerDiscountReducer } from '~/feature/withTiwerDiscount/activateCatalogTimerDiscount/activateCatalogTimerDiscount.slice';
import { activateFeedTimerDiscountReducer } from '~/feature/withTiwerDiscount/activateFeedTimerDiscount/activateFeedTimerDiscount.slice';
import { withTimerDiscountReducer } from '~/feature/withTiwerDiscount/withTimerDiscount.slice';

import { headerReducer } from './atomic/organism/Header/header.slice';
import { authorBlogReducer } from './atomic/page/author/blog/authorBlog.slice';
import { downloadBookReducer } from './atomic/page/book/Book/downloadBook/downloadBook.slice';
import { booksReducer } from './atomic/page/books/books.slice';
import { financePageReducer } from './atomic/page/finance/financePage.slice';
import { paymentRequestsTableReducer } from './atomic/page/finance/paymentRequestsTable/paymentRequestsTable.slice';
import { referralTableReducer } from './atomic/page/finance/ReferralLinksTable/referralLinksTable.slice';
import { feedReducer } from './atomic/page/index/feed.slice';
import { cumulativeDiscountsReducer } from './atomic/page/marketing/cumulativeDiscounts/cumulativeDiscounts.slice';
import { postEditReducer } from './atomic/page/myblog/postEdit.slice';
import { partnersBooksReducer } from './atomic/page/partners/books/partnersBooks.slice';
import { changeEmailFormReducer } from './atomic/page/settings/ChangeEmail/changeEmail.slice';
import { settingsInputsReducer } from './atomic/page/settings/SettingsInputs/settingsInputs.slice';
import { authReducer } from './feature/authorization/auth.slice';
import { blogReducer } from './feature/blogs/blog.slice';
import { blogsFilterNavReducer } from './feature/blogs/BlogsFilterNav/blogsFilterNav.slice';
import { bookReducer } from './feature/book/book.slice';
import { buyBookReducer } from './feature/book/buyBook/buyBook.slice';
import { paymentResultReducer } from './feature/book/paymentResult/paymentResult.slice';
import { subscribeBookModalReducer } from './feature/book/SubscribeBookModal/subscribeBookModal.slice';

const combinedReducers = combineReducers({
  antiPirate: antiPirateReducer,
  anchors: anchorsReducer,
  book: bookReducer,
  user: userReducer,
  registration: registrationReducer,
  cycle: cycleReducer,
  chapter: chapterReducer,
  comments: commentReducer,
  authorizationModal: authorizationModalReducer,
  search: searchReducer,
  searchCatalog: searchCatalogReducer,
  pagesFilter: combineReducers({
    tag: tagPageReducer,
    genre: genrePageReducer,
  }),
  catalogFilter: catalogFilterReducer,
  tag: tagReducer,
  myBooks: myBooksReducer,
  buyBook: buyBookReducer,
  buyAudioBook: buyAudioBookReducer,
  reader: combineReducers({
    reader: readerReducer,
    activateTimerDiscountFromAd: activateReaderAdTimerDiscountReducer,
    readerGiftTimerDiscount: readerGiftTimerDiscountReducer,
  }),
  notifications: notificationsReducer,
  author: authorReducer,
  heroImage: heroImageReducer,
  choosePay: choosePayReducer,
  paymentResult: paymentResultReducer,
  auth: authReducer,
  bookPublicStatistic: bookPublicStatisticsReducer,
  finance: combineReducers({
    adAccount: adAccountReducer,
    page: financePageReducer,
    connectPayoutMethod: connectPayoutMethodReducer,
    paymentRequestsTable: paymentRequestsTableReducer,
    withTimerDiscount: withTimerDiscountReducer,
    campaigns: campaignsReducer,
    partnerProgram: partnerProgramReducer,
    activateFeedTimerDiscount: activateFeedTimerDiscountReducer,
    activateCatalogTimerDiscount: activateCatalogTimerDiscountReducer,
    buyAbonnement: buyAbonnementReducer,
  }),
  authorSubscribers: authorSubscribersReducer,
  purchase: purchaseReducer,
  operationTable: operationTableReducer,
  bindingPhone: bindingPhoneReducer,
  mainPage: feedReducer,
  changeEmailForm: changeEmailFormReducer,
  books: booksReducer,
  pagination: paginationReducer,
  bookEdit: combineReducers({
    tabs: bookEditTabsReducer,
    uploadBook: uploadBookReducer,
    descriptionInputs: descriptionInputsReducer,
    bookPromocodes: bookPromocodesSliceReducer,
    editChapter: editChapterReducer,
  }),
  header: headerReducer,
  topProgressBar: topProgressBarReducer,
  postEdit: postEditReducer,
  authorBlog: authorBlogReducer,
  blog: blogReducer,
  blogsFilterNav: blogsFilterNavReducer,
  referralTable: referralTableReducer,
  referralLink: referralLinkReducers,
  settingsInputs: settingsInputsReducer,
  subscribeBookModal: subscribeBookModalReducer,
  downloadBook: downloadBookReducer,
  accountReplenishment: accountReplenishmentReducer,
  marketing: combineReducers({
    cumulativeDiscounts: cumulativeDiscountsReducer,
    marketing: marketingReducer,
    timerDiscounts: timerDiscountsReducer,
    scheduledDiscount: scheduledDiscountReducer,
  }),
  windowSize: windowSizeReducer,
  partners: combineReducers({
    books: partnersBooksReducer,
    widgetSettings: partnersWidgetReducer,
  }),
  isSupportWebp: isSupportWebpReducer,
  ageLimit: ageLimitReducer,
  statistics: statisticsPageReducer,
  bookModeration: bookModerationReducer,
  audioPlayer: audioPlayerReducer,
  partnersAds: partnersAdsReducer,
  partnersCPA: partnersCPAReducer,
  bookEditAudioSettings: bookEditAudioSettingsReducer,
  allowCookie: allowCookieReducer,
  blackfridayPage: blackfridayPageReducer,
  discountsPage: discountsPageReducer,
  reaction: reactionReducer,
  userAlert: userAlertReducer,
  catalogBookLayout: catalogBookLayoutReducer,
});

export const reducer = (
  state: ReturnType<typeof combinedReducers>,
  action,
): ReturnType<typeof combinedReducers> => {
  if (action.type === 'POPULATE') {
    return {
      ...state, // use previous state
      ...action.payload, // apply delta from hydration
      audioPlayer: state.audioPlayer,
      reaction: state.reaction,
    };
  }
  return combinedReducers(state, action);
};
