import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CumulativeDiscountStatistics } from '~/api/book/discountBooksApiTypes';

export const cumulativeDiscountsSliceName = 'cumulativeDiscounts';

const DEFOUNT_PERCENT = 40;
const DEFOUNT_GRADE = 5;

const cumulativeDiscountsSlice = createSlice({
  name: cumulativeDiscountsSliceName,
  initialState: {
    isEditDiscount: false,
    isSaveCumulativeDiscountLoading: false,
    isGetCumulativeDiscountLoading: false,
    isGetCulumativeDiscountStatisticsLoading: false,
    isActive: false,
    isHaveCumulativeDiscount: false,
    isLoadingActivate: false,
    grade: DEFOUNT_GRADE,
    percent: DEFOUNT_PERCENT,
    statisticks: [] as CumulativeDiscountStatistics[],
  },
  reducers: {
    setIsEditDiscount: (state, action: PayloadAction<boolean>) => {
      state.isEditDiscount = action.payload;
    },
    setIsHaveCumulativeDiscount: (state, action: PayloadAction<boolean>) => {
      state.isHaveCumulativeDiscount = action.payload;
    },
    setIsSaveCumulativeDiscountLoading: (state, action: PayloadAction<boolean>) => {
      state.isSaveCumulativeDiscountLoading = action.payload;
    },
    setIsGetCumulativeDiscountLoading: (state, action: PayloadAction<boolean>) => {
      state.isGetCumulativeDiscountLoading = action.payload;
    },
    setIsGetStatisticsLoading: (state, action: PayloadAction<boolean>) => {
      state.isGetCulumativeDiscountStatisticsLoading = action.payload;
    },
    changeGrade: (state, action: PayloadAction<number>) => {
      state.grade = action.payload;
    },
    changePercent: (state, action: PayloadAction<number>) => {
      state.percent = action.payload;
    },
    changeIsActive: (state, action: PayloadAction<boolean>) => {
      state.isActive = action.payload;
    },
    changeIsLoadingActivate: (state, action: PayloadAction<boolean>) => {
      state.isLoadingActivate = action.payload;
    },
    changeStatistics: (state, action: PayloadAction<CumulativeDiscountStatistics[]>) => {
      state.statisticks = action.payload;
    },
  },
});

export const {
  actions: cumulativeDiscountsActions,
  reducer: cumulativeDiscountsReducer,
} = cumulativeDiscountsSlice;
