import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const downloadBookSliceName = 'downloadBook';

export const downloadBookSlice = createSlice({
  name: downloadBookSliceName,
  initialState: { isLoading: false },
  reducers: {
    setIsLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
  },
});

export const {
  actions: downloadBookActions,
  reducer: downloadBookReducer,
} = downloadBookSlice;
