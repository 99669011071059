import {
  BookListStatisticsPublic,
  BookStatistics,
  BookStatisticsPublic, GetAbonnementStatisticsDates, GetAbonnementStatisticsParams,
  GetAbonnementStatisticsResult,
  GetBookListTotalStatisticsParams,
  GetBookTotalStatisticsParams,
  GetPartnerProgramStatisticsParams,
  GetStatisticsParams,
  PartnerProgramStatistics,
  ViewBookParams,
  ViewChapterParams,
} from '~/api/book/statisticsApiTypes';
import { provider } from '~/api/provider/provider.config';
import { handleRejectedRequest } from '~/api/provider/providerErrors';
import {
  ApiResponse, PaginatedResponse, RejectedRequest,
} from '~/api/provider/providerTypes';

const namespace = Symbol('namespace');

export const statisticsApi = {
  [namespace]: '/v1/statistics',

  async getBookTotalStatistics(data: GetBookTotalStatisticsParams) {
    try {
      const response = await provider.get<ApiResponse<
      BookStatisticsPublic,
      RejectedRequest<void>
      >>(
        `${this[namespace]}/books/${data.bookId}/totals`,
      );

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async getBookListTotalStatistics(data: GetBookListTotalStatisticsParams) {
    try {
      const response = await provider.get<ApiResponse<
      BookListStatisticsPublic[],
      RejectedRequest<void>
      >>(`${this[namespace]}/totals/books`, { params: { ids: data.bookIds } });

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async viewBook({ bookId }: ViewBookParams) {
    try {
      await provider.get<ApiResponse<
      void,
      RejectedRequest<void>
      >>(
        `${this[namespace]}/set/books/view/${bookId}`,
      );
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async viewChapter({ chapterId }: ViewChapterParams) {
    try {
      await provider.get<ApiResponse<
      void,
      RejectedRequest<void>
      >>(
        `${this[namespace]}/set/chapters/view/${chapterId}`,
      );
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async getStatistics(filters: GetStatisticsParams) {
    try {
      const response = await provider.get<PaginatedResponse<
      BookStatistics[],
      RejectedRequest<GetStatisticsParams>
      >>(
        `${this[namespace]}/books/my`,
        { params: { ...filters } },
      );

      if (response?.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getPartnerProgramStatistics(data: GetPartnerProgramStatisticsParams) {
    try {
      const response = await provider.get<
      PaginatedResponse<PartnerProgramStatistics[], RejectedRequest<void>>
      >(`${this[namespace]}/partnerProgram`, { params: data });
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getAbonnementStatistics(data: GetAbonnementStatisticsParams) {
    try {
      const response = await provider.get<
      ApiResponse<GetAbonnementStatisticsResult, RejectedRequest<void>>
      >(`${this[namespace]}/abonnement-royalty`, { params: data });

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      return handleRejectedRequest<void>(error);
    }
  },
  async getAbonnementStatisticsDates() {
    try {
      const response = await provider.get<
      ApiResponse<GetAbonnementStatisticsDates, RejectedRequest<void>>
      >(`${this[namespace]}/abonnement-royalty-months`);

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      return handleRejectedRequest<void>(error);
    }
  },
};
