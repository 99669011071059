import {
  createAsyncThunk, createSelector, createSlice, PayloadAction, unwrapResult,
} from '@reduxjs/toolkit';

import { accountApi } from '~/api/account/accountApi';
import { bookApi } from '~/api/book/bookApi';
import { BookIdType, ChangeIndexInCycleParams } from '~/api/book/bookApiTypes';
import { cycleApi } from '~/api/book/cycleApi';
import {
  ChangeCycleCompleteParams, Cycle,
  GetAuthorCyclesParams, GetCycleBySlugParams,
  UpdateCycleParams,
} from '~/api/book/cycleApiTypes';
import { Paginated } from '~/api/provider/providerTypes';
import { getBookListTotalStatistics } from '~/atomic/page/books/books.data';
import { booksActions } from '~/atomic/page/books/books.slice';
import { refreshBookCash } from '~/feature/refreshCash/refreshBookCash';
import { environments } from '~/lib/const';
import { transliterate } from '~/lib/transliteration';
import { RootState } from '~/store';

const sliceName = 'cycle';

export const changeBookCycle = createAsyncThunk<
void,
{
  bookId: BookIdType;
  bookSlug: string;
  targetCycleId: string;
  sourceCycleId: string;
  numInCycle?: string
},
{ rejectValue: { error: string } }
>(
  `${sliceName}/changeBookCycle`,
  async (
    {
      targetCycleId, bookId, numInCycle, bookSlug,
    },
    thunkAPI,
  ) => {
    try {
      await bookApi.changeCycle({
        cycleId: targetCycleId, bookId, numInCycle,
      });
      refreshBookCash(bookSlug);
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const changeIndexInCycle = createAsyncThunk<
void,
ChangeIndexInCycleParams & { cycleId: string; bookSlug: string; },
{ rejectValue: { error: string } }
>(
  `${sliceName}/changeIndexInCycle`,
  async (
    { bookSlug, ...data },
    thunkAPI,
  ) => {
    try {
      await bookApi.changeIndexInCycle(data);
      refreshBookCash(bookSlug);
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const createCycle = createAsyncThunk<Cycle, any, {
  rejectValue: { error: string };
}>(
  `${sliceName}/createCycle`,
  async (
    name: string,
    thunkAPI,
  ) => {
    try {
      const result = await cycleApi.create({ name });

      if (result?.message) {
        if (environments.isClient) {
          const { message } = await import('~/atomic/atom/message');
          message.success(result.message);
        }
      }

      thunkAPI.dispatch(getCyclesMeWithBooks());
      if (result && 'data' in result) {
        return result.data;
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const deleteCycle = createAsyncThunk<any, any, {
  rejectValue: { error: string };
}>(
  `${sliceName}/deleteCycle`,
  async (
    cycleId: string,
    thunkAPI,
  ) => {
    try {
      const result = await cycleApi.delete({ id: cycleId });

      if (result?.message) {
        if (environments.isClient) {
          const { message } = await import('~/atomic/atom/message');
          message.success(result.message);
        }
      }

      if (result && 'data' in result) {
        return result.data;
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const changeCycleIndex = createAsyncThunk<void, { cycleId: string; position: number; }, {
  rejectValue: { error: string };
}>(
  `${sliceName}/changeCycleIndex`,
  async (
    { cycleId, position },
    thunkAPI,
  ) => {
    try {
      await cycleApi.changeCycleIndex({ cycleId, position });
      // thunkAPI.dispatch(getCyclesMeWithBooks());
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const getCyclesByAuthorUsername = createAsyncThunk<
Paginated<Cycle[]>,
GetAuthorCyclesParams,
{ rejectValue: { error: string } }>(
  `${sliceName}/getCyclesByAuthorId`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const result = await cycleApi.getAuthorCycles(data);

      if (result && 'data' in result) {
        return result;
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const getCycleBySlug = createAsyncThunk<
{ cycle: Cycle },
GetCycleBySlugParams,
{ rejectValue: { error: string } }>(
  `${sliceName}/getCycleBySlug`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const result = await cycleApi.getBySlug(data);

      if (result && 'data' in result) {
        return result.data;
      }
    } catch (error) {
      if (environments.isClient) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const getCyclesMeWithBooks = createAsyncThunk<Cycle[], void, {
  rejectValue: { error: string }; state: RootState
}>(
  `${sliceName}/getCyclesMeWithBooks`,
  async (
    _: void,
    thunkAPI,
  ) => {
    const { isCyclesMeWithBooksLoading } = thunkAPI.getState().cycle;
    if (!isCyclesMeWithBooksLoading) {
      thunkAPI.dispatch(cycleActions.setCyclesMeWithBooksLoading(true));
      try {
        const result = await accountApi.getCyclesWithBooks();

        if (result?.message) {
          if (environments.isClient) {
            const { message } = await import('~/atomic/atom/message');
            message.success(result.message);
          }
        }

        if (result && 'data' in result) {
          const defaultCycle = result.data.find((cycle) => cycle.name === defaultCycleName);

          if (defaultCycle) {
            thunkAPI.dispatch(booksActions.setDefaultCycle(defaultCycle));
          } else {
            thunkAPI.dispatch(booksActions.setDefaultCycle({} as Cycle));
          }

          const booksStatistic = result.data.reduce(
            (acc, cycle): string => {
              const booksIdsOfCycle = cycle.books.map((book) => book.id).join(',');
              if (booksIdsOfCycle === '') return acc;
              if (acc === '') return booksIdsOfCycle;
              return `${acc},${booksIdsOfCycle}`;
            },
            '',
          );
          if (booksStatistic !== '') {
            const bookStatistics = unwrapResult(await thunkAPI.dispatch(
              getBookListTotalStatistics({ bookIds: booksStatistic }),
            ));

            thunkAPI.dispatch(booksActions.setBooksStatistic(bookStatistics));
          }
          return result.data;
        }
      } catch (error) {
        if (environments.isClient && error instanceof Error) {
          const { message } = await import('~/atomic/atom/message');
          message.error(error.message);
        }
        return thunkAPI.rejectWithValue({ error: error.message });
      } finally {
        thunkAPI.dispatch(cycleActions.setCyclesMeWithBooksLoading(false));
      }
    }
  },
);

export const getCyclesMe = createAsyncThunk<Cycle[], void, {
  rejectValue: { error: string }; state: RootState
}>(
  `${sliceName}/getCyclesMe`,
  async (
    _: void,
    thunkAPI,
  ) => {
    const { isCyclesMeWithBooksLoading } = thunkAPI.getState().cycle;
    if (!isCyclesMeWithBooksLoading) {
      thunkAPI.dispatch(cycleActions.setCyclesMeLoading(true));
      try {
        const result = await accountApi.getCycles();

        if (result?.message) {
          if (environments.isClient) {
            const { message } = await import('~/atomic/atom/message');
            message.success(result.message);
          }
        }

        if (result && 'data' in result) {
          thunkAPI.dispatch(cycleActions.changeCycles(result.data));
          return result.data;
        }
      } catch (error) {
        if (environments.isClient && error instanceof Error) {
          const { message } = await import('~/atomic/atom/message');
          message.error(error.message);
        }
        return thunkAPI.rejectWithValue({ error: error.message });
      } finally {
        thunkAPI.dispatch(cycleActions.setCyclesMeLoading(false));
      }
    }
  },
);

export const changeCycleComplete = createAsyncThunk<void, ChangeCycleCompleteParams, {
  rejectValue: { error: string };
}>(
  `${sliceName}/changeCycleComplete`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const result = await cycleApi.changeCycleComplete(data);

      if (result?.message) {
        if (environments.isClient) {
          const { message } = await import('~/atomic/atom/message');
          message.success(result.message);
        }
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const updateCycle = createAsyncThunk<Cycle, UpdateCycleParams, {
  rejectValue: { message?: string, error?: any };
}>(
  `${sliceName}/updateCycle`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const result = await cycleApi.update(data);

      if (environments.isClient) {
        const { message } = await import('~/atomic/atom/message');
        message.success('Серия книг успешно изменена!');
      }

      if (result && 'data' in result) {
        thunkAPI.dispatch(cycleActions.setCycleName(result.data));
        return result.data;
      }
    } catch (e) {
      if (e?.errors) {
        return thunkAPI.rejectWithValue({ error: e.errors });
      }
      if (environments.isClient) {
        const { message } = await import('~/atomic/atom/message');
        message.error(e.message);
      }
      return thunkAPI.rejectWithValue({ message: e.message });
    }
  },
);

export const cycleSelector = (state: RootState) => state.cycle;

export const isHaveCyclesWithBooksSelector = createSelector(
  cycleSelector,
  ({ cyclesWithBooks }) => cyclesWithBooks.length > 1,
);

export const cycleBySlugSelector = (cycleSlug: string) => createSelector(
  cycleSelector,
  ({ cyclesWithBooks }) => cyclesWithBooks.find((cycle) => {
    return transliterate(cycle.name) === cycleSlug;
  }),
);

export const currentCycleSelector = createSelector(
  cycleSelector,
  ({ currentCycle }) => currentCycle,
);

export const cyclesForSelectSelector = createSelector(
  cycleSelector,
  ({ cycles }) => cycles
    ?.map((cycle) => ({ label: cycle.name, value: cycle.id }))
      ?? [] as { label: string, value: string }[],
);

export const defaultCycleName = 'Книги без циклов';

const cycleSlice = createSlice({
  name: sliceName,
  initialState: {
    message: '',
    loading: false,
    cycles: [] as Cycle[],
    currentCycle: null as Cycle | null,
    isCyclesMeWithBooksLoading: false,
    isCyclesMeLoading: false,
    cyclesWithBooks: [] as Cycle[],
    pendingCycles: [] as Cycle[],
  },
  reducers: {
    setCycles: (state, action: PayloadAction<Cycle[]>) => {
      state.cyclesWithBooks = action.payload;
    },
    setCycleName: (state, action: PayloadAction<Cycle>) => {
      state.cyclesWithBooks.forEach((cycle) => {
        if (cycle.id === action.payload.id) {
          cycle.name = action.payload.name;
        }
        return cycle;
      });
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setCyclesMeWithBooksLoading: (state, action: PayloadAction<boolean>) => {
      state.isCyclesMeWithBooksLoading = action.payload;
    },
    setCyclesMeLoading: (state, action: PayloadAction<boolean>) => {
      state.isCyclesMeLoading = action.payload;
    },
    changeCycles: (state, action: PayloadAction<Cycle[]>) => {
      state.cycles = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCycleBySlug.fulfilled, (state, action) => {
      state.currentCycle = action.payload.cycle;
    });
    builder.addCase(createCycle.pending, (state) => {
      state.loading = true;
      state.message = '';
    });
    builder.addCase(createCycle.fulfilled, (state, action) => {
      state.loading = false;
      state.cyclesWithBooks.push(action.payload);
    });
    builder.addCase(createCycle.rejected, (state, action) => {
      state.loading = false;
      state.message = action.payload.error;
    });

    builder.addCase(deleteCycle.pending, (state) => {
      state.loading = true;
      state.message = '';
    });
    builder.addCase(deleteCycle.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(deleteCycle.rejected, (state, action) => {
      state.loading = false;
      state.message = action.payload.error;
    });

    builder.addCase(getCyclesByAuthorUsername.pending, (state) => {
      state.loading = true;
      state.message = '';
    });
    builder.addCase(getCyclesByAuthorUsername.fulfilled, (state, action) => {
      state.loading = false;
      state.cyclesWithBooks = action.payload.data;
    });
    builder.addCase(getCyclesByAuthorUsername.rejected, (state, action) => {
      state.loading = false;
      state.message = action.payload.error;
    });

    builder.addCase(getCyclesMeWithBooks.pending, (state) => {
      state.loading = true;
      state.message = '';
    });
    builder.addCase(getCyclesMeWithBooks.fulfilled, (state, action) => {
      state.loading = false;
      const cycles = action.payload;
      state.cyclesWithBooks = cycles?.sort(
        (cycleA, cycleB) => cycleA.position - cycleB.position,
      ) ?? [];
    });

    builder.addCase(getCyclesMeWithBooks.rejected, (state, action) => {
      state.loading = false;
      state.message = action.payload.error;
    });

    builder.addCase(changeCycleIndex.pending, (state, action) => {
      const { position: targetPosition, cycleId } = action.meta.arg;
      state.pendingCycles = state.cyclesWithBooks;
      const sourceCycle = state.cyclesWithBooks
        .find((cycle) => String(cycle.id) === String(cycleId));
      const sourcePosition = sourceCycle.position;
      state.cyclesWithBooks.splice(sourcePosition - 1, 1);
      state.cyclesWithBooks.splice(targetPosition - 1, 0, sourceCycle);
      state.cyclesWithBooks.forEach((cycle, index) => {
        cycle.position = index + 1;
      });
    });

    builder.addCase(changeCycleIndex.fulfilled, (state, action) => {
      state.pendingCycles = [];
    });

    builder.addCase(changeCycleIndex.rejected, (state, action) => {
      state.cyclesWithBooks = state.pendingCycles;
      state.pendingCycles = [];
    });

    builder.addCase(changeBookCycle.pending, (state, action) => {
      const {
        targetCycleId, bookId, sourceCycleId, numInCycle,
      } = action.meta.arg;
      state.pendingCycles = state.cyclesWithBooks;

      const sourceCycleIndex = state.cyclesWithBooks.findIndex(
        (cycle) => String(cycle.id) === String(sourceCycleId),
      );

      const targetCycleIndex = state.cyclesWithBooks.findIndex(
        (cycle) => String(cycle.id) === String(targetCycleId),
      );

      const bookIndex = state.cyclesWithBooks[sourceCycleIndex].books.findIndex(
        (book) => String(book.id) === String(bookId),
      );

      const book = state.cyclesWithBooks[sourceCycleIndex].books.find(
        (book) => String(book.id) === String(bookId),
      );

      if (bookIndex !== -1) {
        state.cyclesWithBooks[sourceCycleIndex].books.splice(bookIndex, 1);

        state.cyclesWithBooks[sourceCycleIndex].books.forEach((book, index) => {
          book.cycle.numInCycle = index + 1;
        });
      }

      if (book && !numInCycle) {
        state.cyclesWithBooks[targetCycleIndex].books.unshift(book);

        state.cyclesWithBooks[targetCycleIndex].books.forEach((book, index) => {
          book.cycle.numInCycle = index + 1;
        });
      }

      if (book && numInCycle) {
        state.cyclesWithBooks[targetCycleIndex].books.splice(Number(numInCycle) - 1, 0, book);
        state.cyclesWithBooks[targetCycleIndex].books.forEach((book, index) => {
          book.cycle.numInCycle = index + 1;
        });
      }
    });

    builder.addCase(changeBookCycle.fulfilled, (state, action) => {
      state.pendingCycles = [];
    });

    builder.addCase(changeBookCycle.rejected, (state, action) => {
      state.cyclesWithBooks = state.pendingCycles;
      state.pendingCycles = [];
    });

    builder.addCase(changeIndexInCycle.pending, (state, action) => {
      const {
        bookId,
        numInCycle: targetNumInCycle,
        cycleId,
      } = action.meta.arg;
      state.pendingCycles = state.cyclesWithBooks;

      // TODO: Вынести смену позиции внутри цикла в функцию
      const cycleIndex = state.cyclesWithBooks
        .findIndex((cycle) => String(cycle.id) === String(cycleId));

      const book = state.cyclesWithBooks[cycleIndex].books.find(
        (book) => String(book.id) === String(bookId),
      );

      const sourcePosition = book.cycle.numInCycle;

      state.cyclesWithBooks[cycleIndex].books.splice(sourcePosition - 1, 1);
      state.cyclesWithBooks[cycleIndex].books.splice(targetNumInCycle - 1, 0, book);
      state.cyclesWithBooks[cycleIndex].books.forEach((book, index) => {
        book.cycle.numInCycle = index + 1;
      });
    });

    builder.addCase(changeIndexInCycle.fulfilled, (state, action) => {
      state.pendingCycles = [];
    });

    builder.addCase(changeIndexInCycle.rejected, (state, action) => {
      state.cyclesWithBooks = state.pendingCycles;
      state.pendingCycles = [];
    });
  },
});

export type CycleState = ReturnType<typeof cycleSlice.reducer>;

export const { actions: cycleActions, reducer: cycleReducer } = cycleSlice;
