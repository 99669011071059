import React from 'react';
import styled from 'styled-components';

import { OkIcon } from '~/atomic/atom/icon/OkIcon';
import { ShareIcon } from '~/atomic/atom/icon/ShareIcon';
import { VkIcon } from '~/atomic/atom/icon/VkIcon';
import { postContentForSeoSelector } from '~/feature/blogs/blog.selector';
import { environments } from '~/lib/const';
import { createSocialLinks } from '~/lib/createSocialLinks';
import { useAppSelector } from '~/store';

export const PostCardSocialLinks = () => {
  const description = useAppSelector(postContentForSeoSelector);
  const socialLinks = createSocialLinks({ description });

  const createLinkHandler = async () => {
    if (environments.isClient) {
      try {
        await navigator.clipboard.writeText(window.location.href);
        const { message } = await import('~/atomic/atom/message');
        message.success('Ссылка скопирована');
      } catch (error) {
        const { message } = await import('~/atomic/atom/message');
        message.success('Не удалось скопировать ссылку');
      }
    }
  };

  return (
    <SCSocialLinksPostCard>
      <SCLink
        onClick={(e) => {
          e.preventDefault();
          window.open(socialLinks.vk, '', 'toolbar=0,status=0,width=626,height=436');
        }}
        href={socialLinks.vk}
        target="_blank"
        rel="nofollow noopener"
      >
        <SCVkIcon />
      </SCLink>
      <SCLink
        onClick={(e) => {
          e.preventDefault();
          window.open(socialLinks.ok, '', 'toolbar=0,status=0,width=626,height=436');
        }}
        href={socialLinks.ok}
        target="_blank"
        rel="nofollow noopener"
      >
        <SCOkIcon />
      </SCLink>
      <SCLink
        onClick={createLinkHandler}
        as="span"
      >
        <SCShareIcon />
      </SCLink>
    </SCSocialLinksPostCard>
  );
};

const SCSocialLinksPostCard = styled.div`
  display: flex;
  align-items: center;
  
  > *:not(:last-child) {
    margin-right: 12px;
  }
`;

const SCLink = styled.a`
  cursor: pointer;
`;

const SCVkIcon = styled(VkIcon)`
  font-size: 32px;
  color: var(--vk-color);
`;

const SCOkIcon = styled(OkIcon)`
  font-size: 32px;
  color: var(--ok-color);
`;

const SCShareIcon = styled(ShareIcon)`
  font-size: 16px;
  color: var(--black-color);
`;
