import {
  createUrlWithFilter,
  CreateUrlWithFilterProps,
} from '~/atomic/atom/links/createUrlWithFilter';

export interface CreateTagUrlProps extends Omit<CreateUrlWithFilterProps, 'isAddGenreToParams' | 'startWith'> {}

export const createTagUrl = (props: CreateTagUrlProps) => createUrlWithFilter({
  ...props,
  isAddGenreToParams: false,
  startWith: '/tag',
});
