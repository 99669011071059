import React, {
  FC, HTMLAttributes, ReactNode, useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';

import { environments } from '~/lib/const';

import { ArrowIcon } from '../atom/icon/ArrowIcon';

interface AccordionProps extends Omit<HTMLAttributes<HTMLDivElement>, 'title' | 'content'> {
  title: ReactNode;
  content: ReactNode;
}

export const Accordion:FC<AccordionProps> = ({
  title, content, ...props
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const titleRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const [
    accordionHeight,
    changeAccordionHeight,
  ] = useState(0);

  useEffect(() => {
    const titleHeight = titleRef?.current?.clientHeight;
    const contentHeight = contentRef?.current?.clientHeight;

    changeAccordionHeight(isOpen ? contentHeight + titleHeight : titleHeight);
  }, [environments.isClient, isOpen, contentRef.current, titleRef.current]);

  return (
    <SCAccordion
      data-height
      style={{ ['--height' as any]: `${accordionHeight}px` }}
      {...props}
    >
      <SCAccordionTitle
        ref={titleRef}
        onClick={() => { setIsOpen((value) => !value); }}
      >
        { title }
        <SCArrowIcon data-is-open={String(isOpen)} />
      </SCAccordionTitle>
      <SCAccordionContent ref={contentRef}>
        { content }
      </SCAccordionContent>
    </SCAccordion>
  );
};

const SCAccordionContent = styled.div`
`;

const SCAccordionTitle = styled.div`
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
  padding-bottom: 14px;
`;

const SCAccordion = styled.div`
  width: 100%;
  overflow: hidden;
  height: var(--height);
  transition: height 0.5s ease;
`;

const SCArrowIcon = styled(ArrowIcon)`
  color: var(--black-color);
  font-size: 15px;
  margin-left: auto;
  transition: all 0.3s ease;
  transform: rotate(90deg);
  position: relative;

  &[data-is-open="true"] {
    color: var(--primary-color);
    transform: rotate(270deg);
  }
`;
