import React, {
  FC, HTMLAttributes, useEffect,
} from 'react';
import styled from 'styled-components';

import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import { environments } from '~/lib/const';
import { lessThan } from '~/lib/mediaQuery';
import { useAppSelector } from '~/store';

interface VKWidjetProps extends HTMLAttributes<HTMLDivElement> {
  widjetSetting?: Object
}

export const VKWidjet: FC<VKWidjetProps> = ({ widjetSetting, ...props }) => {
  const isMobileSize = useAppSelector(isMobileSizeSelector);

  useEffect(() => {
    setTimeout(() => {
      const isHaveVk = environments.isClient && 'VK' in window;
      if (!isMobileSize && isHaveVk) {
        // @ts-ignore
        window?.VK?.Widgets?.Group('vk_groups', {
          mode: 3, no_cover: 1, ...widjetSetting,
        }, 198991141);
      }
    }, 3000);
  }, []);

  return (
    <SCVKWidjet {...props} id="vk_groups" />
  );
};
const SCVKWidjet = styled.div`
  ${lessThan('sm')} {
    display: none;
  }
`;
