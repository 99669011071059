import { useEffect } from 'react';

import { checkPaymentThunk } from '~/feature/book/paymentResult/paymentResult.data';
import { environments } from '~/lib/const';
import { useAppDispatch } from '~/store';

export const CheckTransaction = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (environments.isClient) {
      dispatch(checkPaymentThunk());
    }
  }, []);

  return null;
};
