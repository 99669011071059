import dayjs from 'dayjs';
import React, { FC } from 'react';
import styled from 'styled-components';

import { Post } from '~/api/blog/blogTypes';
import { CommentsIcon } from '~/atomic/atom/icon/CommentsIcon';
import { PostLink } from '~/atomic/atom/links/dynamicLinks';
import { TextBoldStyle, TextRegStyle } from '~/atomic/Typography';
import { usePostContentFotmatting } from '~/feature/blogs/postContentFotmatting';
import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import { PostPlaceEnum } from '~/pages/post/[slugPost]';
import { useAppSelector } from '~/store';

interface AuthorPostItemProps {
  post: Post;
}

const DAY_IN_MILLISECONDS = 1000 * 60 * 59 * 24;
const TWO_DAYS_IN_MILLISECONDS = 1000 * 60 * 59 * 24 * 2;

export const AuthorPostItem: FC<AuthorPostItemProps> = ({ post }) => {
  const isMobileSize = useAppSelector(isMobileSizeSelector);
  const postContent = usePostContentFotmatting(post.content, 100);

  const showDate = () => {
    const date = post.publishedAt;

    const parseString = dayjs(date, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format();
    const dateNumber = Date.parse(parseString);
    const dateNowInMilliseconds = Date.now();

    const betweenInMilliseconds = dateNowInMilliseconds - dateNumber;

    if (betweenInMilliseconds < DAY_IN_MILLISECONDS) {
      return 'Сегодня';
    }

    if (betweenInMilliseconds > DAY_IN_MILLISECONDS && betweenInMilliseconds < TWO_DAYS_IN_MILLISECONDS) {
      return 'Вчера';
    }

    return dayjs(date, 'YYYY-MM-DDTHH:mm:ss.000ZZ').format('DD.MM.YY');
  };

  return (
    <SCAuthorPostItem>
      <PostLink slugPost={post.slug} place={PostPlaceEnum.Author}>
        <SCTitle>
          {post.title}
        </SCTitle>
      </PostLink>
      <SCRow>
        <SCDateOfPublish>
          {showDate()}
        </SCDateOfPublish>
        {post.counters.comments ? (
          <>
            <SCCommentsIcon />
            <SCCount>{post.counters.comments}</SCCount>
          </>
        ) : null}
      </SCRow>
      {!isMobileSize ? (
        <SCContent dangerouslySetInnerHTML={{ __html: postContent }} />
      ) : null}
    </SCAuthorPostItem>
  );
};

const SCAuthorPostItem = styled.div`
  > *:not(:last-child) {
    margin-bottom: 6px;
  }
`;

const SCTitle = styled.a`
  ${TextBoldStyle};
  color: var(--black-color);
  display: inline-flex;
`;

const SCDateOfPublish = styled.span`
  font-family: var(--second-font);
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 18px;
  color: var(--gray-color);
`;

const SCContent = styled.div`
  --font-size: 14px;
  --line-height: 20px;

  color: var(--black-color);

  line-height: var(--line-height);
  font-size: var(--font-size);

  
  * {
    text-align: left !important;
    ${TextRegStyle};
    font-weight: normal !important;
    color: var(--black-color);
  }
  
  p, h1, h2, h3, h4, h5 {
    margin-bottom: 0;
  }
`;

const SCCommentsIcon = styled(CommentsIcon)`
  font-size: 18px;
  color: var(--gray-color);
`;

const SCRow = styled.div`
  display: flex;
  align-items: center;
  
  > *:not(:last-child) {
    margin-right: 12px;
  }
`;

const SCCount = styled.span`
  ${TextRegStyle};
  color: var(--gray-color);
`;
