import { GetServerSidePropsContext } from 'next';
import nookies from 'nookies';

import { oldTokenName, tokenName } from '~/feature/authorization/tokenName';
import { setTokenWithFrontServer } from '~/pages/api/auth/setToken';
import { getStore } from '~/store';

export const getAuthTokenFromClient = (): string => {
  const store = getStore();

  if (store?.getState()) {
    const { token } = store.getState().user;
    return token;
  }
};

export const getAuthTokenFromServer = (ctx: GetServerSidePropsContext): string => {
  let token = nookies.get(ctx)?.[tokenName] as string;

  if (!token) {
    token = nookies.get(ctx)?.[oldTokenName] as string;

    if (token) {
      setTokenWithFrontServer(ctx.res, token, 259200 * 60);
    }
  }

  return token;
};
