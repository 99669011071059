import React from 'react';
import styled from 'styled-components';

import { Button } from '~/atomic/atom/Button';
import { BooksLink } from '~/atomic/atom/links/staticLinks';
import Modal from '~/atomic/molecula/Modal';
import { PageTitleStyle, TextRegStyle } from '~/atomic/Typography';
import { userAlertSelector } from '~/feature/userAlert/userAlert.selector';
import { userAlertActions } from '~/feature/userAlert/userAlert.slice';
import { useAppDispatch, useAppSelector } from '~/store';

export const AuthorModalAboutAbonnement = () => {
  const dispatch = useAppDispatch();
  const { isShowDefaultAuthorModalAboutAbonnement } = useAppSelector(userAlertSelector);

  const closeModal = () => {
    dispatch(userAlertActions.toggleShowDefaultAuthorModalAboutAbonnement(false));
  };

  return (
    <Modal
      open={isShowDefaultAuthorModalAboutAbonnement}
      onCancel={closeModal}
      width="560px"
    >
      <SCContent>
        <SCTitle>Новости Букривер</SCTitle>
        <SCDescription>
          По итогам августа авторы, добавившие книги в сервис абонемента, получили роялти в размер
          {' '}
          <b>54% </b>
          {' '}
          от стоимости книг, что полностью совпадает с нашими
          {' '}
          <SCLink href="post/abonementy---dlya-tekh-kto-mnogo-chitaet-5122?place=blog" target="_blank">прогнозами</SCLink>
          .
          <br />
          <br />
          Также, спешим сообщить, что мы сократили минимальное время нахождения книги в сервисе с 3 до 1 месяца.
          <br />
          <br />
          Если вы еще не пользуетесь сервисом Абонемент или добавили не все свои книги, то самое время это исправить!
          <br />
          <br />
        </SCDescription>
        <SCButtonsWrap>
          <BooksLink>
            <a style={{ width: '100%' }}>
              <Button block type="primary" size="large">
                Добавить книги
              </Button>
            </a>
          </BooksLink>
          <Button block size="large" onClick={closeModal}>
            Закрыть
          </Button>
        </SCButtonsWrap>
      </SCContent>
    </Modal>
  );
};

const SCContent = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 16px;
`;

const SCTitle = styled.h3`
  ${PageTitleStyle};
`;

const SCDescription = styled.span`
  ${TextRegStyle};
  color: var(--black-color);
`;

const SCButtonsWrap = styled.div`
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
`;

const SCLink = styled.a`
  ${TextRegStyle};
  color: var(--primary-color);
`;
