// eslint-disable-next-line import/no-extraneous-dependencies
import * as OriginLinkify from 'linkifyjs/react';
import React, { FC } from 'react';

import { WithChildren } from '~/lib/WithChildren';

const options = {
  validate: {
    url: (value) => {
      return (
        /^(http)s?:\/\/bookriver\.ru/.test(value)
        || /^bookriver\.ru/.test(value)
      );
    },
  },
};

export const Linkify:FC<{ tagName?: string } & WithChildren> = ({ children, tagName }) => {
  return (
    <OriginLinkify
      tagName={tagName ?? 'div'}
      options={options}
    >
      { children }
    </OriginLinkify>
  );
};
