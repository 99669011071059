import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Book } from '~/api/book/bookApiTypes';

export const bookReaderSliceName = 'bookReader';
export const textSettingsName = 'textSettings';

export enum BookReaderThemeEnum {
  light = 'light',
  Dark = 'dark',
  Sandy = 'sandy',
}
export type DefaultSettingsType = typeof readerDefaultSettings;
export type DefaultSettingsKeys = keyof typeof readerDefaultSettings;

export const readerDefaultSettings = {
  isBookFormat: false,
  font: 'Arial',
  fontSize: 16,
  theme: BookReaderThemeEnum.light,
};

export const readerSlice = createSlice({
  name: bookReaderSliceName,
  initialState: {
    textSettings: readerDefaultSettings as DefaultSettingsType,
    loading: false,
    saveChapterProgress: 0,
    newChapterProgress: 0,
    saveChapterId: null as number,
    nextBookInCycle: {} as Book,
    otherAuthorBooksWithoutCurrentCycleBooks: [] as Array<Book>,
    contentHeight: 0,
    isOpenDropMenu: false,
    openBookFormatPage: 0,
    bookFormatCountPages: 0,
    isSavePlaceReadLoading: false,
    isDefaultSettings: true,
    isOpenSaveUserLoginModalWithLoadSaveChapter: false,
    isOpenLoginModalWithLoadSaveChapter: false,
    isOpenRegisterModalWithLoadChapter: false,
  },
  reducers: {
    changeField: (state, action: PayloadAction<{
      fieldName: DefaultSettingsKeys,
      value: any }>) => {
      state.textSettings = {
        ...state.textSettings,
        [action.payload.fieldName]: action.payload.value,
      };
    },
    setSettings: (state, action: PayloadAction<DefaultSettingsType>) => {
      state.textSettings = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setIsSavePlaceReadLoading: (state, action: PayloadAction<boolean>) => {
      state.isSavePlaceReadLoading = action.payload;
    },
    setIsDefaultSettings: (state, action: PayloadAction<boolean>) => {
      state.isDefaultSettings = action.payload;
    },
    changeSaveChapterProgress: (state, action:PayloadAction<number>) => {
      state.saveChapterProgress = action.payload;
    },
    changeNewChapterProgress: (state, action:PayloadAction<number>) => {
      state.newChapterProgress = action.payload;
    },
    changeSaveChapterId: (state, action:PayloadAction<number>) => {
      state.saveChapterId = action.payload;
    },
    changeNextBookInCycle: (state, action: PayloadAction<Book>) => {
      state.nextBookInCycle = action.payload;
    },
    changeContentHeight: (state, action: PayloadAction<number>) => {
      state.contentHeight = action.payload;
    },
    changeOpenDropMenu: (state, action: PayloadAction<boolean>) => {
      state.isOpenDropMenu = action.payload;
    },
    toggleOpenDropMenu: (state) => {
      state.isOpenDropMenu = !state.isOpenDropMenu;
    },
    changeOpenBookFormatPage: (state, action: PayloadAction<number>) => {
      state.openBookFormatPage = action.payload;
    },
    changeBookFormatCountPages: (state, action: PayloadAction<number>) => {
      state.bookFormatCountPages = action.payload;
    },
    changeOtherAuthorBooksWithoutCurrentCycleBooks: (state, action: PayloadAction<Array<Book>>) => {
      state.otherAuthorBooksWithoutCurrentCycleBooks = action.payload;
    },
    setIsOpenSaveUserLoginModalWithLoadSaveChapter: (state, action: PayloadAction<boolean>) => {
      state.isOpenSaveUserLoginModalWithLoadSaveChapter = action.payload;
    },
    setIsOpenLoginModalWithLoadSaveChapter: (state, action: PayloadAction<boolean>) => {
      state.isOpenLoginModalWithLoadSaveChapter = action.payload;
    },
    setIsOpenRegisterModalWithLoadSaveChapter: (state, action: PayloadAction<boolean>) => {
      state.isOpenRegisterModalWithLoadChapter = action.payload;
    },
  },
});

export const { actions: readerActions, reducer: readerReducer } = readerSlice;
