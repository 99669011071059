import debounce from 'lodash.debounce';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { environments } from '~/lib/const';
import { useAppDispatch } from '~/store';

import { windowSizeActions } from './windowSize.slice';

export const WindowSize = () => {
  const dispatch = useAppDispatch();
  const router = useRouter();

  useEffect(() => {
    const changeSizeHandler = debounce(
      () => {
        dispatch(windowSizeActions.changeSize({
          height: window.innerHeight,
          width: window.innerWidth,
        }));
      },
      300,
    );

    if (environments.isClient) {
      changeSizeHandler();
      window.addEventListener('resize', changeSizeHandler, { passive: true });

      return () => {
        window.removeEventListener('resize', changeSizeHandler);
      };
    }
  }, [environments.isClient, router.pathname]);

  return (
    <>
    </>
  );
};
