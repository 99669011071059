import Link, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import React, {
  Children, FC, HTMLAttributes, isValidElement,
} from 'react';

import { WithChildren } from '~/lib/WithChildren';

export const ActiveLink:FC<LinkProps & WithChildren & HTMLAttributes<HTMLAnchorElement>> = ({
  children, href, as, ...props
}) => {
  const child = Children.only(children);

  const router = useRouter();
  let isActive = false;

  if (isValidElement(child)) {
    isActive = child.props.isActive || false;
  }

  if (router && router.asPath === as) {
    isActive = true;
  }

  if (!as && router && router.pathname === href) {
    isActive = true;
  }

  return (
    <Link prefetch={false} passHref href={href} as={as} {...props}>
      {/* @ts-ignore */}
      { isValidElement(child) && React.cloneElement(child, { 'data-is-active': isActive }) }
    </Link>
  );
};
