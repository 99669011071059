import { AvatarImage } from '~/api/account/accountApiTypes';
import { ImagesFactory } from '~/graphql/books/factory/image/ImagesFactory';

export class AvatarImagesFactory {
  static create(avatarImages: Array<{ format: string; sizeType: string; url: string; }>): Array<AvatarImage> {
    if (!avatarImages && !avatarImages?.length) return null;

    return ImagesFactory.create(avatarImages);
  }
}
