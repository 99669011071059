import { IncomingHttpHeaders } from 'http';

import { DeviceEnum } from '~/api/finance/marketing/campaignsApiTypes';

export const getDeviceFromClient = () => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ? DeviceEnum.Mobile
    : DeviceEnum.Desktop;
};

export const getDeviceFromServer = (headers: IncomingHttpHeaders) => {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(headers['user-agent'])
    ? DeviceEnum.Mobile
    : DeviceEnum.Desktop;
};
