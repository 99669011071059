import { provider } from '~/api/provider/provider.config';
import { getAuthTokenFromClient } from '~/feature/authorization/getAuthToken';
import { environments } from '~/lib/const';

export const setTokenToHeaders = () => {
  if (environments.isClient) {
    const authToken = getAuthTokenFromClient();

    if (authToken) {
      provider.defaults.headers.common.Authorization = `Bearer ${authToken}`;
    }
  }
};

export const deleteTokenFromHeaders = () => {
  if (environments.isClient) {
    delete provider.defaults.headers.common.Authorization;
  }
};
