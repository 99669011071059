import { useRouter } from 'next/router';
import React, { FC } from 'react';
import styled, { css } from 'styled-components';

import { pagesConfigStore } from '~/atomic/atom/links/pagesConfigStore';
import { CommonLayout } from '~/atomic/layout/CommonLayout';
import { HeroLayout } from '~/atomic/layout/HeroLayout';
import {
  LeftNavLayout,
  SCLeftNavLayout,
  SCLeftSideNavWrapper,
} from '~/atomic/layout/LeftNavLayout';
import { authorSelector } from '~/atomic/page/author/author.selector';
import { AuthorCard } from '~/atomic/page/author/AuthorCard';
import { HeroImage } from '~/atomic/page/author/heroImage/HeroImage';
import { SCAuthorCard } from '~/atomic/page/author/styled';
import { DiscountGradeLine } from '~/atomic/page/marketing/cumulativeDiscounts/DiscountGradeLine';
import { AuthorPostsWitdget } from '~/feature/blogs/AuthorPostsWitdget/AuthorPostsWidget';
import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import { lessThan } from '~/lib/mediaQuery';
import { WithChildren } from '~/lib/WithChildren';
import { useAppSelector } from '~/store';

import { VKWidjet } from '../vk/VKWidjet';

export const AuthorTemplate: FC<WithChildren> = ({ children }) => {
  const router = useRouter();
  const { currentAuthor } = useAppSelector(authorSelector);
  const isMobileSize = useAppSelector(isMobileSizeSelector);
  const isAuthorBooksPage = router.asPath === pagesConfigStore.author.as(currentAuthor.username);

  const isShowAuthorPostsWidget = !isMobileSize || isAuthorBooksPage;

  return (
    <SCAuthorLayout>
      <CommonLayout>
        <HeroLayout hero={(
          <HeroImage />
        )}
        >
          <LeftNavLayout
            navCss={SCNav}
            nav={(
              <>
                <SCAuthorCard><AuthorCard /></SCAuthorCard>
                {!!currentAuthor?.discount?.grade && currentAuthor?.discount?.grade > 0 && (
                  <SCDiscountGradeLineInAuthor
                    grade={currentAuthor?.discount?.grade}
                  />
                )}
                {isShowAuthorPostsWidget ? <AuthorPostsWitdget authorUsername={currentAuthor.username} /> : null}
                <VKWidjet
                  widjetSetting={{ width: 'auto' }}
                />
              </>
            )}
          >
            { children }
          </LeftNavLayout>
        </HeroLayout>
      </CommonLayout>
    </SCAuthorLayout>
  );
};

const SCAuthorLayout = styled.div`
  ${SCLeftNavLayout} {
    padding-top: 24px;

    ${lessThan('md')} {
      flex-direction: row;
    }

    ${lessThan('sm')} {
      flex-direction: column;
      padding-left: 0;
      padding-right: 0;
      padding-top: 0;
    };
  }

  ${lessThan('md')} {
    ${SCLeftSideNavWrapper} {
      display: block;
      margin-right: unset;
      margin-bottom: unset;
    }
  };

  ${lessThan('md')} {
    ${SCLeftSideNavWrapper} {
      display: block;
      margin-right: unset;
      margin-bottom: unset;
    }
  };

  ${lessThan('sm')} {
    ${SCLeftSideNavWrapper} {
      width: 100%;
    }
  };
`;

const SCNav = css`
  max-width: 290px;
  
  > *:not(:last-child) {
    margin-bottom: 32px;
  }
  
  ${lessThan('sm')} {
    max-width: 100%;

    > *:not(:last-child) {
      margin-bottom: 12px;
    }
  }
`;

const SCDiscountGradeLineInAuthor = styled(DiscountGradeLine)`
  margin-top: 16px;
  margin-bottom: 14px;

  ${lessThan('sm')} {
    margin: 12px 16px;
  }
`;
