import React from 'react';
import styled from 'styled-components';

import { Button } from '~/atomic/atom/Button';
import Modal from '~/atomic/molecula/Modal';
import { headerSelector } from '~/atomic/organism/Header/header.selector';
import {
  ActiveLibraryEnum,
  headerActions,
} from '~/atomic/organism/Header/header.slice';
import { HeaderLibraryGenres } from '~/atomic/organism/Header/Library/HeaderLibraryGenres';
import { HeaderLibraryTags } from '~/atomic/organism/Header/Library/HeaderLibraryTags';
import { useAppDispatch, useAppSelector } from '~/store';

export const LibraryModal = () => {
  const dispatch = useAppDispatch();
  const { activeLibrary } = useAppSelector(headerSelector);
  const { isOpenLibrary } = useAppSelector(headerSelector);

  const changeActiveLibrary = (newActiveLibrary: ActiveLibraryEnum) => {
    dispatch(headerActions.changeActiveLibrary(newActiveLibrary));
  };

  const closeLibraryHandler = () => {
    dispatch(headerActions.setIsOpenLibrary(false));
  };

  return (
    <SCModal
      onCancel={closeLibraryHandler}
      open={isOpenLibrary}
      centered={false}
    >
      <LibraryButtons>
        <SCButton
          size="large"
          type="gray"
          onClick={() => {
            changeActiveLibrary(ActiveLibraryEnum.Genre);
          }}
          data-is-active={activeLibrary === ActiveLibraryEnum.Genre}
        >
          Жанры
        </SCButton>
        <SCButton
          size="large"
          type="gray"
          onClick={() => {
            changeActiveLibrary(ActiveLibraryEnum.Tag);
          }}
          data-is-active={activeLibrary === ActiveLibraryEnum.Tag}
        >
          Популярные темы
        </SCButton>
      </LibraryButtons>
      {activeLibrary === ActiveLibraryEnum.Genre
        ? <HeaderLibraryGenres />
        : <HeaderLibraryTags /> }
    </SCModal>
  );
};

const LibraryButtons = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  
  > *:first-child {
    border-right: none !important;
  }
`;

const SCButton = styled(Button)` && {
  border: 1px solid var(--gray3-color);
  
  &[data-is-active="true"] {
    background-color: var(--white-color);
  }
}
`;

const SCModal = styled(Modal)`
  max-width: 1100px;
  width: 100% !important;
  margin-top: 20px;
  margin-bottom: 20px;
  top: 0;
`;
