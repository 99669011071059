import React, { FC } from 'react';
import styled from 'styled-components';

import { NavMenu } from '~/atomic/organism/NavMenu';
import { CommercialAuthorNavigationLinks } from '~/feature/user/navigation/CommercialAuthorNavigationLinks';
import { DefaultNavigationLinks } from '~/feature/user/navigation/DefaultNavigationLinks';
import { ExitNavigationLink } from '~/feature/user/navigation/ExitNavigationLink';
import { ReaderNavigationLinks } from '~/feature/user/navigation/ReaderNavigation/ReaderNavigationLinks';

import { AuthorStatusButton } from '../AuthorStatusButton';

export const CommercialAuthorNavigation:FC<{ isMobile?: boolean }> = ({ isMobile = false }) => {
  return (
    <SCNavMenu isMobile={isMobile}>
      <ReaderNavigationLinks />
      <CommercialAuthorNavigationLinks />
      <DefaultNavigationLinks />
      <AuthorStatusButton />
      <ExitNavigationLink />
    </SCNavMenu>
  );
};

const SCNavMenu = styled(NavMenu)`
  > *:not(:last-child) {
    border-bottom: 10px solid var(--border-color);
  }
`;
