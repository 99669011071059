import type { ModalProps as AntdModalProps } from 'antd/lib/modal';
import dynamic from 'next/dynamic';
import React from 'react';
import styled from 'styled-components';

import { CloseIcon } from '~/atomic/atom/icon/CloseIcon';
import { lessThan } from '~/lib/mediaQuery';

interface ModalProps extends AntdModalProps {}

const AntdModal = dynamic(() => import('antd/lib/modal'), { ssr: false });

export const Modal = ({
  children,
  className,
  ...props
}: ModalProps) => {
  return (
    <SCModal
      destroyOnClose
      centered
      maskClosable
      {...props}
      footer={false}
      closeIcon={<SCCloseIcon />}
      className={className}
    >
      {children}
    </SCModal>
  );
};

const SCCloseIcon = styled(CloseIcon)`
  font-size: 12px;  
  color: var(--gray-color);
`;

const SCModal = styled(AntdModal)`
  padding-right: 6px;
  padding-left: 6px;
  
  .ant-modal-header {
    background: var(--bg-color) !important;
    border-bottom: 0;
  }

  .ant-modal-content {
    background: var(--bg-color) !important;
    box-shadow: 0 3px 5px rgba(53, 63, 72, 0.25) !important;
    border-radius: 4px !important;
    padding: 0 !important;
  }

  .ant-modal-body {
    padding-top: 28px;
    padding-bottom: 28px;
    padding-right: 32px;
    padding-left: 32px;

    ${lessThan('sm')} {
      padding-top: 20px;
      padding-bottom: 16px;
      padding-right: 16px;
      padding-left: 16px;
    };
  }
`;

export default Modal;
