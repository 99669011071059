import { AvatarImage, UserRole } from '~/api/account/accountApiTypes';
import { BookIdType } from '~/api/book/bookApiTypes';
import { Methods } from '~/api/finance/financeApiTypes';

export enum CommentAccessStatusEnum {
  Enabled = 'enabled',
  PreModeration = 'pre-moderation',
  Disabled = 'disabled',
}

export enum CommentSettingsNamesEnum {
  Enabled = 'Разрешить комментирование',
  PreModeration = 'Включить премодерацию комментариев',
  Disabled = 'Закрыть комментирование',
}

export enum CommentStatusEnum {
  PreModeration = 'pre-moderation',
  Publish = 'publish',
}

export interface Comment {
  id: string | number;
  content: string;
  targetClass: string;
  createdAt: string;
  targetId: string | number;
  updatedAt: string;
  parentId?: string | number;
  childrenIds?: string;
  reward: number;
  isReview?: boolean;
  status: CommentStatusEnum;
  user: {
    id: string | number;
    username: string;
    name: string;
    role?: UserRole;
    avatarImages?: AvatarImage[];
  }
}

export interface CreateCommentParams {
  targetId: string | number;
  targetClass: string;
  content: string;
  parentId?: string | number;
  reward?: number;
  returnUrl?: string;
  methodPayment?: Methods;
  bankCardId?: number;
  isReview?: boolean;
  replyToComment?: number;
}

export type AddCommentResult = Comment | {
  comment: Comment,
  0: {
    transactionId: number;
    confirmationUrl: string;
  }
};

export interface GetCommentsParams {
  targetId: string | number;
  targetClass: 'book' | 'post';
  page?: number;
  perPage?: number;
}

export interface UpdateCommentParams {
  id: string;
  content: string;
}

export interface DeleteCommentParams {
  id: number | string;
}

export interface CreateComplaintParams {
  id: string;
  cause: string;
}

export interface SetAwardParams {
  commentId: number,
  billId: number
}

export interface GetCommentsResult {
  data: Comment[];
  saveComment?: Comment;
  currentPage: number;
  perPage: number;
  total: number;
}

export interface PublishCommentsParams {
  bookId: BookIdType;
}
