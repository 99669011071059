import { WithToken } from '~/api/auth/authApiTypes';
import {
  AgencyReport,
  AssetBalanceParams,
  BuyBookFreeParams,
  ChangeAutoPaymentStatusParams,
  Commissions,
  ConnecteBillPayoutMethodParams,
  CreateRewardParams,
  DownloadAgreementExclusiveBookSaleProps,
  ExportAgencyReportProps,
  GenerateReferralLinkParams,
  GenerateReferralLinkResult,
  GetBalanceResult,
  GetMethodResult,
  PaymentRequest,
  PaymentRequestsParams,
  Purchase,
  ReferralUrlVisitedParams,
  RegisterPayBookParams,
  RegisterPayBookWithPromocode,
  RegisterPayBookWithPromocodeResult,
  RegisterRewardBookParams,
  RegisterTransactionResult,
  RemoveConnectedPayoutMethodProps,
  SetPartnerParams, TransactionResult,
  TransactionResultParams,
  UnLinkBankCardParams,
  WithdrawMoneyParams,
} from '~/api/finance/financeApiTypes';
import { provider } from '~/api/provider/provider.config';
import { handleRejectedRequest } from '~/api/provider/providerErrors';
import {
  ApiResponse, PaginatedResponse, RejectedRequest,
} from '~/api/provider/providerTypes';
import { getDeviceSessionUid } from '~/feature/utils/deviceSessionUid';

const namespace = Symbol('namespace');

export const financeApi = {
  [namespace]: '/v1/finance',

  async getMethods() {
    try {
      const response = await provider.get<
      ApiResponse<GetMethodResult[], RejectedRequest<void>>
      >(
        `${this[namespace]}/methods`,
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async registerPayBook(data: RegisterPayBookParams) {
    try {
      const response = await provider.post<
      RegisterTransactionResult | RejectedRequest<void>
      >(`${this[namespace]}/payBook`, data);
      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async registerPayAudioBook(data: RegisterPayBookParams) {
    try {
      const response = await provider.post<
      RegisterTransactionResult | RejectedRequest<void>
      >(`${this[namespace]}/payAudiobook`, data);
      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async registerPayBookWithPromocode(data: RegisterPayBookWithPromocode) {
    try {
      const response = await provider.post<
      ApiResponse<RegisterPayBookWithPromocodeResult, RejectedRequest<void>>
      >(`${this[namespace]}/payBook/promocode`, data);
      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async buyBookFree(data: BuyBookFreeParams) {
    try {
      const response = await provider.post<
      RegisterTransactionResult | RejectedRequest<void>
      >(`${this[namespace]}/payBook/free`, data);
      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async registerRewardBook(data: RegisterRewardBookParams) {
    try {
      const response = await provider.post<
      RegisterTransactionResult
      >(`${this[namespace]}/reward`, data);
      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async transactionResult(data: TransactionResultParams) {
    try {
      const response = await provider.post<{ purchase: TransactionResult }>(
        `${this[namespace]}/transaction/${data.transactionId}/finish/yandex`,
      );
      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async transactionTinkoffResult(data: TransactionResultParams) {
    try {
      const response = await provider.post<{ purchase: TransactionResult }>(
        `${this[namespace]}/transaction/${data.transactionId}/finish/tinkoff`,
      );
      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getBalance(data: WithToken | void) {
    try {
      const response = await provider.get<
      ApiResponse<GetBalanceResult, RejectedRequest<void>>
      >(`${this[namespace]}/balance`, {
        headers: {
          Authorization: data && data?.token
            ? `Bearer ${data?.token}`
            : provider.defaults.headers.common.Authorization,
        },
      });
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getPurchase() {
    try {
      const response = await provider.get<
      ApiResponse<Purchase[], RejectedRequest<void>>
      >(
        `${this[namespace]}/bills/purchased`,
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async createReward(data: CreateRewardParams) {
    try {
      const response = await provider.post<
      ApiResponse<void, RejectedRequest<void>>
      >(`${this[namespace]}/register/reward`, data);
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getCommissions() {
    try {
      const response = await provider.get<
      ApiResponse<Commissions, RejectedRequest<void>>
      >(
        `${this[namespace]}/commissions`,
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async unLinkBankCard(data: UnLinkBankCardParams) {
    try {
      const response = await provider.delete<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/bankCard/${data.bankCardId}/unlink`,
      );
      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async generateReferralLink(data: GenerateReferralLinkParams) {
    try {
      const response = await provider.get<
      ApiResponse<GenerateReferralLinkResult, RejectedRequest<void>>
      >(
        `${this[namespace]}/partnerProgram/linkToPageWithPartnerId`,
        { params: { url: data.returnUrl } },
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async referralUrlVisited(data: ReferralUrlVisitedParams) {
    try {
      const deviceSessionUid = getDeviceSessionUid();

      const response = await provider.post<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/partnerProgram/visit`,
        data,
        { headers: { 'device-session-uid': deviceSessionUid } },
      );

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async updatePartner(data: SetPartnerParams) {
    try {
      const deviceSessionUid = getDeviceSessionUid();

      const response = await provider.put<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/partnerProgram/partner`,
        data,
        { headers: { 'device-session-uid': deviceSessionUid } },
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async deletePartner() {
    try {
      await provider.delete<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/partnerProgram/partner`,
      );
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async assetBalance(data: AssetBalanceParams) {
    try {
      const response = await provider.post<
      ApiResponse<RegisterTransactionResult, RejectedRequest<void>>
      >(`${this[namespace]}/transaction/assetBalance`, data);
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async removeConnectedBillPayoutMethod(data: RemoveConnectedPayoutMethodProps) {
    try {
      await provider.delete<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/paymentRequest/attached/bill/${data.uuid}`,
      );
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async getAgencyReports() {
    try {
      const response = await provider.get<
      ApiResponse<AgencyReport[], RejectedRequest<void>>
      >(
        `${this[namespace]}/agencyReports`,
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async connecteBillPayoutMethod(data: ConnecteBillPayoutMethodParams) {
    try {
      await provider.post<
      ApiResponse<void, RejectedRequest<void>>
      >(`${this[namespace]}/paymentRequest/attached/bill/`, data);
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async withdrawMoney(data: WithdrawMoneyParams) {
    try {
      await provider.post<
      ApiResponse<void, RejectedRequest<void>>
      >(`${this[namespace]}/paymentRequest`, data);
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async removeConnectedCardPayoutMethod(data: RemoveConnectedPayoutMethodProps) {
    try {
      await provider.delete<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/paymentRequest/attached/card/${data.uuid}`,
      );
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async changeAutoPaymentStatus(data: ChangeAutoPaymentStatusParams) {
    try {
      await provider.put<
      ApiResponse<void, RejectedRequest<void>>
      >(`${this[namespace]}/paymentRequest/settings`, data);
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async exportAgencyReport(data: ExportAgencyReportProps) {
    try {
      const response = await provider({
        url:
          `${this[namespace]}/agencyReports/export`,
        method: 'POST',
        responseType: 'arraybuffer',
        data,
      });
      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getConnectCardWithdrawUrl() {
    try {
      const response = await provider.get<
      ApiResponse<{ url: string }, RejectedRequest<void>>
      >(
        `${this[namespace]}/paymentRequest/attached/card/url`,
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async getPaymentRequests(data: PaymentRequestsParams) {
    try {
      const response = await provider.get<
      PaginatedResponse<PaymentRequest[], RejectedRequest<void>>
      >(`${this[namespace]}/paymentRequest`, { params: data });
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async downloadAgreementExclusiveBookSale(data: DownloadAgreementExclusiveBookSaleProps) {
    try {
      const response = await provider.get<
      PaginatedResponse<PaymentRequest[], RejectedRequest<void>>
      >(
        `${this[namespace]}/documents/agreement-exclusive-book-sale/${data.bookId}`,
        { responseType: 'arraybuffer' },
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
};
