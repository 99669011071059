import Image from 'next/image';
import React from 'react';
import styled from 'styled-components';

import { PostThemeEnum } from '~/api/blog/blogTypes';
import { SCAgeRating } from '~/atomic/atom/AgeRating';
import { BookriverMobileLink } from '~/atomic/atom/BookriverMobileLink';
import { MastercardIcon } from '~/atomic/atom/icon/MastercardIcon';
import { OkIcon } from '~/atomic/atom/icon/OkIcon';
import { VisaIcon } from '~/atomic/atom/icon/VisaIcon';
import { VkIcon } from '~/atomic/atom/icon/VkIcon';
import { BlogsThemeLink, GenreLink } from '~/atomic/atom/links/dynamicLinks';
import {
  AboutPartnersProgramLink,
  AboutUsLink,
  AntiPirateCheckLink,
  AntiPirateLink, ContactsLink,
  ImAuthorLink, PartnersBooksLink,
  PrivacyPolicyLink,
  UserPolicyLink,
} from '~/atomic/atom/links/staticLinks';
import { Accordion } from '~/atomic/molecula/Accordion';
import { TextBoldStyle, TextSmallStyle } from '~/atomic/Typography';
import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import { getCurrentYear } from '~/lib/getCurrentYear';
import { lessThan } from '~/lib/mediaQuery';
import { useAppSelector } from '~/store';

export const Footer = () => {
  const mobileDeviceSize = useAppSelector(isMobileSizeSelector);

  return (
    <SCFooter>
      <SCContent>
        <SCNav>
          {mobileDeviceSize ? (
            <SCList as="noindex">
              <SCAccordion
                title={(
                  <SCItemTitle>
                    Букривер
                  </SCItemTitle>
                )}
                content={(
                  <SCItem as="div">
                    <AboutUsLink>
                      <SCLink>
                        О проекте
                      </SCLink>
                    </AboutUsLink>
                    <ContactsLink>
                      <SCLink>
                        Контакты
                      </SCLink>
                    </ContactsLink>
                    <ImAuthorLink>
                      <SCLink>
                        Авторам
                      </SCLink>
                    </ImAuthorLink>
                    <BlogsThemeLink
                      theme={PostThemeEnum.ProjectNews}
                    >
                      <SCLink>
                        Новости
                      </SCLink>
                    </BlogsThemeLink>
                  </SCItem>
                )}
              />
              <SCAccordion
                title={(
                  <SCItemTitle>
                    Помощь
                  </SCItemTitle>
                )}
                content={(
                  <SCItem as="div">
                    <SCLink
                      href="https://support.bookriver.ru/knowledge_base/category/47565"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Вопросы и ответы
                    </SCLink>
                    <SCLink
                      href=" https://support.bookriver.ru/ideas/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Идеи для развития
                    </SCLink>
                  </SCItem>
                )}
              />
              <SCAccordion
                title={(
                  <SCItemTitle>
                    Правила
                  </SCItemTitle>
                )}
                content={(
                  <SCItem as="div">
                    <UserPolicyLink>
                      <SCLink>
                        Пользовательское соглашение
                      </SCLink>
                    </UserPolicyLink>
                    <PrivacyPolicyLink>
                      <SCLink>
                        Политика конфиденциальности
                      </SCLink>
                    </PrivacyPolicyLink>
                  </SCItem>
                )}
              />
              <SCAccordion
                title={(
                  <SCItemTitle>
                    Авторские права
                  </SCItemTitle>
                )}
                content={(
                  <SCItem as="div">
                    <AntiPirateLink>
                      <SCLink>
                        Защита от пиратов
                      </SCLink>
                    </AntiPirateLink>
                    <AntiPirateCheckLink>
                      <SCLink>
                        Проверить книгу
                      </SCLink>
                    </AntiPirateCheckLink>
                  </SCItem>
                )}
              />
              <SCAccordion
                title={(
                  <SCItemTitle>
                    Партнерам
                  </SCItemTitle>
                )}
                content={(
                  <SCItem as="div">
                    <AboutPartnersProgramLink>
                      <SCLink>
                        Зарабатывайте с авторами
                      </SCLink>
                    </AboutPartnersProgramLink>
                    <PartnersBooksLink>
                      <SCLink>
                        Предложения авторов
                      </SCLink>
                    </PartnersBooksLink>
                  </SCItem>
                )}
              />
              <SCAccordion
                title={(
                  <SCItemTitle>
                    Популярные жанры
                  </SCItemTitle>
                )}
                content={(
                  <SCItem as="div">
                    <GenreLink slug="popadantsy">
                      <SCLink>
                        Попаданцы
                      </SCLink>
                    </GenreLink>
                    <GenreLink slug="magicheskie-akademii">
                      <SCLink>
                        Магические академии
                      </SCLink>
                    </GenreLink>
                    <GenreLink slug="sovremennyi-lyubovnyi-roman">
                      <SCLink>
                        Современный любовный роман
                      </SCLink>
                    </GenreLink>
                    <GenreLink slug="lyubovnoe-fentezi">
                      <SCLink>
                        Любовное фэнтези
                      </SCLink>
                    </GenreLink>
                    <GenreLink slug="litrpg">
                      <SCLink>
                        ЛитРПГ
                      </SCLink>
                    </GenreLink>
                  </SCItem>
                )}
              />
            </SCList>
          ) : (
            <SCList>
              <SCItem>
                <SCItemTitle>
                  Букривер
                </SCItemTitle>
                <AboutUsLink>
                  <SCLink>
                    О проекте
                  </SCLink>
                </AboutUsLink>
                <ContactsLink>
                  <SCLink>
                    Контакты
                  </SCLink>
                </ContactsLink>
                <ImAuthorLink>
                  <SCLink>
                    Авторам
                  </SCLink>
                </ImAuthorLink>
                <BlogsThemeLink
                  theme={PostThemeEnum.ProjectNews}
                >
                  <SCLink>
                    Новости
                  </SCLink>
                </BlogsThemeLink>
              </SCItem>

              <SCItem>
                <SCItemTitle>
                  Правила
                </SCItemTitle>
                <UserPolicyLink>
                  <SCLink>
                    Пользовательское соглашение
                  </SCLink>
                </UserPolicyLink>
                <PrivacyPolicyLink>
                  <SCLink>
                    Политика конфиденциальности
                  </SCLink>
                </PrivacyPolicyLink>
                <SCChildItem style={{ marginTop: '30px' }}>
                  <SCItemTitle>
                    Помощь
                  </SCItemTitle>
                  <SCLink
                    href="https://support.bookriver.ru/knowledge_base/category/47565"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Вопросы и ответы
                  </SCLink>
                  <SCLink
                    href=" https://support.bookriver.ru/ideas/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Идеи для развития
                  </SCLink>
                </SCChildItem>
              </SCItem>
              <SCItem>
                <SCItemTitle>
                  Авторские права
                </SCItemTitle>
                <AntiPirateLink>
                  <SCLink>
                    Защита от пиратов
                  </SCLink>
                </AntiPirateLink>
                <AntiPirateCheckLink>
                  <SCLink>
                    Проверить книгу
                  </SCLink>
                </AntiPirateCheckLink>
                <SCChildItem style={{ marginTop: '30px' }}>
                  <SCItemTitle>
                    Партнерам
                  </SCItemTitle>
                  <AboutPartnersProgramLink>
                    <SCLink>
                      Зарабатывайте с авторами
                    </SCLink>
                  </AboutPartnersProgramLink>
                  <PartnersBooksLink>
                    <SCLink>
                      Предложения авторов
                    </SCLink>
                  </PartnersBooksLink>
                </SCChildItem>
              </SCItem>
              <SCItem>
                <SCItemTitle>
                  Популярные жанры
                </SCItemTitle>
                <GenreLink slug="popadantsy">
                  <SCLink>
                    Попаданцы
                  </SCLink>
                </GenreLink>
                <GenreLink slug="magicheskie-akademii">
                  <SCLink>
                    Магические академии
                  </SCLink>
                </GenreLink>
                <GenreLink slug="sovremennyi-lyubovnyi-roman">
                  <SCLink>
                    Современный любовный роман
                  </SCLink>
                </GenreLink>
                <GenreLink slug="lyubovnoe-fentezi">
                  <SCLink>
                    Любовное фэнтези
                  </SCLink>
                </GenreLink>
                <GenreLink slug="litrpg">
                  <SCLink>
                    ЛитРПГ
                  </SCLink>
                </GenreLink>
              </SCItem>
            </SCList>
          )}
        </SCNav>
        <SCBottom>
          <SCInfo>
            <SCAgeWrap>
              <SCAgeRating>
                18+
              </SCAgeRating>
              <SCAgeText>
                Сайт содержит информацию для
                просмотра лицами старше 18 лет
              </SCAgeText>
            </SCAgeWrap>
            <SCEmailWrap>
              <SCEmailText>
                Служба поддержки:
              </SCEmailText>
              <SCEmail
                href="mailto:support@bookriver.ru"
              >
                support@bookriver.ru
              </SCEmail>
            </SCEmailWrap>
          </SCInfo>
          <SCBottomIcons>
            <BookriverMobileLink>
              <Image
                width="128"
                height="38"
                src="/image/google_dowload_app.jpg"
                alt="изображение google store"
              />
            </BookriverMobileLink>
            <SCCards>
              <SCVisaIcon />
              <SCMastercardIcon />
            </SCCards>
            <SCSocials>
              <SCSocialLink href="https://vk.com/bookriverru">
                <SCVkIcon />
              </SCSocialLink>
              <SCSocialLink href="https://ok.ru/group/59580287680609">
                <SCOkIcon />
              </SCSocialLink>
            </SCSocials>
          </SCBottomIcons>
        </SCBottom>
        <SCCopywriting>
          2020-
          {getCurrentYear()}
          {' '}
          © Bookriver — литературно-издательская площадка,
          объединяющая читателей и авторов.
        </SCCopywriting>
      </SCContent>
    </SCFooter>
  );
};

export const SCFooter = styled.footer`
  width: 100%;
  background: var(--white-color);
  box-shadow: 0 -1px 1px rgba(141, 147, 162, 0.25);
`;

const SCContent = styled.div`
  max-width: var(--container-width);
  margin: 0 auto;
  padding: 24px 16px 32px 16px;
`;

const SCNav = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;

  ${lessThan('sm')} {
    border-bottom: 1px solid var(--gray3-color);
  }
`;

const SCList = styled.ul`
  display: flex;
  justify-content: space-between;
  margin-bottom: 38px;
  width: 100%;

  ${lessThan('sm')} {
    margin-bottom: 26px;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SCItem = styled.li`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SCChildItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SCItemTitle = styled.span`
  ${TextBoldStyle};
  color: var(--black-color);
  margin-bottom: 14px;

  ${lessThan('sm')} {
    margin-bottom: 0;
  }
`;

const SCLink = styled.a`
  font-family: var(--second-font);
  color: var(--black-color);
  font-size: 14px;
  line-height: 22px;
  display: flex;

  &[data-is-empty="true"] {
    color: var(--gray-color);
    pointer-events: none;
  }

  &:not(:last-child) {
    margin-bottom: 8px;
  }

  ${lessThan('sm')} {
    margin-bottom: 8px;
  }
`;

const SCBottom = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${lessThan('sm')} {
    flex-direction: column-reverse;
    padding-top: 25px;
    align-items: flex-start;
  }
`;

const SCBottomIcons = styled.div`
  width: 45%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${lessThan('sm')} {
    margin-bottom: 25px;
    width: 100%;
  }

`;

const SCAgeWrap = styled.div`
  display: flex;
  align-items: center;

  ${lessThan('sm')} {
    width: 100%;
    max-width: 300px;
  }

  ${lessThan('xs')} {
    margin-right: 0;
  }
`;

const SCAgeText = styled.span`
  ${TextSmallStyle};
  color: var(--gray-color);
  max-width: 220px;
  width: 100%;
  margin-left: 16px;

  ${lessThan('sm')} {
    max-width: 100%;
  }
`;

const SCCards = styled.div`
  display: flex;
  align-items: center;

  > *:not(:last-child) {
    margin-right: 8px;
  }

  ${lessThan('sm')} {
    > *:not(:last-child) {
      margin-right: 12px;
    }
  }
`;

const SCSocials = styled.div`
  display: flex;
  align-items: center;
`;

const SCSocialLink = styled.a`
  display: block;
  width: 32px;
  height: 32px;
  background: var(--gray2-color);
  border-radius: 2px;
  transition: 0.2s;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:hover {
    background: #ececec;
  }
`;

const SCVisaIcon = styled(VisaIcon)`
  font-size: 42px;
  color: var(--gray-color);
`;

const SCMastercardIcon = styled(MastercardIcon)`
  font-size: 42px;
  color: var(--gray-color);
`;

const SCVkIcon = styled(VkIcon)`
  font-size: 32px;
  color: var(--vk-color);
`;

const SCOkIcon = styled(OkIcon)`
  font-size: 32px;
  color: var(--ok-color);
`;

const SCInfo = styled.div`
  display: flex;
  align-items: center;
  width: 45%;
  flex-shrink: 0;
  justify-content: space-between;

  ${lessThan('sm')} {
    width: 100%;
  }

  ${lessThan('xs')} {
    align-items: flex-start;
    flex-direction: column;
  }
`;

const SCEmailWrap = styled.div`
  display: flex;
  flex-direction: column;

  ${lessThan('xs')} {
    margin-top: 15px;
  }
`;

const SCEmailText = styled.span`
  ${TextSmallStyle};
  color: var(--gray-color);
`;

const SCEmail = styled.a`
  ${TextSmallStyle};
  color: var(--black-color);
`;

const SCAccordion = styled(Accordion)`
  ${lessThan('sm')} {
    margin-bottom: 14px;
    border-bottom: 1px solid var(--gray3-color);
  }
`;

const SCCopywriting = styled.div`
  ${TextSmallStyle};
  color: var(--gray-color);
  margin-top: 40px;
`;
