import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const topProgressBarSliceName = 'topProgressBar';

type State = 'loading' | 'stop';

const topProgressBarSlice = createSlice({
  name: topProgressBarSliceName,
  initialState: {
    isActive: true,
    state: 'stop' as State,
    timerId: null as null | number,
    activeRequests: 0,
  },
  reducers: {
    setTimerId(state, action: PayloadAction<number | null>) {
      state.timerId = action.payload;
    },
    setState(state, action: PayloadAction<State>) {
      state.state = action.payload;
    },
    setIsActive(state, action: PayloadAction<boolean>) {
      state.isActive = action.payload;
    },
    changeActiveRequestsCount(state, action: PayloadAction<number>) {
      state.activeRequests = action.payload;
    },
  },
});

export const {
  reducer: topProgressBarReducer,
  actions: topProgressBarActions,
} = topProgressBarSlice;
