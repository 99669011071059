import React, { FC, ReactChild } from 'react';
import styled from 'styled-components';

import { lessThan } from '~/lib/mediaQuery';

import { TextRegStyle, TextSmallStyle } from '../Typography';

interface FieldProps extends React.HTMLAttributes<HTMLDivElement> {
  error?: string | string[] | null;
  label?: string | ReactChild;
  tip?: string | ReactChild;
  children?: any;
  style?: React.CSSProperties;
  description?: string;
}

export const Field: FC<FieldProps> = ({
  error, label, children, style, tip, description, ...props
}) => {
  return (
    <SCField {...props} style={style}>
      <SCTop>
        {label && (
          <SCLabel>{label}</SCLabel>
        )}
        {tip && (
          <SCTip>{tip}</SCTip>
        )}
      </SCTop>
      {description && (
        <SCDescription>{description}</SCDescription>
      )}
      <SCChildren>{children}</SCChildren>
      {error ? (
        <SCError>{error}</SCError>
      ) : null }
    </SCField>
  );
};

export const SCField = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const SCTop = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  width: 100%;

  ${lessThan('xxs')} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

const SCLabel = styled.span`
  ${TextRegStyle};
  color: var(--black-color);
  margin-bottom: 7px;

  ${lessThan('xxs')} {
    margin-bottom: 0;
  }
`;

const SCTip = styled.span`
  ${TextSmallStyle};
  color: var(--gray-color);
`;

export const SCError = styled.span`
  ${TextSmallStyle};
  color: var(--error-color);
`;

const SCChildren = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
`;

const SCDescription = styled.span`
  ${TextSmallStyle};
  color: var(--gray-color);
  margin-top: 2px;
  margin-bottom: 7px;
`;
