import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { MethodPayment, Methods } from '~/api/finance/financeApiTypes';
import { financePageSliceName } from '~/atomic/page/finance/financePage.slice';

export const choosePaySlice = createSlice({
  name: `${financePageSliceName}/choosePay`,
  initialState: {
    methods: [] as MethodPayment[],
    isLoading: false,
    isPayLoading: false,
    unLinkBankCardLoading: false,
    activeMethod: { type: Methods.bankCard } as MethodPayment,
    isOpenModal: false,
    isConfirmSaveBankCardModal: false,
    isSaveBankCard: true,
    isOpenSaveUserLoginModalWithChoosePayBook: false,
    isOpenLoginModalWithChoosePayBook: false,
    isOpenRegisterModalWithChoosePayBook: false,
    isOpenSaveUserLoginModalWithChoosePayAudioBook: false,
    isOpenLoginModalWithChoosePayAudioBook: false,
    isOpenRegisterModalWithChoosePayAudioBook: false,
  },
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setIsConfirmSaveBankCardModal: (state, action: PayloadAction<boolean>) => {
      state.isConfirmSaveBankCardModal = action.payload;
    },
    setIsOpenSaveUserLoginModalWithChoosePayBook: (state, action: PayloadAction<boolean>) => {
      state.isOpenSaveUserLoginModalWithChoosePayBook = action.payload;
    },
    setIsOpenLoginModalWithChoosePayBook: (state, action: PayloadAction<boolean>) => {
      state.isOpenLoginModalWithChoosePayBook = action.payload;
    },
    setIsOpenRegisterModalWithChoosePayBook: (state, action: PayloadAction<boolean>) => {
      state.isOpenRegisterModalWithChoosePayBook = action.payload;
    },
    setIsOpenSaveUserLoginModalWithChoosePayAudioBook: (state, action: PayloadAction<boolean>) => {
      state.isOpenSaveUserLoginModalWithChoosePayAudioBook = action.payload;
    },
    setIsOpenLoginModalWithChoosePayAudioBook: (state, action: PayloadAction<boolean>) => {
      state.isOpenLoginModalWithChoosePayAudioBook = action.payload;
    },
    setIsOpenRegisterModalWithChoosePayAudioBook: (state, action: PayloadAction<boolean>) => {
      state.isOpenRegisterModalWithChoosePayAudioBook = action.payload;
    },
    setIsOpenModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenModal = action.payload;
    },
    setIsPayLoading: (state, action: PayloadAction<boolean>) => {
      state.isPayLoading = action.payload;
    },
    setIsSaveBankCard: (state, action: PayloadAction<boolean>) => {
      state.isSaveBankCard = action.payload;
    },
    setUnLinkBankCardLoading: (state, action: PayloadAction<boolean>) => {
      state.unLinkBankCardLoading = action.payload;
    },
    setMethods: (state, action: PayloadAction<MethodPayment[]>) => {
      state.methods = action.payload;
    },
    changeActiveMethod: (state, action: PayloadAction<MethodPayment>) => {
      state.activeMethod = action.payload;
    },
  },
});

export const { actions: choosePayActions, reducer: choosePayReducer } = choosePaySlice;
