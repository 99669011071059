import React from 'react';
import styled from 'styled-components';

import { BullhornIcon } from '~/atomic/atom/icon/BullhornIcon';
import { pagesConfigStore } from '~/atomic/atom/links/pagesConfigStore';
import { NavItem, SCNavMenuIcon } from '~/atomic/organism/NavMenu';
import { SCCounter, TextBoldStyle } from '~/atomic/Typography';
import { userSelector } from '~/feature/user/user.selector';
import { numberFormatter } from '~/lib/numberFormatter';
import { useAppSelector } from '~/store';

export const CommercialAuthorNavigationLinks = () => {
  const { userCounters, user } = useAppSelector(userSelector);

  return (
    <SCCommercialAuthorNavigationLinks>
      <SCList>
        <NavItem href={pagesConfigStore.books.url} shallow>
          <SCNavMenuIcon src="/icon/books.svg" />
          Мои произведения
          {' '}
          {userCounters?.books > 0 && (
            <SCCounter>
              {userCounters?.books > 99 ? '99+' : userCounters?.books}
            </SCCounter>
          )}
        </NavItem>
        {user && user?.author?.adAccount?.isActive ? (
          <NavItem href={pagesConfigStore.campaigns.url} shallow>
            <SCBullhornIcon />
            Реклама
            {user?.author?.adAccount?.availableBalance >= 0 ? (
              <SCCounter>
                {numberFormatter(user?.author?.adAccount?.availableBalance)}
                {' '}
                ₽
              </SCCounter>
            ) : (
              <SCNegativeBalance>
                {numberFormatter(user?.author?.adAccount?.availableBalance)}
                {' '}
                ₽
              </SCNegativeBalance>
            )}
          </NavItem>
        ) : null}
        <NavItem href={pagesConfigStore.marketing.url} shallow>
          <SCNavMenuIcon src="/icon/promotion.svg" />
          Продвижение
        </NavItem>
        <NavItem href={pagesConfigStore.statistics.url} shallow>
          <SCNavMenuIcon src="/icon/statistics.svg" />
          Статистика
        </NavItem>
        <NavItem href={pagesConfigStore.myblog.url} shallow>
          <SCNavMenuIcon src="/icon/record.svg" />
          Блог
          {userCounters?.posts > 0 && (
            <SCCounter>
              {userCounters?.posts > 99 ? '99+' : userCounters?.posts}
            </SCCounter>
          )}
        </NavItem>
        <NavItem
          href={pagesConfigStore.authorSubscribers.url}
          as={pagesConfigStore.authorSubscribers.as(user.username)}
        >
          <SCNavMenuIcon src="/icon/subscribers.svg" alt="иконка человека" />
          Подписчики
          {' '}
          {user?.counters?.subscribers !== 0 && (
            <SCCounter>{user?.counters?.subscribers}</SCCounter>
          )}
        </NavItem>
      </SCList>
    </SCCommercialAuthorNavigationLinks>
  );
};

const SCCommercialAuthorNavigationLinks = styled.li``;

const SCList = styled.ul`
  > * {
    border-bottom: 1px solid var(--bg-color);
    border-top: 1px solid var(--bg-color);
  }
`;

const SCBullhornIcon = styled(BullhornIcon)`
  font-size: 20px;
  margin-right: 20px;
`;

const SCNegativeBalance = styled.div`
  background-color: var(--error-color);
  padding: 2px 10px;
  ${TextBoldStyle};
  color: var(--white-color);
  margin-left: auto;
  margin-right: 20px;
`;
