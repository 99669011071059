import styled from 'styled-components';

export const TextSmallStyle = {
  'font-family': 'var(--second-font)',
  'font-style': 'normal',
  'font-weight': 'normal',
  'font-size': '13px',
  'line-height': '20px',
};

export const SCTextSmall = styled.span`
  ${TextSmallStyle};
`;

export const TextMediumStyle = {
  'font-family': 'var(--main-font)',
  'font-style': 'normal',
  'font-weight': '500',
  'font-size': '15px',
  'line-height': '20px',
};

export const SCTextMedium = styled.span`
  ${TextMediumStyle}
`;

export const TextBoldStyle = {
  'font-family': 'var(--main-font)',
  'font-style': 'normal',
  'font-weight': '600',
  'font-size': '15px',
  'line-height': '20px',
};

export const SCTextBold = styled.span`
  ${TextBoldStyle};
`;

export const TextRegStyle = {
  'font-family': 'var(--second-font)',
  'font-style': 'normal',
  'font-weight': 'normal',
  'font-size': '14px',
  'line-height': '22px',
};

export const SCTextReg = styled.span`
  ${TextRegStyle};
`;

export const PageTitleStyle = {
  'font-family': 'var(--main-font)',
  'font-style': 'normal',
  'font-weight': '500',
  'font-size': '20px',
  'line-height': '24px',
  margin: '0',
  color: 'var(--black-color)',
};

export const SCPageTitle = styled.h1`
  ${PageTitleStyle};
`;

export const SCCounter = styled.span`
  ${TextBoldStyle};
  margin-left: auto;
  margin-right: 20px;
  color: var(--gray-color);
`;

export const LinkStyle = {
  color: 'var(--primary-color)',
  'transition-property': 'color',
  transition: '0.3s',
};

export const MobH2Style = {
  'font-family': 'var(--main-font)',
  'font-style': 'normal',
  'font-weight': '600',
  'font-size': '15px',
  'line-height': '16px',
};

export const SCMobH2 = styled.h2`
  ${MobH2Style}
`;

export const TextTagStyle = {
  'font-family': 'var(--second-font)',
  'font-style': 'normal',
  'font-weight': 'bold',
  'font-size': '10px',
  'line-height': '10px',
  'letter-spacing': '0.05em',
  'text-transform': 'uppercase',
};

export const SCTextTag = styled.h2`
  ${TextTagStyle}
`;

export const LandingTextStyle = {
  'font-family': 'var(--landing-font)',
  'font-style': 'normal',
  'font-weight': 'normal',
  'font-size': '18px',
  'line-height': '26px',
};

export const SCLandingText = styled.h2`
  ${LandingTextStyle}
`;

export const LandingH1Style = {
  'font-family': 'var(--landing-main-font)',
  'font-style': 'normal',
  'font-weight': 'normal',
  'font-size': '48px',
  'line-height': '133%',
};

export const SCLandingH1Style = styled.h2`
  ${LandingTextStyle}
`;

export const LandingH2Style = {
  'font-family': 'var(--landing-main-font)',
  'font-style': 'normal',
  'font-weight': 'normal',
  'font-size': '36px',
  'line-height': '133%',
};

export const SCLandingH2Style = styled.h2`
  ${LandingTextStyle}
`;
