import { createSelector } from '@reduxjs/toolkit';

import { SearchFieldPlaces } from '~/feature/search/search.slice';
import { RootState } from '~/store';

const searchResultHeaderSelector = createSelector(
  (state: RootState) => state.search,
  (search) => search.places.Header,
);

const searchResultEmptyPurchasesPageSelector = createSelector(
  (state: RootState) => state.search,
  (search) => search.places.EmptyPurchasePage,
);

const searchResultHeaderMobileSelector = createSelector(
  (state: RootState) => state.search,
  (search) => search.places.HeaderMobile,
);

export const searchByPlaceSelector = (place: SearchFieldPlaces) => {
  switch (place) {
    case SearchFieldPlaces.EmptyPurchasePage:
      return searchResultEmptyPurchasesPageSelector;
    case SearchFieldPlaces.Header:
      return searchResultHeaderSelector;
    case SearchFieldPlaces.HeaderMobile:
      return searchResultHeaderMobileSelector;
    default:
      return undefined;
  }
};
