import React, { FC } from 'react';
import styled from 'styled-components';

import { pagesConfigStore } from '~/atomic/atom/links/pagesConfigStore';
import { PaperStyle } from '~/atomic/atom/PaperStyle';
import { UserView } from '~/atomic/molecula/UserView';
import {
  NavItem, NavMenu, SCNav, SCNavMenuIcon,
} from '~/atomic/organism/NavMenu';
import { toggleSubscription } from '~/atomic/page/author/author.data';
import { authorSelector } from '~/atomic/page/author/author.selector';
import { SCCounter } from '~/atomic/Typography';
import { SubscriptionButton } from '~/feature/subscriptions/SubscriptionButton';
import { userSelector } from '~/feature/user/user.selector';
import { lessThan } from '~/lib/mediaQuery';
import { useAppDispatch, useAppSelector } from '~/store';

import { SCAuthorCardPercent, SCAuthorCardPercentIcon } from './styled';

export const AuthorCard: FC = () => {
  const dispatch = useAppDispatch();

  const {
    currentAuthor,
    subscriptionLoading,
    isSubscription,
  } = useAppSelector(authorSelector);

  const subscribeClickHandler = () => {
    dispatch(toggleSubscription({ authorUsername: currentAuthor.username }));
  };

  const { user } = useAppSelector(userSelector);

  return (
    <SCAuthorCardWrapper>
      <SCAuthorCard>
        {currentAuthor?.discount?.percent > 0 && (
          <SCAuthorCardPercent>
            ваша скидка
            {' '}
            {currentAuthor?.discount?.percent}
            %
            <SCAuthorCardPercentIcon
              src="/icon/components/AuthorCard/authorPercent.svg"
              alt="иконка внимания"
            />
          </SCAuthorCardPercent>
        )}
        <SCWrapper>
          <UserView
            userInfo={currentAuthor}
          />
          {(currentAuthor.id !== user.id) && (
            <SCSubscriptionBtnWrap>
              <SubscriptionButton
                isLoading={subscriptionLoading}
                isSubscription={isSubscription}
                onClickHandler={subscribeClickHandler}
              />
            </SCSubscriptionBtnWrap>
          )}
        </SCWrapper>

        <SCNavMenu>
          <NavMenu>
            <NavItem
              href={pagesConfigStore.author.url}
              as={pagesConfigStore.author.as(currentAuthor?.username)}
            >
              <SCNavMenuIcon src="/icon/books.svg" alt="иконка книги" />
              Произведения
              {' '}
              {currentAuthor?.counters?.books !== 0 && (
                <SCCounter>{currentAuthor?.counters?.books}</SCCounter>
              )}
            </NavItem>
            {currentAuthor?.counters?.posts !== 0 && (
              <NavItem
                href="/author/[authorUsername]/blog"
                as={`/author/${currentAuthor.username}/blog`}
              >
                <SCNavMenuIcon src="/icon/pencil.svg" alt="иконка ручки" />
                Блог
                <SCCounter>{currentAuthor?.counters?.posts}</SCCounter>
              </NavItem>
            )}
            <NavItem
              href={pagesConfigStore.authorSubscribers.url}
              as={pagesConfigStore.authorSubscribers.as(currentAuthor.username)}
              rel="nofollow"
            >
              <SCNavMenuIcon src="/icon/subscribers.svg" alt="иконка человека" />
              Подписчики
              {' '}
              {currentAuthor?.counters?.subscribers !== 0 && (
                <SCCounter>{currentAuthor?.counters?.subscribers}</SCCounter>
              )}
            </NavItem>
          </NavMenu>
        </SCNavMenu>
      </SCAuthorCard>
    </SCAuthorCardWrapper>
  );
};

const SCNavMenu = styled.div`
  ${SCNav} {
    width: 286px;
  }
  
  ${lessThan('sm')} {
    ${SCNav} {
      width: 100%;
    }
  }
`;

const SCSubscriptionBtnWrap = styled.div`
  margin-bottom: 12px;
`;

const SCWrapper = styled.div`
  padding: 28px 16px 18px;
`;

const SCAuthorCard = styled.div`
  position: relative;
  width: 288px;
  flex-shrink: 0;
  ${PaperStyle};

  ${lessThan('sm')} {
    width: 100%;
  };
`;

export const SCAuthorCardWrapper = styled.div`
  display: flex;
  flex-direction: column;  
  position: relative;

  ${lessThan('md')} {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
  };

  ${lessThan('sm')} {
    width: 100%;
    padding: 16px 16px 0;
    margin-bottom: unset;
  };
`;
