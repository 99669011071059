import { createAsyncThunk } from '@reduxjs/toolkit';

import { Book } from '~/api/book/bookApiTypes';
import { operationApi } from '~/api/finance/operationApi';
import { GetOperationsProps, Operation } from '~/api/finance/operationApiTypes';
import {
  operationsTableSliceName,
  operationTableActions,
} from '~/atomic/page/finance/OperationsTable/operationsTable.slice';
import { paginationSelector } from '~/feature/pagination/pagination.selector';
import { paginationActions } from '~/feature/pagination/pagination.slice';
import { RootState } from '~/store';

const COUNT_OPERATIONS_PER_PAGE = 20;

export const getOperations = createAsyncThunk<
Operation[],
GetOperationsProps,
{ rejectValue: { error: string }, state: RootState }>(
  `${operationsTableSliceName}/getItems`,
  async (data, thunkAPI) => {
    const {
      isGetOperationsLoading,
      currentOperationAct,
    } = thunkAPI.getState().operationTable;
    const { page } = paginationSelector(thunkAPI.getState());
    if (!isGetOperationsLoading) {
      try {
        thunkAPI.dispatch(operationTableActions.setIsGetOperationsLoading(true));
        const result = await operationApi.getOperations({
          ...data,
          perPage: COUNT_OPERATIONS_PER_PAGE,
          page,
          operationAct: currentOperationAct,
        });

        if (result && 'data' in result) {
          thunkAPI.dispatch(operationTableActions.setOperations(result));
          thunkAPI.dispatch(paginationActions.setTotal({ total: result.total }));
          thunkAPI.dispatch(paginationActions.setPage({ page: result.currentPage }));
          thunkAPI.dispatch(
            paginationActions.setPageSize({ perPage: result.perPage }),
          );
          return result.data;
        }
      } catch (error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
        return thunkAPI.rejectWithValue({ error: error.message });
      } finally {
        thunkAPI.dispatch(operationTableActions.setIsGetOperationsLoading(false));
      }
    }
  },
);

export const getOperationBooks = createAsyncThunk<
Book[],
void,
{ rejectValue: { error: string }, state: RootState }>(
  `${operationsTableSliceName}/getItems`,
  async (_, thunkAPI) => {
    const { isGetOperationBooksLoading } = thunkAPI.getState().operationTable;
    if (!isGetOperationBooksLoading) {
      try {
        thunkAPI.dispatch(operationTableActions.setIsGetOperationBooksLoading(true));
        const result = await operationApi.getOperationBooks();

        if (result && 'data' in result) {
          thunkAPI.dispatch(operationTableActions.setOperationBooks(result.data));
          return result.data;
        }
      } catch (error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
        return thunkAPI.rejectWithValue({ error: error.message });
      } finally {
        thunkAPI.dispatch(operationTableActions.setIsGetOperationBooksLoading(false));
      }
    }
  },
);
