import { useRouter } from 'next/router';
import React, { useEffect } from 'react';

import { environments } from '~/lib/const';

export const SAVE_PREV_PAGE_URL_KEY = 'save_prev_page_url';

export const getPrevPageUrl = () => {
  if (environments.isClient) {
    return sessionStorage?.getItem(SAVE_PREV_PAGE_URL_KEY) ?? '';
  }
};

export const SavePrevPageUrl = () => {
  const router = useRouter();

  useEffect(() => {
    if (environments.isClient) {
      const saveUrl = () => {
        if (!window.location.pathname.startsWith('/reader')) {
          window.sessionStorage.setItem(SAVE_PREV_PAGE_URL_KEY, window.location.pathname);
        }
      };

      router.events.on('beforeHistoryChange', saveUrl);

      return () => {
        router.events.off('beforeHistoryChange', saveUrl);
      };
    }
  }, [environments.isClient]);

  return <></>;
};
