const trakingGenresForVk = {
  yumor: 'VK-RTRG-1499496-3PQq0',
  'eroticheskoe-fentezi': 'VK-RTRG-1499493-gCp3R',
  'lyubovnoe-fentezi': 'VK-RTRG-1499491-a944U',
  'eroticheskaya-fantastika': 'VK-RTRG-1499490-4iYuz',
  'temnoe-fentezi': 'VK-RTRG-1499486-1mcxy',
  'priklyuchencheskoe-fentezi': 'VK-RTRG-1499484-gPYFh',
  'boevoe-fentezi': 'VK-RTRG-1499482-4b9xY',
  'bytovoe-fentezi': 'VK-RTRG-1499481-8vhf8',
  stimpank: 'VK-RTRG-1499480-bXXzE',
  'nauchnaya-fantastika': 'VK-RTRG-1499477-dN59X',
  'molodezhnaya-proza': 'VK-RTRG-1499474-eXtYv',
  'boyar-anime': 'VK-RTRG-1499472-6cP4t',
  'korotkii-lyubovnyi-roman': 'VK-RTRG-1499471-fs9nU',
  'istoricheskii-lyubovnyi-roman': 'VK-RTRG-1499470-4S0aM',
  'ostrosyuzhetnyi-lyubovnyi-roman': 'VK-RTRG-1499468-fwTQe',
  'boevaya-fantastika': 'VK-RTRG-840901-3uEuA',
  postapokalipsis: 'VK-RTRG-840899-dBh6Q',
  uzhasy: 'VK-RTRG-840898-fg7ot',
  'lyubovnaya-fantastika': 'VK-RTRG-840897-1mLcw',
  'magicheskie-akademii': 'VK-RTRG-840896-hJ97S',
  popadantsy: 'VK-RTRG-840894-htRbr',
  'alternativnaya-istoriya': 'VK-RTRG-840891-9OmCR',
  litrpg: 'VK-RTRG-840889-9yIVj',
  'zhyostkaya-erotika': 'VK-RTRG-840887-bjebN',
  detektiv: 'VK-RTRG-840880-X5BJ',
  'romanticheskaya-erotika': 'VK-RTRG-840878-63DwI',
  'sovremennyi-lyubovnyi-roman': 'VK-RTRG-840876-gFdlD',
  fentezi: 'VK-RTRG-840875-hVyGk',
  fantastika: 'VK-RTRG-840874-2Ya5W',
};

export const checkGenreForTracking = (genre?: string) => {
  const pixelCode = trakingGenresForVk[genre ?? ''];

  if (pixelCode) {
    return pixelCode;
  }
};
