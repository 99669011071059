import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const partnerProgramSliceName = 'partnerProgram';
export const deletePartnerProgramName = 'isDeletePartner';

const partnerProgramSlice = createSlice({
  name: partnerProgramSliceName,
  initialState: { isUpdatePartnerLoading: false },
  reducers: {
    setIsUpdatePartnerLoading: (state, action: PayloadAction<boolean>) => {
      state.isUpdatePartnerLoading = action.payload;
    },
  },
});

export const {
  actions: partnerProgramActions,
  reducer: partnerProgramReducer,
} = partnerProgramSlice;
