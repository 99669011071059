export const usePostContentFotmatting = (content: string, maxLength) => {
  const contentWithoutTags = (content && typeof content === 'string' && ('replace' in Object.getPrototypeOf(content)))
    ? content
      ?.replace(
        // eslint-disable-next-line no-useless-escape
        /(style=([\w\W]+?)[\/]?")|(<p><\/p>)|(<img([\w\W]+?)[\/]?>)|(<br>)|(::before)|(::after)|(<p><br><\/p>)|(<\/?span[^>]*>)|(<\/?strong[^>]*>)/gm,
        '',
      )
    : content;

  const shortContent = contentWithoutTags.slice(0, maxLength);
  if (contentWithoutTags.length >= maxLength) {
    return shortContent.concat('...');
  }
  return shortContent;
};
