import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Cycle } from '~/api/book/cycleApiTypes';
import { BookListStatisticsPublic } from '~/api/book/statisticsApiTypes';

export const booksSliceName = 'books';

export const booksSlice = createSlice({
  name: booksSliceName,
  initialState: {
    isOpenAddCycleModal: false,
    isOpenAddBookModal: false,
    cycleIdForBookAdd: '',
    defaultCycle: {} as Partial<Cycle>,
    booksStatistics: [] as BookListStatisticsPublic[],
  },
  reducers: {
    setOpenCycleModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenAddCycleModal = action.payload;
    },
    setOpenBookModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenAddBookModal = action.payload;
    },
    setCycleIdForBookAdd: (state, action: PayloadAction<string>) => {
      state.cycleIdForBookAdd = action.payload;
    },
    setDefaultCycle: (state, action: PayloadAction<Cycle>) => {
      state.defaultCycle = action.payload;
    },
    setBooksStatistic: (state, action: PayloadAction<BookListStatisticsPublic[]>) => {
      state.booksStatistics = action.payload;
    },
  },
});

export const { actions: booksActions, reducer: booksReducer } = booksSlice;
