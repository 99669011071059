import { Book } from '~/api/book/bookApiTypes';
import {
  ChangeWidgetSettingsFields,
  GetPartnersBooksParams,
  WidgetSettings,
} from '~/api/finance/marketing/partnersBooksApiTypes';
import { handleRejectedRequest } from '~/api/provider/providerErrors';
import { Paginated, RejectedRequest } from '~/api/provider/providerTypes';

import { provider } from '../../provider/provider.config';

const namespace = Symbol('namespace');

export const partnersBooksApi = {
  [namespace]: '/v1/partnerProgram',

  async getPartnersBooks(data: GetPartnersBooksParams) {
    try {
      const response = await provider.get<
      Paginated<Book[]> | RejectedRequest<void>
      >(`${this[namespace]}/books`, {
        params: data,
        headers: {
          Authorization: data && data?.token
            ? `Bearer ${data?.token}`
            : provider.defaults.headers.common.Authorization,
        },
      });

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async getPartnersCPABooks(data: GetPartnersBooksParams) {
    try {
      const response = await provider.get<
      Paginated<Book[]> | RejectedRequest<void>
      >(`${this[namespace]}/books?onlyCPA=true`, {
        params: data,
        headers: {
          Authorization: data && data?.token
            ? `Bearer ${data?.token}`
            : provider.defaults.headers.common.Authorization,
        },
      });

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async getWidgetSettings() {
    try {
      const response = await provider.get<
      Paginated<WidgetSettings & { codePartner: string }> | RejectedRequest<void>
      >(`${this[namespace]}/widget/settings`);

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async changeWidgetSettings(data: ChangeWidgetSettingsFields) {
    try {
      const response = await provider.post<
      Paginated<void> | RejectedRequest<void>
      >(`${this[namespace]}/widget/settings`, data);

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

};
