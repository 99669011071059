import React from 'react';

import { SkeletonInput } from '~/atomic/atom/Skeleton';
import { authSelector } from '~/feature/authorization/auth.selector';
import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import { MyBooksFilter } from '~/feature/myBooks/MyBooksFilter/MyBooksFilter';
import { isLoggedInSelector } from '~/feature/user/isLoggedInSelector';
import { userSelector } from '~/feature/user/user.selector';
import { useAppSelector } from '~/store';

export const HeaderLinks = () => {
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const isMobile = useAppSelector(isMobileSizeSelector);

  const { isGetMeLoading } = useAppSelector(userSelector);

  const { loading: authLoading } = useAppSelector(authSelector);

  const isShowLoader = (isGetMeLoading || authLoading?.providerCallback) && !isMobile;

  if (!isLoggedIn) {
    return null;
  }

  return isShowLoader
    ? (
      <SkeletonInput
        active
        size="small"
        style={{
          width: '80px',
          marginLeft: '12px',
        }}
      />
    ) : (
      <MyBooksFilter />
    );
};
