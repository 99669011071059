import { WithToken } from '~/api/auth/authApiTypes';
import { BookIdType } from '~/api/book/bookApiTypes';
import {
  handleRejectedRequest,
  HttpNotFoundError,
  HttpProblemError,
} from '~/api/provider/providerErrors';
import { BookFactory } from '~/graphql/books/factory/book/BookFactory';
import { BookReactionRatingEnum, GraphqlBook } from '~/graphql/books/factory/book/BookFactoryTypes';
import { ChaptersFactory } from '~/graphql/books/factory/book/ChaptersFactory';
import { GraphqlChapters } from '~/graphql/books/factory/book/ChaptersFactoryTypes';
import { EChapterWithContentFactory } from '~/graphql/books/factory/book/EChapterWithContentFactory';
import { GetBookPageFactory } from '~/graphql/books/factory/book/GetBookPageFactory';
import { GetBookPageResult } from '~/graphql/books/factory/book/GetBookPageFactoryTypes';
import { CommentFactory } from '~/graphql/books/factory/comment/CommentFactory';

import {
  AddReactionDocument,
  AddReactionQuery,
  AddReactionQueryVariables,
  GetBookDocument,
  GetBookPageDocument,
  GetBookPageQuery,
  GetBookPageQueryVariables,
  GetBookQuery,
  GetBookQueryVariables,
  GetChaptersDocument,
  GetChaptersQuery,
  GetCommentsDocument,
  GetCommentsQuery,
  GetCommentsQueryVariables,
  GetEBookChapterDocument,
  GetEBookChapterQuery,
  GetEBookChapterQueryVariables,
  ReactionRatingEnum,
} from './GraphQLGeneratedCode';

export interface GetGraphqlBookParams extends WithToken {
  slug?: BookIdType;
  id?: BookIdType;
}

export interface GetGraphqlChaptersParams extends WithToken {
  slug?: BookIdType;
}

export interface AddGraphqlReactionParams extends WithToken {
  bookSlug: BookIdType;
  rating: BookReactionRatingEnum;
}

export interface GetBookCommentsParams extends WithToken {
  bookId: BookIdType;
  page?: number;
  perPage?: number;
  commentIdCursor?: BookIdType;
}

export enum ChapterContentTypeEnum {
  Html = 'html',
  Text = 'text',
}

export interface GetGraphqlBookPageParams extends WithToken {
  input: GetGraphqlBookParams;
  contentType?: ChapterContentTypeEnum;
}

export type GetEBookChapterParams = {
  chapterId: number;
};

export const booksGraphql = {
  async getBookBySlug(data: GetGraphqlBookParams): Promise<GraphqlBook> {
    const { booksGraphqlClient } = await import('~/feature/graphql/booksGraphqlClient');

    try {
      const response = await booksGraphqlClient(data.token).query<GetBookQuery>({
        query: GetBookDocument,
        variables: { input: { id: data.id, slug: data.slug } } as GetBookQueryVariables,
      });

      return BookFactory.create(response.data.getBook.book);
    } catch (error) {
      return handleRejectedRequest<void>(error);
    }
  },
  async getBookPage(
    {
      input, contentType = ChapterContentTypeEnum.Html, token,
    }: GetGraphqlBookPageParams,
  ): Promise<GetBookPageResult> {
    try {
      const { booksGraphqlClient } = await import('~/feature/graphql/booksGraphqlClient');

      const response = await booksGraphqlClient(token).query<GetBookPageQuery>({
        query: GetBookPageDocument,
        variables: {
          input: {
            slug: 'slug' in input ? input.slug : null,
            id: 'id' in input ? input.id : null,
          },
          contentType,
        } as GetBookPageQueryVariables,
      });

      if (response.data && response.data.getBookPage) {
        return GetBookPageFactory.create(response.data.getBookPage);
      }

      throw new Error(response.errors?.[0].message);
    } catch (error) {
      if (error.message === 'Книга не найдена' || error.message === 'Произведение не найдено') {
        return Promise.reject(
          new HttpNotFoundError(error.message),
        );
      }
      return handleRejectedRequest<void>(error);
    }
  },
  async getChapters(data: GetGraphqlChaptersParams): Promise<GraphqlChapters> {
    const { booksGraphqlClient } = await import('~/feature/graphql/booksGraphqlClient');

    try {
      const response = await booksGraphqlClient(data.token).query<GetChaptersQuery>({
        query: GetChaptersDocument,
        variables: { ...data },
      });

      return ChaptersFactory.create(response.data.getChapters);
    } catch (error) {
      return handleRejectedRequest<void>(error);
    }
  },
  async addReaction(data: AddGraphqlReactionParams) {
    const { booksGraphqlClient } = await import('~/feature/graphql/booksGraphqlClient');
    try {
      const response = await booksGraphqlClient(data.token).query<AddReactionQuery>({
        query: AddReactionDocument,
        variables: {
          input: {
            bookSlug: data.bookSlug,
            rating: data.rating as any as ReactionRatingEnum,
          },
        } as AddReactionQueryVariables,
      });

      if (response?.errors?.length) {
        throw new Error(response?.errors?.[0].message);
      }
    } catch (error) {
      return handleRejectedRequest<void>(error);
    }
  },
  async getBookComments(data: GetBookCommentsParams) {
    const { booksGraphqlClient } = await import('~/feature/graphql/booksGraphqlClient');
    try {
      const response = await booksGraphqlClient(data.token).query<GetCommentsQuery>({
        query: GetCommentsDocument,
        variables: {
          input: {
            bookId: data.bookId,
            page: data.page,
            perPage: data.perPage,
            commentIdCursor: data.commentIdCursor,
          },
        } as GetCommentsQueryVariables,
      });

      if (response?.errors?.length) {
        throw new Error(response?.errors?.[0].message);
      }

      return {
        comments: response.data.getBookComments.rootComments.map((comment) => CommentFactory.create(comment)),
        perPage: response.data.getBookComments.pager.perPage,
        page: response.data.getBookComments.pager.page,
        total: response.data.getBookComments.pager.total,
        totalNumberOfComments: response.data.getBookComments?.totalNumberOfComments,
      };
    } catch (error) {
      return handleRejectedRequest<void>(error);
    }
  },

  async getEbookChapter(data: GetEBookChapterParams) {
    const { booksGraphqlClient } = await import('~/feature/graphql/booksGraphqlClient');
    const response = await booksGraphqlClient().query<GetEBookChapterQuery, GetEBookChapterQueryVariables>({
      query: GetEBookChapterDocument,
      variables: { input: data },
    });

    if (response?.errors?.length) {
      throw new Error(response?.errors?.[0].message);
    }

    if (response?.data.getEbookChapter.__typename === 'GetEbookChapterPayload') {
      return EChapterWithContentFactory.create(response.data.getEbookChapter.chapter);
    }

    if (response?.data.getEbookChapter.__typename === 'ProblemType') {
      throw new HttpProblemError(response.data.getEbookChapter.message);
    }

    throw new Error('getEbookChapter data is not unhandled');
  },
};
