import React from 'react';
import styled from 'styled-components';

import { pagesConfigStore } from '~/atomic/atom/links/pagesConfigStore';
import { NavItem, SCNavMenuIcon } from '~/atomic/organism/NavMenu';

export const DefaultNavigationLinks = () => {
  return (
    <SCDefaultNavigationLinks>
      <SCList>
        <NavItem href="/profile" shallow>
          <SCNavMenuIcon src="/icon/profile.svg" />
          Профиль
        </NavItem>
        <NavItem href={pagesConfigStore.settings.url} shallow>
          <SCNavMenuIcon src="/icon/gear.svg" />
          Настройки
        </NavItem>
      </SCList>
    </SCDefaultNavigationLinks>
  );
};

const SCDefaultNavigationLinks = styled.li``;

const SCList = styled.ul`
  > * {
    border-bottom: 1px solid var(--bg-color);
    border-top: 1px solid var(--bg-color);
  }
`;
