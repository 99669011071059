import {
  AddCommentResult,
  Comment,
  CreateCommentParams,
  CreateComplaintParams,
  DeleteCommentParams,
  GetCommentsParams,
  GetCommentsResult,
  PublishCommentsParams,
  SetAwardParams,
  UpdateCommentParams,
} from '~/api/comment/commentApiTypes';
import { provider } from '~/api/provider/provider.config';
import { handleRejectedRequest } from '~/api/provider/providerErrors';
import { ApiResponse, RejectedRequest } from '~/api/provider/providerTypes';

const namespace = Symbol('namespace');

export const commentApi = {
  [namespace]: '/v1/comments',

  async createComment(data: CreateCommentParams) {
    try {
      const response = await provider.post<
      ApiResponse<AddCommentResult, RejectedRequest<CreateCommentParams>>
      >(
        `${this[namespace]}/comment`,
        data,
      );

      if ('data' in response.data) {
        return response.data;
      }

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getComments(data: GetCommentsParams) {
    try {
      const response = await provider.get<
      GetCommentsResult | RejectedRequest<void>
      >(
        `${this[namespace]}/${data.targetClass}/${data.targetId}`,
        { params: data },
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async updateComment(data: UpdateCommentParams) {
    try {
      const response = await provider.put<
      ApiResponse<Comment, RejectedRequest<Omit<UpdateCommentParams, 'id'>>>
      >(
        `${this[namespace]}/comment/${data.id}`,
        { content: data.content },
      );

      if ('data' in response.data) {
        return response.data;
      }

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async deleteComment(data: DeleteCommentParams) {
    try {
      const response = await provider.delete<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/comment/${data.id}`,
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async createComplaint(data: CreateComplaintParams) {
    try {
      const response = await provider.post<
      ApiResponse<void, RejectedRequest<Omit<CreateComplaintParams, 'id'>>>
      >(
        `${this[namespace]}/comment/${data.id}`,
        { cause: data.cause },
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async setAward(data: SetAwardParams) {
    try {
      const response = await provider.post<
      ApiResponse<void, RejectedRequest<void>>
      >(`${this[namespace]}/setAward`, data);

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async publishComments(data: PublishCommentsParams) {
    try {
      const response = await provider.post<
      ApiResponse<void, RejectedRequest<void>>
      >(`${this[namespace]}/book/${data.bookId}/preModeration`, data);

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
};
