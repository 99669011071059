import { createAsyncThunk } from '@reduxjs/toolkit';

import { paymentResultSelector } from '~/feature/book/paymentResult/paymentResult.selector';
import { GraphqlBook } from '~/graphql/books/factory/book/BookFactoryTypes';
import { RootState } from '~/store';

export const saveBuyBookInECommerce = createAsyncThunk<
void,
{ book: Partial<GraphqlBook> },
{ state: RootState }
>(
  'saveBuyBookInECommerce',
  ({ book }, thunkAPI) => {
    const { transactionResult } = paymentResultSelector(thunkAPI.getState());

    (window as any).dataLayerSite.push({
      ecommerce: {
        currencyCode: 'RUB',
        purchase: {
          actionField: {
            revenue: transactionResult?.incomeByPurchaseTotalGoal,
            id: 0,
          },
          products: [
            {
              id: book.slug,
              name: book.name,
              price: transactionResult?.price,
              brand: book.author?.name,
              category: book.genres?.[0].name,
              quantity: 1,
            },
          ],
        },
      },
    });
    if (book?.author?.yandexMetrika) {
      (window as any)?.dataLayer?.push({
        ecommerce: {
          currencyCode: 'RUB',
          purchase: {
            actionField: {
              revenue: transactionResult?.price ? transactionResult.price * 0.7 : 0,
              id: 0,
            },
            products: [
              {
                id: book.slug,
                name: book.name,
                price: transactionResult?.price,
                brand: book.author?.name,
                category: book.genres?.[0].name,
                quantity: 1,
              },
            ],
          },
        },
      });
    }
  },
);
