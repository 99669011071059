import { CoverImage } from '~/api/book/bookApiTypes';
import { ImagesFactory } from '~/graphql/books/factory/image/ImagesFactory';

export class CoverImagesFactory {
  static create(coverImages: Array<{ format: string; sizeType: string; url: string; }>): Array<CoverImage> {
    if (!coverImages && !coverImages?.length) return null;

    return ImagesFactory.create(coverImages);
  }
}
