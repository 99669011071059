import Router from 'next/router';
import { FC, useEffect } from 'react';

import { useAppDispatch } from '~/store';

export const TopProgressBar:FC = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const onStartEventHandler = async () => {
      const { load } = await import('~/feature/topProgressBar/topProgressBar.data');
      dispatch(load());
    };

    const onStopEventHandler = async () => {
      const { stop } = await import('~/feature/topProgressBar/topProgressBar.data');
      dispatch(stop());
    };

    Router.events.on('routeChangeStart', onStartEventHandler);
    Router.events.on('routeChangeComplete', onStopEventHandler);
    Router.events.on('routeChangeError', onStopEventHandler);

    return () => {
      Router.events.off('routeChangeStart', onStartEventHandler);
      Router.events.off('routeChangeComplete', onStopEventHandler);
      Router.events.off('routeChangeError', onStopEventHandler);
    };
  }, []);

  return null;
};
