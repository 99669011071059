import React, { FC } from 'react';
import styled from 'styled-components';

import { AvatarProps } from '~/atomic/atom/Avatar/Avatar';
import { generateDefaultAvatarColor } from '~/atomic/atom/Avatar/generateDefaultAvatarColor';
import { generateDefaultAvatarSymbol } from '~/atomic/atom/Avatar/generateDefaultAvatarSymbol';

interface DefaultAvatarProps extends Pick<AvatarProps, 'imageSize' | 'className' | 'userName'> {}

export const DefaultAvatar : FC<DefaultAvatarProps> = ({
  userName,
  imageSize,
  ...props
}) => {
  const generatedColor = generateDefaultAvatarColor(userName);
  const generatedSymbol = generateDefaultAvatarSymbol(userName);

  return (
    <SCDefaultAvatar
      data-color={generatedColor}
      {...props}
      style={{ ['--size' as any]: `${imageSize}px` }}
    >
      {generatedSymbol}
    </SCDefaultAvatar>
  );
};

const SCDefaultAvatar = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);
  margin: 0;
  padding: 0;
  border-radius: 50%;
  overflow: hidden;
  font-size: calc(var(--size) * .65);
  line-height: calc(var(--size) * .5);
  color: #282C2F;
  text-transform: uppercase;
  
  &[data-color="one"] {
    background: #FFD2D2;
    border: 1px solid var(--error-color);
  }
  
  &[data-color="two"] {
    background: var(--blue-color);
    border: 1px solid var(--primary-hover-color);
  }
  
  &[data-color="three"] {
    background: var(--green-color);
    border: 1px solid var(--green3-color);
  }
  
  &[data-color="four"] {
    background: var(--yellow-color);
    border: 1px solid #FBCA4B;
  }
  
  &[data-color="five"] {
    background: var(--gray3-color);
    border: 1px solid var(--gray-color);
  }
  
  &[data-color="six"] {
    background: #FED2FF;
    border: 1px solid #D22FED;
  }
  
  &[data-color="seven"] {
    background: #F9FFD2;
    border: 1px solid #E9AD11;
  }
`;
