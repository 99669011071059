import React from 'react';
import styled from 'styled-components';

import { AudioPlayerListIcon } from '~/atomic/atom/icon/audioPlayer/ListIcon';
import { AuthorLink, BookLink } from '~/atomic/atom/links/dynamicLinks';
import { Popover } from '~/atomic/atom/Popover';
import { TextBoldStyle, TextRegStyle } from '~/atomic/Typography';
import {
  audioPlayerActions,
  audioPlayerBookAuthorSelector,
  audioPlayerSelector,
} from '~/feature/audio/audioPlayer.slice';
import { AudioPlayerChapterList } from '~/feature/audio/audioPlayerChapterList/AudioPlayerChapterList';
import { isTabletSizeSelector } from '~/feature/media/windowSize.selector';
import { lessThan } from '~/lib/mediaQuery';
import { useAppDispatch, useAppSelector } from '~/store';

export const AudioPlayerChapterListPopover = () => {
  const dispatch = useAppDispatch();
  const isMobileSize = useAppSelector(isTabletSizeSelector);
  const { book } = useAppSelector(audioPlayerSelector);
  const bookAuthor = useAppSelector(audioPlayerBookAuthorSelector);

  const openModalHandler = () => {
    dispatch(audioPlayerActions.setIsOpenChapterListModal(true));
  };

  if (isMobileSize) {
    return (
      <SCAudioPlayerChapterListPopover>
        <SCAudioPlayerListIcon
          onClick={openModalHandler}
        />
      </SCAudioPlayerChapterListPopover>
    );
  }

  return (
    <SCAudioPlayerChapterListPopover>
      <Popover
        position="leftTop"
        header={(
          <SCAudioPlayerListIcon />
        )}
      >
        <SCHeader>
          <BookLink slug={book?.slug}>
            <SCName>{book?.name}</SCName>
          </BookLink>
          <SCCoAuthors>
            <AuthorLink username={bookAuthor?.username ?? ''} key={bookAuthor?.id ?? 0}>
              <SCCoAuthorsLink>
                {bookAuthor?.name ?? 'Имя автора'}
              </SCCoAuthorsLink>
            </AuthorLink>
          </SCCoAuthors>
        </SCHeader>
        <AudioPlayerChapterList />
      </Popover>
    </SCAudioPlayerChapterListPopover>
  );
};

const SCAudioPlayerListIcon = styled(AudioPlayerListIcon)`
  fill: var(--white-color);
  width: 22px;
  height: 19px;
  cursor: pointer;
  transition: fill 0.3s ease;

  &:hover {
    fill: var(--primary-hover-color);
  }
`;

const SCAudioPlayerChapterListPopover = styled.div`
  padding: 25px 40px 25px 0;
  display: flex;

  ${lessThan('sm')} {
    padding: 16px 0 12px 0;
  }
`;

const SCHeader = styled.div` && {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 10px;
    padding-left: 12px;
    margin-bottom: 0;
    border-bottom: 1px solid var(--border-color);
  }
`;

const SCCoAuthorsLink = styled.a`
  ${TextRegStyle};
  margin-right: 3px;
  color: var(--gray-color);

  &:not(:last-child) {
    &:after {
      content: ",";
    }
  }

  span {
    transition: color 0.3s ease;
    &:hover {
      color: inherit;
    }
  }
`;

const SCCoAuthors = styled.div`
  ${TextRegStyle};
`;

const SCName = styled.a`
  ${TextBoldStyle};
  color: var(--black-color);
  cursor: pointer;

  ${lessThan('xs')} {
    font-size: 15px;
    line-height: 16px;
  }
`;
