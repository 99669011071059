import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  Post, PostThemeEnum, PostThemeNamesEnum,
} from '~/api/blog/blogTypes';

export const getPostIdFromSlug = (postSlug: string) => {
  if (postSlug.match(/(\d)+$/)?.length) {
    return postSlug.match(/(\d)+$/)?.[0];
  }
};

export const getBlogThemeFromSlug = (themeSlug?: string) => {
  if (themeSlug) {
    const themeNameKey = Object.entries(PostThemeEnum).find(([_, value]) => value === themeSlug)[0];
    return PostThemeNamesEnum[themeNameKey];
  }
};

export const blogSliceName = 'blog';

export const DEFAULT_PAGE_SIZE_FOR_BLOGS = 20;

export const blogSlice = createSlice({
  name: blogSliceName,
  initialState: {
    post: {} as Post,
    mePosts: [] as Post[],
    posts: [] as Post[],
    isGetPostByIdLoading: false,
    isGetMePostsLoading: false,
    isGetPostsLoading: false,
    errorMessage: '',
  },
  reducers: {
    changePost: (state, action: PayloadAction<Post>) => {
      state.post = action.payload;
    },
    setIsGetPostByIdLoading: (state, action:PayloadAction<boolean>) => {
      state.isGetPostByIdLoading = action.payload;
    },
    setIsGetMePostsLoading: (state, action:PayloadAction<boolean>) => {
      state.isGetMePostsLoading = action.payload;
    },
    setIsGetPostsLoading: (state, action:PayloadAction<boolean>) => {
      state.isGetPostsLoading = action.payload;
    },
    changeMePosts: (state, action: PayloadAction<Post[]>) => {
      state.mePosts = action.payload;
    },
    changePosts: (state, action: PayloadAction<Post[]>) => {
      state.posts = action.payload;
    },
    changeErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
  },
});

export const { actions: blogActions, reducer: blogReducer } = blogSlice;
