import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const bookEditTabsSliceName = 'bookEditTabs';

const BookEditTabsSlice = createSlice({
  name: bookEditTabsSliceName,
  initialState: { tabActive: '1' },
  reducers: {
    changeActiveTab: (state, action: PayloadAction<string>) => {
      state.tabActive = action.payload;
    },
  },
});

export const {
  reducer: bookEditTabsReducer,
  actions: bookEditTabsActions,
} = BookEditTabsSlice;
