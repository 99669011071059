import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { DeviceEnum } from '~/api/finance/marketing/campaignsApiTypes';

const windowSizeSlice = createSlice({
  name: 'windowSize',
  initialState: {
    size: {
      width: 0,
      height: 0,
    },
    deviceFromServer: DeviceEnum.Desktop,
  },
  reducers: {
    changeSize: (state, action: PayloadAction<{ width: number, height: number }>) => {
      state.size = action.payload;
    },
    changeDeviceFromServer: (state, action: PayloadAction<DeviceEnum>) => {
      state.deviceFromServer = action.payload;
    },
  },
});

export const { actions: windowSizeActions, reducer: windowSizeReducer } = windowSizeSlice;
