import React, { FC } from 'react';
import styled from 'styled-components';

import { SearchIcon } from '~/atomic/atom/icon/SearchIcon';
import { headerActions } from '~/atomic/organism/Header/header.slice';
import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import { searchActions, SearchFieldPlaces } from '~/feature/search/search.slice';
import { environments } from '~/lib/const';
import { useAppDispatch, useAppSelector } from '~/store';

import { ym } from '../yandex/YandexMetrikaInit';

export const ShowSearchButton:FC<{ place: SearchFieldPlaces }> = ({ place }) => {
  const dispatch = useAppDispatch();
  const isMobileSize = useAppSelector(isMobileSizeSelector);

  const openSearchHandler = (e: React.MouseEvent<HTMLElement, MouseEvent>) => {
    if (isMobileSize) {
      dispatch(headerActions.setIsShowMobileSearchDraw(true));
    } else {
      if (environments.isProduction && place === SearchFieldPlaces.Header && ym) {
        ym('reachGoal', 'header:search');
      }
      dispatch(searchActions.showSearch({ place }));
      e.stopPropagation();
    }
  };

  return (
    <SCSearchIcon
      onClick={openSearchHandler}
    />
  );
};

export const SCSearchIcon = styled(SearchIcon)`
  font-size: 20px;
  transition: 0.3s;
  cursor: pointer;
  color: var(--black-color); 
  
  &:hover {
    color: var(--primary-hover-color);
  }
`;
