import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const descriptionInputsSliceName = 'descriptionInputs';

export const descriptionInputsSlice = createSlice({
  name: descriptionInputsSliceName,
  initialState: { isDirtyForm: false },
  reducers: {
    changeIsDirtyForm: (state, action: PayloadAction<boolean>) => {
      state.isDirtyForm = action.payload;
    },
  },
});

export const {
  actions: descriptionInputsActions,
  reducer: descriptionInputsReducer,
} = descriptionInputsSlice;
