import React from 'react';
import styled from 'styled-components';

import { audioPlayerSelector } from '~/feature/audio/audioPlayer.slice';
import { AudioPlayerChapterItem } from '~/feature/audio/audioPlayerChapterList/AudioPlayerChapterItem';
import { lessThan } from '~/lib/mediaQuery';
import { useAppSelector } from '~/store';

export const AudioPlayerChapterList = () => {
  const { chapters } = useAppSelector(audioPlayerSelector);

  return (
    <SCChapterList>
      {chapters
        .map((chapter) => (
          <AudioPlayerChapterItem
            chapter={chapter}
            key={chapter?.id}
          />
        ))}
    </SCChapterList>
  );
};

const SCChapterList = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 250px;
  overflow-y: auto;
  overflow-x: hidden;
  
  > *:not(:last-child) {
    border-bottom: 1px solid var(--border-color);
  }
  
  ${lessThan('sm')} {
    max-height: 350px;
  }
`;
