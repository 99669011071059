import { createSelector } from '@reduxjs/toolkit';

import { ChapterStatus } from '~/api/book/chapterApiTypes';
import { RootState } from '~/store';

export const chapterSelector = (state: RootState) => state.chapter;

export const ebookChaptersSelector = createSelector(
  chapterSelector,
  ({ graphqlChapters }) => graphqlChapters?.ebookChapters ?? [],
);

export const audioBookChaptersSelector = createSelector(
  chapterSelector,
  ({ graphqlChapters }) => graphqlChapters?.audioChapters ?? [],
);

export const isHavePublishChapterSelector = createSelector(
  chapterSelector,
  ({ bookChapters }) => bookChapters.some((chapter) => chapter.status === ChapterStatus.Published),
);

export const isHaveSomeNoPublishChapterSelector = createSelector(
  chapterSelector,
  ({ bookChapters }) => (bookChapters.length
    ? bookChapters.some((chapter) => chapter.status !== ChapterStatus.Published)
    : false),
);

export const isHaveBuyPublishChaptersSelector = createSelector(
  ebookChaptersSelector,
  (chapters) => {
    const paidChapters = chapters.filter((chapter) => !chapter.available);

    return paidChapters.length > 0;
  },
);

export const isHavePaidPublishAudioChaptersSelector = createSelector(
  audioBookChaptersSelector,
  (chapters) => {
    const paidChapters = chapters.filter((chapter) => (chapter?.audioUrl ? !chapter?.audioUrl : false));

    return paidChapters.length > 0;
  },
);

export const isHaveAvailablePublishAudioChaptersSelector = createSelector(
  audioBookChaptersSelector,
  (chapters) => {
    const availableChapters = chapters.filter((chapter) => chapter?.audioUrl);

    return availableChapters.length > 0;
  },
);

export const sortedChaptersSelector = createSelector(
  chapterSelector,
  ({ bookChapters }) => {
    if (bookChapters.length) {
      return [...bookChapters].sort((prevChapter, nextChapter) => {
        return prevChapter.number - nextChapter.number;
      });
    }

    return [];
  },
);

export const isCurrentEditChapterFreeSelector = createSelector(
  chapterSelector,
  ({ currentEditChapter }) => currentEditChapter?.free,
);

export const currentEditChapterContentSelector = createSelector(
  chapterSelector,
  ({ currentEditChapter }) => currentEditChapter?.content,
);

export const bookChaptersSelector = createSelector(
  chapterSelector,
  ({ bookChapters }) => bookChapters,
);

export const lastPublishChapterSelector = createSelector(
  ebookChaptersSelector,
  (chapters) => {
    const {
      length,
      [length - 1]: last,
    } = chapters ?? [];
    return last;
  },
);

export const isChapterFirstFourChaptersSelector = (chapterId: string) => createSelector(
  ebookChaptersSelector,
  (chapters) => {
    if (chapters.length === 0) return true;
    const firstFourChapterIds = chapters.slice(0, 4)
      .map((chapter) => chapter.chapterId);

    return firstFourChapterIds.includes(Number(chapterId));
  },
);

export const lastPublishAvailableChapterSelector = createSelector(
  ebookChaptersSelector,
  (chapters) => {
    const availableChapters = chapters?.filter((chapter) => chapter.available) ?? [];
    const {
      length,
      [length - 1]: last,
    } = availableChapters;
    return last;
  },
);

export const firstPublishChapterSelector = createSelector(
  ebookChaptersSelector,
  (chapters) => chapters?.[0],
);

export const secondPublishChapterSelector = createSelector(
  ebookChaptersSelector,
  (chapters) => chapters?.[1],
);

export const firstChapterSelector = createSelector(
  chapterSelector,
  ({ bookChapters }) => bookChapters?.[0],
);

export const isFirstPublishChapterSelector = (chapterId: string) => createSelector(
  ebookChaptersSelector,
  (chapters) => String(chapterId) === String(chapters?.[0]?.chapterId),
);

export const isFirstChapterSelector = (chapterId: string) => createSelector(
  firstChapterSelector,
  (firstChapterSelector) => String(chapterId) === String(firstChapterSelector?.id),
);

export const isLastAvailableChapterSelector = (chapterId: string) => createSelector(
  lastPublishAvailableChapterSelector,
  (lastAvailableChapter) => String(chapterId) === String(lastAvailableChapter?.chapterId),
);
