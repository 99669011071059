import { ChapterStatus } from '~/api/book/chapterApiTypes';
import { GraphqlChapters } from '~/graphql/books/factory/book/ChaptersFactoryTypes';
import { EBookChapterFactory } from '~/graphql/books/factory/book/EBookChapterFactory';
import { GetChaptersQuery } from '~/graphql/books/GraphQLGeneratedCode';

export class ChaptersFactory {
  static create(chapters: GetChaptersQuery['getChapters']): GraphqlChapters {
    return {
      ebookChapters: chapters.ebookChapters.map((chapter) => EBookChapterFactory.create(chapter)),
      audioChapters: chapters.audioChapters.map((chapter) => ({
        available: chapter.available,
        chapterId: chapter.chapterId,
        createdAt: chapter.createdAt,
        associatedTextChapterId: chapter.associatedTextChapterId,
        name: chapter.name,
        audioUrl: chapter.audioUrl,
        free: chapter.free,
        duration: chapter.duration,
        status: String(chapter.status) as ChapterStatus,
        firstPublishedAt: chapter.firstPublishedAt,
        position: chapter.position,
        updatedAt: chapter.updatedAt,
        voicingStatus: chapter.voicingStatus,
      })),
    };
  }
}
