import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { BookStatisticsPublic } from '~/api/book/statisticsApiTypes';

export const bookPublicStatisticSliceName = 'bookPublicStatistic';

const initState = {
  readCompleted: 0,
  readLater: 0,
  readNow: 0,
  views: 0,
  likes: 0,
  dislikes: 0,
  rewards: 0,
} as BookStatisticsPublic;

const bookPublicStatisticsSlice = createSlice({
  name: bookPublicStatisticSliceName,
  initialState: initState,
  reducers: {
    changeStatistics: (state, action: PayloadAction<BookStatisticsPublic>) => {
      return action.payload;
    },
  },
});

export const {
  actions: bookPublicStatisticActions,
  reducer: bookPublicStatisticsReducer,
} = bookPublicStatisticsSlice;
