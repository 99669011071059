import { LinkProps } from 'next/link';
import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { ArrowIcon } from '~/atomic/atom/icon/ArrowIcon';
import { ActiveLink } from '~/atomic/molecula/ActiveLink';
import { SCNotificationsCount } from '~/atomic/organism/Header/styled';
import { WithChildren } from '~/lib/WithChildren';

interface NavItemProps extends LinkProps, WithChildren {
  disabled?: boolean;
  arrowProps?: HTMLAttributes<HTMLElement>;
}

export const NavItem = ({
  children,
  disabled = false,
  arrowProps,
  ...props
}: NavItemProps & HTMLAttributes<HTMLAnchorElement>) => {
  return (
    <SCNavItem data-is-disabled={String(disabled)}>
      <ActiveLink {...props}>
        <SCNavLink>
          { children }
          <SCArrowIcon {...arrowProps} />
        </SCNavLink>
      </ActiveLink>
    </SCNavItem>
  );
};

interface NavMenuProps extends HTMLAttributes<HTMLUListElement> {
  isMobile?: boolean;
}

export const NavMenu:FC<NavMenuProps> = ({
  children, isMobile, ...props
}) => (
  <SCNav data-is-mobile={String(isMobile)}>
    <SCNavList {...props}>
      { children }
    </SCNavList>
  </SCNav>
);

export const SCNavLink = styled.a`
  display: flex;
  align-items: center;
  color: var(--black-color);
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  padding: 15px 16px;
  position: relative;

  &[data-is-active="true"] {
    color: var(--primary-hover-color);
  }
  
  &:hover {
    color: var(--primary-hover-color);
  }
`;

const SCNavList = styled.ul`
  > * {
    border-bottom: 1px solid var(--bg-color);
    border-top: 1px solid var(--bg-color);
  }
`;

export const SCNavItem = styled.li`
  &[data-is-disabled="true"] {
    a {
      color: var(--gray-color);
    }
    pointer-events: none;
  }

  ${SCNotificationsCount} {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(25px, -22px);
    border: 4px solid var(--bg-color);
    scale: 0.85;
  }
`;

export const SCNav = styled.nav`
  width: 282px;
  flex-shrink: 0;
  background: #ffffff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;

  &[data-is-mobile="true"] {
    width: 100%;
    border-radius: 0;
    box-shadow: none;

    ${SCNavItem} {
      &:last-child {
        border-radius: 0 0 4px 4px;
      }
    }
  }
`;

export const SCNavMenuIcon = styled.img`
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 20px;
`;

const SCArrowIcon = styled(ArrowIcon)`
  font-size: 12px;
  position: absolute;
  right: 17px;
  top: 50%;
  transform: translateY(-50%);
`;
