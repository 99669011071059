import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PaymentRequest } from '~/api/finance/financeApiTypes';
import { Paginated } from '~/api/provider/providerTypes';

export const paymentRequestsTableSliceName = 'paymentRequestsTable';

export const paymentRequestsTableSlice = createSlice({
  name: paymentRequestsTableSliceName,
  initialState: {
    paymentRequests: [] as PaymentRequest[],
    isGetPaymentRequestsLoading: false,
    total: 0,
    currentPage: 1,
    perPage: 20,
  },
  reducers: {
    setCurrentStage: (state, action: PayloadAction<number>) => {
      state.currentPage = action.payload;
    },
    changePaymentRequests: (state, action: PayloadAction<Paginated<PaymentRequest[]>>) => {
      state.paymentRequests = action.payload.data;
      state.total = action.payload.total;
      state.currentPage = action.payload.currentPage;
      state.perPage = action.payload.perPage;
    },
    setIsGetPaymentRequestsLoading: (state, action: PayloadAction<boolean>) => {
      state.isGetPaymentRequestsLoading = action.payload;
    },
  },
});

export const {
  actions: paymentRequestsTableActions,
  reducer: paymentRequestsTableReducer,
} = paymentRequestsTableSlice;
