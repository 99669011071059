import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AdAccount } from '~/api/finance/marketing/adAccountApiTypes';

export const adAccountSliceName = 'adAccount';

export const adAccountSlice = createSlice({
  name: adAccountSliceName,
  initialState: {
    adAccountInfo: {} as AdAccount,
    isLoadAdAccountInfo: false,
    isOpenAdAccountReplenishmentModal: false,
  },
  reducers: {
    changeAdAccountInfo: (state, action: PayloadAction<AdAccount>) => {
      state.adAccountInfo = action.payload;
    },
    setIsLoadAdAccountInfo: (state, action: PayloadAction<boolean>) => {
      state.isLoadAdAccountInfo = action.payload;
    },
    setIsOpenAdAccountReplenishmentModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenAdAccountReplenishmentModal = action.payload;
    },
  },
});

export const {
  actions: adAccountActions,
  reducer: adAccountReducer,
} = adAccountSlice;
