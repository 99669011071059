import React, { FC, useState } from 'react';
import styled from 'styled-components';

import { UserRole } from '~/api/account/accountApiTypes';
import { MiniatureSizesEnum } from '~/api/book/bookApiTypes';
import { CommentStatusEnum } from '~/api/comment/commentApiTypes';
import { Avatar } from '~/atomic/atom/Avatar/Avatar';
import { Comment } from '~/atomic/atom/Comment';
import { TextArea } from '~/atomic/atom/Input';
import { AuthorLink, UserLink } from '~/atomic/atom/links/dynamicLinks';
import { Loader } from '~/atomic/atom/Loader';
import { TextRegStyle, TextSmallStyle } from '~/atomic/Typography';
import { openModal } from '~/feature/authorization/authorizationModal.slice';
import {
  addComment, deleteComment, getComments,
} from '~/feature/comments/comment.data';
import { commentsSelector } from '~/feature/comments/comment.selector';
import { isCommentsLoading } from '~/feature/comments/comment.slice';
import { ReviewTag } from '~/feature/myBooks/Review/ReviewTag';
import { isLoggedInSelector } from '~/feature/user/isLoggedInSelector';
import { userSelector } from '~/feature/user/user.selector';
import { Linkify } from '~/feature/utils/Linkify';
import { DateFormatComponent } from '~/lib/dateFormatText';
import { useAppDispatch, useAppSelector } from '~/store';

interface CommentsListProps {
  targetClass: 'book' | 'post';
  targetId: string | number;
  targetAuthorId: string;
}

export const CommentsList: FC<CommentsListProps> = ({
  targetAuthorId, targetClass, targetId,
}) => {
  const dispatch = useAppDispatch();
  const { user } = useAppSelector(userSelector);
  const { comments, loading } = useAppSelector(commentsSelector);
  const [commentParentId, setCommentParentId] = useState(null);
  const [repliedCommentId, setRepliedCommentId] = useState(null);
  const [replayText, setReplayText] = useState('');
  const repliedComment = comments.find((comment) => comment.id === repliedCommentId);
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const isReplayDisabled = replayText === `${repliedComment?.user.name}, `;

  const loadComments = () => {
    if (targetClass && targetId) {
      return dispatch(getComments({ targetClass, targetId }));
    }
  };

  const sendReplayCommentHandler = async () => {
    if (!isReplayDisabled) {
      await dispatch(addComment({
        content: replayText,
        targetClass,
        targetId,
        parentId: commentParentId,
        replyToComment: repliedCommentId,
      }));
      setRepliedCommentId(null);
      setCommentParentId(null);
      setReplayText('');
      loadComments();
    }
  };

  const ctrlEnterHandler = (e) => {
    if (e.keyCode === 13 && e.ctrlKey) {
      sendReplayCommentHandler();
    }
  };

  const handleCommentTextChange = async (e) => {
    if (e.target.value && e.target.value.length > 3999) {
      const { message } = await import('~/atomic/atom/message');
      message.warning('Длина комментария не может быть больше 4000 символов.');
    } else {
      setReplayText(e.target.value);
    }
  };

  const deleteCommentHandler = async (id: string | number) => {
    await dispatch(deleteComment({ id }));
  };

  const formattedComments = comments
    .map((comment) => {
      const Link = comment.user?.role === UserRole.author ? AuthorLink : UserLink;

      const isPreModeration = comment.status === CommentStatusEnum.PreModeration;

      const isCanDeleteComment = Number(targetAuthorId) === Number(user?.id)
      || comment?.user?.id === user?.id;

      return {
        id: comment.id,
        parentId: comment.parentId,
        childrenIds: comment.childrenIds.split(',').filter((id) => !!id).map(Number),
        createdAt: comment.createdAt,
        updatedAt: comment.updatedAt,
        user: comment.user,
        isPreModeration,
        actions: [
          !isPreModeration && (
            <SCCommentAction onClick={() => {
              setCommentParentId(comment?.parentId ?? comment.id);
              setRepliedCommentId(comment.id);
              setReplayText(`${comment.user.name}, `);
            }}
            >
              Ответить
            </SCCommentAction>
          ),
          isCanDeleteComment
          && (
            <SCDeleteComment
              onClick={async () => {
                await deleteCommentHandler(comment.id);
              }}
            >
              Удалить
            </SCDeleteComment>
          ),
        ],
        author: (
          <SCAuthorWrapper
            data-is-pre-moderation={isPreModeration}
            id={comment?.isReview ? 'user-review' : null}
          >
            <SCAuthor
              itemScope
              itemType="https://schema.org/Person"
              itemProp="author"
            >
              <Link username={comment.user.username}>
                <a>
                  <Avatar
                    imageSize={24}
                    avatarSize={MiniatureSizesEnum.M}
                    avatarImages={comment?.user?.avatarImages}
                    userName={comment?.user?.name}
                    alt="Аватарка пользователя"
                  />
                </a>
              </Link>
              <Link username={comment.user.username}>
                <SCAuthorName
                  itemProp="name"
                >
                  { comment?.user?.name ?? 'Имя пользователя'}
                  {String(comment.user.id) === String(targetAuthorId) && (
                    <SCAuthorBadger>Автор</SCAuthorBadger>
                  )}
                </SCAuthorName>
              </Link>
            </SCAuthor>
            {comment?.isReview ? <ReviewTag /> : null}
            <SCInfo>
              {isPreModeration && (
                <SCNotPublished>
                  Неопубликован
                </SCNotPublished>
              )}
              <SCTime
                itemProp="dateCreated"
              >
                <DateFormatComponent date={comment?.updatedAt ?? Date.now()} />
              </SCTime>
            </SCInfo>
          </SCAuthorWrapper>
        ),
        content: (
          <Linkify>
            <SCText
              itemProp="text"
              data-is-pre-moderation={isPreModeration}
            >
              {comment.content}
              {!!comment.reward && (
                <SCRewardTag
                  itemProp="award"
                >
                  <SCRubleIcon src="/icon/ruble.svg" alt="иконка рубля" />
                  награда автору
                </SCRewardTag>
              )}
            </SCText>
          </Linkify>
        ),
      };
    });

  const mainFormattedComments = formattedComments.filter((comment) => !comment?.parentId);

  const getChildFormattedComments = (parentComment) => formattedComments.filter(
    (childrenComment) => {
      const isHaveInChildren = parentComment.childrenIds?.includes(Number(childrenComment.id));
      const isHaveInChildrenOfChildren = parentComment.childrenIds?.includes(
        Number(childrenComment.id),
      ) || parentComment.childrenIds?.includes(Number(childrenComment.parentId));

      return isHaveInChildren || isHaveInChildrenOfChildren;
    },
  );

  const textAreaClickHandler = () => {
    if (!isLoggedIn) {
      dispatch(openModal('login'));
    }
  };

  return (
    <div>
      {mainFormattedComments.map((comment) => {
        return (
          <SCComment
            key={`comment-${comment.id}`}
            actions={comment.actions}
            author={comment.author}
            data-is-pre-moderation={comment.isPreModeration}
            content={comment.content}
          >
            <div
              itemScope
              itemType="https://schema.org/Comment"
              itemProp="comment"
            >
              {getChildFormattedComments(comment).sort((a, b) => (
                Number(new Date(a.updatedAt)) - Number(new Date(b.updatedAt))
              )).map((filteredChildren) => (
                <SCComment
                  key={`comment-${filteredChildren.id}`}
                  actions={filteredChildren.actions}
                  author={filteredChildren.author}
                  content={filteredChildren.content}
                />
              ))}
              {((repliedCommentId === comment.id)
              || (comment.childrenIds.includes(repliedCommentId))) && (
                <SCTextAreaWrapper>
                  <SCAvatarWrapper
                    data-disabled={String(!user?.id)}
                  >
                    <SCAvatar
                      imageSize={24}
                      avatarSize={MiniatureSizesEnum.M}
                      avatarImages={comment?.user?.avatarImages}
                      userName={comment?.user?.name}
                      alt="Аватарка пользователя"
                    />
                  </SCAvatarWrapper>
                  <SCTextArea
                    autoFocus
                    onFocus={(event) => {
                      event.persist();
                      // set cursor to end
                      event.target.setSelectionRange(
                        event.target.value.length,
                        event.target.value.length,
                      );
                    }}
                    data-is-disabled={!user?.id}
                    onKeyDown={ctrlEnterHandler}
                    placeholder="Написать комментарий"
                    autoSize={{ minRows: 1 }}
                    value={replayText}
                    onClick={textAreaClickHandler}
                    onChange={handleCommentTextChange}
                  />
                  <SCActionsWrapper>
                    {isCommentsLoading('addReplay', loading)
                      ? <Loader size={30} />
                      : (
                        <SCSendButton
                          disabled={isReplayDisabled || !user?.id}
                          onClick={sendReplayCommentHandler}
                        />
                      )}
                  </SCActionsWrapper>
                </SCTextAreaWrapper>
              )}
            </div>
          </SCComment>
        );
      })}
    </div>
  );
};

const SCComment = styled(Comment)`
  .ant-comment-nested {
    margin-left: 30px;
  }

  &[data-is-pre-moderation="true"] {
    .ant-comment-content { 
      ul > li:nth-child(2) {
        display: block;
      }
    }
  }

  .ant-comment-inner {
    padding: unset;
    margin-bottom: 16px;
  }

  .ant-comment-content-author-name {
    width: 100%;
  }

  .ant-comment-actions {
    display: flex;
    align-items: center;
    margin-top: 5px;
  }

  .ant-comment-content { 
  
    ul > li:nth-child(2) {
      display: none;
      line-height: 19px;

    }
    
    &:hover {
      ul > li:nth-child(2) {
      display: inline-block;
      }
    }
  }

  .ant-comment-content-author {
    justify-content: space-between;
  }
`;

const SCTextAreaWrapper = styled.div` 
  position: relative;
  border: 1px solid var(--border-color);
  border-radius: 2px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const SCSendButton = styled.button`
  border: unset;
  mask-image: url('/icon/send.svg');
  mask-position: center;
  mask-repeat: no-repeat;
  mask-size: contain;
  background-color: var(--primary-hover-color);
  cursor: pointer;
  width: 20px;
  height: 20px;
  opacity: 0.6;
  
  &:disabled {
    background-color: var(--gray-color);
    user-select: none;
    pointer-events: none;
  }
`;
const SCAvatarWrapper = styled.div`
  position: absolute;
  min-height: 44px;
  width: 48px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 2;
  
  &[data-disabled="true"] {
     background-color: #f5f5f5;
  }
`;

const SCActionsWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  position: absolute;
  z-index: 2;
  min-width: 20px;
  min-height: 20px;
  right: 12px;
  bottom: 12px;
`;

const SCTextArea = styled(TextArea)`
  border: none;
  padding-left: 50px;
  padding-right: 70px;
  z-index: 1;
  resize: none;
  
  &.ant-input {
    min-height: 44px !important; 
    font-family: var(--second-font);
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    padding-top: 11px;
  }

  &[data-is-disabled="true"]{
    background-color: #f5f5f5;
    cursor: not-allowed;

    &:focus, &:active {
      border: none;
      box-shadow: none;
      outline: none;
      caret-color: transparent;
    }
  }
`;

const SCAvatar = styled(Avatar)`
  margin-top: 10px;
`;

const SCAuthorBadger = styled.span`
  background: transparent;
  
  width: 42px;
  height: 16px;
  border: 1px solid rgba(141, 147, 162, 0.25);
  border-radius: 14px;
  
  font-family: var(--second-font);
  font-style: normal;
  font-weight: 600;
  font-size: 9px;
  line-height: 16px;
  text-align: center;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: var(--gray-color);
  
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 9px;
`;

const SCCommentAction = styled.span` &&& {
    ${TextSmallStyle};
    color: var(--primary-color);
    display: flex;
    align-items: center;

    &[data-gray="true"] {
      color: var(--gray-color);
    }
  }
`;

const SCTime = styled.span`
  font-family: var(--second-font) !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 13px !important;
  line-height: 20px !important;
  `;

const SCInfo = styled.div`
  margin-left: auto !important;
  display: flex;
  padding-left: 3px;

  > *:not(:last-child) {
    margin-right: 14px;
  }
`;

const SCAuthorName = styled.a`
  ${TextRegStyle};
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-left: 8px;
  color: var(--black-color);
  
  &:hover {
    color: var(--primary-hover-color);
  } 
`;

const SCAuthorWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;

  &[data-is-pre-moderation="true"] {
    ${SCAuthorName} {
      color: var(--gray-color)''
    }
  }
`;

const SCText = styled.span`
  ${TextRegStyle};
  color: var(--black-color);
  display: block;
  background: var(--bg-color);
  border-radius: 4px;
  padding: 9px 19px 9px 12px;
  white-space: pre-line;

  &[data-is-pre-moderation="true"] {
    color: var(--gray-color);
  }
`;

const SCRewardTag = styled.div`
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  color: var(--black-color);
  font-family: var(--second-font);
  font-weight: bold;
  font-size: 10px;
  line-height: 11px;
  padding: 0 10px;
  height: 19px;
  letter-spacing: 0.05em;
  background-position: 10px center;
  background-repeat: no-repeat;
  text-transform: uppercase;
  background-color: var(--yellow-color);
  width: max-content;
  margin-top: 10px;
  border: 1px solid #FBCA4B;
`;

const SCRubleIcon = styled.img`
  width: 10px;
  height: 11px;
  margin-right: 6px;
`;

const SCAuthor = styled.div`
  display: flex;
  align-items: center;
  margin-right: 8px;
`;

const SCNotPublished = styled.span`
  ${TextSmallStyle};
  display: flex;
  color: var(--error-color);
`;

const SCDeleteComment = styled.span` && {
    ${TextSmallStyle};
    color: var(--error-color);

    &:hover {
      color: var(--error-color);
    }

    &[data-is-pre-moderation="true"] {
      display: none;
      
      &:hover {
        display: none;
      }
    }
  }
`;
