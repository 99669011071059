import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Button } from '~/atomic/atom/Button';
import { Drawer } from '~/atomic/atom/Drawer';
import { BullhornIcon } from '~/atomic/atom/icon/BullhornIcon';
import { PenIcon } from '~/atomic/atom/icon/PenIcon';
import { breakepoints } from '~/atomic/breakepoints';
import { headerSelector } from '~/atomic/organism/Header/header.selector';
import {
  ActiveLibraryEnum,
  headerActions,
} from '~/atomic/organism/Header/header.slice';
import { HeaderLogo } from '~/atomic/organism/Header/HeaderLogo';
import { HeaderMobNavGenreLibrary } from '~/atomic/organism/Header/HeaderMobNav/HeaderMobNavGenreLibrary';
import { HeaderMobNavTagLibrary } from '~/atomic/organism/Header/HeaderMobNav/HeaderMobNavTagLibrary';
import { NavItem } from '~/atomic/organism/NavMenu';
import { isLoggedInSelector } from '~/feature/user/isLoggedInSelector';
import { useAppDispatch, useAppSelector } from '~/store';

export const HeaderMobNav = () => {
  const dispatch = useAppDispatch();
  const { isHamburgerActive, activeLibrary } = useAppSelector(headerSelector);
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const [isOpened, setIsOpened] = useState(false);

  const handleHeaderExitClick = () => {
    dispatch(headerActions.setIsHamburgerActive(false));
  };

  const handleClose = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    handleHeaderExitClick();
  };

  const changeActiveLibrary = (newActiveLibrary: ActiveLibraryEnum) => {
    dispatch(headerActions.changeActiveLibrary(newActiveLibrary));
  };

  let touchendX;
  let touchstartX;

  let touchendY;
  let touchstartY;

  const handleGesture = () => {
    const betweenX = Math.abs(touchstartX - touchendX);
    const betweenY = Math.abs(touchstartY - touchendY);

    if ((touchendX < touchstartX) && (betweenX > betweenY)) {
      dispatch(headerActions.setIsHamburgerActive(false));
    }
  };

  const onTouchStartHandler = (event) => {
    touchstartX = event.changedTouches[0].screenX;
    touchstartY = event.changedTouches[0].screenY;
  };

  const onTouchEndHandler = (event) => {
    touchendX = event.changedTouches[0].screenX;
    touchendY = event.changedTouches[0].screenY;
    handleGesture();
  };

  useEffect(() => {
    if (isHamburgerActive) {
      setIsOpened(true);
    }
  }, [isHamburgerActive]);

  if (!isHamburgerActive && !isOpened) {
    return null;
  }

  return (
    <SCDrawer
      placement="left"
      closable={false}
      onClose={handleClose}
      open={isHamburgerActive}
      width={290}
      destroyOnClose
      style={{ position: 'fixed', top: '56px' }}
    >
      <SCWrapper
        data-is-logged={isLoggedIn}
        onTouchStart={onTouchStartHandler}
        onTouchEnd={onTouchEndHandler}
      >
        {isLoggedIn ? (
          <SCHeaderLogoWrapper
            onClick={handleHeaderExitClick}
          >
            <HeaderLogo />
          </SCHeaderLogoWrapper>
        ) : null}
        <LibraryButtons>
          <SCButton
            size="large"
            type="gray"
            onClick={() => {
              changeActiveLibrary(ActiveLibraryEnum.Genre);
            }}
            data-is-active={activeLibrary === ActiveLibraryEnum.Genre}
          >
            Жанры
          </SCButton>
          <SCButton
            size="large"
            type="gray"
            onClick={() => {
              changeActiveLibrary(ActiveLibraryEnum.Tag);
            }}
            data-is-active={activeLibrary === ActiveLibraryEnum.Tag}
          >
            Темы
          </SCButton>
        </LibraryButtons>
        <SCHeaderMobNavLibrary>
          {activeLibrary === ActiveLibraryEnum.Genre
            ? <HeaderMobNavGenreLibrary />
            : <HeaderMobNavTagLibrary /> }
        </SCHeaderMobNavLibrary>
        <SCList>
          <NavItem href="/blogs" shallow>
            <SCBullhornIcon />
            Блоги авторов
          </NavItem>
          <NavItem href="/contests" shallow>
            <SCPenIcon />
            Конкурсы
          </NavItem>
        </SCList>
      </SCWrapper>
    </SCDrawer>
  );
};

const SCHeaderMobNavLibrary = styled.div`
  background-color: var(--white-color);
  max-height: calc(100% - 198px);
  overflow: auto;
  
  > * {
    border-bottom: 1px solid var(--bg-color);
  }

  > *:first-child {
    border-top: 1px solid var(--bg-color);
  }
`;

const SCDrawer = styled(Drawer)`
  height: calc(100% - 56px);

  @media only screen and (min-width: ${breakepoints['screen-md-down']}) {
    visibility: hidden;
    display: none;
  }

  .ant-drawer-content-wrapper {
    box-shadow: none !important;
  }
  
  .ant-drawer-body {
    background-color: var(--bg-color);
    padding: 0;
  }
`;

const SCWrapper = styled.div`
  height: 100%;
  
  > *:not(:last-child) {
    margin-bottom: 20px;
  }

  &[data-is-logged="false"] {
    padding-top: 26px;
    
    ${SCHeaderMobNavLibrary} {
      max-height: calc(100% - 146px);
    }
  }
`;

const SCList = styled.ul`
  width: 100%;
  background-color: var(--bg-color);
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: 2px solid var(--border-color);
  padding-right: 10px;
  
  > *:not(:last-child) {
    border-bottom: 1px solid var(--border-color);
  }
`;

const SCBullhornIcon = styled(BullhornIcon)`
  font-size: 20px;
  color: var(--black-color);
  margin-right: 20px;
`;

const SCPenIcon = styled(PenIcon)`
  font-size: 20px;
  color: var(--black-color);
  margin-right: 20px;
`;

const LibraryButtons = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0 !important;
`;

const SCButton = styled(Button)` && {
  width: 50%;
  border: none;
  
  &:active, &:hover {
    border: none;
  }
  
  &[data-is-active="true"] {
    background-color: var(--white-color);
    border: 1px solid var(--border-color);
    border-bottom: none;
  }
}
`;

const SCHeaderLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  
  && {
    margin-bottom: 0;
    padding-top: 14px;
    padding-bottom: 14px;
  }
`;
