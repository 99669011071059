import React from 'react';

import { environments } from '~/lib/const';

export const GoogleAnalyticsInit = () => {
  if (!environments.isProduction) {
    return null;
  }

  return (
    <>
      <script async src="https://www.googletagmanager.com/gtag/js?id=G-XBW4T8DVQC" />
      <script
        dangerouslySetInnerHTML={{
          __html: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
    
        gtag('config', 'G-XBW4T8DVQC');`,
        }}
      />
    </>
  );
};
