import { createAsyncThunk } from '@reduxjs/toolkit';

import { blogApi } from '~/api/blog/blog';
import {
  DeletePostParams,
  GetPostByIdParams,
  Post,
  PostThemeEnum,
  ViewPostParams,
} from '~/api/blog/blogTypes';
import {
  blogActions, blogSliceName, DEFAULT_PAGE_SIZE_FOR_BLOGS,
} from '~/feature/blogs/blog.slice';
import { paginationActions } from '~/feature/pagination/pagination.slice';
import { environments } from '~/lib/const';
import { RootState } from '~/store';

export const getPostById = createAsyncThunk<
Post,
GetPostByIdParams,
{ state: RootState }>(
  `${blogSliceName}/getBlogById`,
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(blogActions.setIsGetPostByIdLoading(true));
      const getBlogByIdResult = await blogApi.getPostById(data);

      if ('data' in getBlogByIdResult) {
        await thunkAPI.dispatch(blogActions.changePost(getBlogByIdResult.data));
        return getBlogByIdResult.data;
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      thunkAPI.dispatch(blogActions.changeErrorMessage(error.message));
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(blogActions.setIsGetPostByIdLoading(false));
    }
  },
);

export const getMePosts = createAsyncThunk<
Post[],
void,
{ state: RootState }>(
  `${blogSliceName}/getMePosts`,
  async (_, thunkAPI) => {
    const { perPage, page } = thunkAPI.getState().pagination;
    try {
      thunkAPI.dispatch(blogActions.setIsGetMePostsLoading(true));
      const getMePostsResult = await blogApi.getMePosts({
        perPage,
        page,
      });

      if ('data' in getMePostsResult) {
        thunkAPI.dispatch(blogActions.changeMePosts(getMePostsResult.data));
        thunkAPI.dispatch(paginationActions.setTotal({ total: getMePostsResult.total }));
        thunkAPI.dispatch(paginationActions.setPage({ page: getMePostsResult.currentPage }));
        thunkAPI.dispatch(paginationActions.setPageSize({ perPage: 10 }));
        return getMePostsResult.data;
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(blogActions.setIsGetMePostsLoading(false));
    }
  },
);

export const deletePost = createAsyncThunk<
Post,
DeletePostParams,
{ state: RootState }>(
  `${blogSliceName}/getBlogById`,
  async (data, thunkAPI) => {
    try {
      await blogApi.deletePost(data);
      await thunkAPI.dispatch(getMePosts());
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const viewPost = createAsyncThunk<
Post,
ViewPostParams,
{ state: RootState }>(
  `${blogSliceName}/viewPost`,
  async (data, thunkAPI) => {
    try {
      await blogApi.viewPost(data);
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const getPosts = createAsyncThunk<
Post[],
{ theme?: PostThemeEnum },
{ state: RootState }>(
  `${blogSliceName}/getPosts`,
  async (data, thunkAPI) => {
    const { page } = thunkAPI.getState().pagination;
    const { onlySubscribed, themes } = thunkAPI.getState().blogsFilterNav;
    try {
      thunkAPI.dispatch(blogActions.setIsGetPostsLoading(true));
      const getPostsResult = await blogApi.getPosts({
        page,
        onlySubscribed,
        theme: data?.theme ?? themes.join(','),
        perPage: DEFAULT_PAGE_SIZE_FOR_BLOGS,
      });

      if ('data' in getPostsResult) {
        thunkAPI.dispatch(paginationActions.setPageSize({ perPage: getPostsResult.perPage }));
        thunkAPI.dispatch(paginationActions.setPage({ page: getPostsResult.currentPage }));
        thunkAPI.dispatch(paginationActions.setTotal({ total: getPostsResult.total }));
        await thunkAPI.dispatch(blogActions.changePosts(getPostsResult.data));
        return getPostsResult.data;
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(blogActions.setIsGetPostsLoading(false));
    }
  },
);
