import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const marketingSliceName = 'marketing';

export const marketingSlice = createSlice({
  name: marketingSliceName,
  initialState: {
    campaign: { isActive: false },
    yandexMetrika: { isActive: false },
    accumulativeDiscount: { isActive: false },
    partnerProgram: { isActive: false },
    timerDiscount: { isActive: false },
    advertisingFromBookriver: { isActive: false },
    scheduledDiscount: { isActive: false, numberOfBooks: 0 },
    isLoadingActivatePartnersProgram: false,
  },
  reducers: {
    changeCampaign: (state, action: PayloadAction<boolean>) => {
      state.campaign.isActive = action.payload;
    },
    changeYandexMetrika: (state, action: PayloadAction<boolean>) => {
      state.yandexMetrika.isActive = action.payload;
    },
    changeAccumulativeDiscount: (state, action: PayloadAction<boolean>) => {
      state.accumulativeDiscount.isActive = action.payload;
    },
    changePartnerProgram: (state, action: PayloadAction<boolean>) => {
      state.partnerProgram.isActive = action.payload;
    },
    changeTimerDiscount: (state, action: PayloadAction<boolean>) => {
      state.timerDiscount.isActive = action.payload;
    },
    changeAdvertisingFromBookriver: (state, action: PayloadAction<boolean>) => {
      state.advertisingFromBookriver.isActive = action.payload;
    },
    changeScheduledDiscount: (state, action: PayloadAction<{ isActive: boolean, numberOfBooks: number }>) => {
      state.scheduledDiscount = action.payload;
    },
    changeIsLoadingActivatePartnersProgram: (state, action: PayloadAction<boolean>) => {
      state.isLoadingActivatePartnersProgram = action.payload;
    },
  },
});

export const {
  actions: marketingActions,
  reducer: marketingReducer,
} = marketingSlice;
