import React, { FC, useState } from 'react';
import styled from 'styled-components';

import {
  Post, PostStatus, PostThemeEnum, PostThemeNamesEnum,
} from '~/api/blog/blogTypes';
import {
  AuthorLink, PostEditLink, PostLink,
} from '~/atomic/atom/links/dynamicLinks';
import { BookStatus } from '~/atomic/molecula/BookStatus';
import { deletePost } from '~/feature/blogs/blog.data';
import { usePostContentFotmatting } from '~/feature/blogs/postContentFotmatting';
import {
  MAX_MOBILE_POST_CARD_CONTENT_LENGTH,
  MAX_POST_CARD_CONTENT_LENGTH,
} from '~/feature/blogs/ViewPostCard';
import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import { DateFormatComponent } from '~/lib/dateFormatText';
import { PostPlaceEnum } from '~/pages/post/[slugPost]';
import { useAppDispatch, useAppSelector } from '~/store';

import {
  SCAuthorPostCard,
  SCBottomPostCard,
  SCControlsPostCard,
  SCDatePostCard,
  SCDeletePostCard,
  SCDeletePostCardIcon,
  SCDeletePostCardLoader,
  SCEditPostCardButton,
  SCHeaderPostCard,
  SCMainPostCard,
  SCPostButton,
  SCPostCard, SCPostCommentsIcon, SCPostCommentsWrapper, SCPostCount,
  SCTextPostCard,
  SCTitlePostCard,
  SCTitlePostCardLink,
} from './postCardStyle';

export const MAX_CARD_LENGTH_CONTENT = 400;

export const ReadMoreLinkHTML = (post?: Post) => `...<a href="/post/${post?.slug ?? ''}?place=${PostPlaceEnum.Blog}">читать</a>`;

export const getPostThemeName = (theTheme?: PostThemeEnum) => {
  if (!theTheme) return '';
  const [themeKey] = Object?.entries(PostThemeEnum)
    ?.find((postTheme) => postTheme[1] === theTheme) ?? [];

  return PostThemeNamesEnum?.[themeKey];
};

export const EditPostCard: FC<{ post: Post }> = ({ post }) => {
  const dispatch = useAppDispatch();
  const [deletePostLoading, setDeletePostLoading] = useState(false);

  const deletePostClickHandler = async () => {
    const { Modal } = await import('antd');

    const deletePostConfirm = Modal.error({
      title: 'Удаление записи',
      cancelText: 'Нет',
      okCancel: true,
      okText: 'Да',
      content: (
        <>
          Вы действительно хотите удалить запись
          {' '}
          &quot;
          {post.title}
          &quot;
          ?
        </>
      ),
      onOk: async () => {
        deletePostConfirm.update({ cancelButtonProps: { loading: true } });
        setDeletePostLoading(true);
        await dispatch(deletePost({ id: post.id }));
        setDeletePostLoading(false);
      },
    });
  };

  const PostTitleLink = post.status !== PostStatus.Published
    ? PostEditLink
    : PostLink;

  const isMobileSize = useAppSelector(isMobileSizeSelector);
  const postContent = usePostContentFotmatting(
    post.content,
    isMobileSize ? MAX_MOBILE_POST_CARD_CONTENT_LENGTH : MAX_POST_CARD_CONTENT_LENGTH,
  );

  return (
    <SCPostCard>
      <SCMainPostCard>
        <SCHeaderPostCard>
          <PostTitleLink
            slugPost={post.slug}
            postId={post.id}
          >
            <SCTitlePostCardLink>
              <SCTitlePostCard>
                {post.title}
              </SCTitlePostCard>
            </SCTitlePostCardLink>
          </PostTitleLink>
          <AuthorLink
            username={post?.author?.username ?? ''}
          >
            <SCAuthorPostCard>
              {post?.author?.name ?? ''}
            </SCAuthorPostCard>
          </AuthorLink>
          {post?.publishedAt && (
            <SCDatePostCard>
              <DateFormatComponent date={post?.publishedAt ?? new Date()} />
            </SCDatePostCard>
          )}
          <BookStatus text={getPostThemeName(post?.theme)} type="theme" />
          {post.counters.comments ? (
            <SCPostCommentsWrapper>
              <SCPostCommentsIcon />
              <SCPostCount>{post.counters.comments}</SCPostCount>
            </SCPostCommentsWrapper>
          ) : null}
          <SCPostStatus>
            <BookStatus type={post.status} />
          </SCPostStatus>
        </SCHeaderPostCard>
        <SCTextPostCard
          dangerouslySetInnerHTML={{ __html: postContent }}
        />
        <PostLink slugPost={post.slug}>
          <a>
            <SCPostButton
              size="small"
              style={{ marginTop: '8px' }}
            >
              Читать дальше
            </SCPostButton>
          </a>
        </PostLink>
      </SCMainPostCard>
      <SCBottomPostCard>
        <SCControlsPostCard>
          <PostEditLink
            postId={post.id}
          >
            <a>
              <SCEditPostCardButton>
                Редактировать пост
              </SCEditPostCardButton>
            </a>
          </PostEditLink>
          <SCDeletePostCard
            onClick={deletePostClickHandler}
          >
            {deletePostLoading
              ? (
                <SCDeletePostCardLoader />
              )
              : (
                <SCDeletePostCardIcon />
              )}
          </SCDeletePostCard>
        </SCControlsPostCard>
      </SCBottomPostCard>
    </SCPostCard>
  );
};

const SCPostStatus = styled.span`
  margin-left: 14px;
`;
