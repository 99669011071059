import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const readerGiftTimerDiscountSliceName = 'readerGiftTimerDiscount';

const readerGiftTimerDiscountSlice = createSlice({
  name: readerGiftTimerDiscountSliceName,
  initialState: {
    isOpenGiftModal: false,
    isActivated: false,
  },
  reducers: {
    setIsOpenGiftModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenGiftModal = action.payload;
    },
    setIsActivated: (state, action: PayloadAction<boolean>) => {
      state.isActivated = action.payload;
    },
  },
});

export const {
  actions: readerGiftTimerDiscountActions,
  reducer: readerGiftTimerDiscountReducer,
} = readerGiftTimerDiscountSlice;
