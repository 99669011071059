import { Book } from '~/api/book/bookApiTypes';
import {
  Campaign,
  CreateCampaignsProps,
  GetCampaignBooksProps,
  GetCampaignProps,
  GetCampaignsResult,
  GetNetworkBooksProps,
  GetPixelProps,
  NetworkBook,
  TargetologistType,
  VisitLinkCampaignParams,
} from '~/api/finance/marketing/campaignsApiTypes';
import { provider } from '~/api/provider/provider.config';
import { handleRejectedRequest } from '~/api/provider/providerErrors';
import { ApiResponse, RejectedRequest } from '~/api/provider/providerTypes';
import { getDeviceSessionUid } from '~/feature/utils/deviceSessionUid';

const namespace = Symbol('namespace');

export const campaignsApi = {
  [namespace]: 'v1/ad-campaigns',

  async visitLinkCampaign(data: VisitLinkCampaignParams) {
    const deviceSessionUid = getDeviceSessionUid();

    try {
      await provider.post<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/visit`,
        data,
        { headers: { 'device-session-uid': deviceSessionUid } },
      );
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getCampaigns() {
    try {
      const response = await provider.get<
      ApiResponse<GetCampaignsResult, RejectedRequest<void>>
      >(
        `${this[namespace]}`,
      );

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getCampaignBooks(data: GetCampaignBooksProps) {
    try {
      const response = await provider.get<
      ApiResponse<Book[], RejectedRequest<void>>
      >(`${this[namespace]}/books-autocomplete`, { params: { ...data, perPage: 100 } });

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getPixel(data: GetPixelProps) {
    try {
      const response = await provider.get<
      ApiResponse<{ vk: string | null }, RejectedRequest<void>>
      >(`${this[namespace]}/pixel`, { params: data });

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getTargetologists() {
    try {
      const response = await provider.get<
      ApiResponse<TargetologistType[], RejectedRequest<void>>
      >(
        `${this[namespace]}/targetologists`,
      );

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getCampaign(data: GetCampaignProps) {
    try {
      const response = await provider.get<
      ApiResponse<Campaign, RejectedRequest<void>>
      >(
        `${this[namespace]}/${data.adCampaignId}`,
        {
          headers: {
            Authorization: data && data?.token
              ? `Bearer ${data?.token}`
              : provider.defaults.headers.common.Authorization,
          },
        },
      );

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getAdNetworkBooks(data: GetNetworkBooksProps) {
    try {
      const response = await provider.get<
      ApiResponse<NetworkBook[], RejectedRequest<void>>
      >(
        'v1/ad-network/books',
        { params: data },
      );

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async deleteCampaign(data: GetCampaignProps) {
    try {
      const response = await provider.delete<
      ApiResponse<Campaign, RejectedRequest<void>>
      >(
        `${this[namespace]}/${data.adCampaignId}`,
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async createCampaigns(data: CreateCampaignsProps) {
    try {
      const response = await provider.post<
      ApiResponse<Campaign, RejectedRequest<void>>
      >(`${this[namespace]}`, data);

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
};
