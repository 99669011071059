import { createSelector } from '@reduxjs/toolkit';

import { CommentStatusEnum } from '~/api/comment/commentApiTypes';
import { RootState } from '~/store';

export const commentsSelector = (state: RootState) => state.comments;

export const preModerationCommentsCountSelector = createSelector(
  commentsSelector,
  ({ comments }) => comments
    ?.filter((comment) => comment.status === CommentStatusEnum.PreModeration)
    ?.length,
);
