import { WithToken } from '~/api/auth/authApiTypes';
import {
  BookIdType, BookTypeEnum, CompleteStatusEnum,
} from '~/api/book/bookApiTypes';
import { BookReactionRatingEnum } from '~/graphql/books/factory/book/BookFactoryTypes';

export enum MyBookStatusEnum {
  readNow = 'read_now',
  readLater = 'read_later',
  read = 'read',
  NotRead = 'not_read',
  Empty = '',
}

export enum MyBookStatusTextEnum {
  read_now = 'Читаю',
  read_later = 'Хочу прочитать',
  read = 'Прочитал',
  delete = 'Убрать из библиотеки',
}

export type GetMyBooksReactionParam = BookReactionRatingEnum | 'all';

export interface GetMyBooksParams extends WithToken {
  genreSlugs: string[];
  statuses: MyBookStatusEnum;
  statusComplete: CompleteStatusEnum;
  page: number;
  perPage: number;
  free?: boolean;
  type?: BookTypeEnum;
  reaction: GetMyBooksReactionParam;
}

export interface GetMyViewedBooksParams extends WithToken {
  page: number;
  perPage: number;
}

export interface ChangeMyBookStatus {
  status: MyBookStatusEnum;
}

export interface ChangeBookStatusParams extends ChangeMyBookStatus {
  bookId: BookIdType;
}

export interface ChangeBookStatusResult {
  message: string
}

export interface DeleteMyBookParams {
  bookId: BookIdType;
}

export interface SavePlaceReadParams {
  chapterId: number;
  bookId: BookIdType;
  chapterProgress: number;
}

export interface GetPlaceReadByBookSlugParams {
  slug: string,
}

export interface GetPlaceReadResult {
  bookId: BookIdType,
  chapterId: number,
  chapterProgress: number
}

export interface HiddenBookParams {
  bookId: BookIdType;
}
