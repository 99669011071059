import React from 'react';
import styled from 'styled-components';

export enum StatusColorEnum {
  Green = 'green',
  Yellow = 'yellow',
  Red = 'red',
  Blue = 'blue',
}

export interface StatusProps extends React.HTMLAttributes<HTMLDivElement> {
  className?: string;
  color?: StatusColorEnum | undefined;
}

export const Status = ({
  children,
  color = StatusColorEnum.Green,
  ...props
}: StatusProps) => {
  return (
    <SCStatus
      {...props}
      data-color={color}
    >
      {children}
    </SCStatus>
  );
};

const SCStatus = styled.div`
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  line-height: 10px;
  color: var(--black-color);
  font-family: var(--second-font);
  text-transform: uppercase;
  letter-spacing: 0.05em;
  border-radius: 10px;
  display: flex;
  align-items: center;
  flex-shrink: 0;
  background-repeat: no-repeat;
  padding: 4px 10px;

  &[data-color="green"] {
    background-color: var(--green-color);
  }
  
  &[data-color="blue"] {
    background-color: var(--blue-color);
  }

  &[data-color="yellow"] {
    background-color: var(--yellow-color);
  }
  
  &[data-color="red"] {
    background-color: #FCE1E8;
  }
`;
