import React from 'react';
import styled from 'styled-components';

import { pagesConfigStore } from '~/atomic/atom/links/pagesConfigStore';
import { NavItem, SCNavMenuIcon } from '~/atomic/organism/NavMenu';
import { SCCounter } from '~/atomic/Typography';
import { userSelector } from '~/feature/user/user.selector';
import { useAppSelector } from '~/store';

export const AuthorNavigationLinks = () => {
  const { userCounters, user } = useAppSelector(userSelector);

  return (
    <SCAuthorNavigationLinks>
      <SCList>
        <NavItem href={pagesConfigStore.books.url} shallow>
          <SCNavMenuIcon src="/icon/books.svg" />
          Мои произведения
          {' '}
          {userCounters?.books > 0 && (
            <SCCounter>
              {userCounters?.books > 99 ? '99+' : userCounters?.books}
            </SCCounter>
          )}
        </NavItem>
        <NavItem href={pagesConfigStore.statistics.url} shallow>
          <SCNavMenuIcon src="/icon/statistics.svg" />
          Статистика
        </NavItem>
        <NavItem href={pagesConfigStore.myblog.url} shallow>
          <SCNavMenuIcon src="/icon/record.svg" />
          Блог
          {userCounters?.posts > 0 && (
            <SCCounter>
              {userCounters?.posts > 99 ? '99+' : userCounters?.posts}
            </SCCounter>
          )}
        </NavItem>
        <NavItem
          href={pagesConfigStore.authorSubscribers.url}
          as={pagesConfigStore.authorSubscribers.as(user.username)}
        >
          <SCNavMenuIcon src="/icon/subscribers.svg" alt="иконка человека" />
          Подписчики
          {' '}
          {user?.counters?.subscribers !== 0 && (
            <SCCounter>{user?.counters?.subscribers}</SCCounter>
          )}
        </NavItem>
      </SCList>
    </SCAuthorNavigationLinks>
  );
};

const SCAuthorNavigationLinks = styled.li``;

const SCList = styled.ul`
  > * {
    border-bottom: 1px solid var(--bg-color);
    border-top: 1px solid var(--bg-color);
  }
`;
