import {
  createAsyncThunk, createSlice, PayloadAction,
} from '@reduxjs/toolkit';

import { accountApi } from '~/api/account/accountApi';
import { EmailRequestParams } from '~/api/account/accountApiTypes';
import { environments } from '~/lib/const';

export const changeEmail = createAsyncThunk<void, EmailRequestParams, {
  rejectValue: { error: string };
}>(
  'user/changeEmail',
  async (
    data,
    thunkAPI,
  ) => {
    try {
      thunkAPI.dispatch(changeEmailFormActions.setLoading(true));
      const result = await accountApi.changeEmail(data);

      if (result?.message) {
        const { message } = await import('~/atomic/atom/message');
        message.success(result.message);
      }
      thunkAPI.dispatch(changeEmailFormActions.setDisabled(true));
      thunkAPI.dispatch(changeEmailFormActions.setOpenModal(false));
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(changeEmailFormActions.setLoading(false));
    }
  },
);

const changeEmailFormSlice = createSlice({
  name: 'changeEmailForm',
  initialState: {
    isDisabled: true,
    isLoading: false,
    isOpenModal: false,
  },
  reducers: {
    setDisabled: (state, action: PayloadAction<boolean>) => {
      state.isDisabled = action.payload;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.isLoading = action.payload;
    },
    setOpenModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenModal = action.payload;
    },
  },
});

export const {
  actions: changeEmailFormActions,
  reducer: changeEmailFormReducer,
} = changeEmailFormSlice;

export type ChangeEmailFormState = ReturnType<typeof changeEmailFormReducer>;
