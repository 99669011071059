import { BookIdType } from '~/api/book/bookApiTypes';

export interface Chapter {
  id: string;
  content: string;
  name: string;
  symbols?: number;
  authorPages?: number;
  createdAt: string;
  publicationScheduledFor?: string;
  updatedAt?: string;
  isAvailable: boolean;
  protected: boolean;
  bookId: BookIdType;
  free?: boolean;
  status?: ChapterStatus;
}

export enum ChapterStatus {
  Draft = 'draft',
  Published = 'published',
  Scheduled = 'scheduled',
}

export interface ChapterAudio {
  url?: string;
  available: boolean;
  duration: number;
  status: string;
}

export interface ChapterTitle {
  id: string;
  name: string;
  symbols?: number;
  createdAt: string;
  updatedAt?: string;
  firstPublishedAt?: string;
  number: number;
  status?: ChapterStatus;
  isAvailable: boolean;
  views: number;
  protected?: boolean;
  audio: ChapterAudio;
  publicationScheduledFor?: string;
}

export interface CreateTextChapterFields {
  name: string;
  content: string;
  publicationScheduledFor?: string;
}

export interface CreateTextChapterRejectValue {
  name?: string[];
  content?: string[];
}

export interface CreateTextChapterParams extends CreateTextChapterFields {
  bookId: BookIdType;
  status?: ChapterStatus;
}

export interface GetTextChapterParams {
  id: string;
  symbols?: number;
}

export interface GetChapterListParams {
  id: string | number;
}

export interface GetPublishedChapterListByBookSlugParams {
  slug: string
}

export interface UpdateTextChapterParams {
  chapterId: string;
  name: string;
  content: string;
  protected: boolean;
  publicationScheduledFor?: string;
  status?: ChapterStatus;
}

export type AnchorsErrors = Record<string, string[]> & { general: string };

export interface DeleteTextChaptersParams {
  id: string;
}

export interface UpdateChapterIndexParams {
  id: string;
  number: number;
}

export interface ChangeStatusParams {
  id: string;
  status: ChapterStatus;
}

export interface PublistChaptersParams {
  chapterIds: number[]
}

export interface CreateAudioChapterParams {
  id: number;
  file: File;
}

export interface DeleteAudioChapterParams {
  id: number;
}
