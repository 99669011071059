import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const buyAbonnementSliceName = 'buyAbonnement';

const buyAbonnementSlice = createSlice({
  name: buyAbonnementSliceName,
  initialState: { isOpenChoosePayModal: false },
  reducers: {
    setIsOpenChoosePayModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenChoosePayModal = action.payload;
    },
  },
});

export const {
  actions: buyAbonnementActions,
  reducer: buyAbonnementReducer,
} = buyAbonnementSlice;
