import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const activateReaderAdTimerDiscountSliceName = 'activateReaderAdTimerDiscount';

export const activateReaderAdTimerDiscountSlice = createSlice({
  name: activateReaderAdTimerDiscountSliceName,
  initialState: {
    isAvailableActivateReaderAdTimerDiscount: false,
    isOpenLoginModalWithActivateReaderAdTimerDiscount: false,
    isOpenRegisterModalWithActivateReaderAdTimerDiscount: false,
    isOpenSuccessActivateReaderAdTimerDiscountModal: false,
    isOpenSaveUserLoginModalWithActivateReaderAdTimerDiscount: false,
  },
  reducers: {
    setIsOpenLoginModalWithActivateReaderAdTimerDiscount: (state, action: PayloadAction<boolean>) => {
      state.isOpenLoginModalWithActivateReaderAdTimerDiscount = action.payload;
    },
    setIsOpenRegisterModalWithActivateReaderAdTimerDiscount: (state, action: PayloadAction<boolean>) => {
      state.isOpenRegisterModalWithActivateReaderAdTimerDiscount = action.payload;
    },
    setIsOpenSaveUserLoginModalWithActivateReaderAdTimerDiscount: (state, action: PayloadAction<boolean>) => {
      state.isOpenSaveUserLoginModalWithActivateReaderAdTimerDiscount = action.payload;
    },
    setIsOpenSuccessActivateReaderAdTimerDiscountModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenSuccessActivateReaderAdTimerDiscountModal = action.payload;
    },
    setIsAvailableActivateReaderAdTimerDiscount: (state, action: PayloadAction<boolean>) => {
      state.isAvailableActivateReaderAdTimerDiscount = action.payload;
    },
  },
});

export const {
  actions: activateReaderAdTimerDiscountActions,
  reducer: activateReaderAdTimerDiscountReducer,
} = activateReaderAdTimerDiscountSlice;
