import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetServerSidePropsContext } from 'next';

import { WithToken } from '~/api/auth/authApiTypes';
import { BookSortingEnum } from '~/api/book/bookApiTypes';
import {
  ActivateTimerDiscountProps, TimerDiscount,
  TimerDiscountOfferSourceEnum,
} from '~/api/book/discountBooksApiTypes';
import { discountsApi } from '~/api/book/discountsApi';
import { partnersBooksApi } from '~/api/finance/marketing/partnersBooksApi';
import { HttpConflictError } from '~/api/provider/providerErrors';
import { paginationSelector } from '~/feature/pagination/pagination.selector';
import { paginationActions } from '~/feature/pagination/pagination.slice';
import { getQueriesFromUrl } from '~/feature/utils/getQueriesFromUrl/getQueriesFromUrl';
import { withTimerDiscountSelector } from '~/feature/withTiwerDiscount/withTimerDiscount.selectors';
import {
  activateTimerDiscountFromUrlActionName,
  withTimerDiscountActions, withTimerDiscountSliceName,
} from '~/feature/withTiwerDiscount/withTimerDiscount.slice';
import { environments } from '~/lib/const';
import { RootState } from '~/store';

export const getBooksWithTimerDiscount = createAsyncThunk<void, WithToken | void, {
  state: RootState
}>(
  `${withTimerDiscountSliceName}/getBooksWithTimerDiscount`,
  async (data, thunkAPI) => {
    thunkAPI.dispatch(withTimerDiscountActions.setIsLoadTimerDiscountBooks(true));
    const { perPage, page } = paginationSelector(thunkAPI.getState());
    const { filter } = withTimerDiscountSelector(thunkAPI.getState());
    try {
      const result = await partnersBooksApi.getPartnersBooks({
        genres: filter.genres.length ? filter.genres.join(',') : undefined,
        statusComplete: filter.completeStatus,
        type: filter.bookType,
        sortingType: BookSortingEnum.Rand,
        download: filter.isCanDownload === true ? true : undefined,
        withAdvertisingFromBookriver: filter?.withAdvertisingFromBookriver ? true : undefined,
        withTimerDiscount: true,
        page,
        perPage,
        token: data && data?.token ? data.token : undefined,
      });

      if (result && 'data' in result) {
        thunkAPI.dispatch(withTimerDiscountActions.changeBooks(result.data));
        thunkAPI.dispatch(paginationActions.setPage({ page: result.currentPage }));
        thunkAPI.dispatch(paginationActions.setPageSize({ perPage: result.perPage }));
        thunkAPI.dispatch(paginationActions.setTotal({ total: result.total }));
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(withTimerDiscountActions.setIsLoadTimerDiscountBooks(false));
    }
  },
);

export const getTimerDiscount = createAsyncThunk<void,
WithToken | void,
{ state: RootState }>(
  `${withTimerDiscountSliceName}/getTimerDiscount`,
  async (data, thunkAPI) => {
    const { isTimerLoading } = withTimerDiscountSelector(thunkAPI.getState());
    if (!isTimerLoading) {
      try {
        thunkAPI.dispatch(withTimerDiscountActions.setIsTimerLoading(true));
        const result = await discountsApi.getTimerDiscount(data);

        if (result && 'data' in result) {
          await thunkAPI.dispatch(withTimerDiscountActions.changeTimer(result.data));
        }
      } catch (error) {
        if (environments.isClient && error instanceof Error) {
          const { message } = await import('~/atomic/atom/message');
          message.error(error.message);
        }
        return thunkAPI.rejectWithValue({ error: error.message });
      } finally {
        thunkAPI.dispatch(withTimerDiscountActions.setIsTimerLoading(false));
      }
    }
  },
);

export const checkVMDParamsInQueries = createAsyncThunk<
void,
GetServerSidePropsContext,
{ state: RootState }
>(
  `${withTimerDiscountSliceName}/checkVMDParamsInQueries`,
  async (
    ctx,
    thunkAPI,
  ) => {
    if (ctx && 'query' in ctx) {
      const { query } = ctx;

      const adQuery = query?.ad;

      if (adQuery && adQuery === 'set') {
        thunkAPI.dispatch(withTimerDiscountActions.setWithAdvertisingFromBookriver(true));
      }
    }
  },
);

export const activateTimerDiscount = createAsyncThunk<
TimerDiscount,
ActivateTimerDiscountProps,
{ state: RootState }
>(
  `${withTimerDiscountSliceName}/activateTimerDiscount`,
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(withTimerDiscountActions.setIsActivateTimerDiscountLoading(true));
      const result = await discountsApi.activateTimerDiscount(data);

      if (result && 'data' in result) {
        return result.data;
      }
    } catch (error) {
      if (error?.message) {
        const { message } = await import('~/atomic/atom/message');

        if (data.offerSource !== TimerDiscountOfferSourceEnum.LastChapter) {
          if (error instanceof HttpConflictError) {
            message.success({
              content: error?.message,
              duration: 3,
            });
          } else {
            message.error({
              content: error?.message,
              duration: 3,
            });
          }
        }
      }
      return thunkAPI.rejectWithValue(error.message);
    } finally {
      thunkAPI.dispatch(withTimerDiscountActions.setIsActivateTimerDiscountLoading(false));
    }
  },
);

export const getActivateTimerDiscountTypeFromUrl = async (): Promise<undefined | TimerDiscountOfferSourceEnum> => {
  const queries = await getQueriesFromUrl({ searchedQueries: ['action', 'offerSource'] });

  const action = queries?.action;
  const offerSource = queries?.offerSource as TimerDiscountOfferSourceEnum;

  const isHaveActionActivateParam = action && action === activateTimerDiscountFromUrlActionName;
  const isHaveOfferSourceActivateParam = offerSource
    && Object.values(TimerDiscountOfferSourceEnum).includes(offerSource);

  if (isHaveActionActivateParam || isHaveOfferSourceActivateParam) return offerSource;
};
