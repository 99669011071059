import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetServerSidePropsContext } from 'next';

import { commentApi } from '~/api/comment/commentApi';
import {
  AddCommentResult, Comment, CreateCommentParams,
  DeleteCommentParams,
  GetCommentsParams,
  PublishCommentsParams,
} from '~/api/comment/commentApiTypes';
import { getAuthTokenFromClient } from '~/feature/authorization/getAuthToken';
import { commentsSelector } from '~/feature/comments/comment.selector';
import { commentActions } from '~/feature/comments/comment.slice';
import { paginationSelector } from '~/feature/pagination/pagination.selector';
import { paginationActions } from '~/feature/pagination/pagination.slice';
import { getQueriesFromUrl } from '~/feature/utils/getQueriesFromUrl/getQueriesFromUrl';
import { booksGraphql, GetBookCommentsParams } from '~/graphql/books/booksGraphql';
import { environments } from '~/lib/const';
import { RootState } from '~/store';

const DEFAULT_COMMENT_PER_PAGE = 50;

export const checkMoveOnBookWithCurrentComment = createAsyncThunk<
void,
GetServerSidePropsContext, { state: RootState }>(
  'comment/checkMoveOnBookWithCurrentComment',
  async (ctx, thunkAPI) => {
    try {
      if ('commentIdCursor' in ctx.query) {
        const { commentIdCursor } = ctx.query;
        thunkAPI.dispatch(commentActions.changeCommentIdCursor(Number(commentIdCursor as string)));
      }
    } catch (e) {
      return thunkAPI.rejectWithValue({ message: e.message });
    }
  },
);

export const deleteMoveOnBookWithCurrentComment = createAsyncThunk(
  'comment/deleteMoveOnBookWithCurrentComment',
  async (ctx, thunkAPI) => {
    try {
      await getQueriesFromUrl({ searchedQueries: ['commentIdCursor'] });
    } catch (e) {
      return thunkAPI.rejectWithValue({ message: e.message });
    }
  },
);

export const getComments = createAsyncThunk<void, GetCommentsParams, {
  rejectValue: { error: string }, state: RootState;
}>(
  'comment/getComments',
  async (
    data,
    thunkAPI,
  ) => {
    thunkAPI.dispatch(commentActions.addLoading('getComments'));
    const { page } = paginationSelector(thunkAPI.getState());

    try {
      const response = await commentApi.getComments({
        ...data, page, perPage: DEFAULT_COMMENT_PER_PAGE,
      });

      if ('data' in response) {
        if (response?.saveComment?.id) {
          thunkAPI.dispatch(commentActions.changeSaveComment(response.saveComment));
        } else {
          thunkAPI.dispatch(commentActions.changeSaveComment({} as Comment));
        }

        thunkAPI.dispatch(paginationActions.setPageSize({ perPage: response.perPage }));
        thunkAPI.dispatch(paginationActions.setPage({ page: response.currentPage }));
        thunkAPI.dispatch(paginationActions.setTotal({ total: response.total }));

        const comments = response.data;
        comments.sort(
          (a, b) => Number(new Date(b.createdAt)) - Number(new Date(a.createdAt)),
        );
        thunkAPI.dispatch(commentActions.changeComments(comments));
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(commentActions.removeLoading('getComments'));
    }
  },
);

export const getBookComments = createAsyncThunk<void, GetBookCommentsParams, {
  rejectValue: { error: string }, state: RootState;
}>(
  'comment/getBookComments',
  async (
    data,
    thunkAPI,
  ) => {
    thunkAPI.dispatch(commentActions.addLoading('getComments'));
    const { page } = paginationSelector(thunkAPI.getState());
    const { commentIdCursor } = commentsSelector(thunkAPI.getState());

    try {
      const response = await booksGraphql.getBookComments({
        perPage: 10,
        page,
        bookId: data.bookId,
        commentIdCursor,
        token: getAuthTokenFromClient(),
      });
      thunkAPI.dispatch(paginationActions.setPageSize({ perPage: response.perPage }));
      thunkAPI.dispatch(paginationActions.setPage({ page: response.page }));
      thunkAPI.dispatch(paginationActions.setTotal({ total: response.total }));

      const { comments } = response;
      comments.sort(
        (a, b) => Number(new Date(b.createdAt)) - Number(new Date(a.createdAt)),
      );
      thunkAPI.dispatch(commentActions.changeGraphqlBookComments(comments));
      thunkAPI.dispatch(commentActions.changeTotalBookComments(response.totalNumberOfComments));
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(commentActions.removeLoading('getComments'));
    }
  },
);

export const addComment = createAsyncThunk<AddCommentResult, CreateCommentParams, {
  rejectValue: { error: string };
}>(
  'comment/addComment',
  async (newComment, thunkAPI) => {
    if (newComment.parentId) {
      thunkAPI.dispatch(commentActions.addLoading('addReplay'));
    } else {
      thunkAPI.dispatch(commentActions.addLoading('addComment'));
    }
    try {
      const result = await commentApi.createComment(newComment);

      if (result && 'data' in result) {
        return result.data;
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      if (newComment.parentId) {
        thunkAPI.dispatch(commentActions.removeLoading('addReplay'));
      } else {
        thunkAPI.dispatch(commentActions.removeLoading('addComment'));
      }
    }
  },
);

export const deleteComment = createAsyncThunk<void, DeleteCommentParams, {
  rejectValue: { error: string };
}>(
  'comment/deleteComment',
  async (data, thunkAPI) => {
    try {
      const result = await commentApi.deleteComment(data);

      thunkAPI.dispatch(commentActions.deleteComment(data.id as number));
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const deleteBookComment = createAsyncThunk<void, DeleteCommentParams, {
  rejectValue: { error: string };
}>(
  'comment/deleteBookComment',
  async (data, thunkAPI) => {
    try {
      await commentApi.deleteComment(data);
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const deleteSaveComment = createAsyncThunk<void, void, {
  rejectValue: { error: string }; state: RootState
}>(
  'comment/deleteSaveComment',
  async (_, thunkAPI) => {
    try {
      const { saveComment } = thunkAPI.getState().comments;
      if (saveComment?.id) {
        thunkAPI.dispatch(commentActions.setIsDeleteSaveCommentLoading(true));
        const result = await commentApi.deleteComment({ id: saveComment.id as number });
        thunkAPI.dispatch(commentActions.deleteSaveComment());
        if (result && 'data' in result) {
          return result.data;
        }
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(commentActions.setIsDeleteSaveCommentLoading(false));
    }
  },
);

export interface PublishCommentsThunkParams extends PublishCommentsParams {
  targetClass: 'book' | 'post';
  targetId: string | number;
}

export const publishComments = createAsyncThunk<void, PublishCommentsThunkParams, {
  rejectValue: { error: string }; state: RootState
}>(
  'comment/publishComments',
  async (data, thunkAPI) => {
    try {
      thunkAPI.dispatch(commentActions.setIsPublishCommentsLoading(true));
      await commentApi.publishComments(data);
      if (environments.isClient) {
        const { message } = await import('~/atomic/atom/message');
        message.success('Комментарии успешно опубликованы!');
      }
      await thunkAPI.dispatch(getComments({
        targetClass: data.targetClass,
        targetId: data.targetId,
      }));
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(commentActions.setIsPublishCommentsLoading(false));
    }
  },
);
