import { Genre } from '~/api/book/genreApiTypes';

export const getGenreSlugByName = (name: string) => {
  return genres.find(
    (genre) => genre.name === name,
  )?.slug ?? '';
};

export const getGenreNameBySlug = ({ slug }:{ slug: string }) => {
  return genres.find(
    (genre) => genre.slug === slug,
  )?.name ?? '';
};

export const getGenreBySlug = (slug: string) => {
  return genres.find(
    (genre) => genre.slug === slug,
  );
};

export const genres: Genre[] = [
  {
    name: 'Фантастика',
    slug: 'fantastika',
  },
  {
    name: 'Боевая фантастика',
    slug: 'boevaya-fantastika',
    parentGenreSlug: 'fantastika',
  },
  {
    name: 'Героическая фантастика',
    slug: 'geroicheskaya-fantastika',
    parentGenreSlug: 'fantastika',
  },
  {
    name: 'Научная фантастика',
    slug: 'nauchnaya-fantastika',
    parentGenreSlug: 'fantastika',
  },
  {
    name: 'Постапокалипсис',
    slug: 'postapokalipsis',
    parentGenreSlug: 'fantastika',
  },
  {
    name: 'Космическая фантастика',
    slug: 'kosmicheskaya-fantastika',
    parentGenreSlug: 'fantastika',
  },
  {
    name: 'Киберпанк',
    slug: 'kiberpank',
    parentGenreSlug: 'fantastika',
  },
  {
    name: 'Детективная фантастика',
    slug: 'detektivnaya-fantastika',
    parentGenreSlug: 'fantastika',
  },
  {
    name: 'Социальная фантастика',
    slug: 'sotsialnaya-fantastika',
    parentGenreSlug: 'fantastika',
  },
  {
    name: 'Альтернативная история',
    slug: 'alternativnaya-istoriya',
    parentGenreSlug: 'fantastika',
  },
  {
    name: 'ЛитРПГ',
    slug: 'litrpg',
    parentGenreSlug: 'fantastika',
  },
  {
    name: 'Боярь-аниме',
    slug: 'boyar-anime',
    parentGenreSlug: 'fantastika',
  },
  {
    name: 'Стимпанк',
    slug: 'stimpank',
    parentGenreSlug: 'fantastika',
  },
  {
    name: 'Юмористическая фантастика',
    slug: 'yumoristicheskaya-fantastika',
    parentGenreSlug: 'fantastika',
  },
  {
    name: 'Фэнтези',
    slug: 'fentezi',
  },
  {
    name: 'Боевое фэнтези',
    slug: 'boevoe-fentezi',
    parentGenreSlug: 'fentezi',
  },
  {
    name: 'Бытовое фэнтези',
    slug: 'bytovoe-fentezi',
    parentGenreSlug: 'fentezi',
  },
  {
    name: 'Героическое фэнтези',
    slug: 'geroicheskoe-fentezi',
    parentGenreSlug: 'fentezi',
  },
  {
    name: 'Городское фэнтези',
    slug: 'gorodskoe-fentezi',
    parentGenreSlug: 'fentezi',
  },
  {
    name: 'Историческое фэнтези',
    slug: 'istoricheskoe-fentezi',
    parentGenreSlug: 'fentezi',
  },
  {
    name: 'Тёмное фэнтези',
    slug: 'temnoe-fentezi',
    parentGenreSlug: 'fentezi',
  },
  {
    name: 'Славянское фэнтези',
    slug: 'slavyanskoe-fentezi',
    parentGenreSlug: 'fentezi',
  },
  {
    name: 'Юмористическое фэнтези',
    slug: 'yumoristicheskoe-fentezi',
    parentGenreSlug: 'fentezi',
  },
  {
    name: 'Приключенческое фэнтези',
    slug: 'priklyuchencheskoe-fentezi',
    parentGenreSlug: 'fentezi',
  },
  {
    name: 'Эпическое фэнтези',
    slug: 'epicheskoe-fentezi',
    parentGenreSlug: 'fentezi',
  },
  {
    name: 'Магические академии',
    slug: 'magicheskie-akademii',
    parentGenreSlug: 'fentezi',
  },
  {
    name: 'Попаданцы',
    slug: 'popadantsy',
  },
  {
    name: 'Любовный роман',
    slug: 'lyubovnyi-roman',
  },
  {
    name: 'Современный любовный роман',
    slug: 'sovremennyi-lyubovnyi-roman',
    parentGenreSlug: 'lyubovnyi-roman',
  },
  {
    name: 'Любовная фантастика',
    slug: 'lyubovnaya-fantastika',
    parentGenreSlug: '',
  },
  {
    name: 'Любовное фэнтези',
    slug: 'lyubovnoe-fentezi',
    parentGenreSlug: 'lyubovnyi-roman',
  },
  {
    name: 'Остросюжетный любовный роман',
    slug: 'ostrosyuzhetnyi-lyubovnyi-roman',
    parentGenreSlug: 'lyubovnyi-roman',
  },
  {
    name: 'Исторический любовный роман',
    slug: 'istoricheskii-lyubovnyi-roman',
    parentGenreSlug: 'lyubovnyi-roman',
  },
  {
    name: 'Короткий любовный роман',
    slug: 'korotkii-lyubovnyi-roman',
    parentGenreSlug: 'lyubovnyi-roman',
  },
  {
    name: 'Фанфик',
    slug: 'fanfik',
  },
  {
    name: 'Детектив',
    slug: 'detektiv',
  },
  {
    name: 'Исторический детектив',
    slug: 'istoricheskii-detektiv',
    parentGenreSlug: 'detektiv',
  },
  {
    name: 'Классический детектив',
    slug: 'klassicheskii-detektiv',
    parentGenreSlug: 'detektiv',
  },
  {
    name: 'Юмористический детектив',
    slug: 'yumoristicheskii-detektiv',
    parentGenreSlug: 'detektiv',
  },
  {
    name: 'Шпионский детектив',
    slug: 'shpionskii-detektiv',
    parentGenreSlug: 'detektiv',
  },
  {
    name: 'Политический детектив',
    slug: 'politicheskii-detektiv',
    parentGenreSlug: 'detektiv',
  },
  {
    name: 'Криминальный детектив',
    slug: 'kriminalnyi-detektiv',
    parentGenreSlug: 'detektiv',
  },
  {
    name: 'Эротика',
    slug: 'erotika',
  },
  {
    name: 'Романтическая эротика',
    slug: 'romanticheskaya-erotika',
    parentGenreSlug: 'erotika',
  },
  {
    name: 'Эротическое фэнтези',
    slug: 'eroticheskoe-fentezi',
    parentGenreSlug: 'erotika',
  },
  {
    name: 'Эротическая фантастика',
    slug: 'eroticheskaya-fantastika',
    parentGenreSlug: 'erotika',
  },
  {
    name: 'Эротический фанфик',
    slug: 'eroticheskii-fanfik',
    parentGenreSlug: 'erotika',
  },
  {
    name: 'Жёсткая эротика',
    slug: 'zhyostkaya-erotika',
    parentGenreSlug: 'erotika',
  },
  {
    name: 'С элементами эротики',
    slug: 's-elementami-erotiki',
    parentGenreSlug: 'erotika',
  },
  {
    name: 'Молодежная проза',
    slug: 'molodezhnaya-proza',
  },
  {
    name: 'Сказка',
    slug: 'skazki',
  },
  {
    name: 'Разное',
    slug: 'raznoe',
  },
  {
    name: 'Детская литература',
    slug: 'detskaya-literatura',
    parentGenreSlug: 'raznoe',
  },
  {
    name: 'Бизнес-литература',
    slug: 'biznes-literatura',
    parentGenreSlug: 'raznoe',
  },
  {
    name: 'Развитие личности',
    slug: 'razvitie-lichnosti',
    parentGenreSlug: 'raznoe',
  },
  {
    name: 'Профессиональная литература',
    slug: 'professionalnaya-literatura',
    parentGenreSlug: 'raznoe',
  },
  {
    name: 'Учебная литература',
    slug: 'uchebnaya-literatura',
    parentGenreSlug: 'raznoe',
  },
  {
    name: 'Психология',
    slug: 'psikhologiya',
    parentGenreSlug: 'raznoe',
  },
  {
    name: 'Биографии и мемуары',
    slug: 'biografii-i-memuary',
    parentGenreSlug: 'raznoe',
  },
  {
    name: 'Стихи, поэзия',
    slug: 'stikhi-poeziya',
  },
  {
    name: 'Боевик',
    slug: 'boevik',
  },
  {
    name: 'Хоррор',
    slug: 'uzhasy',
  },
  {
    name: 'Мистика',
    slug: 'mistika',
  },
  {
    name: 'Современная проза',
    slug: 'sovremennaya-proza',
  },
  {
    name: 'Историческая проза',
    slug: 'istoricheskaya-proza',
  },
  {
    name: 'Триллер',
    slug: 'triller',
  },
  {
    name: 'Драма',
    slug: 'drama',
  },
  {
    name: 'Юмористический роман',
    slug: 'yumor',
  },
  {
    name: 'Хобби, досуг',
    slug: 'khobbi-dosug',
  },
];
