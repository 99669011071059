import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const activateFeedTimerDiscountSliceName = 'activateFeedTimerDiscount';

const activateFeedTimerDiscountSlice = createSlice({
  name: activateFeedTimerDiscountSliceName,
  initialState: {
    isShowActivateFeedTimerDiscountModal: false,
    isOpenLoginModalWithActivateFeedTimerDiscount: false,
    isOpenRegisterModalWithActivateFeedTimerDiscount: false,
    isOpenSaveUserModalWithActivateFeedTimerDiscount: false,
  },
  reducers: {
    setIsShowActivateFeedTimerDiscountModal: (state, action: PayloadAction<boolean>) => {
      state.isShowActivateFeedTimerDiscountModal = action.payload;
    },
    setIsOpenLoginModalWithActivateFeedTimerDiscount: (state, action: PayloadAction<boolean>) => {
      state.isOpenLoginModalWithActivateFeedTimerDiscount = action.payload;
    },
    setIsOpenRegisterModalWithActivateFeedTimerDiscount: (state, action: PayloadAction<boolean>) => {
      state.isOpenRegisterModalWithActivateFeedTimerDiscount = action.payload;
    },
    setIsOpenSaveUserModalWithActivateFeedTimerDiscount: (state, action: PayloadAction<boolean>) => {
      state.isOpenSaveUserModalWithActivateFeedTimerDiscount = action.payload;
    },
  },
});

export const {
  actions: activateFeedTimerDiscountActions,
  reducer: activateFeedTimerDiscountReducer,
} = activateFeedTimerDiscountSlice;
