import { createAsyncThunk, unwrapResult } from '@reduxjs/toolkit';
import { GetServerSidePropsContext } from 'next';
import Router from 'next/router';
import { ParsedUrlQuery } from 'querystring';

import { accountApi } from '~/api/account/accountApi';
import {
  ChangeMeRequestParams,
  Counters,
  EmailConfirmationParams, PasswordRequestParams, SetSocialPasswordParams, User,
} from '~/api/account/accountApiTypes';
import { userApi } from '~/api/account/userApi';
import {
  LoginResponse, ProviderEnum, WithToken,
} from '~/api/auth/authApiTypes';
import { pagesConfigStore } from '~/atomic/atom/links/pagesConfigStore';
import { logout } from '~/feature/authorization/auth.data';
import { getAuthTokenFromServer } from '~/feature/authorization/getAuthToken';
import { userActions, userSliceName } from '~/feature/user/user.slice';
import { environments } from '~/lib/const';
import { RootState } from '~/store';

export const getUser = createAsyncThunk<User, string, {
  rejectValue: { error: string }
}>(
  `${userSliceName}/getUser`,
  async (
    username: string,
    thunkAPI,
  ) => {
    try {
      const result = await userApi.getUser({ username });
      if (result && 'data' in result) {
        thunkAPI.dispatch(userActions.changeCurrentUser(result.data));
        return result.data;
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const emailConfirmation = createAsyncThunk<LoginResponse, EmailConfirmationParams, {
  rejectValue: { error: string };
}>(
  `${userSliceName}/confirm`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      thunkAPI.dispatch(userActions.setLoading(true));
      const result = (await accountApi.emailConfirmation(data) as any) as LoginResponse;

      thunkAPI.dispatch(setUser(result.data));

      if (result?.message) {
        const { message } = await import('~/atomic/atom/message');
        message.success(result.message);
      }
    } catch (error) {
      await Router.push(pagesConfigStore.home.url, undefined, { shallow: true });
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(userActions.setLoading(false));
    }
  },
);

export const deleteAvatar = createAsyncThunk<void, void, {
  rejectValue: { error: string };
}>(
  `${userSliceName}/deleteAvatar`,
  async (
    _: void,
    thunkAPI,
  ) => {
    try {
      const result = await accountApi.deleteAvatar();
      if (result?.message) {
        const { message } = await import('~/atomic/atom/message');
        message.success(result.message);
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const deleteMe = createAsyncThunk<any, void, {
  rejectValue: { error: string };
}>(
  `${userSliceName}/deleteMe`,
  async (
    _: void,
    thunkAPI,
  ) => {
    try {
      const result = await accountApi.deleteMe();
      if (result?.message) {
        const { message } = await import('~/atomic/atom/message');
        message.success(result.message);
      }
      thunkAPI.dispatch(logout());
    } catch (error) {
      thunkAPI.dispatch(logout());
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const disconnectProvider = createAsyncThunk<any, any, {
  rejectValue: { error: string };
}>(
  `${userSliceName}/disconnectProvider`,
  async (
    provider: ProviderEnum,
    thunkAPI,
  ) => {
    try {
      const result = await accountApi.deleteSocialAccount({ provider });
      if (result?.message) {
        const { message } = await import('~/atomic/atom/message');
        message.success(result.message);
      }
      if (result && 'data' in result) {
        thunkAPI.dispatch(setUser(result.data));
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const getProviderConnectURL = createAsyncThunk<any, any, {
  rejectValue: { error: string };
}>(
  `${userSliceName}/getProviderConnectURL`,
  async (
    provider: ProviderEnum,
    thunkAPI,
  ) => {
    try {
      const result = await accountApi.getSocialAccount({ provider });

      if (result && 'data' in result) {
        return result.data.redirectUrl;
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const toggleConnectProvider = createAsyncThunk<void, ProviderEnum, {
  rejectValue: { error: string }, state: RootState
}>(
  `${userSliceName}/toggleConnectProvider`,
  async (
    provider,
    thunkAPI,
  ) => {
    const { user } = thunkAPI.getState().user;
    const socialNetWorkAttached = user.attachedSocialAccounts?.some((it) => it === provider);

    if (!socialNetWorkAttached) {
      const url = unwrapResult(
        await thunkAPI.dispatch(getProviderConnectURL(provider)),
      );
      window.location.href = url;
    } else {
      await thunkAPI.dispatch(disconnectProvider(provider));
    }
  },
);

export const getCounters = createAsyncThunk<Counters, WithToken | void, {
  rejectValue: { error: string };
}>(
  `${userSliceName}/getCounters`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const result = await accountApi.getCounters(data);

      if (result && 'data' in result) {
        thunkAPI.dispatch(userActions.changeUserCounters(result.data));
        return result.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const repeatConfirmationEmailToken = createAsyncThunk<void, void, {
  rejectValue: { error: string };
}>(
  `${userSliceName}/repeatConfirmationEmailToken`,
  async (
    _,
    thunkAPI,
  ) => {
    try {
      const result = await accountApi.repeatConfirmationEmailToken();

      if (result?.message) {
        const { message } = await import('~/atomic/atom/message');
        message.success(result.message);
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const setUser = createAsyncThunk<void, User &(
WithToken | void), {
  rejectValue: { error: string };
}>(
  `${userSliceName}/setUser`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      thunkAPI.dispatch(userActions.changeUser(data));
      if (data?.id) {
        await thunkAPI.dispatch(getCounters({ token: data.token }));
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
  );

export const meOnServer = createAsyncThunk<any, GetServerSidePropsContext<ParsedUrlQuery>, {
  rejectValue: { error: string };
}>(
  `${userSliceName}/me`,
  async (
    context,
    thunkAPI,
  ) => {
    const token = getAuthTokenFromServer(context);

    try {
      thunkAPI.dispatch(userActions.setIsGetMeLoading(true));
      const result = await accountApi.me({ token });

      if (result && 'data' in result) {
        thunkAPI.dispatch(userActions.changeToken(token));
        await thunkAPI.dispatch(setUser({
          ...result.data,
          token,
        }));

        return result.data;
      }
    } catch (error) {
      thunkAPI.dispatch(logout());
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(userActions.setIsGetMeLoading(false));
    }
  },
);

export const me = createAsyncThunk<any, void, {
  rejectValue: { error: string };
}>(
  `${userSliceName}/me`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      thunkAPI.dispatch(userActions.setIsGetMeLoading(true));
      const result = await accountApi.me(data);
      if (result && 'data' in result) {
        await thunkAPI.dispatch(setUser(result.data));

        return result.data;
      }
    } catch (error) {
      thunkAPI.dispatch(logout());
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(userActions.setIsGetMeLoading(false));
    }
  },
);

export const sendProviderConnectCode = createAsyncThunk<User, any, {
  rejectValue: { error: string };
}>(
  `${userSliceName}/sendProviderConnectCode`,
  async (
    { provider, code }: { provider: ProviderEnum; code: string; },
    thunkAPI,
  ) => {
    try {
      thunkAPI.dispatch(userActions.setLoading(true));
      const result = await accountApi.updateSocialAccount({ code, provider });

      if (result?.message) {
        const { message } = await import('~/atomic/atom/message');
        message.success(result.message);
      }

      if (result && 'data' in result) {
        thunkAPI.dispatch(userActions.changeUser(result.data));
        return result.data;
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(userActions.setLoading(false));
    }
  },
);

export const updateMe = createAsyncThunk<any, ChangeMeRequestParams, {
  rejectValue: {
    message: string;
    errors: { [P in keyof ChangeMeRequestParams]?: string[]; }
  }
}>(
  `${userSliceName}/updateMe`,
  async (
    newUserData,
    thunkAPI,
  ) => {
    try {
      thunkAPI.dispatch(userActions.setUpdateMeLoading(true));
      const result = await accountApi.changeMe(newUserData);

      if (result?.message) {
        const { message } = await import('~/atomic/atom/message');
        message.success(result.message);
      }

      thunkAPI.dispatch(userActions.updateUser(newUserData));
      return result;
    } catch (error) {
      return thunkAPI.rejectWithValue({ message: error.message, errors: error?.errors ?? [] });
    } finally {
      thunkAPI.dispatch(userActions.setUpdateMeLoading(false));
    }
  },
);

export const updateMePassword = createAsyncThunk<any, PasswordRequestParams, {
  rejectValue: { error: string, errors?: PasswordRequestParams };
}>(
  `${userSliceName}/updateMePassword`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const result = await accountApi.changePassword(data);

      if (result?.message) {
        const { message } = await import('~/atomic/atom/message');
        message.success(result.message);
      }

      if (result && 'data' in result) {
        return result.data;
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }

      if ('errors' in error) {
        return thunkAPI.rejectWithValue({ error: error.message, errors: error.errors });
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const setSocialPassword = createAsyncThunk<void, SetSocialPasswordParams, {
  rejectValue: { error: string, errors?: SetSocialPasswordParams };
}>(
  `${userSliceName}/setSocialPassword`,
  async (
    { password },
    thunkAPI,
  ) => {
    try {
      const result = await accountApi.setSocialPassword({ password });

      if (result?.message) {
        const { message } = await import('~/atomic/atom/message');
        message.success(result.message);
      }

      thunkAPI.dispatch(userActions.setSocialPassword());
    } catch (error) {
      if ('errors' in error) {
        if (environments.isClient && error instanceof Error) {
          const { message } = await import('~/atomic/atom/message');
          message.error(error.message);
        }

        return thunkAPI.rejectWithValue({ error: error.message, errors: error.errors });
      }

      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);
