import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { TextRegStyle } from '~/atomic/Typography';

export interface DiscountGradeLineProps extends HTMLAttributes<HTMLDivElement> {
  grade: number;
}

export const DiscountGradeLine: FC<DiscountGradeLineProps> = ({
  grade,
  ...props
}) => {
  return (
    <SCDiscountGradeLine {...props}>
      <SCIcon>
        %
      </SCIcon>
      <SCText>
        Покупка книги добавляет
        {' '}
        +
        {grade}
        % к вашей накопительной скидке у этого автора
      </SCText>
    </SCDiscountGradeLine>
  );
};

const SCDiscountGradeLine = styled.div`
  padding: 15px;
  background: var(--warning-color);
  box-shadow: 0 1px 1px rgba(141, 147, 162, 0.25);
  border-radius: 4px;
  display: flex;
  justify-content: center;
`;

const SCText = styled.span`
  ${TextRegStyle};
  color: var(--black-color);
`;

const SCIcon = styled.div`
  width: 30px;
  height: 30px;
  background: #F2C94C;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 18px;
  margin-right: 16px;
  border: 2px solid var(--white-color);
`;
