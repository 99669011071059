import { WithToken } from '~/api/auth/authApiTypes';
import {
  ActivateTimerDiscountProps,
  CumulativeDiscountStatistics,
  GetDiscountProps,
  GetTimerDiscountSettings,
  SaveDiscountProps,
  TimerDiscount,
  TimerDiscountSettings,
} from '~/api/book/discountBooksApiTypes';
import { handleRejectedRequest } from '~/api/provider/providerErrors';
import { ApiResponse, RejectedRequest } from '~/api/provider/providerTypes';

import { provider } from '../provider/provider.config';

const namespace = Symbol('namespace');

export const discountsApi = {
  [namespace]: '/v1/discounts',

  async saveDiscount(data: SaveDiscountProps) {
    try {
      await provider.post<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/settings`,
        data,
      );
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async editDiscount(data: SaveDiscountProps) {
    try {
      await provider.put<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/settings`,
        data,
      );
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getDiscount() {
    try {
      const response = await provider.get<
      ApiResponse<GetDiscountProps, RejectedRequest<void>>
      >(
        `${this[namespace]}/settings`,
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getTimerDiscount(data: WithToken | void) {
    try {
      const response = await provider.get<
      ApiResponse<TimerDiscount, RejectedRequest<void>>
      >(
        `${this[namespace]}/timer`,
        {
          headers: {
            Authorization: data && data?.token
              ? `Bearer ${data?.token}`
              : provider.defaults.headers.common.Authorization,
          },
        },
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async activateTimerDiscount(data: ActivateTimerDiscountProps) {
    try {
      const response = await provider.post<
      ApiResponse<TimerDiscount, RejectedRequest<void>>
      >(
        `${this[namespace]}/timer`,
        data,
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getTimerDiscountSettings() {
    try {
      const response = await provider.get<
      ApiResponse<GetTimerDiscountSettings, RejectedRequest<void>>
      >(
        `${this[namespace]}/timer/settings`,
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async editTimerDiscountSettings(data: TimerDiscountSettings) {
    try {
      await provider.post<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/timer/settings`,
        data,
      );
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async activate() {
    try {
      const response = await provider.post<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/settings/activate`,
      );
      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async deactivate() {
    try {
      const response = await provider.post<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/settings/deactivate`,
      );
      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async enableTimerDiscount() {
    try {
      const response = await provider.post<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/timer/settings/enable`,
      );
      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async disableTimerDiscount() {
    try {
      const response = await provider.post<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/timer/settings/disable`,
      );
      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getStatistics() {
    try {
      const response = await provider.get<
      ApiResponse<CumulativeDiscountStatistics[], RejectedRequest<void>>
      >(
        `${this[namespace]}/statistics`,
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
};
