import { useRouter } from 'next/router';
import Script from 'next/script';
import React, { FC, useEffect } from 'react';

import { checkGenreForTracking } from '~/atomic/page/book-reader/getPixelForGenre';
import { bookSelector } from '~/feature/book/book.selector';
import { environments } from '~/lib/const';
import { useAppSelector } from '~/store';

const initDefaultVkPixel = () => {
  // @ts-ignore
  window.VK.Retargeting.Init('VK-RTRG-545454-1m8Mj');
  // @ts-ignore
  window.VK.Retargeting.Hit();
};

export const VkPixel: FC = () => {
  const router = useRouter();
  const { currentBookProperties, bookPage } = useAppSelector(bookSelector);
  const isReaderPage = router.pathname.startsWith('/reader');
  const isHaveVk = environments.isClient && 'VK' in window;
  const genres = 'genres' in currentBookProperties ? currentBookProperties.genres : (bookPage?.genres ?? []);

  useEffect(() => {
    if (isHaveVk && isReaderPage && currentBookProperties.bookId) {
      const timeoutId = setTimeout(() => {
        genres?.forEach((genre) => {
          const pixelCode = checkGenreForTracking(genre?.slug);

          if (pixelCode) {
            // @ts-ignore
            window.VK.Retargeting.Init(pixelCode);
            // @ts-ignore
            window.VK.Retargeting.Hit();
          }
        });
      }, 15 * 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
  }, [
    currentBookProperties.bookId,
    environments.isProduction,
    environments.isClient,
    router.asPath,
    isHaveVk,
  ]);

  useEffect(() => {
    if (isHaveVk && environments.isClient) {
      initDefaultVkPixel();
    }
  }, [
    environments.isProduction,
    environments.isClient,
    router.asPath,
    isHaveVk,
  ]);

  if (!environments.isProduction) return null;

  return (
    <Script
      strategy="lazyOnload"
      async
      defer
      dangerouslySetInnerHTML={{
        __html: ''
              + '!function(){var t=document.createElement("script");t.type="text/javascript",'
              + 't.async=!0,t.defer=!0,'
              + 't.src="https://vk.com/js/api/openapi.js?168",t.onload=function(){'
              + '},'
              + 'document.body.appendChild(t)}();',
      }}
    />
  );
};
