import {
  configureStore, createAsyncThunk, Store,
} from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { reducer } from '~/reducer';

let reduxStore;

export function getStore(): Store<RootState> {
  return reduxStore;
}

export const createStore = async () => {
  const store = configureStore({
    reducer,
    devTools: process.env.NODE_ENV !== 'production',
  });

  reduxStore = store;

  const { dispatch } = store;

  return { store, dispatch };
};

export const initStore = (state?: any) => {
  const store = configureStore({
    reducer,
    devTools: process.env.NODE_ENV !== 'production',
    preloadedState: state ?? undefined,
  });

  reduxStore = store;

  return store;
};

type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;

type CreateStoreType = ThenArg<ReturnType<typeof createStore>>;

export type RootState = ReturnType<CreateStoreType['store']['getState']>;
export type AppDispatch = CreateStoreType['dispatch'];

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector = <T>(
  selector: (state: RootState) => T,
  equalityFn?: (left: T, right: T) => boolean,
) => useSelector<RootState, T>(selector, equalityFn);

export const createAppThunk = createAsyncThunk.withTypes<{
  state: RootState
  dispatch: AppDispatch
}>();

export const updateState = (state: RootState, dispatch) => {
  dispatch({
    type: 'POPULATE',
    payload: state,
  });
};
