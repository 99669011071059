import { createAsyncThunk } from '@reduxjs/toolkit';

import { bookApi } from '~/api/book/bookApi';
import {
  AddPropertiesParams,
  AddPropertiesRejectValue, Book,
  BookIdType, BookLog, BookProperties,
  ChangeNameParams,
  ChangePriceParams,
  ChangePriceRejectValue, CompleteStatusEnum, CreateBookParams,
  CreateBookResult,
  GetBookLogsParams,
  GetMyBooksParams,
  GetNextBookInCycleParams,
  GetSimilarBooksParams, PublishStatusEnum,
} from '~/api/book/bookApiTypes';
import { HttpNotFoundError, HttpValidationError } from '~/api/provider/providerErrors';
import { Paginated, RejectedRequest } from '~/api/provider/providerTypes';
import { readerActions } from '~/atomic/page/book-reader/reader.slice';
import { getAuthTokenFromClient } from '~/feature/authorization/getAuthToken';
import { bookActions } from '~/feature/book/book.slice';
import { refreshBookCash } from '~/feature/refreshCash/refreshBookCash';
import { getCounters } from '~/feature/user/user.data';
import { GetGraphqlBookPageParams, GetGraphqlBookParams } from '~/graphql/books/booksGraphql';
import { GraphqlBook } from '~/graphql/books/factory/book/BookFactoryTypes';
import { GetBookPageResult } from '~/graphql/books/factory/book/GetBookPageFactoryTypes';
import { environments } from '~/lib/const';
import { RootState } from '~/store';

export const changeCompleteStatusBook = createAsyncThunk<
any,
{ bookId: BookIdType, newStatus: CompleteStatusEnum; bookSlug: string },
{ rejectValue: { error: string } }>(
  'book/changeCompleteStatusBook',
  async (
    {
      bookId, newStatus, bookSlug,
    },
    thunkAPI,
  ) => {
    try {
      const result = await bookApi.changeCompletenessStatus(
        { id: bookId, statusComplete: newStatus },
      );
      refreshBookCash(bookSlug);

      thunkAPI.dispatch(bookActions.changeCurrentBookCompleteStatus(newStatus));

      if (result?.message) {
        const { message } = await import('~/atomic/atom/message');
        message.success(result.message);
      }

      if (result && 'data' in result) {
        return result.data;
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const changePublishStatusBook = createAsyncThunk<
void,
{ bookId: BookIdType, newStatus: PublishStatusEnum, bookSlug: string, },
{ rejectValue: { error: string } }>(
  'book/changePublishStatusBook',
  async (
    {
      bookId, newStatus, bookSlug,
    },
    thunkAPI,
  ) => {
    try {
      thunkAPI.dispatch(bookActions.setIsChangePublishStatusLoading(true));
      const result = await bookApi.changePublishStatus({ id: bookId, statusPublish: newStatus });
      refreshBookCash(bookSlug);
      if (result?.message) {
        const { message } = await import('~/atomic/atom/message');
        message.success(result.message);
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(bookActions.setIsChangePublishStatusLoading(false));
    }
  },
);

export const createBook = createAsyncThunk<CreateBookResult | Book, CreateBookParams, {
  rejectValue: {
    message: string, errors?: RejectedRequest<CreateBookParams>, book?: Partial<Book>
  };
}>(
  'book/createBook',
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const result = await bookApi.create(data);

      await thunkAPI.dispatch(getCounters());

      if (result?.message) {
        const { message } = await import('~/atomic/atom/message');
        message.success(result.message);
      }

      if (result && 'data' in result) {
        return result.data;
      }
    } catch (error) {
      if (error instanceof HttpValidationError) {
        return thunkAPI.rejectWithValue({ message: error.message });
      }

      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ message: error.message });
    }
  },
);

export const deleteBook = createAsyncThunk<any, { bookId: BookIdType; bookSlug: string }, {
  rejectValue: { error: string };
}>(
  'book/deleteBook',
  async (
    { bookId, bookSlug },
    thunkAPI,
  ) => {
    try {
      const result = await bookApi.deleteBook({ id: bookId });
      refreshBookCash(bookSlug);
      await thunkAPI.dispatch(getCounters());
      if (result?.message) {
        const { message } = await import('~/atomic/atom/message');
        message.success(result.message);
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const editPriceBook = createAsyncThunk<any, ChangePriceParams, {
  rejectValue: { message?: string; errors?: ChangePriceRejectValue }, state: RootState;
}>(
  'book/editPriceBook',
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const { currentBook } = thunkAPI.getState().book;
      await bookApi.changePrice(data);
      refreshBookCash(currentBook.slug);
      thunkAPI.dispatch(bookActions.changeCurrentBookPrice(data.price));
      thunkAPI.dispatch(bookActions.changeCurrentBookFree(data.free === 'true'));
      thunkAPI.dispatch(bookActions.changeCurrentBookFreeChapters(data.countFreeChapters));
      thunkAPI.dispatch(bookActions.changeCurrentBookExclusive(data.exclusive));
      await thunkAPI.dispatch(getBook(currentBook.id));

      if (environments.isClient) {
        const { message } = await import('~/atomic/atom/message');
        message.success('Стоимость успешно изменена!');
      }
    } catch (error) {
      if (error?.errors) {
        return thunkAPI.rejectWithValue({ errors: error.errors });
      }
      if (environments.isClient && error?.message) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ message: error.message });
    }
  },
);

export const getBook = createAsyncThunk<Book, BookIdType, {
  rejectValue: { error: string };
}>(
  'book/getBook',
  async (
    bookId,
    thunkAPI,
  ) => {
    try {
      thunkAPI.dispatch(bookActions.setIsGetCurrentBookLoading(true));
      const result = await bookApi.getBook({ id: bookId });
      if (result?.message) {
        const { message } = await import('~/atomic/atom/message');
        message.success(result.message);
      }
      if (result && 'data' in result) {
        thunkAPI.dispatch(bookActions.changeCurrentBook(result.data));
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(bookActions.setIsGetCurrentBookLoading(false));
    }
  },
);

export const getGraphqlBook = createAsyncThunk<GraphqlBook, GetGraphqlBookParams, {
  rejectValue: { error: string } | HttpNotFoundError;
}>(
  'book/getBookBySlug',
  async (
    {
      slug = null, id = null, token,
    },
    thunkAPI,
  ) => {
    try {
      thunkAPI.dispatch(bookActions.setIsGetCurrentBookLoading(true));
      const { booksGraphql } = await import('~/graphql/books/booksGraphql');
      const result = await booksGraphql.getBookBySlug({
        slug, id, token: token ?? getAuthTokenFromClient(),
      });

      thunkAPI.dispatch(bookActions.changeCurrentGraphqlBook(result));

      return result;
    } catch (error) {
      if (error instanceof HttpNotFoundError) {
        return thunkAPI.rejectWithValue(new HttpNotFoundError(error.message));
      }
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(bookActions.setIsGetCurrentBookLoading(false));
    }
  },
);

export const getMyBooks = createAsyncThunk<Paginated<Book[]>, GetMyBooksParams, {
  rejectValue: { error: string };
}>(
  'book/getMyBooks',
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const result = await bookApi.getMyBooks(data);

      if (result && 'data' in result) {
        return result;
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const getBookLog = createAsyncThunk<
Paginated<BookLog[]>,
GetBookLogsParams, {
  rejectValue: { error: string };
}>(
  'book/getBookLog',
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const result = await bookApi.getLogs(data);

      if (result && 'data' in result) {
        const logs = result.data;

        logs.sort((a, b) => {
          return Number(new Date(b.createdAt)) - Number(new Date(a.createdAt));
        });

        result.data = logs;

        thunkAPI.dispatch(bookActions.changeCurrentBookLog(result));
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const getBookProperties = createAsyncThunk<BookProperties, string | number, {
  rejectValue: { error: string };
}>(
  'book/getBookProperties',
  async (
    id,
    thunkAPI,
  ) => {
    try {
      thunkAPI.dispatch(bookActions.setIsGetBookPropertiesLoading(true));
      const result = await bookApi.getProperties({ id });

      if (result?.message) {
        const { message } = await import('~/atomic/atom/message');
        message.success(result.message);
      }

      if (result && 'data' in result) {
        thunkAPI.dispatch(bookActions.changeCurrentBookProperties(result.data));
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    } finally {
      thunkAPI.dispatch(bookActions.setIsGetBookPropertiesLoading(false));
    }
  },
);

export const removeBookCover = createAsyncThunk<any, { bookId: BookIdType; bookSlug: string; }, {
  rejectValue: { error: string };
}>(
  'book/removeBookCover',
  async (
    { bookId, bookSlug },
    thunkAPI,
  ) => {
    try {
      const result = await bookApi.deleteCover({ id: bookId });
      refreshBookCash(bookSlug);
      if (result?.message) {
        const { message } = await import('~/atomic/atom/message');
        message.success(result.message);
      }

      if (result && 'data' in result) {
        return result.data;
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const renameBook = createAsyncThunk<void, ChangeNameParams, {
  rejectValue: { error: string };
}>(
  'book/renameBook',
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const result = await bookApi.changeName(data);
      refreshBookCash(data.slug);
      if (result?.message) {
        const { message } = await import('~/atomic/atom/message');
        message.success(result.message);
      }

      if (result && 'data' in result) {
        thunkAPI.dispatch(bookActions.changeCurrentBookName(data.name));
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const updateBookProperties = createAsyncThunk<void,
AddPropertiesParams & { bookSlug: string }, {
  rejectValue: { message?: string; errors?: AddPropertiesRejectValue }
}>(
  'book/updateBookProperties',
  async (
    { bookSlug, ...data },
    thunkAPI,
  ) => {
    try {
      const result = await bookApi.addProperties(data);
      refreshBookCash(bookSlug);
      await thunkAPI.dispatch(getBookProperties(data.id));
      if (result?.message) {
        const { message } = await import('~/atomic/atom/message');
        message.success(result.message);
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      if (error instanceof HttpValidationError) {
        return thunkAPI.rejectWithValue({ errors: error.getErrors() });
      }

      return thunkAPI.rejectWithValue({ message: error.message });
    }
  },
);

export const getBookPageData = createAsyncThunk<
GetBookPageResult,
GetGraphqlBookPageParams,
{
  rejectValue: {
    redirect?: 'not found';
    message?: string;
  };
  state: RootState
}>(
  'book/getBookPageData',
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const { booksGraphql } = await import('~/graphql/books/booksGraphql');

      const result = await booksGraphql.getBookPage({
        ...data,
        token: data?.token ?? getAuthTokenFromClient(),
      });

      thunkAPI.dispatch(bookActions.changeBookPage(result.book));
      thunkAPI.dispatch(bookActions.changeBookPageCycleBooks(result.cycleBooks));
      thunkAPI.dispatch(bookActions.changeOtherBooksByAuthor(result.otherBooksByAuthor));

      return result;
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      if (error instanceof HttpNotFoundError) {
        return thunkAPI.rejectWithValue({ redirect: 'not found' });
      }

      return thunkAPI.rejectWithValue({ message: error.message });
    }
  },
);

export const getNextBookInCycle = createAsyncThunk<
void,
GetNextBookInCycleParams
>(
  'book/getNextBookInCycle',
  async (data, thunkAPI) => {
    try {
      const nextBookResult = await bookApi.getNextBook(data);

      if ('data' in nextBookResult) {
        thunkAPI.dispatch(readerActions.changeNextBookInCycle(nextBookResult.data));
      }
    } catch (e) {
      console.error(e);
      return thunkAPI.rejectWithValue({ error: e.message });
    }
  },
);

export const getSimilarBooks = createAsyncThunk<
Book[],
GetSimilarBooksParams,
{ rejectValue: { error: string }; state: RootState }
>(
  'book/getSimilarBooks',
  async (data, thunkAPI) => {
    const { book: { isGetSimilarBooksLoading } } = thunkAPI.getState();

    if (!isGetSimilarBooksLoading) {
      try {
        thunkAPI.dispatch(bookActions.setIsGetSimilarBooksLoading(true));

        const response = await bookApi.getSimilarBooks(data);

        if (response && 'data' in response) {
          thunkAPI.dispatch(bookActions.changeBookList(response.data));
        }
      } catch (e) {
        console.error(e);
        return thunkAPI.rejectWithValue({ error: e.message });
      } finally {
        thunkAPI.dispatch(bookActions.setIsGetSimilarBooksLoading(false));
      }
    }
  },
);
