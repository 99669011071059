import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { TransactionResult } from '~/api/finance/financeApiTypes';

export const paymentResultSliceName = 'paymentResult';

export type ResultModalsType = 'buyBookSuccess' | 'buyBookReject'
| 'buyAudioBookSuccess' | 'buyAudioBookReject'
| 'rewardAuthorReject' | 'rewardAuthorSuccess'
| 'assetBalanceReject' | 'assetBalanceSuccess'
| 'buyBookFreeSuccess'
| 'payCardReject' | 'payCardSuccess'
| 'adAccountReplenishmentReject' | 'adAccountReplenishmentSuccess'
| 'buyAbonnementSuccess' | 'buyAbonnementReject'
| 'rewardInCommentSuccess' | 'rewardInCommentReject';

type ResultModals = {
  [key in ResultModalsType]: boolean | undefined;
};

export const paymentResultSlice = createSlice({
  name: paymentResultSliceName,
  initialState: {
    modalOpen: {} as ResultModals,
    errorMessage: '',
    isOpenTransactionLoader: false,
    transactionResult: {} as TransactionResult,
  },
  reducers: {
    openModal: (state, action: PayloadAction<ResultModalsType>) => {
      state.modalOpen[action.payload] = true;
    },
    closeModal: (state, action: PayloadAction<ResultModalsType>) => {
      delete state.modalOpen[action.payload];
    },
    changeErrorMessage: (state, action: PayloadAction<string>) => {
      state.errorMessage = action.payload;
    },
    setIsOpenTransactionLoader: (state, action: PayloadAction<boolean>) => {
      state.isOpenTransactionLoader = action.payload;
    },
    changeTransactionResult: (state, action: PayloadAction<TransactionResult>) => {
      state.transactionResult = action.payload;
    },
  },
});

export const { actions: paymentResultActions, reducer: paymentResultReducer } = paymentResultSlice;
