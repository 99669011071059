import { Book } from '~/api/book/bookApiTypes';
import { provider } from '~/api/provider/provider.config';
import { handleRejectedRequest } from '~/api/provider/providerErrors';
import { Paginated, RejectedRequest } from '~/api/provider/providerTypes';
import {
  AuthorWithStatistic,
  FindAuthorsParams,
  FindBooksParams,
  FindUsersAndBooksParams,
  FindUsersAndBooksResponse,
} from '~/api/search/searchApiTypes';

const namespace = Symbol('namespace');

export const searchApi = {
  [namespace]: '/v1/search',

  async findUsersAndBooks(data: FindUsersAndBooksParams) {
    try {
      const response = await provider.get<
      FindUsersAndBooksResponse | RejectedRequest<void>
      >(
        `${this[namespace]}/autocomplete`,
        { params: data },
      );

      // eslint-disable-next-line no-unsafe-optional-chaining
      if ('data' in response?.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async findBooks(data: FindBooksParams) {
    try {
      const response = await provider.get<
      Paginated<Book[]> | RejectedRequest<void>
      >(
        `${this[namespace]}/books`,
        {
          params: data,
          headers: {
            Authorization: data && data?.token
              ? `Bearer ${data?.token}`
              : provider.defaults.headers.common.Authorization,
          },
        },
      );

      if (response?.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },

  async findAuthors(data: FindAuthorsParams) {
    try {
      const response = await provider.get<
      Paginated<AuthorWithStatistic[]> | RejectedRequest<void>
      >(
        `${this[namespace]}/authors`,
        { params: data },
      );

      if (response?.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
};
