import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export const bookModerationSliceName = 'bookModeration';

export const bookModerationSlice = createSlice({
  name: bookModerationSliceName,
  initialState: {
    isOpenModerationBookModal: false,
    isSavePropertiesLoading: false,
  },
  reducers: {
    setIsOpenModerationBookModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenModerationBookModal = action.payload;
    },
    setIsSavePropertiesLoading: (state, action: PayloadAction<boolean>) => {
      state.isSavePropertiesLoading = action.payload;
    },
  },
});

export const {
  actions: bookModerationActions,
  reducer: bookModerationReducer,
} = bookModerationSlice;
