import {
  ChangeMeRequestParams,
  Counters,
  DeleteSocialAccountParams,
  EmailConfirmationParams,
  EmailRequestParams,
  GetSocialAccountParams,
  GetSocialAccountRequest,
  PasswordRequestParams,
  SetSocialPasswordParams,
  UpdateSocialAccountParams,
  User,
} from '~/api/account/accountApiTypes';
import { WithToken } from '~/api/auth/authApiTypes';
import { Cycle } from '~/api/book/cycleApiTypes';
import { handleRejectedRequest } from '~/api/provider/providerErrors';
import { ApiResponse, RejectedRequest } from '~/api/provider/providerTypes';

import { baseURL, provider } from '../provider/provider.config';

const namespace = Symbol('namespace');

export const accountApi = {
  [namespace]: '/v1/users/mereserve',
  async setSocialPassword(data: SetSocialPasswordParams) {
    try {
      const response = await provider.put<
      ApiResponse<void, RejectedRequest<SetSocialPasswordParams>>
      >(`${this[namespace]}/setSocialPassword`, data);

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<SetSocialPasswordParams>(error);
      return handleError;
    }
  },
  async changePassword(data: PasswordRequestParams) {
    try {
      const response = await provider.put<
      ApiResponse<void, RejectedRequest<PasswordRequestParams>>
      >(`${this[namespace]}/password`, data);

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<PasswordRequestParams>(error);
      return handleError;
    }
  },
  async changeEmail(data: EmailRequestParams) {
    try {
      const response = await provider.put<
      ApiResponse<void, RejectedRequest<EmailRequestParams>>
      >(`${this[namespace]}/email`, data);

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async emailConfirmation(data: EmailConfirmationParams) {
    try {
      const response = await provider.post<
      ApiResponse<void, RejectedRequest<EmailConfirmationParams>>
      >(`${this[namespace]}/email/confirmation`, data);

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(
        error,
      );
      return handleError;
    }
  },
  async me(data: WithToken | void) {
    try {
      const response = await provider.get<
      ApiResponse<User, RejectedRequest<void>>
      >(
        `${this[namespace]}`,
        {
          headers: {
            Authorization: data && data?.token
              ? `Bearer ${data?.token}`
              : provider.defaults.headers.common.Authorization,
          },
        },
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async changeMe(data: ChangeMeRequestParams) {
    try {
      const response = await provider.put<
      ApiResponse<User, RejectedRequest<ChangeMeRequestParams>>
      >(`${this[namespace]}`, data);
      return response.data;
    } catch (error) {
      const handleError = handleRejectedRequest<ChangeMeRequestParams>(error);
      return handleError;
    }
  },
  async deleteMe() {
    try {
      const response = await provider.delete<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}`,
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  setAvatarUrl() { return `${baseURL}${this[namespace]}/avatar`; },
  async deleteAvatar() {
    try {
      const response = await provider.delete<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/avatar`,
      );

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getSocialAccount(data: GetSocialAccountParams) {
    try {
      const response = await provider.get<
      ApiResponse<GetSocialAccountRequest, RejectedRequest<void>>
      >(
        `${this[namespace]}/socialAccounts/${data.provider}`,
      );

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async deleteSocialAccount(data: DeleteSocialAccountParams) {
    try {
      const response = await provider.delete<
      ApiResponse<User, RejectedRequest<void>>
      >(
        `${this[namespace]}/socialAccounts/${data.provider}`,
      );
      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async updateSocialAccount(data: UpdateSocialAccountParams) {
    try {
      const response = await provider.post<
      ApiResponse<User, RejectedRequest<void>>
      >(`${this[namespace]}/socialAccounts/${data.provider}/callback`, { code: data.code });

      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getCycles() {
    try {
      const response = await provider.get<
      ApiResponse<Cycle[], RejectedRequest<void>>
      >(
        'v1/books/cycles/me',
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getCyclesWithBooks() {
    try {
      const response = await provider.get<
      ApiResponse<Cycle[], RejectedRequest<void>>
      >(
        'v1/books/cycles/me',
        { params: { withBooks: true } },
      );

      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async getCounters(data: WithToken | void) {
    try {
      const response = await provider.get<
      ApiResponse<Counters, RejectedRequest<void>>
      >(
        `${this[namespace]}/counters`,
        {
          headers: {
            Authorization: data && data?.token
              ? `Bearer ${data?.token}`
              : provider.defaults.headers.common.Authorization,
          },
        },
      );
      if ('data' in response.data) {
        return response.data;
      }
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  async repeatConfirmationEmailToken() {
    try {
      const response = await provider.get<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/repeatConfirmation`,
      );
      return response.data;
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
  setHeroImage() { return `${baseURL}${this[namespace]}/heroImage`; },
  async deleteHeroImage() {
    try {
      await provider.delete<
      ApiResponse<void, RejectedRequest<void>>
      >(
        `${this[namespace]}/heroImage`,
      );
    } catch (error) {
      const handleError = await handleRejectedRequest<void>(error);
      return handleError;
    }
  },
};
