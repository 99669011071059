import React from 'react';

import { Button, ButtonProps } from '~/atomic/atom/Button';

interface SubscriptionButtonProps extends ButtonProps {
  isSubscription: boolean;
  onClickHandler: () => void;
  isLoading?: boolean;
  subscribeText?: string;
  unSubscribeText?: string;
}

export const SubscriptionButton: React.FC<SubscriptionButtonProps> = ({
  isSubscription, isLoading, onClickHandler, subscribeText, unSubscribeText, ...props
}) => {
  return (
    <Button
      isLoading={isLoading}
      type={isSubscription ? 'default' : 'primary'}
      onClick={onClickHandler}
      block
      {...props}
    >
      {isSubscription ? unSubscribeText ?? 'Отписаться' : subscribeText ?? 'Подписаться'}
    </Button>
  );
};
