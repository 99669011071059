import * as Yup from 'yup';

export const requiredMessage = 'Поле обязательно для ввода';

Yup.setLocale({ mixed: { required: requiredMessage } });

export const validatePasswordSchema = Yup
  .string()
  .required()
  .min(8, 'Минимальная длина пароля должна составлять 8 символом')
  .matches(
    /(^.*(?=..*[0-9]).*$)/,
    'Пароль должна иметь хотя бы одну цифру',
  )
  .matches(
    /(^.*(?=.*[A-Z]).*$)/,
    'Пароль должна иметь хотя бы одну заглавную букву',
  );

export function validateInn(inn: string) {
  let result = false;

  if (!inn) return false;

  const checkDigit = function (innValue: string, coefficients) {
    let n = 0;

    coefficients.forEach((k, index) => {
      n += coefficients[index] * Number(innValue[index]);
    });

    return Number(n % 11 % 10);
  };

  switch (inn.length) {
    case 10:
      if (checkDigit(inn, [2, 4, 10, 3, 5, 9, 4, 6, 8]) === Number(inn[9])) {
        result = true;
      }
      break;
    case 12:
      if (
        (checkDigit(inn, [7, 2, 4, 10, 3, 5, 9, 4, 6, 8]) === Number(inn[10]))
        && (checkDigit(inn, [3, 7, 2, 4, 10, 3, 5, 9, 4, 6, 8]) === Number(inn[11]))) {
        result = true;
      }
      break;

    default:
      result = false;
  }

  return result;
}
