import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { WithdrawalMethodTypeEnum } from '~/api/finance/financeApiTypes';

export const connectPayoutMethodSliceName = 'connectPayoutMethod';

export const connectPayoutMethodSlice = createSlice({
  name: connectPayoutMethodSliceName,
  initialState: {
    isOpenConnectBillPayoutModal: false,
    isConnectBillPayoutMethodLoading: false,
    billNumber: '',
    selectedPayoutMethod: WithdrawalMethodTypeEnum.Card,
    connectCardPayoutStatus: '' as 'success' | 'fail' | '',
    connectCardPayoutErrorMessage: '',
  },
  reducers: {
    setIsOpenConnectBillPayoutModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenConnectBillPayoutModal = action.payload;
    },
    setIsConnectBillPayoutMethodLoading: (state, action: PayloadAction<boolean>) => {
      state.isConnectBillPayoutMethodLoading = action.payload;
    },
    changeBillNumber: (state, action:PayloadAction<string>) => {
      state.billNumber = action.payload;
    },
    changeSelectedPayoutMethod: (state, action: PayloadAction<WithdrawalMethodTypeEnum>) => {
      state.selectedPayoutMethod = action.payload;
    },
    changeConnectCardPayoutStatus: (state, action: PayloadAction<'success' | 'fail' | ''>) => {
      state.connectCardPayoutStatus = action.payload;
    },
    changeConnectCardPayoutErrorMessage: (state, action: PayloadAction<string>) => {
      state.connectCardPayoutErrorMessage = action.payload;
    },
  },
});

export const {
  actions: connectPayoutMethodActions,
  reducer: connectPayoutMethodReducer,
} = connectPayoutMethodSlice;
