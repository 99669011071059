import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Book } from '~/api/book/bookApiTypes';
import { Operation, OperationEnum } from '~/api/finance/operationApiTypes';
import { Paginated } from '~/api/provider/providerTypes';
import { financePageSliceName } from '~/atomic/page/finance/financePage.slice';

export const operationsTableSliceName = `${financePageSliceName}/operationTable`;

export const operationTableSlice = createSlice({
  name: operationsTableSliceName,
  initialState: {
    operations: [] as Operation[],
    isGetOperationsLoading: false,
    isGetOperationBooksLoading: false,
    currentOperationAct: null as OperationEnum,
    operationBooks: [] as Book[],
  },
  reducers: {
    setOperations: (state, action: PayloadAction<Paginated<Operation[]>>) => {
      state.operations = action.payload.data;
    },
    setIsGetOperationsLoading: (state, action: PayloadAction<boolean>) => {
      state.isGetOperationsLoading = action.payload;
    },
    setIsGetOperationBooksLoading: (state, action: PayloadAction<boolean>) => {
      state.isGetOperationBooksLoading = action.payload;
    },
    changeCurrentOperationAct: (state, action: PayloadAction<OperationEnum>) => {
      state.currentOperationAct = action.payload;
    },
    setOperationBooks: (state, action: PayloadAction<Book[]>) => {
      state.operationBooks = action.payload;
    },

  },
});

export const {
  actions: operationTableActions,
  reducer: operationTableReducer,
} = operationTableSlice;
