import { createAsyncThunk } from '@reduxjs/toolkit';
import { GetServerSidePropsContext } from 'next';
import Router from 'next/router';

import {
  BookSortingEnum, BookTypeEnum, CompleteStatusEnum,
} from '~/api/book/bookApiTypes';
import {
  createUrlWithFilter,
  DOWNLOAD_BOOK_FILTER,
  FREE_BOOK_FILTER, WITH_ABONNEMENT_FILTER, WITH_AUDIOBOOK_FILTER,
} from '~/atomic/atom/links/createUrlWithFilter';
import {
  catalogFilterGenresSelector,
  catalogFilterSelector,
} from '~/feature/catalog/filter/catalogFilter.selector';
import {
  catalogFilterActions,
  catalogFilterSliceName,
} from '~/feature/catalog/filter/catalogFilter.slice';
import {
  genres, getGenreBySlug, getGenreNameBySlug,
} from '~/feature/genre/genres';
import { paginationActions } from '~/feature/pagination/pagination.slice';
import { environments } from '~/lib/const';
import { RootState } from '~/store';

export const getFilterFromUrl = createAsyncThunk<
void,
GetServerSidePropsContext | void,
{ rejectValue: { error: string }; state: RootState }
>(
  `${catalogFilterSliceName}/getFilterFromUrl`,
  async (
    ctx,
    thunkAPI,
  ) => {
    const { isAddGenreToParams } = catalogFilterSelector(thunkAPI.getState());

    let query;
    let keywords;

    if (environments.isClient || !ctx) {
      query = Router?.query;
      keywords = query.keywords;
    } else {
      query = (ctx && ctx?.query) ?? undefined;
      keywords = ctx.params?.keywords ?? [];
    }

    const isFree = keywords.includes(FREE_BOOK_FILTER);
    const isDownload = keywords.includes(DOWNLOAD_BOOK_FILTER);
    const isWithAudiobook = keywords.includes(WITH_AUDIOBOOK_FILTER);
    const isWithAbonnement = keywords.includes(WITH_ABONNEMENT_FILTER);
    let tag;
    let isOnlyFree;
    let isCanDownload;
    let withAudiobook;
    let withAbonnement;
    let subGenresSlugsFromUrl = [] as Array<string>;
    let mainGenreSlug = null;

    const completeStatus: CompleteStatusEnum | undefined = query?.completeStatus ?? null;
    const sortingType: BookSortingEnum = query?.sortingType ?? BookSortingEnum.Bestseller;
    const isGenreFirstParam = isAddGenreToParams ? Boolean(getGenreNameBySlug({ slug: keywords[0] })) : false;
    const bookType: BookTypeEnum | undefined = query?.bookType ?? null;

    if (query?.g) subGenresSlugsFromUrl = (query?.g || [])?.split(',');
    if (isGenreFirstParam) [mainGenreSlug] = keywords;
    if (isFree) isOnlyFree = true;
    if (isDownload) isCanDownload = true;
    if (isWithAudiobook) withAudiobook = true;
    if (isWithAbonnement) withAbonnement = true;

    const conditions = [isGenreFirstParam, isFree, isDownload, isWithAudiobook, withAbonnement];
    let count = conditions.reduce((acc, curr) => (curr ? acc + 1 : acc), 0);

    if (isGenreFirstParam && isAddGenreToParams === false) count -= 1;

    if (keywords.length > 0 && keywords.length <= 5 && count === keywords.length - 1) {
      if (isGenreFirstParam) {
        [, tag] = keywords;
      } else {
        [tag] = keywords;
      }
    }

    if (tag) {
      thunkAPI.dispatch(catalogFilterActions.setTagSlug(tag ?? null));
    }
    if (isOnlyFree) {
      thunkAPI.dispatch(catalogFilterActions.setIsOnlyFree(isOnlyFree));
    }
    if (isCanDownload) {
      thunkAPI.dispatch(catalogFilterActions.setIsCanDownload(isCanDownload));
    }
    if (withAudiobook) {
      thunkAPI.dispatch(catalogFilterActions.setWithAudiobook(withAudiobook));
    }
    if (withAbonnement) {
      thunkAPI.dispatch(catalogFilterActions.setIsWithAbonnement(withAbonnement));
    }

    thunkAPI.dispatch(catalogFilterActions.setMainGenreSlug(mainGenreSlug));
    thunkAPI.dispatch(catalogFilterActions.setSubGenresSlugs(subGenresSlugsFromUrl));
    thunkAPI.dispatch(catalogFilterActions.setCompleteStatus(completeStatus));
    thunkAPI.dispatch(catalogFilterActions.setBookType(bookType));
    thunkAPI.dispatch(catalogFilterActions.setBookSorting(sortingType));

    const isCanDownloadUrl = createCatalogFilterUrl(thunkAPI.getState(), { isCanDownload: !isCanDownload });
    thunkAPI.dispatch(catalogFilterActions.changeIsCanDownloadUrl(isCanDownloadUrl));

    const isOnlyFreeUrl = createCatalogFilterUrl(thunkAPI.getState(), { isOnlyFree: !isOnlyFree });
    thunkAPI.dispatch(catalogFilterActions.changeIsOnlyFreeUrl(isOnlyFreeUrl));
  },
);

export const createCatalogFilterUrl = (
  state: RootState,
  data: { tagSlug?: string, isCanDownload?: boolean, isOnlyFree?: boolean, withAbonnement?: boolean } | void = {},
) => {
  const {
    completeStatus,
    isOnlyFree: isOnlyFreeFromStorage,
    bookType,
    pageUrlStartWith,
    sortingType,
    withAudiobook,
    isCanDownload: isCanDownloadFromStorage,
    tagSlug: tagSlugFromStorage,
    isWithAbonnement: withAbonnementFromStorage,
    isAddGenreToParams,
  } = catalogFilterSelector(state);

  const selectedGenreSlugs = catalogFilterGenresSelector(state);
  let genresSlugs = [];

  if (selectedGenreSlugs.length) {
    genresSlugs = genres.filter((genre) => {
      return selectedGenreSlugs.includes(genre.slug);
    }).map((selectedGenre) => selectedGenre.slug);
  }

  const tagSlug = data && 'tagSlug' in data ? data.tagSlug : tagSlugFromStorage;
  const isOnlyFree = data && 'isOnlyFree' in data ? data.isOnlyFree : isOnlyFreeFromStorage;
  const isCanDownload = data && 'isCanDownload' in data ? data.isCanDownload : isCanDownloadFromStorage;
  const isWithAbonnement = data && 'withAbonnement' in data ? data.withAbonnement : withAbonnementFromStorage;

  return createUrlWithFilter({
    startWith: pageUrlStartWith,
    bookType,
    completeStatus,
    genresSlugs,
    isOnlyFree,
    sortingType,
    withAudiobook,
    isWithAbonnement,
    isCanDownload,
    tagSlug,
    isAddGenreToParams,
  });
};

export const setFilterToUrl = createAsyncThunk<
void,
void,
{ rejectValue: { error: string }; state: RootState }
>(
  `${catalogFilterSliceName}/setFilterToUrl`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const {
        isCanDownload, isOnlyFree, isAddFilterToParams,
      } = catalogFilterSelector(thunkAPI.getState());
      const newUrl = createCatalogFilterUrl(thunkAPI.getState());

      if (!isAddFilterToParams) return;

      const isCanDownloadUrl = createCatalogFilterUrl(thunkAPI.getState(), { isCanDownload: !isCanDownload });
      thunkAPI.dispatch(catalogFilterActions.changeIsCanDownloadUrl(isCanDownloadUrl));

      const isOnlyFreeUrl = createCatalogFilterUrl(thunkAPI.getState(), { isOnlyFree: !isOnlyFree });
      thunkAPI.dispatch(catalogFilterActions.changeIsOnlyFreeUrl(isOnlyFreeUrl));

      if (typeof newUrl === 'string') {
        await Router.replace(newUrl, undefined, { shallow: true });
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const changeGenres = createAsyncThunk<
void,
string[],
{ rejectValue: { error: string }; state: RootState }
>(
  `${catalogFilterSliceName}/changeGenres`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const isHaveWrongGenre = data
        .filter((genreSlug) => !getGenreBySlug(genreSlug)?.slug).length > 0;

      if (isHaveWrongGenre) {
        const { message } = await import('~/atomic/atom/message');
        message.error('Такого жанра не существует. Выберите жанр из списка');
      }

      if (!isHaveWrongGenre
        && data.length <= 4
        && data.every((genre) => genre.trim().length > 0)) {
        const [mainGenreSlug, ...subGenresSlugs] = data;

        thunkAPI.dispatch(catalogFilterActions.setMainGenreSlug(mainGenreSlug));
        thunkAPI.dispatch(catalogFilterActions.setSubGenresSlugs(subGenresSlugs));

        if (data.length < 1) {
          thunkAPI.dispatch(catalogFilterActions.resetTags());
        }

        thunkAPI.dispatch(setFilterToUrl());
        thunkAPI.dispatch(paginationActions.resetPaging());
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);
export const changeCompleteStatus = createAsyncThunk<
void,
CompleteStatusEnum | undefined,
{ rejectValue: { error: string }; state: RootState }
>(
  `${catalogFilterSliceName}/changeCompleteStatus`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      await thunkAPI.dispatch(catalogFilterActions.setCompleteStatus(data));
      await thunkAPI.dispatch(setFilterToUrl());
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const changeBookType = createAsyncThunk<
void,
BookTypeEnum | undefined,
{ rejectValue: { error: string }; state: RootState }
>(
  `${catalogFilterSliceName}/changeBookType`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      await thunkAPI.dispatch(catalogFilterActions.setBookType(data));
      await thunkAPI.dispatch(setFilterToUrl());
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const changeSortingType = createAsyncThunk<
void,
BookSortingEnum,
{ rejectValue: { error: string }; state: RootState }
>(
  `${catalogFilterSliceName}/changeSortingType`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      await thunkAPI.dispatch(catalogFilterActions.setBookSorting(data));
      await thunkAPI.dispatch(setFilterToUrl());
    } catch (error) {
      return thunkAPI.rejectWithValue({ error: error.message });
    }
  },
);

export const changeCatalogFilterWithAudiobook = createAsyncThunk<
void,
boolean
>(
  `${catalogFilterSliceName}/changeCatalogFilterWithAudiobook`,
  async (
    data,
    thunkAPI,
  ) => {
    await thunkAPI.dispatch(catalogFilterActions.setWithAudiobook(data));
    thunkAPI.dispatch(setFilterToUrl());
    thunkAPI.dispatch(paginationActions.resetPaging());
  },
);

export const changeCatalogFilterIsOnlyFree = createAsyncThunk<
void,
boolean
>(
  `${catalogFilterSliceName}/changeCatalogFilterIsOnlyFree`,
  async (
    data,
    thunkAPI,
  ) => {
    await thunkAPI.dispatch(catalogFilterActions.setIsOnlyFree(data));
    thunkAPI.dispatch(setFilterToUrl());
    thunkAPI.dispatch(paginationActions.resetPaging());
  },
);

export const changeCatalogFilterIsCanDownload = createAsyncThunk<
void,
boolean
>(
  `${catalogFilterSliceName}/changeCatalogFilterIsCanDownload`,
  async (
    data,
    thunkAPI,
  ) => {
    await thunkAPI.dispatch(catalogFilterActions.setIsCanDownload(data));
    thunkAPI.dispatch(setFilterToUrl());
    thunkAPI.dispatch(paginationActions.resetPaging());
  },
);

export const changeCatalogFilterIsWithAbonnement = createAsyncThunk<
void,
boolean
>(
  `${catalogFilterSliceName}/changeCatalogFilterIsWithAbonnement`,
  async (
    data,
    thunkAPI,
  ) => {
    thunkAPI.dispatch(catalogFilterActions.setIsWithAbonnement(data));
    thunkAPI.dispatch(setFilterToUrl());
    thunkAPI.dispatch(paginationActions.resetPaging());
  },
);

export const checkVMDParamsInQueries = createAsyncThunk<
void,
GetServerSidePropsContext,
{ state: RootState }
>(
  `${catalogFilterSliceName}/checkVMDParamsInQueries`,
  async (
    ctx,
    thunkAPI,
  ) => {
    if (ctx && 'query' in ctx) {
      const { query } = ctx;

      const adQuery = query?.ad;

      if (adQuery && adQuery === 'set') {
        thunkAPI.dispatch(catalogFilterActions.setWithAdvertisingFromBookriver(true));
      }
    }
  },
);
