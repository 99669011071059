import { createSelector } from '@reduxjs/toolkit';

import { isLoggedInSelector } from '~/feature/user/isLoggedInSelector';
import { getDiscountTime } from '~/feature/withTiwerDiscount/getDiscountTime';
import { RootState } from '~/store';

export const withTimerDiscountSelector = (state: RootState) => state.finance.withTimerDiscount;

export const isHaveTimerDiscountSelector = createSelector(
  withTimerDiscountSelector,
  ({ timer }) => timer && timer?.enabled === true,
);

export const isShowTimerDiscountSelector = createSelector(
  isLoggedInSelector,
  withTimerDiscountSelector,
  (isLoggedIn, {
    isTimerLoading,
    timer,
  }) => isLoggedIn && !isTimerLoading && timer.enabled && (getDiscountTime(timer.expiredAt) > 0),
);

export const withTimerDiscountBooksSelector = createSelector(
  withTimerDiscountSelector,
  ({ books }) => {
    return [...books].sort((prevBook, nextBook) => {
      if (prevBook.discount.percent > nextBook.discount.percent) {
        return -1;
      }
      if (prevBook.discount.percent < nextBook.discount.percent) {
        return 1;
      }

      return 0;
    });
  },
);
