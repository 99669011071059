import React, { useRef } from 'react';
import styled from 'styled-components';

import { MiniatureSizesEnum } from '~/api/book/bookApiTypes';
import { Avatar } from '~/atomic/atom/Avatar/Avatar';
import { Drawer } from '~/atomic/atom/Drawer';
import { ArrowIcon } from '~/atomic/atom/icon/ArrowIcon';
import { CloseIcon } from '~/atomic/atom/icon/CloseIcon';
import { AuthorLink } from '~/atomic/atom/links/dynamicLinks';
import { headerSelector } from '~/atomic/organism/Header/header.selector';
import { headerActions } from '~/atomic/organism/Header/header.slice';
import { PageTitleStyle, TextBoldStyle } from '~/atomic/Typography';
import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import { isLoggedInSelector } from '~/feature/user/isLoggedInSelector';
import { UserNavigation } from '~/feature/user/navigation/UserNavigation';
import { userSelector } from '~/feature/user/user.selector';
import { TimerDiscountBadger } from '~/feature/withTiwerDiscount/TimerDiscountBadger';
import { isShowTimerDiscountSelector } from '~/feature/withTiwerDiscount/withTimerDiscount.selectors';
import { lessThan } from '~/lib/mediaQuery';
import { useAppDispatch, useAppSelector } from '~/store';

export const HeaderMobileUserInfo = () => {
  const dispatch = useAppDispatch();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const { user } = useAppSelector(userSelector);
  const isShowTimerDiscount = useAppSelector(isShowTimerDiscountSelector);
  const isLoggedIn = useAppSelector(isLoggedInSelector);
  const { isMobileUserInfoOpen } = useAppSelector(headerSelector);
  const isMobileSize = useAppSelector(isMobileSizeSelector);
  const imageSize = isMobileSize ? 34 : 44;
  const drawerSize = isMobileSize ? 280 : 350;

  const handleClose = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    dispatch(headerActions.setIsMobileUserInfoOpen(false));
  };

  let touchendX;
  let touchstartX;

  let touchendY;
  let touchstartY;

  const handleGesture = () => {
    const betweenX = Math.abs(touchstartX - touchendX);
    const betweenY = Math.abs(touchstartY - touchendY);

    if ((touchendX > touchstartX) && (betweenX > betweenY)) {
      dispatch(headerActions.setIsMobileUserInfoOpen(false));
    }
  };

  const onTouchEnd = (event) => {
    touchendX = event.changedTouches[0].screenX;
    touchendY = event.changedTouches[0].screenY;
    handleGesture();
  };

  const onTouchStart = (event) => {
    touchstartX = event.changedTouches[0].screenX;
    touchstartY = event.changedTouches[0].screenY;
  };

  if (!isLoggedIn && !isMobileUserInfoOpen) {
    return null;
  }

  return (
    <SCDrawer
      placement="right"
      closable={false}
      onClose={handleClose}
      open={isMobileUserInfoOpen}
      style={{ position: 'fixed' }}
      width={drawerSize}
      destroyOnClose
    >
      <SCWrapper
        ref={wrapperRef}
        onTouchEnd={onTouchEnd}
        onTouchStart={onTouchStart}
      >
        <AuthorLink
          username={user?.username}
        >
          <SCTitle>
            <SCAvatar
              imageSize={imageSize}
              avatarSize={MiniatureSizesEnum.L}
              avatarImages={user?.avatarImages}
              alt={user?.name}
              userName={user?.name}
            />
            <SCLabel>
              { user?.name }
            </SCLabel>
            <SCArrowIcon />
          </SCTitle>
        </AuthorLink>
        {isShowTimerDiscount ? (
          <SCTimerDiscountWrapper>
            <TimerDiscountBadger />
          </SCTimerDiscountWrapper>
        ) : null}
        <UserNavigation isMobile />
        <SCCloseIcon />
      </SCWrapper>
    </SCDrawer>
  );
};

const SCDrawer = styled(Drawer)`

  .ant-drawer-content {
    overflow: visible;
  }
  
  .ant-drawer-content-wrapper {
    max-width: 100%;
    box-shadow: none !important;
  }
  
  .ant-drawer-body {
    padding-top: 0;
    padding-right: 0;
    padding-left: 0;
  }
`;

const SCLabel = styled.span`
  ${PageTitleStyle};
  display: block;
  padding-left: 12px;
  transition: color 0.3s ease;
  
  ${lessThan('sm')} {
    ${TextBoldStyle};
    color: var(--black-color);
  }
`;

const SCTitle = styled.a`
  display: flex;
  align-items: center;
  padding-left: 16px;
  padding-right: 18px;
  margin-bottom: 12px;
  margin-top: 12px;
  
  &:hover {
    ${SCLabel} {
      color: var(--primary-hover-color);
    }
  }
`;

const SCTimerDiscountWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(208, 217, 221, 0.4);
  width: 100%;
  padding-top: 14px;
  padding-bottom: 14px;
`;

const SCArrowIcon = styled(ArrowIcon)`
  font-size: 12px;
  margin-left: auto;
  color: var(--black-color);
`;

const SCWrapper = styled.div`
`;

const SCCloseIcon = styled(CloseIcon)`
  font-size: 16px;
  color: var(--white-color);
  position: absolute;
  left: -30px;
  top: 10px;
`;

const SCAvatar = styled(Avatar)`
  flex-shrink: 0;
`;
