import React, { FC, HTMLAttributes } from 'react';
import styled from 'styled-components';

import { AvatarImage } from '~/api/account/accountApiTypes';
import {
  CoverImage, MiniatureFormatEnum, MiniatureSizesEnum,
} from '~/api/book/bookApiTypes';
import { DefaultBookCover } from '~/atomic/atom/DefaultBookCover';
import { isSupportWebpSelector } from '~/feature/supportWebp/isSupportWebp.selector';
import { useAppSelector } from '~/store';

interface BookCoverProps {
  width: number;
  font: {
    min: number;
    max: number;
  };
  size?: MiniatureSizesEnum;
  book?: {
    title: string;
    author: string;
  };
  coverImages: CoverImage[];
}

export const sortByType = (images: AvatarImage[] | CoverImage[]): { jpeg: CoverImage[]; webp: CoverImage[]; } => {
  const jpegImages = images?.filter(
    (image) => image.format === MiniatureFormatEnum.JPG || image.sizeType === MiniatureSizesEnum.Original,
  ) ?? [];

  const webpImages = images?.filter((image) => image.format === MiniatureFormatEnum.WEBP) ?? [];

  return {
    jpeg: jpegImages,
    webp: webpImages,
  };
};

export const getImageUrlBySize = (
  images: CoverImage[],
  size: MiniatureSizesEnum,
): string => {
  return images?.find((image) => image.sizeType === size)?.url ?? '';
};

export const SimpleCover: FC<BookCoverProps & HTMLAttributes<HTMLImageElement>> = ({
  book, coverImages, font, width, size, ...props
}) => {
  if (!coverImages) {
    return (
      <SCBookCover>
        <SCImageContent>
          <DefaultBookCover
            font={font}
            bookName={book?.title ?? 'Название книги'}
            authorName={book?.author ?? 'Имя Автора'}
          />
        </SCImageContent>
      </SCBookCover>
    );
  }

  const { jpeg, webp } = sortByType(coverImages);
  const isSupportWebp = useAppSelector(isSupportWebpSelector);

  let srcSet: string;

  if (isSupportWebp) {
    srcSet = `${getImageUrlBySize(webp, MiniatureSizesEnum.S)} 100w, `
    + `${getImageUrlBySize(webp, MiniatureSizesEnum.M)} 160w, `
    + `${getImageUrlBySize(webp, MiniatureSizesEnum.L)} 320w`;
  } else {
    srcSet = `${getImageUrlBySize(jpeg, MiniatureSizesEnum.S)} 100w, `
    + `${getImageUrlBySize(jpeg, MiniatureSizesEnum.M)} 160w, `
    + `${getImageUrlBySize(jpeg, MiniatureSizesEnum.L)} 320w`;
  }

  return (
    <SCBookCover>
      <SCBookCoverPicture>
        <img
          loading="lazy"
          decoding="async"
          src={getImageUrlBySize(jpeg, MiniatureSizesEnum.Original)}
          srcSet={srcSet}
          width={width}
          alt={`${book?.title ?? 'Название книги'} - ${book?.author ?? 'Автор книги'}`}
          {...props}
        />
      </SCBookCoverPicture>
    </SCBookCover>
  );
};

const SCBookCoverPicture = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow: hidden;
  background-color: var(--gray2-color);

  img {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    height: auto;
  }
`;

const SCBookCover = styled.div`
  display: block;
  width: 100%;
  padding-bottom: var(--image-aspect);
  position: relative;
`;

export const SCImageContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
`;
