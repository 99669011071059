import {
  GetServerSidePropsContext, NextApiRequest, NextApiResponse,
} from 'next';
import { setCookie } from 'nookies';

import { tokenName } from '~/feature/authorization/tokenName';
import { apiStatusCodes } from '~/lib/const';

export const setTokenWithFrontServer = (res: GetServerSidePropsContext['res'], token: string, ttl: number) => {
  setCookie(
    { res },
    tokenName,
    token as string,
    {
      maxAge: Number(ttl) * 60,
      path: '/',
      secure: true,
      httpOnly: true,
      sameSite: 'none',
    },
  );
};

export default function setToken(req: NextApiRequest, res: NextApiResponse) {
  try {
    const { data } = JSON.parse(req.body);

    if ('token' in data && 'ttl' in data) {
      const { token, ttl } = data;
      setTokenWithFrontServer(res, token, ttl);
    } else {
      res.status(apiStatusCodes.notValidError).json({ message: 'parameter "token" and "ttl" is not found' });
    }

    res.status(apiStatusCodes.success).json({ message: 'Куки успешно установлены' });
  } catch (error) {
    if (error instanceof Error) {
      const message = error?.message ?? '';

      res.status(apiStatusCodes.error).json({ message });
    }

    res.status(apiStatusCodes.error);
  }
}
