import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PostThemeEnum } from '~/api/blog/blogTypes';

export const blogsFilterNavSliceName = 'blogsFilterNav';

export const blogsFilterNavDefaultThemes = [
  PostThemeEnum.AllAboutSales,
  PostThemeEnum.CreativeProcess,
  PostThemeEnum.EntertainingStories,
  PostThemeEnum.InterestingEvents,
  PostThemeEnum.Reflections,
  PostThemeEnum.ReviewsAndCriticism,
  PostThemeEnum.Interview,
  PostThemeEnum.ProjectNews,
];

export const blogsFilterNavDefaultOnlySubscribed = false;

export const blogsFilterNavSlice = createSlice({
  name: blogsFilterNavSliceName,
  initialState: {
    onlySubscribed: blogsFilterNavDefaultOnlySubscribed,
    themes: blogsFilterNavDefaultThemes,
  },
  reducers: {
    changeOnlySubscribed: (state, action: PayloadAction<boolean>) => {
      state.onlySubscribed = action.payload;
    },
    toggleThemes: (state, action: PayloadAction<PostThemeEnum>) => {
      if (state.themes.includes(action.payload)) {
        state.themes = state.themes.filter((theme) => theme !== action.payload);
      } else {
        state.themes.push(action.payload);
      }
    },
    changeThemes: (state, action: PayloadAction<string[]>) => {
      state.themes = action.payload as PostThemeEnum[];
    },
  },
});

export const {
  actions: blogsFilterNavActions,
  reducer: blogsFilterNavReducer,
} = blogsFilterNavSlice;
