import { PostThemeEnum } from '~/api/blog/blogTypes';
import { createTagUrl, CreateTagUrlProps } from '~/atomic/atom/links/createTagUrl';
import { createUrlWithFilter, CreateUrlWithFilterProps } from '~/atomic/atom/links/createUrlWithFilter';
import { createRelativeUrl } from '~/lib/utils/createRelativeUrl';
import { PostPlaceEnum } from '~/pages/post/[slugPost]';

export const pagesConfigStore = {
  tag: {
    as: (props: CreateTagUrlProps) => createTagUrl(props),
    url: (props?: CreateTagUrlProps) => createTagUrl(props),
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  genre: {
    as: (props: Omit<CreateUrlWithFilterProps, 'startWith'>) => createUrlWithFilter({
      ...props,
      startWith: '/genre',
    }),
    url: (props: Omit<CreateUrlWithFilterProps, 'startWith'> | void) => createUrlWithFilter({
      ...props,
      startWith: '/genre',
    }),
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  recommendation: {
    as: (props: Omit<CreateUrlWithFilterProps, 'startWith'>) => createUrlWithFilter({
      ...props,
      startWith: '/recommendation',
    }),
    url: (props: Omit<CreateUrlWithFilterProps, 'startWith'>) => createUrlWithFilter({
      ...props,
      startWith: '/recommendation',
    }),
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  book: {
    as: (slug?: string) => `/book/${slug ?? ''}`,
    url: '/book/[bookSlug]',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  cycle: {

    as: (authorUsername?: string, cycleSlug?: string) => `/series/${authorUsername ? `${authorUsername}/${cycleSlug ?? ''}` : ''}`,
    url: '/series/[authorUsername]/[cycleSlug]',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  blogs: {
    url: '/blogs',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  blogsTheme: {
    as: (theme?: PostThemeEnum) => `/blogs/${theme ?? ''}`,
    url: '/blogs/[theme]',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  mybooks: {
    as: ({ status, ...props }: Omit<CreateUrlWithFilterProps, 'startWith'> & { status?: string }) => createUrlWithFilter({
      ...props,
      startWith: '/mybooks',
      query: status ? { status } : undefined,
    }),
    url: (status?: string) => `/mybooks/[[...keywords]]${status ? `?status=${status}` : ''}`,
    isPublic: false,
    isAvailableForReader: false,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  reader: {
    as: (bookSlug?: string) => `/reader/${bookSlug}`,
    url: '/reader/[bookSlug]',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  readerChapter: {

    as: (bookSlug?: string, chapterId?: string | number) => `/reader/${bookSlug ? `${bookSlug}/${chapterId ?? ''}` : ''}`,
    url: '/reader/[bookSlug]/[chapterId]',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  user: {
    as: (username?: string) => `/user/${username ?? ''}`,
    url: '/user/[username]',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  author: {
    as: (username?: string) => `/author/${username ?? ''}`,
    url: '/author/[authorUsername]',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  authorBlog: {
    as: (username?: string) => `/author${username ? `/${username}/blog` : '/'}`,
    url: '/author/[authorUsername]/blog',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  authorSubscribers: {
    as: (username?: string) => `/author${username ? `/${username}/subscribers` : '/'}`,
    url: '/author/[authorUsername]/subscribers',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  post: {
    as: (postSlug: string, place?: PostPlaceEnum) => createRelativeUrl({
      pathname: `/post/${postSlug ?? ''}`,
      query: { place },
    }),
    url: (place?: PostPlaceEnum) => `/post/[slugPost]${place ? `?place=${place}` : ''}`,
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  postEdit: {
    as: (postId: string | number) => `/myblog/${postId ?? ''}`,
    url: '/myblog/[postId]',
    isPublic: false,
    isAvailableForReader: false,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  campaign: {
    as: (id?: string) => `/ad-campaigns/${id ?? ''}`,
    url: '/ad-campaigns/[id]',
    isPublic: false,
    isAvailableForReader: false,
    isAvailableForAuthor: false,
    isAvailableForCommercialAuthor: true,
  },

  bookEdit: {
    as: (
      bookId?: number | string,
    ) => `/book-edit/${bookId ?? ''}`,
    url: '/book-edit/[bookId]',
    isPublic: false,
    isAvailableForReader: false,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  chapterEdit: {
    as: (
      bookId?: number | string,
      chapterId?: number | string,
    ) => `/book-edit/${bookId ? `${bookId}/chapter/${chapterId ?? ''}` : ''}`,
    url: '/book-edit/[bookId]/chapter/[chapterId]',
    isPublic: false,
    isAvailableForReader: false,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  searchBooks: {
    as: (genres?: Array<string>, keyword?: string) => createRelativeUrl(
      {
        pathname: `/search/books/${genres?.join('/') ?? ''}`,
        query: { keyword },
      },
    ),
    url: (keyword?: string) => createRelativeUrl(
      {
        pathname: '/search/books/[[...keywords]]',
        query: { keyword },
      },
    ),
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  searchAuthors: {
    as: (genres?: Array<string>, keyword?: string) => createRelativeUrl(
      {
        pathname: `/search/authors/${genres?.join('/') ?? ''}`,
        query: { keyword },
      },
    ),
    url: (keyword?: string) => createRelativeUrl(
      {
        pathname: '/search/authors/[[...keywords]]',
        query: { keyword },
      },
    ),
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  authorCommercial: {
    url: '/author/commercial',
    isPublic: false,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  authorCommercialRegistration: {
    url: '/author/commercial/registration',
    isPublic: false,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  authorCommercialRegistrationPending: {
    url: '/author/commercial/registration/pending',
    isPublic: false,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  authorRegistration: {
    url: '/author/registration',
    isPublic: false,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  home: {
    url: '/',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  about: {
    url: '/about',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  campaigns: {
    url: '/ad-campaigns',
    isPublic: false,
    isAvailableForReader: false,
    isAvailableForAuthor: false,
    isAvailableForCommercialAuthor: true,
  },

  refriver: {
    url: '/refriver',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  books: {
    url: '/books',
    isPublic: false,
    isAvailableForReader: false,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  myblog: {
    url: '/myblog',
    isPublic: false,
    isAvailableForReader: false,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  partnersBooks: {
    url: '/partners/books',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  partnersRegistration: {
    url: '/partners/registration',
    isPublic: false,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  partnersDiscount: {
    url: '/partners/discount',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  partnersBooksWidget: {
    url: '/partners/books-widget',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  imAuthor: {
    url: '/im-author',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  antiPirate: {
    url: '/anti-pirate',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  contacts: {
    url: '/contacts',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  antiPirateCheck: {
    url: '/anti-pirate/check',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  statistics: {
    url: '/statistics',
    isPublic: false,
    isAvailableForReader: false,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  notifications: {
    url: '/notifications',
    isPublic: false,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  marketing: {
    url: '/marketing',
    isPublic: false,
    isAvailableForReader: false,
    isAvailableForAuthor: false,
    isAvailableForCommercialAuthor: true,
  },

  settings: {
    url: '/settings',
    isPublic: false,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  partnersAboutProgram: {
    url: '/partners/about-program',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  myPurchases: {
    url: '/my-purchases',
    isPublic: false,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  legalPrivacyPolicy: {
    url: '/legal/privacy-policy',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  legalUserPolicy: {
    url: '/legal/user-policy',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  legalPrivacyAgreement: {
    url: '/legal/privacy-agreement',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  legalAuthorPolicy: {
    url: '/legal/author-policy',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  legalLicensePolicy: {
    url: '/legal/license-policy',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  legalLoyaltyPolicy: {
    url: '/legal/loyalty-policy',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  legalAdvertising: {
    url: '/legal/advertising',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  legalAdsPolicy: {
    url: '/legal/ads-policy',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  legalReferralProgram: {
    url: '/legal/referral-program',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  personalYandexMetricaCounterInfo: {
    url: '/personal-yandex-metrica-counter-info',
    isPublic: false,
    isAvailableForReader: false,
    isAvailableForAuthor: false,
    isAvailableForCommercialAuthor: true,
  },

  cumulativeDiscounts: {
    url: '/cumulative-discounts',
    isPublic: false,
    isAvailableForReader: false,
    isAvailableForAuthor: false,
    isAvailableForCommercialAuthor: true,
  },

  authResetPassword: {
    url: '/auth/reset-password',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  finance: {
    url: '/finance',
    isPublic: false,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  profile: {
    url: '/profile',
    isPublic: false,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  subscriptions: {
    url: '/subscriptions',
    isPublic: false,
    isAvailableForReader: false,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  timerDiscounts: {
    url: '/timer-discounts',
    isPublic: false,
    isAvailableForReader: false,
    isAvailableForAuthor: false,
    isAvailableForCommercialAuthor: true,
  },

  partnersAds: {
    url: '/partners/ads',
    isPublic: false,
    isAvailableForReader: false,
    isAvailableForAuthor: false,
    isAvailableForCommercialAuthor: true,
  },

  partnersCPA: {
    url: '/partners/cpa',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  scheduledDiscount: {
    url: '/scheduled-discount',
    isPublic: false,
    isAvailableForReader: false,
    isAvailableForAuthor: false,
    isAvailableForCommercialAuthor: true,
  },

  christmasSale: {
    url: '/christmas-sale',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  blackfriday: {
    url: '/blackfriday',
    isPublic: true,
  },

  discounts: {
    url: '/discounts',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  contests: {
    url: '/contests',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  contestEverythingWillBeFine: {
    url: '/contests/everything-will-be-fine',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  contestStorySapphire: {
    url: '/contests/story-sapphire',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  contestDukeRectorTwoDragons: {
    url: '/contests/duke-rector-two-dragons',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  contestZhenitNelzyaPomilovat: {
    url: '/contests/zhenit-nelzya-pomilovat',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },

  bookSubscriptions: {
    url: '/book-subscriptions',
    isPublic: true,
    isAvailableForReader: true,
    isAvailableForAuthor: true,
    isAvailableForCommercialAuthor: true,
  },
};
