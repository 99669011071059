import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  AgencyReport, GetBalanceResult, WithdrawalMethod,
} from '~/api/finance/financeApiTypes';

export const financePageSliceName = 'financePage';

export const financePageSlice = createSlice({
  name: financePageSliceName,
  initialState: {
    isLoading: false,
    info: {} as GetBalanceResult,
    isWithdrawMoneyLoading: false,
    isChangeAutoPaymentStatusLoading: false,
    agencyReports: [] as AgencyReport[],
    isGetReportsLoading: false,
  },
  reducers: {
    setInfo: (state, action: PayloadAction<GetBalanceResult>) => {
      state.info = action.payload;
    },
    deleteConnectPayoutMethod: (state) => {
      state.info.attached = {} as WithdrawalMethod;
    },
    changeAttachmentPayoutMethod: (state, action: PayloadAction<WithdrawalMethod>) => {
      state.info.attached = action.payload;
    },
    changeIsWithdrawMoneyLoading: (state, action: PayloadAction<boolean>) => {
      state.isWithdrawMoneyLoading = action.payload;
    },
    setIsChangeAutoPaymentStatusLoading: (state, action: PayloadAction<boolean>) => {
      state.isChangeAutoPaymentStatusLoading = action.payload;
    },
    changeAgencyReports: (state, action: PayloadAction<AgencyReport[]>) => {
      state.agencyReports = action.payload;
    },
    setIsGetReportsLoading: (state, action: PayloadAction<boolean>) => {
      state.isGetReportsLoading = action.payload;
    },
  },
});

export const { actions: financePageActions, reducer: financePageReducer } = financePageSlice;
