import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Comment } from '~/api/comment/commentApiTypes';
import { GraphqlBookComment } from '~/graphql/books/factory/comment/CommentFactory';

type LoadingItemTypes = 'addComment' | 'addReplay' | 'getComments' | '';

export const isCommentsLoading = (item: LoadingItemTypes, loading) => {
  return loading.includes(item);
};

const commentSlice = createSlice({
  name: 'comment',
  initialState: {
    createCommentText: '',
    comments: [] as Comment[],
    bookComments: [] as GraphqlBookComment[],
    loading: '',
    isDeleteSaveCommentLoading: false,
    isPublishCommentsLoading: false,
    error: '',
    saveComment: {} as Comment,
    totalBookComments: 0,
    commentIdCursor: null as number | null,
  },
  reducers: {
    addLoading: (state, action: PayloadAction<LoadingItemTypes>) => {
      state.loading += ` ${action.payload}`;
    },
    setIsDeleteSaveCommentLoading: (state, action: PayloadAction<boolean>) => {
      state.isDeleteSaveCommentLoading = action.payload;
    },
    setIsPublishCommentsLoading: (state, action: PayloadAction<boolean>) => {
      state.isPublishCommentsLoading = action.payload;
    },
    removeLoading: (state, action: PayloadAction<LoadingItemTypes>) => {
      state.loading = state.loading.replace(action.payload, '');
    },
    changeSaveComment: (state, action: PayloadAction<Comment>) => {
      state.saveComment = action.payload;
    },
    deleteSaveComment: (state) => {
      state.saveComment = {} as Comment;
    },
    changeCreateCommentText: (state, action: PayloadAction<string>) => {
      state.createCommentText = action.payload;
    },
    deleteComment: (state, action: PayloadAction<number >) => {
      state.comments = state.comments.filter((comment) => comment.id !== action.payload);
    },
    deleteBookComment: (state, action: PayloadAction<number >) => {
      state.bookComments = state.bookComments.filter((comment) => comment.id !== action.payload);
    },
    changeComments: (state, action: PayloadAction<Array<Comment>>) => {
      state.comments = action.payload;
    },
    changeGraphqlBookComments: (state, action: PayloadAction<Array<GraphqlBookComment>>) => {
      state.bookComments = action.payload;
    },
    changeTotalBookComments: (state, action: PayloadAction<number>) => {
      state.totalBookComments = action.payload;
    },
    changeCommentIdCursor: (state, action: PayloadAction<number>) => {
      state.commentIdCursor = action.payload;
    },
  },
});

export const { actions: commentActions, reducer: commentReducer } = commentSlice;
