import { createAsyncThunk } from '@reduxjs/toolkit';

import { accountApi } from '~/api/account/accountApi';
import { authorActions } from '~/atomic/page/author/author.slice';
import { heroImageActions, heroImageSliceName } from '~/atomic/page/author/heroImage/heroImage.slice';
import { environments } from '~/lib/const';
import { RootState } from '~/store';

export const deleteHeroImage = createAsyncThunk<
void, void, { state: RootState }>(
  `${heroImageSliceName}/deleteheroImage`,
  async (_, thunkAPI) => {
    const { isDeleteLoading } = thunkAPI.getState().heroImage;
    if (!isDeleteLoading) {
      thunkAPI.dispatch(heroImageActions.setIsDeleteLoading(true));
      try {
        await accountApi.deleteHeroImage();
        thunkAPI.dispatch(authorActions.changeCurrentAuthorHeroImage(''));
      } catch (error) {
        if (environments.isClient && error instanceof Error) {
          const { message } = await import('~/atomic/atom/message');
          message.error(error.message);
        }
        return thunkAPI.rejectWithValue({ error: error.message });
      } finally {
        thunkAPI.dispatch(heroImageActions.setIsDeleteLoading(false));
      }
    }
  },
);
