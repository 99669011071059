import React, { FC } from 'react';

import { AuthorNavigation } from '~/feature/user/navigation/AuthorNavigation';
import { ReaderNavigation } from '~/feature/user/navigation/ReaderNavigation/ReaderNavigation';
import {
  isLoggedUserAdminSelector,
  isLoggedUserAuthorSelector, isLoggedUserCommercialAuthorSelector,
} from '~/feature/user/user.selector';
import { useAppSelector } from '~/store';

import { CommercialAuthorNavigation } from './CommercialAuthorNavigation';

export const UserNavigation: FC<{ isMobile?: boolean }> = ({ isMobile }) => {
  const isLoggedUserCommercialAuthor = useAppSelector(isLoggedUserCommercialAuthorSelector);
  const isLoggedUserAdmin = useAppSelector(isLoggedUserAdminSelector);
  const isLoggedUserAuthor = useAppSelector(isLoggedUserAuthorSelector);

  if (isLoggedUserCommercialAuthor || isLoggedUserAdmin) {
    return <CommercialAuthorNavigation isMobile={isMobile} />;
  }

  if (isLoggedUserAuthor) {
    return <AuthorNavigation isMobile={isMobile} />;
  }

  return (
    <ReaderNavigation isMobile={isMobile} />
  );
};
