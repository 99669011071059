import { v4 as uuidv4 } from 'uuid';

const deviceSessionUidName = 'deviceSessionUid';

export const generateDeviceSessionUid = () => {
  const deviceSessionUidFromStorage = localStorage.getItem(deviceSessionUidName);

  if (!deviceSessionUidFromStorage) {
    const uuid = uuidv4();
    localStorage.setItem(deviceSessionUidName, uuid);
  }
};

export const getDeviceSessionUid = (): string | undefined => {
  return localStorage.getItem(deviceSessionUidName);
};
