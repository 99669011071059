import React, { FC } from 'react';

import { Post } from '~/api/blog/blogTypes';
import { AuthorLink, PostLink } from '~/atomic/atom/links/dynamicLinks';
import { BookStatus } from '~/atomic/molecula/BookStatus';
import { usePostContentFotmatting } from '~/feature/blogs/postContentFotmatting';
import { isMobileSizeSelector } from '~/feature/media/windowSize.selector';
import { DateFormatComponent } from '~/lib/dateFormatText';
import { useIsClientRender } from '~/lib/hooks/useIsClientRender';
import { PostPlaceEnum } from '~/pages/post/[slugPost]';
import { useAppSelector } from '~/store';

import { getPostThemeName } from './EditPostCard';
import {
  SCAuthorPostCard,
  SCDatePostCard,
  SCHeaderPostCard,
  SCMainPostCard, SCPostButton,
  SCPostCard,
  SCPostCommentsIcon,
  SCPostCommentsWrapper,
  SCPostCount,
  SCTextPostCard,
  SCTitlePostCard,
  SCTitlePostCardLink,
} from './postCardStyle';

export const MAX_POST_CARD_CONTENT_LENGTH = 270;
export const MAX_MOBILE_POST_CARD_CONTENT_LENGTH = 180;

export const ViewPostCard: FC<{ post: Post }> = ({ post }) => {
  const isMobileSize = useAppSelector(isMobileSizeSelector);
  const isClient = useIsClientRender();
  const postContent = usePostContentFotmatting(
    post.content,
    isMobileSize && isClient ? MAX_MOBILE_POST_CARD_CONTENT_LENGTH : MAX_POST_CARD_CONTENT_LENGTH,
  );

  return (
    <SCPostCard>
      <SCMainPostCard>
        <SCHeaderPostCard>
          <PostLink
            slugPost={post.slug}
          >
            <SCTitlePostCardLink>
              <SCTitlePostCard>
                {post.title}
              </SCTitlePostCard>
            </SCTitlePostCardLink>
          </PostLink>
          <AuthorLink
            username={post?.author?.username ?? ''}
          >
            <SCAuthorPostCard>
              {post?.author?.name ?? ''}
            </SCAuthorPostCard>
          </AuthorLink>
          {post?.publishedAt && (
            <SCDatePostCard>
              <DateFormatComponent date={post?.publishedAt ?? new Date()} />
            </SCDatePostCard>
          )}
          <BookStatus text={getPostThemeName(post?.theme)} type="theme" />
          {post.counters.comments ? (
            <SCPostCommentsWrapper>
              <SCPostCommentsIcon />
              <SCPostCount>{post.counters.comments}</SCPostCount>
            </SCPostCommentsWrapper>
          ) : null}
        </SCHeaderPostCard>
        <SCTextPostCard
          dangerouslySetInnerHTML={{ __html: postContent }}
        />
        <PostLink slugPost={post.slug} place={PostPlaceEnum.Blog}>
          <a>
            <SCPostButton
              size="small"
            >
              Читать дальше
            </SCPostButton>
          </a>
        </PostLink>
      </SCMainPostCard>
    </SCPostCard>
  );
};
