import React, { FC } from 'react';
import styled from 'styled-components';

import { SiteTemplate } from '~/feature/template/SiteTemplate';

export const CommonLayout: FC<{ children: any }> = ({ children }) => {
  return (
    <SiteTemplate>
      <SCContentWrapper>
        {children}
      </SCContentWrapper>
    </SiteTemplate>
  );
};

const SCContentWrapper = styled.div`
  max-width: var(--container-width);
  margin: 0 auto;
  width: 100%;
  height: 100%;
  flex-grow: 1;
`;
