interface CreateUrlProps {
  pathname?: string;
  query?: Object;
  hash?: string;
}

export const createRelativeUrl = (url: CreateUrlProps) => {
  const exampleUrl = 'https://bookriver.ru';
  const defaultUrl = new URL(exampleUrl);

  Object.keys(url.query)
    .forEach((key) => {
      if (url.query[key]) {
        defaultUrl.searchParams.set(key, url.query[key]);
      }
    });

  if (url?.pathname) {
    defaultUrl.pathname = url.pathname;
  }

  if (url?.hash) {
    defaultUrl.hash = url.hash;
  }

  return defaultUrl.pathname + defaultUrl.search + defaultUrl.hash;
};
