import { useRouter } from 'next/router';
import React, {
  FC, HTMLAttributes, ReactNode,
} from 'react';
import styled from 'styled-components';

import { isAndroidDevice } from '~/lib/utils/isAndroidDevice';

interface BookriverMobileLinkProps extends HTMLAttributes<HTMLAnchorElement> {
  url?: string;
  children: ReactNode;
}

export const BookriverMobileLink: FC<BookriverMobileLinkProps> = ({
  url = '',
  children,
  ...props
}) => {
  const router = useRouter();
  const isAndroid = isAndroidDevice();

  return (
    <SCLink
      {...props}
      onClick={() => {
        setTimeout(() => {
          router.push(isAndroid
            ? 'market://details?id=ru.bookriver.app'
            : 'https://play.google.com/store/apps/details?id=ru.bookriver.app&hl=ru&gl=US');
        }, 300);
      }}
      href={`app://bookriver.ru/${url}`}
    >
      {children}
    </SCLink>
  );
};

const SCLink = styled.a`
  display: flex;
`;
