import { WithToken } from '~/api/auth/authApiTypes';
import { Book } from '~/api/book/bookApiTypes';

export interface FeedSlider {
  keyword: {
    name: string;
    slug: string;
    type: 'bestseller' | 'hot_and_exclusive' | 'recommended' | 'latest';
  };
  books: Book[];
}

export enum FeedTabEnum {
  Popularity = 'popularity',
  Abonnement = 'abonnement',
  Recommended = 'recommended',
  Bestseller = 'bestseller',
  Exclusive = 'exclusive',
  News = 'news',
  Discount = 'discount',
  Listen = 'listen',
}

export type GenreTags = {
  name?: string;
  slug: string;
  tags: {
    slug: string;
    name: string;
  }[]
};
export type GetFeedResponse = FeedSlider[];
export type GetFeedGenreParam = {
  slug: string;
  checked: boolean;
  subgenres: {
    slug: string;
    checked: boolean;
  }[]
};
export type FeedGenreType = {
  name: string;
  slug: string;
  isActive: boolean;
  isAllSubgenresActive?: boolean;
  isAllSubgenresInactive?: boolean;
  childs?: FeedSubGenreType[];
};
export type FeedSubGenreType = {
  slug: string;
  name: string;
  isActive: boolean;
};

export interface GetFeedParams extends WithToken {
  genres: GetFeedGenreParam[];
  bestsellersPage: number;
  bestsellersPerPage: number;
}
