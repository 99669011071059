// eslint-disable-next-line max-classes-per-file
import { UserAbonnementStatusEnum } from '~/api/account/accountApiTypes';
import {
  AbonnementChapterCountEnum, AbonnementPriceEnum,
  AbonnementType,
  AbonnementTypeEnum,
  AbonnementTypeNameEnum, UserAbonnement,
} from '~/graphql/books/factory/abonement/AbonnementFactoryTypes';
import {
  AbonnementTypeFragmentFragment,
  UserAbonnementFragmentFragment,
} from '~/graphql/books/GraphQLGeneratedCode';

export const getAbonnementTypeName = (type: AbonnementTypeEnum) => {
  const key = Object.entries(AbonnementTypeEnum)?.find(([_, v]) => {
    return v === type;
  })?.[0];

  return AbonnementTypeNameEnum[key];
};

export const getAbonnementCharacter = (type: AbonnementTypeEnum) => {
  const key = Object.entries(AbonnementTypeEnum)?.find(([_, v]) => {
    return v === type;
  })?.[0];

  return AbonnementChapterCountEnum[key];
};

export const getAbonnementPrice = (type: AbonnementTypeEnum) => {
  const key = Object.entries(AbonnementTypeEnum)?.find(([_, v]) => {
    return v === type;
  })?.[0];

  return AbonnementPriceEnum[key];
};

export class AbonnementTypeFactory {
  static create(data: AbonnementTypeFragmentFragment): AbonnementType {
    return {
      type: data?.type as AbonnementTypeEnum,
      price: data?.price ?? 0,
    };
  }
}

export class UserAbonnementFactory {
  static create(data: UserAbonnementFragmentFragment): UserAbonnement {
    return {
      status: data.status as any as UserAbonnementStatusEnum,
      abonnementType: AbonnementTypeFactory.create(data.abonnementType),
      characterConsumptionAsPercent: data.characterConsumptionAsPercent,
      isAutoRenewalEnabled: data.isAutoRenewalEnabled,
      paymentError: data.paymentError,
      shouldExpireAt: data.shouldExpireAt,
      switchAbonnementType: data.switchAbonnementType,
    };
  }
}
