import { createSelector } from '@reduxjs/toolkit';

import { DeviceEnum } from '~/api/finance/marketing/campaignsApiTypes';
import { breakpoints } from '~/lib/mediaQuery';
import { RootState } from '~/store';

export const windowSizeSelector = (state: RootState) => state.windowSize;

export const isLaptopSizeSelector = createSelector(
  windowSizeSelector,
  ({ size }) => {
    if (size.width !== 0) {
      return breakpoints.lg > size.width;
    }

    return false;
  },
);

export const isTabletSizeSelector = createSelector(
  windowSizeSelector,
  ({ size }) => {
    if (size.width !== 0) {
      return breakpoints.md > size.width;
    }

    return false;
  },
);

export const isMobileSizeSelector = createSelector(
  windowSizeSelector,
  ({ size, deviceFromServer }) => {
    if (size.width !== 0) {
      return (breakpoints.sm > size.width);
    }

    return deviceFromServer === DeviceEnum.Mobile;
  },
);
