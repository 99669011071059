import React, {
  FC, useRef, useState,
} from 'react';
import styled from 'styled-components';

import { ArrowIcon } from '~/atomic/atom/icon/ArrowIcon';
import { TagLink } from '~/atomic/atom/links/dynamicLinks';
import { headerActions } from '~/atomic/organism/Header/header.slice';
import { TextBoldStyle } from '~/atomic/Typography';
import { getPopularTagSlugByName } from '~/feature/popularTags/popularTags';
import { useAppDispatch } from '~/store';

const ChildTagLabel: FC<{ tagLabel: string }> = ({ tagLabel }) => {
  const dispatch = useAppDispatch();

  const handleHeaderExitClick = () => {
    dispatch(headerActions.setIsHamburgerActive(false));
  };

  const closeLibrary = () => {
    dispatch(headerActions.setIsOpenMobileLibrary(false));
  };

  return (
    <SCChildTagLabel key={getPopularTagSlugByName(tagLabel)}>
      <TagLink
        slug={getPopularTagSlugByName(tagLabel)}
      >
        <a
          onClick={() => {
            closeLibrary();
            handleHeaderExitClick();
          }}
        >
          <span>{tagLabel}</span>
        </a>
      </TagLink>
    </SCChildTagLabel>
  );
};

interface TagGroupProps {
  children?: React.ReactNode;
  tagLabel: string;
}

const MIN_HEIGHT = 44;

const GenreGroup: FC<TagGroupProps> = ({ children, tagLabel }) => {
  const [isOpen, setIsOpen] = useState(false);
  const listRef = useRef<HTMLDivElement>(null);
  const titleWrapperRef = useRef<HTMLDivElement>(null);
  const titleHeight = MIN_HEIGHT;
  const [mainHeight, setMainHeight] = useState(titleHeight);

  const showAllTagsHandler = () => {
    if (children) {
      setIsOpen((isOpen) => !isOpen);
      setMainHeight(!isOpen ? listRef.current.offsetHeight + titleHeight : titleHeight);
    }
  };

  return (
    <SCTagGroup
      data-is-open={isOpen}
      style={{ maxHeight: `${mainHeight}px` }}
    >
      <SCTitleWrapper
        ref={titleWrapperRef}
      >
        <SCTitle
          onClick={showAllTagsHandler}
        >
          {tagLabel}
          <SCArrowIcon />
        </SCTitle>
      </SCTitleWrapper>
      <SCTagLabelGroup
        ref={listRef}
      >
        {children}
      </SCTagLabelGroup>
    </SCTagGroup>
  );
};

export const HeaderMobNavTagLibrary = () => {
  return (
    <>
      <GenreGroup
        tagLabel="Этот дивный мир"
      >
        <ChildTagLabel
          tagLabel="Авторский мир"
        />
        <ChildTagLabel
          tagLabel="Магический мир"
        />
        <ChildTagLabel
          tagLabel="Реалрпг"
        />
        <ChildTagLabel
          tagLabel="Иные миры"
        />
        <ChildTagLabel
          tagLabel="Современность"
        />
        <ChildTagLabel
          tagLabel="Параллельные миры"
        />
        <ChildTagLabel
          tagLabel="Средневековье"
        />
        <ChildTagLabel
          tagLabel="Русреал"
        />
        <ChildTagLabel
          tagLabel="Стимпанк"
        />
        <ChildTagLabel
          tagLabel="Техномагия"
        />
        <ChildTagLabel
          tagLabel="Антиутопия"
        />
        <ChildTagLabel
          tagLabel="Российская империя"
        />
        <ChildTagLabel
          tagLabel="Виртуальная реальность"
        />
      </GenreGroup>
      <GenreGroup
        tagLabel="Не(просто)люди"
      >
        <ChildTagLabel
          tagLabel="Оборотни"
        />
        <ChildTagLabel
          tagLabel="Драконы"
        />
        <ChildTagLabel
          tagLabel="Демоны"
        />
        <ChildTagLabel
          tagLabel="Ведьма"
        />
        <ChildTagLabel
          tagLabel="Вампиры"
        />
        <ChildTagLabel
          tagLabel="Авторские расы"
        />
        <ChildTagLabel
          tagLabel="Эльфы"
        />
        <ChildTagLabel
          tagLabel="Боги"
        />
        <ChildTagLabel
          tagLabel="Некромант"
        />
        <ChildTagLabel
          tagLabel="Монстры"
        />
        <ChildTagLabel
          tagLabel="Мутанты"
        />
        <ChildTagLabel
          tagLabel="Искусственный интеллект"
        />
        <ChildTagLabel
          tagLabel="Призраки"
        />
        <ChildTagLabel
          tagLabel="Мифические существа"
        />
        <ChildTagLabel
          tagLabel="Инопланетяне"
        />
        <ChildTagLabel
          tagLabel="Ангелы"
        />
        <ChildTagLabel
          tagLabel="Наги"
        />
      </GenreGroup>
      <GenreGroup
        tagLabel="Такие разные герои"
      >
        <ChildTagLabel
          tagLabel="Властный герой"
        />
        <ChildTagLabel
          tagLabel="Становление героя"
        />
        <ChildTagLabel
          tagLabel="Попаданка"
        />
        <ChildTagLabel
          tagLabel="Сильная героиня"
        />
        <ChildTagLabel
          tagLabel="Неунывающая героиня"
        />
        <ChildTagLabel
          tagLabel="Беременная героиня"
        />
        <ChildTagLabel
          tagLabel="Адекватные герои"
        />
        <ChildTagLabel
          tagLabel="Настоящий мужчина"
        />
        <ChildTagLabel
          tagLabel="Невинная героиня"
        />
        <ChildTagLabel
          tagLabel="Попаданец"
        />
        <ChildTagLabel
          tagLabel="Сильный герой"
        />
        <ChildTagLabel
          tagLabel="Студенты"
        />
        <ChildTagLabel
          tagLabel="Нежная героиня"
        />
        <ChildTagLabel
          tagLabel="Бывшие"
        />
        <ChildTagLabel
          tagLabel="Богатый парень и простая девушка"
        />
        <ChildTagLabel
          tagLabel="Подростки"
        />
        <ChildTagLabel
          tagLabel="Дерзкая героиня"
        />
        <ChildTagLabel
          tagLabel="Неидеальные герои"
        />
        <ChildTagLabel
          tagLabel="Сводные брат и сестра"
        />
        <ChildTagLabel
          tagLabel="Плохой парень"
        />
        <ChildTagLabel
          tagLabel="Антигерой"
        />
        <ChildTagLabel
          tagLabel="Жесткий герой"
        />
        <ChildTagLabel
          tagLabel="Попаданцы в СССР"
        />
        <ChildTagLabel
          tagLabel="Близнецы"
        />
        <ChildTagLabel
          tagLabel="Отец одиночка"
        />
      </GenreGroup>
      <GenreGroup
        tagLabel="Вся палитра чувств"
      >
        <ChildTagLabel
          tagLabel="От ненависти до любви"
        />
        <ChildTagLabel
          tagLabel="Очень откровенно"
        />
        <ChildTagLabel
          tagLabel="Романтика и любовь"
        />
        <ChildTagLabel
          tagLabel="Юмор"
        />
        <ChildTagLabel
          tagLabel="Любовь и страсть"
        />
        <ChildTagLabel
          tagLabel="Сложные отношения"
        />
        <ChildTagLabel
          tagLabel="Противостояние героев"
        />
        <ChildTagLabel
          tagLabel="Первая любовь"
        />
        <ChildTagLabel
          tagLabel="Разница в возрасте"
        />
        <ChildTagLabel
          tagLabel="Любовный треугольник"
        />
        <ChildTagLabel
          tagLabel="Истинная пара"
        />
        <ChildTagLabel
          tagLabel="Сильные чувства"
        />
        <ChildTagLabel
          tagLabel="Служебный роман"
        />
        <ChildTagLabel
          tagLabel="Тайны прошлого"
        />
        <ChildTagLabel
          tagLabel="Секс"
        />
        <ChildTagLabel
          tagLabel="Встреча через время"
        />
        <ChildTagLabel
          tagLabel="МЖМ"
        />
        <ChildTagLabel
          tagLabel="Вынужденный брак"
        />
        <ChildTagLabel
          tagLabel="Драма"
        />
        <ChildTagLabel
          tagLabel="Дружба"
        />
        <ChildTagLabel
          tagLabel="Запретная любовь"
        />
        <ChildTagLabel
          tagLabel="Любовь вопреки"
        />
        <ChildTagLabel
          tagLabel="Любовь и приключения"
        />
        <ChildTagLabel
          tagLabel="Измена"
        />
        <ChildTagLabel
          tagLabel="Жестокость и насилие"
        />
        <ChildTagLabel
          tagLabel="Интриги и страсть"
        />
        <ChildTagLabel
          tagLabel="Предательство"
        />
        <ChildTagLabel
          tagLabel="Страсть"
        />
        <ChildTagLabel
          tagLabel="Настоящая любовь"
        />
        <ChildTagLabel
          tagLabel="Принуждение"
        />
        <ChildTagLabel
          tagLabel="Любовь и месть"
        />
        <ChildTagLabel
          tagLabel="Любовь с первого взгляда"
        />
        <ChildTagLabel
          tagLabel="Одержимость"
        />
        <ChildTagLabel
          tagLabel="Босс и подчинённая"
        />
      </GenreGroup>
      <GenreGroup
        tagLabel="Особенности"
      >
        <ChildTagLabel
          tagLabel="Приключения"
        />
        <ChildTagLabel
          tagLabel="Магия и волшебство"
        />
        <ChildTagLabel
          tagLabel="Интриги"
        />
        <ChildTagLabel
          tagLabel="Кланы"
        />
        <ChildTagLabel
          tagLabel="Мистика"
        />
        <ChildTagLabel
          tagLabel="Сверхспособности"
        />
        <ChildTagLabel
          tagLabel="Криминал"
        />
        <ChildTagLabel
          tagLabel="Выживание"
        />
        <ChildTagLabel
          tagLabel="Война"
        />
        <ChildTagLabel
          tagLabel="Жизнь"
        />
        <ChildTagLabel
          tagLabel="Хэппи энд"
        />
        <ChildTagLabel
          tagLabel="Космос"
        />
        <ChildTagLabel
          tagLabel="БДСМ"
        />
        <ChildTagLabel
          tagLabel="Отбор невест"
        />
        <ChildTagLabel
          tagLabel="Расследование"
        />
        <ChildTagLabel
          tagLabel="Гаремник"
        />
        <ChildTagLabel
          tagLabel="Смерть"
        />
        <ChildTagLabel
          tagLabel="Повседневность"
        />
        <ChildTagLabel
          tagLabel="Чёрный юмор"
        />
        <ChildTagLabel
          tagLabel="Прогрессорство"
        />
        <ChildTagLabel
          tagLabel="Путешествие"
        />
        <ChildTagLabel
          tagLabel="Спасение мира"
        />
        <ChildTagLabel
          tagLabel="Боевые искусства"
        />
        <ChildTagLabel
          tagLabel="Культивация"
        />
        <ChildTagLabel
          tagLabel="Young adult"
        />
        <ChildTagLabel
          tagLabel="Путешествие во времени"
        />
      </GenreGroup>
    </>
  );
};

const SCChildTagLabel = styled.span`
  ${TextBoldStyle};
  display: flex;
  margin-bottom: 12px;

  a {
    color: var(--gray-color);

    &:hover {
      color: var(--primary-hover-color);
    }
  }
`;

const SCTagLabelGroup = styled.div`
  padding-bottom: 12px;
  margin-left: 12px;
`;

const SCTitleWrapper = styled.span`
  display: flex;
  align-items: center;
  padding-bottom: 12px;
`;

const SCTitle = styled.span`
  ${TextBoldStyle};
  width: 100%;
  display: flex;
  align-items: center;
`;

const SCArrowIcon = styled(ArrowIcon)`
  font-size: 12px;
  color: var(--black-color);
  transform: rotate(90deg);
  margin-left: auto;
  transition: all 0.3s ease;
`;

const SCTagGroup = styled.div`
  overflow: hidden;
  padding: 12px;
  transition: all 0.5s ease;
  min-height: ${MIN_HEIGHT}px;
  
  &[data-is-open="true"] {
    ${SCArrowIcon} {
      transform: rotate(270deg);      
    }
  }
`;
