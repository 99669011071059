import { unwrapResult } from '@reduxjs/toolkit';
import { useFormik } from 'formik';
import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';

import { Button, SCButton } from '~/atomic/atom/Button';
import { Input } from '~/atomic/atom/Input';
import { Field } from '~/atomic/molecula/Field';
import { TextBoldStyle } from '~/atomic/Typography';
import { login } from '~/feature/authorization/auth.data';
import { authSelector } from '~/feature/authorization/auth.selector';
import { getUserEmailInLocalStorage } from '~/feature/authorization/SaveUserLoginModal/saveUserEmailInLocalStorage';
import { useAppDispatch, useAppSelector } from '~/store';

import { openModal } from '../authorizationModal.slice';

interface SaveUserLoginFormProps {
  afterLogin?: () => void;
  loginInOtherAccount: () => void;
}

export const SaveUserLoginForm: FC<SaveUserLoginFormProps> = ({ afterLogin, loginInOtherAccount }) => {
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(authSelector);

  const loginForm = useFormik({
    initialValues: {
      email: '',
      password: '',
      rememberMe: true,
    },
    onSubmit: async ({
      email, password, rememberMe,
    }, formikHeplers) => {
      const result = await dispatch(login({
        email, password, rememberMe: Number(rememberMe), afterLogin,
      }));

      if (login.rejected.match(result) && result.payload?.errors) {
        formikHeplers.setErrors({
          email: result.payload?.errors?.email?.[0],
          password: result.payload?.errors?.password?.[0],
          rememberMe: result.payload?.errors?.rememberMe?.[0],
        });
      }
    },
    validateOnBlur: false,
    validationSchema: Yup.object().shape({
      email: Yup
        .string()
        .required()
        .email('Введите корректный E-mail адрес'),
      password: Yup.string().required(),
    }),
  });

  const loadSaveUserEmail = async () => {
    const userEmailFromStorage = unwrapResult(await dispatch(getUserEmailInLocalStorage()));

    if (userEmailFromStorage) {
      loginForm.setFieldValue('email', userEmailFromStorage);
    }
  };

  useEffect(() => {
    loadSaveUserEmail();
  }, []);

  useEffect(() => {
    return () => {
      loginForm.resetForm();
    };
  }, []);

  const onRecoveryClick = () => {
    dispatch(openModal('recovery'));
  };

  const loginInOtherAccountHandler = () => {
    loginInOtherAccount();
  };

  return (
    <SCLoginForm
      onSubmit={loginForm.handleSubmit}
    >
      <SCFieldsWrap>
        <Field
          error={loginForm.touched.email && loginForm.errors.email}
        >
          <SCEmailInput
            placeholder="E-mail"
            name="email"
            onBlur={loginForm.handleBlur}
            value={loginForm.values.email}
          />
        </Field>
        <Field
          error={loginForm.touched.password && loginForm.errors.password}
        >
          <SCInput
            name="password"
            type="password"
            placeholder="Пароль"
            onChange={(e) => {
              loginForm.setFieldValue('password', e.target.value.trim());
            }}
            onBlur={loginForm.handleBlur}
            value={loginForm.values.password}
          />
        </Field>
      </SCFieldsWrap>
      <SCRowRememberMe>
        <SCLink onClick={onRecoveryClick}>
          Забыли пароль?
        </SCLink>
      </SCRowRememberMe>
      <SCButtonWrapp>
        <Button
          type="primary"
          htmlType="submit"
          block
          isLoading={loading.login === true}
        >
          Войти
        </Button>
      </SCButtonWrapp>
      <SCLink onClick={loginInOtherAccountHandler}>
        Войти в другой аккаунт
      </SCLink>
    </SCLoginForm>
  );
};

const SCLoginForm = styled.form`
  text-align: center;
`;

const SCFieldsWrap = styled.div`
  > *:not(:last-child) {
    margin-bottom: 16px;
  }
`;

const SCEmailInput = styled(Input)`
  background: var(--border-color);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  border-radius: 2px;
  height: 44px;
  cursor: not-allowed;
  pointer-events: none;
  
  &:hover, &:active {
    border: 1px solid var(--border-color);
  }
`;

const SCInput = styled(Input)`
  background: var(--white-color);
  border: 1px solid var(--border-color);
  box-sizing: border-box;
  border-radius: 2px;
  height: 44px;
`;

const SCRowRememberMe = styled.div`
  display: flex;  
  align-items: center;
  justify-content: flex-end;
  color: var(--black-color);
  margin: 17px 0 25px 0;
`;

const SCLink = styled.a`
  ${TextBoldStyle};
`;

const SCButtonWrapp = styled.div`
  ${SCButton} {
    ${TextBoldStyle};
    margin-bottom: 30px;
    height: 44px; 
  }
`;
