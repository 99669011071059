import styled from 'styled-components';

import { NotificationIcon } from '~/atomic/atom/icon/NotificationIcon';
import { lessThan } from '~/lib/mediaQuery';

export const SCNotificationsCount = styled.div`
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: 800;
  font-size: 10px;
  line-height: 20px;
  background: var(--error-color);
  border-radius: 100%;
  position: absolute;
  top: -4px;
  right: -1px;
  cursor: pointer;
  z-index: 1;

  ${lessThan('sm')} {
    height: 16px;
    width: 16px;
  }
`;

export const SCHeaderNotificationIcon = styled(NotificationIcon)`
  font-size: 20px;
  color: var(--black-color);
  transition: all 0.3s;

  &:hover {
    color: var(--primary-hover-color);
  }
`;

export const SCIconWrapper = styled.a`
  position: relative;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  
  ${lessThan('sm')} {
    height: 24px;
    width: 24px;
  }
`;
