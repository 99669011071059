import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { AudioBookSettings } from '~/api/book/audioBookApiTypes';

export const bookEditAudioSettingsSliceName = 'bookEditAudioSettings';

const bookEditAudioSettingsSlice = createSlice({
  name: bookEditAudioSettingsSliceName,
  initialState: {
    isOpenConnectTTSPlusModal: false,
    isOpenConnectAuthorModal: false,
    audioBookSettings: {} as AudioBookSettings,
    isLoadingChangeAudioBookSettings: false,
    isOpenEditAudioBookSettings: false,
  },
  reducers: {
    setIsOpenConnectTTSPlusModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenConnectTTSPlusModal = action.payload;
    },
    setIsOpenEditAudioBookSettings: (state, action: PayloadAction<boolean>) => {
      state.isOpenEditAudioBookSettings = action.payload;
    },
    setIsOpenConnectAuthorModal: (state, action: PayloadAction<boolean>) => {
      state.isOpenConnectAuthorModal = action.payload;
    },
    setIsLoadingChangeAudioBookSettings: (state, action: PayloadAction<boolean>) => {
      state.isLoadingChangeAudioBookSettings = action.payload;
    },
    changeAudioBookSettings: (state, action: PayloadAction<Partial<AudioBookSettings>>) => {
      state.audioBookSettings = {
        ...state.audioBookSettings,
        ...action.payload,
      };
    },
  },
});

export const {
  actions: bookEditAudioSettingsActions,
  reducer: bookEditAudioSettingsReducer,
} = bookEditAudioSettingsSlice;
