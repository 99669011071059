import { WithToken } from '~/api/auth/authApiTypes';
import { RegisterTransactionResult } from '~/api/finance/financeApiTypes';
import { AdAccount, AdAccountReplenishmentProps } from '~/api/finance/marketing/adAccountApiTypes';
import { provider } from '~/api/provider/provider.config';
import { handleRejectedRequest } from '~/api/provider/providerErrors';
import {
  ApiResponse, FulfilledRequest, RejectedRequest,
} from '~/api/provider/providerTypes';

const namespace = Symbol('adAccount');

export const adAccountApi = {
  [namespace]: '/v1/finance/ad-account',

  async getAccountInfo(data: WithToken | void) {
    try {
      const result = await provider.get<
      ApiResponse<{ adAccount: AdAccount }, RejectedRequest<void>>
      >(
        this[namespace],
        {
          headers: {
            Authorization: data && data?.token
              ? `Bearer ${data?.token}`
              : provider.defaults.headers.common.Authorization,
          },
        },
      );

      if ('data' in result) {
        return result.data;
      }
    } catch (e) {
      return handleRejectedRequest<void>(e);
    }
  },

  async adAccountReplenishment(data: AdAccountReplenishmentProps) {
    try {
      const result = await provider.post<
      FulfilledRequest<RegisterTransactionResult>
      >(`${this[namespace]}/replenishment`, data);

      if ('data' in result) {
        return result.data;
      }
    } catch (e) {
      return handleRejectedRequest<void>(e);
    }
  },
};
