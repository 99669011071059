import { createAsyncThunk } from '@reduxjs/toolkit';
import Router from 'next/router';

import { LegalStatusEnum } from '~/api/account/accountApiTypes';
import { userApi } from '~/api/account/userApi';
import {
  GetRegistrationStepAuthorResponse,
  PostRegistrationStepAuthorParams,
  PostRegistrationStepCommercialParams,
  PostRegistrationStepCommercialParamsRejectValue,
  PostRegistrationStepPartnerParams, RegistrationRequest,
} from '~/api/account/userApiTypes';
import { HttpValidationError } from '~/api/provider/providerErrors';
import { pagesConfigStore } from '~/atomic/atom/links/pagesConfigStore';
import { registrationActions, userRegistrationSliceName } from '~/feature/user/registration.slice';
import { me, setUser } from '~/feature/user/user.data';
import { environments } from '~/lib/const';
import { RootState } from '~/store';

export const getRegistrationStepAuthor = createAsyncThunk<
GetRegistrationStepAuthorResponse,
void,
{
  rejectValue: { message: string; errors: { [key: string]: string[]; } };
}>(
  `${userRegistrationSliceName}/getRegistrationStepAuthor`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const result = await userApi.getRegistrationMe();

      if (result && 'data' in result) {
        thunkAPI.dispatch(registrationActions.changeAuthor(result.data));
        return result.data;
      }
    } catch (error) {
      return thunkAPI.rejectWithValue({ message: error.message, errors: error.errors });
    }
  },
);

export const postRegistrationStepAuthor = createAsyncThunk<void,
PostRegistrationStepAuthorParams,
{
  rejectValue: { message: string; errors: { [key: string]: string[]; } };
  state: RootState;
}>(
  `${userRegistrationSliceName}/postRegistrationStepAuthor`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const { user: { phoneConfirmed, emailVerified } } = thunkAPI.getState().user;

      if (!emailVerified) {
        const { message } = await import('~/atomic/atom/message');
        message.error(
          'Подтвердите свой емаил',
        );
      } else if (!phoneConfirmed) {
        const { message } = await import('~/atomic/atom/message');
        message.error(
          'Что бы зарегестрироваться как автор, '
          + 'ваш номер телефона должен быть подтвержден.',
        );
      } else {
        const result = await userApi.postRegistrationStepAuthor(data);

        thunkAPI.dispatch(me());

        if (result?.message && environments.isClient) {
          const { message } = await import('~/atomic/atom/message');
          message.success(result?.message);
        }
        if (result && 'data' in result) {
          if (environments.isProduction && (window as any)?.VK) {
            (window as any).VK?.Goal('conversion');
          }
          thunkAPI.dispatch(setUser(result?.data));
        }
        Router.push(pagesConfigStore.books.url).then(() => {
          if (result && 'data' in result) {
            thunkAPI.dispatch(setUser(result?.data));
          }
        });
      }
    } catch (error) {
      if (error instanceof HttpValidationError) {
        const { message } = await import('~/atomic/atom/message');
        message.error('Не все данные заполнены корректно');
      }

      if (environments.isClient
        && error instanceof Error
        && !(error instanceof HttpValidationError)) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ message: error.message, errors: error.errors });
    }
  },
);

export const postRegistrationStepCommercialAuthor = createAsyncThunk<void,
PostRegistrationStepAuthorParams,
{
  rejectValue: { message: string; errors?: { [key: string]: string[]; } };
  state: RootState;
}>(
  `${userRegistrationSliceName}/postRegistrationStepCommercialAuthor`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const { user: { phoneConfirmed, emailVerified } } = thunkAPI.getState().user;

      if (!emailVerified) {
        const { message } = await import('~/atomic/atom/message');
        message.error(
          'Подтвердите свой емаил',
        );
      } else if (!phoneConfirmed) {
        const { message } = await import('~/atomic/atom/message');
        message.error(
          'Что бы зарегестрироваться как автор, '
          + 'ваш номер телефона должен быть подтвержден.',
        );
      } else {
        const result = await userApi.postRegistrationStepCommercialAuthor({
          ...data,
          ogrnip: data.legalStatus === LegalStatusEnum.IndividualEntrepreneur
            ? data.ogrnip : undefined,
        });

        if (result && 'data' in result) {
          Router.push('/author/commercial/registration').then(() => {
            thunkAPI.dispatch(setUser(result?.data));
          });
        }
      }
    } catch (error) {
      if (error instanceof HttpValidationError) {
        const { message } = await import('~/atomic/atom/message');
        message.error('Не все данные заполнены корректно');
      }

      if (environments.isClient && error instanceof Error
        && !(error instanceof HttpValidationError)) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ message: error.message, errors: error.errors });
    }
  },
);

export const postRegistrationStepPartnerAsCommercialAuthor = createAsyncThunk<void,
PostRegistrationStepAuthorParams,
{
  rejectValue: { message: string; errors?: { [key: string]: string[]; } };
  state: RootState;
}>(
  `${userRegistrationSliceName}/postRegistrationStepPartnerAsCommercialAuthor`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const { user: { phoneConfirmed, emailVerified } } = thunkAPI.getState().user;

      if (!emailVerified) {
        const { message } = await import('~/atomic/atom/message');
        message.error(
          'Подтвердите свой емаил',
        );
      } else if (!phoneConfirmed) {
        const { message } = await import('~/atomic/atom/message');
        message.error(
          'Что бы зарегестрироваться как автор, '
          + 'ваш номер телефона должен быть подтвержден.',
        );
      } else {
        const result = await userApi.postRegistrationStepCommercialAuthor({
          ...data,
          ogrnip: data.legalStatus === LegalStatusEnum.IndividualEntrepreneur
            ? data.ogrnip : undefined,
        });
        if (result && 'data' in result) {
          thunkAPI.dispatch(setUser(result?.data));
        }
      }
    } catch (error) {
      if (error instanceof HttpValidationError) {
        const { message } = await import('~/atomic/atom/message');
        message.error('Не все данные заполнены корректно');
      }

      if (environments.isClient && error instanceof Error
        && !(error instanceof HttpValidationError)) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ message: error.message, errors: error.errors });
    }
  },
);

export const postRegistrationPartner = createAsyncThunk<void,
PostRegistrationStepPartnerParams,
{
  rejectValue: { message: string; errors?: { [key: string]: string[]; } };
  state: RootState;
}>(
  `${userRegistrationSliceName}/postRegistrationStepAuthor`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const { user: { phoneConfirmed, emailVerified } } = thunkAPI.getState().user;

      if (!emailVerified) {
        const { message } = await import('~/atomic/atom/message');
        message.error(
          'Подтвердите свой емаил',
        );
      } else if (!phoneConfirmed) {
        const { message } = await import('~/atomic/atom/message');
        message.error(
          'Что бы зарегистрироваться как партнер, '
          + 'ваш номер телефона должен быть подтвержден.',
        );
      } else {
        await userApi.postRegistrationStepPartner({
          ...data,
          ogrnip: data.legalStatus === LegalStatusEnum.IndividualEntrepreneur
            ? data.ogrnip : undefined,
        });
        Router.push('/author/commercial/registration');
      }
    } catch (error) {
      if (error instanceof HttpValidationError) {
        const { message } = await import('~/atomic/atom/message');
        message.error('Не все данные заполнены корректно');
      }
      if (environments.isClient && error instanceof Error
        && !(error instanceof HttpValidationError)) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ message: error.message, errors: error.errors });
    }
  },
);

export const postRegistrationPassport = createAsyncThunk<
void,
PostRegistrationStepCommercialParams,
{
  rejectValue: {
    message?: string;
    errors?: PostRegistrationStepCommercialParamsRejectValue
  };
}>(
  `${userRegistrationSliceName}/postRegistrationPassport`,
  async (
    data,
    thunkAPI,
  ) => {
    try {
      const result = await userApi.postRegistrationPassport({ ...data });
      if (result && 'data' in result) {
        if (environments.isProduction && (window as any)?.VK) {
          (window as any).VK?.Goal('conversion');
        }

        thunkAPI.dispatch(setUser(result.data)).then(() => {
          Router.push('/author/commercial/registration/pending');
        });
      }
    } catch (error) {
      if (error instanceof HttpValidationError) {
        const { message } = await import('~/atomic/atom/message');
        message.error('Не все данные заполнены корректно');
        return thunkAPI.rejectWithValue({
          message: error.message,
          errors: error.getErrors(),
        });
      }
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ message: error.message });
    }
  },
);

export const getRegistrationRequest = createAsyncThunk<
RegistrationRequest,
void,
{
  rejectValue: { message: string; errors: { [key: string]: string[]; } };
}>(
  `${userRegistrationSliceName}/getRegistrationRequest`,
  async (
    _,
    thunkAPI,
  ) => {
    try {
      const result = await userApi.getRegistrationRequest();

      if (result && 'data' in result) {
        thunkAPI.dispatch(registrationActions.changeRegistrationRequest(result.data));
        return result.data;
      }
    } catch (error) {
      if (environments.isClient && error instanceof Error) {
        const { message } = await import('~/atomic/atom/message');
        message.error(error.message);
      }
      return thunkAPI.rejectWithValue({ message: error.message, errors: error.errors });
    }
  },
);
