import React, {
  useEffect, useRef, useState,
} from 'react';
import styled from 'styled-components';

import { AudioPlayerCloseIcon } from '~/atomic/atom/icon/audioPlayer/CloseIcon';
import { AudioPlayerNextIcon } from '~/atomic/atom/icon/audioPlayer/NextIcon';
import { AudioPlayerPlayIcon } from '~/atomic/atom/icon/audioPlayer/PlayIcon';
import { AudioPlayerPrevIcon } from '~/atomic/atom/icon/audioPlayer/PrevIcon';
import { AudioPlayerStopIcon } from '~/atomic/atom/icon/audioPlayer/StopIcon';
import { closeAudioPlayer, openAudioPlayer } from '~/feature/audio/audioPlayer.data';
import {
  audioPlayerActions,
  audioPlayerSelector, isAvailableNextChapterPlayerSelector,
  isAvailablePrevChapterPlayerSelector,
  nextChapterPlayerSelector,
  prevChapterPlayerSelector,
} from '~/feature/audio/audioPlayer.slice';
import { AudioPlayerChapterListModal } from '~/feature/audio/audioPlayerChapterList/AudioPlayerChapterListModal';
import { AudioPlayerChapterListPopover } from '~/feature/audio/audioPlayerChapterList/AudioPlayerChapterListPopover';
import { AudioPlayerProgress } from '~/feature/audio/AudioPlayerProgress';
import { bookSelector } from '~/feature/book/book.selector';
import { buyAudioBookActions } from '~/feature/book/buyAudioBook/buyAudioBook.slice';
import { environments } from '~/lib/const';
import { lessThan } from '~/lib/mediaQuery';
import { useAppDispatch, useAppSelector } from '~/store';

const showNotAllowedAudioChapterWarningModal = async () => {
  const { Modal } = await import('antd');

  Modal.warning({
    title: 'К сожалению, глава еще не озвучена :(',
    okCancel: false,
    okText: 'Закрыть',
    maskClosable: true,
  });
};

export const AudioPlayer = () => {
  const dispatch = useAppDispatch();
  const {
    isPlayed,
    isOpenPlayer,
    isChangeTrack,
    audioPath,
    chapters,
  } = useAppSelector(audioPlayerSelector);
  const { bookPage } = useAppSelector(bookSelector);
  const isAvailablePrevChapter = useAppSelector(isAvailablePrevChapterPlayerSelector);
  const isAvailableNextChapter = useAppSelector(isAvailableNextChapterPlayerSelector);
  const prevChapterPlayer = useAppSelector(prevChapterPlayerSelector);
  const nextChapterPlayer = useAppSelector(nextChapterPlayerSelector);
  const [timerId, setTimerId] = useState(undefined);
  const audioPlayerRef = useRef<HTMLAudioElement>(null);

  const playPrevChapterHandler = () => {
    dispatch(openAudioPlayer({ filePath: prevChapterPlayer?.url }));
  };

  const playNextChapterHandler = () => {
    if (isAvailableNextChapter) {
      dispatch(openAudioPlayer({ filePath: nextChapterPlayer?.url }));
    } else if (!bookPage?.audiobook?.bought) {
      dispatch(buyAudioBookActions.showBuyAudioBookModal());
    } else {
      showNotAllowedAudioChapterWarningModal();
    }
  };

  const playHandler = () => {
    dispatch(audioPlayerActions.setIsPlayed(true));
  };

  const stopHandler = () => {
    dispatch(audioPlayerActions.setIsPlayed(false));
    clearInterval(timerId);
  };

  const closePlayerHandler = () => {
    dispatch(closeAudioPlayer());
    clearInterval(timerId);
  };

  useEffect(() => {
    // Обработка включения другой аудиозаписи
    if (isOpenPlayer && isChangeTrack) {
      stopHandler();
      audioPlayerRef.current.src = audioPath;
      setTimeout(() => {
        dispatch(audioPlayerActions.setIsChangeTrack(false));
        dispatch(audioPlayerActions.changeAudioTime(
          Math.round(audioPlayerRef.current.duration * 1000),
        ));
        playHandler();
      }, 1);
    }
  }, [audioPath, isOpenPlayer, isChangeTrack]);

  useEffect(() => {
    // запуск плеера
    if (isPlayed && isOpenPlayer) {
      audioPlayerRef.current?.play();
    }
  }, [isPlayed]);

  useEffect(() => {
    // остановка плеера
    if (!isPlayed && isOpenPlayer) {
      audioPlayerRef.current?.pause();
    }
  }, [isPlayed]);

  useEffect(() => {
    // запуск таймера для подсчета времени
    if (environments.isClient && isOpenPlayer) {
      const currentTimeTimer = () => {
        const timerId = setInterval(() => {
          const currentTime = Math.round(audioPlayerRef.current.currentTime * 1000);
          dispatch(audioPlayerActions.changeCurrentAudioTime(
            currentTime,
          ));
        }, 1000);
        setTimerId(timerId);
      };

      audioPlayerRef.current.onplay = () => {
        currentTimeTimer();
      };
    }
  }, [isOpenPlayer]);

  useEffect(() => {
    // получения общего времени аудиофайла и текущего
    if (environments.isClient && isOpenPlayer) {
      audioPlayerRef.current.onloadeddata = () => {
        dispatch(audioPlayerActions.changeCurrentAudioTime(
          Math.round(audioPlayerRef.current.currentTime * 1000),
        ));
        dispatch(audioPlayerActions.changeAudioTime(
          Math.round(audioPlayerRef.current.duration * 1000),
        ));
      };
    }
  }, [isOpenPlayer]);

  useEffect(() => {
    // Обработка завершения трека
    if (environments.isClient && isOpenPlayer) {
      audioPlayerRef.current.onended = () => {
        dispatch(audioPlayerActions.changeCurrentAudioTime(
          Math.round(audioPlayerRef.current.duration * 1000),
        ));
        dispatch(audioPlayerActions.setIsPlayed(false));
        dispatch(audioPlayerActions.setIsEndAudio(true));

        if (isAvailableNextChapter) {
          playNextChapterHandler();
        } else {
          clearInterval(timerId);
        }
      };
    }
  }, [isOpenPlayer, nextChapterPlayer]);

  useEffect(() => {
    // сброс таймера для подсчета времени
    if (environments.isClient && isOpenPlayer) {
      audioPlayerRef.current.onpause = () => {
        clearInterval(timerId);
      };
    }
  }, [timerId, isOpenPlayer]);

  return (
    <>
      <SCAudioPlayer data-is-open={isOpenPlayer}>
        <audio preload="auto" src={audioPath} ref={audioPlayerRef} />
        {isOpenPlayer ? (
          <>
            <SCControls>
              <SCPrevIcon
                onClick={playPrevChapterHandler}
                data-is-active={isAvailablePrevChapter}
              />
              {isPlayed
                ? <SCStopIcon onClick={stopHandler} />
                : <SCPlayIcon onClick={playHandler} />}
              <SCNextIcon
                onClick={playNextChapterHandler}
              />
            </SCControls>
            {!!chapters.length && (
              <AudioPlayerChapterListPopover />
            )}
            <AudioPlayerProgress audioPlayer={audioPlayerRef.current} />
            <SCClosePlayer>
              <SCAudioPlayerCloseIcon onClick={closePlayerHandler} />
            </SCClosePlayer>
          </>
        ) : null}
      </SCAudioPlayer>
      {isOpenPlayer ? <AudioPlayerChapterListModal /> : null}
    </>
  );
};

const SCAudioPlayer = styled.div`
  position: fixed;
  z-index: 10;
  bottom: 0;
  right: 50%;
  transform: translateX(50%);
  max-width: 1200px;
  width: 100%;
  background-color: var(--black-color);
  box-shadow: 0 -3px 5px rgba(53, 63, 72, 0.15);
  display: flex;
  align-items: center;
  
  ${lessThan('sm')} {
    flex-wrap: wrap;
  }
  
  &[data-is-open="false"] {
    opacity: 0;
    pointer-events: none;
  }
`;

const SCControls = styled.div`
  display: flex;
  align-items: center;
  padding: 25px 40px 25px 30px;
  
  > *:not(:last-child) {
    margin-right: 32px;
  }

  ${lessThan('sm')} {
    padding: 16px 32px 12px 20px;
  }
`;

const SCPrevIcon = styled(AudioPlayerPrevIcon)`
  fill: var(--white-color);
  width: 14px;
  height: 20px;
  cursor: pointer;
  transition: fill 0.3s ease;
  
  &:hover {
    fill: var(--primary-hover-color);
  }
  
  &[data-is-active="false"] {
    fill: var(--gray-color);
    pointer-events: none;
  }
`;

const SCPlayIcon = styled(AudioPlayerPlayIcon)`
  fill: var(--white-color);
  width: 22px;
  height: 22px;
  cursor: pointer;
  transition: fill 0.3s ease;
  
  &:hover {
    fill: var(--primary-hover-color);
  }
`;

const SCNextIcon = styled(AudioPlayerNextIcon)`
  fill: var(--white-color);
  width: 14px;
  height: 20px;
  cursor: pointer;
  transition: fill 0.3s ease;

  &:hover {
    fill: var(--primary-hover-color);
  }

  &[data-is-active="false"] {
    fill: var(--gray-color);
    pointer-events: none;
  }
`;

const SCClosePlayer = styled.div`
  display: flex;
  margin-left: auto;
  padding: 25px 25px 25px 20px;

  ${lessThan('sm')} {
    order: 2;

    padding: 16px 20px 12px;
  }
`;

const SCAudioPlayerCloseIcon = styled(AudioPlayerCloseIcon)`
  width: 18px;
  height: 18px;
  fill: var(--gray-color);
  cursor: pointer;
  transition: fill 0.3s ease;

  &:hover {
    fill: var(--primary-hover-color);
  }
`;

const SCStopIcon = styled(AudioPlayerStopIcon)`
  width: 22px;
  height: 22px;
  fill: var(--white-color);
  cursor: pointer;
  transition: fill 0.3s ease;

  &:hover {
    fill: var(--primary-hover-color);
  }
`;
