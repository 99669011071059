// eslint-disable-next-line max-classes-per-file
import { AxiosError } from 'axios';

import { RejectedRequest } from '~/api/provider/providerTypes';

export class HttpValidationError<T> extends Error {
  private errors: any;

  getErrors<T = any>(): {
    [P in keyof T]?: string[];
  } {
    return this.errors;
  }

  constructor(errors: RejectedRequest<T>['errors'], message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = HttpValidationError.name;
    this.errors = errors;
  }
}

export class HttpNotFoundError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = HttpNotFoundError.name;
  }
}

export class HttpUnAuthorizedError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = HttpUnAuthorizedError.name;
  }
}

export class HttpServerNotAllowedError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = HttpServerNotAllowedError.name;
  }
}

export class HttpConflictError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = HttpConflictError.name;
  }
}

export class HttpInvalidDataError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = HttpInvalidDataError.name;
  }
}

export class HttpProblemError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = HttpProblemError.name;
  }
}

export class HttpForbiddenError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
    this.name = HttpForbiddenError.name;
  }
}

export function isAxiosError<T = any>(error: Error): error is AxiosError<T> {
  return !!(error as any).isAxiosError;
}

export const defaultErrorMessage = 'Что-то пошло не так. '
  + 'Мы зафиксировали ошибку и постараемся как можно быстрее исправить';

export function handleRejectedRequest<T = void>(
  error: Error | unknown,
  message?: string,
) {
  if (error instanceof Error && isAxiosError<RejectedRequest<T>>(error)) {
    if (error.response?.status === 422) {
      return Promise.reject(
        new HttpValidationError<T>(
          error?.response?.data?.errors ?? {},
          message
          || error?.response?.data?.message
          || 'Изменения не сохранены. Проверьте правльность заполнения данных.',
        ),
      );
    }
    if (error.response?.status === 401) {
      return Promise.reject(
        new HttpUnAuthorizedError(
          '',
        ),
      );
    }

    if (error.response?.status === 400) {
      return Promise.reject(
        new HttpInvalidDataError(
          error.response.data.message,
        ),
      );
    }

    if (error.response?.status === 404) {
      return Promise.reject(
        new HttpNotFoundError(
          error.response.data.message,
        ),
      );
    }

    if (error.response?.status === 403) {
      return Promise.reject(
        new HttpForbiddenError(
          error.response.data.message,
        ),
      );
    }

    if (error.response?.status === 409) {
      return Promise.reject(
        new HttpConflictError(
          error.response.data.message,
        ),
      );
    }

    if (error.response!.status > 499) {
      return Promise.reject(
        new HttpServerNotAllowedError(defaultErrorMessage),
      );
    }
    return Promise.reject(
      new HttpUnAuthorizedError(
        error.response!.data.message,
      ),
    );
  }
  return Promise.reject(
    new HttpServerNotAllowedError(defaultErrorMessage),
  );
}
