import React from 'react';
import styled from 'styled-components';

import { User } from '~/api/account/accountApiTypes';
import { MiniatureSizesEnum } from '~/api/book/bookApiTypes';
import { Avatar } from '~/atomic/atom/Avatar/Avatar';
import { AuthorLink } from '~/atomic/atom/links/dynamicLinks';
import { TextBoldStyle } from '~/atomic/Typography';
import { Linkify } from '~/feature/utils/Linkify';

interface UserViewProps {
  userInfo?: Partial<User>;
}

export const UserView = ({ userInfo }: UserViewProps) => {
  const {
    name, status, username, avatarImages,
  } = userInfo;

  return (
    <SCUserView>
      <SCTop>
        <SCAvatarWrapper>
          <AuthorLink
            username={username ?? ''}
          >
            <a>
              <Avatar
                alt={name}
                imageSize={80}
                avatarSize={MiniatureSizesEnum.L}
                avatarImages={avatarImages}
                userName={name}
              />
            </a>
          </AuthorLink>
        </SCAvatarWrapper>
        <SCGroupInfo>
          <AuthorLink
            username={username ?? ''}
          >
            <SCAuthorName>{name}</SCAuthorName>
          </AuthorLink>
        </SCGroupInfo>
      </SCTop>
      <div>

        {
          status && (
            <Linkify>
              <SCQuote>{ status }</SCQuote>
            </Linkify>
          )
        }
      </div>
    </SCUserView>
  );
};

const SCAuthorName = styled.a`
  ${TextBoldStyle};
  margin-bottom: 3px;
  color: var(--black-color);
  transition-property: color;
  transition: 0.3s;

  &:hover {
    color: var(--primary-hover-color);
  }
`;

const SCAvatarWrapper = styled.div`
  margin-bottom: 12px;
`;

const SCGroupInfo = styled.div`
  margin-bottom: 0;
  flex-direction: column;
  display: flex;
  justify-content: center;
`;

export const SCQuote = styled.div`
  width: 100%;
  max-width: 350px;
  margin: 0 auto;
  position: relative;
  font-size: 14px;
  line-height: 18px;
  color: var(--gray-color);
  background: var(--bg-color);
  border-radius: 4px;
  padding: 10px;
  margin-top: 15px;
  word-break: break-word;
  text-align: left;

  &:after {
    content: '';
    position:absolute;
    top: -14px;
    left: 15px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 19px 14px;
    border-color: transparent transparent var(--bg-color) transparent;
  }
`;

const SCTop = styled.div`
  display: flex;
  text-align: center;
  flex-direction: column;
`;

const SCUserView = styled.div`
  padding: 0 0 13px 0;
`;
