import { createSlice } from '@reduxjs/toolkit';

const editChapterSliceName = 'editChapter';

const editChapterSlice = createSlice({
  name: editChapterSliceName,
  initialState: { isEnableSchedulePublicationChapter: false },
  reducers: {
    toggleIsEnableSchedulePublicationChapter: (state) => {
      state.isEnableSchedulePublicationChapter = !state.isEnableSchedulePublicationChapter;
    },
  },
});

export const {
  actions: editChapterActions,
  reducer: editChapterReducer,
} = editChapterSlice;
